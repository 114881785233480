import { StackProps, HStack, Text, VStack, Box } from '@chakra-ui/react';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import { PrimitiveValue, AnyCalculationValue, AbsolutePeriod } from '../../../domain';
import React from 'react';

export interface CalculationViewFormatOptions {
    format: 'long' | 'short';
}

export interface CalculationViewProps {
    containerProps?: Omit<StackProps, 'children'>;
    label: string;
    value: number | null;
    description?: string | null;
    period: AbsolutePeriod | null;
    comparison: AbsolutePeriod | null;
    formatPeriod(period: AbsolutePeriod): string;
    formatValue(
        value: PrimitiveValue,
        options?: CalculationViewFormatOptions
    ): React.ReactNode;
}

export const CalculationView: React.FC<CalculationViewProps> = ({ ...props }) => {
    return (
        <VStack p={3} alignItems="flex-start" {...props.containerProps}>
            <Text>{props.label}</Text>
            <VStack alignItems="flex-start" spacing={0}>
                {props.comparison && (
                    <>
                        <HStack spacing={2}>
                            <Text size="xs" variant="mute">
                                {props.formatPeriod(props.comparison)}
                            </Text>
                            <ArrowForwardIcon color="whiteAlpha.600" />
                        </HStack>
                    </>
                )}
                {props.period && (
                    <Text size="xs" variant="mute">
                        {props.formatPeriod(props.period)}
                    </Text>
                )}
            </VStack>
            <Text bg="blackAlpha.500" px={1}>
                {props.formatValue(props.value, { format: 'long' })}
            </Text>
            {props.description && (
                <Box>
                    <Text
                        py={0}
                        fontStyle="italic"
                        size="sm"
                        color="whiteAlpha.800"
                        dangerouslySetInnerHTML={{
                            __html: props.description ?? '',
                        }}
                    />
                </Box>
            )}
        </VStack>
    );
};

import { DefaultValues, useForm } from 'react-hook-form';
import { ImportNewFormProvider } from './importNewInterface';
import { ImportNewAggregate, ImportNewFormValues } from './importNewModel';
import { ImportNewControllerProps } from './importNewProps';

export function createImportNewFormProvider(): ImportNewFormProvider {
    function getDefaultProps(
        item: ImportNewAggregate
    ): DefaultValues<ImportNewFormValues> {
        const [sample, ...rest] = item.datasets;
        if (rest.length === 0) {
            return {
                dataset: sample.dataset.id,
                rows: null,
            };
        }
        return {
            dataset: null,
            rows: null,
        };
    }
    return {
        useForm(context, item) {
            const form = useForm<ImportNewFormValues>({
                defaultValues: getDefaultProps(item),
            });
            return {
                ...form,
                setValue(key, value, options) {
                    if (key === 'dataset') {
                        // clear uploaded data when dataset changes
                        form.setValue('rows', null);
                    }
                    // @ts-expect-error
                    return form.setValue(key, value, options);
                },
            };
        },
    };
}

import React, { useMemo } from 'react';
import { useParams } from 'react-router';
import { ReportDetailRouteConfig } from './reportDetailConfig';
import { ReportDetailRouteContext } from './reportDetailContext';
import { createReportDetailController } from './reportDetailController';
import { createReportDetailLoader } from './reportDetailLoader';
import { ReportDefailRouteParamsSchema } from './reportDetailSchema';
import { createReportDetailRouteView } from './reportDetailView';

export function createReportDetailRoute(config: ReportDetailRouteConfig): React.FC {
    const { hook } = config;
    const controller = createReportDetailController(config);
    const loader = createReportDetailLoader(config);
    const Component = createReportDetailRouteView(config);
    return () => {
        const params = useParams();

        const parsed = useMemo(() => {
            return ReportDefailRouteParamsSchema.parse(params);
        }, [params]);

        const auth = hook.useAuth();
        const organization = hook.useOrganization();
        const context = {
            auth,
            organization,
        };
        const viewData = loader.useLoad(context, {
            report: {
                id: parsed.reportId,
            },
        });
        const viewProps = controller.useProps(context, viewData, {
            report: {
                id: parsed.reportId,
            },
        });
        return (
            <ReportDetailRouteContext.Provider value={context}>
                <Component {...viewProps} />
            </ReportDetailRouteContext.Provider>
        );
    };
}

import { ReportItemAggregate } from '../../item';
import { buildDependencyItemProps } from '../item';
import { StudyDependencyListProps } from './studyDependencyListProps';

export function buildDependencyListProps(
    item: ReportItemAggregate
): StudyDependencyListProps {
    const items = item.report.dependencies.map(buildDependencyItemProps.bind(null, item));
    const fulfilledCount = items.filter((item) => item.isFulfilled).length;
    const totalCount = items.length;
    return {
        status: {
            label: `${fulfilledCount}/${totalCount} fulfilled`,
            isFulfilled: fulfilledCount === totalCount,
        },
        items: item.report.dependencies.map(buildDependencyItemProps.bind(null, item)),
    };
}

import { useTheme } from '@chakra-ui/system';
import { useMemo } from 'react';
import { LineChartProps } from './lineChartProps';
import { LineChartConfig } from './lineChartConfig';
import { LineChartTooltipItem, LineChartDataset } from './lineChartModel';

const PARTIAL_DATASET_ID_SUFFIX = '-partial';

export interface UseLineChartReturnValue {
    getLineChartProps(): LineChartProps;
}

export const useLineChart = (config: LineChartConfig): UseLineChartReturnValue => {
    const theme = useTheme();
    const colorsLegend = theme.colors.legend;

    function getColor(dataset: LineChartDataset, index: number) {
        return (
            config.options.getColor?.(dataset, index) ??
            colorsLegend?.[dataset.colorScheme]?.[200]
        );
    }

    function isPartial(label: string) {
        return config.options.partial.includes(label);
    }

    const datasetsFull = useMemo(
        () =>
            config.data.datasets.map((dataset, index) => ({
                colorScheme: dataset.colorScheme,
                hidden: !config.chart.isVisible({ id: dataset.label }),
                lineTension: 0,
                cubicInterpolationMode: 'monotone',
                fill: false,
                backgroundColor: getColor(dataset, index),
                borderColor: getColor(dataset, index),
                label: dataset.label,
                borderWidth: 3,
                pointRadius: 2,
                data: config.data.labels.map((label, index, array) => {
                    return isPartial(label) ? null : dataset.data[index];
                }),
            })),
        [config.chart.isVisible, config.data.datasets, config.data.labels]
    );

    const datasetsPartial = useMemo(() => {
        if (config.options.partial.length === 0) {
            // only create partial datasets if there are partial labels
            return [];
        }
        return config.data.datasets.map((dataset, index) => ({
            colorScheme: dataset.colorScheme,
            hidden: !config.chart.isVisible({ id: dataset.label }),
            lineTension: 0,
            cubicInterpolationMode: 'monotone',
            fill: false,
            backgroundColor: getColor(dataset, index),
            borderColor: getColor(dataset, index),
            borderDash: [0, 8],
            borderWidth: 4,
            pointRadius: 2,
            borderCapStyle: 'round',
            label: `${dataset.label}${PARTIAL_DATASET_ID_SUFFIX}`,
            partial: true,
            data: config.data.labels.map((label, index, array) => {
                const labelNext = array[index + 1];
                if (!labelNext) {
                    return dataset.data[index];
                }
                return isPartial(labelNext) ? dataset.data[index] : null;
            }),
        }));
    }, [
        config.chart.isVisible,
        config.options.partial,
        config.data.datasets,
        config.data.labels,
    ]);

    const datasetsFullAndPartial = [...datasetsFull, ...datasetsPartial];

    const getLineChartProps: UseLineChartReturnValue['getLineChartProps'] = () => ({
        ...config.options,
        data: {
            ...config.data,
            datasets: datasetsFullAndPartial,
        },
        getLabel(tooltipItem: LineChartTooltipItem) {
            const dataset = datasetsFullAndPartial[tooltipItem.datasetIndex];
            if (!dataset) {
                return;
            }
            if (
                tooltipItem.index === dataset.data.length - 2 &&
                dataset.label.includes(PARTIAL_DATASET_ID_SUFFIX)
            ) {
                return;
            }
            const labelNormalized = dataset.label.replace(PARTIAL_DATASET_ID_SUFFIX, '');
            const rowIndexNormalized =
                tooltipItem.datasetIndex % config.data.datasets.length;

            const value = config.options.getCaption?.(
                {
                    rowIndex: rowIndexNormalized,
                    columnIndex: tooltipItem.index,
                    key: labelNormalized,
                },
                tooltipItem.value
            );

            if (!value) {
                return;
            }
            return `${labelNormalized}: ${value}`;
        },
    });

    return { getLineChartProps };
};

import { FlexProps } from '@chakra-ui/react';
import React, { useMemo } from 'react';
import {
    Chart,
    useChart,
    useLineChart,
    LineChart,
    LineChartProps,
    ChartWrapperProps,
    LineChartV2,
} from '../../../../ui';

export interface LineVisualizationProps extends LineChartProps {
    style?: {
        wrapper?: ChartWrapperProps['style'];
    };
}

export const LineVisualization: React.FC<LineVisualizationProps> = ({
    data,
    ...props
}) => {
    const chart = useChart({
        width: props.width ?? 0,
        height: props.height ?? 0,
        datasets: data.datasets,
    });
    const line = useLineChart({
        chart,
        data,
        options: props,
    });
    // console.log('DEBUG chart', chart);
    // console.log('DEBUG line', line);
    return (
        <Chart.Wrapper style={props.style?.wrapper} {...chart.getWrapperProps()}>
            <Chart.Legend {...chart.getLegendProps()} />
            <Chart.Content {...chart.getContentProps()}>
                <LineChart {...line.getLineChartProps()} />
            </Chart.Content>
        </Chart.Wrapper>
    );
};

export const LineVisualizationV2: React.FC<LineVisualizationProps> = ({
    data,
    ...props
}) => {
    const chart = useChart({
        width: props.width ?? 0,
        height: props.height ?? 0,
        datasets: data.datasets,
    });
    const line = useLineChart({
        chart,
        data,
        options: props,
    });
    // console.log('DEBUG chart', chart);
    // console.log('DEBUG line', line);
    return <LineChartV2 {...line.getLineChartProps()} />;
};

import { SdkClientError } from '..';
import { EndpointConfig } from './endpointConfig';
import { Endpoint } from './endpointInterface';

export function createEndpoint(config: EndpointConfig): Endpoint {
    return {
        async list(props) {
            const response = await config.http.get(props.url, {
                params: props.query,
                headers: {
                    authorization: props.options.token,
                },
            });
            const parsed = props.schema.response.safeParse(response.data);
            if (!parsed.success) {
                console.error('schema error', parsed.error, response.data);
                throw new SdkClientError('unexpected data returned');
            }
            return parsed.data;
        },
        async retrieve(props) {
            const response = await config.http.get(props.url, {
                headers: {
                    authorization: props.options.token,
                },
            });
            const parsed = props.schema.response.safeParse(response.data);
            if (!parsed.success) {
                console.error('schema error', parsed.error, response.data);
                throw new SdkClientError('unexpected data returned');
            }
            return parsed.data;
        },
        async create(props) {
            const response = await config.http.post(props.url, props.payload, {
                headers: {
                    authorization: props.options.token,
                },
            });
            const parsed = props.schema.response.safeParse(response.data);
            if (!parsed.success) {
                console.error('schema error', parsed.error, response.data);
                throw new SdkClientError('unexpected data returned');
            }
            return parsed.data;
        },
    };
}

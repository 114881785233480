import { z } from 'zod';
import { ConversationThreadLoaderProps } from './conversationThreadProps';

export const ConversationThreadUrlParamSchema = z
    .object({
        threadId: z.string(),
        type: z.union([z.literal('survey'), z.literal('assistant')]),
    })
    .transform(
        (item): ConversationThreadLoaderProps => ({
            agent: {
                id: item.type,
            },
            thread: {
                id: item.threadId,
            },
        })
    );

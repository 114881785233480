import {
    Box,
    Button,
    ButtonGroup,
    Heading,
    HStack,
    Icon,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalOverlay,
    StackDivider,
    Text,
    VStack,
    Wrap,
} from '@chakra-ui/react';
import React from 'react';
import { FaQuoteLeft, FaQuoteRight } from 'react-icons/fa';
import { RiDoubleQuotesL } from 'react-icons/ri';
import { AiOutlineCheck, AiOutlineCheckCircle } from 'react-icons/ai';
import { Icons } from '../../../../config';
import { NetworkOverviewRouteConfig } from './networkOverviewConfig';
import { NetworkOverviewViewProps } from './networkOverviewProps';

export function createNetworkOverviewRouteView(
    config: NetworkOverviewRouteConfig
): React.FC<NetworkOverviewViewProps> {
    const {
        UI: {
            Application: { Link, Anchor },
        },
    } = config;

    // color="blackAlpha.900"
    // bg="#c6e74f"

    const ExampleItem: React.FC<{ text: string }> = (props) => {
        return (
            <VStack
                w="full"
                // borderRadius="md"
                // px={6}
                // py={3}
                // borderColor="whiteAlpha.300"
                // borderStyle="solid"
                // borderLeftWidth={4}
                // borderColor="#c6e74f"
                align="start"
            >
                <HStack
                    // pl={4}
                    // borderStyle="solid"
                    // borderLeftWidth={4}
                    // borderColor="#c6e74f"
                    color="whiteAlpha.800"
                    fontStyle="italic"
                    spacing={3}
                    align="start"
                    fontWeight="medium"
                >
                    <Icon
                        color="#c6e74f"
                        position="relative"
                        top={1}
                        display="inline"
                        as={FaQuoteLeft}
                    />
                    <Text whiteSpace="pre-wrap">{props.text}</Text>
                </HStack>
                {/* <HStack w="full" spacing={3} pl={6}>
                    <Box p={1} borderRadius="full" bg="whiteAlpha.300">
                        <Box w={5} h={5}></Box>
                    </Box>
                    <HStack spacing={2}>
                        <Text color="whiteAlpha.600">John Doe, CEO</Text>
                    </HStack>
                </HStack> */}
            </VStack>
        );
        // return (
        //     <HStack
        //         w="full"
        //         borderRadius="md"
        //         p={6}
        //         borderStyle="solid"
        //         borderColor="whiteAlpha.300"
        //         borderWidth={2}
        //     >
        //         <HStack spacing={4}>
        //             <Box
        //                 p={2.5}
        //                 borderRadius="sm"
        //                 bg="whiteAlpha.300"
        //                 // bg="#c6e74f"
        //                 // bg="blue.200"
        //             >
        //                 {/* <Icon fontSize="lg" color="blue.200" as={AiOutlineCheckCircle} /> */}
        //                 <Icon fontSize="xl" color="#c6e74f" as={AiOutlineCheckCircle} />
        //             </Box>
        //             <Text color="whiteAlpha.800">{props.text}</Text>
        //         </HStack>
        //     </HStack>
        // );
    };

    return (props) => {
        return (
            <>
                <VStack
                    align="start"
                    w="full"
                    spacing={6}
                    divider={
                        <StackDivider borderColor="whiteAlpha.200" borderWidth={2} />
                    }
                >
                    <VStack w="full" align="start" spacing={6}>
                        <VStack w="full" align="start" spacing={3}>
                            <VStack w="full" align="start" spacing={3}>
                                <Heading
                                    fontSize="xl"
                                    fontWeight="semibold"
                                    color="blue.300"
                                >
                                    High Performer Calls
                                </Heading>
                                <Text fontWeight="semibold" fontSize="2xl" maxW="36rem">
                                    Meet 1:1 with a High Performer from your peer group
                                    and get tactical advice on how to improve
                                </Text>
                            </VStack>
                            <VStack
                                align="start"
                                spacing={2}
                                fontSize="lg"
                                color="whiteAlpha.700"
                                maxW="42rem"
                            >
                                <Text>
                                    High Performer Calls connect you with top-performing
                                    (verified by Varos) marketing professionals in your
                                    peer group who provide actionable strategies tailored
                                    to your business. Whether optimizing ad spend or
                                    enhancing campaign performance, our expert network can
                                    help you achieve your marketing goals
                                </Text>
                            </VStack>
                        </VStack>
                        <ButtonGroup w="full" spacing={4}>
                            <Button
                                {...props.modal.triggerProps}
                                borderRadius="full"
                                variant="outline"
                                colorScheme="blue"
                                _focus={{ outline: 'none' }}
                            >
                                Watch video
                            </Button>
                            <Link {...props.links.bookings}>
                                <Button
                                    borderRadius="full"
                                    colorScheme="blue"
                                    _focus={{ outline: 'none' }}
                                >
                                    Request a call
                                </Button>
                            </Link>
                        </ButtonGroup>
                    </VStack>
                    <VStack
                        w="full"
                        align="start"
                        fontWeight="medium"
                        spacing={6}
                        maxW="42rem"
                    >
                        <HStack
                            w="full"
                            justify="space-between"
                            // pb={4}
                            // borderBottomWidth={2}
                            // borderStyle="solid"
                            // borderColor="whiteAlpha.300"
                        >
                            <Heading fontSize="lg" fontWeight="semibold">
                                Testimonials
                            </Heading>
                            <Anchor {...props.links.faq}>
                                <Text
                                    cursor="pointer"
                                    color="blue.300"
                                    _hover={{ textDecoration: 'underline' }}
                                >
                                    Learn more about high performer calls
                                </Text>
                            </Anchor>
                        </HStack>
                        <VStack
                            w="full"
                            align="start"
                            fontWeight="medium"
                            spacing={6}
                            maxW="32rem"
                        >
                            <ExampleItem text="I was able to learn from an e-commerce jewelry brand who profitably scaled spend from $10k to $100k" />
                            <ExampleItem text="I discovered tactics that helped my high AOV furniture brand succeed with Google Ads" />
                            <ExampleItem text="I was able to get actionable creative strategies from high ROAS brands in my category that helped me improve my ROAS" />
                        </VStack>
                    </VStack>
                </VStack>
                <Modal {...props.modal.containerProps} size="3xl">
                    <ModalOverlay />
                    <ModalContent>
                        <ModalCloseButton _focus={{ outline: 'none' }} />
                        <ModalBody p={0}>
                            <VStack w="full" align="start" p={12} spacing={6}>
                                <VStack w="full" align="start" spacing={3}>
                                    <HStack color="#c6e74f">
                                        <Icon as={Icons.Feature.Highlight} />
                                        <Text fontWeight="bold">Feature highlight</Text>
                                    </HStack>
                                    <VStack w="full" align="start" spacing={1}>
                                        <Heading fontSize="2xl">
                                            {props.video.title}
                                        </Heading>
                                        {props.video.description && (
                                            <Text
                                                fontWeight="medium"
                                                fontSize="xl"
                                                color="whiteAlpha.700"
                                            >
                                                {props.video.description}
                                            </Text>
                                        )}
                                    </VStack>
                                </VStack>
                                <Box
                                    bg="whiteAlpha.300"
                                    borderRadius="lg"
                                    overflow="hidden"
                                    w="full"
                                    style={{
                                        width: '100%',
                                        position: 'relative',
                                        paddingBottom: '64.86486486486486%',
                                        height: 0,
                                    }}
                                >
                                    <iframe
                                        src={props.video.src}
                                        frameBorder="0"
                                        allowFullScreen
                                        style={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            width: '100%',
                                            height: '100%',
                                        }}
                                    />
                                </Box>
                            </VStack>
                        </ModalBody>
                    </ModalContent>
                </Modal>
            </>
        );
    };
}

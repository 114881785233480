import React from 'react';
import { HStack, Icon, Text } from '@chakra-ui/react';
import { Route } from 'react-router-dom';
import { assertNever } from '../../util';
import {
    ConversationContainerBaseConfig,
    ConversationControllerBaseConfig,
    ConversationLoaderBaseConfig,
    ConversationViewBaseConfig,
} from './base';
import {
    createConversationThreadContainer,
    createConversationThreadController,
    createConversationThreadLoader,
    createConversationThreadView,
} from './detail';
import {
    createConversationLayoutContainer,
    createConversationLayoutController,
    createConversationLayoutLoader,
    createConversationLayoutView,
    createConversationLayoutStateProvider,
} from './layout';
import { ConversationRouteConfig, ConversationRouteInit } from './conversationConfig';
import { ConversationRoute, ConversationRouteEnhancer } from './conversationInterface';
import { AiOutlineWarning } from 'react-icons/ai';
import {
    createConversationNewContainer,
    createConversationNewController,
    createConversationNewLoader,
    createConversationNewView,
} from './new';

export function configureConversationRouter(
    config: ConversationRouteInit,
    enhancer?: ConversationRouteEnhancer
): ConversationRoute {
    return createConversationRouter(
        {
            ...config,
            strategy: {
                Agent(props) {
                    return (
                        <HStack
                            color="red.400"
                            spacing={3}
                            borderWidth={2}
                            borderColor="red.400"
                            borderRadius="lg"
                            p={6}
                        >
                            <Icon fontSize="lg" as={AiOutlineWarning} />
                            <Text>agent type '{props.type}' not implemented</Text>
                        </HStack>
                    );
                },
                Block(props) {
                    return (
                        <HStack
                            color="red.400"
                            spacing={3}
                            borderWidth={2}
                            borderColor="red.400"
                            borderRadius="lg"
                            p={6}
                        >
                            <Icon fontSize="lg" as={AiOutlineWarning} />
                            <Text>block type '{props.type}' not implemented</Text>
                        </HStack>
                    );
                },
                parser: {
                    parse(props) {
                        if (props.contentType === 'text') {
                            return {
                                type: 'text',
                                data: {},
                            };
                        }
                        if (props.contentType === 'json') {
                            return {
                                type: 'json',
                                data: {},
                            };
                        }
                        assertNever(props.contentType);
                    },
                },
            },
        },
        enhancer
    );
}

function createConversationRouter(
    config: ConversationRouteConfig,
    enhancer?: ConversationRouteEnhancer
): ConversationRoute {
    if (enhancer) {
        return enhancer(createConversationRouter)(config);
    }

    const containerConfig: ConversationContainerBaseConfig = {
        repository: config.repository,
        infra: config.infra,
        context: {
            useContext() {
                return {
                    auth: config.hook.useAuth(),
                };
            },
        },
    };

    const loaderConfig: ConversationLoaderBaseConfig = {
        repository: config.repository,
    };

    const controllerConfig: ConversationControllerBaseConfig = {
        strategy: config.strategy,
        provider: config.provider,
    };

    const viewConfig: ConversationViewBaseConfig = {
        UI: config.UI,
        strategy: config.strategy,
        Layout: createConversationLayoutContainer(
            containerConfig,
            createConversationLayoutStateProvider(containerConfig),
            createConversationLayoutLoader(),
            createConversationLayoutController(),
            createConversationLayoutView({
                UI: config.UI,
            })
        ),
    };

    const Thread = {
        Detail: createConversationThreadContainer(
            containerConfig,
            createConversationThreadLoader(loaderConfig),
            createConversationThreadController(controllerConfig),
            createConversationThreadView(viewConfig)
        ),
        New: createConversationNewContainer(
            containerConfig,
            createConversationNewLoader(loaderConfig),
            createConversationNewController(controllerConfig),
            createConversationNewView(viewConfig)
        ),
    };

    return {
        element: (
            <Route path={config.mount}>
                <Route path="new" element={<Thread.New />} />
                <Route path=":threadId" element={<Thread.Detail />} />
            </Route>
        ),
    };
}

import { useMemo } from 'react';
import { assert } from '../../../../util/assert';
import { resolveVisualizationView } from '../../../../domain/visualization';
import { QueryRequest, resolveView } from '../../../../domain/query';
import {
    ReportDetailRouteOpportunityVisualizationLoader,
    ReportDetailRouteOpportunityVisualizationData,
} from './visualizationInterface';
import { ReportDetailRouteConfig } from '../reportDetailConfig';
import { isReferenceProperty } from '../../../../domain/attributes';

export function createReportDetailRouteOpportunityVisualizationLoader(
    config: Pick<ReportDetailRouteConfig, 'loader' | 'strategy' | 'repository'>
): ReportDetailRouteOpportunityVisualizationLoader {
    const { repository, strategy } = config;
    return {
        useLoad(context, props): ReportDetailRouteOpportunityVisualizationData {
            const views = repository.view.useFind(
                context,
                {},
                {
                    staleTime: Infinity,
                }
            );
            const plugins = repository.plugin.useFind(
                context,
                {},
                { suspense: true, staleTime: Infinity }
            );
            const peergroups = repository.peergroup.useFind(
                context,
                {
                    workspace: props.asset,
                    plugins: plugins.data?.map((item) => item.id) ?? [],
                },
                { suspense: true, staleTime: Infinity }
            );

            const view = useMemo(
                () => resolveVisualizationView(views.data ?? [], props.visualization),
                [views.data, props.visualization]
            );

            const members = repository.member.useFind(
                context,
                {
                    workspace: props.asset,
                    types:
                        view?.columns
                            .filter((item) =>
                                props.visualization.breakdown?.properties.some(
                                    (candidate) => candidate.key === item.key
                                )
                            )
                            .flatMap((item) =>
                                isReferenceProperty(item) ? [item.type] : []
                            ) ?? [],
                },
                { suspense: true, staleTime: Infinity }
            );

            assert(view, 'no view found');

            const resolvedView = useMemo(() => {
                return resolveView(view, members.data ?? []);
            }, [view, members.data]);

            // console.log('resolvedView', resolvedView);

            const peergroup = useMemo(
                () =>
                    view
                        ? peergroups.data?.find(
                              (item) => item.plugin.id === view.plugin
                          ) ?? null
                        : null,
                [view, peergroups.data]
            );

            const query = useMemo<QueryRequest>(
                () => ({
                    ...strategy.queryable.toQuery(
                        { segment: peergroup?.conditions ?? [] },
                        view,
                        props.visualization
                    ),
                    segment: peergroup?.conditions ?? [],
                }),
                [view, props.visualization, peergroup?.conditions]
            );

            const response = repository.query.response.useFind(
                context,
                {
                    request: query,
                    asset: props.asset,
                },
                {
                    retry: false,
                    suspense: true,
                    staleTime: Infinity,
                }
            );

            const result = useMemo(() => {
                return props.visualization && response.data
                    ? strategy.resultable.create(
                          resolvedView,
                          props.visualization,
                          response.data
                      )
                    : null;
            }, [props.visualization, response.data]);

            if (response.error instanceof Error) {
                return {
                    item: {
                        status: 'error',
                        error: response.error,
                        data: null,
                    },
                };
            }

            if (!result || response.status !== 'success') {
                return {
                    item: {
                        status: 'loading',
                        data: null,
                    },
                };
            }

            return {
                item: {
                    status: 'loaded',
                    data: {
                        visualization: props.visualization,
                        view,
                        result: result,
                    },
                },
            };
        },
    };
}

import { Link, Box, Text } from '@chakra-ui/react';
import React from 'react';
import { Link as RRDLink } from 'react-router-dom';

const ThankYouMessage: React.FC<{ message: string; linkTxt?: string; link?: string }> = ({
    message,
    link,
    linkTxt,
}) => {
    return (
        <Box>
            <Text marginBottom={6}>{message}</Text>
            {link && linkTxt && (
                <Link as={RRDLink} to={link}>
                    {linkTxt}
                </Link>
            )}
        </Box>
    );
};
export default ThankYouMessage;

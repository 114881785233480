import {
    Alert,
    AlertDescription,
    AlertIcon,
    Box,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Grid,
    GridItem,
    HStack,
    Icon,
    Input,
    InputGroup,
    StackDivider,
    Text,
    VStack,
} from '@chakra-ui/react';
import React from 'react';
import { AiOutlinePlus } from 'react-icons/ai';
import { HiOutlineCube } from 'react-icons/hi';
import { SettingAccountItemProviderConfig } from '../../../../../route';
import { SettingAssetNewViewProps } from './newProps';

export function createSettingAssetNewView(
    config: SettingAccountItemProviderConfig
): React.FC<SettingAssetNewViewProps> {
    const {
        UI: {
            Application: { Link, Heading, Button },
        },
        Layout: {
            Container,
            Content,
            Panel,
            Form,
            Header: {
                Action: { List: ActionList },
                Container: Header,
                Breadcrumbs: { List: BreadcrumbsList, Item: BreadcrumbsItem },
            },
        },
    } = config;

    return (props) => {
        const newProps = props.getNewProps();
        const alertProps = newProps.getAlertProps();
        return (
            <form {...props.getFormProps()}>
                <Container>
                    <Header>
                        <BreadcrumbsList>
                            <BreadcrumbsItem>Companies</BreadcrumbsItem>
                            <BreadcrumbsItem>New</BreadcrumbsItem>
                        </BreadcrumbsList>
                    </Header>
                    <Content>
                        <VStack
                            align="start"
                            w="full"
                            py={3}
                            spacing={6}
                            maxW="24rem"
                            divider={
                                <StackDivider
                                    borderWidth={1}
                                    borderColor="whiteAlpha.300"
                                />
                            }
                        >
                            <VStack align="start" w="full" spacing={6}>
                                {alertProps && (
                                    <Alert status="error">
                                        <AlertIcon />
                                        <AlertDescription>
                                            {alertProps.text}
                                        </AlertDescription>
                                    </Alert>
                                )}
                                <FormControl {...newProps.getFormControlProps('name')}>
                                    <FormLabel htmlFor="name">Company name</FormLabel>
                                    <InputGroup>
                                        <Input
                                            {...newProps.getInputProps('name')}
                                            placeholder="Enter company name"
                                        />
                                    </InputGroup>
                                    <FormErrorMessage
                                        {...newProps.getErrorMessageProps('name')}
                                    />
                                </FormControl>
                                <FormControl {...newProps.getFormControlProps('url')}>
                                    <FormLabel htmlFor="url">Website URL</FormLabel>
                                    <InputGroup>
                                        <Input
                                            {...newProps.getInputProps('url')}
                                            placeholder="Enter website URL"
                                        />
                                    </InputGroup>
                                    <FormErrorMessage
                                        {...newProps.getErrorMessageProps('url')}
                                    />
                                </FormControl>
                            </VStack>
                            <VStack w="full" align="start" spacing={3}>
                                <HStack w="full" spacing={3} justify="end">
                                    <Link to="..">
                                        <Button _focus={{ outline: 'none' }}>
                                            Cancel
                                        </Button>
                                    </Link>
                                    <Button
                                        {...newProps.getSubmitButtonProps()}
                                        colorScheme="blue"
                                        _focus={{ outline: 'none' }}
                                        type="submit"
                                    >
                                        Create company
                                    </Button>
                                </HStack>
                                <HStack w="full" justify="end" fontWeight="medium">
                                    <Text color="whiteAlpha.500">
                                        Have issues?{' '}
                                        <Box
                                            // color="whiteAlpha.700"
                                            // fontWeight="semibold"
                                            cursor="pointer"
                                            as="span"
                                            color="blue.300"
                                            _hover={{ textDecor: 'underline' }}
                                            onClick={newProps.getHelpProps().onClick}
                                        >
                                            Contact us
                                        </Box>
                                    </Text>
                                </HStack>
                            </VStack>
                        </VStack>
                    </Content>
                </Container>
            </form>
        );
    };
}

import React, { useMemo } from 'react';
import { WorkspaceContextV2 } from '../../../app';
import { InsightRouteConfigV2 } from './insightRouteConfig';
import { InsightRouteContextV2 } from './insightRouteContext';
import { createInsightRouteControllerV2 } from './insightRouteController';
import { createInsightRouteLoaderV2 } from './insightRouteLoader';
import { createInsightRouteViewV2 } from './insightRouteView';

export function createInsightRouteV2(
    config: InsightRouteConfigV2
): React.FC<React.PropsWithChildren<{}>> {
    const {
        deps: { hook, ...deps },
    } = config;
    const controller = createInsightRouteControllerV2(config);
    const loader = createInsightRouteLoaderV2(config.deps);
    const View = createInsightRouteViewV2(config);
    return deps.state.connect((props) => {
        const organization = hook.useOrganization();
        const workspace = hook.useWorkspace();
        const auth = hook.useAuth();
        const state = deps.state.useState();
        const context: WorkspaceContextV2 = {
            organization,
            workspace,
            auth,
        };
        const viewData = loader.useLoad(context, state.value);
        const viewProps = controller.useProps(context, state, viewData, {});
        return (
            <InsightRouteContextV2.Provider value={context}>
                <View {...viewProps}>{props.children}</View>
            </InsightRouteContextV2.Provider>
        );
    });
}

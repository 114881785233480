import { VisualizationApi } from './visualizationInterface';
import { VisualizationApiConfig } from './visualizationConfig';
import { createVisualizationResource } from './visualization/visualizationResource';

export function createVisualizationApi(config: VisualizationApiConfig): VisualizationApi {
    const { client } = config;
    return {
        visualization: createVisualizationResource(client),
    };
}

import { Box, BoxProps, Button, HStack, Image, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import { Graphics } from '../../../config/svg';
import { ConversationViewBaseConfig } from '../base';
import { ConversationNewViewProps } from './conversationNewProps';

export function createConversationNewView(
    config: ConversationViewBaseConfig
): React.FC<ConversationNewViewProps> {
    const { Layout } = config;
    const containerStyle = {
        maxW: '42rem',
    } satisfies BoxProps;

    return (props) => {
        return (
            <form
                style={{ width: '100%', height: '100%' }}
                {...props.form.getFormElementProps()}
            >
                <Layout title={undefined}>
                    <HStack w="full" justify="center" p={6}>
                        <VStack
                            align="center"
                            w="full"
                            justify="center"
                            maxW={containerStyle.maxW}
                            spacing={8}
                            p={6}
                        >
                            <Box
                                p={4}
                                borderWidth={2}
                                borderColor="whiteAlpha.300"
                                color="whiteAlpha.600"
                                borderRadius="full"
                            >
                                <Image
                                    position="relative"
                                    left={-0.5}
                                    top={0.5}
                                    w={4}
                                    h={4}
                                    src={Graphics.Brand.Icon}
                                />
                            </Box>
                            <VStack
                                align="center"
                                textAlign="center"
                                w="full"
                                spacing={2}
                                maxW="24rem"
                            >
                                <Text fontSize="xl" fontWeight="medium">
                                    Start a conversation
                                </Text>
                                <Text fontSize="md" color="whiteAlpha.700">
                                    Culpa tempor non occaecat sit do id amet sint sint et
                                    mollit ut occaecat aliquip
                                </Text>
                            </VStack>
                            <Button
                                {...props.form.getSubmitButtonProps()}
                                size="md"
                                colorScheme="green"
                                _focus={{ outline: 'none' }}
                            >
                                Start interview
                            </Button>
                        </VStack>
                    </HStack>
                </Layout>
            </form>
        );
    };
}

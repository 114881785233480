import { HStack, Tag, Text } from '@chakra-ui/react';
import React from 'react';
import { AiFillBulb, AiFillBell } from 'react-icons/ai';
import { FiBell } from 'react-icons/fi';
import { WorkspaceLayoutDropdownProps } from '../../../layout';
import { InsightViewProps } from '../../../view';
import { WorkspaceInsightContainerConfig } from './workspaceInsightConfig';

export const INSIGHT_WORKSPACE_ITEM_ID = 'insights';

export function createWorkspaceInsightContainer(
    config: WorkspaceInsightContainerConfig
): WorkspaceLayoutDropdownProps<InsightViewProps> {
    const { controller, loader, Component, hooks } = config;
    return {
        id: INSIGHT_WORKSPACE_ITEM_ID,
        style: {
            body: {
                overflowY: 'scroll',
                maxH: '33vh',
            },
        },
        render(props) {
            return <Component {...props} />;
        },
        useProps(input) {
            const workspace = hooks.useWorkspace();
            const organization = hooks.useOrganization();
            const auth = hooks.useAuth();
            const formatter = hooks.useFormatter();

            const viewData = loader.useData(
                {
                    organization,
                    workspace,
                    auth,
                },
                {
                    query: {
                        category: ['alert'],
                        limit: 25,
                    },
                }
            );
            const viewProps = controller.useProps(
                {
                    organization,
                    workspace,
                    auth,
                },
                formatter,
                viewData,
                {}
            );
            return {
                name: 'Alerts',
                label: (
                    <HStack alignItems="center">
                        <Text>Alerts</Text>
                        <Tag fontWeight="medium" bg="whiteAlpha.100" color="#c6e74f">
                            Beta
                        </Tag>
                    </HStack>
                ),
                // Icon: AiFillBulb,
                Icon: FiBell,
                props: viewProps,
            };
        },
    };
}

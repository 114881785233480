import React from 'react';
import { UserListViewProps } from './userListProps';

import { UserListConfigControllerConfig } from './userListConfig';
import { UserListController } from './userListInerface';
import { useDisclosure } from '@chakra-ui/react';
import { LoadedUserDto } from '../../../../../v2/api/platform';

export function createUserListController(
    init: UserListConfigControllerConfig
): UserListController {
    const {
        service: {
            user: { useList, useRemove }
        },
    } = init;
    return {
        useProps(context, props): UserListViewProps {
            const usersList = useList(context, {
                page_size: 500,
            });
            const [isRemoveError, setRemoveError] = React.useState<boolean>(false);
            const removeUser = useRemove(context);
            const { //isOpen, onOpen,
                
                onClose } = useDisclosure()
            const [userToDelete, setUserToDelete] = React.useState<LoadedUserDto>();
            return {
                // getChildProps(workspace, index) {
                // return {
                // onEditWorkspace(dto) {
                //     setWorkspaceInEdit(dto);
                //     modalDisclosure.onOpen();
                // },
                // editProps: workspaceInEdit
                //     ? {
                //           ...modalDisclosure,
                //           workspace: workspaceInEdit,
                //           onSubmit: (values) => {
                //               return updateWorkspace(values);
                //           },
                //       }
                //     : null,
                removal: {
                    cancel: () => {
                        setRemoveError(false);
                        setUserToDelete(undefined);
                        onClose();
                    },
                    set: setUserToDelete,
                    userToDelete,
                    isError: isRemoveError,
                    onDelete: () => userToDelete && removeUser(userToDelete.id).then(() => {
                        setUserToDelete(undefined);
                        setRemoveError(false);
                        onClose();
                    }).catch(() => setRemoveError(true))
                },
                users: {...usersList, data: usersList.data.map(x => ({...x, isDeleteAllowed: props.user.isAdmin && props.user.id !== x.id }))},
            };
        },
    };
}

import { Box, Center, Heading, Image, Text, Flex, VStack } from '@chakra-ui/react';
import warningSVG from '../../../svg/warning-big.svg';
import React from 'react';

const COLORS: { [key in CardEmptyStateLevel]: string | null } = {
    info: null,
    warn: 'yellow.500',
    error: 'red.500',
};

export type CardEmptyStateLevel = 'info' | 'warn' | 'error';

export interface CardEmptyStateProps {
    level?: CardEmptyStateLevel;
    title: string;
    hint?: string;
    action?: React.ReactElement;
}

export const CardEmptyState: React.FC<CardEmptyStateProps> = ({
    level = 'info',
    ...props
}) => {
    const textColor = COLORS[level] ?? undefined;
    return (
        <Flex height="100%" justifyContent="center" alignItems="center">
            <VStack spacing={4}>
                {level === 'info' && (
                    <Center>
                        <Image src={warningSVG} />
                    </Center>
                )}
                <Heading fontSize="large" color={textColor}>
                    {props.title}
                </Heading>
                {props.hint && (
                    <Text fontSize="sm" fontWeight="bold" color={textColor}>
                        {props.hint}
                    </Text>
                )}
                {props.action && <Box>{props.action}</Box>}
            </VStack>
        </Flex>
    );
};

import React from 'react';
import { z } from 'zod';
import { createConversationPlugin } from '../../../../route';
import { ConversationEntityConfig } from './blockEntityConfig';
import { createConversationEntityContainer } from './blockEntityContainer';
import { createConversationSurveyController } from './blockEntityController';
import { ConversationEntityContainerProps } from './blockEntityProps';
import { createConversationEntityView } from './blockEntityView';

export function createConversationEntityPlugin(init: ConversationEntityConfig) {
    return createConversationPlugin({
        type: 'entity',
        schema: z
            .object({
                id: z.string(),
                kind: z.union([z.literal('survey'), z.literal('survey')]),
            })
            .transform(
                (item): ConversationEntityContainerProps => ({
                    item: {
                        entity: {
                            id: item.id,
                            kind: item.kind,
                            name: '2024-10-10 submission',
                        },
                    },
                })
            )
            .pipe(
                z.object({
                    item: z.object({
                        entity: z.object({
                            id: z.string(),
                            kind: z.union([z.literal('survey'), z.literal('survey')]),
                            name: z.string(),
                        }),
                    }),
                })
            ),
        create(config) {
            const Component = createConversationEntityContainer(
                config,
                createConversationSurveyController(config),
                createConversationEntityView(config)
            );
            return {
                Component(props) {
                    return <Component {...props.data} />;
                },
            };
        },
    });
}

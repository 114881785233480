import {
    Box,
    Link as ChakraLink,
    Grid,
    GridItem,
    HStack,
    Icon,
    Image,
    Input,
    InputGroup,
    InputLeftElement,
    InputRightElement,
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverContent,
    PopoverTrigger,
    Spinner,
    StackDivider,
    Tag,
    Text,
    Tooltip,
    VStack,
    SimpleGrid,
    Select,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Menu,
    MenuButton,
    IconButton,
    MenuList,
    MenuItem,
    Alert,
    AlertTitle,
    AlertIcon,
} from '@chakra-ui/react';
import React from 'react';
import { AiOutlineInfoCircle, AiOutlinePlus } from 'react-icons/ai';
import { HiOutlineCube, HiOutlineDotsHorizontal } from 'react-icons/hi';
import { MdSubdirectoryArrowRight } from 'react-icons/md';
import { SettingAccountItemProviderConfig } from '../../../../../route';
import { AttributeItemProps, AttributeListProps } from '../../../../../view/common';
import { GRAY } from '../../../../../config';
import {
    SettingIntegrationDetailViewProps,
    SettingIntegrationMappingProps,
} from './integrationDetailProps';
import { SettingIntegrationItemContainerProps } from '../item';
import { createSettingMappingNewView } from '../mapping';

export function createSettingIntegrationDetailView(
    config: SettingAccountItemProviderConfig
): React.FC<SettingIntegrationDetailViewProps> {
    const {
        UI: {
            Application: { Link, Heading, Button },
        },
        Layout: {
            Container,
            Content,
            Header: {
                Container: Header,
                Breadcrumbs: { List: BreadcrumbsList, Item: BreadcrumbsItem },
                Action: { List: ActionList },
            },
        },
    } = config;

    const MappingView = createSettingMappingNewView();

    const AssetIcon = HiOutlineCube;
    const ConnectedIcon = MdSubdirectoryArrowRight;
    const MenuIcon = HiOutlineDotsHorizontal;

    const KeywordItem: React.FC<AttributeItemProps> = (props) => {
        return (
            <VStack align="start" spacing={1}>
                <Text
                    letterSpacing="wider"
                    color="whiteAlpha.600"
                    textTransform="uppercase"
                    fontSize="xs"
                    fontWeight="bold"
                >
                    {props.label}
                </Text>
                {props.children}
            </VStack>
        );
    };

    const KeywordList: React.FC<AttributeListProps> = (props) => {
        return (
            <HStack
                w="full"
                spacing={6}
                divider={<StackDivider borderWidth={1} borderColor="whiteAlpha.200" />}
                align="start"
            >
                {props.children}
            </HStack>
        );
    };

    return (props) => {
        return (
            <>
                <Container>
                    <Header>
                        <BreadcrumbsList>
                            <BreadcrumbsItem to="..">Integrations</BreadcrumbsItem>
                            <BreadcrumbsItem>{props.integration.label}</BreadcrumbsItem>
                        </BreadcrumbsList>
                    </Header>
                    <Content>
                        {props.page.alert && (
                            <Alert status="warning">
                                <AlertIcon />
                                <AlertTitle>{props.page.alert.label}</AlertTitle>
                            </Alert>
                        )}
                        <VStack align="start" w="full" spacing={12}>
                            {/* details */}
                            <VStack
                                align="start"
                                w="full"
                                spacing={4}
                                divider={
                                    <StackDivider
                                        borderColor="whiteAlpha.300"
                                        borderWidth={1}
                                    />
                                }
                            >
                                <Heading fontSize="xl" fontWeight="semibold">
                                    Details
                                </Heading>
                                <KeywordList>
                                    <KeywordItem label="Integration type">
                                        <HStack spacing={3} align="center">
                                            <Image
                                                {...props.definition.logo}
                                                h={4}
                                                w={4}
                                            />
                                            <Text fontWeight="medium">
                                                {props.definition.label}
                                            </Text>
                                        </HStack>
                                    </KeywordItem>
                                    <KeywordItem label="Created">
                                        <Tooltip
                                            shouldWrapChildren={true}
                                            placement="top"
                                            hasArrow={true}
                                            p={3}
                                            bg="#1D1C21"
                                            label={
                                                props.integration.createdAt.description
                                            }
                                        >
                                            <Text fontWeight="medium" cursor="default">
                                                {props.integration.createdAt.relative}
                                            </Text>
                                        </Tooltip>
                                    </KeywordItem>
                                    <KeywordItem label="Last synced">
                                        {props.integration.syncedAt && (
                                            <Tooltip
                                                shouldWrapChildren={true}
                                                placement="top"
                                                hasArrow={true}
                                                p={3}
                                                bg="#1D1C21"
                                                isDisabled={
                                                    props.integration.syncedAt
                                                        .description === null
                                                }
                                                label={
                                                    props.integration.syncedAt.description
                                                }
                                            >
                                                <Text
                                                    fontWeight="medium"
                                                    cursor="default"
                                                >
                                                    {props.integration.syncedAt.relative}
                                                </Text>
                                            </Tooltip>
                                        )}
                                    </KeywordItem>
                                    <KeywordItem label="Status">
                                        <Tooltip
                                            {...props.integration.status.getTooltipProps()}
                                            placement="top"
                                            hasArrow={true}
                                            p={3}
                                            bg="#1D1C21"
                                        >
                                            <Tag
                                                {...props.integration.status.getTagProps()}
                                                cursor="default"
                                                size="md"
                                            />
                                        </Tooltip>
                                    </KeywordItem>
                                </KeywordList>
                            </VStack>
                            {/* mappings */}
                            <VStack align="start" w="full" spacing={4}>
                                <HStack w="full" justify="space-between">
                                    <HStack spacing={3}>
                                        <Heading fontSize="xl" fontWeight="semibold">
                                            Data sources
                                        </Heading>
                                        <Tooltip
                                            shouldWrapChildren={true}
                                            placement="top"
                                            hasArrow={true}
                                            p={3}
                                            bg="#1D1C21"
                                            label={
                                                <Text>
                                                    Data sources map data in an
                                                    integration to companies in your
                                                    account
                                                </Text>
                                            }
                                        >
                                            <Icon
                                                fontSize="xl"
                                                as={AiOutlineInfoCircle}
                                            />
                                        </Tooltip>
                                    </HStack>
                                    <Button
                                        {...props.modal.triggerProps}
                                        size="sm"
                                        colorScheme="blue"
                                        leftIcon={<Icon as={AiOutlinePlus} />}
                                        _focus={{ outline: 'none' }}
                                    >
                                        New data source
                                    </Button>
                                </HStack>
                                {props.mappings.empty && (
                                    <HStack
                                        w="full"
                                        borderWidth={2}
                                        borderStyle="dashed"
                                        borderColor="whiteAlpha.300"
                                        borderRadius="md"
                                        p={8}
                                        justify="center"
                                    >
                                        <VStack align="start" spacing={1}>
                                            <Text
                                                fontWeight="medium"
                                                color="whiteAlpha.600"
                                            >
                                                {props.mappings.empty.title}
                                            </Text>
                                        </VStack>
                                    </HStack>
                                )}
                                {!props.mappings.empty && (
                                    <VStack
                                        w="full"
                                        borderWidth={2}
                                        borderColor="whiteAlpha.300"
                                        borderRadius="lg"
                                        align="start"
                                        spacing={0}
                                        divider={
                                            <StackDivider
                                                borderColor="whiteAlpha.300"
                                                borderWidth={1}
                                            />
                                        }
                                    >
                                        {props.mappings.collection.map((item) => {
                                            const labelContent = (
                                                <HStack
                                                    cursor="pointer"
                                                    _hover={{
                                                        textDecor: 'underline',
                                                    }}
                                                    spacing={2}
                                                >
                                                    <Icon as={AssetIcon} />
                                                    <Text>{item.asset.label}</Text>
                                                </HStack>
                                            );
                                            return (
                                                <HStack
                                                    key={item.id}
                                                    w="full"
                                                    p={6}
                                                    justify="space-between"
                                                >
                                                    <VStack
                                                        align="start"
                                                        spacing={1}
                                                        fontWeight="medium"
                                                    >
                                                        <HStack spacing={2.5}>
                                                            <Image
                                                                {...props.definition.logo}
                                                                h={4}
                                                                w={4}
                                                            />
                                                            <Text cursor="default">
                                                                {item.entity.label}{' '}
                                                                <Box
                                                                    as="span"
                                                                    color="whiteAlpha.600"
                                                                >
                                                                    ({item.entity.caption}
                                                                    )
                                                                </Box>
                                                            </Text>
                                                        </HStack>
                                                        <HStack spacing={3}>
                                                            <HStack
                                                                spacing={1}
                                                                color="whiteAlpha.600"
                                                                userSelect="none"
                                                            >
                                                                <Icon
                                                                    as={ConnectedIcon}
                                                                />
                                                            </HStack>
                                                            {item.link.asset && (
                                                                <Link
                                                                    {...item.link.asset}
                                                                >
                                                                    {labelContent}
                                                                </Link>
                                                            )}
                                                            {!item.link.asset &&
                                                                labelContent}
                                                        </HStack>
                                                    </VStack>
                                                    <HStack spacing={4}>
                                                        <Tooltip
                                                            {...item.status.getTooltipProps()}
                                                            placement="top"
                                                            hasArrow={true}
                                                            p={3}
                                                            bg="#1D1C21"
                                                        >
                                                            <Tag
                                                                {...item.status.getTagProps()}
                                                                cursor="default"
                                                                size="lg"
                                                            />
                                                        </Tooltip>
                                                        {/* <Menu placement="bottom-end">
                                                        <MenuButton
                                                            as={IconButton}
                                                            icon={<Icon as={MenuIcon} />}
                                                            _focus={{ outline: 'none' }}
                                                        />
                                                        <MenuList bg={GRAY[600]}>
                                                            <MenuItem
                                                                color="red.400"
                                                                onClick={
                                                                    itemProps.action
                                                                        .delete
                                                                }
                                                            >
                                                                Delete
                                                            </MenuItem>
                                                        </MenuList>
                                                    </Menu> */}
                                                    </HStack>
                                                </HStack>
                                            );
                                        })}
                                    </VStack>
                                )}
                            </VStack>
                        </VStack>
                    </Content>
                </Container>
                <Modal {...props.modal.containerProps} isCentered={true}>
                    <ModalOverlay />
                    <ModalContent>
                        <form {...props.mapping.new.form.getFormElementProps()}>
                            <ModalHeader p={4}>
                                <Heading fontSize="md">Add integration mapping</Heading>
                            </ModalHeader>
                            <ModalBody p={4}>
                                <MappingView {...props.mapping.new} />
                            </ModalBody>
                            <ModalFooter p={4}>
                                <HStack w="full" justify="end">
                                    <Button
                                        {...props.modal.closeProps}
                                        size="sm"
                                        _focus={{ outline: 'none' }}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        {...props.mapping.new.form.getSubmitButtonProps()}
                                        size="sm"
                                        colorScheme="green"
                                        _focus={{ outline: 'none' }}
                                    >
                                        Add
                                    </Button>
                                </HStack>
                            </ModalFooter>
                        </form>
                    </ModalContent>
                </Modal>
            </>
        );
    };
}

import { QueryFunction } from '@tanstack/react-query';
import { ApplicationContext } from '../../../base';
import { listPlans, PlanType } from '../../../api';
import { PLAN_DEFAULT_FEATURES } from '../../../config';
import { AnyPlan, BillingUnitKey, CustomPlan, PlanCategory, PlanCategoryKey, PLAN_CATEGORY_NAME_BY_KEY } from './planType';


export const BILLING_UNIT_BY_CATEGORY: {[key in PlanCategoryKey]?: { key: BillingUnitKey, name: string }} = {
    brand: { key: 'avg_monthly_spend', name: 'avg. monthly spend'},
    agency: {key: 'num_of_clients', name: 'num of clients' }
}



export function createListPlansHandler(
    context: ApplicationContext
): QueryFunction<{ categories: PlanCategory[]}> {
    return async () => {
        const response = await listPlans(context.api, { version: 2 });
        
        const enterprise: CustomPlan[] = [{
                id: 'enterprise',
                name: 'Enterprise',
                type: 'agency',
                description:
                    'Dedicated solutions for custom data needs and/or big agencies',
                // caption: `Everything in ${capitalize(pluralize(planType))} plus`,
                default: false,
                currency: 'usd',
                unit_kind: 'custom',
                unit_name: 'client',
                threshold_start: 50,
                add_ons: [],
                // features: features.enterprise.map((feature) => ({
                //     ...feature,
                //     caption: feature.caption ?? null,
                // })),
                custom: true,
            }
        ];
        const categories = Object.values([...response.items, ...enterprise].reduce<Record<PlanCategoryKey, PlanCategory>>((agg, curr) => {
                const categoryKey: PlanCategoryKey = curr.default ? 'free' : curr.type;
                const category: PlanCategory = agg[categoryKey] || {
                    key: categoryKey,
                    name: PLAN_CATEGORY_NAME_BY_KEY[categoryKey],
                    caption: null,
                    features: PLAN_DEFAULT_FEATURES[categoryKey],
                    plans: [],
                    fromAmount: curr.amount ?? 0,
                    currency: curr.currency,
                    billingUnit: BILLING_UNIT_BY_CATEGORY[categoryKey] ?? {
                        ...BILLING_UNIT_BY_CATEGORY[categoryKey],
                        threshold_start: curr.threshold_start || 0,
                        threshold_end: null,
                    }

                }
                category.plans.push(curr);
                if(curr.amount) {
                    category.fromAmount = Math.min(category.fromAmount, curr.amount);
                }
                category.plans = category.plans.sort((a, b) => (a.threshold_end ?? Number.MAX_SAFE_INTEGER) - (b.threshold_end ?? Number.MAX_SAFE_INTEGER));
                if(category.billingUnit) {
                    if(curr.threshold_start) {
                        category.billingUnit.thresholdStart = Math.min(category.billingUnit.thresholdStart, curr.threshold_start);
                    }
                    category.billingUnit.thresholdEnd = category.plans[category.plans.length  -1 ]?.threshold_end ?? null;
                }
                agg[categoryKey] = category;
                return agg;
        }, {} as Record<PlanCategoryKey, PlanCategory>));
        return { ...response, categories };
    };
}

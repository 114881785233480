import React from 'react';
import {
    DateFilterFieldsV2,
    DateFilterFieldsV2Props,
} from '../../../../components/AddDatasetQueryFilterForm/TypeFIlters/DateFilterV2';
import { useAuthenticatedContext } from '../../../../container';
import { getDateFilterOptions } from './dateHelpers';
import { FilterDateInternalProps, FilterDateViewProps } from './dateProps';
import { useInternal } from './dateHook';

export const FilterDateView: React.FC<FilterDateViewProps> = (props) => {
    const internalProps = useInternal(props);
    if (!props.enabled) {
        return null;
    }
    return <Internal {...internalProps} />;
};

const Internal: React.FC<FilterDateInternalProps> = ({ ...props }) => {
    const context = useAuthenticatedContext();

    const handleChange: DateFilterFieldsV2Props['onChange'] = (values) => {
        props.onChange({
            period: values.period,
            granularity: values.granularity,
            comparison: values.comparison,
        });
    };

    return (
        <DateFilterFieldsV2
            context={context}
            minDate={props.minDate}
            comparison={props.value.comparison ?? null}
            granularity={props.value.granularity}
            period={props.value.period}
            onChange={handleChange}
            column={{ name: 'Date Range' }}
            getPresets={getDateFilterOptions}
        />
    );
};

import { keyBy } from 'lodash';
import { useMemo } from 'react';
import { getVisualizationName } from '../../../../domain/visualization';
import { assert } from '../../../../util/assert';
import { ReportDetailRouteConfig } from '../reportDetailConfig';
import { ReportDetailRouteOpportunityVisualizationController } from './visualizationInterface';

export function createReportDetailRouteOpportunityVisualizationController(
    config: Pick<ReportDetailRouteConfig, 'controller' | 'strategy'>
): ReportDetailRouteOpportunityVisualizationController {
    const { controller } = config;
    return {
        useProps(context, data, props) {
            assert(data.item.status === 'loaded', 'expected suspense');
            const resultProps = controller.visualization.result.useProps({
                visualization: props.visualization,
                item: data.item.data.result ?? null,
            });

            const columnsByKey = useMemo(
                () => keyBy(data.item.data?.view.columns ?? [], (item) => item.key),
                [data.item.data.view]
            );

            const label = useMemo(
                () => getVisualizationName(null, columnsByKey, props.visualization),
                [columnsByKey, props.visualization]
            );

            return {
                label,
                date: {
                    range: {
                        label: 'ad',
                    },
                },
                resultProps: resultProps,
            };
        },
    };
}

import {
    AnyCondition,
    DatePreviousCondition,
    DateBetweenCondition,
    BetweenCondition,
    InCondition,
    EqCondition,
    GreaterThanCondition,
    GreaterThanOrEqualCondition,
    LessThanCondition,
    LessThanOrEqualCondition,
} from './conditionModel';

export function isPreviousCondition(
    condition: AnyCondition
): condition is DatePreviousCondition {
    return condition.operator === 'previous';
}

export function isBetweenCondition(
    condition: AnyCondition
): condition is DateBetweenCondition {
    return condition.operator === 'between';
}

export function isNumericBetweenCondition(
    condition: AnyCondition
): condition is BetweenCondition<number> {
    return condition.operator === 'between' && typeof condition.value.from === 'number';
}

export function isPartialNumericBetweenCondition(
    condition: AnyCondition
): condition is BetweenCondition<number> {
    return (
        condition.operator === 'between' &&
        typeof condition.value.from === 'number' &&
        condition.value === null
    );
}

export function isDateBetweenCondition(
    condition: AnyCondition
): condition is DateBetweenCondition {
    return (
        condition.operator === 'between' &&
        condition.value.from instanceof Date &&
        condition.value.to instanceof Date
    );
}

export function isEqualCondition(condition: AnyCondition): condition is EqCondition {
    return condition.operator === 'eq';
}

export function isLessThanCondition(
    condition: AnyCondition
): condition is LessThanCondition {
    return condition.operator === 'lt';
}

export function isLessThanOrEqualCondition(
    condition: AnyCondition
): condition is LessThanOrEqualCondition {
    return condition.operator === 'lte';
}

export function isGreaterThanCondition(
    condition: AnyCondition
): condition is GreaterThanCondition {
    return condition.operator === 'gt';
}

export function isGreaterThanOrEqualCondition(
    condition: AnyCondition
): condition is GreaterThanOrEqualCondition {
    return condition.operator === 'gte';
}

export function isInCondition(condition: AnyCondition | null): condition is InCondition {
    return condition?.operator === 'in' ?? false;
}

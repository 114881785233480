import { OrganizationRouteController } from '../../route';
import { ApplicationEntryEnhancer } from '../../entrypoint';
import { useOrganizationContextV2 } from '../../context';
import { useUserApi } from '../../../v2';
import { Organization, AccountAction, WorkspaceReference } from '../../domain';

function getPendingActionsRoute(
    organization: Organization,
    currentWorkspace: WorkspaceReference
): {
    route: string;
    act: AccountAction;
} | null {
    if (organization.pendingActions) {
        for (const accAct of organization.pendingActions) {
            if (accAct.scope === 'organization') {
                if (accAct.spec.kind == 'ingestion_survey') {
                    return {
                        route: `/u/assets/${currentWorkspace.id}/reporting/reports/${accAct.spec.survey_ids[0]}`,
                        act: accAct,
                    };
                } else if (accAct.spec.kind === 'ingestion_integration') {
                    //todo: redirect to integrations here with forcing to integrate the given integrations
                    console.log(
                        'ingestion integration redirect is requested but not supported yet'
                    );
                }
            } else if (accAct.scope.kind === 'assets') {
                if (accAct.scope.asset_ids.some((x) => x === currentWorkspace.id)) {
                    if (accAct.spec.kind === 'ingestion_survey') {
                        return {
                            route: `/u/assets/${currentWorkspace.id}/reporting/reports/${accAct.spec.survey_ids[0]}`,
                            act: accAct,
                        };
                    } else if (accAct.spec.kind === 'ingestion_integration') {
                        //todo: redirect to integrations here with forcing to integrate the given integrations
                        console.log(
                            'ingestion integration redirect is requested but not supported yet'
                        );
                    }
                }
            }
        }
    }
    return null;
}

export function createAccountActionsEnhancer(): ApplicationEntryEnhancer {
    return (create) => (config) => {
        function enhanceController(
            controller: OrganizationRouteController
        ): OrganizationRouteController {
            return {
                ...controller,
                useProps(...args) {
                    const viewProps = controller.useProps(...args);
                    const {
                        data: { organization },
                    } = useOrganizationContextV2();
                    const userApi = useUserApi();
                    const currentWorkspace = userApi.getCurrentWorkspace();
                    if (!currentWorkspace) {
                        return viewProps;
                    }
                    const pendingAction = getPendingActionsRoute(
                        organization,
                        currentWorkspace
                    );
                    if (pendingAction) {
                        const { route: pendingActionUrl, act } = pendingAction;
                        return {
                            ...viewProps,
                            workspaceUrl: pendingActionUrl,
                            dashboardUrl: null,
                            homeUrl: pendingActionUrl,
                            creativeCoOpUrl: null,
                            researchUrl: null,
                            insightsUrl: null,
                            reportsUrl:
                                act.spec.kind === 'ingestion_survey'
                                    ? pendingActionUrl
                                    : null,
                            peersUrl: null,
                            expertCallUrl: null,
                            integrationsUrl:
                                act.spec.kind === 'ingestion_integration'
                                    ? viewProps.integrationsUrl
                                    : null,
                            links: {
                                ...viewProps.links,
                                newIntegration:
                                    act.spec.kind === 'ingestion_integration'
                                        ? viewProps.links.newIntegration
                                        : null,
                            },
                        };
                    }
                    return viewProps;
                },
            };
        }

        return create({
            ...config,
            redirect: {
                ...config.redirect,
                createRoot(rootConfig) {
                    return config.redirect.createRoot({
                        ...rootConfig,
                        provider: {
                            createController() {
                                const underlyingController =
                                    rootConfig.provider.createController();
                                return {
                                    useProps(controllerProps) {
                                        if (controllerProps.workspace) {
                                            const pendingAction = getPendingActionsRoute(
                                                controllerProps.organization,
                                                controllerProps.workspace
                                            );
                                            if (pendingAction) {
                                                return {
                                                    to: pendingAction.route,
                                                };
                                            }
                                        }
                                        return underlyingController.useProps(
                                            controllerProps
                                        );
                                    },
                                };
                            },
                        },
                    });
                },
            },
            router: {
                ...config.router,
                createReporting(reportingConfig) {
                    return config.router.createReporting({
                        ...reportingConfig,
                        provider: {
                            ...reportingConfig.provider,
                            createLayoutController: (layoutConfig) => {
                                const underlyingController =
                                    reportingConfig.provider.createLayoutController(
                                        layoutConfig
                                    );
                                return {
                                    useProps(context, props) {
                                        const {
                                            data: { organization },
                                        } = useOrganizationContextV2();
                                        const pendingRoute = getPendingActionsRoute(
                                            organization,
                                            context.workspace
                                        );
                                        if (
                                            pendingRoute?.act.spec.kind ===
                                            'ingestion_survey'
                                        ) {
                                            return underlyingController.useProps(
                                                context,
                                                {
                                                    ...props,
                                                    showSidebar: false,
                                                }
                                            );
                                        }
                                        return underlyingController.useProps(
                                            context,
                                            props
                                        );
                                    },
                                };
                            },
                        },
                    });
                },
            },
            route: {
                ...config.route,
                createOrganizationRoute(routeConfig) {
                    return config.route.createOrganizationRoute({
                        ...routeConfig,
                        providers: {
                            ...routeConfig.providers,
                            createController(...args) {
                                const controller = routeConfig.providers.createController(
                                    ...args
                                );
                                return enhanceController(controller);
                            },
                        },
                    });
                },
                createOrganizationWorkspaceRoute(routeConfig) {
                    return config.route.createOrganizationRoute({
                        ...routeConfig,
                        providers: {
                            ...routeConfig.providers,
                            createController(...args) {
                                const controller = routeConfig.providers.createController(
                                    ...args
                                );
                                return enhanceController(controller);
                            },
                        },
                    });
                },
            },
            controller: {
                ...config.controller,
                settings: {
                    ...config.controller.settings,
                    createOrganization() {
                        const controller =
                            config.controller.settings.createOrganization();
                        return {
                            useProps() {
                                const props = controller.useProps();
                                const {
                                    data: { organization },
                                } = useOrganizationContextV2();
                                const userApi = useUserApi();
                                const currentWorkspace = userApi.getCurrentWorkspace();
                                if (!currentWorkspace) {
                                    return props;
                                }
                                const pendingRoute = getPendingActionsRoute(
                                    organization,
                                    currentWorkspace
                                );
                                if (pendingRoute) {
                                    const allowdRouteKeys = new Set<string>([
                                        'companies',
                                        'permissions',
                                        'users',
                                        'subscription',
                                        'payment',
                                    ]);
                                    if (
                                        pendingRoute.act.spec.kind ===
                                        'ingestion_integration'
                                    ) {
                                        allowdRouteKeys.add('integrations');
                                    }
                                    return {
                                        ...props,
                                        isVisible(item) {
                                            if (allowdRouteKeys.has(item.key)) {
                                                return props.isVisible(item);
                                            }
                                            return false;
                                        },
                                    };
                                }
                                return props;
                            },
                        };
                    },
                },
            },
        });
    };
}

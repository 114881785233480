import { Box, Heading, SimpleGrid, StackProps, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import { IntegrationTheme } from '../../integrationTheme';

export interface IntegrationCardGroupProps {
    containerProps?: Pick<StackProps, 'spacing' | 'width'>;
    title?: string;
    description?: React.ReactNode;
    theme?: IntegrationTheme;
}

export const IntegrationCardListGroup: React.FC<IntegrationCardGroupProps> = ({
    ...props
}) => {
    if (!props.title && !props.description) {
        return <>{props.children}</>;
    }
    return (
        <VStack align="flex-start" width="100%" {...props.containerProps}>
            <Box display="flex" alignItems="flex-start" flexDirection="column">
                {props.title && <Heading size="md">{props.title}</Heading>}
                {props.description && (
                    <Text mt={1} fontSize="md" color={props.theme?.subTitleColor}>
                        {props.description}
                    </Text>
                )}
            </Box>
            {props.children}
        </VStack>
    );
};

import {
    Box,
    Button,
    Center,
    Grid,
    GridItem,
    Heading,
    HStack,
    Image,
    Input,
    Popover,
    PopoverBody,
    PopoverContent,
    PopoverHeader,
    PopoverTrigger,
    StackDivider,
    Text,
    useDisclosure,
} from '@chakra-ui/react';
import React, { Suspense } from 'react';
import { CardSpinner } from '../../../../domain';
import { AnalysisRouteDeps } from './analysisRouteConfig';
import { AnalysisContainers, AnalysisViews } from './analysisRouteInterface';
import { AnalysisRouteViewProps } from './analysisRouteProps';

export function createAnalysisRouteView(
    config: AnalysisRouteDeps,
    Views: AnalysisViews,
    Components: AnalysisContainers
): React.FC<AnalysisRouteViewProps> {
    const { Layout } = config;
    const { Content, Breakdown, CompetitiveSet, Segment, Filter, Date, Ranking } =
        Components;
    const { Select } = Views;

    return (props) => {
        const disclosure = useDisclosure();
        // const selectProps = props.getSelectionProps();
        return (
            <Layout.Wrapper
                isVisible={() => true}
                disclosure={disclosure}
                stickyControls={false}
                showGroups={false}
                mode="dynamic"
                onModeChange={console.log}
            >
                <Layout.BackButton to="../../home">Go back</Layout.BackButton>
                <Layout.CompetitiveSet>
                    <CompetitiveSet {...props.getCompetitiveSetProps()} />
                </Layout.CompetitiveSet>
                <Layout.Segment>
                    <Segment {...props.getSegmentProps()} />
                </Layout.Segment>
                <Layout.Control.List>
                    {/* <Layout.Control.Item id="ranking">
                        <Container.Ranking {...props.getRankingProps()} />
                    </Layout.Control.Item> */}
                </Layout.Control.List>
                <Layout.Group>
                    <Text>Not impl</Text>
                </Layout.Group>
                <Layout.Content>
                    <Suspense
                        fallback={
                            <Center p={8}>
                                <CardSpinner />
                            </Center>
                        }
                    >
                        <Grid templateRows="min-content 1fr" gap={8}>
                            <GridItem>
                                <HStack justify="space-between" spacing={8}>
                                    <Filter {...props.getFilterProps()} />
                                    <HStack
                                        justify={{
                                            sm: 'space-between',
                                            lg: 'flex-end',
                                        }}
                                        spacing={3}
                                        divider={
                                            <StackDivider
                                                css={{
                                                    marginTop: '0.5rem !important',
                                                    marginBottom: '0.5rem !important',
                                                }}
                                                borderColor={{
                                                    sm: 'transparent',
                                                    lg: 'whiteAlpha.300',
                                                }}
                                                borderWidth="1px"
                                            />
                                        }
                                    >
                                        {/* <Popover>
                                            <PopoverTrigger>
                                                <HStack spacing={3}>
                                                    <Text
                                                        textTransform="uppercase"
                                                        fontSize="sm"
                                                        color="whiteAlpha.700"
                                                        letterSpacing="wide"
                                                        fontWeight="bold"
                                                    >
                                                        Breakdown
                                                    </Text>
                                                    <Button size="sm">None</Button>
                                                </HStack>
                                            </PopoverTrigger>
                                            <PopoverContent _focus={{ outline: 'none' }}>
                                                <PopoverHeader>
                                                    <Heading
                                                        fontSize="md"
                                                        fontWeight="medium"
                                                    >
                                                        Breakdown
                                                    </Heading>
                                                </PopoverHeader>
                                                <PopoverBody>
                                                    <Breakdown
                                                        {...props.getRankingProps()}
                                                    />
                                                </PopoverBody>
                                            </PopoverContent>
                                        </Popover> */}
                                        <Ranking {...props.getRankingProps()} />
                                        <Date {...props.getDateProps()} />
                                    </HStack>
                                </HStack>
                            </GridItem>
                            <GridItem>
                                <Suspense
                                    fallback={
                                        <Center p={8}>
                                            <CardSpinner />
                                        </Center>
                                    }
                                >
                                    <Content {...props.getContentProps()} />
                                </Suspense>
                            </GridItem>
                        </Grid>
                    </Suspense>
                </Layout.Content>
            </Layout.Wrapper>
        );
    };
}

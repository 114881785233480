import { useMutation } from '@tanstack/react-query';
import React, { useMemo } from 'react';
import { keyBySafe } from '../../../../util';
import { ConversationRouteEnhancer } from '../../conversationInterface';
import {
    ConversationAgentConfig,
    ConversationAgentContextValue,
} from './conversationAgentConfig';
import { ConversationAgent } from './conversationAgentInterface';
import { createAgentView } from './conversationAgentView';

export function applyConversationAgents(
    ...plugins: ConversationAgent[]
): ConversationRouteEnhancer {
    return (create) => (config) => {
        const pluginConfig: ConversationAgentConfig = {
            context: {
                useContext() {
                    const auth = config.hook.useAuth();
                    const organization = config.hook.useOrganization();
                    const mutation = {
                        update: useMutation({
                            async mutationFn(props) {
                                await new Promise((resolve) => setTimeout(resolve, 1000));
                                console.log('updating', props);
                                return;
                            },
                        }),
                    };
                    const context = useMemo(
                        (): ConversationAgentContextValue => ({
                            auth,
                            organization,
                        }),
                        [auth, organization]
                    );
                    return context;
                },
            },
            UI: config.UI,
            Layout: createAgentView(),
            provider: {
                createFormController: config.provider.createFormController,
            },
        };
        const instances = plugins.map((plugin) => ({
            ...plugin,
            ...plugin.create(pluginConfig),
        }));
        const agentByType = keyBySafe(instances, (item) => item.type);

        if (instances.length !== Object.keys(agentByType).length) {
            throw new Error('duplicate conversation plugin keys');
        }

        const {
            strategy: { Agent },
        } = config;
        return create({
            ...config,
            strategy: {
                ...config.strategy,
                Agent(agentProps) {
                    const agent = agentByType[agentProps.type];
                    if (!agent) {
                        return <Agent {...agentProps} />;
                    }
                    const { Component } = agent;
                    const parsed = agent.schema.safeParse(agentProps.data);
                    if (!parsed.success) {
                        console.error('failed block data parse', {
                            error: parsed.error.format()._errors,
                            raw: agentProps.data,
                        });
                        return <>an error occured</>;
                    }
                    return <Component type={agentProps.type} data={parsed.data} />;
                },
            },
        });
    };
}

import { TableCellProps } from '@chakra-ui/react';
import pluralize from 'pluralize';
import { TableAggregate } from './tableModel';
import { TableViewProps } from './tableProps';

const MAX_ROWS = 10;

export function buildTableViewProps(item: TableAggregate): TableViewProps {
    const headerProps = item.columns.map((column) => ({
        key: column.key,
        children: column.title,
    }));
    const cellPropsByRow = item.rows.reduce(
        (acc, row) => ({
            ...acc,
            [row.id]: item.columns.map((column, index) => {
                const value = row.data[column.key] ?? 'N/A';
                return {
                    key: column.key,
                    children: value,
                };
            }),
        }),
        {} as Record<string, Pick<TableCellProps, 'children'>[]>
    );

    const verb = pluralize('record', item.rows.length);

    const included = item.rows.slice(0, MAX_ROWS);
    const truncated = item.rows.slice(MAX_ROWS);

    return {
        items: included,
        collection: {
            label: `${item.rows.length} ${verb} loaded`,
        },
        truncate:
            truncated.length > 0
                ? {
                      label: `${truncated.length} records truncated`,
                  }
                : null,
        getHeaderProps() {
            return headerProps;
        },
        getTrProps(row) {
            return { key: row.id };
        },
        getCellProps(row) {
            const cellProps = cellPropsByRow[row.id];
            return cellProps;
        },
    };
}

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListSchema = void 0;
const zod_1 = require("zod");
function ListSchema(schema) {
    return zod_1.z.object({
        data: zod_1.z.array(schema),
    });
}
exports.ListSchema = ListSchema;

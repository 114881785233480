import React from 'react';
import { BarVisualizationV2 } from '../../../../../strategy';
import { BarChartControllerProps } from '../../../../vendor/chakra-chart';
import { createBarChartController } from './barController';

export function createBarChart(): React.FC<BarChartControllerProps> {
    const controller = createBarChartController();
    return (props) => {
        const viewProps = controller.useProps(props);
        return <BarVisualizationV2 {...viewProps} />;
    };
}

import {
    isTimestampPrevFilter,
    isTimestampBetweenFilter,
    TimestampFilter,
    AnyDateConstraint,
} from '../../../../../api';
import moment from 'moment';
import { DateFilter } from '../../../../../v2/domain';
import { DateType, Property } from '../../../../../v2/domain/attributes';

export function normalizeFilterWithConstraints(
    property: Property<DateType>,
    timestampFilter: TimestampFilter,
    constraints: AnyDateConstraint[]
): DateFilter {
    if (isTimestampPrevFilter(timestampFilter.condition)) {
        if (constraints.length > 0) {
            const [first] = constraints;
            const minDate = moment().add(-first.expression.days, 'day');
            const currentFrom = moment()
                .add(
                    -timestampFilter.condition.value,
                    timestampFilter.condition.granularity
                )
                .startOf(timestampFilter.condition.granularity);
            if (currentFrom.isBefore(minDate)) {
                return {
                    kind: 'property',
                    property,
                    default: {
                        operator: 'between',
                        value: {
                            from: minDate.toDate(),
                            to: new Date(),
                        },
                    },
                };
            }
            return {
                kind: 'property',
                property,
                default: {
                    operator: 'previous',
                    value: {
                        interval: timestampFilter.condition.granularity,
                        amount: timestampFilter.condition.value,
                    },
                },
            };
        } else {
            return {
                kind: 'property',
                property,
                default: {
                    operator: 'previous',
                    value: {
                        interval: timestampFilter.condition.granularity,
                        amount: timestampFilter.condition.value,
                    },
                },
            };
        }
    }
    if (isTimestampBetweenFilter(timestampFilter.condition)) {
        if (constraints.length > 0) {
            const [first] = constraints;
            const minDate = moment().add(-first.expression.days, 'day').toDate();
            const from = new Date(
                Math.max(
                    minDate.getTime(),
                    new Date(timestampFilter.condition.since).getTime()
                )
            );
            return {
                kind: 'property',
                property,
                default: {
                    operator: 'between',
                    value: {
                        from,
                        to: new Date(timestampFilter.condition.until),
                    },
                },
            };
        }
        return {
            kind: 'property',
            property,
            default: {
                operator: 'between',
                value: {
                    from: new Date(timestampFilter.condition.since),
                    to: new Date(timestampFilter.condition.until),
                },
            },
        };
    }
    return {
        kind: 'property',
        property,
        default: {
            operator: 'previous',
            value: { amount: 4, interval: 'week' },
        },
    };
}

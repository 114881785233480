// schemas.ts

import { z } from 'zod';
import { AxisSchema } from '../axis';
import { EnvironmentSchema } from '../environment';
import { AnyLabelSchema } from '../label';
import { AnyMetricSchema } from '../metric';
import { CreateVisualizationQuerySchema } from '../query';
import { SeriesSchema } from '../series';
import { TableSchema } from '../table';

export const VisualizationSchema = z.object({
    title: z.string().optional(),
    axes: z.array(AxisSchema),
    labels: z.array(AnyLabelSchema),
    series: z.array(SeriesSchema),
});

export const CreateVisualizationPayloadSchema = z.object({
    kind: z.enum(['trend', 'category']),
    metrics: z.array(AnyMetricSchema),
    query: CreateVisualizationQuerySchema,
    environment: z.lazy(() => EnvironmentSchema).optional(),
    tables: z.lazy(() => z.array(TableSchema)).optional(),
});

import { ButtonProps } from '@chakra-ui/button';
import { CheckboxProps } from '@chakra-ui/checkbox';
import React, { useMemo } from 'react';

export interface SelectItem<TItem> {
    data: TItem;
    getInputProps(): CheckboxProps;
    getButtonProps(): { onClick: React.MouseEventHandler };
    getChildren(): SelectItem<TItem>[] | null;
}

export interface UseTreeSelectReturn<TItem> {
    value: Array<string | number>;
    onChange(value: Array<string | number>): void;
    getItems(): SelectItem<TItem>[];
}

export interface UseTreeSelectConfig<TItem>
    extends Pick<UseTreeSelectReturn<TItem>, 'value' | 'onChange'> {
    options: TItem[];
    getValue(item: TItem): string | number;
    getChildren(item: TItem): TItem[] | null;
}

export const useTreeSelect = <TItem>(
    config: UseTreeSelectConfig<TItem>
): UseTreeSelectReturn<TItem> => {
    const handleChange: UseTreeSelectReturn<TItem>['onChange'] = (value) => {
        config.onChange(value);
    };

    const valueCache = useMemo(() => {
        return new Set(config.value);
    }, [config.value]);

    const parentByValue = useMemo<Record<string, TItem | undefined>>(() => {
        return config.options.reduce(
            (accParent, option) =>
                config.getChildren(option)?.reduce(
                    (accChild, child) => ({
                        ...accChild,
                        [config.getValue(child)]: option,
                    }),
                    accParent
                ) ?? {},
            {}
        );
    }, [config.options]);

    const handleParentToggle = (item: TItem) => {
        const itemValue = config.getValue(item);
        const isChecked = valueCache.has(itemValue);
        const children = config.getChildren(item) ?? [];
        const childrenValues = children.map(config.getValue);
        const isIndeterminate = children.some((child) =>
            valueCache.has(config.getValue(child))
        );
        if (isIndeterminate) {
            const valueWithoutChildren = config.value.filter(
                (candidate) => !childrenValues.includes(candidate)
            );
            const nextvalue = [...valueWithoutChildren, itemValue];
            return handleChange(nextvalue);
        }
        if (isChecked) {
            handleChange(config.value.filter((candidate) => candidate !== itemValue));
        } else {
            handleChange([...config.value, itemValue]);
        }
    };

    const handleChildToggle = (item: TItem) => {
        const itemValue = config.getValue(item);
        const parent = parentByValue[itemValue];
        const parentValue = parent ? config.getValue(parent) : null;
        const parentChildren = parent ? config.getChildren(parent) ?? [] : [];
        const isChecked = valueCache.has(itemValue);
        const isParentChecked = parentValue ? valueCache.has(parentValue) : false;

        const siblings = parentChildren.filter(
            (child) => config.getValue(child) !== itemValue
        );

        const allSiblingsChecked = siblings.every((sibling) =>
            valueCache.has(config.getValue(sibling))
        );

        if (!isChecked && isParentChecked) {
            // console.log('checking siblings');
            const nextvalue = [
                ...config.value,
                ...parentChildren.map(config.getValue),
            ].filter((candidate) => candidate !== itemValue && candidate !== parentValue);
            return handleChange(nextvalue);
        }

        if (!isChecked && parentValue && allSiblingsChecked) {
            // console.log('unchecking siblings to parent');
            const nextvalue = [...config.value, parentValue].filter(
                (candidate) =>
                    !siblings.some((sibling) => config.getValue(sibling) === candidate)
            );
            return handleChange(nextvalue);
        }

        if (isChecked) {
            handleChange(config.value.filter((candidate) => candidate !== itemValue));
        } else {
            handleChange([...config.value, itemValue]);
        }
    };

    return {
        value: config.value,
        onChange: handleChange,
        getItems() {
            return config.options.map<SelectItem<TItem>>((option) => {
                const parentValue = config.getValue(option);
                const parentChildren = config.getChildren(option) ?? [];
                const parentAllChecked =
                    valueCache.has(parentValue) ||
                    parentChildren.every((child) =>
                        valueCache.has(config.getValue(child))
                    );
                const isIndeterminate = parentChildren.some((child) =>
                    valueCache.has(config.getValue(child))
                );
                return {
                    getChildren() {
                        const children = config.getChildren(option);
                        return (
                            children?.map<SelectItem<TItem>>((child) => {
                                const valueChild = config.getValue(child);
                                const childChecked = valueCache.has(valueChild);
                                const childCheckedOrParent =
                                    childChecked ||
                                    (parentAllChecked && !isIndeterminate);
                                return {
                                    getChildren() {
                                        return null;
                                    },
                                    getButtonProps() {
                                        return {
                                            onClick(event) {
                                                return handleChildToggle(child);
                                            },
                                        };
                                    },
                                    getInputProps() {
                                        return {
                                            value: valueChild,
                                            isChecked: childCheckedOrParent,
                                            onChange(event) {
                                                return handleChildToggle(child);
                                            },
                                        };
                                    },
                                    data: child,
                                };
                            }) ?? null
                        );
                    },
                    getButtonProps() {
                        return {
                            onClick() {
                                return handleParentToggle(option);
                            },
                        };
                    },
                    getInputProps() {
                        const isChecked = valueCache.has(parentValue);
                        return {
                            value: parentValue,
                            isChecked,
                            isIndeterminate,
                            onChange(event) {
                                return handleParentToggle(option);
                            },
                        };
                    },
                    data: option,
                };
            });
        },
    };
};

import React, { RefObject } from 'react';

export function usePrevious<T>(value: T) {
    const ref = React.useRef<T>();
    React.useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

export function withLoadUnloadEvent(
    reportEvent: (eventName: string, eventData: Record<string, any>) => void,
    eventPrefix: string,
    eventData: Record<string, any>
) {
    React.useEffect(() => {
        reportEvent(`${eventPrefix}Load`, eventData);

        return () => {
            reportEvent(`${eventPrefix}UnLoad`, eventData);
        };
    }, []);
}

// source: https://gist.github.com/morajabi/523d7a642d8c0a2f71fcfa0d8b3d2846
import { useLayoutEffect, useCallback, useState } from 'react';

type RectResult = {
    bottom: number;
    height: number;
    left: number;
    right: number;
    top: number;
    width: number;
};

function getRect<T extends HTMLElement>(element?: T): RectResult | null {
    if (element) {
        return element.getBoundingClientRect();
    }
    return null;
}

export function useRect<T extends HTMLElement>(
    ref: React.RefObject<T>
): RectResult | null {
    const [rect, setRect] = useState<RectResult | null>(
        ref && ref.current ? getRect(ref.current) : getRect()
    );
    const handleResize = useCallback(() => {
        if (!ref.current) return;
        setRect(getRect(ref.current)); // Update client rect
    }, [ref.current]);

    useLayoutEffect(() => {
        const element = ref.current;
        if (!element) return;

        handleResize();

        // @ts-ignore
        if (typeof ResizeObserver === 'function') {
            // @ts-ignore
            let resizeObserver: ResizeObserver | null = new ResizeObserver(() =>
                handleResize()
            );
            resizeObserver.observe(element);
            return () => {
                if (!resizeObserver) return;
                resizeObserver.disconnect();
                resizeObserver = null;
            };
        } else {
            window.addEventListener('resize', handleResize); // Browser support, remove freely
            return () => window.removeEventListener('resize', handleResize);
        }
    }, [ref.current]);

    return rect;
}

export const useIntersection = (
    element: RefObject<HTMLElement | null>,
    options: IntersectionObserverInit = {}
) => {
    const [isVisible, setState] = React.useState(false);

    React.useEffect(() => {
        const curr = element.current;
        const observer = new IntersectionObserver(([entry]) => {
            if (entry.isIntersecting) {
                setState(entry.isIntersecting);
                curr && observer.unobserve(curr);
            }
        }, options);

        curr && observer.observe(curr);

        return () => {
            curr && observer.unobserve(curr);
        };
    }, []);

    return isVisible;
};

import React, { useEffect, useState } from 'react';
import {
    Icon as ChakraIcon,
    TagLabel,
    Box,
    Image,
    HStack,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionIcon,
    AccordionPanel,
    VStack,
    Wrap,
    WrapItem,
    Tag,
    Center,
    BoxProps,
    Button,
} from '@chakra-ui/react';
import { FiArrowRight } from 'react-icons/fi';
import { AiFillBulb, AiOutlineBulb } from 'react-icons/ai';
import { InsightViewProps } from './insightProps';
import { InsightModuleConfig } from './insightConfig';
import { Link } from 'react-router-dom';
import { LockIcon } from '@chakra-ui/icons';

export function createInsightView(
    config: Pick<InsightModuleConfig['deps'], 'UI'>
): React.FC<InsightViewProps> {
    const {
        UI: {
            EmptyState,
            Text,
            Icon: { Icon, IconGroup },
        },
    } = config;
    return (props) => {
        const {
            style = {},
            bottomContent = (
                <Center color="whiteAlpha.300" p={3}>
                    <Box>End</Box>
                </Center>
            ),
        } = props;
        const { fontSize = 'sm', borderWidth = 0.5, px = 4, py = 4 } = style;

        if (props.items.length === 0) {
            return (
                <EmptyState
                    icon={<Icon as={AiOutlineBulb} />}
                    title="No insights found"
                />
            );
        }

        return (
            <>
                <VStack align="start" w="full">
                    <Accordion
                        w="full"
                        fontWeight="medium"
                        fontSize={fontSize}
                        alignItems="flex-start"
                        allowMultiple={false}
                        allowToggle={true}
                    >
                        {props.items.map((item, index) => {
                            const iconSrc = props.getIconSource(item);
                            return (
                                <AccordionItem
                                    justifyContent="flex-start"
                                    key={item.id}
                                    borderTop={index === 0 ? 'none' : undefined}
                                    borderBottom={
                                        index === props.items.length - 1
                                            ? 'none'
                                            : undefined
                                    }
                                    borderBottomWidth={borderWidth}
                                >
                                    {({ isExpanded }) => {
                                        const childProps = props.getChildProps(
                                            item,
                                            index
                                        );
                                        const onClick: BoxProps['onClick'] = isExpanded
                                            ? childProps.onClose
                                            : childProps.onOpen;
                                        return (
                                            <>
                                                <AccordionButton
                                                    // _expanded={{ bg: 'blackAlpha.200' }}
                                                    fontWeight="medium"
                                                    position="relative"
                                                    textAlign="left"
                                                    fontSize={fontSize}
                                                    py={py}
                                                    px={px}
                                                    _focus={{ outline: 0 }}
                                                >
                                                    <Box
                                                        position="absolute"
                                                        left={0}
                                                        // top={5}
                                                        top={py}
                                                        mt="1px"
                                                        bottom={0}
                                                        flexShrink={0}
                                                        w={1.5}
                                                        h={8}
                                                        // h={8}
                                                        borderRadius="xs"
                                                        bg={props.getStatusColor(item)}
                                                        borderTopRightRadius="sm"
                                                        borderBottomRightRadius="sm"
                                                        opacity={0.5}
                                                    />
                                                    <HStack w="full" onClick={onClick}>
                                                        <VStack
                                                            w="full"
                                                            alignItems="flex-start"
                                                            spacing={1}
                                                        >
                                                            <HStack w="full" spacing={4}>
                                                                <HStack
                                                                    spacing={2}
                                                                    flexShrink={0}
                                                                >
                                                                    {iconSrc ? (
                                                                        <IconGroup
                                                                            p={2}
                                                                            flexShrink={0}
                                                                        >
                                                                            <Icon
                                                                                as={Image}
                                                                                w={5}
                                                                                h={5}
                                                                                src={
                                                                                    iconSrc
                                                                                }
                                                                            ></Icon>
                                                                        </IconGroup>
                                                                    ) : null}
                                                                    {/* <Box
                                                        bg="whiteAlpha.200"
                                                        p={2}
                                                        borderRadius="md"
                                                        flexShrink={0}
                                                    >
                                                        {iconSrc ? (
                                                            <Image
                                                                w={5}
                                                                h={5}
                                                                src={iconSrc}
                                                            />
                                                        ) : null}
                                                    </Box> */}
                                                                </HStack>
                                                                <Box flex={1}>
                                                                    <Text noOfLines={2}>
                                                                        {props.formatTitle(
                                                                            item
                                                                        )}
                                                                    </Text>
                                                                </Box>
                                                                <AccordionIcon />
                                                            </HStack>
                                                            <Box>
                                                                <Wrap
                                                                    alignItems="center"
                                                                    spacing={4}
                                                                >
                                                                    <WrapItem>
                                                                        <Box>
                                                                            <Text
                                                                                fontSize={
                                                                                    fontSize
                                                                                }
                                                                                color="whiteAlpha.500"
                                                                            >
                                                                                {props.formatRelative(
                                                                                    item.period_end_at
                                                                                )}
                                                                            </Text>
                                                                        </Box>
                                                                    </WrapItem>
                                                                </Wrap>
                                                            </Box>
                                                        </VStack>
                                                    </HStack>
                                                </AccordionButton>
                                                <AccordionPanel px={px} pt={0} pb={py}>
                                                    <VStack
                                                        alignItems="flex-start"
                                                        spacing={2}
                                                    >
                                                        <VStack
                                                            alignItems="flex-start"
                                                            spacing={2}
                                                        >
                                                            <Text>{item.body}</Text>
                                                            {item.hint ? (
                                                                <Box
                                                                    position="relative"
                                                                    // bg="whiteAlpha.100"
                                                                    // px={3}
                                                                    // py={2}
                                                                    // borderRadius="md"
                                                                >
                                                                    <Text>
                                                                        <Box
                                                                            as="span"
                                                                            display="inline-block"
                                                                            position="relative"
                                                                            top="2px"
                                                                        >
                                                                            <AiFillBulb fontSize="14px" />
                                                                        </Box>{' '}
                                                                        <Box as="span">
                                                                            {item.hint}
                                                                        </Box>
                                                                    </Text>
                                                                </Box>
                                                            ) : null}

                                                            {childProps.detailPath ? (
                                                                <Link
                                                                    to={
                                                                        childProps.detailPath
                                                                    }
                                                                >
                                                                    <Text
                                                                        display="flex"
                                                                        alignItems="center"
                                                                        onClick={
                                                                            childProps.onDetailClick
                                                                        }
                                                                        cursor="pointer"
                                                                        color="blue.400"
                                                                        _hover={{
                                                                            textDecoration:
                                                                                'underline',
                                                                        }}
                                                                    >
                                                                        <Box as="span">
                                                                            Dive deeper
                                                                        </Box>
                                                                    </Text>
                                                                </Link>
                                                            ) : (
                                                                <Text
                                                                    display="flex"
                                                                    alignItems="center"
                                                                    onClick={
                                                                        childProps.onDetailClick
                                                                    }
                                                                    cursor="pointer"
                                                                    color="blue.400"
                                                                    _hover={{
                                                                        textDecoration:
                                                                            'underline',
                                                                    }}
                                                                >
                                                                    <Box as="span">
                                                                        Dive deeper
                                                                    </Box>
                                                                </Text>
                                                            )}
                                                        </VStack>
                                                        <Wrap>
                                                            {item.tags.map((tag) => (
                                                                <WrapItem key={tag}>
                                                                    <Tag
                                                                        variant="subtle"
                                                                        size="sm"
                                                                    >
                                                                        <TagLabel>
                                                                            {tag}
                                                                        </TagLabel>
                                                                    </Tag>
                                                                </WrapItem>
                                                            ))}
                                                        </Wrap>
                                                        <HStack
                                                            spacing={2}
                                                            fontSize={fontSize}
                                                            color="whiteAlpha.500"
                                                            lineHeight="shorter"
                                                        >
                                                            {item.comparison_start_at &&
                                                            item.comparison_end_at ? (
                                                                <>
                                                                    <Box>
                                                                        {props.formatRange(
                                                                            {
                                                                                start: item.comparison_start_at,
                                                                                end: item.comparison_end_at,
                                                                            }
                                                                        )}
                                                                    </Box>
                                                                    <Box>
                                                                        <FiArrowRight />
                                                                    </Box>
                                                                </>
                                                            ) : null}
                                                            <Box>
                                                                {props.formatRange({
                                                                    start: item.period_start_at,
                                                                    end: item.period_end_at,
                                                                })}
                                                            </Box>
                                                        </HStack>
                                                    </VStack>
                                                </AccordionPanel>
                                            </>
                                        );
                                    }}
                                </AccordionItem>
                            );
                        })}
                        {props.status ? (
                            <Box px={4} pb={4}>
                                <VStack
                                    p={4}
                                    // border="2px solid"
                                    // borderColor="blackAlpha.300"
                                    bg="blackAlpha.300"
                                    borderRadius="md"
                                    spacing={2}
                                >
                                    {/* <Box bg="blackAlpha.100" p={4} borderRadius="md">
                                <Icon w={5} h={5} as={LockIcon} />
                            </Box> */}
                                    <HStack align="center">
                                        <Box display="inline-block" mb={0.5}>
                                            <Icon as={LockIcon} />
                                        </Box>
                                        <Text
                                            fontWeight="medium"
                                            display="inline-block"
                                            fontSize="md"
                                        >
                                            {props.status.title}
                                        </Text>
                                    </HStack>
                                    {props.status.buttonProps && (
                                        <Button
                                            colorScheme="green"
                                            variant="outline"
                                            size="sm"
                                            onClick={props.status.buttonProps.onClick}
                                        >
                                            {props.status.buttonProps?.children}
                                        </Button>
                                    )}
                                </VStack>
                            </Box>
                        ) : (
                            bottomContent
                        )}
                    </Accordion>
                </VStack>
                {props.children}
            </>
        );
    };
}

import React from 'react';
import { SettingAssetContextValue, SettingAssetController } from './assetInterface';
import { SettingAssetViewProps } from './assetProps';
import { SettingAssetConfig } from './assetConfig';
import { SettingAssetContext } from './assetContext';

export function createSettingAssetContainer(
    config: SettingAssetConfig['deps'],
    controller: SettingAssetController,
    Component: React.FC<SettingAssetViewProps>
): React.FC {
    return (props) => {
        const auth = config.hooks.useAuth();
        const organization = config.hooks.useOrganization();
        const workspace = config.hooks.useWorkspace();
        const context: SettingAssetContextValue = {
            auth,
            organization,
            workspace,
        };
        const viewProps = controller.useProps();
        return (
            <SettingAssetContext.Provider value={context}>
                <Component {...viewProps}>{props.children}</Component>
            </SettingAssetContext.Provider>
        );
    };
}

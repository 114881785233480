import { AgentObjectSchema, ListSchema } from '@varos/schema';
import { ResourceBaseConfig } from '../support/base';
import { AgentResource } from './agentInterface';

export function createAgentResource(config: ResourceBaseConfig): AgentResource {
    return {
        list(options) {
            return config.endpoint.list({
                url: `/agents`,
                query: null,
                schema: {
                    response: ListSchema(AgentObjectSchema),
                },
                options,
            });
        },
        get(agentId, options) {
            return config.endpoint.retrieve({
                url: `/agents/${agentId}`,
                schema: {
                    response: AgentObjectSchema,
                },
                options,
            });
        },
    };
}

import { Box, Flex, FlexProps } from '@chakra-ui/layout';
import React from 'react';
import { LegendProps } from '.';
import { ChartLegend } from './ChartLegend';

export interface ChartWrapperProps {
    style?: Omit<FlexProps, 'children'>;
    children: React.ReactElement[];
}

export const ChartWrapper: React.FC<ChartWrapperProps> = ({ ...props }) => {
    return (
        <Flex
            // bg="red"
            // p={4}
            h="full"
            w="full"
            justifyContent="center"
            flexDirection="column"
            {...props.style}
        >
            {React.Children.map(props.children, (child) => (
                <Box w="full">{child}</Box>
            ))}
        </Flex>
    );
};

import React, { useContext } from 'react';
import { assert } from '../../../../util/assert';
import { ReportDetailRouteConfig } from '../reportDetailConfig';
import { ReportDetailRouteContext } from '../reportDetailContext';
import { createReportDetailRouteOpportunityController } from './opportunityController';
import { createReportDetailRouteOpportunityLoader } from './opportunityLoader';
import { ReportDetailOpportunityContainerProps } from './opportunityProps';

export function createReportOpportunityContainer(
    config: Pick<
        ReportDetailRouteConfig,
        'controller' | 'loader' | 'strategy' | 'repository'
    >
): React.FC<ReportDetailOpportunityContainerProps> {
    const controller = createReportDetailRouteOpportunityController(config);
    const loader = createReportDetailRouteOpportunityLoader(config);
    return ({ as: Component, ...props }) => {
        const viewContext = useContext(ReportDetailRouteContext);
        assert(viewContext, 'no context');
        const viewData = loader.useLoad(viewContext, props);
        const viewProps = controller.useProps(viewContext, viewData, props);
        return <Component {...viewProps}>{props.children}</Component>;
    };
}

import { ConversationPluginConfig } from '../../../../route';
import { ConversationSurveyController } from './blockSurveyInterface';
import { ConversationSurveyViewProps } from './blockSurveyProps';

export function createConversationSurveyController(
    config: ConversationPluginConfig
): ConversationSurveyController {
    const {
        provider: { createFormController },
    } = config;

    const formController = createFormController();

    return {
        useProps(props): ConversationSurveyViewProps {
            const form = formController.useProps(props.form);
            return { form };
        },
    };
}

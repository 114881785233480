import { HStack, Icon, Tag, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import { FiChevronDown } from 'react-icons/fi';
import { EntityControlViewProps } from '../../../../../view';

export function createOnboardingEntityControlView(): React.FC<EntityControlViewProps> {
    return (props) => {
        const listProps = props.getListProps();
        return (
            <VStack align="end" spacing={2}>
                <HStack
                    flexShrink={0}
                    px={4}
                    py={1.5}
                    border="1px solid"
                    borderColor="gray.200"
                    borderRadius="md"
                    spacing={2}
                    userSelect="none"
                    _expanded={{
                        bg: 'gray.50',
                    }}
                >
                    <HStack
                        cursor="pointer"
                        spacing={2}
                        fontWeight="medium"
                        whiteSpace="nowrap"
                    >
                        <VStack w="full">
                            {listProps.value.length === 0 ? (
                                <Text>{listProps.title}</Text>
                            ) : null}
                            {listProps.value.map((valueItem, index) => (
                                <HStack
                                    key={index}
                                    spacing={2}
                                    w="full"
                                    justify="space-between"
                                >
                                    <Text isTruncated={false}>{valueItem.label}</Text>
                                    {valueItem.secondary && (
                                        <Text color="gray.500" fontSize="md">
                                            {valueItem.secondary}
                                        </Text>
                                    )}
                                </HStack>
                            ))}
                        </VStack>
                        <Icon fontSize="xl" color="gray.500" as={FiChevronDown} />
                    </HStack>
                </HStack>
            </VStack>
        );
    };
}

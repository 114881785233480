import React from 'react';
import { OrganizationReference, AuthenticationV2 } from '../../../../domain/platform';
import { ScheduleListController } from './scheduleListInterface';
import { ScheduleListViewProps } from './scheduleListProps';

export function createScheduleListContainer(config: {
    hook: {
        useOrganization(): OrganizationReference;
        useAuth(): AuthenticationV2;
    };
    controller: ScheduleListController;
    View: React.FC<ScheduleListViewProps>;
}): React.FC {
    const {
        View,
        controller: { useProps: useView },
        hook: { useOrganization, useAuth },
    } = config;
    return (_props) => {
        const organization = useOrganization();
        const auth = useAuth();
        const viewProps = useView({ organization, auth }, {});
        return <View {...viewProps} />;
    };
}

import { useMutation } from '@tanstack/react-query';
import React from 'react';
import { keyBySafe } from '../../../../util';
import { ConversationRouteEnhancer } from '../../conversationInterface';
import { ConversationPluginConfig } from './conversationBlockConfig';
import { ConversationPlugin } from './conversationBlockInterface';
import { createPluginView } from './conversationBlockView';

export function applyConversationPlugins(
    ...plugins: ConversationPlugin[]
): ConversationRouteEnhancer {
    return (create) => (config) => {
        const pluginConfig: ConversationPluginConfig = {
            context: {
                useContext() {
                    const mutation = {
                        update: useMutation({
                            async mutationFn(props) {
                                await new Promise((resolve) => setTimeout(resolve, 1000));
                                console.log('updating', props);
                                return;
                            },
                        }),
                    };
                    return {
                        update(props) {
                            return mutation.update.mutateAsync(
                                // @ts-expect-error
                                props
                            );
                        },
                    };
                },
            },
            Layout: createPluginView(),
            provider: {
                createFormController: config.provider.createFormController,
            },
        };
        const instances = plugins.map((plugin) => ({
            ...plugin,
            ...plugin.create(pluginConfig),
        }));
        const pluginByType = keyBySafe(instances, (item) => item.type);

        if (instances.length !== Object.keys(pluginByType).length) {
            throw new Error('duplicate conversation plugin keys');
        }

        return create({
            ...config,
            strategy: {
                ...config.strategy,
                Block(blockProps) {
                    const plugin = pluginByType[blockProps.type];
                    if (!plugin) {
                        return React.createElement(config.strategy.Block, blockProps);
                    }
                    const parsed = plugin.schema.safeParse(blockProps.data);
                    if (!parsed.success) {
                        console.error('failed block data parse', {
                            error: parsed.error,
                            raw: blockProps.data,
                        });
                        return <>an error occured</>;
                    }
                    return React.createElement(plugin.Component, { data: parsed.data });
                },
                parser: {
                    ...config.strategy.parser,
                    parse(parseProps) {
                        if (parseProps.contentType !== 'json') {
                            return null;
                        }
                        const plugin = pluginByType[parseProps.contentType];
                        if (!plugin) {
                            return null;
                        }

                        const raw = JSON.parse(parseProps.content);
                        const parsed = plugin.schema.safeParse(raw);
                        if (!parsed.success) {
                            console.error({ error: parsed.error, raw });
                            return config.strategy.parser.parse(parseProps);
                        }
                        return { type: plugin.type, data: parsed.data };
                    },
                },
            },
        });
    };
}

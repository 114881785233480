import { Icon } from '@chakra-ui/react';
import React from 'react';
import { AiOutlineBulb } from 'react-icons/ai';
import { InsightViewProps } from '../../../view';
import { HomeAlertViewProps } from '../../../strategy';
import { HomeItemConfig } from '../homeRouteModel';

export function createHomeAlertView(
    init: HomeItemConfig,
    View: React.FC<InsightViewProps>
): React.FC<HomeAlertViewProps> {
    const {
        UI: { EmptyState },
    } = init;
    return (props) => {
        if (props.items.length === 0) {
            return (
                <EmptyState
                    icon={<Icon as={AiOutlineBulb} />}
                    title="No alerts found"
                    description={props.emptyDescription}
                />
            );
        }
        return (
            <View
                {...props}
                style={{ fontSize: 'md', borderWidth: 1, px: 6, py: 6 }}
                bottomContent={
                    props.truncateText ? props.truncateText : props.bottomContent
                }
            >
                {props.children}
            </View>
        );
    };
}

import {
    Box,
    Button,
    Grid,
    GridItem,
    Heading,
    HStack,
    Image,
    Input,
    List,
    ListItem,
    StackDivider,
    Switch,
    Tag,
    Text,
    Tooltip,
    VStack,
    FormControl,
    FormLabel,
    InputGroup,
    InputLeftAddon,
    Icon,
    SimpleGrid,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverBody,
    useDisclosure,
    PopoverHeader,
    Center,
    Wrap,
    WrapItem,
    Alert,
    AlertTitle,
    AlertIcon,
    Spinner,
    ButtonGroup,
} from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import React from 'react';
import {
    AiOutlineArrowRight,
    AiOutlineCaretDown,
    AiOutlineCheckCircle,
    AiOutlineClose,
    AiOutlineEdit,
    AiOutlineLink,
} from 'react-icons/ai';
import { FiChevronDown } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { RemoteIframe } from '../../../../ui';
import { CardSpinner } from '../../../../domain';
import { AssetPrepareViewProps } from '../../../view/assets';
import { ReportNewViewProps } from '../../../view/reports';
import { OnboardingStrategyConfig } from '../../../route';
import { OnboardingReceiptViewProps } from './onboardingReceiptProps';
import ReactDatePicker from 'react-datepicker';
import { CalendarIcon } from '@chakra-ui/icons';

export function createOnboardingReceiptView(
    config: OnboardingStrategyConfig
): React.FC<OnboardingReceiptViewProps> {
    const {
        Layout: { Container, Content },
    } = config;
    return (props) => {
        if (props.status === 'loading') {
            return (
                <Center p={6}>
                    <CardSpinner />
                </Center>
            );
        }
        return (
            <Container
                title="Your audit is ready"
                rightContent={
                    <ButtonGroup>
                        <Link {...props.getLinkProps()}>
                            <Button
                                w="full"
                                colorScheme="green"
                                rightIcon={<Icon as={AiOutlineArrowRight} />}
                                _focus={{ outline: 'none' }}
                            >
                                View audit
                            </Button>
                        </Link>
                    </ButtonGroup>
                }
            >
                <Content isLoading={false}>
                    <Alert status="success">
                        <AlertIcon />
                        <AlertTitle fontWeight="semibold" w="full">
                            <HStack w="full" justify="space-between">
                                <Text fontWeight="semibold">Purchase was successful</Text>
                            </HStack>
                        </AlertTitle>
                    </Alert>
                </Content>
            </Container>
        );
    };
}

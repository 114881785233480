import React, { useState } from 'react';
import {
    Box,
    Table,
    Image,
    HStack,
    Text,
    Tbody,
    Tr,
    Td,
    Heading,
    Button,
} from '@chakra-ui/react';
import { ReportViewProps } from './reportProps';
import { ReportViewConfig } from './reportConfig';
import { WarningIcon } from '@chakra-ui/icons';
import { Link } from 'react-router-dom';

export function createReportView(config: ReportViewConfig): React.FC<ReportViewProps> {
    const {
        component: {
            Layout,
            Chart: { Table: TableVisualization },
            Input: { Date: DateInput },
        },
    } = config;
    return (props) => {
        const { renderStatus = (status) => status.status } = props;
        return (
            <Layout.Wrapper>
                <Layout.Container>
                    <Layout.Loader>Loading report...</Layout.Loader>
                    <Layout.Header>
                        <HStack alignItems="center">
                            <Heading size="lg">{props.report.name}</Heading>
                        </HStack>
                    </Layout.Header>
                    <Layout.Control>
                        <HStack justifyContent="flex-end">
                            <Box>
                                <DateInput {...props.getDateInputProps()} />
                            </Box>
                        </HStack>
                    </Layout.Control>
                    <Layout.Body>
                        <Layout.Section>
                            <Layout.Column>
                                {props.report.blocks.map((block, index) => (
                                    <Layout.Row key={index}>
                                        <TableVisualization
                                            segments={props.report.segments}
                                            metrics={block.metrics}
                                            control={props.control}
                                        />
                                    </Layout.Row>
                                ))}
                                <Layout.Row>
                                    <Table>
                                        <Tbody>
                                            {props.report.dependencies
                                                .filter(
                                                    (dependency) =>
                                                        dependency.status !== 'ready'
                                                )
                                                .map((dependency) => (
                                                    <Tr
                                                        key={dependency.dashboard.id}
                                                        fontWeight="semibold"
                                                    >
                                                        <Td
                                                            width="33%"
                                                            borderColor="whiteAlpha.400"
                                                        >
                                                            <HStack
                                                                py={2}
                                                                alignItems="center"
                                                                spacing={4}
                                                            >
                                                                {dependency.dashboard
                                                                    .iconUrl && (
                                                                    <Image
                                                                        width={5}
                                                                        src={
                                                                            dependency
                                                                                .dashboard
                                                                                .iconUrl
                                                                        }
                                                                    />
                                                                )}
                                                                <Text>
                                                                    {
                                                                        dependency
                                                                            .dashboard
                                                                            .name
                                                                    }
                                                                </Text>
                                                            </HStack>
                                                        </Td>
                                                        <Td
                                                            width="33%"
                                                            borderColor="whiteAlpha.400"
                                                            textAlign="right"
                                                        >
                                                            <Link
                                                                {...props.getDashboardLinkProps(
                                                                    dependency
                                                                )}
                                                            >
                                                                <Button
                                                                    leftIcon={
                                                                        <WarningIcon color="red.400" />
                                                                    }
                                                                    variant="outline"
                                                                    size="sm"
                                                                    borderRadius="none"
                                                                    borderColor="red.400"
                                                                    color="red.400"
                                                                    background="none"
                                                                >
                                                                    Reconnect
                                                                </Button>
                                                            </Link>
                                                        </Td>
                                                        <Td
                                                            width="33%"
                                                            borderColor="whiteAlpha.400"
                                                        />
                                                    </Tr>
                                                ))}
                                        </Tbody>
                                    </Table>
                                </Layout.Row>
                            </Layout.Column>
                        </Layout.Section>
                        <Layout.Section></Layout.Section>
                    </Layout.Body>
                </Layout.Container>
            </Layout.Wrapper>
        );
    };
}

import { PluginIcons } from '../../../config';
import { HStack, Image, Input, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import { MetricSelectViewProps } from './selectProps';

export function createMetricSelectView(): React.FC<MetricSelectViewProps> {
    return (props) => (
        <VStack align="start">
            <Input
                autoFocus={true}
                placeholder="Search metrics"
                value={props.fields.search.value}
                onChange={props.fields.search.onChange}
            />
            <VStack align="start">
                {props.filtered.map((item) => (
                    <HStack
                        key={item.id}
                        onClick={props.onSelect.bind(null, item)}
                        p={2}
                        spacing={3}
                        cursor="pointer"
                        fontWeight="medium"
                    >
                        <Image
                            width={5}
                            alt="asdasd"
                            src={PluginIcons[item.plugin ?? 'asdas'] ?? 'asdas'}
                        />

                        <Text>{item.name}</Text>
                    </HStack>
                ))}
            </VStack>
        </VStack>
    );
}

import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { ConversationPluginConfig } from '../../../../route';
import { ConversationSurveyController } from './blockSurveyInterface';
import {
    ConversationSurveyContainerProps,
    ConversationSurveyViewProps,
} from './blockSurveyProps';

export function createConversationSurveyContainer(
    config: ConversationPluginConfig,
    controller: ConversationSurveyController,
    View: React.FC<ConversationSurveyViewProps>
): React.FC<ConversationSurveyContainerProps> {
    const {
        context: { useContext },
    } = config;
    return (containerProps) => {
        const context = useContext();
        const props = controller.useProps({
            form: {
                id: 'survey_submission_form',
                form: useForm({
                    defaultValues: {
                        survey: containerProps.survey.id,
                        data: {},
                    },
                }),
                async onSubmit(values) {
                    console.log('values', values);
                    const response = await context.update({
                        metadata: {
                            submitted: true,
                        },
                    });
                },
            },
        });
        return <View {...props} />;
    };
}

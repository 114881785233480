import { AxiosCacheInstance } from 'axios-cache-interceptor';
import { AxiosInstance } from 'axios';
import { LegacyAuthenticationScheme } from '../../common';
import { PreferenceListDto, PreferenceMatchListDto } from './preferenceModel';
import { PreferenceResource } from './preferenceInterface';
import {
    PreferenceListResponseSchema,
    PreferenceMatchListResponseSchema,
} from './preferenceSchema';

export function createPreferenceResource(client: AxiosInstance): PreferenceResource {
    return {
        async list(context, query): Promise<PreferenceListDto> {
            const scheme = context.auth.scheme as LegacyAuthenticationScheme;
            await scheme.store.waitAuthenticated();

            const params: Record<string, unknown> = {};
            params['asset_id'] = query.assetId;
            params['page_size'] = query.pageSize;
            params['section'] = query.sections;

            const response = await (client as AxiosCacheInstance).get(
                '/api/v1/account/preference-sections',
                {
                    cache: false,
                    params,
                    headers: {
                        Authorization: `bearer ${scheme.store.authToken}`,
                    },
                }
            );

            const parsed = PreferenceListResponseSchema.parse(response.data);

            // @ts-expect-error
            return { data: parsed };
        },
        async match(context, query): Promise<PreferenceMatchListDto> {
            const scheme = context.auth.scheme as LegacyAuthenticationScheme;
            await scheme.store.waitAuthenticated();

            const params: Record<string, unknown> = {};
            params['asset_id'] = query.assetId;
            params['page_size'] = query.pageSize;
            params['section'] = query.sections;
            params['plugins'] = query.plugins.map(btoa);
            params['dashboards'] = query.dashboards.map(btoa);

            const response = await (client as AxiosCacheInstance).get(
                '/api/v1/account/preference-match',
                {
                    cache: false,
                    params,
                    headers: {
                        Authorization: `bearer ${scheme.store.authToken}`,
                    },
                }
            );

            const parsed = PreferenceMatchListResponseSchema.parse(response.data);

            // @ts-expect-error
            return { data: parsed };
        },
        async update(context, payload) {
            const scheme = context.auth.scheme as LegacyAuthenticationScheme;
            await scheme.store.waitAuthenticated();
            const response = await client.put(`/api/v1/account/preferences`, payload, {
                headers: {
                    Authorization: `bearer ${scheme.store.authToken}`,
                },
            });
            return;
        },
    };
}

import { z } from 'zod';
import { ConditionGroupSchema } from '../condition';

export const ColumnAggregationTypeSchema = z.enum([
    'sum',
    'average',
    'distribution',
    'count',
]);

export const TableFormatSchema = z.enum([
    'currency',
    'percentage',
    'integer',
    'decimal',
    'time',
]);

export const MetricAggregationSchema = z.object({
    kind: z.literal('aggregation').default('aggregation'),
    key: z.string().optional(),
    display_name: z.string().optional(),
    namespace: z.string(),
    table: z.string().optional(),
    column: z.string().optional(),
    type: ColumnAggregationTypeSchema,
    filter: ConditionGroupSchema.optional(),
    visible: z.boolean().optional(),
});

export const MetricFormulaSchema = z.object({
    kind: z.literal('formula').default('formula'),
    key: z.string().optional(),
    display_name: z.string().optional(),
    formula: z.string(),
    format: TableFormatSchema.optional(),
    visible: z.boolean().optional(),
});

export const AnyMetricSchema = z.discriminatedUnion('kind', [
    MetricAggregationSchema,
    MetricFormulaSchema,
]);

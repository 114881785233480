import { assert } from '../../../util/assert';
import { ConversationAgentAdapter } from '../../../app/conversations';
import { AgentImplConfig } from './agentImplConfig';
import { toAgentEntity } from './agentImplSerdes';

export function createAgentImpl(config: AgentImplConfig): ConversationAgentAdapter {
    const {
        api: { conversation: api },
    } = config;

    return {
        async find(context, props) {
            assert(context.auth.scheme.kind === 'legacy', 'expected legacy auth');
            assert(context.auth.scheme.store.authToken, 'missing token');
            const response = await api.agent.list({
                token: context.auth.scheme.store.authToken,
            });
            return response.data.map(toAgentEntity);
        },
        async lookup(context, props) {
            assert(context.auth.scheme.kind === 'legacy', 'expected legacy auth');
            assert(context.auth.scheme.store.authToken, 'missing token');
            const response = await api.agent.get(props.id, {
                token: context.auth.scheme.store.authToken,
            });
            return toAgentEntity(response);
        },
    };
}

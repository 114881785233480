import Axios, { AxiosResponse } from 'axios';
import querystring from 'querystring';
import {
    ChangePasswordIdentifiedUser,
    SocialLogin,
    LoginResponse,
} from '../models/Account';
import { apiBaseURL, apiPrefix } from './../config/config';

export interface LoginPayload {
    emailAddress: string;
    password: string;
}

export const login = async (payload: LoginPayload) => {
    const reqData = querystring.stringify({
        username: payload.emailAddress,
        password: payload.password,
        grant_type: 'password',
    });
    const api = Axios.create({
        baseURL: `${apiBaseURL}${apiPrefix}`,
        timeout: 60 * 1000,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    });
    return api.post<unknown, AxiosResponse<LoginResponse>>('/login/token', reqData);
};

export const loginSocial = async (payload: SocialLogin) => {
    const api = Axios.create({
        baseURL: `${apiBaseURL}${apiPrefix}`,
        timeout: 60 * 1000,
        headers: {
            'Content-Type': 'application/json',
        },
    });
    return api.post<unknown, AxiosResponse<LoginResponse>>('/login/social', payload);
};

export const loginWithRefreshToken = async (refreshToken: string) => {
    const api = Axios.create({
        baseURL: `${apiBaseURL}${apiPrefix}`,
        timeout: 60 * 1000,
        headers: {
            'Content-Type': 'application/json',
        },
    });
    return api.post<unknown, AxiosResponse<LoginResponse>>('/login/refresh_token', {
        refresh_token: refreshToken,
    });
};

export const setPasswordRequest = async (email: string, recaptchaToken: string) => {
    const api = Axios.create({
        baseURL: `${apiBaseURL}${apiPrefix}`,
        timeout: 60 * 1000,
        headers: {
            'Content-Type': 'application/json',
        },
    });
    return api.post<unknown, AxiosResponse<{ success: boolean }>>(
        '/set-password/request',
        {
            email,
            re_captcha_token: recaptchaToken,
        }
    );
};

export const setPasswordIdentify = async (token: string) => {
    const api = Axios.create({
        baseURL: `${apiBaseURL}${apiPrefix}`,
        timeout: 60 * 1000,
        headers: {
            'Content-Type': 'application/json',
        },
    });
    return api.get<unknown, AxiosResponse<ChangePasswordIdentifiedUser>>(
        `/set-password/identify?token=${token}`
    );
};

export const setPasswordResponse = async (token: string, newPassword: string) => {
    const api = Axios.create({
        baseURL: `${apiBaseURL}${apiPrefix}`,
        timeout: 60 * 1000,
        headers: {
            'Content-Type': 'application/json',
        },
    });
    return api.post(`/set-password/response`, { token, password: newPassword });
};



export const requestPasswordLessLogin = async (email: string, recaptchaToken: string) => {
    const api = Axios.create({
        baseURL: `${apiBaseURL}${apiPrefix}`,
        timeout: 60 * 1000,
        headers: {
            'Content-Type': 'application/json',
        },
    });
    return api.post<unknown, AxiosResponse<{ success: boolean }>>(
        '/login/passwordless',
        {
            email,
            re_captcha_token: recaptchaToken,
        }
    );
};
import React from 'react';

export interface DemoLayoutHeaderProps {
    title: string;
    subtitle: string;
}

export const DemoLayoutHeader: React.FC<DemoLayoutHeaderProps> = (props) => (
    <>{props.children}</>
);

import { action, autorun, computed, makeObservable, observable } from 'mobx';

import { sendInvitePeer, getPeerGroups, listParticipants } from './../api/peers';
import { InvitePeerRequest, ListParticipantsPagingResponse, PeerEmailDelivery, PeerGroup } from '../models/Peer';
import StoreBase from './StoreBase';
import { ContextType } from '../models/Account';
import { HomeContext } from '../models/Context';

class PeerStore extends StoreBase {
    isLoading: boolean = false;
    firstLoaded: boolean = false;
    emailDelivery: PeerEmailDelivery | null = null;
    peerGroups: Array<PeerGroup> | null = null;
    subjectId: number | string | null = null;
    participantsList: ListParticipantsPagingResponse | null = null;
    //   currentAssets: Array<Asset> | null = null;
    //   assetInEdit: Asset | null = null;
    private peerGroupsPromise: Promise<void> | null = null;
    private _pgResolveCb: (() => void) | null = null;
    private _pgRejectCb: ((reason: any) => void) | null = null;

    constructor() {
        super();
        makeObservable(this, {
            // Observables
            emailDelivery: observable,
            isLoading: observable,
            peerGroups: observable,
            firstLoaded: observable,
            subjectId: observable,
            participantsList: observable,
            // Actions
            start: action,
            listParticipants: action,
            setParticipantsList: action,
            // fetchAllAssets: action,

            peerGroupsById: computed,

            setLoading: action,
            invitePeer: action,
            setPeerEmailDelivery: action,
            fetchPeerGroups: action,
            setPeerGroups: action,
        });

        autorun(() => {
            if (this.peerGroups) {
                this._pgResolveCb && this._pgResolveCb();
                this.peerGroupsPromise = null;
                this._pgResolveCb = null;
                this._pgRejectCb = null;
            } else if (this.err) {
                this._pgRejectCb && this._pgRejectCb(this.err);
                this.peerGroupsPromise = null;
                this._pgResolveCb = null;
                this._pgRejectCb = null;
            }
        });
    }

    start = async () => {
        await this.rootStore.auth.waitAuthenticated();
        this.rootStore.auth.modifyVisibleSection('peer-groups', false);
    };

    get peerGroupsById(): Record<number, PeerGroup> {
        return (this.peerGroups || []).reduce<Record<number, PeerGroup>>((agg, curr) => {
            agg[curr.id] = curr;
            return agg;
        }, {});
    }

    async onContextChanged(contextName: ContextType, ctx?: HomeContext | null): Promise<void> {
        switch (contextName) {
            case 'dashboard':
            case 'on-boarding':
                if (!this.participantsList) {
                    await this.listParticipants(1, 100);
                }
            // if (subjectType == 'asset' && subjectId) {
            // await this.fetchPeerGroups(subjectId, subjectType);
            // }
        }
    }

    waitPeerGroups = async () => {
        if (this.peerGroups) {
            return Promise.resolve();
        } else if (this.peerGroupsPromise) {
            return this.peerGroupsPromise;
        } else {
            this.peerGroupsPromise = new Promise((resolve, reject) => {
                this._pgResolveCb = resolve;
                this._pgRejectCb = reject;
            });
            return this.peerGroupsPromise;
        }
    };

    setPeerEmailDelivery(emailDelivery: PeerEmailDelivery) {
        this.emailDelivery = emailDelivery;
    }

    setLoading(flag: boolean) {
        if (!this.firstLoaded && !flag && this.isLoading) {
            this.firstLoaded = true;
        }
        this.isLoading = flag;
    }

    invitePeer = async (request: InvitePeerRequest) => {
        this.setLoading(true);
        try {
            const { data } = await sendInvitePeer(request);
            //   this.setAssetInEdit(data);
            //   await this.fetchDefinitions();
            this.setPeerEmailDelivery(data);
        } catch (e) {
            const isDone = await this.rootStore.auth.doCommonUnauthErrorHandling(e);
            if (isDone) {
                await this.invitePeer(request);
            } else {
                this.handleAxiosError(e);
            }
        } finally {
            this.setLoading(false);
        }
    };

    setPeerGroups(arr: Array<PeerGroup> | null, subjectId: number | string) {
        this.peerGroups = arr;
        this.subjectId = subjectId;
    }

    fetchPeerGroups = async (assetId: number | string, soft: boolean = false) => {
        if (soft && this.subjectId == assetId && this.peerGroups) {
            return;
        }
        this.setPeerGroups(null, assetId);
        this.setLoading(true);
        await this.rootStore.auth.waitAuthenticated();
        try {
            const { data } = await getPeerGroups(assetId);
            this.setPeerGroups(data, assetId);
            this.rootStore.auth.modifyVisibleSection('peer-groups', !!(data && data.length > 1));
        } catch (e) {
            // const isDone = await this.rootStore.auth.doCommonUnauthErrorHandling(e);
            // if (isDone) {
            //     await this.fetchPeerGroups(subjectId, subjectType);
            // } else {
            this.handleAxiosError(e);
            // }
        } finally {
            this.setLoading(false);
        }
    };

    setParticipantsList(list: ListParticipantsPagingResponse | null) {
        this.participantsList = list;
    }

    listParticipants = async (page?: number, page_size?: number, search?: string) => {
        this.setLoading(true);
        await this.rootStore.auth.waitAuthenticated();
        try {
            const { data } = await listParticipants(page, page_size, search);
            this.setParticipantsList(data);
        } catch (e) {
            const isDone = await this.rootStore.auth.doCommonUnauthErrorHandling(e);
            if (isDone) {
                await this.listParticipants(page, page_size, search);
            } else {
                this.setErrType('serverErr');
            }
        } finally {
            this.setLoading(false);
        }
    };
}

export default PeerStore;

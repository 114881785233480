import { keyBy } from 'lodash';
import { useMemo } from 'react';
import { ReportDetailRouteConfig } from './reportDetailConfig';
import { ReportDetailRouteLoader } from './reportDetailInterface';

export function createReportDetailLoader(
    config: Pick<ReportDetailRouteConfig, 'loader' | 'strategy' | 'repository'>
): ReportDetailRouteLoader {
    const { loader, repository, strategy } = config;
    return {
        useLoad(context, props) {
            const views = repository.view.useFind(context, {}, {});
            const plugins = repository.plugin.useFind(context, {}, {});

            const metrics = repository.metric.definition.useFind(context, {
                plugins: plugins.data ?? [],
            });

            const detail = loader.report.useLoad(context, props.report);

            const status = [detail].every((item) => item.status === 'success')
                ? 'success'
                : 'loading';

            return {
                status,
                item: {
                    detail: detail,
                    metrics: metrics.data ?? [],
                },
            };
        },
    };
}

import { z } from 'zod';

export const AxisTypeSchema = z.enum(['time', 'category', 'scalar', 'overlay']);

export const AxisFormatSchema = z.enum([
    'percentage',
    'time',
    'string',
    'currency',
    'decimal',
    'integer',
]);

export const AxisSchema = z.object({
    key: z.string(),
    label: z.string(),
    type: AxisTypeSchema,
    format: AxisFormatSchema.nullable(),
});

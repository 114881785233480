import React from 'react';
import { StudiesContextProvider } from '../../base';
import {
    StudiesExploreController,
    StudiesExploreLoader,
} from './studiesReportListInterface';
import { StudiesExploreViewProps } from './studiesReportListProps';

export function createStudiesExploreRoute(
    context: StudiesContextProvider,
    controller: StudiesExploreController,
    loader: StudiesExploreLoader,
    View: React.FC<StudiesExploreViewProps>
): React.FC {
    return (props) => {
        const viewContext = context.useContext();
        const viewData = loader.useLoad(viewContext);
        const viewProps = controller.useProps(viewContext, viewData, {});
        return <View {...viewProps} />;
    };
}

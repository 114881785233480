import { Box } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { ApplicationEntryEnhancer } from '../../../../entrypoint';
import { HomeAlertViewProps } from '../../../../strategy/home';
import { AnyHomeAlertTrackingEvent } from './alertTrackingEvent';

export function createHomeAlertTrackingStrategy(): ApplicationEntryEnhancer {
    return (create) => (config) => {
        function useTracker() {
            const tracker = instance.infra.useTracker<AnyHomeAlertTrackingEvent>();
            return tracker;
        }

        const Tracker: React.FC<{ onLoad(): void }> = (props) => {
            const [tracked, setTracked] = useState(false);
            const { ref, inView } = useInView({
                // rootMargin: '100px',
                // threshold: 0,
                triggerOnce: true,
            });

            // We want to track when the component is actually rendered which
            // is not the same as when it's loaded because of suspense
            useEffect(() => {
                if (inView && !tracked) {
                    props.onLoad();
                    setTracked(true);
                }
            }, [inView, tracked]);

            return (
                <Box
                    position="absolute"
                    // Add a bit of margin to only trigger the visible event
                    // when a larger part of the alerts are visible
                    top={24}
                    as="span"
                    ref={ref}
                />
            );
        };

        function enhanceInsightView(
            Component: React.FC<HomeAlertViewProps>
        ): React.FC<HomeAlertViewProps> {
            return (props) => {
                const tracker = useTracker();

                const handleLoad = () => {
                    if (props.items.length === 0) {
                        return;
                    }
                    tracker.track('home_view_alert_list_viewed', {
                        insight_visible_count: props.items.length,
                        insight_ids: props.items.map((item) => item.id),
                        insight_definition_ids: props.items.map(
                            (item) => item.definition.id
                        ),
                        insight_kinds: props.items.map((item) => item.kind),
                        insight_statuses: props.items.map((item) => item.status),
                        insight_priority: props.items.map((item) => item.priority),
                    });
                };

                // we want to refresh the tracking logic when the insights change due to filtering
                const key = props.items.map((item) => item.id).join('|');

                return (
                    // make sure the tracker is aligned with the top of the component
                    <Box position="relative">
                        <Component {...props}>
                            <Tracker key={key} onLoad={handleLoad} />
                        </Component>
                    </Box>
                );
            };
        }

        const instance = create({
            ...config,
            view: {
                ...config.view,
                home: {
                    ...config.view.home,
                    createAlert(...args) {
                        const View = config.view.home.createAlert(...args);
                        return enhanceInsightView(View);
                    },
                },
            },
        });

        return instance;
    };
}

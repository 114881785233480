import { ChevronRightIcon } from '@chakra-ui/icons';
import {
    Grid,
    GridItem,
    Heading,
    HStack,
    Icon,
    StackDivider,
    Text,
    VStack,
} from '@chakra-ui/react';
import React from 'react';
import { PageLayoutConfig } from './pageLayoutConfig';
import { PageLayoutViewProps } from './pageLayoutProps';
import { PageLayoutSectionItemViewProps } from './section';

export function createPageLayoutView(
    config: PageLayoutConfig
): React.FC<PageLayoutViewProps> {
    const {
        UI: { Link },
    } = config;

    const SectionItem: React.FC<PageLayoutSectionItemViewProps> = (props) => {
        return (
            <VStack
                align="start"
                w="full"
                spacing={4}
                maxW="42rem"
                divider={<StackDivider borderColor="whiteAlpha.200" borderWidth={2} />}
            >
                <HStack w="full">
                    {props.Icon && <Icon fontSize="xl" as={props.Icon} />}
                    <Heading fontSize="xl">{props.title}</Heading>
                </HStack>
                <VStack
                    align="start"
                    w="full"
                    spacing={6}
                    // maxW="42rem"
                >
                    {props.description && (
                        <Text
                            color="whiteAlpha.700"
                            maxW="32rem"
                            // fontWeight="medium"
                        >
                            {props.description}
                        </Text>
                    )}
                    {props.children}
                </VStack>
            </VStack>
        );
    };

    return (props) => (
        <Grid templateColumns="1fr" rowGap={12} w="full">
            <GridItem>
                <VStack
                    w="full"
                    align="start"
                    spacing={6}
                    // pb={6}
                    // borderBottomWidth={3}
                    // borderColor="whiteAlpha.200"
                    // borderStyle="solid"
                >
                    <HStack
                        w="full"
                        justify="space-between"
                        spacing={12}
                        align="end"
                        maxW="32rem"
                    >
                        <VStack w="full" align="start" spacing={1}>
                            <HStack
                                fontWeight="semibold"
                                spacing={2}
                                divider={
                                    <Icon
                                        boxSize="1.20rem"
                                        color="whiteAlpha.500"
                                        as={ChevronRightIcon}
                                        border="none"
                                    />
                                }
                            >
                                {props.header.breadcrumbs.map((item, index) => (
                                    <HStack key={index}>
                                        <Link to="..">
                                            <Text
                                                color="blue.300"
                                                _hover={{ color: 'blue.400' }}
                                            >
                                                {item.label}
                                            </Text>
                                        </Link>
                                    </HStack>
                                ))}
                            </HStack>
                            <VStack align="start" w="full" spacing={3}>
                                <Heading fontSize="2xl">{props.header.title}</Heading>
                                {props.header.description && (
                                    <Text fontSize="md" color="whiteAlpha.700">
                                        {props.header.description}
                                    </Text>
                                )}
                            </VStack>
                        </VStack>
                    </HStack>
                </VStack>
            </GridItem>
            <GridItem>
                <VStack align="start" w="full" spacing={12}>
                    {props.section.items.map((item) => (
                        <SectionItem key={item.id} {...item} />
                    ))}
                </VStack>
            </GridItem>
        </Grid>
    );
}

import {
    Box,
    HStack,
    Image,
    Table,
    Tag,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tooltip,
    Tr,
    VStack,
} from '@chakra-ui/react';
import React from 'react';
import {
    isLineVisualization,
    isComparisonVisualization,
    isDistributionVisualization,
} from '../../../../domain/visualization';
import { AnalysisRouteDeps } from '../analysisRouteConfig';
import { AnalysisViews } from '../analysisRouteInterface';
import { VisualizationViewProps } from './visualizationProps';

export function createVisualizationView(
    config: AnalysisRouteDeps,
    View: AnalysisViews
): React.FC<VisualizationViewProps> {
    return (props) => {
        if (isComparisonVisualization(props.visualization)) {
            const tableProps = props.getTableProps(props.visualization);
            return (
                <Table>
                    <Thead
                        textTransform="uppercase"
                        fontWeight="bold"
                        letterSpacing="widest"
                        fontSize="xs"
                    >
                        <Th
                            color="whiteAlpha.500"
                            borderBottomWidth="2px"
                            borderColor="whiteAlpha.300"
                        >
                            Metric
                        </Th>
                        {tableProps.response.queries.flatMap((query) =>
                            query.series.items.map((series, index) => {
                                const headerProps = tableProps.getHeaderProps(
                                    series,
                                    index
                                );
                                return (
                                    <Th
                                        key={series.name}
                                        color="whiteAlpha.500"
                                        borderBottomWidth="2px"
                                        borderColor="whiteAlpha.300"
                                    >
                                        <Tooltip
                                            placement="top"
                                            hasArrow={true}
                                            bg="#1d1c21"
                                            borderRadius="none"
                                            isDisabled={headerProps.description === null}
                                            label={
                                                <Box p={2}>
                                                    <Text>{headerProps.description}</Text>
                                                </Box>
                                            }
                                        >
                                            <Text>{headerProps.label}</Text>
                                        </Tooltip>
                                    </Th>
                                );
                            })
                        )}
                    </Thead>
                    <Tbody fontWeight="medium">
                        {tableProps.visualization.metrics.map((metric) => {
                            return (
                                <Tr>
                                    <Td
                                        borderColor="whiteAlpha.200"
                                        borderBottomWidth="2px"
                                    >
                                        <HStack>
                                            <Tooltip
                                                placement="top"
                                                hasArrow={true}
                                                bg="#1d1c21"
                                                borderRadius="none"
                                                label={
                                                    <Box p={3}>
                                                        <VStack align="start">
                                                            <HStack spacing={3}>
                                                                <Box
                                                                    p={2}
                                                                    bg="whiteAlpha.200"
                                                                    borderRadius="md"
                                                                >
                                                                    <Image
                                                                        w={5}
                                                                        h={5}
                                                                        src={
                                                                            tableProps
                                                                                .context
                                                                                .data
                                                                                .plugin
                                                                                .iconUrl ??
                                                                            ''
                                                                        }
                                                                    />
                                                                </Box>
                                                                <Text fontWeight="medium">
                                                                    {metric.name}
                                                                </Text>
                                                            </HStack>
                                                            <Text
                                                                fontWeight="medium"
                                                                color="whiteAlpha.700"
                                                            >
                                                                {metric.description}
                                                            </Text>
                                                        </VStack>
                                                    </Box>
                                                }
                                            >
                                                <HStack spacing={4}>
                                                    <Box
                                                        p={2}
                                                        bg="whiteAlpha.300"
                                                        borderRadius="md"
                                                    >
                                                        <Image
                                                            w={5}
                                                            h={5}
                                                            src={
                                                                tableProps.context.data
                                                                    .plugin.iconUrl ?? ''
                                                            }
                                                        />
                                                    </Box>
                                                    <Text fontWeight="medium">
                                                        {metric.name}
                                                    </Text>
                                                </HStack>
                                            </Tooltip>
                                        </HStack>
                                    </Td>
                                    {tableProps.response.queries.flatMap((query) => {
                                        return query.series.items.map((series) => {
                                            const labelProps = props.getScalarLabelProps(
                                                metric,
                                                series,
                                                // @ts-expect-error
                                                series.data[0]
                                            );
                                            return (
                                                <Td
                                                    key={series.name}
                                                    borderColor="whiteAlpha.200"
                                                    borderBottomWidth="2px"
                                                    color="whiteAlpha.700"
                                                >
                                                    <Box py={4}>{labelProps.label}</Box>
                                                </Td>
                                            );
                                        });
                                    })}
                                </Tr>
                            );
                        })}
                    </Tbody>
                </Table>
            );
        }
        if (isLineVisualization(props.visualization)) {
            return <View.Chart {...props.getLineProps(props.visualization)} />;
        }
        if (isDistributionVisualization(props.visualization)) {
            return (
                <View.Distribution {...props.getDistributionProps(props.visualization)} />
            );
        }
        throw new Error('not impl');
    };
}

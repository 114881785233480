import React, { useMemo } from 'react';
import { periodToRange } from '../../../../domain';
import { WorkspaceContextV2 } from '../../../app';
import { InsightRouteConfig } from './insightRouteConfig';
import { createInsightRouteController } from './insightRouteController';
import { createInsightRouteView } from './insightRouteView';

export function createInsightRoute(
    config: InsightRouteConfig
): React.FC<React.PropsWithChildren<{}>> {
    const {
        deps: { hook, ...deps },
    } = config;
    const controller = createInsightRouteController(config);
    const View = createInsightRouteView(config);
    return deps.state.connect((props) => {
        const organization = hook.useOrganization();
        const workspace = hook.useWorkspace();
        const auth = hook.useAuth();
        const state = deps.state.useState();
        const context: WorkspaceContextV2 = {
            organization,
            workspace,
            auth,
        };

        const applied = useMemo(() => {
            return {
                period: state.value.period ?? { amount: 4, interval: 'week' },
                sorting: state.value.sorting ?? 'relevance',
            };
        }, [state.value]);

        const range = periodToRange(null, applied.period);
        const viewData = config.deps.loader.opportunity.useData(context, {
            query: {
                since: range.start,
                before: range.end,
                limit: 25,
                orderBy: [
                    {
                        key: 'priority',
                        direction: 'desc',
                    },
                    {
                        key: 'period_end_at',
                        direction: 'desc',
                    },
                ],
                aggregate: { keys: ['definition', 'kind'], take: 1 },
            },
        });
        const viewProps = controller.useProps(context, state, viewData, {});
        return <View {...viewProps}>{props.children}</View>;
    });
}

import React, { Suspense, useEffect, useMemo } from 'react';
import { FiBell } from 'react-icons/fi';
import { css } from '@emotion/react';
import { CardSpinner } from '../../../../domain';
import {
    IconButton,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverBody,
    useColorMode,
    ButtonProps,
    Box,
    DarkMode,
    Text,
    StackProps,
    HStack,
    Flex,
    Grid,
    Center,
    GridItem,
    GridProps,
    Button,
    GridItemProps,
    BadgeProps,
    IconProps,
} from '@chakra-ui/react';
import {
    getChildrenProps,
    getElements,
    fetchElements,
    getAllChildrenProps,
} from '../../../util';
import { ApplicationLinkProps } from '../../../ui';
import { useLazySuspense, LazySuspense } from '../../../vendor';
import { OrganizationLayoutConfig } from '../organizationLayoutConfig';
import { OrganizationLayoutContext } from './OrganizationLayoutHeader';
import { OrganizationLayoutBody } from './OrganizationLayoutBody';
import { OrganizationLayoutBrand } from './OrganizationLayoutBrand';
import { OrganizationLayoutMenu } from './OrganizationLayoutMenu';
import { OrganizationLayoutNavigation } from './OrganizationLayoutNavigation';
import { OrganizationLayoutItem } from './OrganizationLayoutItem';
import { OrganizationLayoutDatasetText } from './OrganizationLayoutText';
import {
    useMatch,
    useResolvedPath,
    NavLink as RouterLink,
    useLocation,
} from 'react-router-dom';

export interface OrganizationLayoutStyle {
    wrapper?: Omit<GridProps, 'children' | 'templateAreas'>;
    header?: Omit<GridProps, 'children' | 'area'>;
    main?: Omit<GridProps, 'children' | 'area'>;
    scroll?: Pick<GridProps, '__css'>;
    left?: Omit<StackProps, 'children' | 'area'>;
    button?: Omit<ButtonProps, 'children' | 'onClick'>;
    navigation?: Omit<StackProps, 'children'>;
}

export interface OrganizationLayoutWrapperProps {
    style?: Partial<OrganizationLayoutStyle>;
    children: Array<React.ReactElement | null>;
}

export function createOrganizationLayoutWrapper(
    config: OrganizationLayoutConfig & {
        OrganizationLayoutLink: React.FC<ApplicationLinkProps>;
    }
): React.FC<OrganizationLayoutWrapperProps> {
    const {
        OrganizationLayoutLink,
        UI: { Link },
    } = config;

    const NavLink: React.FC<ApplicationLinkProps> = ({ children, ...props }) => {
        const location = useLocation();
        const isActive = useMemo(() => {
            const [lastFragment] = props.to.toString().split('/').reverse();
            return location.pathname.includes(lastFragment);
        }, [props.to, location.pathname]);
        return (
            <Link {...props}>
                <Button
                    aria-selected={isActive}
                    size="sm"
                    // variant="ghost"
                    bg="none"
                    _focus={{ outline: 'none' }}
                    _hover={{ bg: 'whiteAlpha.100' }}
                    _active={{ bg: 'whiteAlpha.200' }}
                    _selected={{ bg: 'whiteAlpha.200' }}
                >
                    {children}
                </Button>
            </Link>
        );
    };

    return (props) => {
        const { Body, Navigation } = fetchElements(props.children, {
            Body: OrganizationLayoutBody,
            Navigation: OrganizationLayoutNavigation,
        });
        const { Context, Brand, Menu } = getElements(props.children, {
            Context: OrganizationLayoutContext,
            Menu: OrganizationLayoutMenu,
            Brand: OrganizationLayoutBrand,
        });

        const { DatasetText: datasetTextProps } = getChildrenProps(props.children, {
            DatasetText: OrganizationLayoutDatasetText,
        });

        const {
            Item: itemPropsList,
            Link: linkPropsList,
            Dropdown: dropdownPropsList,
        } = getAllChildrenProps(Navigation.props.children, {
            Item: OrganizationLayoutItem,
            Link: OrganizationLayoutLink,
            Dropdown: OrganizationLayoutNotifications,
        });

        const { elementRef, suspenseProps } = useLazySuspense();

        return (
            <Grid
                {...props.style?.wrapper}
                templateAreas={`
                        "header"
                        "main"
                    `}
                w="100%"
                h="100vh"
            >
                <GridItem area="header">
                    <Grid
                        {...props.style?.header}
                        templateAreas='"left center right"'
                        w="full"
                    >
                        <GridItem area="left">
                            <HStack h="100%" {...props.style?.left}>
                                <Box flexShrink={0}>{Brand}</Box>
                                {Context}
                            </HStack>
                        </GridItem>
                        <GridItem area="center">
                            <Flex h="100%" justifyContent="center" alignItems="center">
                                <Text colorScheme="varosGreen">
                                    {datasetTextProps?.children}
                                </Text>
                            </Flex>
                        </GridItem>
                        <GridItem area="right">
                            <HStack h="100%" justifyContent="flex-end" spacing={0}>
                                <HStack {...props.style?.navigation}>
                                    {linkPropsList.map((item, index) => (
                                        <NavLink key={index} to={item.to} id={item.id}>
                                            {item.children}
                                        </NavLink>
                                    ))}
                                    {itemPropsList.map((item, index) => (
                                        <HStack key={index} spacing={2}>
                                            <Box>{item.leftIcon}</Box>
                                            <Box>{item.children}</Box>
                                        </HStack>
                                    ))}
                                </HStack>
                                <Flex h="100%" justifyContent="flex-end">
                                    {Menu}
                                </Flex>
                            </HStack>
                        </GridItem>
                    </Grid>
                </GridItem>
                <GridItem
                    {...props.style?.main}
                    {...props.style?.scroll}
                    area="main"
                    overflowY="auto"
                    h="100%"
                >
                    <LazySuspense
                        {...suspenseProps}
                        fallback={
                            <Center
                                position="relative"
                                alignItems="center"
                                h="full"
                                py={4}
                                top={-8}
                            >
                                <CardSpinner />
                            </Center>
                        }
                    >
                        <Box ref={elementRef}>{Body}</Box>
                    </LazySuspense>
                </GridItem>
            </Grid>
        );
    };
}

export function createOrganizationLayoutLink(
    config: OrganizationLayoutConfig
): React.FC<ApplicationLinkProps> {
    const {
        UI: { Link },
    } = config;
    return ({ children, ...props }) => {
        return (
            <Link {...props}>
                <Button size="sm" variant="ghost">
                    {children}
                </Button>
            </Link>
        );
    };
}

interface DropdownProps {
    badge?: BadgeProps;
    // icon: IconProps;
    children: React.ReactElement;
}

export const OrganizationLayoutNotifications: React.FC<DropdownProps> = ({
    ...props
}) => {
    return <>{props.children}</>;
};

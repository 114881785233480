import { ReportShellController } from './reportShellInterface';

export function createReportShellController(): ReportShellController {
    return {
        useProps(deps, props) {
            const { location } = deps;
            const { pathname } = location;
            return {
                links: [
                    {
                        label: 'Audit',
                        to: '/r/reports',
                    },
                    {
                        label: 'Integrations',
                        to: `/u/onboarding?step=integration&cancel_path=${pathname}&success_path=${pathname}`,
                    },
                    {
                        label: 'Sign out',
                        to: `/logout`,
                    },
                ],
            };
        },
    };
}

import { Box, StackProps } from '@chakra-ui/react';
import React from 'react';

export interface DashboardLayoutContentProps {
    children: React.ReactNode;
}

export const DashboardLayoutContent: React.FC<DashboardLayoutContentProps> = (props) => (
    <>{props.children}</>
);

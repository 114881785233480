import { AxiosInstance } from 'axios';
import { LegacyAuthenticationScheme } from '../../common';
import { VisualizationResource } from './visualizationInterface';
import { VisualizationSchema } from './visualizationSchema';

export function createVisualizationResource(
    client: AxiosInstance
): VisualizationResource {
    return {
        async create(context, payload) {
            const scheme = context.auth.scheme as LegacyAuthenticationScheme;
            await scheme.store.waitAuthenticated();

            const response = await client.post(`/api/v4/visualizations`, payload, {
                headers: {
                    Authorization: `bearer ${scheme.store.authToken}`,
                },
            });

            const parsed = VisualizationSchema.parse(response.data);
            return parsed;
        },
    };
}

import React from 'react';
import { ApplicationEntryEnhancer } from '../../../../entrypoint';
import { GroupHomeViewedEvent } from './groupHomeEvent';
import { GroupPaywallViewedEvent } from '../billing';
import { useInView } from 'react-intersection-observer';
import { Box } from '@chakra-ui/react';

export function createGroupHomeTrackingStrategy(): ApplicationEntryEnhancer {
    return (create) => (config) => {
        function useTracker() {
            const tracker = instance.infra.useTracker<
                GroupHomeViewedEvent | GroupPaywallViewedEvent
            >();
            return tracker;
        }
        const Tracker: React.FC<{ onLoad(): void }> = (props) => {
            const [tracked, setTracked] = React.useState(false);
            const { ref, inView } = useInView();

            // We want to track when the component is actually rendered which
            // is not the same as when it's loaded because of suspense
            React.useEffect(() => {
                if (inView && !tracked) {
                    props.onLoad();
                    setTracked(true);
                }
            }, [inView, tracked]);
            return <Box as="span" w="1px" h="1px" ref={ref}></Box>;
        };

        const instance = create({
            ...config,
            view: {
                ...config.view,
                groups: {
                    ...config.view.groups,
                    createActivated(viewConf) {
                        const Underlying = config.view.groups.createActivated(viewConf);
                        return (props) => {
                            const tracker = useTracker();
                            // We want to track when the component is actually rendered which
                            // is not the same as when it's loaded because of suspense

                            const handleLoad = () => {
                                if (props.group) {
                                    tracker.track('private_groups_home_viewed', {
                                        group_id: props.group.id,
                                        group_membership: props.group.membership.kind,
                                        group_name: props.group.name,
                                    });
                                }
                            };

                            return (
                                <Underlying
                                    {...props}
                                    children={[<Tracker key="1" onLoad={handleLoad} />]}
                                />
                            );
                        };
                    },
                },
            },
            controller: {
                ...config.controller,
                groups: {
                    ...config.controller.groups,
                    group: {
                        ...config.controller.groups.group,
                        // createActivated(activatedProps) {
                        //     const controller =
                        //         config.controller.groups.group.createActivated(
                        //             activatedProps
                        //         );
                        //     return {
                        //         useProps(scope, data) {
                        //             const retVal = controller.useProps(scope, data);
                        //             const tracker = useTracker();

                        //             const onOpen = React.useCallback(() => {
                        //                 retVal.subscriptionAddonState.onOpen();
                        //                 if (data.group) {
                        //                     tracker.track('addon_paywall_viewed', {
                        //                         group_id: data.group.id,
                        //                         group_membership:
                        //                             data.group.membership.kind,
                        //                         group_name: data.group.name,
                        //                     });
                        //                 }
                        //             }, [data.group]);

                        //             return {
                        //                 ...retVal,
                        //                 subscriptionAddonState: {
                        //                     ...retVal.subscriptionAddonState,
                        //                     onOpen,
                        //                 },
                        //             };
                        //         },
                        //     };
                        // },
                    },
                },
            },
        });

        return instance;
    };
}

import { useMemo } from 'react';
import { GroupsConfig } from '../common';
import { buildGroupItemViewProps } from '../item';
import { GroupItemController } from './groupItemInterface';

export function createGroupItemController(
    config: Pick<GroupsConfig['deps'], 'infra'>
): GroupItemController {
    const {
        infra: { formatter },
    } = config;
    return {
        useProps(context, item) {
            const viewProps = useMemo(
                () => buildGroupItemViewProps(formatter, context, item, 0),
                [context, item.peerset]
            );
            return viewProps;
        },
    };
}

import { observer } from 'mobx-react';
import React from 'react';
import { OnboardingAssetRouteStrategy } from '../../../route';
import { OnboardingReceiptConfig } from './onboardingReceiptConfig';
import { createOnboardingReceiptView } from './onboardingReceiptView';
import { useLocation, useNavigate } from 'react-router';

export function createOnboardingReceiptItem(
    init: OnboardingReceiptConfig
): OnboardingAssetRouteStrategy {
    const { loader, hooks } = init;
    const controller = init.provider.createController(init);
    return {
        id: 'receipt',
        name: 'receipt',
        slug: 'receipt',
        create(config) {
            const Component = createOnboardingReceiptView(config);
            return {
                Component: observer((props) => {
                    const navigate = useNavigate();
                    const location = useLocation();
                    const organization = hooks.useOrganization();
                    const workspace = hooks.useWorkspace();
                    const auth = hooks.useAuth();
                    const context = { organization, workspace, auth };

                    const viewData = {
                        poll: loader.report.poll.useLoad(context, {
                            query: {
                                report: {
                                    asset: {
                                        id: workspace.id as number,
                                    },
                                },
                            },
                        }),
                        prepare: loader.prepare.useData(context, {
                            asset: {
                                id: workspace.id as number,
                            },
                        }),
                    };

                    const viewProps = controller.useProps(
                        { navigate, location },
                        context,
                        viewData,
                        {
                            asset: props.asset,
                            onContinue: props.onContinue,
                        }
                    );

                    return <Component {...viewProps} />;
                }),
            };
        },
    };
}

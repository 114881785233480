import { HStack, VStack } from '@chakra-ui/react';
import React from 'react';
import { ConversationPluginLayout } from './conversationBlockInterface';

export function createPluginView(): ConversationPluginLayout {
    return {
        Container(props) {
            return (
                <VStack align="start" spacing={4}>
                    {props.children}
                </VStack>
            );
        },
        Content(props) {
            return (
                <HStack
                    p={6}
                    borderWidth={2}
                    borderColor="whiteAlpha.300"
                    borderRadius="lg"
                >
                    {props.children}
                </HStack>
            );
        },
    };
}

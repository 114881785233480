import { observer } from 'mobx-react';
import React from 'react';
import {
    FilterItemContainerProps,
    FilterListContainerProps,
} from './filterContainerProps';

export const FilterListContainer: React.FC<FilterListContainerProps> = ({
    as: Component,
    useController,
    ...props
}) => {
    const viewProps = useController();
    return (
        <Component {...viewProps} renderFilter={props.renderFilter}>
            {props.children}
        </Component>
    );
};

export const FilterItemContainer: React.FC<FilterItemContainerProps> = observer(
    ({ as: Component, useController, ...props }) => {
        const viewProps = useController(props);
        return <Component {...viewProps}>{props.children}</Component>;
    }
);

import {
    Popover,
    PopoverTrigger,
    PopoverBody,
    PopoverContent,
    StatLabelProps,
    useTheme,
    Box,
    Flex,
    HStack,
    Stat,
    StatGroup,
    StatLabel,
    StatNumber,
    StatHelpText,
    Text,
    VStack,
    StatArrow,
} from '@chakra-ui/react';
import { Tooltip } from '../../../../ui';
import { CalculationView, useCalculationView } from '../../../../views';
import { Calculation } from '../../../../domain';
import React, { useCallback } from 'react';
import { StatMetricProps, StatVisualizationProps } from '../statProps';

export const StatVisualization: React.FC<StatVisualizationProps> = ({
    context,
    ...props
}) => {
    const theme = useTheme();
    const { getProps: getCalculationProps } = useCalculationView(context);
    const colorsLegend = theme?.colors?.legend;

    const getColor = useCallback(
        (metric: StatMetricProps): StatLabelProps['color'] => {
            if (metric.direction === 'increase' && !metric.inverse) {
                return colorsLegend?.green?.[200];
            }
            if (metric.direction === 'increase' && metric.inverse) {
                return colorsLegend?.red?.[200];
            }
            if (metric.direction === 'decrease' && !metric.inverse) {
                return colorsLegend?.red?.[200];
            }
            if (metric.direction === 'decrease' && metric.inverse) {
                return colorsLegend?.green?.[200];
            }
            return 'whiteAlpha.700';
        },
        [colorsLegend]
    );

    return (
        <VStack height="100%" alignItems="center" justifyContent="center">
            <VStack textAlign="center" spacing={4}>
                {props.metrics.map((metric, index) => {
                    const calculationProps = getCalculationProps(
                        props.getCalculation(metric)
                    );
                    return (
                        <StatGroup
                            key={metric.label}
                            width="100%"
                            // height="75%"
                            // borderRight={index === 0 ? '2px solid #5B5B5B' : ''}
                        >
                            <Flex
                                justifyContent="center"
                                alignItems="center"
                                height="100%"
                            >
                                <Stat textAlign="center" width="100%">
                                    <StatLabel
                                        color="whiteAlpha.700"
                                        whiteSpace="nowrap"
                                        fontWeight="normal"
                                        // maxWidth={width}
                                        overflow="hidden"
                                        textOverflow="ellipsis"
                                    >
                                        {metric.label}
                                    </StatLabel>

                                    <Tooltip.Tooltip
                                        placement="top-start"
                                        label={
                                            <CalculationView
                                                {...calculationProps}
                                                containerProps={{ minW: 300 }}
                                            />
                                        }
                                    >
                                        <span>
                                            <StatNumber
                                                whiteSpace="nowrap"
                                                // maxWidth={width}
                                                overflow="hidden"
                                                textOverflow="ellipsis"
                                            >
                                                <Text
                                                    fontSize={{ base: '2xl', xl: '3xl' }}
                                                >
                                                    {calculationProps.formatValue(
                                                        metric.value.current,
                                                        {
                                                            format: 'short',
                                                        }
                                                    )}
                                                </Text>
                                            </StatNumber>
                                            {metric.secondary !== undefined && (
                                                <StatHelpText
                                                    // color="whiteAlpha.700"
                                                    color={getColor(metric)}
                                                    fontWeight="medium"
                                                    overflow="hidden"
                                                    textOverflow="ellipsis"
                                                >
                                                    {metric.direction && (
                                                        <StatArrow
                                                            pb={0.5}
                                                            type={metric.direction}
                                                            color={getColor(metric)}
                                                        />
                                                    )}
                                                    {metric.secondary === null
                                                        ? 'N/A'
                                                        : metric.secondary}
                                                </StatHelpText>
                                            )}
                                        </span>
                                    </Tooltip.Tooltip>
                                </Stat>
                            </Flex>
                        </StatGroup>
                    );
                })}
            </VStack>
        </VStack>
    );
};

import { RunCreatePayloadSchema, RunObjectSchema } from '@varos/schema';
import { ResourceBaseConfig } from '../support/base';
import { RunResource } from './runInterface';

export function createRunResource(config: ResourceBaseConfig): RunResource {
    return {
        create(payload, options) {
            return config.endpoint.create({
                url: '/runs',
                payload: payload,
                schema: {
                    body: RunCreatePayloadSchema,
                    response: RunObjectSchema,
                },
                options,
            });
        },
    };
}

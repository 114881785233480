import React from 'react';
import { OrganizationScope } from '../../../../app';
import { GroupsConfig } from '../common/groupsConfig';
import { GroupsLoader } from '../loader';
import { GroupsRedirectProps } from './groupsRedirectProps';
import {
    GroupRedirectController,
    GroupRedirectControllerProps,
} from './groupRedirectInterface';

export function createGroupRedirectContainer(
    hooks: GroupsConfig['deps']['hook'],
    loader: GroupsLoader,
    controller: GroupRedirectController,
    View: React.FC<GroupsRedirectProps>
) {
    return (props: GroupRedirectControllerProps) => {
        const organization = hooks.useOrganization();
        const auth = hooks.useAuth();
        const orgContext: OrganizationScope = {
            organization,
            auth,
        };
        const data = loader.useLoad(orgContext);
        const viewProps = controller.useProps(props, data);

        return <View {...viewProps} />;
    };
}

import { isEnumType } from '../../../../domain/attributes';
import { buildFormInputProps } from '../../input';
import { REQUIRED_ERROR_MESSAGE } from './formFieldItemConstant';
import { FormFieldItemAggregate } from './formFieldItemModel';
import {
    FormFieldItemControllerProps,
    FormFieldItemViewProps,
} from './formFieldItemProps';

export function buildFormFieldItemProps(
    // form: UseFormReturn<DatasetFormFormValues>,
    // formValues: DatasetFormFormValues,
    item: FormFieldItemAggregate,
    props: FormFieldItemControllerProps
): FormFieldItemViewProps {
    return {
        id: item.property.key,
        label: item.field?.name ?? item.property.name,
        description: item.field?.description ?? item.property.description,
        isRequired: item.property.isRequired ?? false,
        input: buildFormInputProps(item, props),
        error: props.error ? fixEnumError(item, props.error) : null,
    };
}

// hack to fix the issue with required enum values being labeled as value errors instead of
// not null values. see the zod schema strategy for more details
function fixEnumError(item: FormFieldItemAggregate, error: Error | null): Error | null {
    if (
        isEnumType(item.property.type) &&
        error?.message.toLowerCase().includes('invalid literal')
    ) {
        return { ...error, message: REQUIRED_ERROR_MESSAGE };
    }
    return error;
}

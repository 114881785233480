import { keyBy } from 'lodash';
import { useMemo } from 'react';
import { AnyVisualization, buildVisualization } from '../../../domain/visualization';
import {
    ReportDetailOpportunityContainerProps,
    ReportDetailOpportunityViewProps,
} from './opportunity';
import { ReportDetailRouteConfig } from './reportDetailConfig';
import { ReportDetailRouteController } from './reportDetailInterface';
import { ReportDetailOpportunityVisualizationContainerProps } from './visualization/visualizationProps';

export function createReportDetailController(
    config: Pick<ReportDetailRouteConfig, 'controller' | 'strategy'>
): ReportDetailRouteController {
    const { controller, strategy } = config;
    return {
        useProps(context, data, props) {
            const detailProps = controller.report.useProps(context, data.item.detail, {});

            const metricsById = useMemo(
                () => keyBy(data.item.metrics, (item) => item.id),
                [data.item.metrics]
            );

            const visualizationByInsight = useMemo(() => {
                return (
                    data.item.detail.item.data?.opportunities.reduce((acc, item) => {
                        const visualizationProps = strategy.visualizable.toVisualization(
                            item.insight
                        );
                        if (!visualizationProps) {
                            return acc;
                        }
                        return {
                            ...acc,
                            [item.insight.id]: buildVisualization(
                                visualizationProps.metrics.flatMap((item) => {
                                    const metric = metricsById[item.id];
                                    return metric ? [metric] : [];
                                }),
                                visualizationProps
                            ),
                        };
                    }, {} as Record<string, AnyVisualization | undefined>) ?? {}
                );
            }, [data.item.detail.item.data?.opportunities, metricsById]);

            if (detailProps.status !== 'success') {
                return {
                    status: 'loading',
                };
            }

            return {
                status: 'success',
                getDetailProps() {
                    return detailProps;
                },
                getOpportunityItemProps(
                    item
                ): Omit<ReportDetailOpportunityContainerProps, 'as'> {
                    const visualization = visualizationByInsight[item.insight.id] ?? null;
                    return {
                        item,
                        visualization,
                    };
                },
                getVisualizationProps(
                    item
                ): Omit<ReportDetailOpportunityVisualizationContainerProps, 'as'> | null {
                    const visualization = visualizationByInsight[item.insight.id];
                    if (!visualization || !data.item.detail.item.data?.asset) {
                        return null;
                    }
                    return { asset: data.item.detail.item.data.asset, visualization };
                },
            };
        },
    };
}

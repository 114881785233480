import { ConversationPluginConfig } from '../../../../route';
import { ConversationEntityController } from './blockEntityInterface';
import { ConversationEntityViewProps } from './blockEntityProps';

export function createConversationSurveyController(
    config: ConversationPluginConfig
): ConversationEntityController {
    const {} = config;
    return {
        useProps(item, props): ConversationEntityViewProps {
            return { label: item.entity.name };
        },
    };
}

import { observer } from 'mobx-react';
import React from 'react';
import { BreakdownController, BreakdownStateProvider } from './breakdownInterface';
import {
    AnalysisBreakdownControllerProps,
    AnalysisBreakdownViewProps,
} from './breakdownProps';

export function createBreakdownContainer(
    states: BreakdownStateProvider,
    controller: BreakdownController,
    Component: React.FC<AnalysisBreakdownViewProps>
): React.FC<AnalysisBreakdownControllerProps> {
    return observer((props) => {
        const state = states.useState(props.context);
        const viewProps = controller.useProps(state, props);
        return <Component {...viewProps} />;
    });
}

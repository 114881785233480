import * as React from 'react';
import { useForm } from 'react-hook-form';
import {
    CompanyType,
    Organization,
    User,
    UserUpdateRequest,
} from '../../../models/Account';
import isUrl from 'validator/es/lib/isURL';
import { ErrorInfo } from '../../../models/Errors';
import {
    Button,
    Text,
    Container,
    Heading,
    SimpleGrid,
    GridItem,
    VStack,
    Image,
    Center,
} from '@chakra-ui/react';
import ErrorView from '../../ErrorView/ErrorView';
import Input from '../../FormControls/Input';
import Select from '../../FormControls/Select';
import playSVG from '../../../svg/play.svg';
import { useLocalStorage } from 'react-use';
export interface AdvancedProps {
    isLoading: boolean;
    err: ErrorInfo | null;
    user: User;
    organization?: Organization;
    updateUser: (user: UserUpdateRequest) => void;
}

const TITLES = [
    'Agency - CEO',
    'Agency - VP or Director or C-Suite',
    'Agency - Account Manager or Other',
    'Brand - Marketer or other',
    'Brand - CMO/CFO, VP or Director',
    'Brand - CEO',
    'SaaS - CEO',
    'SaaS - VP or Director or C-Suite',
    'SaaS - Marketer or Other',
    'Other',
].map((name) => ({ name, value: name }));

const COMPANY_TYPES: Array<{ name: string; value: CompanyType }> = [
    { name: 'eCommerce', value: 'ecommerce_website' },
    { name: 'Mobile App', value: 'mobile_app' },
    { name: 'SaaS', value: 'saas' },
    { name: 'Marketplace', value: 'marketplace' },
    { name: 'Ad Agency', value: 'ad_agency' },
    { name: 'Other', value: 'website' },
];

// const validateUrl = (v: string) => {
//     if (
//         v &&
//         !isUrl(v, {
//             require_valid_protocol: false,
//             require_host: true,
//             require_tld: true,
//             allow_protocol_relative_urls: false,
//         })
//     ) {
//         return 'Invalid URL';
//     }
// };

// const EXTERNAL_IDENTIFIER_NAMES: Record<
//     CompanyType,
//     { name: string; validate?: (v: string) => string | undefined } | undefined
// > = {
//     ecommerce_website: {
//         name: 'Product URL',
//         validate: validateUrl,
//     },
//     saas: {
//         name: 'Product URL',
//         validate: validateUrl,
//     },
//     marketplace: {
//         name: 'Product URL',
//         validate: validateUrl,
//     },
//     website: {
//         name: 'Product URL',
//         validate: validateUrl,
//     },
//     mobile_app: {
//         name: 'packageName/bundleId/appId or App Name',
//         validate: validateUrl,
//     },
//     ad_agency: {
//         name: 'Website URL',
//         // validate: 'required'
//     },
// };
export type REG_KEY =
    | 'first_name'
    | 'last_name'
    | 'organization_name'
    | 'organization_url'
    | 'company_type';
export const AdvancedRegistrationForm: React.FunctionComponent<AdvancedProps> = ({
    isLoading,
    err,
    updateUser,
    user,
    organization,
}) => {
    const {
        formState,
        handleSubmit,
        setValue,
        register,
        control,
        getValues,
        setError,
        reset,
        watch,
    } = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange',
        defaultValues: {
            first_name: '',
            last_name: '',
            organization_name: '',
            organization_url: '',
            company_type: COMPANY_TYPES[0].value,
            promo_code: '',
            title: null,
        },
    });
    React.useEffect(() => {
        const usrToUpdate: Record<string, any> = { company_type: COMPANY_TYPES[0].value };
        if (user.first_name) {
            usrToUpdate['first_name'] = user.first_name;
        }
        if (user.last_name) {
            usrToUpdate['last_name'] = user.last_name;
        }
        if (user.title) {
            usrToUpdate['title'] = user.title;
        }

        if (organization) {
            if (organization.domain) {
                usrToUpdate['organization_url'] = organization.domain;
            }
            if (organization.name) {
                usrToUpdate['organization_name'] = organization.name;
            }
            if (organization.company_type) {
                usrToUpdate['company_type'] = organization.company_type;
            }
        }
        reset(usrToUpdate);
    }, [user, organization]);
    React.useEffect(() => {
        register(`company_type`, {
            required: 'Required',
        });
    }, []);
    React.useEffect(() => {
        if (err && err.fieldErrors) {
            err.fieldErrors.forEach(({ loc, msg }) => {
                if (loc.length == 2) {
                    if (loc[1])
                        setError(loc[1] as REG_KEY, {
                            message: msg,
                            type: 'invalid',
                        });
                } else if (loc.length == 3 && loc[1] == 'organization') {
                    let field: REG_KEY | null = null;
                    switch (loc[2]) {
                        case 'name':
                            field = 'organization_name';
                            break;
                        case 'domain':
                            field = 'organization_url';
                            break;
                        default:
                            break;
                    }
                    if (field) {
                        setError(field, {
                            message: msg,
                            type: 'invalid',
                        });
                    }
                }
            });
        }
    }, [err]);
    // const compType: CompanyType | undefined = watch('company_type');
    // const orgUrl: string | undefined = watch('organization_url');
    // const extIdentifier = compType ? EXTERNAL_IDENTIFIER_NAMES[compType] : undefined;

    const [profile, setProfile] = useLocalStorage<{ title: string } | null>(
        '_onboarding_profile',
        null
    );

    const onSubmit = async (data: any) => {
        const {
            first_name,
            last_name,
            title,
            organization_name,
            organization_url,
            company_type,
            promo_code,
        } = data;
        updateUser({
            first_name,
            last_name,
            //   email,
            title,
            asset_url: organization_url,
            //   password,
            with_customer: true,
            organization: {
                role: 'admin',
                organization: {
                    id: organization?.id,
                    name: organization_name,
                    domain: organization_url,
                    company_type,
                    promo_code,
                    created_at: organization?.created_at ?? new Date(),
                },
            },
        });
    };
    return (
        <Container centerContent maxW="md">
            <VStack
                w="full"
                h="full"
                p={10}
                align="stretch"
                spacing={8}
                border="2px"
                borderColor="frame"
                borderRadius={10}
                bg="box"
            >
                <VStack>
                    <Heading size="lg" textAlign="center" color="onboarding.title">
                        Account information
                    </Heading>
                    {/* <Text fontSize="lg" color="gray.500">
                        Enter your details below
                    </Text> */}
                </VStack>
                <form onSubmit={handleSubmit(onSubmit)} id="advanced_frm">
                    <SimpleGrid columns={2} columnGap={4} rowGap={5} w="full">
                        {/* <Box mb={2}>
                            <Text>Email: {user.email}</Text>
                        </Box> */}
                        {/* <GridItem colSpan={2}>
                            <Input bg="white" label="Email" type="text" value={user.email} isDisabled />
                        </GridItem> */}
                        <GridItem colSpan={1}>
                            <Input
                                bg="button.white"
                                placeholder="First Name"
                                {...register('first_name', {
                                    required: 'Required',
                                })}
                                err={
                                    formState.errors.first_name &&
                                    formState.errors.first_name.message
                                }
                                label="First Name"
                                type="text"
                            />
                        </GridItem>
                        <GridItem colSpan={1}>
                            <Input
                                bg="button.white"
                                placeholder="Last Name"
                                {...register('last_name', {
                                    required: 'Required',
                                })}
                                label="Last Name"
                                err={
                                    formState.errors.last_name &&
                                    formState.errors.last_name.message
                                }
                                type="text"
                            />
                        </GridItem>
                        <GridItem colSpan={2}>
                            <Select
                                bg="button.white"
                                {...register('title', {
                                    required: 'Required',
                                })}
                                label="Title"
                                placeholder="Select Title"
                                options={TITLES}
                                err={
                                    formState.errors.title &&
                                    formState.errors.title.message
                                }
                            />
                        </GridItem>
                        <GridItem colSpan={2}>
                            <Input
                                bg="button.white"
                                placeholder="Company Name"
                                {...register('organization_name', {
                                    required: 'Required',
                                })}
                                label="Company Name"
                                err={
                                    formState.errors.organization_name &&
                                    formState.errors.organization_name.message
                                }
                                type="text"
                            />
                        </GridItem>
                        <GridItem colSpan={2}>
                            <Input
                                bg="button.white"
                                placeholder="Domain"
                                {...register('organization_url', {
                                    validate: (v) => {
                                        if (
                                            v &&
                                            !isUrl(v, {
                                                require_valid_protocol: false,
                                                require_host: true,
                                                require_tld: true,
                                                allow_protocol_relative_urls: false,
                                            })
                                        ) {
                                            return 'Invalid URL';
                                        }
                                    },
                                    required: 'Required',
                                })}
                                label="Company Website"
                                err={
                                    formState.errors.organization_url &&
                                    formState.errors.organization_url.message
                                }
                                type="text"
                            />
                        </GridItem>
                        <GridItem colSpan={2}>
                            <Select
                                bg="button.white"
                                {...register('company_type')}
                                label="Company Type"
                                options={COMPANY_TYPES}
                                err={
                                    formState.errors.company_type &&
                                    formState.errors.company_type.message
                                }
                            />
                        </GridItem>
                        <GridItem colSpan={2}>
                            <Button
                                w="full"
                                textColor="button.white"
                                rightIcon={<Image pt={0.5} src={playSVG} />}
                                boxShadow="lg"
                                bg="button.green"
                                id="advanced_btn"
                                type="submit"
                                _hover={{ bg: 'button.hoverGreen' }}
                                isLoading={isLoading}
                            >
                                Continue
                            </Button>
                        </GridItem>
                        {err && (
                            <Center>
                                <ErrorView {...err} />
                            </Center>
                        )}
                    </SimpleGrid>
                </form>
            </VStack>
        </Container>
    );
};

import React from 'react';
import { StudiesContextProvider } from '../../base';
import {
    StudiesResponseItemController,
    StudiesResponseItemLoader,
} from './studiesResponseItemInterface';
import { StudiesResponseItemContainerProps } from './studiesResponseItemProps';

export function createStudiesResponseItemContainer(
    context: StudiesContextProvider,
    loader: StudiesResponseItemLoader,
    controller: StudiesResponseItemController
): React.FC<StudiesResponseItemContainerProps> {
    return ({ as: View, children, ...props }) => {
        const viewContext = context.useContext();
        const viewData = loader.useLoad(viewContext, props);
        const viewProps = controller.useProps(viewContext, viewData, props);
        return <View {...viewProps}>{children}</View>;
    };
}

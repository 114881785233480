import { z } from 'zod';

export const StringTypeSchema = z.literal('string');

export const CurrencyTypeSchema = z.literal('currency');

export const IntegerTypeSchema = z.literal('integer');

export const FloatTypeSchema = z.literal('float');

export const BooleanTypeSchema = z.literal('boolean');

export const DateTypeSchema = z.literal('date');

export const PrimitiveTypeSchema = z.union([
    StringTypeSchema,
    IntegerTypeSchema,
    FloatTypeSchema,
    BooleanTypeSchema,
    CurrencyTypeSchema,
    DateTypeSchema,
]);

export const EnumMemberSchema = z.object({
    value: z.string(),
    display_name: z.string().optional(),
    source: z.array(z.union([z.string(), z.number()])),
});

export const EnumTypeSchema = z.object({
    kind: z.literal('enum'),
    members: z.array(EnumMemberSchema),
});

export const AnyTypeSchema = z.union([PrimitiveTypeSchema, EnumTypeSchema]);

import { keyByMapSafe } from '../../../../util';
import { CompanyEntity } from '../../../../app/companies';
import { PeerSetCreateProps, PeerSetEntity } from '../../../../app';
import { assert } from '../../../../util/assert';
import { AccountInvitationBulkProps } from '../../../../app/accounts';
import { Reference } from '../../../../base';
import { Asset } from '../../../../domain/assets';
import { PeerSetServiceCreateProps } from '../peersetProps';

export function buildPeerSetCreateProps(
    item: {
        companies: CompanyEntity[];
    },
    props: PeerSetServiceCreateProps
) {
    const acc: PeerSetCreateProps = {
        name: props.name ?? 'Unnamed',
        members: [],
    };
    const itemById = keyByMapSafe(item.companies, (company) => {
        const asset: Reference<Asset> | null =
            company.profiles.flatMap((item) =>
                item.kind === 'varos' ? [{ id: Number(item.id) }] : []
            )[0] ?? null;
        return [
            company.id,
            {
                company,
                asset,
            },
        ];
    });

    for (const id of props.companies) {
        const item = itemById[id] ?? null;
        assert(item, `not company found`);
        if (!item.asset) {
            // this company is not registered, send invitation instead
            continue;
        }
        acc.members.push({
            asset: item.asset.id,
        });
    }
    return acc;
}

export function buildCreateInvitationBulkProps(item: {
    peerset: PeerSetEntity;
    companies: CompanyEntity[];
}) {
    const { peerset } = item;
    const acc: AccountInvitationBulkProps = {
        items: [],
    };
    const itemById = keyByMapSafe(item.companies, (company) => {
        const asset: Reference<Asset> | null =
            company.profiles.flatMap((item) =>
                item.kind === 'varos' ? [{ id: Number(item.id) }] : []
            )[0] ?? null;
        return [
            company.id,
            {
                company,
                asset,
            },
        ];
    });

    for (const company of item.companies ?? []) {
        const item = itemById[company.id] ?? null;
        assert(item, `not company found`);
        if (item.asset) {
            // this company is already an asset, skip
            continue;
        }
        acc.items.push({
            kind: 'create',
            data: {
                id: item.company.id,
                kind: 'company',
                source: {
                    kind: 'group',
                    id: peerset.id,
                },
            },
        });
    }
    return acc;
}

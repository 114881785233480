import { Helmet, HelmetProvider } from 'react-helmet-async';
import React, { useEffect } from 'react';
import Frame from 'react-frame-component';
import { EmailConfig } from './emailConfig';
import { EmailComponents } from './emailInterface';
import { createInitialContent } from './emailHelper';
import { snakeCase } from 'lodash';

export function createEmail(config: EmailConfig): EmailComponents {
    return {
        Html(props) {
            return (
                <Frame
                    name={config.frameName}
                    width="100%"
                    height="100%"
                    initialContent={createInitialContent(config)}
                >
                    <HelmetProvider>
                        <div id={props.id} className={props.className}>
                            {props.children}
                        </div>
                    </HelmetProvider>
                </Frame>
            );
        },
        Meta({ children, ...props }) {
            const title = Array.isArray(props.title)
                ? props.title.join('')
                : String(props.title);

            useEffect(() => {
                const [iframe, ...rest] = document.getElementsByName(config.frameName);
                if (rest.length > 0) {
                    console.warn('unexpected number of frame elements selected');
                }
                // @ts-expect-error
                var doc = iframe.contentWindow.document.head;

                var element = document.createElement('title');
                element.innerHTML = title;

                doc.append(element);

                for (const [key, value] of Object.entries(props.metadata ?? {})) {
                    let metaElement = document.createElement('meta');
                    metaElement.name = `template:metadata:${snakeCase(key)}`;
                    metaElement.content = value.toString();
                    doc.append(metaElement);
                }
            }, []);

            // to support previewing the title in the browser we also add the title to the top-level document
            // using Helmet
            return (
                <Helmet>
                    <title>{title}</title>
                </Helmet>
            );
        },
        Container(props) {
            return (
                <table
                    width="100%"
                    {...props}
                    cellPadding="0"
                    cellSpacing="0"
                    role="presentation"
                >
                    <tbody>
                        <tr>
                            <td>{props.children}</td>
                        </tr>
                    </tbody>
                </table>
            );
        },
        Embed(props) {
            return <>{props.children}</>;
        },
        Section({ spacing, ...props }) {
            let inner: React.ReactElement[] | React.ReactElement | null = null;
            if (Array.isArray(props.children)) {
                inner = React.Children.map(props.children, (child, index) => {
                    return (
                        <tr key={index}>
                            <td style={{ paddingTop: index === 0 ? undefined : spacing }}>
                                {child}
                            </td>
                        </tr>
                    );
                });
            } else {
                inner = (
                    <tr>
                        <td>{props.children}</td>
                    </tr>
                );
            }
            return (
                <table
                    width="100%"
                    {...props}
                    cellPadding="0"
                    cellSpacing="0"
                    role="presentation"
                >
                    <tbody>{inner}</tbody>
                </table>
            );
        },
        Divider(props) {
            return (
                <hr
                    {...props}
                    style={{
                        width: '100%',
                        border: 'none',
                        borderTop: '1px solid #eaeaea',
                    }}
                />
            );
        },
        Row({ width = '100%', ...props }) {
            return (
                <table
                    width={width}
                    {...props}
                    role="presentation"
                    cellSpacing="0"
                    cellPadding="0"
                >
                    <tbody
                    // style={{ width: '100%' }}
                    >
                        <tr
                        // style={{ width: '100%' }}
                        >
                            {props.children}
                        </tr>
                    </tbody>
                </table>
            );
        },
        Column({ spacing, ...props }) {
            let baseProps: Partial<
                React.DetailedHTMLProps<
                    React.HTMLAttributes<HTMLHeadingElement>,
                    HTMLHeadingElement
                >
            > = {
                style: {},
            };
            if (props.align === 'center') {
                baseProps.style = { ...baseProps.style, textAlign: 'center' };
            }
            if (props.align === 'right') {
                baseProps.style = { ...baseProps.style, textAlign: 'right' };
            }
            if (!Array.isArray(props.children)) {
                return (
                    <td {...props}>
                        {React.cloneElement(props.children, {
                            ...props.children.props,
                            style: {
                                ...baseProps.style,
                                ...props.children.props.style,
                            },
                        })}
                    </td>
                );
            }
            return (
                <td {...props}>
                    {React.Children.map(props.children, (child, index) => {
                        if (!child) {
                            return <></>;
                        }
                        return React.cloneElement(child, {
                            ...child.props,
                            style: {
                                ...child.props.style,
                                marginTop: index === 0 ? undefined : spacing,
                                marginBottom: 0,
                            },
                        });
                    })}
                </td>
            );
        },

        Heading({ children, textAlign, ...props }) {
            const type = `h${props.level}` as const;
            let baseProps: Partial<
                React.DetailedHTMLProps<
                    React.HTMLAttributes<HTMLHeadingElement>,
                    HTMLHeadingElement
                >
            > = {
                style: {
                    margin: 0,
                },
            };
            if (textAlign === 'center') {
                baseProps.style = { ...baseProps.style, textAlign };
            }
            return React.createElement(type, { ...props, ...baseProps }, children);
        },
        Text(props) {
            return <p style={{ margin: 0, ...props.style }} {...props} />;
        },
        Link(props) {
            return (
                <a
                    style={{
                        ...props.style,
                        // color: '#416cbb'
                    }}
                    {...props}
                />
            );
        },
        Button(props) {
            return <button {...props} />;
        },
        Image({ width, height, ...props }) {
            // NOTE it's important that width and height is specicied without unit/px
            // as otherwise the sizing will not be applied in outlook desktop client
            // https://stackoverflow.com/questions/41789814/outlook-shows-images-at-original-size
            return (
                <img
                    style={{ display: 'block', border: 'none', ...props.style }}
                    width={width}
                    height={height}
                    {...props}
                />
            );
        },
    };
}

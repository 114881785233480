import { compose } from 'lodash/fp';
import { Tooltip } from '@chakra-ui/react';
import React from 'react';
import { Route, Outlet } from 'react-router-dom';
import { CalculationView } from '../../../views';
import { useStore } from '../../../stores/setupContext';
import {
    OrganizationReference,
    WorkspaceReference,
    AuthenticationV2,
    UserReference,
} from '../../domain/platform';
import { createToaster, VarosRoutes } from '../../../infra';
import { createMovementAtom, createEnumAtom } from '../../atom/attributes';
import {
    createApplicationEntrypoint,
    applyOrganizationEnhancers,
    applyDashboardEnhancers,
} from '../../entrypoint';
import { ViewPartitionKeyBySlug } from '../../../config/view';
import {
    createInsightModule,
    createOpportunityCollectionLoader,
    createOpportunityCollectionController,
    createOpportunityItemController,
} from '../../view/insights';
import { createReportModule, createScheduledReportsModule } from '../../view/_reportsold';
import {
    ControlWorkspaceHook,
    createChargeRepository,
    createClientPreferenceRepository,
    createCollaboratorRepository,
    createCompetitveSetStatusRepository,
    // createConnectionRepository,
    createConnectionRepositoryV2,
    createCountRepository,
    createFeatureRepository,
    createOrganizationProvider,
    createOrganizationRepository,
    createPaymentMethodRepository,
    createPluginRepository,
    createQueryResponseRepository,
    createRegistrationOptionRepository,
    createReportInvitationRepository,
    createReportRepository,
    createReportV2Repository,
    createSetupIntentRepository,
    createSubscriptionPlanRepository,
    createSubscriptionRepository,
    createViewRepository,
    useOrganizationScope,
    useStorageContext,
    useUserContext,
    useWorkspaceContext,
} from '../../app';
import {
    createGroupRepository,
    createGroupMemberRepository,
    createGroupInviteRepository,
} from '../../app/groups';
import {
    createPeerTrackingStrategy,
    createAssetChangedTrackingStrategy,
    createDashboardRankingTrackingStrategy,
    createFeatureInsightsStrategy,
    createFeatureMultiAssetStrategy,
    createInsightTrackingStrategy,
    createQueryParamFormStrategy,
    createRendererStrategy,
    createReportLoadTrackingStrategy,
    createSettingsWorkspaceManagementTrackingStrategy,
    createSettingsUserManagementTrackingStrategy,
    createHomeTrackingStrategy,
    createHomeMetricItem,
    createNavigationTrackingStrategy,
    createTooltipTrackingStrategy,
    createFeatureDynamicDashboardStrategy,
    createFeatureSubcategoryStrategy,
    createOnboardingPeerGroupItem,
    createOnboardingMappingItem,
    createOnboardingPluginItemController,
    createFeatureMiddleware,
    applyFeatureMiddleware,
    createFeatureDataFiltersStrategy,
    createInsightVisualizable,
    createMetricVisualizable,
    createQueryableVisualization,
    createHomeOpportunityController,
    createMetricTrackingStrategy,
    createOnboardingMappingItemV2,
    createOnboardingAssetItem,
    createOnboardingPrepareItem,
    createOnboardingProfileItem,
    createOnboardingIntegrationItem,
    createOnboardingProfileController,
    createTrackingOnboardingStrategy,
    createOnboardingIntegrationController,
    createOnboardingAssetController,
    createOnboardingMappingController,
    createOnboardingPeerGroupController,
    createOnboardingPrepareController,
    createHomeIntegrationController,
    createHomeIntegrationLoader,
    createHomeOpportunityLoader,
    createHomeAlertLoader,
    createHomeAlertController,
    createHomeDestinationLoader,
    createHomeDestinationController,
    createOnboardingMetricItem,
    createOrganizationAuditLayout,
    createOnboardingReportItem,
    createOnboardingReceiptItem,
    createReportPollLoader,
    createReportPollController,
    createOnboardingMetricController,
    createOnboardingReportController,
    createOnboardingReceiptController,
    createVisualizationQueryResponseStrategy,
    createSettingAccountAssetStrategy,
    createSettingAssetMappingStrategy,
    createSettingAssetProfileStrategy,
    createSettingIntegrationStrategy,
    createTrackingSettingsStrategy,
    createSettingAssetPluginStrategy,
    createPageTrackingStrategy,
    createAlertTrackingStrategy,
    createSettingBillingStrategy,
    createGroupTrackingStrategy,
    createSettingDatasetConnectionStrategy,
    createSettingDataSourceStrategy,
    createSettingImportStrategy,
    createHomeGuideLoader,
    createHomeGuideController,
    createHomeExpertCallController,
    createLinkTrackingStrategy,
    createHelpTrackingStrategy,
    createModalTrackingStrategy,
    createBillingTrackingStrategy,
    createFormsZodSchemaStrategy,
    createFormTrackingStrategy,
    createSearchTrackingStrategy,
    createHomeExpertCallLoader,
    // createStudiesVisualizable,
    createFeaturePluginsStrategy,
    createOnboardingCompetitiveSetItem,
    createOnboardingCompetitiveSetController,
} from '../../strategy';
import {
    GRAY,
    RedirectIds,
    ViewIds,
    SourceIcons,
    VAROS_GREEN,
    PageIds,
    DEFAULT_COLLECTION_FN,
    VISUALIZABLE_CONFIG,
    getSupportedOnboardingIntegrations,
    FEATURE_FLAG_STORAGE_KEY,
    ModalIds,
    AUTOHIDE_HOME_SECTION_AFTER_DAYS,
    REPORT_INVITATIONS,
    ReportDefinitions,
    // TOP_COMPANY_REPORT_CALLBACK,
} from '../../config';
import { INSTALL_ROUTE } from '../install';
import { INTEGRATION_ROUTE } from '../integration';
import {
    FeatureEnhancerConfig,
    createOrganizationFeatureMiddleware,
    createDashboardFeatureMiddleware,
} from '../feature';
import { createReportQueryFromMetrics, createReports } from '../../config/report-legacy';
import { createDashboardTrackingMiddleware } from '../tracking';
import { createDashboardRecommendationMiddleware } from '../recommendation';
import { createRedirectMiddleware, RedirectConfig } from '../redirect';
import {
    createSegmentStatusImpl,
    createDependencyStatusImpl,
    createReportStore,
    createIntlFormatter,
    createCalculationImpl,
    createConnectionImpl,
    createPeerStatusImpl,
    createCollectionImpl,
    createMetricConfigurationImpl,
    createErrorFormatterImpl,
    createApiDashboardImpl,
    createAxios,
    createAnalysisQueryExecutorImpl,
    createAnalysisStateImpl,
    createMemberImpl,
    createCompetitiveStatusImpl,
    createTraitImpl,
    createPluginImpl,
    createCompetitiveSetImpl,
    createPeerGroupImpl,
    createProfileImpl,
    createClientPreferenceImpl,
    createMetricDefinitionImpl,
    createViewImpl,
    createMetricSummaryImpl,
    createCollaboratorImpl,
    createRegistrationOptionImpl,
    createFeaturesetImpl,
    createQueryResponseImpl,
    createOrganizationImpl,
    createPermissionRoleImpl,
    createVisualizationUI,
    createGroupsImpl,
    createGroupMemberImpl,
    createSubscriptionPlanImpl,
    createSubscriptionV2Impl,
    createPaymentMethodImpl,
    createDatasetImpl,
    createDatasetConnectionImpl,
    createDatasetImportImpl,
    createDatasetParserProvider,
    createUrlOptionParser,
    createInputUI,
    createGroupInviteImpl,
    createFormSubmissionImpl,
    createAccountInvitationImpl,
    createCountImpl,
    createFormDataImpl,
    createHelpImpl,
    createReportV2Impl,
    createDatesUI,
    createChargeApiImpl,
    createCompanyImpl,
    createIndustryImpl,
    createPeerSetImpl,
    createRecommendationImpl,
    createPeerSetMemberImpl,
    createReportInvitationImpl,
    createPeerHealthcheckImpl,
    createSetupIntentImpl,
    createVisualizationImplV2,
} from '../../impl';
import { configureStorageProvider } from '../storage';
import { createReportService } from '../../service/report';
import { createChakraUI } from '../../impl/chakra';
import { createSettingsLayout } from '../../layout/settings';
import {
    createAssetApi,
    createBillingApi,
    createDashboardApi,
    createInsightApi,
    createMetricApi,
    createNotificationApi,
    createPlatformApi,
    createQueryApi,
    createReportApi,
    createGroupApi,
    createDataIngestionApi,
    createReportApiV2,
    createCompanyApi,
    createVisualizationApi,
} from '../../api';

import {
    createCollaboratorService,
    createPluginService,
    createScheduledReportService,
    createUserService,
    createWorkspaceService,
} from '../../service';
import { createSettingsUI } from '../../impl/chakra';
import {
    createAnalysisFilterController,
    createDashboardRoute,
    createHomeAlertView,
    createHomeOpportunityView,
    createHomeRoute,
    createInsightRoute,
    createInsightRouteV2,
    createMetricAnalysisRoute,
    createOnboardingAssetRoute,
    createOnboardingOrganizationRoute,
    createOnboardingUserRoute,
    createOrganizationRoute,
    createReportDetailRoute,
    createReportListRoute,
    createReportSelectRoute,
    createReportShellRoute,
    createSettingAccountRoute,
    createVisualizationController,
    createWorkspaceRoute,
    createWorkspaceRouteController,
    getAnalysisMetricLink,
    createGroupsAnalyzeRoute,
    createGroupsSettingsRoute,
    createGroupsActivatedView,
    createReportRouter,
    createNetworkRouter,
    createChargeCompleteRoute,
    configurePeerRouter,
    configureRegistrationRouter,
    configureConversationRouter,
} from '../../route';
import {
    createWorkspaceNavigationView,
    createWorkspaceNavigationController,
    createWorkspaceNavigationContainer,
    createAssetMappingModule,
    createWorkspaceSelectionModule,
    createCohortModule,
    createMetricModule,
    METRIC_FIXTURE_SIMPLE,
    createSegmentItemController,
    createSegmentItemContainer,
    createRatingController,
    createAccountProfileLoader,
    createAccountProfileController,
    createMetricCollectionNewForm,
    createMetricCollectionNewLoader,
    createMetricCollectionNewController,
    createUserManagementModule,
    createMetricCollectionEditLoader,
    createEntityControlController,
    createEntityListController,
    createIntegrationListStateProvider,
    createIntegrationListController,
    createIntegrationItemController,
    createPluginListController,
    createPluginItemController,
    createGroupsActivatedController,
    createGroupsIntroController,
    createGroupRedirectController,
    createGroupsFormController,
    createGroupsLoader,
    createGroupListController,
    createGroupDetailController,
    createGroupItemController,
    createInviteFormController,
    createPlatformHelpController,
} from '../../view';
import { createCacheEnhancer } from '../cache';
import {
    SESSION_SOURCE_TRIGGER_URL_PARAMETER_KEY,
    SESSION_SOURCE_MEDIUM_PARAMETER_KEY,
    SESSION_SOURCE_NAME_PARAMETER_KEY,
    SESSION_SOURCE_INSIGHT_ID_PARAMETER_KEY,
} from '../../config/tracking';
import { createSessionEnhancer } from '../session';
import { UseTrackerHook } from '../../base';
import { AnyQueryResponse } from '../../domain/query';
import {
    createAnalysisLayout,
    createDashboardLayout,
    createDomainLayout,
    createDomainLayoutController,
    createDomainLayoutView,
    createHomeLayout,
    createOnboardingLayout,
    createOrganizationLayout,
    createPageLayout,
    createWorkspaceLayout,
} from '../../layout';
import {
    createReadStatusEnhancer,
    createFeatureFlagEnhancer,
    createOnboardingEnhancer,
    createFeedbackEnhancer,
    createPreferenceEnhancer,
    createMockEnhancer,
    createFormatEnhancer,
    createDefaultsEnhancer,
    createMetricDashboardAccessEnhancer,
    createLinkOverridesEnhancer,
    createRecommendationEnhancer,
    createOnboardingRecommendationEnhancer,
    createApplicationMiddleware,
} from '../../middleware';

import { QUERYBUILDER } from '../../../container';
import { createWorkspaceInsightContainer } from '../../strategy/workspace';
import { createNotificationModule } from '../../view/notifications';
import { createUserRoute } from '../../route/user';
import { createWorkspaceManagementModule } from '../../view/platform/workspace';
import { WorkspaceControl } from '../../domain';
import {
    createOrganizationProviderV2,
    createSystemProvider,
    createWorkspaceProvider,
} from '../../context';
import { DashboardSlugs } from '../../../config/dashboard';
import {
    createWorkspaceDefaultDashboardRedirect,
    createWorkspaceRedirectController,
} from '../workspace';
import { createMemberRepository } from '../../app/attributes';
import {
    createExampleController,
    createGroupEditController,
    createPeerDatasetEligibilityController,
    createPeerFilterListController,
    createPeerGroupItemController,
    createPreviewController,
    createTraitController,
} from '../../view/peer';
import {
    createPeerStatusRepository,
    createPeerGroupRepository,
    createTraitRepository,
    createPeerSetRepository,
    createPeerSetMemberRepository,
    createPeerHealthcheckRepository,
} from '../../app/peers';
import { createPeerGroupService, createPeerSetService } from '../../service/peers';
import { createPropertyInputController } from '../../strategy/input';
import {
    createBreakdownForm,
    createDateForm,
    createFilterForm,
} from '../../service/query';
import { createOpportunityRepository } from '../../app/insight/opportunity';
import {
    createInsightDefinitionImpl,
    createInsightStateProvider,
    createOpportunityImpl,
} from '../../impl/insights';
import { createOpportunityService } from '../../service/insight';
import {
    createAssetRepository,
    createDestinationRepository,
    createIntegrationDefinitionRepository,
    createIntegrationRepository,
    createMappableEntityRepository,
    createMappingConnectionRepository,
} from '../../app/assets';
import {
    createAssetImpl,
    createDestinationDefinitionImpl,
    createIntegrationDefinitionImpl,
    createIntegrationImpl,
    createMappableEntityImpl,
    createMappingConnectionImpl,
} from '../../impl/assets';
import {
    createAssetService,
    createIntegrationService,
    createOrganizationMappingService,
} from '../../service/assets';
import { createGroupsService } from '../../service/groups';
import {
    createAssetNewController,
    createAssetPrepareController,
    createAssetPrepareLoader,
    createAssetStateListProvider,
    createOrganizationMappingController,
    createOrganizationMappingLoader,
    createAssetEditFormProvider,
    createAssetEditController,
    createAssetNewFormProvider,
    createAssetListController,
} from '../../view/assets';
import {
    createOnboardingAssetStateService,
    createOnboardingOrganizationStateService,
} from '../../service/onboarding';
import { createAccountProfileService } from '../../service/accounts';
import {
    createAccountInvitationRepository,
    createAccountProfileRepository,
} from '../../app/accounts';
import { createLocalStorageHook } from '../../util';
import {
    createMetricCollectionRepository,
    createMetricConfigurationRepository,
    createMetricDashboardRepository,
    createMetricDefinitionRepository,
    createMetricSummaryRepository,
} from '../../app/metrics';
import {
    createReportDetailController,
    createReportDetailLoader,
    createReportListController,
    createReportListLoader,
    createReportNewController,
    createReportNewFormProvider,
    createReportNewLoader,
    createReportSelectController,
    createReportSelectFormProvider,
    createReportSelectLoader,
} from '../../view/reports';
import { createReportImpl } from '../../impl/reportsold';
import {
    createVisualizationMovementItemController,
    createVisualizationRangeItemController,
    createVisualizationResultController,
    createVisualizationScalarItemController,
} from '../../view/visualizations';
import { createMetricConfigurationV2Impl } from '../../impl/metrics/configuration-v2';
import { createInsightDefinitionRepository } from '../../app/insight';
import { createVisualizationResultRepository } from '../../app/visualizations';
import {
    applyPermissionMiddleware,
    createPermissionMiddleware,
} from '../../middleware/permissions';
import { createOrganizationRoleRepository } from '../../app/permissions';
import {
    createSettingAssetProfileLoader,
    createSettingAssetProfileController,
} from '../../strategy';
import { createPageController, createPageControllerV2 } from '../../view/page';
import {
    createSettingAccountController,
    createSettingAssetController,
    createSettingAssetRoute,
} from '../../route/settings';
import {
    createChargeCompletedController,
    createPlanSelectController,
    createSubscriptionEditController,
    createSubscriptionFormProvider,
} from '../../view/billing';
import { createBillingSubscriptionService } from '../../service/billing';
import {
    createDatasetConnectionRepository,
    createDatasetImportRepository,
    createDatasetRepository,
} from '../../app/datasets';
import {
    createDatasetConnectionListController,
    createDatasetEntryListController,
    createDataSourceListController,
    createImportListController,
    createImportNewController,
} from '../../view/datasets';
import { createPaywallStateProvider, createPaywallStore } from '../../state';
import { createPlanModalController, createPlanModalRoute } from '../../route/billing';
import {
    createActionItemController,
    createActionListController,
    createAlertListController,
    createConfirmationController,
    createFilterListController,
    createFormController,
    createImageListController,
    createListBoxController,
    createModalController,
    createModalV1Controller,
    createOnboardingController,
    createOptionListController,
    createSearchController,
    createSelectController,
    createVideoController,
} from '../../view/common';
import {
    createFormFieldListController,
    createFormSubmissionController,
} from '../../view/forms';
import {
    createStudyFilterListController,
    createStudyListController,
    createStudyResponseItemController,
    createStudyResponseListController,
    createSubmissionItemController,
    createSubmissionListController,
} from '../../view/studies';
import {
    createGroupInviteListController,
    createGroupMemberListController,
} from '../../view/groups';
import {
    createFormDataRepository,
    createFormSubmissionRepository,
} from '../../app/forms';
import moment from 'moment';
import { createDatePickerController } from '../../vendor/chakra-date-picker';
import {
    createCompanyListController,
    createCompanyListFactory,
} from '../../view/companies';
import { createCompanyRepository, createIndustryRepository } from '../../app/companies';
import { createEditOverlay } from '../../route/common';
import { createInvitationService } from '../../service/accounts/invitation';
import {
    createInvitationAcceptController,
    createInvitationLoader,
    createInvitationAcceptFormView,
} from '../../view/invitations';
import { createInvitationAcceptRoute } from '../../route/invitations';
import { createRecommendationRepository } from '../../app/personalization';
import { configureFormUI } from '../../ui';
import {
    createOnboardingApplicationTheme,
    createOnboardingFormTheme,
} from '../../theme/onboarding';
import { createApplicationMacros } from '../../macro';
import { createApplicationIcons } from '../../icon';
import { createVisualizationRepositoryV2 } from '../../app/visualization-v2';
import { createChatController } from '../../vendor/headless-chat';
import {
    createAgentImpl,
    createConversationRunImpl,
    createMessageImpl,
    createThreadImpl,
} from '../../impl/conversation';
import {
    createAgentRepository,
    createConversationRunRepository,
    createMessageRepository,
    createThreadRepository,
} from '../../app/conversations';
import { createConversationSdk } from '@varos/sdk-llm';

moment.updateLocale('en', {
    relativeTime: {
        future: 'in %s',
        past: function (input) {
            return input.toLowerCase() === 'just now' ? input : input + ' ago';
        },
        s: 'Just now',
        ss: '%d seconds',
        m: 'A minute',
        mm: '%d minutes',
        h: 'An hour',
        hh: '%d hours',
        d: 'A day',
        dd: '%d days',
        M: 'A month',
        MM: '%d months',
        y: 'A year',
        yy: '%d years',
    },
});

export interface ApplicationEntrypointContainerConfig extends FeatureEnhancerConfig {
    redirect: RedirectConfig;
}

export function configureApplicationEntrypoint(
    config: ApplicationEntrypointContainerConfig
) {
    const useTracker: UseTrackerHook = () => {
        const { auth } = useStore();
        return {
            track(event, payload) {
                // console.log('TRACK', event, payload);
                // const cased = snakeCaseKeys(payload);
                // cannot enable automatic casing due to backwards-compatability
                return auth.reportEvent(event, payload);
            },
        };
    };

    const enhancer = compose(
        createReadStatusEnhancer({
            storageKey: 'vs_read_status',
            initial: { insights: {} },
        }),
        createFeatureFlagEnhancer({
            storageKey: FEATURE_FLAG_STORAGE_KEY,
            initial: {
                insights: {
                    enabled: false,
                },
                permissions: {
                    enabled: true,
                },
                fixedCohorts: {
                    enabled: false,
                },
                experimentalDashboardFilters: {
                    enabled: false,
                },
                exampleMode: {
                    enabled: false,
                },
            },
            // cohortPreviewOrgIds: COHORT_PREVIEW_ORG_IDS,
            previewOrgIds: [
                // 3654, 19817, 6847, 4600, 1222, 20000, 20109, 10929, 2075, 2484, 3983,
                // 1977, 19872, 3975, 2839, 19815, 19908, 19529, 51, 4581, 13366, 2001, 6759,
                // 3654, 19591, 19559, 19559, 4772, 19646, 19477, 19681,
            ],
            id: {
                homePage: PageIds.HOME,
                legacyReportPage: PageIds.REPORTS,
                permissions: ViewIds.PERMISSIONS,
                cohorts: ViewIds.COHORTS,
                experimentalFilters: new Set<string>([]), //['facebookads.attribution_spec']),
                defaultAssetRedirect: RedirectIds.DEFAULT_WORKSPACE,
            },
        }),
        createMetricDashboardAccessEnhancer(),
        createFormatEnhancer({}),
        createOnboardingEnhancer({
            redirectIds: {
                defaultAsset: RedirectIds.DEFAULT_WORKSPACE,
            },
            organization: {
                config: {
                    supportedIntegrationKinds: getSupportedOnboardingIntegrations(),
                },
            },
        }),
        createFeedbackEnhancer({
            getIntercom() {
                // @ts-expect-error
                return window.Intercom ?? null;
            },
            verticalPropertyKey: 'vertical-v2',
        }),
        createPreferenceEnhancer({}),
        createSessionEnhancer({
            createId() {
                // function randomUUID is not available in all contexts and browsers
                if (window.isSecureContext && typeof crypto.randomUUID === 'function') {
                    return crypto.randomUUID();
                }
                console.warn(
                    `function 'randomUUID' requires secure context, falling back to date-based ID`
                );
                return new Date().toISOString();
            },
            storageKey: 'vs_session',
            // 15 minutes
            ttlMs: 1000 * 60 * 15,
            mouseDebounceMs: 2500,
            sourceNameParameterName: SESSION_SOURCE_NAME_PARAMETER_KEY,
            sourceMediumParameterName: SESSION_SOURCE_MEDIUM_PARAMETER_KEY,
            sourceTriggerParameterName: SESSION_SOURCE_TRIGGER_URL_PARAMETER_KEY,
        }),
        // NOTE: do not enable yet. for some reason the caching will apply when even when there is no matching rule
        // createCacheEnhancer({
        //     rules: [
        //         // quick hack to avoid duplicate requests during initial application load.
        //         // ideally should be moved to the react-query cache
        //         {
        //             pattern: '/api/v2/dashboards',
        //             exact: true,
        //             ttl: 15000,
        //         },
        //     ],
        // }),
        createDefaultsEnhancer({
            metric: {
                collection: DEFAULT_COLLECTION_FN,
            },
        }),
        createMockEnhancer({
            report: {
                reports: Object.values(ReportDefinitions),
            },
            loader: {
                metric: {
                    list: METRIC_FIXTURE_SIMPLE,
                },
            },
        }),
        createLinkOverridesEnhancer({
            insight: {
                rule: (context, insight) => {
                    throw new Error('not impl');
                    // const visualization = toVisualization(
                    //     createInsightVisualizable(VISUALIZABLE_CONFIG),
                    //     insight
                    // );
                    // if (!visualization) {
                    //     return null;
                    // }
                    // return getAnalysisMetricLink(context, visualization);
                },
            },
        }),
        createApplicationMiddleware({
            autohide: {
                autohideAfterDays: AUTOHIDE_HOME_SECTION_AFTER_DAYS,
            },
            top: {
                invitations: Object.entries(REPORT_INVITATIONS).flatMap(
                    ([reportId, item]) =>
                        item.assets.map((asset) => ({
                            title: item.title,
                            report: {
                                id: reportId,
                            },
                            asset: {
                                id: asset,
                            },
                        }))
                ),
            },
        }),
        createBillingTrackingStrategy(),
        createTrackingOnboardingStrategy(),
        createTrackingSettingsStrategy(),
        createPeerTrackingStrategy(),
        createMetricTrackingStrategy(),
        createFeatureInsightsStrategy(),
        createFeatureDynamicDashboardStrategy({
            strategy: {
                visualizable: createMetricVisualizable({}),
            },
        }),
        createFeatureMultiAssetStrategy(),
        createFeatureDataFiltersStrategy(),
        createTooltipTrackingStrategy({
            minOpenDurationMs: 1000,
        }),
        createFeaturePluginsStrategy(),
        createRecommendationEnhancer(),
        createOnboardingRecommendationEnhancer(),
        createNavigationTrackingStrategy(),
        createHomeTrackingStrategy(),
        createDashboardRankingTrackingStrategy(),
        createInsightTrackingStrategy({
            parameters: {
                insightId: SESSION_SOURCE_INSIGHT_ID_PARAMETER_KEY,
            },
        }),
        applyPermissionMiddleware(...createPermissionMiddleware({})),
        applyFeatureMiddleware(
            ...createFeatureMiddleware({
                report: {
                    product: {
                        priceCents: 4900,
                    },
                },
            })
        ),
        createLinkTrackingStrategy(),
        createHelpTrackingStrategy(),
        createPageTrackingStrategy(),
        createAlertTrackingStrategy(),
        createFormTrackingStrategy(),
        createSearchTrackingStrategy(),
        createModalTrackingStrategy(),
        createReportLoadTrackingStrategy(),
        createAssetChangedTrackingStrategy(),
        createQueryParamFormStrategy({}),
        createSettingsUserManagementTrackingStrategy(),
        createSettingsWorkspaceManagementTrackingStrategy(),
        createGroupTrackingStrategy(),
        createRendererStrategy({
            components: {
                Tooltip({ children, ...props }) {
                    return (
                        <Tooltip placement="top" {...props}>
                            <span>{children}</span>
                        </Tooltip>
                    );
                },
                Movement: createMovementAtom(),
                Enum: createEnumAtom({
                    icons: SourceIcons,
                }),
                Calculation(props) {
                    return (
                        <CalculationView
                            label={props.property.name}
                            description={props.description}
                            value={props.value.current}
                            period={props.period}
                            comparison={props.comparison}
                            formatPeriod={props.formatPeriod}
                            formatValue={props.formatValue}
                        />
                    );
                },
            },
            formatter: createIntlFormatter(),
        }),
        createOrganizationFeatureMiddleware(),
        // applyOrganizationEnhancers(createOrganizationFeatureMiddleware()),
        applyDashboardEnhancers(
            // feature restriction needs to happen last to properly
            // lock the recommended verticals
            createDashboardFeatureMiddleware(config),
            createDashboardTrackingMiddleware(),
            createDashboardRecommendationMiddleware(),
            createRedirectMiddleware(config.redirect)
        ),

        // subcategory feature straety needs to come after recommendations
        createFeatureSubcategoryStrategy()
    );

    return createApplicationEntrypoint(
        {
            v2: {
                common: {
                    alert: {
                        createController: createAlertListController,
                    },
                    page: {
                        createController: createPageControllerV2,
                    },
                    video: {
                        createController: createVideoController,
                    },
                    chat: {
                        createController: createChatController,
                    },
                },
                conversations: {
                    thread: {
                        createAdapter: createThreadImpl,
                        createRepository: createThreadRepository,
                    },
                    message: {
                        createAdapter: createMessageImpl,
                        createRepository: createMessageRepository,
                    },
                    run: {
                        createAdapter: createConversationRunImpl,
                        createRepository: createConversationRunRepository,
                    },
                    agent: {
                        createAdapter: createAgentImpl,
                        createRepository: createAgentRepository,
                    },
                },
                peers: {
                    peerset: {
                        createService: createPeerSetService,
                    },
                    eligibility: {
                        createController: createPeerDatasetEligibilityController,
                    },
                },
                personalization: {
                    recommendation: {
                        createAdapter: createRecommendationImpl,
                        createRepository: createRecommendationRepository,
                    },
                },
                companies: {
                    company: {
                        list: {
                            createController: createCompanyListController,
                            createFactory: createCompanyListFactory,
                        },
                    },
                },
                visualizations: {
                    visualization: {
                        createAdapter: createVisualizationImplV2,
                        createRepository: createVisualizationRepositoryV2,
                    },
                },
            },
            strategy: {
                // studies: {
                //     createVisualizable: createStudiesVisualizable,
                // },
                forms: {
                    createSchema: createFormsZodSchemaStrategy,
                },
                settings: {
                    billing: {
                        createSubscription: createSettingBillingStrategy,
                    },
                    account: {
                        createAssets: createSettingAccountAssetStrategy,
                        createIntegrations: createSettingIntegrationStrategy,
                    },
                    asset: {
                        createMapping: createSettingAssetMappingStrategy,
                        createDatasetConnection: createSettingDatasetConnectionStrategy,
                        createProfile: createSettingAssetProfileStrategy,
                        createPlugin: createSettingAssetPluginStrategy,
                        createDataSource: createSettingDataSourceStrategy,
                        createImport: createSettingImportStrategy,
                    },
                },
                onboarding: {
                    createIntegration: createOnboardingIntegrationItem,
                    createPrepare: createOnboardingPrepareItem,
                    createMetric: createOnboardingMetricItem,
                    createPeerGroup: createOnboardingPeerGroupItem,
                    createMapping: createOnboardingMappingItem,
                    createMappingV2: createOnboardingMappingItemV2,
                    createAsset: createOnboardingAssetItem,
                    createProfile: createOnboardingProfileItem,
                    createReport: createOnboardingReportItem,
                    createReceipt: createOnboardingReceiptItem,
                    createCompetitiveSet: createOnboardingCompetitiveSetItem,
                },
                home: {
                    createMetricItem: createHomeMetricItem,
                },
                query: {
                    createResultable: createVisualizationQueryResponseStrategy,
                },
                visualization: {
                    createQueryableVisualization: createQueryableVisualization,
                },
                insight: {
                    createVisualizable: createInsightVisualizable,
                },
            },
            infra: {
                dataset: {
                    createParser: createDatasetParserProvider,
                },
                createApplicationIcons: createApplicationIcons,
                createHelp: createHelpImpl,
                createToaster: createToaster,
                createUrlOptionParser: createUrlOptionParser,
                createErrorFormatter: createErrorFormatterImpl,
                getIntercom() {
                    // @ts-expect-error
                    return window.Intercom ?? null;
                },
                createQueryBuilder() {
                    return () => QUERYBUILDER;
                },
                createUseTracker() {
                    return useTracker;
                },
                createAxios: createAxios,
                createFormatter() {
                    return createIntlFormatter();
                },
                createHttp(axios) {
                    return {
                        request(context, request) {
                            if (context.scheme.kind === 'apikey') {
                                return axios.request({
                                    ...request,
                                    params: {
                                        ...request.params,
                                        'api-key': context.scheme.value,
                                    },
                                });
                            } else {
                                return axios.request({
                                    ...request,
                                    headers: {
                                        ...request.headers,
                                        Authorization: `Bearer ${context.scheme.value}`,
                                    },
                                });
                            }
                        },
                    };
                },
            },
            api: {
                createVisualizationApi: createVisualizationApi,
                createCompanyApi: createCompanyApi,
                createReportApiV2: createReportApiV2,
                createDataIngestionApi: createDataIngestionApi,
                createAssetApi: createAssetApi,
                createPlatformApi: createPlatformApi,
                createBillingApi: createBillingApi,
                createReportApi: createReportApi,
                createInsightApi: createInsightApi,
                createDashboardApi: createDashboardApi,
                createNotificationApi: createNotificationApi,
                createQueryApi: createQueryApi,
                createMetricApi: createMetricApi,
                createGroupApi: createGroupApi,
                createConversationApi: createConversationSdk,
            },
            theme: {
                createOnboardingForm: createOnboardingFormTheme,
                createOnboardingApplication: createOnboardingApplicationTheme,
            },
            ui: {
                createWorkspaceUI: createChakraUI,
                createSettingsUI: createSettingsUI,
                createVisualization: createVisualizationUI,
                createInput: createInputUI,
                createDate: createDatesUI,
                createForm: configureFormUI,
            },
            redirect: {
                createRoot: createWorkspaceDefaultDashboardRedirect,
            },
            provider: {
                custom: [],
                createStorageProvider(config) {
                    return configureStorageProvider({
                        report: config.store.report,
                    });
                },
                createAuth() {
                    return {
                        Provider() {
                            throw new Error('not impl');
                        },
                        useContext() {
                            const { auth: store } = useStore();
                            return {
                                user: store.currentUser?.id
                                    ? { id: store.currentUser.id }
                                    : null,
                                scheme: { kind: 'legacy', store },
                            };
                        },
                    };
                },
                createOrganization() {
                    const OrganizationContext =
                        React.createContext<OrganizationReference | null>(null);
                    return {
                        Provider: OrganizationContext.Provider,
                        useContext() {
                            const context = React.useContext(OrganizationContext);
                            if (!context) {
                                throw new Error(`organization context not found`);
                            }
                            return context;
                        },
                    };
                },
                createWorkspace() {
                    const WorkspaceContext =
                        React.createContext<WorkspaceReference | null>(null);
                    return {
                        Provider: WorkspaceContext.Provider,
                        useContext() {
                            const context = React.useContext(WorkspaceContext);
                            if (!context) {
                                throw new Error(`workspace context not found`);
                            }
                            return context;
                        },
                    };
                },
                createUser() {
                    return {
                        Provider(props) {
                            throw new Error('not impl');
                        },
                        useContext() {
                            const legacy = useUserContext();
                            return { id: legacy.user.id };
                        },
                    };
                },
                createAccess() {
                    return {
                        Provider(props) {
                            throw new Error('not impl');
                        },
                        useContext() {
                            const legacy = useUserContext();
                            return legacy.assets;
                        },
                    };
                },
            },
            config: {
                reports: createReports(),
            },
            store: {
                createPaywall: createPaywallStore,
                createReportStore() {
                    return createReportStore({
                        initialValues: {
                            date: {
                                granularity: 'week',
                                period: {
                                    interval: 'week',
                                    amount: 4,
                                },
                                comparison: {
                                    interval: 'period',
                                    amount: 1,
                                },
                            },
                        },
                    });
                },
            },
            components: {
                Routes: VarosRoutes,
                Route,
                Outlet,
            },
            layout: {
                createOrganizationBaseLayout: createOrganizationLayout,
                createOrganizationAuditLayout: createOrganizationAuditLayout,
                createWorkspaceLayout: createWorkspaceLayout,
                createSettingsLayout: createSettingsLayout,
                createOnboardingLayout: createOnboardingLayout,
                createDashboardLayout: createDashboardLayout,
                createHomeLayout: createHomeLayout,
                createAnalysisLayout: createAnalysisLayout,
                createDomainLayout: createDomainLayout,
                createPageLayout: createPageLayout,
            },
            context: {
                createSystem: createSystemProvider,
                createOrganization: createOrganizationProviderV2,
                createWorkspace: createWorkspaceProvider,
                useWorkspace() {
                    const context = useWorkspaceContext();
                    return { id: context.workspace.id };
                },
            },
            form: {
                billing: {
                    subscription: {
                        createEdit: createSubscriptionFormProvider,
                    },
                },
                assets: {
                    asset: {
                        createNew: createAssetNewFormProvider,
                        createEdit: createAssetEditFormProvider,
                    },
                },
                reports: {
                    createNew: createReportNewFormProvider,
                    createSelect: createReportSelectFormProvider,
                },
                query: {
                    createDate: createDateForm,
                    createBreakdown: createBreakdownForm,
                    createFilter: createFilterForm,
                },
                metrics: {
                    collection: {
                        createNew: createMetricCollectionNewForm,
                    },
                },
            },
            repository: {
                companies: {
                    createCompany: createCompanyRepository,
                    createIndustry: createIndustryRepository,
                },
                forms: {
                    createSubmission: createFormSubmissionRepository,
                    createData: createFormDataRepository,
                },
                datasets: {
                    createDataset: createDatasetRepository,
                    createDatasetConnection: createDatasetConnectionRepository,
                    createImport: createDatasetImportRepository,
                },
                permissions: {
                    role: {
                        createOrganization: createOrganizationRoleRepository,
                    },
                },
                visualizations: {
                    createResult: createVisualizationResultRepository,
                },
                registration: {
                    createOption: createRegistrationOptionRepository,
                },
                reportsold: {
                    createReport: createReportRepository,
                },
                reports: {
                    createReport: createReportV2Repository,
                    createInvitation: createReportInvitationRepository,
                },
                metric: {
                    createConfiguration: createMetricConfigurationRepository,
                    createCollection: createMetricCollectionRepository,
                    createDefinition: createMetricDefinitionRepository,
                    createSummary: createMetricSummaryRepository,
                    createDashboard: createMetricDashboardRepository,
                },
                accounts: {
                    createProfile: createAccountProfileRepository,
                    createInvitation: createAccountInvitationRepository,
                },
                assets: {
                    createDestination: createDestinationRepository,
                    createDefinition: createIntegrationDefinitionRepository,
                    createConnnection: createMappingConnectionRepository,
                    createAsset: createAssetRepository,
                    createEntity: createMappableEntityRepository,
                    createIntegration: createIntegrationRepository,
                },
                insights: {
                    createOpportunity: createOpportunityRepository,
                    createDefinition: createInsightDefinitionRepository,
                },
                platform: {
                    createCount: createCountRepository,
                    createCollaborator: createCollaboratorRepository,
                    createPlugin: createPluginRepository,
                    createClientPreference: createClientPreferenceRepository,
                    createConnectionV2: createConnectionRepositoryV2,
                    createFeatureSet: createFeatureRepository,
                    createOrganization: createOrganizationRepository,
                },
                peers: {
                    createTrait: createTraitRepository,
                    createPeerStatus: createPeerStatusRepository,
                    createPeerGroups: createPeerGroupRepository,
                    createPeerset: createPeerSetRepository,
                    createMember: createPeerSetMemberRepository,
                    createHealthcheck: createPeerHealthcheckRepository,
                },
                attributes: {
                    createMember: createMemberRepository,
                },
                query: {
                    createView: createViewRepository,
                    createCompetitiveStatus: createCompetitveSetStatusRepository,
                    createResponse: createQueryResponseRepository,
                },
                groups: {
                    createGroup: createGroupRepository,
                    createGroupInvite: createGroupInviteRepository,
                    createGroupMember: createGroupMemberRepository,
                },
                billing: {
                    createPaymentMethod: createPaymentMethodRepository,
                    createSubscription: createSubscriptionRepository,
                    createPlan: createSubscriptionPlanRepository,
                    createCharge: createChargeRepository,
                    createSetupIntent: createSetupIntentRepository,
                },
            },
            state: {
                createPaywall: createPaywallStateProvider,
                createInsight: createInsightStateProvider,
                assets: {
                    createList: createAssetStateListProvider,
                },
                integrations: {
                    createList: createIntegrationListStateProvider,
                },
            },
            loader: {
                settings: {
                    createProfile: createSettingAssetProfileLoader,
                },
                reports: {
                    createReportSelect: createReportSelectLoader,
                    createReportList: createReportListLoader,
                    createReportNew: createReportNewLoader,
                    createReportDetail: createReportDetailLoader,
                    createReportPoll: createReportPollLoader,
                },
                metrics: {
                    collection: {
                        createNew: createMetricCollectionNewLoader,
                        createEdit: createMetricCollectionEditLoader,
                    },
                },
                insights: {
                    createOpportunity: createOpportunityCollectionLoader,
                },
                home: {
                    createIntegration: createHomeIntegrationLoader,
                    createDestination: createHomeDestinationLoader,
                    createOpportunity: createHomeOpportunityLoader,
                    createAlert: createHomeAlertLoader,
                    createGuide: createHomeGuideLoader,
                    createExpertCall: createHomeExpertCallLoader,
                },
                accounts: {
                    createProfile: createAccountProfileLoader,
                },
                assets: {
                    createMapping: createOrganizationMappingLoader,
                    createPrepare: createAssetPrepareLoader,
                },
                groups: {
                    create: createGroupsLoader,
                },
                invitation: {
                    create: createInvitationLoader,
                },
            },
            hook: {
                createUseLocalStorage: createLocalStorageHook,
            },
            adapter: {
                companies: {
                    createCompany: createCompanyImpl,
                    createIndustry: createIndustryImpl,
                },
                forms: {
                    createSubmission: createFormSubmissionImpl,
                    createData: createFormDataImpl,
                },
                datasets: {
                    createDataset: createDatasetImpl,
                    createDatasetConnection: createDatasetConnectionImpl,
                    createImport: createDatasetImportImpl,
                },
                permissions: {
                    createRole: createPermissionRoleImpl,
                },
                registration: {
                    createOption: createRegistrationOptionImpl,
                },
                reportsold: {
                    createReport: createReportImpl,
                },
                reports: {
                    createReport: createReportV2Impl,
                    createInvitation: createReportInvitationImpl,
                },
                accounts: {
                    createProfile: createProfileImpl,
                    createInvitation: createAccountInvitationImpl,
                },
                billing: {
                    createPaymentMethod: createPaymentMethodImpl,
                    createPlan: createSubscriptionPlanImpl,
                    createSubscription: createSubscriptionV2Impl,
                    createCharge: createChargeApiImpl,
                    createSetupIntent: createSetupIntentImpl,
                },
                assets: {
                    createDestination: createDestinationDefinitionImpl,
                    createDefinition: createIntegrationDefinitionImpl,
                    createConnection: createMappingConnectionImpl,
                    createAsset: createAssetImpl,
                    createEntity: createMappableEntityImpl,
                    createIntegration: createIntegrationImpl,
                },
                insights: {
                    createOpportunity: createOpportunityImpl,
                    createDefinition: createInsightDefinitionImpl,
                },
                platform: {
                    createCount: createCountImpl,
                    createCollaborator: createCollaboratorImpl,
                    createPlugin: createPluginImpl,
                    createConnection: createConnectionImpl,
                    createClientPreference: createClientPreferenceImpl,
                    createFeatureSet: createFeaturesetImpl,
                    createOrganization: createOrganizationImpl,
                },
                peers: {
                    createTrait: createTraitImpl,
                    createStatus: createPeerStatusImpl,
                    createPeerGroup: createPeerGroupImpl,
                    createPeerSet: createPeerSetImpl,
                    createMember: createPeerSetMemberImpl,
                    createHealthcheck: createPeerHealthcheckImpl,
                },
                attributes: {
                    createMember: createMemberImpl,
                },
                query: {
                    createView: createViewImpl,
                    createCompetitiveStatus: createCompetitiveStatusImpl,
                    createResponse: createQueryResponseImpl,
                },
                createDashboardAdapter: createApiDashboardImpl,
                createCompetitiveSetAdapter: createCompetitiveSetImpl,
                control: {
                    createControlWorkpaceHook(): ControlWorkspaceHook {
                        const DEFAULT: WorkspaceControl = {
                            date: {
                                granularity: 'week',
                                period: {
                                    interval: 'week',
                                    amount: 4,
                                },
                                comparison: {
                                    amount: 1,
                                    interval: 'period',
                                },
                            },
                        };
                        return (workspace) => {
                            const storage = useStorageContext();
                            return {
                                set(value) {
                                    storage.workspace.setDashboardDateState(
                                        workspace,
                                        value.date
                                    );
                                },
                                value: {
                                    date:
                                        storage.workspace.getDashboardDateState(
                                            workspace
                                        ) ?? DEFAULT.date,
                                },
                            };
                        };
                    },
                },
                analysis: {
                    createQueryState: createAnalysisStateImpl,
                    createQueryExecutor: createAnalysisQueryExecutorImpl,
                },
                metric: {
                    createConfigurationV2: createMetricConfigurationV2Impl,
                    createDefinition: createMetricDefinitionImpl,
                    createConfigurationProvider: createMetricConfigurationImpl,
                    createCalculationAdapterHook: createCalculationImpl,
                    createCollectionProvider: createCollectionImpl,
                    createSummary: createMetricSummaryImpl,
                },
                group: {
                    createGroupAdapter: createGroupsImpl,
                    createGroupMemberAdapter: createGroupMemberImpl,
                    createGroupInviteAdapter: createGroupInviteImpl,
                },
                report: {
                    createDependencyAdapter: createDependencyStatusImpl,
                    createSegmentAdapter: createSegmentStatusImpl,
                    useChartAdapter() {
                        const context = useOrganizationScope();
                        return {
                            async execute(axios, workspace, control, metrics, segments) {
                                const queries = createReportQueryFromMetrics(
                                    {
                                        partitionKeyByView: ViewPartitionKeyBySlug,
                                    },
                                    control,
                                    metrics,
                                    segments
                                );
                                const responses = await Promise.all(
                                    queries.map(
                                        async (query): Promise<AnyQueryResponse> => {
                                            try {
                                                const response =
                                                    await context.adapter.chart.executeQuery(
                                                        workspace,
                                                        null,
                                                        null,
                                                        query
                                                    );
                                                return {
                                                    error: null,
                                                    request: query,
                                                    response,
                                                };
                                            } catch (error) {
                                                if (error instanceof Error) {
                                                    return {
                                                        request: query,
                                                        error,
                                                    };
                                                }
                                                throw error;
                                            }
                                        }
                                    )
                                );
                                return responses;
                            },
                        };
                    },
                },
                useChartAdapter() {
                    const context = useOrganizationScope();
                    return context.adapter.chart;
                },
            },
            service: {
                billing: {
                    createSubscription: createBillingSubscriptionService,
                },
                accounts: {
                    createProfile: createAccountProfileService,
                },
                onboarding: {
                    createOrganizationState: createOnboardingOrganizationStateService,
                    createAssetState: createOnboardingAssetStateService,
                },
                assets: {
                    createMapping: createOrganizationMappingService,
                    createAsset: createAssetService,
                    createIntegration: createIntegrationService,
                },
                insights: {
                    createOpportunity: createOpportunityService,
                },
                peers: {
                    createPeerGroupService: createPeerGroupService,
                },
                platform: {
                    createWorkspaceService: createWorkspaceService,
                    createCollaboratorService: createCollaboratorService,
                    createUserService: createUserService,
                },
                reports: {
                    createScheduledReportService: createScheduledReportService,
                },
                dashboards: {
                    createPluginService: createPluginService,
                },
                groups: {
                    createGroupsService,
                },
                invitation: {
                    createInvitationService: createInvitationService,
                },
                createReportService: createReportService,
            },
            controller: {
                root: {
                    redirect: createWorkspaceRedirectController,
                },
                common: {
                    createSelect: createSelectController,
                    createConfirmation: createConfirmationController,
                    createActionList: createActionListController,
                    createActionItem: createActionItemController,
                    createImageList: createImageListController,
                    createOnboarding: createOnboardingController,
                    createFilterList: createFilterListController,
                    createSearch: createSearchController,
                    createOptionList: createOptionListController,
                    createListBox: createListBoxController,
                },
                invitation: {
                    createAcceptInvitation: createInvitationAcceptController,
                },
                studies: {
                    study: {
                        createFilter: createStudyFilterListController,
                        createList: createStudyListController,
                    },
                    submission: {
                        createList: createSubmissionListController,
                        createItem: createSubmissionItemController,
                    },
                    response: {
                        createList: createStudyResponseListController,
                        createItem: createStudyResponseItemController,
                    },
                },
                forms: {
                    createSubmission: createFormSubmissionController,
                    createFieldList: createFormFieldListController,
                },
                modal: {
                    billing: {
                        createPlan: createPlanModalController,
                    },
                },
                datasets: {
                    connection: {
                        createList: createDatasetConnectionListController,
                    },
                    source: {
                        createList: createDataSourceListController,
                    },
                    import: {
                        createList: createImportListController,
                        createNew: createImportNewController,
                    },
                    entry: {
                        createList: createDatasetEntryListController,
                    },
                },
                billing: {
                    subscription: {
                        createEdit: createSubscriptionEditController,
                    },
                    plan: {
                        createSelect: createPlanSelectController,
                    },
                    charge: {
                        complete: createChargeCompletedController,
                    },
                },
                layout: {
                    createDomain: createDomainLayoutController,
                    createPage: createPageController,
                    createV1Modal: createModalV1Controller,
                    createModal: createModalController,
                    createForm: createFormController,
                },
                integrations: {
                    entity: {
                        createControl: createEntityControlController,
                        createList: createEntityListController,
                    },
                    integration: {
                        createList: createIntegrationListController,
                        createItem: createIntegrationItemController,
                    },
                },
                visualization: {
                    createScalar: createVisualizationScalarItemController,
                    createMovement: createVisualizationMovementItemController,
                    createRange: createVisualizationRangeItemController,
                    createResult: createVisualizationResultController,
                },
                reports: {
                    createReportList: createReportListController,
                    createReportNew: createReportNewController,
                    createReportSelect: createReportSelectController,
                    createReportDetail: createReportDetailController,
                    createReportPoll: createReportPollController,
                },
                metrics: {
                    collection: {
                        createNew: createMetricCollectionNewController,
                    },
                },
                home: {
                    createIntegration: createHomeIntegrationController,
                    createDestination: createHomeDestinationController,
                    createOpportunity: createHomeOpportunityController,
                    createAlert: createHomeAlertController,
                    createGuide: createHomeGuideController,
                    createExpertCall: createHomeExpertCallController,
                },
                accounts: {
                    createProfile: createAccountProfileController,
                    createInviteForm: createInviteFormController,
                },
                assets: {
                    createList: createAssetListController,
                    createNew: createAssetNewController,
                    createEdit: createAssetEditController,
                    createPrepare: createAssetPrepareController,
                    createMapping: createOrganizationMappingController,
                },
                feedback: {
                    createRating: createRatingController,
                },
                date: {
                    createDateSelector: createDatePickerController,
                },
                insights: {
                    opportunity: {
                        createCollection: createOpportunityCollectionController,
                        createItem: createOpportunityItemController,
                    },
                },
                analysis: {
                    createVisualization: createVisualizationController,
                    createFilter: createAnalysisFilterController,
                },
                onboarding: {
                    createPlugin: createOnboardingPluginItemController,
                    createProfile: createOnboardingProfileController,
                    createIntegration: createOnboardingIntegrationController,
                    createAsset: createOnboardingAssetController,
                    createMapping: createOnboardingMappingController,
                    createPeerGroups: createOnboardingPeerGroupController,
                    createPrepare: createOnboardingPrepareController,
                    createCompetitiveSet: createOnboardingCompetitiveSetController,
                    createMetric: createOnboardingMetricController,
                    createReport: createOnboardingReportController,
                    createReceipt: createOnboardingReceiptController,
                },
                platform: {
                    createHelp: createPlatformHelpController,
                    createWorkspace: createWorkspaceRouteController,
                    plugin: {
                        createList: createPluginListController,
                        createItem: createPluginItemController,
                    },
                },
                peer: {
                    createTrait: createTraitController,
                    createPreview: createPreviewController,
                    createExample: createExampleController,
                    createGroupEdit: createGroupEditController,
                    createGroupItem: createPeerGroupItemController,
                    createFilterList: createPeerFilterListController,
                },
                attributes: {
                    createPropertyInput: createPropertyInputController,
                },
                segment: {
                    createItem: createSegmentItemController,
                },
                dashboards: {
                    createWorkspaceNavigationController:
                        createWorkspaceNavigationController,
                },
                groups: {
                    group: {
                        createActivated: createGroupsActivatedController,
                        createIntro: createGroupsIntroController,
                        createRedirect: createGroupRedirectController,
                        createSettings: createGroupsFormController,
                        createList: createGroupListController,
                        createItem: createGroupItemController,
                    },
                    invite: {
                        createList: createGroupInviteListController,
                    },
                    member: {
                        createList: createGroupMemberListController,
                    },
                },
                settings: {
                    createOrganization: createSettingAccountController,
                    createAsset: createSettingAssetController,
                    createProfile: createSettingAssetProfileController,
                },
            },
            module: {
                createMetricModule: createMetricModule,
                createWorkspaceSelectionModule: createWorkspaceSelectionModule,
                createAssetMappingModule: createAssetMappingModule,
                createInsightModule: createInsightModule,
                createReportModule: createReportModule,
                createScheduledReportsModule: createScheduledReportsModule,
                createNotificationModule: createNotificationModule,
                createWorkspaceManagementModule: createWorkspaceManagementModule,
                createUserManagementModule: createUserManagementModule,
                createCohortModule: createCohortModule,
            },
            view: {
                home: {
                    createAlert: createHomeAlertView,
                    createOpportunity: createHomeOpportunityView,
                },
                segment: {
                    createPropertyItem: createSegmentItemContainer,
                },
                dashboards: {
                    createWorkspaceNavigationView(config) {
                        return createWorkspaceNavigationView({
                            ...config,
                            style: {
                                ...config.style,
                                wrapper: {
                                    spacing: 8,
                                    // spacing: 0,
                                    fontWeight: 'medium',
                                    px: 4,
                                    h: '100%',
                                    fontSize: 'sm',
                                },
                                list: {
                                    maxW: {
                                        sm: '100%',
                                        md: '75%',
                                        lg: '66%',
                                    },
                                },
                                item: {
                                    // px: 2,
                                    spacing: 3,
                                    _selected: {
                                        borderWidth: '2px',
                                        borderColor: VAROS_GREEN[200],
                                    },
                                },
                                dropdown: {
                                    fontWeight: 'medium',
                                    bg: GRAY[600],
                                },
                            },
                        });
                    },
                },
                groups: {
                    createActivated: createGroupsActivatedView,
                },
                invitations: {
                    createAcceptView: createInvitationAcceptFormView,
                },
            },
            container: {
                dashboards: {
                    createWorkspaceNavigationContainer:
                        createWorkspaceNavigationContainer,
                },
                workspaces: {
                    createWorkspaceInsightContainer: createWorkspaceInsightContainer,
                },
            },
            router: {
                createReporting: createReportRouter,
                createPeer: configurePeerRouter,
                createNetwork: createNetworkRouter,
                createRegistration: configureRegistrationRouter,
                createConversation: configureConversationRouter,
            },
            macro: {
                createApplication: createApplicationMacros,
                common: {
                    createEditOverlay: createEditOverlay,
                },
            },
            route: {
                billing: {
                    plan: {
                        createSelect: createPlanModalRoute,
                    },
                    charge: {
                        complete: createChargeCompleteRoute,
                    },
                },
                settings: {
                    createOrganization: createSettingAccountRoute,
                    createAsset: createSettingAssetRoute,
                },
                reports: {
                    createReportList: createReportListRoute,
                    createReportSelect: createReportSelectRoute,
                    createReportDetail: createReportDetailRoute,
                    createReportShell: createReportShellRoute,
                },
                install: INSTALL_ROUTE,
                integration: INTEGRATION_ROUTE,
                createDashboardRoute: createDashboardRoute,
                createOrganizationRoute: createOrganizationRoute,
                createOrganizationWorkspaceRoute: createOrganizationRoute,
                createWorkspaceRoute: createWorkspaceRoute,
                createUserRoute: createUserRoute,
                createOnboardingUserRoute: createOnboardingUserRoute,
                createOnboardingOrganizationRoute: createOnboardingOrganizationRoute,
                createOnboardingAssetRoute: createOnboardingAssetRoute,
                createHomeRoute: createHomeRoute,
                createMetricAnalysisRoute: createMetricAnalysisRoute,
                createInsightRoute: createInsightRoute,
                createInsightRouteV2: createInsightRouteV2,
                createGroupsAnalyzeRoute,
                createGroupsSettingsRoute,
                createInvitiationAcceptRoute: createInvitationAcceptRoute,
            },
        },
        enhancer
    );
}

import React from 'react';
import { ConversationAgentConfig } from '../../../../route';
import {
    AgentInterviewController,
    AgentInterviewLoader,
} from './agentInterviewInterface';
import {
    AgentInterviewContainerProps,
    AgentInterviewViewProps,
} from './agentInterviewProps';

export function createAgentInterviewContainer(
    config: ConversationAgentConfig,
    loader: AgentInterviewLoader,
    controller: AgentInterviewController,
    View: React.FC<AgentInterviewViewProps>
): React.FC<AgentInterviewContainerProps> {
    const {
        context: { useContext },
    } = config;
    return (containerProps) => {
        const context = useContext();
        const data = loader.useLoad(context, containerProps);
        const props = controller.useProps(data, {});
        return <View {...props} />;
    };
}

import { DEFAULT_COMPARISON } from '../../../../components/DashboardView/DashboardView';
import { TimeGranularityType } from '../../../../models/Common';
import { isRelativePeriod, isAbsolutePeriod, Schema } from '../../../../domain';
import { QueryRequest, Comparison } from '../../../domain/query';
import {
    DateBetweenCondition,
    DateCondition,
    AnyCondition,
} from '../../../domain/attributes';
import { CohortState } from '../../../domain/dashboard';
import { useWorkspaceApi } from '../workspace';
import { ControlDateValue, useControlApi } from '../control';
import { DashboardApi, useDashboardApi } from '../dashboard';
import {
    QueryExpressionSegment,
    QueryRangeSegment,
    QuerySource,
} from '../../../../api/v2/query';

export interface QueryFormDateValues {
    granularity: TimeGranularityType;
    filter: DateCondition;
    comparison: Comparison | null;
}

export interface QueryFormValues {
    data: AnyCondition[];
    segment: AnyCondition[];
    date: QueryFormDateValues;
}

export interface QueryApi {
    getDateConfiguration(): ControlDateValue | null;
    getDataFilters(schema: Schema): AnyCondition[];
    getSegment(): AnyCondition[];
    getGranularity(): TimeGranularityType;
    getComparison(): Comparison | null;
    getCohort(): CohortState | null;
    getQuery(
        querySource: QuerySource
    ): Pick<
        QueryRequest,
        'filters' | 'segment' | 'granularity' | 'comparison' | 'segments'
    >;
    /**
     * returns a hash of the query state that can be used
     * for caching and error boundaries
     */
    getHash(querySource: QuerySource): string;
    getSegmentHash(): string;
}

function _getProperties(querySource: QuerySource, dashboardApi: DashboardApi): Schema {
    const { viewSchemas } = dashboardApi.getConfiguration();
    const viewSchema = viewSchemas.find((x) => x.view == querySource.view);
    if (!viewSchema) {
        throw new Error(`cannot find schema for source ${querySource.view}`);
    }
    return viewSchema.schema;
}

export const useQueryApi = (): QueryApi => {
    const api = {
        workspace: useWorkspaceApi(),
        dashboard: useDashboardApi(),
        control: useControlApi(),
    };

    const workspaceKey = api.workspace.getKey();

    return {
        getDateConfiguration() {
            return api.control.getDateFilter(api.dashboard.getDateConfiguration());
        },
        getSegment() {
            let groups = api.control.getSegmentGroupValue();
            let mode = api.control.getFilterMode();
            let segment = api.dashboard.getTraits().flatMap((filter) => {
                const value = api.control.getSegmentFilterValue(filter);
                return value ? [value] : [];
            });
            if (mode === 'fixed' && groups.length > 0) {
                // when filtering by groups, ignore any other dynamic filters
                segment = [{ key: 'asset', operator: 'in', value: groups }];
            } else if (mode === 'fixed') {
                segment = [];
            }
            return segment;
        },
        getCohort() {
            return api.control.getCohortValue();
        },
        getDataFilters(schema: Schema) {
            return api.dashboard.getFilters().flatMap((filter) => {
                const prop = schema.type_properties[filter.property.key]; // key reflects as must be type id
                if (prop) {
                    const value = api.control.getDataFilterValue(filter);
                    return value
                        ? [
                              {
                                  ...value,
                                  key: prop.key,
                              },
                          ]
                        : [];
                }
                return [];
            });
        },
        getGranularity() {
            return (
                api.control.getDateFilter(api.dashboard.getDateConfiguration())
                    ?.granularity ?? 'week'
            );
        },
        getComparison() {
            return (
                api.control.getDateFilter(api.dashboard.getDateConfiguration())
                    ?.comparison ?? DEFAULT_COMPARISON
            );
        },
        getHash(querySource: QuerySource) {
            const query = this.getQuery(querySource);
            return JSON.stringify({ ...query, workspaceKey });
        },
        getSegmentHash() {
            const segment = this.getSegment();
            return JSON.stringify({ segment, workspaceKey });
        },
        getQuery(
            querySource: QuerySource
        ): Pick<
            QueryRequest,
            'filters' | 'segment' | 'granularity' | 'comparison' | 'segments'
        > {
            const props = _getProperties(querySource, api.dashboard);
            const [dateColumn] = props.partition;
            const datefilter = api.dashboard.getDateConfiguration()?.filter;
            let filters: AnyCondition[] = this.getDataFilters(props);
            let segment: AnyCondition[] = this.getSegment();

            const date = this.getDateConfiguration();
            if (dateColumn) {
                if (datefilter && date && isRelativePeriod(date.period)) {
                    filters = [
                        ...filters,
                        {
                            key: dateColumn,
                            operator: 'previous',
                            value: date.period,
                        },
                    ];
                }
                if (datefilter && date && isAbsolutePeriod(date.period)) {
                    const condition: DateBetweenCondition = {
                        key: dateColumn,
                        operator: 'between',
                        value: {
                            from: date.period.start,
                            to: date.period.end,
                        },
                    };
                    filters = [...filters, condition];
                }
            }

            const cohort = this.getCohort();
            return {
                filters,
                segment,
                granularity: this.getGranularity() ?? undefined,
                comparison: this.getComparison(),
                segments: cohort
                    ? cohort.mode === 'fixed'
                        ? [
                              { kind: 'asset', name: 'My company' },
                              ...cohort.config.fixed.cohorts
                                  //   .filter((item) => item.visible)
                                  .map<QueryRangeSegment>((item) => ({
                                      kind: 'range',
                                      name: item.name,
                                      range: {
                                          column: cohort.config.fixed.comparison,
                                          start: {
                                              kind: 'percentile',
                                              value: item.lower,
                                          },
                                          end: {
                                              kind: 'percentile',
                                              value: item.upper,
                                          },
                                      },
                                      reducer: {
                                          kind: 'percentile',
                                          value: 50,
                                      },
                                  })),
                          ]
                        : [
                              { kind: 'asset', name: 'My company' },
                              ...cohort.config.dynamic.cohorts
                                  //   .filter((item) => item.visible)
                                  .map<QueryExpressionSegment>((item) => ({
                                      kind: 'expression',
                                      name: item.name,
                                      expr: {
                                          kind: 'boolean',
                                          value: true,
                                      },
                                      reducer: {
                                          kind: 'percentile',
                                          value: item.value,
                                      },
                                  })),
                          ]
                    : undefined,
            };
        },
    };
};

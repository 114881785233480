import React from 'react';
import { Flex, Container, Box, Button, VStack } from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';

export interface MinimalLayoutContainerProps {}

export const MinimalLayoutContainer: React.FC<MinimalLayoutContainerProps> = ({ ...props }) => {
    return (
        <Flex h="full" justify="center" alignItems="center">
            <Container centerContent maxW="xl">
                {props.children}
            </Container>
        </Flex>
    );
};

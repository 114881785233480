import { z } from 'zod';

export const LinkedInProfileSchema = z.object({
    kind: z.literal('linkedin'),
    id: z.string().nullable().default(null),
    url: z.string(),
});

export const VarosProfileSchema = z.object({
    kind: z.literal('varos'),
    id: z.string(),
    url: z.nullable(z.string()).default(null),
});

export const CompanyProfileSchema = z.discriminatedUnion('kind', [
    LinkedInProfileSchema,
    VarosProfileSchema,
]);

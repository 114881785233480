"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AnyBlockSchema = exports.BlockEventSchema = exports.BlockJsonSchema = exports.BlockActionSchema = exports.BlockSelectSchema = exports.BlockTextSchema = exports.BlockSectionSchema = exports.BlockUnknownSchema = void 0;
const zod_1 = require("zod");
const element_1 = require("../element");
exports.BlockUnknownSchema = zod_1.z.object({
    kind: zod_1.z.literal('unknown'),
});
exports.BlockSectionSchema = zod_1.z.object({
    kind: zod_1.z.literal('section'),
    text: zod_1.z.string().optional(),
    fields: zod_1.z.array(zod_1.z.object({
        label: zod_1.z.string(),
        value: zod_1.z.string(),
    })),
}, { description: 'a section includes set of fields for previewing structured data' });
exports.BlockTextSchema = zod_1.z.object({
    kind: zod_1.z.literal('text'),
    text: zod_1.z.string(),
    attachments: zod_1.z
        .array(zod_1.z.object({
        kind: zod_1.z.literal('reference'),
        type: zod_1.z.union([
            zod_1.z.literal('survey'),
            zod_1.z.literal('chart'),
            zod_1.z.literal('user'),
            zod_1.z.literal('organization'),
        ]),
        id: zod_1.z.string(),
    }, { description: 'An attachment that references an entity type by id' }))
        .optional(),
    modifiers: zod_1.z
        .array(zod_1.z.union([
        zod_1.z.literal('bold'),
        zod_1.z.literal('italic'),
        zod_1.z.literal('code'),
        zod_1.z.literal('strikethrough'),
    ]))
        .optional(),
}, { description: 'a text block' });
exports.BlockSelectSchema = zod_1.z.object({
    kind: zod_1.z.literal('input'),
    type: zod_1.z.literal('select'),
    placeholder: zod_1.z.string().optional(),
    options: zod_1.z.array(zod_1.z.object({
        label: zod_1.z.string(),
        value: zod_1.z.string(),
    })),
}, { description: 'a select block' });
exports.BlockActionSchema = zod_1.z.object({
    kind: zod_1.z.literal('action'),
    elements: zod_1.z.array(element_1.AnyElementSchema),
}, { description: 'an action block' });
exports.BlockJsonSchema = zod_1.z.object({
    kind: zod_1.z.literal('json'),
    data: zod_1.z.union([
        zod_1.z.record(zod_1.z.string(), zod_1.z.unknown()),
        zod_1.z.array(zod_1.z.record(zod_1.z.string(), zod_1.z.unknown())),
    ]),
}, { description: 'a json block' });
exports.BlockEventSchema = zod_1.z.object({
    kind: zod_1.z.literal('event'),
    data: zod_1.z.discriminatedUnion('kind', [
        zod_1.z.object({
            kind: zod_1.z.literal('action'),
            id: zod_1.z.string(),
        }),
    ]),
}, { description: 'an event block' });
exports.AnyBlockSchema = zod_1.z.discriminatedUnion('kind', [
    exports.BlockUnknownSchema,
    exports.BlockSelectSchema,
    exports.BlockTextSchema,
    exports.BlockJsonSchema,
    exports.BlockSectionSchema,
    exports.BlockActionSchema,
    exports.BlockEventSchema,
]);

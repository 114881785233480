import { ConversationControllerBaseConfig } from '../base';
import { ConversationNewController } from './conversationNewInterface';

export function createConversationNewController(
    config: ConversationControllerBaseConfig
): ConversationNewController {
    const {
        provider: { createFormController },
    } = config;
    const formController = createFormController({});
    return {
        useProps(context, item, props) {
            const form = formController.useProps(props.form);
            return { form };
        },
    };
}

import React, { Suspense } from 'react';
import { VStack, Heading } from '@chakra-ui/react';
import { DeleteIcon } from '@chakra-ui/icons';
import { UserListConfig } from './userListConfig';
import { UserListViewProps } from './userListProps';
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    AlertDialogCloseButton,
    Button
  } from '@chakra-ui/react'

export function createUserListView(
    config: UserListConfig
): React.FC<UserListViewProps> {
    const {
        UI: { Table, Tbody, Thead, Tr, Td, Th, Tag, Text },
        Layout,
        // Container: { EditForm },
    } = config;
    return (props) => {
        const cancelRef = React.useRef<HTMLButtonElement | null>(null);
        return (
            <VStack alignItems="flex-start" spacing={4} w="full">
                <VStack alignItems="flex-start" spacing={2}>
                    <Heading size="lg">Team</Heading>
                    <Text color="whiteAlpha.700">manage team</Text>
                </VStack>
                <Table>
                    <Thead>
                        <Tr>
                            <Th>Name</Th>
                            <Th>Email</Th>
                            <Th>Title</Th>
                            <Th>Administrator</Th>
                            <Th>Settings</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {props.users.data.map((user, index) => {
                            return (
                                <Tr key={index}>
                                    <Td whiteSpace="nowrap">{user.first_name} {user.last_name}</Td>
                                    <Td>{user.email}</Td>
                                    <Td>{user.title}</Td>
                                    <Td>{user.role === 'admin' ? 'Yes': 'No'}</Td>
                                    <Td>
                                        { user.isDeleteAllowed &&<DeleteIcon
                                            cursor="pointer"
                                            onClick={() => props.removal.set(user)} />
                                        }
                                    </Td>
                                </Tr>
                            );
                        })}
                    </Tbody>
                </Table>
                <Suspense
                    fallback={
                        // temp hack that wraps in suspense to prevent bubbling loading states from inside modal content
                        <></>
                    }
                >
                    {/* {props.editProps && (
                        <EditForm
                            key={props.editProps.workspace.id}
                            {...props.editProps}
                        />
                    )} */}
                </Suspense>
                {props.removal.userToDelete && <AlertDialog
                    isOpen={!!props.removal.userToDelete}
                    leastDestructiveRef={cancelRef}
                    onClose={props.removal.cancel}
                >
                    <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                        Remove User
                        </AlertDialogHeader>

                        <AlertDialogBody>
                        Are you sure you want to remove user {props.removal.userToDelete?.email}? You can't undo this action afterwards.
                        
                        { props.removal.isError && <Text fontSize="md" color="error">
                            Failed to remove user. please contact us for support
                        </Text>}

                        </AlertDialogBody>

                        <AlertDialogFooter>
                        <Button ref={cancelRef} onClick={props.removal.cancel}>
                            Cancel
                        </Button>
                        <Button colorScheme='red' onClick={props.removal.onDelete} ml={3}>
                            Delete
                        </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                    </AlertDialogOverlay>
                </AlertDialog>}
            </VStack>
        );
    };
}

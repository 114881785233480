/**
 * Main wrapper file
 */

import React from 'react';

import { WithMobx } from './WithMobx';
// import { WithGlobal } from './WithGlobal';
import { WithRouterForApp, WithRouterForTesting } from './WithRouter';
import { WithTheme } from './WithTheme';
import { WithReactQuery } from './WithReactQuery';

/**
 * TODO:
 * 1. ADD Wrapper for i18n using - https://formatjs.io/docs/react-intl#formatting-data
 * 2. ADD Storybook wrapper
 * 3. ADD MobX wrapper
 * 4. ADD Main wrapper (all wrappers included)
 */

const WithAllAppWrappers: React.FunctionComponent = ({ children }) => {
    return (
        <WithReactQuery>
            <WithMobx>
                {/* <WithRouterForApp> */}
                <WithTheme>
                    {/* <WithGlobal /> */}
                    {children}
                </WithTheme>
                {/* </WithRouterForApp> */}
            </WithMobx>
        </WithReactQuery>
    );
};

const WithAllStorybookWrappers: React.FunctionComponent = ({ children }) => {
    return (
        <>
            <WithReactQuery>
                <WithMobx>
                    <WithRouterForTesting>
                        <>
                            {/* <WithGlobal /> */}
                            {children}
                        </>
                    </WithRouterForTesting>
                </WithMobx>
            </WithReactQuery>
        </>
    );
};

export default WithAllAppWrappers;
export { WithMobx, WithAllStorybookWrappers };

import { Box, ButtonGroup, Text, Tooltip, Button, Icon } from '@chakra-ui/react';
import { observer } from 'mobx-react';
import React from 'react';
import { OnboardingAssetRouteStrategy } from '../../../route';
import { OnboardingReportConfig } from './onboardingReportConfig';
import { createOnboardingReportView } from './onboardingReportView';
import { useLocation, useNavigate } from 'react-router';

// export const INSIGHT_WORKSPACE_ITEM_ID = 'insights';

export function createOnboardingReportItem(
    init: OnboardingReportConfig
): OnboardingAssetRouteStrategy {
    const { loader, hooks } = init;
    const controller = init.provider.createController(init);
    return {
        id: 'report',
        name: 'report',
        slug: 'report',
        create(config) {
            const Component = createOnboardingReportView(config);
            return {
                Component: observer((props) => {
                    const navigate = useNavigate();
                    const location = useLocation();
                    const organization = hooks.useOrganization();
                    const workspace = hooks.useWorkspace();
                    const auth = hooks.useAuth();
                    const context = { organization, workspace, auth };

                    const viewData = {
                        report: loader.report.useLoad(context, {
                            asset: {
                                id: workspace.id as number,
                            },
                        }),
                        prepare: loader.prepare.useData(context, {
                            asset: {
                                id: workspace.id as number,
                            },
                        }),
                    };

                    const viewForm = init.form.report.useForm(context, viewData.report, {
                        defaultValues: {},
                    });

                    const viewProps = controller.useProps(
                        {
                            navigate,
                            location,
                        },
                        context,
                        viewData,
                        viewForm,
                        {
                            asset: props.asset,
                            onContinue: props.onContinue,
                        }
                    );

                    return <Component {...viewProps} />;
                }),
            };
        },
    };
}

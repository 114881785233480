import { chain } from 'lodash';
import {
    AnyQuerySegment,
    DataValue,
    QueryMetadataFacet,
    QueryMetadataFacetTerm,
} from '../../../api';
import { BarChartData, BarChartDataset } from '../../../ui';
import type { QueryResult } from '../../../v2/domain/query';
import { assertNumeric, formatType, Schema } from '../../../domain';
import { BarVisualizationStrategy } from './barModel';
import { getSegmentColor } from '../../../v2/domain/visualization';
import { BarVisualizationProps } from './barProps';
import { BarVisualizationStrategyConfig } from './barConfig';
import { AnyResolvedType } from 'src/v2/domain/attributes';

function segmentDatasetStrategy(
    config: BarVisualizationStrategyConfig,
    properties: Schema['properties'],
    valueQuery: QueryResult,
    segments: AnyQuerySegment[],

    valueKey: string
): BarChartData {
    const seriesBySegment = valueQuery.series.items.reduce<Record<string, DataValue[]>>(
        (acc, curr) => {
            acc[curr.name] = curr.data.map((row) => row[valueKey]);
            return acc;
        },
        {}
    );
    const valueProperty = properties[valueKey];
    if (!valueProperty) {
        throw Error(`property ${valueKey} not found in schema`);
    }
    const data: BarChartData = {
        labels: segments.map((x) => x.name),
        flatten: true,
        datasets: segments.map((x, index) => {
            const segment = segments[index];
            return {
                label: x.name,
                description: segment
                    ? segment.description ??
                      config.intl.getDescription(segments[index], {
                          key: valueKey,
                          ...valueProperty,
                      })
                    : undefined,
                data: seriesBySegment[x.name],
                colorScheme: segment ? getSegmentColor(segment) : 'gray',
            };
        }),
    };
    return data;
}

function facetingDatasetStrategy(
    config: BarVisualizationStrategyConfig,
    properties: Schema['properties'],
    facetmetadata: QueryMetadataFacet,
    valueQuery: QueryResult,
    segments: AnyQuerySegment[] | undefined,

    valueKey: string
): BarChartData {
    const valueProperty = properties[valueKey];
    if (!valueProperty) {
        throw Error(`property ${valueKey} not found in schema`);
    }
    const data: BarChartData = {
        labels: facetmetadata.terms.map((x) => x.name),
        datasets: valueQuery.series.items.map<BarChartDataset>((series, index) => {
            const segment = segments?.[index];
            return {
                label: series.name,
                description: segment
                    ? segment.description ??
                      config.intl.getDescription(segment, {
                          key: valueKey,
                          ...valueProperty,
                      })
                    : null,
                data: series.data.map((row) => {
                    return row[valueKey];
                }),
                colorScheme: segment ? getSegmentColor(segment) : 'gray',
            };
        }),
    };
    return data;
}

export function createBarStrategy(
    init: BarVisualizationStrategyConfig
): BarVisualizationStrategy {
    return (
        context,
        visualization,
        request,
        response,
        config,
        { schema }
    ): BarVisualizationProps => {
        const [valueQuery, captionQuery, ...rest] = response.queries as [
            QueryResult,
            ...Array<QueryResult | undefined>
        ];

        if (rest.length > 0) {
            console.warn('bar chart does not support more than 2 queries');
        }

        const [valueKey, ...restValueKeys] = Object.keys(
            valueQuery.schema.properties
        ).filter((key) => response.metadata.scalar.includes(key));

        const [captionKey, ...restCaptionsKeys] = Object.keys(
            captionQuery?.schema.properties ?? {}
        ).filter((key) => response.metadata.scalar.includes(key)) as Array<
            string | undefined
        >;

        // console.log('DEBUG valueKey', valueKey);
        // console.log('DEBUG captionKey', captionKey);

        // console.log('DEBUG valueQuery', valueQuery);
        // console.log('DEBUG captionQuery', captionQuery);

        const facetmetadata = response.facet;

        if (!valueKey) {
            throw new Error(`bar type requires at least one scalar value`);
        }

        if (restValueKeys.length > 0) {
            const candidates = [valueKey, ...restValueKeys].join(', ');
            throw new Error(
                `bar chart must have a single scalar value, but found ${candidates}`
            );
        }

        if (restCaptionsKeys.length > 0) {
            throw new Error(`bar chart does not support multiple caption types`);
        }

        const allProperties: Schema['properties'] = Object.entries(
            {
                ...valueQuery.schema.properties,
                ...captionQuery?.schema.properties,
                ...schema.properties,
            } ?? {}
        ).reduce((acc, [key, value]) => {
            const valueType = valueQuery.schema.properties[key]?.type;
            const valueTypeCasted: AnyResolvedType | null =
                valueType === 'percent' ? 'percent' : null;
            return {
                ...acc,
                [key]: {
                    ...schema.properties[key],
                    // HACK to give precedence to type returned by response
                    type: valueTypeCasted ?? schema.properties[key]?.type,
                },
            };
        }, {} as Schema['properties']);
        // console.log('DEBUG allProperties', valueKey, allProperties);
        // console.log('DEBUG response schema', valueKey, response.queries[0].schema);

        // console.log('allProperties', allProperties);
        // console.log('i am a bar chart', dashboard);

        // console.log('DEBUG allProperties', allProperties);
        const valueType = allProperties[valueKey]?.type;
        assertNumeric(valueType, `bar chart not type fund  for value key ${valueKey}`);

        let data: BarChartData | null = null;
        let formatLabel: BarVisualizationProps['formatLabel'] = undefined;

        if (facetmetadata) {
            const termsByName = facetmetadata.terms.reduce<
                Record<string, QueryMetadataFacetTerm>
            >((agg, curr) => {
                agg[curr.name] = curr;
                return agg;
            }, {});
            (formatLabel = (t) =>
                chain(termsByName[t].value)
                    .keys()
                    .map((propKey) => {
                        const facetProp = allProperties[propKey];
                        const facetValue = termsByName[t].value[propKey];
                        if (facetProp) {
                            return formatType(context, facetProp.type, facetValue);
                        } else {
                            return facetValue || 'unknown';
                        }
                    })
                    // we use unique here to account for multi-dimensional breakdowns where
                    // otherwise the merged facet label would have been, eg. "other / other"
                    .uniq()
                    .value()
                    .join(' / ')),
                (data = facetingDatasetStrategy(
                    init,
                    allProperties,
                    facetmetadata,
                    valueQuery,
                    request.segments,
                    valueKey
                ));
        } else if (request.segments) {
            data = segmentDatasetStrategy(
                init,
                allProperties,
                valueQuery,
                request.segments,
                valueKey
            );
        } else {
            throw new Error(`bar chart requires either faceting or segmenting`);
        }

        return {
            data,
            tooltipProps: { enabled: !!captionQuery },
            getValue: ({ rowIndex, columnIndex }, original) => {
                // console.log('value', rowIndex, columnIndex, original);
                const formatted = formatType(context, valueType, original);
                return formatted;
            },
            getCaption:
                !captionKey || !captionQuery
                    ? undefined
                    : ({ rowIndex, columnIndex }, original) => {
                          const property = allProperties[captionKey];
                          if (!property) {
                              throw new Error(
                                  `caption key '${captionKey}' not found in schema`
                              );
                          }
                          const value =
                              captionQuery.series.items[rowIndex].data[columnIndex][
                                  captionKey
                              ];
                          return formatType(context, property.type, value);
                      },
            formatUnit: (value) => formatType(context, valueType, value),
            formatLabel,
        };
    };
}

import { capitalize, chain } from 'lodash';
import { Formatter } from '../../../../../hooks';
import { DatasetConnectionEntity } from '../../../../app/datasets';
import { assertNever } from '../../../../util';
import { buildStatusProps, StatusInfo, StatusProps } from '../../../../view/common';
import { buildDatasetItemViewProps } from '../../dataset';
import { DataSourceStatus, DataSourceStatusKind } from '../status';
import { getSourceStatus } from './sourceItemHelper';
import { DataSourceAggregate } from './sourceItemModel';
import { DataSourceItemProps } from './sourceItemProps';

export function buildDataSourceItemProps(
    formatter: Formatter,
    item: DataSourceAggregate
): DataSourceItemProps {
    const mostRecentUpdated = (chain(item.connections)
        .maxBy((item) => Number(item.updatedAt))
        .value() ?? null) as DatasetConnectionEntity | null;

    const updatedAt = mostRecentUpdated?.updatedAt ?? item.mapping.createdAt;

    const absoluteDateLabel = formatter.date(updatedAt, {});
    const absoluteTimeLabel = formatter.time(updatedAt, {});
    const relativeLabel = formatter.timeago(updatedAt, {});

    const status = getSourceStatus(item);
    const statusInfo = buildDataSourceStatusInfo(item, status);
    return {
        id: item.entity.key,
        name: item.entity.name ?? 'Unnamed',
        secondary: item.entity.key,
        iconUrl: item.definition.iconUrl ?? 'N/A',
        status: buildStatusProps(statusInfo),
        updated: {
            relative: {
                label: relativeLabel,
            },
            absolute: {
                description: `Data source was last updated on ${absoluteDateLabel} at ${absoluteTimeLabel}`,
            },
        },
        getMetadataListProps() {
            return {
                items: [
                    {
                        // label: item.definition.entityName,
                        label: item.entity.key,
                    },
                ],
            };
        },
    };
}

export function buildDataSourceStatusInfo(
    item: DataSourceAggregate,
    status: DataSourceStatus
): StatusInfo {
    if (status.kind === 'active') {
        return {
            value: status.kind,
            kind: 'success',
            label: 'Active',
            description: `Data source is active and up-to-date`,
            ordinal: 1,
        };
    }
    if (status.kind === 'importing') {
        return {
            value: status.kind,
            kind: 'warning',
            label: 'Importing',
            description: `Data source is importing integration data`,
            ordinal: 1,
        };
    }
    if (status.kind === 'stale') {
        return {
            value: status.kind,
            kind: 'warning',
            label: 'Stale',
            description: null,
            ordinal: 1,
        };
    }
    if (status.kind === 'error') {
        return {
            value: status.kind,
            kind: 'error',
            label: `Error`,
            reason: status.key,
            description: `An error has occured and this data source is no longer be synced`,
            ordinal: 1,
        };
    }
    if (status.kind === 'delayed') {
        return {
            value: status.kind,
            kind: 'warning',
            label: 'Delayed',
            description: `Integration data is taking longer than expected to import`,
            ordinal: 1,
        };
    }
    if (status.kind === 'created') {
        return {
            value: status.kind,
            kind: 'warning',
            label: 'Processing',
            description: `Data source has been created and is being processed`,
            ordinal: 1,
        };
    }
    if (status.kind === 'modified') {
        return {
            value: status.kind,
            kind: 'warning',
            label: 'Updating',
            description: `Data source has recently been modified and is resyncing`,
            ordinal: 1,
        };
    }
    assertNever(status);
}

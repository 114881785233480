import React, { useEffect } from 'react';
import { AnchorProps, ApplicationLinkProps, ApplicationUI } from '../../../ui';
import { ApplicationEntryEnhancer } from '../../../entrypoint';
import { AnyLinkEvent } from './trackingLinkEvent';

export function createLinkTrackingStrategy(): ApplicationEntryEnhancer {
    return (create) => (init) => {
        function useTracker() {
            const tracker = instance.infra.useTracker<AnyLinkEvent>();
            return tracker;
        }

        function enhanceAnchor(Component: React.FC<AnchorProps>): React.FC<AnchorProps> {
            return (props) => {
                const tracker = useTracker();
                return React.createElement(Component, {
                    ...props,
                    onClick(event) {
                        tracker.track('external_link_clicked', {
                            link_id: props.id,
                            link_url: props.href,
                        });
                        return event;
                    },
                });
            };
        }

        function enhanceLink(
            Component: React.FC<ApplicationLinkProps>
        ): React.FC<ApplicationLinkProps> {
            return (props) => {
                const tracker = useTracker();
                const { id: linkId } = props;
                if (!linkId) {
                    return React.createElement(Component, props);
                }
                return React.createElement(Component, {
                    ...props,
                    onClick(event) {
                        tracker.track('internal_link_clicked', {
                            link_id: linkId,
                            link_path: props.to as string,
                        });
                        return event;
                    },
                });
            };
        }

        function enhanceUI(UI: ApplicationUI): ApplicationUI {
            return {
                ...UI,
                Anchor: enhanceAnchor(UI.Anchor),
                Link: enhanceLink(UI.Link),
            };
        }

        const instance = create({
            ...init,
            ui: {
                ...init.ui,
                createWorkspaceUI(...args) {
                    const UI = init.ui.createWorkspaceUI(...args);
                    return enhanceUI(UI);
                },
            },
        });

        return instance;
    };
}

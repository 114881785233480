import { keyBy } from 'lodash';
import { useMemo } from 'react';
import { getAnalysisMetricLink } from '../../../../route/metric';
import { getVisualizationName } from '../../../../domain/visualization';
import { assert } from '../../../../util/assert';
import { InsightRouteConfigV2 } from '../insightRouteConfig';
import { OpportunityRouteVisualizationController } from './visualizationInterface';

export function createOpportunityRouteVisualizationController(
    config: Pick<InsightRouteConfigV2['deps'], 'controller'>
): OpportunityRouteVisualizationController {
    const { controller } = config;
    return {
        useProps(context, data, props) {
            assert(data.item.status === 'loaded', 'expected suspense');

            const resultProps = controller.visualization.result.useProps({
                visualization: props.visualization,
                item: data.item.data.result ?? null,
            });

            const itemProps = controller.opportunity.item.useProps(context, {
                item: props.opportunity,
            });

            const columnsByKey = useMemo(
                () => keyBy(data.item.data?.view.columns ?? [], (item) => item.key),
                [data.item.data.view]
            );

            const label = useMemo(
                () =>
                    getVisualizationName(
                        data.item.data?.plugin ?? null,
                        columnsByKey,
                        props.visualization
                    ),
                [data.item.data?.plugin, columnsByKey, props.visualization]
            );

            return {
                label,
                getPluginProps() {
                    return {
                        iconUrl: data.item.data?.plugin.iconUrl ?? '',
                    };
                },
                getResultProps() {
                    return resultProps;
                },
                getRouteProps() {
                    return {
                        path: getAnalysisMetricLink(
                            context,
                            // @ts-expect-error
                            props.visualization
                        ),
                        onOpenClick: itemProps.onOpenClick,
                    };
                },
            };
        },
    };
}

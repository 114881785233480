import React from 'react';
import { Navigate } from 'react-router';
import { BillingChargeCompleteConfig } from './chargeCompleteConfig'
import { ChargeCompleteViewProps } from '../../../view/billing/charge'
import { Center, Container } from '@chakra-ui/react';
import { props } from 'lodash/fp';


export function createChargeCompleteView(config: BillingChargeCompleteConfig): React.FC<ChargeCompleteViewProps> {
    const {
        UI: { Anchor, Link, Heading, Button },
    } = config;
    return ({ charge: { name }, status, nextUrl }) => {
        let msg = null;
        switch(status) {
            case 'error':
                msg = <Heading size='md' colorScheme='red'>Error processing charge: {name}</Heading>
                break;
            case 'processing':
                msg = <Heading size='md'>Processing charge - {name}...</Heading>
                break;
            case 'success':
                msg = <Heading size='md' color='green'>Success. Redirecting...</Heading>
                break;
            default:
                break;
                
        }
        if(nextUrl) {
            return <Navigate to={nextUrl} />;
        }
        return <Container>
            <Center justifyContent='center' alignContent='center' textAlign='center'>
                {msg}
            </Center>
        </Container>
    }
}
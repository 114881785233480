import {
    Box,
    Button,
    ButtonGroup,
    Center,
    Grid,
    GridItem,
    Heading,
    HStack,
    Icon,
    Popover,
    PopoverBody,
    PopoverContent,
    PopoverFooter,
    PopoverHeader,
    PopoverTrigger,
    StackDivider,
    Text,
    VStack,
    Wrap,
    WrapItem,
} from '@chakra-ui/react';
import React, { Suspense, useMemo } from 'react';
import { HiOutlineHashtag } from 'react-icons/hi';
import { HiOutlineAdjustments } from 'react-icons/hi';
import { BsPercent } from 'react-icons/bs';
import { FaChartPie } from 'react-icons/fa';
import { BsChatLeftDotsFill } from 'react-icons/bs';
import { MdOutlineOpenInNew } from 'react-icons/md';
import { ChevronRightIcon, SmallCloseIcon } from '@chakra-ui/icons';
import { CardSpinner } from '../../../../../domain';
import { Icons } from '../../../../../config';
import { ApiError } from '../../../../base';
import { StudiesChartRouteContainerProps, StudiesChartRouteViewProps } from '../../chart';
import { StudiesResponseDetailViewProps } from './studiesResponseDetailProps';
import { StudiesResponseItemContainerProps, StudiesResponseItemViewProps } from '../item';
import { StudiesBaseRouteConfig } from '../../base';
import { AiOutlinePlus, AiOutlineWarning } from 'react-icons/ai';
import {
    createFilterPopoverContainer,
    FilterPopoverViewProps,
} from '../../../../view/common';
import { createInputContainer } from '../../../../ui';
import { NetworkInput } from '../../../networks/request/new/candidate';

export function createStudiesResponseDetailView(
    config: Pick<StudiesBaseRouteConfig, 'Layout' | 'UI'>,
    View: {
        Item: React.FC<StudiesResponseItemContainerProps>;
        Chart: React.FC<StudiesChartRouteContainerProps>;
    }
): React.FC<StudiesResponseDetailViewProps> {
    const {
        UI: {
            Date: {
                DatePicker: {
                    Container: DatePicker,
                    Relative: DatePickerRelative,
                    Absolute: DatePickerAbsolute,
                },
            },
            Application: { Link: ApplicationLink, createErrorBoundary, Tooltip },
            Visualization: {
                BarChartV2: { Container: BarChart, Item: BarItem, Scale: BarScale },
            },
        },
    } = config;

    const ChartErrorBoundary = createErrorBoundary({
        errorClass: ApiError,
    });

    const InputContainer = createInputContainer();
    const PopoverContainer = createFilterPopoverContainer();

    const { Chart: ChartContainer, Item: ResponseItemContainer } = View;

    const AnswerIcon = BsChatLeftDotsFill;
    const CustomSegmentIcon = HiOutlineAdjustments;
    const SegmentIcon = FaChartPie;
    const MenuOpenIcon = MdOutlineOpenInNew;

    // purple
    // const COLOR = '#7164ff';

    // varos green
    const COLOR = '#c6e74f';

    const VisualizationItem: React.FC<StudiesChartRouteViewProps> = (props) => {
        if (props.chart.kind === 'bar') {
            return (
                <>
                    <HStack
                        pointerEvents="none"
                        w="full"
                        justify="end"
                        position="absolute"
                        top={0}
                    >
                        <HStack h={10} align="center">
                            <Text fontSize="sm">
                                <Box color="whiteAlpha.600" as="span" fontWeight="medium">
                                    Responses:
                                </Box>{' '}
                                <Box
                                    as="span"
                                    color="whiteAlpha.800"
                                    fontWeight="semibold"
                                >
                                    {props.responses.absolute.label}
                                </Box>{' '}
                                <Box
                                    as="span"
                                    color="whiteAlpha.800"
                                    fontWeight="semibold"
                                >
                                    ({props.responses.relative.label})
                                </Box>{' '}
                            </Text>
                        </HStack>
                    </HStack>
                    <Box py={6}>
                        <BarChart {...props.chart.chart}>
                            <BarScale {...props.scale} />
                            <BarItem
                                bg={COLOR}
                                h={8}
                                color="whiteAlpha.800"
                                borderColor="whiteAlpha.300"
                            />
                        </BarChart>
                    </Box>
                </>
            );
        }
        return <Box>unsupported chart</Box>;
    };

    const ResponseItem: React.FC<StudiesResponseItemViewProps> = (props) => {
        return (
            <VStack w="full" align="start" spacing={props.metric.description ? 12 : 6}>
                <VStack w="full" spacing={3} align="start">
                    <Heading
                        cursor="pointer"
                        _hover={{ textDecoration: 'underline' }}
                        fontSize="xl"
                    >
                        <HStack w="full" spacing={3}>
                            <Text>{props.metric.label}</Text>
                            <Icon fontSize="lg" as={MenuOpenIcon} />
                        </HStack>
                    </Heading>
                    {props.metric.description && (
                        <Text
                            fontSize="lg"
                            fontWeight="medium"
                            color="whiteAlpha.700"
                            maxW="42rem"
                        >
                            {props.metric.description}
                        </Text>
                    )}
                </VStack>
                <VStack align="start" w="full" spacing={12} position="relative">
                    <HStack w="full" justify="space-between">
                        <ButtonGroup spacing={3}>
                            {props.segment.options.map((item) => (
                                <Tooltip
                                    key={item.label}
                                    placement="top"
                                    hasArrow={true}
                                    p={4}
                                    bg="#1D1C21"
                                    isDisabled={!item.isDisabled}
                                    label={item.disabledReason}
                                >
                                    {/* need to wrap in box otherwise tooltip does not trigger when disabled */}
                                    <Box>
                                        <Button
                                            aria-selected={item.isSelected}
                                            isDisabled={item.isDisabled}
                                            onClick={item.onClick}
                                            borderRadius="full"
                                            px={6}
                                            h={10}
                                            bg="none"
                                            color="whiteAlpha.700"
                                            _focus={{ outline: 'none' }}
                                            _hover={{
                                                bg: 'whiteAlpha.300',
                                            }}
                                            _disabled={{
                                                opacity: 0.6,
                                                _hover: {
                                                    bg: 'none',
                                                },
                                            }}
                                            _selected={{
                                                borderColor: COLOR,
                                                bg: COLOR,
                                                color: 'black',
                                            }}
                                            leftIcon={<Icon as={SegmentIcon} />}
                                        >
                                            {item.label}
                                        </Button>
                                    </Box>
                                </Tooltip>
                            ))}
                        </ButtonGroup>
                    </HStack>
                    <VStack w="full" align="start" spacing={12}>
                        <Box w="full" h="full">
                            <ChartErrorBoundary
                                {...props.errorBoundary}
                                fallback={(info) => (
                                    <Center
                                        w="full"
                                        h="full"
                                        p={12}
                                        borderWidth={2}
                                        borderColor="whiteAlpha.300"
                                        borderStyle="solid"
                                        borderRadius="lg"
                                    >
                                        <VStack spacing={3} fontWeight="medium">
                                            <Icon
                                                w={12}
                                                h={12}
                                                color="red.500"
                                                as={AiOutlineWarning}
                                            />
                                            <VStack spacing={0}>
                                                <Text
                                                    fontWeight="semibold"
                                                    color="whiteAlpha.700"
                                                >
                                                    Failed to load chart data
                                                </Text>
                                                <Text
                                                    fontSize="sm"
                                                    color="whiteAlpha.700"
                                                >
                                                    If this issue continues, please
                                                    <Box as="span" color="blue.400">
                                                        {' '}
                                                        contact support
                                                    </Box>
                                                </Text>
                                            </VStack>
                                        </VStack>
                                    </Center>
                                )}
                            >
                                {/* <Suspense
                                    fallback={
                                        <Center h="full" w="full" p={12}>
                                            <CardSpinner />
                                        </Center>
                                    }
                                > */}
                                {props.children}
                                {/* </Suspense> */}
                            </ChartErrorBoundary>
                        </Box>
                        <HStack w="full" justify="space-between" spacing={32}>
                            <HStack
                                w="full"
                                spacing={4}
                                color="whiteAlpha.600"
                                fontSize="sm"
                                align="start"
                                // maxW="24rem"
                                fontWeight="medium"
                            >
                                <Icon
                                    fontSize="lg"
                                    position="relative"
                                    top="0.3rem"
                                    as={AnswerIcon}
                                />
                                <Text>{props.question.label}</Text>
                            </HStack>
                            {props.mode && (
                                <ButtonGroup size="sm" spacing={2}>
                                    {props.mode.options.map((item) => (
                                        <Button
                                            key={item.label}
                                            aria-selected={item.isSelected}
                                            borderRadius="full"
                                            leftIcon={<Icon as={item.Icon} />}
                                            onClick={item.onClick}
                                            color="whiteAlpha.700"
                                            bg="none"
                                            _focus={{ outline: 'none' }}
                                            _hover={{ bg: 'whiteAlpha.300' }}
                                            _selected={{
                                                _hover: { bg: 'blue.300' },
                                                bg: 'blue.200',
                                                color: 'black',
                                            }}
                                        >
                                            {item.label}
                                        </Button>
                                    ))}
                                </ButtonGroup>
                            )}
                        </HStack>
                    </VStack>
                </VStack>
            </VStack>
        );
    };

    const FilterItem: React.FC<FilterPopoverViewProps> = (props) => {
        return (
            <Popover
                placement="bottom"
                {...props.popover}
                isLazy={true}
                lazyBehavior="unmount"
            >
                <PopoverTrigger>
                    <HStack
                        userSelect="none"
                        cursor="pointer"
                        aria-selected={props.item.isActive}
                        borderWidth={2}
                        borderStyle="dashed"
                        borderColor="whiteAlpha.300"
                        h={10}
                        px={4}
                        spacing={2}
                        borderRadius="full"
                        fontWeight="medium"
                        _hover={{
                            bg: 'whiteAlpha.50',
                        }}
                        _selected={{
                            borderWidth: 1,
                            borderStyle: 'solid',
                            borderColor: 'blue.300',
                            color: 'blue.300',
                        }}
                    >
                        {props.item.isActive && (
                            <>
                                <Text>{props.item.value.label}</Text>
                                <Icon
                                    _hover={{ color: 'blue.400' }}
                                    as={SmallCloseIcon}
                                    {...props.button.clear}
                                />
                            </>
                        )}
                        {!props.item.isActive && (
                            <>
                                <Text>{props.button.trigger.children}</Text>
                                <Icon as={AiOutlinePlus} />
                            </>
                        )}
                    </HStack>
                </PopoverTrigger>
                <PopoverContent bg="#1D1C21" _focus={{ outline: 'none' }}>
                    <PopoverHeader px={3}>
                        <HStack w="full" justify="space-between">
                            <HStack w="full">
                                <Text fontWeight="semibold">{props.item.title}</Text>
                            </HStack>
                        </HStack>
                    </PopoverHeader>
                    <PopoverBody p={6} maxH="32vh" overflowY="auto">
                        <InputContainer {...props.item.input} as={NetworkInput} />
                    </PopoverBody>
                    <PopoverFooter px={3}>
                        <HStack w="full" justify="space-between">
                            {props.selection && (
                                <Text
                                    whiteSpace="nowrap"
                                    color="blue.300"
                                    fontWeight="semibold"
                                    fontSize="sm"
                                >
                                    {props.selection.label}
                                </Text>
                            )}
                            <HStack w="full" justify="end">
                                {props.button.reset && (
                                    <Button
                                        size="sm"
                                        _focus={{ outline: 'none' }}
                                        {...props.button.reset}
                                    >
                                        Reset
                                    </Button>
                                )}
                                <Button
                                    colorScheme="blue"
                                    size="sm"
                                    _focus={{ outline: 'none' }}
                                    {...props.button.apply}
                                >
                                    Apply
                                </Button>
                            </HStack>
                        </HStack>
                    </PopoverFooter>
                </PopoverContent>
            </Popover>
        );
    };

    return (props) => {
        return (
            <Grid templateColumns="1fr" rowGap={6} w="full">
                <GridItem>
                    <VStack
                        borderBottom={2}
                        borderColor="whiteAlpha.300"
                        borderStyle="solid"
                        pb={4}
                        w="full"
                        align="start"
                        spacing={6}
                        divider={
                            <StackDivider borderWidth={1} borderColor="whiteAlpha.300" />
                        }
                    >
                        <HStack w="full" justify="space-between" spacing={12} align="end">
                            <VStack w="full" align="start" spacing={4}>
                                <HStack w="full" align="start">
                                    <VStack w="full" align="start" spacing={1}>
                                        <HStack
                                            fontWeight="semibold"
                                            spacing={2}
                                            divider={
                                                <Icon
                                                    boxSize="1.20rem"
                                                    color="whiteAlpha.500"
                                                    as={ChevronRightIcon}
                                                    border="none"
                                                />
                                            }
                                        >
                                            <ApplicationLink to="../..">
                                                <Text
                                                    color="blue.300"
                                                    _hover={{ color: 'blue.400' }}
                                                >
                                                    Reports
                                                </Text>
                                            </ApplicationLink>
                                            {/* <ApplicationLink to="..">
                                                <Text
                                                    color="blue.300"
                                                    _hover={{ color: 'blue.400' }}
                                                >
                                                    Submissions
                                                </Text>
                                            </ApplicationLink> */}
                                        </HStack>
                                        <Heading fontSize="2xl">Response</Heading>
                                        <HStack w="full">
                                            <Text
                                                fontWeight="medium"
                                                color="whiteAlpha.700"
                                            >
                                                For report
                                            </Text>
                                            <ApplicationLink
                                                {...props.links.report.detail}
                                            >
                                                <HStack
                                                    spacing={2}
                                                    cursor="pointer"
                                                    _hover={{
                                                        textDecoration: 'underline',
                                                    }}
                                                >
                                                    <Icon as={Icons.Reports.Study} />
                                                    <Text
                                                        as="span"
                                                        color="whiteAlpha.800"
                                                        fontWeight="semibold"
                                                    >
                                                        {props.report.label}
                                                    </Text>
                                                </HStack>
                                            </ApplicationLink>
                                        </HStack>
                                    </VStack>
                                </HStack>
                            </VStack>
                            <DatePicker {...props.date} isAttached={true}>
                                <DatePickerRelative />
                                <DatePickerAbsolute />
                            </DatePicker>
                        </HStack>
                    </VStack>
                </GridItem>
                <GridItem>
                    <VStack
                        align="start"
                        w="full"
                        spacing={4}
                        divider={
                            <StackDivider borderColor="whiteAlpha.300" borderWidth={1} />
                        }
                    >
                        <VStack align="start" w="full" spacing={6}>
                            {props.peer.filter.items.length > 0 && (
                                <VStack align="start" w="full" spacing={3}>
                                    <HStack w="full">
                                        <Text
                                            color="whiteAlpha.700"
                                            fontWeight="bold"
                                            fontSize="sm"
                                            textTransform="uppercase"
                                            letterSpacing="wider"
                                        >
                                            Peer group
                                        </Text>
                                    </HStack>
                                    <Wrap spacingX={3} spacingY={1}>
                                        {props.peer.filter.items.map((item) => (
                                            <WrapItem key={item.key}>
                                                <PopoverContainer
                                                    as={FilterItem}
                                                    {...props.peer.filter.getPopoverProps(
                                                        item
                                                    )}
                                                />
                                            </WrapItem>
                                        ))}
                                    </Wrap>
                                </VStack>
                            )}
                            <Suspense
                                fallback={
                                    <Center h="full" w="full" p={12}>
                                        <CardSpinner />
                                    </Center>
                                }
                            >
                                <VStack
                                    w="full"
                                    align="start"
                                    spacing={12}
                                    py={6}
                                    divider={
                                        <StackDivider
                                            borderColor="whiteAlpha.200"
                                            borderWidth={2}
                                        />
                                    }
                                >
                                    {props.items.map((item, index) => {
                                        const itemProps = props.getItemProps(item);
                                        const chartProps = props.getChartProps(item);
                                        return (
                                            <ResponseItemContainer
                                                as={ResponseItem}
                                                key={index}
                                                {...itemProps}
                                            >
                                                <ChartContainer
                                                    as={VisualizationItem}
                                                    {...chartProps}
                                                />
                                            </ResponseItemContainer>
                                        );
                                    })}
                                </VStack>
                            </Suspense>
                        </VStack>
                    </VStack>
                </GridItem>
            </Grid>
        );
    };
}

import { z } from 'zod';

export const RelativePeriodSchema = z.object({
    interval: z.enum(['day', 'week', 'month', 'quarter', 'year', 'period']),
    amount: z.number(),
});

export const AbsolutePeriodSchema = z.object({
    start: z.date({ coerce: true }),
    end: z.date({ coerce: true }),
});

export const AnyPeriodSchema = z.union([RelativePeriodSchema, AbsolutePeriodSchema]);

import { ErrorBoundary as SentryErrorBoundary, ErrorBoundaryProps } from '@sentry/react';
import React from 'react';

export interface SentryErrorBoundaryProps extends ErrorBoundaryProps {}

export const ErrorBoundary: React.FC<SentryErrorBoundaryProps> = ({
    fallback = <p>An error has occurred</p>,
    ...props
}) => {
    return (
        <SentryErrorBoundary fallback={fallback}>{props.children}</SentryErrorBoundary>
    );
};

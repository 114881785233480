import { AlertStatus, TagProps } from '@chakra-ui/react';
import { IntegrationStatus } from 'src/v2/domain/assets';

export const COLORS: Record<AlertStatus, TagProps['colorScheme'] | undefined> = {
    warning: 'orange',
    success: 'green',
    error: 'red',
    info: 'gray',
    // analyzing: 'orange',
    // importing: 'orange',
    // syncing: 'green',
    // ready: 'green',
    // disconnected: 'red',
};

import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import React from 'react';
import { useLocation, useNavigationType, createRoutesFromChildren, matchRoutes } from 'react-router-dom';
import { getApplicationEnv } from '..';
import { ErrorBoundary } from './component';
import { SentryConfig } from './sentryConfig';

export function createSentry(config: SentryConfig) {
    return {
        initialize() {
            Sentry.init({
                environment: getApplicationEnv(),
                ignoreErrors: ['ResizeObserver loop limit exceeded'],
                integrations: [
                    new BrowserTracing({
                        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
                            React.useEffect,
                            useLocation,
                            useNavigationType,
                            createRoutesFromChildren,
                            matchRoutes
                        ),
                    }),
                ],
                ...config,
            });
        },
        ErrorBoundary,
    };
}

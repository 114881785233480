import {
    Box,
    Button,
    Grid,
    GridItem,
    Heading,
    HStack,
    Image,
    Input,
    List,
    ListItem,
    StackDivider,
    Switch,
    Tag,
    Text,
    Tooltip,
    VStack,
    FormControl,
    FormLabel,
    InputGroup,
    InputLeftAddon,
    Icon,
    SimpleGrid,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverBody,
    useDisclosure,
    PopoverHeader,
    Center,
    Wrap,
    WrapItem,
    Alert,
    AlertTitle,
    AlertIcon,
    Spinner,
    ButtonGroup,
    LightMode,
    Radio,
} from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import React from 'react';
import {
    AiOutlineArrowRight,
    AiOutlineCaretDown,
    AiOutlineCheckCircle,
    AiOutlineClose,
    AiOutlineEdit,
    AiOutlineInfoCircle,
    AiOutlineLink,
} from 'react-icons/ai';
import { FiChevronDown } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { RemoteIframe } from '../../../../ui';
import { CardSpinner } from '../../../../domain';
import { AssetPrepareViewProps } from '../../../view/assets';
import { ReportNewViewProps } from '../../../view/reports';
import { OnboardingStrategyConfig } from '../../../route';
import { OnboardingReportViewProps } from './onboardingReportProps';
import ReactDatePicker from 'react-datepicker';
import { CalendarIcon } from '@chakra-ui/icons';
import { HiOutlineDocumentReport } from 'react-icons/hi';

export function createOnboardingReportView(
    config: OnboardingStrategyConfig
): React.FC<OnboardingReportViewProps> {
    const {
        Layout: { Container, Content },
    } = config;

    const ReportIcon = HiOutlineDocumentReport;
    const Definitions = [
        {
            title: 'Performance Audit',
            // description: `Non veniam velit officia veniam Lorem`,
            description: null,
        },
        // {
        //     title: 'Executive Summary',
        //     description: `Quis amet fugiat do et culpa laborum esse qui`,
        // },
        // {
        //     title: 'Media mix',
        //     description: `Fugiat sint magna veniam duis velit do quis nulla aliquip Lorem anim non`,
        // },
        // {
        //     title: 'Market trends',
        //     description: `Veniam commodo sunt voluptate consequat deserunt`,
        // },
    ] as const;

    return (props) => {
        if (props.status === 'loading') {
            return (
                <Center p={6}>
                    <CardSpinner />
                </Center>
            );
        }
        const reportProps = props.getReportProps();
        const prepareProps = props.getPrepareProps();
        const formProps = props.getFormProps();
        if (prepareProps.status !== 'success' || reportProps.status === 'loading') {
            return (
                <Center p={6}>
                    <CardSpinner />
                </Center>
            );
        }
        const dateProps = reportProps.getDateProps();
        const statusProps = prepareProps.getStatusProps();
        return (
            <Container title="Create your report">
                <Content isLoading={false}>
                    {statusProps.status === 'ready' ? (
                        <></>
                    ) : (
                        <Alert status="warning">
                            <AlertIcon />
                            <AlertTitle fontWeight="semibold" w="full">
                                <HStack w="full" justify="space-between">
                                    <Text fontWeight="semibold">{statusProps.label}</Text>
                                    {statusProps.isLoading && (
                                        <Spinner speed="3.5s" boxSize={5} />
                                    )}
                                </HStack>
                            </AlertTitle>
                        </Alert>
                    )}
                    <VStack
                        w="full"
                        spacing={6}
                        py={6}
                        divider={
                            <StackDivider borderColor="gray.300" borderWidth={0.5} />
                        }
                        align="start"
                    >
                        <Grid w="full" gap={12} templateColumns="1fr 1fr">
                            <GridItem>
                                <Grid
                                    templateRows={{ base: 'min-content 1fr' }}
                                    w="full"
                                    gap={4}
                                >
                                    <GridItem>
                                        <VStack
                                            w="full"
                                            spacing={8}
                                            divider={
                                                <StackDivider borderColor="gray.300" />
                                            }
                                        >
                                            <VStack w="full" spacing={4} align="start">
                                                <FormControl>
                                                    <FormLabel>Report type</FormLabel>
                                                    <VStack spacing={4} overflow="hidden">
                                                        {Definitions.map(
                                                            (item, index) => (
                                                                <Box
                                                                    w="full"
                                                                    key={item.title}
                                                                >
                                                                    <VStack
                                                                        aria-selected={
                                                                            index === 0
                                                                        }
                                                                        cursor={
                                                                            index === 0
                                                                                ? 'pointer'
                                                                                : undefined
                                                                        }
                                                                        w="full"
                                                                        px={6}
                                                                        py={3}
                                                                        borderWidth={2}
                                                                        borderColor="gray.300"
                                                                        borderStyle="solid"
                                                                        borderRadius="full"
                                                                        align="start"
                                                                        _selected={{
                                                                            borderColor:
                                                                                'blue.400',
                                                                            // bg: 'gray.100',
                                                                        }}
                                                                    >
                                                                        <HStack w="full">
                                                                            <HStack
                                                                                w="full"
                                                                                align="center"
                                                                                flex={1}
                                                                                spacing={
                                                                                    4
                                                                                }
                                                                            >
                                                                                <VStack
                                                                                    align="start"
                                                                                    spacing={
                                                                                        -0.5
                                                                                    }
                                                                                >
                                                                                    <HStack>
                                                                                        <Text
                                                                                            fontWeight="semibold"
                                                                                            fontSize="lg"
                                                                                            color={
                                                                                                index >
                                                                                                0
                                                                                                    ? 'gray.500'
                                                                                                    : undefined
                                                                                            }
                                                                                        >
                                                                                            {
                                                                                                item.title
                                                                                            }
                                                                                        </Text>
                                                                                        {item.description && (
                                                                                            <Tooltip
                                                                                                placement="top"
                                                                                                hasArrow={
                                                                                                    true
                                                                                                }
                                                                                                p={
                                                                                                    4
                                                                                                }
                                                                                                label={
                                                                                                    item.description
                                                                                                }
                                                                                                isDisabled={
                                                                                                    item.description ===
                                                                                                    null
                                                                                                }
                                                                                            >
                                                                                                <span>
                                                                                                    <Icon
                                                                                                        color={
                                                                                                            index >
                                                                                                            0
                                                                                                                ? 'gray.500'
                                                                                                                : 'gray.700'
                                                                                                        }
                                                                                                        as={
                                                                                                            AiOutlineInfoCircle
                                                                                                        }
                                                                                                    />
                                                                                                </span>
                                                                                            </Tooltip>
                                                                                        )}
                                                                                    </HStack>
                                                                                </VStack>
                                                                            </HStack>
                                                                            {index >
                                                                                0 && (
                                                                                <Tag
                                                                                    flexShrink={
                                                                                        0
                                                                                    }
                                                                                    size="md"
                                                                                    // variant="outline"
                                                                                    bg="none"
                                                                                    color="blue.500"
                                                                                    fontWeight="bold"
                                                                                    letterSpacing="wide"
                                                                                    textTransform="uppercase"
                                                                                    fontSize="xs"
                                                                                    colorScheme="blue"
                                                                                    borderRadius="sm"
                                                                                >
                                                                                    Coming
                                                                                    soon
                                                                                </Tag>
                                                                            )}
                                                                            {index ===
                                                                                0 && (
                                                                                <Box>
                                                                                    <Radio
                                                                                        colorScheme="blue"
                                                                                        isChecked={
                                                                                            true
                                                                                        }
                                                                                    />
                                                                                </Box>
                                                                            )}
                                                                        </HStack>
                                                                    </VStack>
                                                                </Box>
                                                            )
                                                        )}
                                                    </VStack>
                                                </FormControl>
                                            </VStack>
                                        </VStack>
                                    </GridItem>
                                    <GridItem>
                                        <VStack
                                            w="full"
                                            spacing={8}
                                            divider={
                                                <StackDivider borderColor="gray.300" />
                                            }
                                            align="start"
                                        >
                                            <VStack w="full" spacing={4} align="start">
                                                <FormControl>
                                                    <FormLabel>Dates</FormLabel>
                                                    <ReactDatePicker
                                                        calendarClassName="light"
                                                        customInput={
                                                            <HStack
                                                                cursor="pointer"
                                                                w="full"
                                                                bg="white"
                                                                borderColor="gray.300"
                                                                borderWidth={1}
                                                                borderStyle="solid"
                                                                px={5}
                                                                py={3}
                                                                borderRadius="md"
                                                                fontWeight="medium"
                                                                justify="space-between"
                                                            >
                                                                <Text>
                                                                    {dateProps.getAbsoluteLabel(
                                                                        dateProps.value,
                                                                        true
                                                                    )}
                                                                </Text>
                                                                <Icon
                                                                    color="gray.500"
                                                                    as={CalendarIcon}
                                                                />
                                                            </HStack>
                                                        }
                                                        {...dateProps.getRangeProps()}
                                                        selectsRange={true}
                                                    />
                                                </FormControl>
                                            </VStack>
                                            <Button
                                                w="full"
                                                size="lg"
                                                colorScheme="green"
                                                onClick={formProps.onSubmit}
                                                isLoading={formProps.isSubmitting}
                                                isDisabled={!formProps.isSubmittable}
                                                _focus={{ outline: 'none' }}
                                            >
                                                {formProps.submitText}
                                            </Button>
                                        </VStack>
                                    </GridItem>
                                </Grid>
                            </GridItem>
                            <GridItem></GridItem>
                        </Grid>
                    </VStack>
                </Content>
            </Container>
        );
    };
}

import { observer } from 'mobx-react';
import React from 'react';
import { ContentController, ContentStateProvider } from './contentInterface';
import { AnalysisContentControllerProps, AnalysisContentViewProps } from './contentProps';

export function createContentContainer(
    states: ContentStateProvider,
    controller: ContentController,
    Component: React.FC<AnalysisContentViewProps>
): React.FC<AnalysisContentControllerProps> {
    return observer((props) => {
        const viewState = states.useState(props.context);
        const viewProps = controller.useProps(viewState, props);
        return <Component {...viewProps} />;
    });
}

import React from 'react';
import { Tooltip, Box, Button, Heading, HStack, VStack, Spacer, Text, useMediaQuery, Image } from '@chakra-ui/react';
import playSVG from '../../../svg/play.svg';

interface IntegrationTitleModel {
    onContinue: () => void;
    isDisabled: boolean | undefined;
    title?: string;
    subTitle?: string;
}

interface continueButtonModel {
    onContinue: () => void;
    isDisabled: boolean | undefined;
}

const ContinueButton: React.FC<continueButtonModel> = ({ onContinue, isDisabled }) => {
    const [isLargerThan960] = useMediaQuery('(min-width: 960px)');

    return (
        <Button
            id="integ_continue_btn"
            w={isLargerThan960 ? '' : '100%'}
            textColor="button.white"
            rightIcon={<Image pt={0.5} src={playSVG} />}
            boxShadow="lg"
            bg="button.green"
            px={10}
            onClick={onContinue}
            _hover={{ bg: 'button.hoverGreen' }}
            isDisabled={isDisabled}
        >
            Continue
        </Button>
    );
};

export const IntegrationTitle: React.FunctionComponent<IntegrationTitleModel> = ({
    onContinue,
    isDisabled,
    title = 'Connect data sources',
    subTitle = 'Connect all your data sources to get both platform specific and blended benchmarks. It takes just a few seconds for each.',
}) => {
    const [isLargerThan960] = useMediaQuery('(min-width: 960px)');

    const button = (
        <Tooltip
            label="Connect one of the mandatory integrations in the upper section to complete account setup"
            isDisabled={!isDisabled}
            openDelay={0}
        >
            <span>
                <ContinueButton onContinue={onContinue} isDisabled={isDisabled} />
            </span>
        </Tooltip>
    );

    return (
        <HStack w="full">
            {isLargerThan960 ? (
                <HStack w="full">
                    <Box>
                        <Heading color="onboarding.title">{title}</Heading>
                        {subTitle ? (
                            <Text pt={2} color="onboarding.subtitle" fontSize="lg" maxW="73%">
                                {subTitle}
                            </Text>
                        ) : (
                            <></>
                        )}
                    </Box>
                    <Spacer />
                    {button}
                </HStack>
            ) : (
                <VStack w="full">
                    <Box>
                        <Heading color="onboarding.title">{title}</Heading>
                        {subTitle ? (
                            <Text textAlign="center" pt={2} color="onboarding.subtitle" fontSize="lg">
                                {subTitle}
                            </Text>
                        ) : (
                            <></>
                        )}
                    </Box>
                    {button}
                </VStack>
            )}
        </HStack>
    );
};

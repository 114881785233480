import {
    Alert,
    AlertIcon,
    AlertTitle,
    FormControl,
    FormErrorMessage,
    FormHelperText,
    FormLabel,
    HStack,
    Select,
    Text,
    VStack,
} from '@chakra-ui/react';
import React from 'react';
import { SettingMappingNewViewProps } from './mappingNewProps';

export function createSettingMappingNewView(): React.FC<SettingMappingNewViewProps> {
    return (props) => {
        const {
            field: { asset, mappable },
        } = props;
        return (
            <VStack align="start" w="full" spacing={4}>
                <FormControl {...props.form.getFormControlProps('mappable')}>
                    <FormLabel>{mappable.label}</FormLabel>
                    <Select
                        {...mappable.input.getSelectProps()}
                        placeholder={mappable.placeholder}
                        _focus={{ outline: 'none' }}
                    >
                        {mappable.input.items.map((item) => {
                            const itemProps = mappable.input.getItemProps(item);
                            return (
                                <option value={itemProps.value}>{itemProps.label}</option>
                            );
                        })}
                    </Select>
                    <FormErrorMessage
                        {...props.form.getFormErrorMessageProps('mappable')}
                    />
                    {mappable.helper && (
                        <FormHelperText>{mappable.helper}</FormHelperText>
                    )}
                </FormControl>
                <FormControl {...props.form.getFormControlProps('asset')}>
                    <FormLabel>Company</FormLabel>
                    <Select
                        {...asset.input.getSelectProps()}
                        placeholder="Select company"
                        _focus={{ outline: 'none' }}
                    >
                        {asset.input.items.map((item) => {
                            const itemProps = asset.input.getItemProps(item);
                            return (
                                <option value={itemProps.value}>{itemProps.label}</option>
                            );
                        })}
                    </Select>
                    <FormErrorMessage {...props.form.getFormErrorMessageProps('asset')} />
                </FormControl>
                {props.form.error && (
                    <Alert status="error">
                        <AlertIcon />
                        <AlertTitle>{props.form.error.label}</AlertTitle>
                    </Alert>
                )}
            </VStack>
        );
    };
}

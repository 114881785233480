import React from 'react';
import { observer } from 'mobx-react';
import { CardListViewProps, CardItemViewProps } from '../../../view/dashboard';
import { CardListController, CardItemController } from './cardInterface';

export interface CardListContainerProps extends Pick<CardListViewProps, 'renderItem'> {
    as: React.FC<CardListViewProps>;
    useController: CardListController;
}

export const CardListContainer: React.FC<CardListContainerProps> = observer(
    ({ as: Component, useController, ...restProps }) => {
        const viewProps = useController();
        return (
            <Component {...viewProps} {...restProps} renderItem={restProps.renderItem} />
        );
    }
);

export interface CardItemContainerProps
    extends Pick<CardItemViewProps, 'renderChart' | 'card'> {
    as: React.FC<CardItemViewProps>;
    useController: CardItemController;
}

export const CardItemContainer: React.FC<CardItemContainerProps> = observer(
    ({ as: Component, useController, card, ...restProps }) => {
        const viewProps = useController(card);
        return <Component {...viewProps} renderChart={restProps.renderChart} />;
    }
);

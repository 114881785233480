import { capitalize } from 'lodash';
import { useMemo } from 'react';
import { SelectBlock } from '../../../../app/conversations';
import { ConversationControllerBaseConfig } from '../../base';
import { MessageItemViewProps } from '../message';
import { SuggestionItemProps } from '../suggestion';
import { ConversationThreadController } from './conversationThreadInterface';
import { ConversationThreadViewProps } from './conversationThreadProps';

export function createConversationThreadController(
    config: ConversationControllerBaseConfig
): ConversationThreadController {
    const {
        provider: { createChatController },
    } = config;

    const chatController = createChatController();

    return {
        useProps(context, item, props): ConversationThreadViewProps {
            const chat = chatController.useProps(item.chat, props.chat);

            const handleSuggestionClick = async (value: string) => {
                const response = await props.chat.onSubmit({ text: value });
            };

            const suggestions = useMemo(
                () =>
                    item.mostRecentMessage?.blocks
                        .find(
                            (block): block is SelectBlock =>
                                block.kind === 'input' && block.type === 'select'
                        )
                        ?.options.map(
                            (option): SuggestionItemProps => ({
                                id: option.value,
                                label: option.label,
                                button: {
                                    onClick: handleSuggestionClick.bind(
                                        null,
                                        option.label
                                    ),
                                },
                            })
                        ) ?? [],
                [item.mostRecentMessage]
            );

            return {
                isLoading: props.status === 'processing',
                chat,
                suggestion: {
                    items: suggestions,
                },
                detail: {
                    agent: {
                        label: item.agent.name,
                        description: item.agent.description ?? 'no description',
                    },
                },
                items: item.entries.map((item): MessageItemViewProps => {
                    const { message, result: event } = item;
                    return {
                        id: message.id,
                        author: {
                            kind: message.role,
                            label: capitalize(message.role),
                        },
                        content: event
                            ? // when the response was an event we assume that in was in response to
                              // the most recent action
                              message.blocks.filter((block) => block.kind !== 'action')
                            : message.blocks,
                    };
                }),
            };
        },
    };
}

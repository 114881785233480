import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { useColorMode } from '@chakra-ui/react';
import { Center } from '@chakra-ui/layout';
import { useStore } from '../../../../stores/setupContext';
import { listParticipantsV2 } from '../../../../api/peers';
import { User, Workspace } from '../../../domain';
import { UserContext, UserContextValue } from '../../../app';
import { CardSpinner } from '../../../../domain';
import { AssetDto, createAssetResource } from '../../../api/platform';
import { observer } from 'mobx-react';
import { getAuthenticatedApi } from '../../../../infra/api/apiHook';
import { isErrorInfo } from '../../../../models/Errors';

export const USER_PROVIDER_QUERY_PREFIX = ['participants'];

export function createLegacyUserProvider() {
    const LegacyUserProvider: React.FC = (props) => {
        const {
            auth,
            peers: { participantsList },
        } = useStore();
        const navigate = useNavigate();

        const api = getAuthenticatedApi(auth);
        const resources = { assets: createAssetResource(api) };

        const workspaces = useQuery({
            queryKey: [...USER_PROVIDER_QUERY_PREFIX],
            suspense: true,
            staleTime: Infinity,
            cacheTime: Infinity,
            retry: 3,
            enabled: !!auth.currentUser?.id,
            async queryFn(): Promise<Workspace[]> {
                const response = await resources.assets.list(
                    {
                        auth: { scheme: { kind: 'legacy', store: auth } },
                    },
                    {}
                );
                return response.data.map((item) => ({
                    ...item,
                    visible: item.is_visible,
                }));
            },
        });

        // console.log('workspaces', workspaces);

        // const workspaces = useQuery({
        //     async queryFn() {
        //         if (!auth.currentUser?.id) {
        //             return [];
        //         }
        //         try {
        //             const [responseParticipants, responseAssets] = await Promise.all([
        //                 listParticipantsV2(api, 1, 1000),
        //                 resources.assets.list({
        //                     auth: { scheme: { kind: 'legacy', store: auth } },
        //                 }),
        //             ]);
        //             const assetById = responseAssets.data.reduce(
        //                 (acc, item) => ({ ...acc, [item.id]: item }),
        //                 {} as Record<string, AssetDto | undefined>
        //             );
        //             return responseParticipants.data.items.map<Workspace>((item) => {
        //                 return {
        //                     id: item.asset.id,
        //                     name: item.asset.title,
        //                     url: assetById[item.id]?.url ?? null,
        //                 };
        //             });

        //         }catch(e) {
        //             if (isErrorInfo(e) && e.errType == 'noPerm') {
        //                 return [];
        //             }
        //             throw e;
        //         }
        //     },
        //     queryKey: [...USER_PROVIDER_QUERY_PREFIX, auth.currentUser?.id],
        //     suspense: true,
        //     staleTime: Infinity,
        //     cacheTime: Infinity,
        //     retry: 3,
        // });

        // const assetQuery = useQuery({
        //     queryKey: keys.assets,
        //     refetchOnWindowFocus: true,
        //     refetchInterval: 60000,
        //     staleTime: 15000,
        //     retry: false,
        //     async queryFn() {
        //         const response = await listAssets(context);
        //         return response.data;
        //     },
        //     suspense: true,
        // });

        useEffect(() => {
            // resolve auth context and redirect to login screen
            if (auth.requiredState?.state === 'login') {
                navigate('/login', { replace: true });
            }
        }, [auth.currentUser?.id, auth.requiredState?.state]);

        const { colorMode, toggleColorMode } = useColorMode();
        React.useEffect(() => {
            if (colorMode === 'light') {
                toggleColorMode();
            }
            // this is a hack to fix this issue
            // https://github.com/chakra-ui/chakra-ui/issues/5925
            if (localStorage.getItem('chakra-ui-color-mode') === 'light') {
                localStorage.setItem('chakra-ui-color-mode', 'dark');
            }
        }, []);

        const workspaceList = workspaces.data ?? [];

        const visibleAssets = useMemo(
            () =>
                workspaceList
                    .filter((item) => item.visible)
                    .map((item) => ({ id: item.id as number })),
            [workspaceList]
        );

        // console.log(JSON.stringify(auth.currentUser, null, 2));

        if (!auth.currentUser?.id) {
            console.warn('user not found', auth.jwtPayload, auth.refreshToken != null);
            return <CardSpinner />;
        }

        // const workspaces: Workspace[] =
        //     participants.data?.map((item) => ({
        //         id: item.asset.id,
        //         name: item.asset.title,
        //     })) ?? [];

        const user: User = {
            id: auth.currentUser.id,
            firstname: auth.currentUser.first_name ?? null,
            lastname: auth.currentUser.last_name ?? null,
            isAdmin: auth.currentRole == 'admin',
            email: auth.currentUser.email,
            // defaultWorkspace:
            //     workspaceList.length > 0 ? (workspaceList[0].id as string) : null,
            isVaros:
                process.env.VAROS_ENV != 'production' ||
                auth.currentUser.email.endsWith('@varos.com') ||
                auth.currentUser.email.endsWith('@varos.io'),
            workspaces: workspaceList,
        };

        const context: UserContextValue = {
            user,
            assets: visibleAssets,
        };

        return (
            <UserContext.Provider value={context}>{props.children}</UserContext.Provider>
        );
    };
    return observer(LegacyUserProvider);
}

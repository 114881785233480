import { Button, ButtonGroup, HStack, Icon, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import { AiOutlineEdit } from 'react-icons/ai';
import { ConversationPluginConfig } from '../../../../route';
import { ConversationSurveyViewProps } from './blockSurveyProps';

export function createConversationSurveyView(
    config: ConversationPluginConfig
): React.FC<ConversationSurveyViewProps> {
    const {
        Layout: { Container, Content },
    } = config;

    const answers = [
        {
            question: `Dolor ipsum do veniam nisi consectetur velit?`,
            answer: 'Marketing',
        },
        {
            question: `Duis amet quis cupidatat sint aute adipisicing deserunt elit in ad excepteur?`,
            answer: 'Yes',
        },
        {
            question: `Magna in mollit non enim quis do?`,
            answer: '$100k - $200k',
        },
    ];

    return (props) => {
        return (
            <form style={{ width: '100%' }} {...props.form.getFormElementProps()}>
                <Container>
                    <Content>
                        <VStack align="start" w="full">
                            <VStack align="start" w="full" spacing={3}>
                                {answers.map((item, index) => (
                                    <VStack
                                        key={index}
                                        align="start"
                                        w="full"
                                        spacing={1}
                                    >
                                        <Text fontWeight="medium" color="whiteAlpha.600">
                                            {item.question}
                                        </Text>
                                        <Text fontWeight="semibold">{item.answer}</Text>
                                    </VStack>
                                ))}
                            </VStack>
                        </VStack>
                    </Content>
                    <HStack w="full" justify="end">
                        <ButtonGroup spacing={6}>
                            <Button variant="link" leftIcon={<Icon as={AiOutlineEdit} />}>
                                Edit manually
                            </Button>
                            <Button
                                {...props.form.getSubmitButtonProps()}
                                colorScheme="green"
                            >
                                Submit
                            </Button>
                        </ButtonGroup>
                    </HStack>
                </Container>
            </form>
        );
    };
}

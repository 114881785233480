export class SdkError extends Error {
    constructor(message: string) {
        super(message);
        this.name = 'SdkError';
        Object.setPrototypeOf(this, SdkError.prototype);
    }
}

export class SdkApiError extends SdkError {
    constructor(message: string) {
        super(message);
        this.name = 'SdkApiError';
        Object.setPrototypeOf(this, SdkApiError.prototype);
    }
}

export class SdkClientError extends SdkError {
    constructor(message: string) {
        super(message);
        this.name = 'SdkClientError';
        Object.setPrototypeOf(this, SdkClientError.prototype);
    }
}

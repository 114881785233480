import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ConversationRunAdapter } from './conversationRunAdapter';
import {
    ConversationRunRepository,
    ConversationRunCreateMutation,
} from './conversationRunInterface';
import { MessageRunCreateProps } from './conversationRunProps';
import { ConversationRunEntity } from './conversationRunModel';
import { UserScope } from '../../platform';
import { getMessageQueryKey, MessageEntity } from '../message';

export function createConversationRunRepository(
    adapter: ConversationRunAdapter
): ConversationRunRepository {
    const PREFIX = ['conversations', 'run'];

    // Helper function to generate consistent query keys
    const getQueryKey = (context: UserScope) => [...PREFIX, context.auth.user];

    return {
        useCreate(context): ConversationRunCreateMutation {
            const client = useQueryClient();
            const queryKey = getQueryKey(context);

            interface MutationContextType {
                previousMessages: MessageEntity[] | undefined;
                optimisticMessages: MessageEntity[];
            }

            const result = useMutation<
                ConversationRunEntity, // TResult
                Error, // TError
                MessageRunCreateProps, // TVariables
                MutationContextType // TContext
            >({
                mutationFn: async (props) => {
                    const response = await adapter.create(context, props);
                    return response;
                },
                onMutate: async (props): Promise<MutationContextType> => {
                    const messageQueryKey = getMessageQueryKey(context, {
                        thread: props.thread,
                    });

                    await client.cancelQueries({ queryKey });
                    await client.cancelQueries({ queryKey: messageQueryKey });

                    const previousMessages =
                        client.getQueryData<MessageEntity[]>(messageQueryKey);

                    const now = new Date();
                    // Create the optimistic messages with unique temporary IDs
                    const optimisticMessages = [props.message].map(
                        (item, index): MessageEntity => ({
                            id: `optimistic-${Date.now()}-${index}`, // Unique temporary ID
                            role: 'user',
                            blocks: item.blocks,
                            createdAt: now,
                        })
                    );

                    // Update the cache with the optimistic messages
                    client.setQueryData<MessageEntity[]>(
                        messageQueryKey,
                        (oldMessages = []) => [...optimisticMessages, ...oldMessages]
                    );

                    return { previousMessages, optimisticMessages };
                },
                onError: (error, variables, querycontext) => {
                    const messageQueryKey = getMessageQueryKey(context, {
                        thread: variables.thread,
                    });
                    if (querycontext?.previousMessages) {
                        client.setQueryData(
                            messageQueryKey,
                            querycontext.previousMessages
                        );
                    }
                },
                onSuccess: (data, variables, querycontext) => {
                    const messageQueryKey = getMessageQueryKey(context, {
                        thread: variables.thread,
                    });
                    // Assume 'data' includes 'messages' array
                    const serverMessages = data.messages; // Adjust according to your data structure
                    const optimisticIds = new Set(
                        querycontext?.optimisticMessages.map((msg) => msg.id)
                    );

                    client.setQueryData<MessageEntity[]>(
                        messageQueryKey,
                        (messages = []) => {
                            // Remove optimistic messages
                            const messagesWithoutOptimistic = messages.filter(
                                (message) => !optimisticIds.has(message.id)
                            );
                            // Add server messages
                            return [...serverMessages, ...messagesWithoutOptimistic];
                        }
                    );
                },
                onSettled: (data, error, variables) => {
                    const messageQueryKey = getMessageQueryKey(context, {
                        thread: variables.thread,
                    });
                    client.invalidateQueries({ queryKey });
                    client.invalidateQueries({ queryKey: messageQueryKey });
                },
            });
            return result;
        },
    };
}

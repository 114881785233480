import React from 'react';
import { Heading, Box } from '@chakra-ui/layout';
import { Spinner } from '@chakra-ui/spinner';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import { useStore } from '../../../stores/setupContext';
import { makeNewIntegrateHandler, makeReconnectHandler } from '../../../hooks/integrations/funcs';
import { observer } from 'mobx-react';
import ErrorView from '../../../components/ErrorView/ErrorView';
import { IntegrationItemAssetView } from '../../../views/integration';
import { useAuthenticatedContext } from '../../../hooks';
import { useIntegrationDefinitions } from '../../../views';
import { useHome } from '../main/context';

function SingleIntegPage({}): React.ReactElement {
    const context = useAuthenticatedContext();
    const definitions = useIntegrationDefinitions(context);
    const rootStore = useStore();
    const home = useHome();
    const { integrationDefinitionId } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();

    const {
        isLoading,
        err,
        redirectToConnectLink,
        redirectToReconnecLink,
        isLoadingIntegrateLink,
        isLoadingReconnectLink,
        isLoadingId,
    } = rootStore.integrations;

    const definition = React.useMemo(() => {
        if (integrationDefinitionId && definitions) {
            const defId = parseInt(integrationDefinitionId);
            const defObj = definitions.data?.find((x) => x.id == defId);
            return defObj;
        }
    }, [integrationDefinitionId, definitions]);

    const integState = searchParams.get('state');
    const title = React.useMemo(() => {
        if (definition) {
            let state = 'new';
            const connectedIntegs = definition.counts?.count_connected;
            if (connectedIntegs && connectedIntegs > 0) {
                state = 'add';
            } else {
                if (definition.counts?.count && definition.counts.count > 0) {
                    state = 'disconnected';
                } else {
                }
            }

            switch (integState) {
                case 'disconnected':
                    return `Looks like we lost the integration with your ${definition.title} account. Integrate to start benchmarking with Varos.`;
                case 'add':
                    return `Integrate another ${definition.title} account to continue benchmarking with Varos`;
                case 'new':
                default:
                    return `Integrate with ${definition.title} to start benchmarking with Varos`;
            }
        }
        return '';
    }, [definition, integState]);

    const navigate = useNavigate();

    const integHandler = makeNewIntegrateHandler(navigate, redirectToConnectLink, {
        newTab: true,
    });
    const reconnectHandler = makeReconnectHandler(navigate, redirectToReconnecLink, {
        newTab: true,
    });

    if (isLoading) {
        return <Spinner />;
    }
    if (!integrationDefinitionId || !definition) {
        return <div>Not Found</div>;
    }

    return (
        <Box w="lg">
            <Heading my={2} fontSize="lg">
                {title}
            </Heading>
            {home?.value?.pgp?.asset.id && (
                <Box height={1 / 3}>
                    <IntegrationItemAssetView
                        asset={{ id: home?.value?.pgp?.asset.id }}
                        definition={{ ...definition, isIgnored: false, canIgnore: false }}
                        isLoadingConnection={isLoadingIntegrateLink}
                        isLoadingReconnection={isLoadingReconnectLink}
                        onConnect={integHandler}
                        onReconnect={reconnectHandler}
                        isLoadingId={isLoadingId}
                    />
                </Box>
            )}
            {err && <ErrorView {...err} />}
            {isLoading && <Spinner />}
        </Box>
    );
}

export default observer(SingleIntegPage);

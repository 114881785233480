import React from 'react';
import { SettingAccountViewProps } from './accountProps';
import { SettingAccountConfig } from './accountConfig';
import { capitalize, chain } from 'lodash';
import { AnySettingItem, isRootItem } from '../common';
import { Icon } from '@chakra-ui/react';
import { AiOutlineWarning } from 'react-icons/ai';
import { useLocation } from 'react-router';

export function createSettingAccountView(
    config: Pick<SettingAccountConfig, 'deps' | 'config'>,
    items: AnySettingItem[]
): React.FC<SettingAccountViewProps> {
    const {
        deps: {
            UI: {
                Application: { createErrorBoundary },
            },
            Layout,
        },
    } = config;

    const ErrorBoundary = createErrorBoundary({
        errorClass: Error,
    });

    const sections = chain(items)
        .filter(isRootItem)
        .groupBy((item) => item.scope)
        .map((items, key) => ({ key, title: capitalize(key), children: items }))
        .value();

    return (props) => {
        const location = useLocation();
        return (
            <Layout.Wrapper>
                <Layout.Container>
                    <Layout.Navigation.List>
                        {sections.map((item) => (
                            <Layout.Navigation.Section key={item.key} label={item.title}>
                                {item.children.map((item) => (
                                    <Layout.Navigation.Item
                                        key={item.key}
                                        to={item.path}
                                        visible={props.isVisible(item)}
                                    >
                                        {item.title}
                                    </Layout.Navigation.Item>
                                ))}
                            </Layout.Navigation.Section>
                        ))}
                    </Layout.Navigation.List>
                    <Layout.Body>
                        <ErrorBoundary
                            key={location.pathname}
                            fallback={(info) => (
                                <Layout.EmptyState
                                    icon={<Icon as={AiOutlineWarning} />}
                                    header="An unexpected error occured"
                                />
                            )}
                        >
                            {props.children}
                        </ErrorBoundary>
                    </Layout.Body>
                </Layout.Container>
            </Layout.Wrapper>
        );
    };
}

import { SettingAssetMappingController } from '../../../../strategy/settings';
import { ApplicationEntryEnhancer } from '../../../../entrypoint';
import { OnboardingMappingController } from '../../../onboarding';
import { AnyOnboardingTrackingEvent } from '../trackingOnboardingEvent';

export function createTrackingOnboardingMappingStrategy(): ApplicationEntryEnhancer {
    return (create) => (config) => {
        function useTracker() {
            const tracker = instance.infra.useTracker<AnyOnboardingTrackingEvent>();
            return tracker;
        }

        function enhanceOnboardingController(
            controller: OnboardingMappingController
        ): OnboardingMappingController {
            return {
                ...controller,
                useProps(...args) {
                    const tracker = useTracker();
                    const viewProps = controller.useProps(...args);
                    return {
                        ...viewProps,
                        getFormProps() {
                            const base = viewProps.getFormProps();
                            return {
                                ...base,
                                async onSubmit(...args) {
                                    const [values] = args;
                                    const submitted = await base.onSubmit(...args);
                                    tracker.track('onboarding_mapping_submitted', {
                                        // mapping_count: Object.keys(
                                        //     values.entityByDefinition
                                        // ).length,
                                    });
                                    return submitted;
                                },
                            };
                        },
                    };
                },
            };
        }

        const instance = create({
            ...config,
            controller: {
                ...config.controller,
                onboarding: {
                    ...config.controller.onboarding,
                    createMapping(...args) {
                        const controller = config.controller.onboarding.createMapping(
                            ...args
                        );
                        return enhanceOnboardingController(controller);
                    },
                },
            },
        });

        return instance;
    };
}

import {
    Box,
    BoxProps,
    HStack,
    Table,
    Tbody,
    Td,
    Text,
    Tooltip,
    Tr,
    VStack,
} from '@chakra-ui/react';
import React, { useMemo, useState } from 'react';
import { calculateBarWidths, calculateRelativeValues } from './chakraBarChartHelper';
import { BarChartViewProps } from './chakraBarChartProps';

export function createBarChartV2View(): React.FC<BarChartViewProps> {
    const OptionItem: React.FC<{
        label: string;
        relative: string;
        absolute: string;
        value: string;
        width: BoxProps['width'];
        layout: BarChartViewProps['layout'];
    }> = (props) => {
        return (
            <Tr role="group">
                <Td py={1} px={0} border="none" w="30%">
                    <HStack
                        h={props.layout.item.h ?? 8}
                        w="full"
                        justify="end"
                        textAlign="right"
                        px={3}
                        _groupHover={{ bg: 'whiteAlpha.200' }}
                    >
                        <Text
                            fontWeight="medium"
                            color={props.layout.item.color}
                            whiteSpace="nowrap"
                        >
                            {props.label}
                        </Text>
                    </HStack>
                </Td>
                <Td py={1} px={0} border="none" w="70%">
                    <HStack w="full" spacing={3} _groupHover={{ bg: 'whiteAlpha.200' }}>
                        <Tooltip
                            placement="top"
                            hasArrow={true}
                            p={0}
                            minW="12rem"
                            maxW="20rem"
                            w="full"
                            label={
                                <VStack align="start" p={3} spacing={2}>
                                    <Text fontWeight="medium">
                                        {props.layout.scale.label}
                                    </Text>
                                    <VStack align="start" spacing={1}>
                                        <Text color="whiteAlpha.800">{props.label}</Text>
                                        <HStack>
                                            <Box bg="blackAlpha.600" px={1}>
                                                <Text>
                                                    {props.absolute} ({props.relative})
                                                </Text>
                                            </Box>
                                            <Text color="whiteAlpha.600">
                                                response(s)
                                            </Text>
                                        </HStack>
                                    </VStack>
                                </VStack>
                            }
                            bg="#1D1C21"
                        >
                            <Box
                                width={props.width}
                                minW="fit-content"
                                h={props.layout.item.h ?? 8}
                                p={1}
                                bg={props.layout.item.borderColor ?? 'whiteAlpha.300'}
                                borderRadius={3}
                            >
                                <Box
                                    h="full"
                                    w="full"
                                    bg={props.layout.item.bg}
                                    borderWidth={2}
                                    borderColor="whiteAlpha.100"
                                    borderStyle="solid"
                                    borderRadius={2}
                                />
                            </Box>
                        </Tooltip>
                        <HStack minW="10%">
                            <Text fontWeight="bold" color={props.layout.item.color}>
                                {props.value}
                            </Text>
                        </HStack>
                    </HStack>
                </Td>
            </Tr>
        );
    };

    return (props) => {
        const {
            scale: { startAtZero = false, mode = 'absolute' },
        } = props.layout;
        const widths = useMemo(
            () => calculateBarWidths(props.items, 100, startAtZero),
            [props.items, startAtZero]
        );
        const relativeLabels = useMemo(
            () => calculateRelativeValues(props.items),
            [props.items]
        );
        return (
            <Table w="full">
                <Tbody>
                    {props.items.map((item, index) => {
                        const itemProps = props.getItemProps(item);
                        const width = `${widths[index]}%`;
                        const relative = `${relativeLabels[index]}%`;
                        const value = mode === 'relative' ? relative : itemProps.value;
                        return (
                            <OptionItem
                                {...itemProps}
                                value={value}
                                relative={relative}
                                absolute={itemProps.value}
                                key={item.label}
                                width={width}
                                layout={props.layout}
                            />
                        );
                    })}
                </Tbody>
            </Table>
        );
    };
}

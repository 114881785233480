import { z } from 'zod';

export const ImportDtoSchema = z.object({
    id: z.string(),
    asset_id: z.number(),
    dataset_id: z.number(),
    dataset: z.object({
        id: z.number(),
        slug: z.string()
    }),
    user_id: z.number(),
    status: z.union([
        z.literal('pending_data'),
        z.literal('processing'),
        z.literal('uploaded'),
        z.literal('error'),
    ]),
    error: z.unknown(),
    created_at: z.date({ coerce: true }),
    updated_at: z.date({ coerce: true }),
});

export const ImportListDtoSchema = z.object({
    data: z.array(ImportDtoSchema),
});

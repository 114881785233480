import moment from 'moment';
import { ReportDto } from 'src/v2/api';
import { ReportAdapter, ReportEntity } from '../../../app';
import { ReportImplConfig } from './reportConfig';

export function createReportImpl(config: ReportImplConfig): ReportAdapter {
    const {
        api: { report: api },
    } = config;

    function toEntity(item: ReportDto): ReportEntity {
        return {
            id: item.id,
            kind: item.kind,
            name: item.name,
            asset: {
                id: item.asset,
            },
            creator: {
                id: item.creator,
            },
            period: {
                from: item.period_start_at,
                to: item.period_end_at,
            },
            comparison: {
                interval: 'period',
                amount: 1,
            },
            createdAt: item.created_at,
        };
    }

    return {
        async find(context, query) {
            const response = await api.reports.list(context, {
                limit: query.limit,
                asset: query.asset?.id ?? undefined,
            });
            return response.data.map(toEntity);
        },
        async findOne(context, lookup) {
            const response = await api.reports.get(context, lookup.id);
            return toEntity(response);
        },
        async create(context, props) {
            const response = await api.reports.create(context, {
                kind: 'audit',
                asset: props.asset,
                period_start_at: moment(props.period.start).format('YYYY-MM-DD'),
                period_end_at: moment(props.period.end).format('YYYY-MM-DD'),
            });
            return toEntity(response);
        },
    };
}

import { MessageObject } from '@varos/schema';
import { MessageEntity } from '../../../app/conversations';
import { toBlock } from '../block';

export function toMessageEntity(dto: MessageObject): MessageEntity {
    return {
        id: dto.id,
        role: dto.role,
        blocks: dto.content.map(toBlock),
        createdAt: dto.created_at,
    };
}

import {
    Box,
    Breadcrumb,
    Link as ChakraLink,
    BreadcrumbItem,
    BreadcrumbLink,
    Button,
    ButtonGroup,
    FormHelperText,
    FormControl,
    FormLabel,
    Grid,
    GridItem,
    Heading,
    HStack,
    Icon,
    Input,
    InputGroup,
    // Link,
    StackDivider,
    Tag,
    Text,
    VStack,
    Image,
    List,
    ListItem,
    Wrap,
    WrapItem,
    Alert,
    AlertDescription,
    AlertIcon,
    AlertTitle,
    Center,
    TextProps,
    TagProps,
    Checkbox,
} from '@chakra-ui/react';
import { Link, useLocation } from 'react-router-dom';
import React, { Suspense, useLayoutEffect, useMemo, useRef, useState } from 'react';
import {
    AiOutlineArrowRight,
    AiOutlineInfoCircle,
    AiOutlineLineChart,
    AiOutlineWarning,
} from 'react-icons/ai';
import { useWindowSize } from 'react-use';
import { MdOutlineInsertChartOutlined } from 'react-icons/md';
import { FaChartPie, FaSortAmountDown } from 'react-icons/fa';
import {
    HiArrowSmDown,
    HiArrowSmRight,
    HiArrowSmUp,
    HiOutlineDocumentReport,
} from 'react-icons/hi';
import { CalendarIcon } from '@chakra-ui/icons';
import { CardSpinner } from '../../../../domain';
import { ReportDetailMetricItemProps } from '../../../view/reports';
import {
    VisualizationMovementItemControllerProps,
    VisualizationRangeItemControllerProps,
    AnyVisualizationResultViewProps,
    VisualizationScalarItemControllerProps,
    isLineResultProps,
    isDistributionResultProps,
    isComparisonResultProps,
} from '../../../view/visualizations';
import { createPercentileRank } from '../../../atom/visualization';
import { PeerGroupItemControllerProps } from '../../../view/peer';
import { ApiError } from '../../../base';
import { assertNever } from '../../../util';
import {
    createReportOpportunityContainer,
    ReportDetailOpportunityViewProps,
} from './opportunity';
import { ReportDetailOpportunityVisualizationViewProps } from './visualization/visualizationProps';
import { createReportOpportunityVisualizationContainer } from './visualization';
import { ReportDetailRouteConfig } from './reportDetailConfig';
import { ReportDetailRouteViewProps } from './reportDetailProps';

export function createReportDetailRouteView(
    config: ReportDetailRouteConfig
): React.FC<ReportDetailRouteViewProps> {
    const {
        controller,
        UI: {
            Application: {
                Table,
                Thead,
                Tbody,
                Tr,
                Td,
                Th,
                Tooltip,
                createErrorBoundary,
            },
            Visualization: { LineChart, BarChart },
        },
        // Component: { VisualizationResult },
    } = config;

    const ErrorBoundary = createErrorBoundary({
        errorClass: ApiError,
    });

    const OpportunityItemContainer = createReportOpportunityContainer(config);
    const VisualizationItemContainer =
        createReportOpportunityVisualizationContainer(config);

    const ReportIcon = HiOutlineDocumentReport;
    const UpIcon = HiArrowSmUp;
    const DownIcon = HiArrowSmDown;
    const RightIcon = HiArrowSmRight;
    const PeerGroupIcon = FaChartPie;
    const RankingIcon = FaSortAmountDown;
    const PercentileRank = createPercentileRank({});

    function getTagProps(colorScheme: TextProps['colorScheme']): Partial<TagProps> {
        if (!colorScheme) {
            return {
                bg: '#292929',
            };
        }
        return { variant: 'solid', colorScheme };
    }

    function getTextColor(
        colorScheme: TextProps['colorScheme']
    ): TextProps['colorScheme'] {
        if (!colorScheme) {
            return 'whiteAlpha.900';
        }
        return `${colorScheme}.500`;
    }

    function getBackgroundColor(
        colorScheme: TextProps['colorScheme']
    ): TextProps['colorScheme'] {
        if (!colorScheme) {
            return 'gray.500';
        }
        return `${colorScheme}.500`;
    }

    const ErrorState: React.FC = (props) => {
        return (
            <Center w="full" h="full" p={6}>
                <VStack spacing={2} fontWeight="medium">
                    <Icon w={12} h={12} color="red.500" as={AiOutlineWarning} />
                    <VStack color="whiteAlpha.700" spacing={0}>
                        <Text fontWeight="medium">Failed to load chart data</Text>
                        <Text fontSize="sm">
                            If this issue continues, please
                            <Box as="span" color="blue.400">
                                {' '}
                                contact support
                            </Box>
                        </Text>
                    </VStack>
                </VStack>
            </Center>
        );
    };

    const MetricCell: React.FC<ReportDetailMetricItemProps> = (props) => {
        return (
            <Box w="min-content">
                <Tooltip
                    placement="top"
                    hasArrow={true}
                    p={4}
                    minW="20rem"
                    bg="#1D1C21"
                    label={
                        <VStack align="start">
                            <HStack spacing={3}>
                                <Box
                                    flexShrink={0}
                                    p={2}
                                    bg="whiteAlpha.300"
                                    borderRadius="md"
                                >
                                    <Image w={5} h={5} src={props.plugin.iconUrl} />
                                </Box>
                                <Text whiteSpace="nowrap" fontWeight="medium">
                                    {props.title}
                                </Text>
                            </HStack>
                            {props.description && (
                                <Text color="whiteAlpha.700">{props.description}</Text>
                            )}
                        </VStack>
                    }
                >
                    <HStack spacing={3} cursor="default">
                        <Box flexShrink={0} p={2} bg="whiteAlpha.300" borderRadius="md">
                            <Image w={5} h={5} src={props.plugin.iconUrl} />
                        </Box>
                        <Text whiteSpace="nowrap">{props.title}</Text>
                    </HStack>
                </Tooltip>
            </Box>
        );
    };

    const ScalarCell: React.FC<VisualizationScalarItemControllerProps> = (props) => {
        const viewProps = controller.visualization.scalar.useProps(props);
        return (
            <Tooltip
                placement="top"
                hasArrow={true}
                p={4}
                minW="20rem"
                bg="#1D1C21"
                label={
                    <VStack align="start">
                        <HStack spacing={3}>
                            <Box p={2} bg="whiteAlpha.300" borderRadius="md">
                                <Image w={5} h={5} src={viewProps.plugin.iconUrl} />
                            </Box>
                            <Text>{viewProps.metric.label}</Text>
                        </HStack>
                        <Text color="whiteAlpha.500">{viewProps.date.label}</Text>
                        <Text bg="blackAlpha.900" px={1}>
                            {viewProps.value.label}
                        </Text>
                        {viewProps.value.value !== null && (
                            <Text color="whiteAlpha.700" fontStyle="italic">
                                <Box as="span">Your </Box>
                                <Box
                                    as="span"
                                    color="whiteAlpha.800"
                                    fontWeight="semibold"
                                    // fontStyle="normal"
                                >
                                    {viewProps.metric.label}
                                </Box>{' '}
                                <Box as="span">is</Box>{' '}
                                <Box
                                    as="span"
                                    px={1}
                                    py={0.25}
                                    color="whiteAlpha.800"
                                    bg="blackAlpha.700"
                                    fontWeight="semibold"
                                    fontStyle="normal"
                                >
                                    {viewProps.value.label}
                                </Box>{' '}
                                <Box as="span">for the selected period</Box>
                            </Text>
                        )}
                    </VStack>
                }
            >
                <Tag
                    {...getTagProps(viewProps.control.colorScheme)}
                    size="lg"
                    cursor="default"
                >
                    {viewProps.value.label}
                </Tag>
            </Tooltip>
        );
    };

    const RangeCell: React.FC<VisualizationRangeItemControllerProps> = (props) => {
        const viewProps = controller.visualization.range.useProps(props);
        return (
            <Tooltip
                placement="top"
                hasArrow={true}
                p={4}
                minW="20rem"
                bg="#1D1C21"
                label={
                    <VStack align="start">
                        <HStack spacing={3}>
                            <Box p={2} bg="whiteAlpha.300" borderRadius="md">
                                <Image w={5} h={5} src={viewProps.plugin.iconUrl} />
                            </Box>
                            <Text>{viewProps.metric.label}</Text>
                        </HStack>
                        <Text color="whiteAlpha.500">{viewProps.date.label}</Text>
                        <List listStyleType="none" spacing={1} w="full">
                            <ListItem
                                borderBottom={2}
                                borderStyle="solid"
                                borderColor="whiteAlpha.200"
                                pb={2}
                            >
                                <HStack>
                                    <Box
                                        boxSize={2}
                                        bg={getBackgroundColor(
                                            viewProps.value.colorScheme
                                        )}
                                    />
                                    <Text>My company</Text>
                                    <Text bg="blackAlpha.900" px={1}>
                                        {viewProps.value.label}
                                    </Text>
                                </HStack>
                            </ListItem>
                            <ListItem pt={1}>
                                <HStack>
                                    <Box
                                        boxSize={2}
                                        bg={`${viewProps.range.high.colorScheme}.500`}
                                    />
                                    <Text>High performers</Text>
                                    <Text bg="blackAlpha.900" px={1}>
                                        {viewProps.range.high.label}
                                    </Text>
                                </HStack>
                            </ListItem>
                            <ListItem>
                                <HStack>
                                    <Box
                                        boxSize={2}
                                        bg={`${viewProps.range.mid.colorScheme}.500`}
                                    />
                                    <Text>Median</Text>
                                    <Text bg="blackAlpha.900" px={1}>
                                        {viewProps.range.mid.label}
                                    </Text>
                                </HStack>
                            </ListItem>
                            <ListItem>
                                <HStack>
                                    <Box
                                        boxSize={2}
                                        bg={`${viewProps.range.low.colorScheme}.500`}
                                    />
                                    <Text>Low performers</Text>
                                    <Text bg="blackAlpha.900" px={1}>
                                        {viewProps.range.low.label}
                                    </Text>
                                </HStack>
                            </ListItem>
                        </List>
                        {viewProps.ranking && (
                            <HStack spacing={2}>
                                <Text color="whiteAlpha.700">Ranked by</Text>
                                <HStack spacing={1}>
                                    <Icon w={3} h={3} as={RankingIcon} />
                                    <Text>{viewProps.ranking.label}</Text>
                                </HStack>
                            </HStack>
                        )}
                        {viewProps.value.value !== null && (
                            <Text color="whiteAlpha.700" fontStyle="italic">
                                <Box as="span">Your </Box>
                                <Box
                                    as="span"
                                    color="whiteAlpha.800"
                                    fontWeight="semibold"
                                >
                                    {viewProps.metric.label}
                                </Box>{' '}
                                <Box as="span">at</Box>{' '}
                                <Box
                                    as="span"
                                    px={1}
                                    py={0.25}
                                    color="whiteAlpha.800"
                                    bg="blackAlpha.700"
                                    fontWeight="semibold"
                                    fontStyle="normal"
                                >
                                    {viewProps.value.label}
                                </Box>{' '}
                                <Box as="span">was among the</Box>{' '}
                                {viewProps.bucket && (
                                    <Box
                                        as="span"
                                        color="whiteAlpha.800"
                                        fontWeight="semibold"
                                        whiteSpace="nowrap"
                                    >
                                        {viewProps.bucket.label}
                                    </Box>
                                )}{' '}
                                {viewProps.ranking ? (
                                    <>
                                        <Box as="span">in terms of</Box>{' '}
                                        <Box
                                            as="span"
                                            color="whiteAlpha.800"
                                            fontWeight="semibold"
                                        >
                                            {viewProps.ranking.label}
                                        </Box>{' '}
                                    </>
                                ) : null}
                                <Box as="span">for the selected period</Box>
                            </Text>
                        )}
                    </VStack>
                }
            >
                <Box w="full">
                    <PercentileRank
                        buckets={viewProps.buckets.map((bucket) => ({
                            ...bucket,
                            color: `${bucket.color}.500`,
                        }))}
                        inverse={viewProps.isInverse}
                        value={viewProps.value.value}
                        ticks={[
                            {
                                value: viewProps.range.low.value,
                                label: viewProps.range.low.label,
                            },
                            {
                                hidden: true,
                                value: viewProps.range.mid.value,
                                label: viewProps.range.mid.label,
                            },
                            {
                                value: viewProps.range.high.value,
                                label: viewProps.range.high.label,
                            },
                        ]}
                    />
                </Box>
            </Tooltip>
        );
    };

    const MovementCell: React.FC<VisualizationMovementItemControllerProps> = (props) => {
        const viewProps = controller.visualization.movement.useProps(props);
        const verb = viewProps.direction === 'up' ? 'increased' : 'decreased';
        const descriptionPrefix =
            props.segment.definition.kind === 'asset' ? 'Your' : `Peer group`;
        let changeSign = '';
        if (viewProps.direction === 'up') {
            changeSign = '+';
        }
        if (viewProps.direction === 'down') {
            changeSign = '-';
        }
        return (
            <Tooltip
                placement="top"
                hasArrow={true}
                p={4}
                minW="20rem"
                bg="#1D1C21"
                label={
                    <VStack align="start">
                        <HStack spacing={3}>
                            <Box p={2} bg="whiteAlpha.300" borderRadius="md">
                                <Image w={5} h={5} src={viewProps.plugin.iconUrl} />
                            </Box>
                            <Text>{viewProps.metric.label}</Text>
                        </HStack>
                        <HStack>
                            <Text color="whiteAlpha.500">
                                {viewProps.comparison.label}
                            </Text>
                            <Icon color="whiteAlpha.500" as={RightIcon} />
                            <Text color="whiteAlpha.500">{viewProps.date.label}</Text>
                        </HStack>
                        <HStack
                            bg="blackAlpha.900"
                            color={getTextColor(viewProps.control.colorScheme)}
                            px={1}
                            spacing={1}
                        >
                            {viewProps.direction === 'up' && (
                                <Icon fontSize="lg" as={UpIcon} />
                            )}
                            {viewProps.direction === 'down' && (
                                <Icon fontSize="lg" as={DownIcon} />
                            )}
                            <Text>{viewProps.value.change.label}</Text>
                        </HStack>
                        {viewProps.value.change.value !== null && (
                            <Text color="whiteAlpha.700" fontStyle="italic">
                                <Box as="span">{descriptionPrefix}</Box>{' '}
                                <HStack display="inline-block" align="center" spacing={2}>
                                    <Box
                                        as="span"
                                        color="whiteAlpha.800"
                                        fontWeight="semibold"
                                        // fontStyle="normal"
                                    >
                                        {viewProps.metric.label}
                                    </Box>
                                </HStack>{' '}
                                <Box as="span">{verb} by</Box>{' '}
                                <Box
                                    px={1}
                                    py={0.25}
                                    as="span"
                                    color="whiteAlpha.800"
                                    // fontWeight="semibold"
                                    bg="blackAlpha.700"
                                    fontWeight="semibold"
                                    fontStyle="normal"
                                >
                                    {changeSign}
                                    {viewProps.value.change.label}
                                </Box>{' '}
                                <Box as="span">
                                    compared to the previous period, going from
                                </Box>{' '}
                                <Box
                                    px={1}
                                    py={0.25}
                                    as="span"
                                    color="whiteAlpha.800"
                                    // fontWeight="semibold"
                                    bg="blackAlpha.700"
                                    fontWeight="semibold"
                                    fontStyle="normal"
                                >
                                    {viewProps.value.previous.label}
                                </Box>{' '}
                                <Box as="span">to</Box>{' '}
                                <Box
                                    px={1}
                                    py={0.25}
                                    as="span"
                                    color="whiteAlpha.800"
                                    // fontWeight="semibold"
                                    bg="blackAlpha.700"
                                    fontWeight="semibold"
                                    fontStyle="normal"
                                >
                                    {viewProps.value.current.label}
                                </Box>
                            </Text>
                        )}
                    </VStack>
                }
            >
                <Tag bg="#292929" size="lg" fontWeight="medium">
                    <HStack
                        color={getTextColor(viewProps.control.colorScheme)}
                        spacing={1}
                    >
                        {viewProps.direction === 'up' && (
                            <Icon fontSize="lg" as={UpIcon} />
                        )}
                        {viewProps.direction === 'down' && (
                            <Icon fontSize="lg" as={DownIcon} />
                        )}
                        <Text>{viewProps.value.change.label}</Text>
                    </HStack>
                </Tag>
            </Tooltip>
        );
    };

    const PeerCell: React.FC<PeerGroupItemControllerProps> = (props) => {
        const location = useLocation();
        const viewProps = controller.peer.item.useProps({ location }, props);
        return (
            <Link to={viewProps.path}>
                <Tooltip
                    placement="top"
                    hasArrow={true}
                    p={4}
                    minW="20rem"
                    bg="#1D1C21"
                    label={
                        <VStack align="start">
                            <HStack w="full" justify="space-between">
                                <HStack spacing={3}>
                                    <Box p={2} bg="whiteAlpha.300" borderRadius="md">
                                        <Icon boxSize={5} as={PeerGroupIcon} />
                                    </Box>
                                    <Text>{viewProps.name}</Text>
                                </HStack>
                                <Tag size="sm" colorScheme="blue" borderRadius="none">
                                    Default
                                </Tag>
                            </HStack>
                            <Wrap w="full" spacingY={0}>
                                {viewProps.conditions.length === 0 && (
                                    <Text color="whiteAlpha.500" fontWeight="medium">
                                        No filters spefified
                                    </Text>
                                )}
                                {viewProps.conditions.map((condition, index) => (
                                    <WrapItem key={index}>
                                        <Text>
                                            <Box
                                                as="span"
                                                color="whiteAlpha.700"
                                                fontWeight="semibold"
                                            >
                                                {condition.key}
                                            </Box>{' '}
                                            <Box
                                                as="span"
                                                color="whiteAlpha.700"
                                                fontStyle="italic"
                                                fontWeight="medium"
                                            >
                                                {condition.operator}
                                            </Box>{' '}
                                            <Box
                                                as="span"
                                                color="whiteAlpha.800"
                                                fontWeight="semibold"
                                            >
                                                {condition.value}
                                            </Box>
                                        </Text>
                                    </WrapItem>
                                ))}
                            </Wrap>
                        </VStack>
                    }
                >
                    <Button
                        bg="#292929"
                        size="sm"
                        rightIcon={<Icon as={AiOutlineInfoCircle} />}
                    >
                        Peers
                    </Button>
                </Tooltip>
            </Link>
        );
    };

    const OpportunityItem: React.FC<ReportDetailOpportunityViewProps> = (props) => {
        const { itemProps } = props;
        return (
            <VStack align="start" fontWeight="medium" h="full" spacing={6}>
                <VStack align="start">
                    <HStack spacing={3}>
                        <Box p={2} bg="whiteAlpha.300" borderRadius="md" flexShrink={0}>
                            <Image w={6} h={6} src={itemProps.iconUrl}></Image>
                        </Box>
                        <Text>{itemProps.title}</Text>
                    </HStack>
                    {itemProps.description && (
                        <Box flex={1}>
                            <Text color="whiteAlpha.700">{itemProps.description}</Text>
                        </Box>
                    )}
                    {props.showDate && (
                        <HStack>
                            <Tooltip
                                placement="top"
                                hasArrow={true}
                                p={3}
                                label={<Box>{itemProps.date.description}</Box>}
                                bg="#1D1C21"
                            >
                                <Text cursor="default" color="whiteAlpha.500">
                                    {itemProps.date.relative.label}
                                </Text>
                            </Tooltip>
                        </HStack>
                    )}
                </VStack>
                {props.children && (
                    <VStack flex={1} justify="end" w="full">
                        <Box
                            w="full"
                            pb={3}
                            pt={6}
                            // px={6}
                            borderTopWidth={2}
                            borderColor="whiteAlpha.300"
                            borderStyle="solid"
                            h={{ base: '33vh', xl: '25vh' }}
                            minH="18rem"
                        >
                            {props.children}
                        </Box>
                    </VStack>
                )}
            </VStack>
        );
    };

    const VisualizationChart: React.FC<AnyVisualizationResultViewProps> = (props) => {
        if (isLineResultProps(props)) {
            return <LineChart {...props.getChartProps()} />;
        }
        if (isDistributionResultProps(props)) {
            return <BarChart {...props.getChartProps()} />;
        }
        if (isComparisonResultProps(props)) {
            return <Box>Not supported</Box>;
        }
        // @ts-expect-error
        assertNever(props);
    };

    const VisualizationItem: React.FC<ReportDetailOpportunityVisualizationViewProps> = (
        props
    ) => {
        const dateProps = props.resultProps.getDateProps();
        const legendProps = props.resultProps.getLegendProps();
        return (
            <VStack align="start" h="full" w="full" color="whiteAlpha.900" spacing={3}>
                <VStack w="full" align="start" spacing={1}>
                    <HStack w="full" spacing={3} align="center">
                        <Icon w={5} h={5} as={MdOutlineInsertChartOutlined} />
                        <Text fontWeight="semibold">{props.label}</Text>
                    </HStack>
                    <Text fontWeight="medium" color="whiteAlpha.500" fontSize="sm">
                        {dateProps.period.label}
                    </Text>
                </VStack>
                <VStack align="start" h="full" w="full" spacing={3} flex={1}>
                    <VStack w="full">
                        <HStack spacing={6}>
                            {legendProps.map((item) => (
                                <Tooltip
                                    placement="top"
                                    hasArrow={true}
                                    key={item.label}
                                    bg="#1D1C21"
                                    p={4}
                                    label={
                                        <VStack
                                            align="start"
                                            fontWeight="medium"
                                            spacing={1}
                                        >
                                            <HStack spacing={2} align="center">
                                                <Box
                                                    w={2}
                                                    h={2}
                                                    bg={`${item.colorScheme}.300`}
                                                    borderRadius="full"
                                                />
                                                <Text>{item.label}</Text>
                                            </HStack>
                                            <Text
                                                fontStyle="italic"
                                                color="whiteAlpha.700"
                                            >
                                                {item.description}
                                            </Text>
                                        </VStack>
                                    }
                                    isDisabled={item.description === null}
                                >
                                    <HStack>
                                        <Checkbox
                                            size="sm"
                                            isChecked={true}
                                            colorScheme={`legend.${item.colorScheme}`}
                                        />
                                        <Text userSelect="none" fontSize="sm">
                                            {item.label}
                                        </Text>
                                    </HStack>
                                </Tooltip>
                            ))}
                        </HStack>
                    </VStack>
                    <HStack align="center" h="full" w="full" flex={1}>
                        <Box position="relative" h="full" width="full">
                            <VisualizationChart {...props.resultProps} />
                        </Box>
                    </HStack>
                </VStack>
            </VStack>
        );
    };

    return (props) => {
        if (props.status !== 'success') {
            return (
                <Center py={16} h="100%" position="relative" userSelect="none">
                    <VStack
                        // position="relative" bottom={16}
                        spacing={4}
                    >
                        <CardSpinner />
                        <Text
                            fontWeight="medium"
                            fontSize="lg"
                            color="whiteAlpha.700"
                            letterSpacing="wide"
                        >
                            Loading report data
                        </Text>
                    </VStack>
                </Center>
            );
        }
        const detailProps = props.getDetailProps();
        // const resultProps = props.getResultProps();
        const rootProps = detailProps.getRootProps();
        const opportunityListProps = detailProps.getOpportunityListProps();
        const metricListProps = detailProps.getMetricListProps();
        const statusProps = detailProps.getStatusProps();
        // console.log('DEBUG resultProps', resultProps);
        return (
            <Suspense
                fallback={
                    <Center py={16} h="100%" position="relative" userSelect="none">
                        <VStack
                            // position="relative" bottom={16}
                            spacing={4}
                        >
                            <CardSpinner />
                            <Text
                                fontWeight="medium"
                                fontSize="lg"
                                color="whiteAlpha.700"
                                letterSpacing="wide"
                            >
                                Loading report data
                            </Text>
                        </VStack>
                    </Center>
                }
            >
                <Grid templateRows="1fr" h="full">
                    <GridItem>
                        <VStack position="relative">
                            <VStack
                                align="start"
                                w="full"
                                maxW={{
                                    sm: '100vw',
                                    md: '90vw',
                                    lg: '90vw',
                                    xl: '90vw',
                                    '2xl': '80vw',
                                    '3xl': '75vw',
                                }}
                                marginX="auto"
                                spacing={4}
                                pt={4}
                                pb={48}
                                divider={
                                    <StackDivider
                                        borderColor="whiteAlpha.200"
                                        borderWidth={1}
                                    />
                                }
                            >
                                <VStack spacing={4} w="full">
                                    <HStack justify="space-between" w="full">
                                        <HStack flex={1} spacing={3} align="center">
                                            <Breadcrumb
                                                separator="/"
                                                color="whiteAlpha.500"
                                                fontWeight="semibold"
                                                fontSize="2xl"
                                                alignItems="center"
                                                spacing={3}
                                            >
                                                <BreadcrumbItem>
                                                    <BreadcrumbLink
                                                        as={Link}
                                                        to=".."
                                                        _focus={{ outline: 'none' }}
                                                    >
                                                        Audits
                                                    </BreadcrumbLink>
                                                </BreadcrumbItem>
                                                <BreadcrumbItem
                                                    isCurrentPage={true}
                                                    color="whiteAlpha.900"
                                                >
                                                    <HStack spacing={3}>
                                                        <Text>
                                                            {rootProps.title.label}
                                                        </Text>
                                                    </HStack>
                                                </BreadcrumbItem>
                                            </Breadcrumb>
                                        </HStack>
                                        <HStack
                                            spacing={3}
                                            cursor="default"
                                            color="whiteAlpha.700"
                                        >
                                            <Tooltip
                                                placement="top"
                                                hasArrow={true}
                                                p={4}
                                                label={
                                                    <Box>
                                                        {rootProps.date.description}
                                                    </Box>
                                                }
                                                bg="#1D1C21"
                                            >
                                                <Tag
                                                    px={4}
                                                    py={2}
                                                    size="md"
                                                    fontWeight="semibold"
                                                >
                                                    <HStack spacing={3}>
                                                        <Icon as={CalendarIcon}></Icon>
                                                        <Text>
                                                            {rootProps.date.label}
                                                        </Text>
                                                    </HStack>
                                                </Tag>
                                            </Tooltip>
                                            <Text
                                                color="whiteAlpha.500"
                                                fontWeight="medium"
                                            >
                                                compared to
                                            </Text>
                                            <Tooltip
                                                placement="top"
                                                hasArrow={true}
                                                p={4}
                                                label={
                                                    <Box>
                                                        {rootProps.comparison.description}
                                                    </Box>
                                                }
                                                bg="#1D1C21"
                                            >
                                                <Tag
                                                    px={4}
                                                    py={2}
                                                    size="md"
                                                    fontWeight="semibold"
                                                >
                                                    {rootProps.comparison.label}
                                                </Tag>
                                            </Tooltip>
                                        </HStack>
                                    </HStack>
                                    {statusProps && (
                                        <Alert status={statusProps.status}>
                                            <AlertIcon />
                                            <AlertTitle fontWeight="medium">
                                                {statusProps.label}
                                            </AlertTitle>
                                        </Alert>
                                    )}
                                </VStack>
                                <VStack
                                    w="full"
                                    spacing={6}
                                    divider={
                                        <StackDivider
                                            borderColor="whiteAlpha.100"
                                            borderWidth={1}
                                        />
                                    }
                                >
                                    {opportunityListProps.items.length > 0 && (
                                        <Grid
                                            pt={2}
                                            w="full"
                                            gap={6}
                                            templateColumns={{
                                                // use minmax to ensure the visualization dimensions
                                                // does not expand the grid container
                                                base: 'minmax(0, 1fr)',
                                                xl: 'minmax(0, 1fr) minmax(0, 1fr)',
                                            }}
                                        >
                                            {opportunityListProps.items.map(
                                                (item, index, array) => {
                                                    const visualizationProps =
                                                        props.getVisualizationProps(item);

                                                    const previous = array[index - 1];
                                                    const visualizationPropsPrevious =
                                                        previous
                                                            ? props.getVisualizationProps(
                                                                  previous
                                                              )
                                                            : null;

                                                    const newLine =
                                                        visualizationPropsPrevious !==
                                                            null &&
                                                        visualizationProps === null;

                                                    return (
                                                        <GridItem
                                                            key={item.insight.id}
                                                            p={6}
                                                            borderRadius="lg"
                                                            borderWidth={2}
                                                            borderStyle="solid"
                                                            borderColor="whiteAlpha.300"
                                                            minH={24}
                                                            gridColumnStart={
                                                                newLine ? 1 : undefined
                                                            }
                                                        >
                                                            <OpportunityItemContainer
                                                                as={OpportunityItem}
                                                                {...props.getOpportunityItemProps(
                                                                    item
                                                                )}
                                                            >
                                                                {visualizationProps ? (
                                                                    <ErrorBoundary
                                                                        fallback={(
                                                                            error
                                                                        ) => (
                                                                            <ErrorState />
                                                                        )}
                                                                    >
                                                                        <VisualizationItemContainer
                                                                            as={
                                                                                VisualizationItem
                                                                            }
                                                                            {...visualizationProps}
                                                                        />
                                                                    </ErrorBoundary>
                                                                ) : null}
                                                            </OpportunityItemContainer>
                                                        </GridItem>
                                                    );
                                                }
                                            )}
                                        </Grid>
                                    )}
                                    {metricListProps.items.length > 0 && (
                                        <Box w="full">
                                            <Table w="full" fontWeight="medium">
                                                <Thead>
                                                    <Tr>
                                                        <Th></Th>
                                                        <Th></Th>
                                                        <Th
                                                            textAlign="center"
                                                            colSpan={2}
                                                        >
                                                            Benchmark
                                                        </Th>
                                                        <Th
                                                            textAlign="center"
                                                            colSpan={2}
                                                        >
                                                            Trend
                                                        </Th>
                                                    </Tr>
                                                </Thead>
                                                <Tbody>
                                                    <Tr>
                                                        <Td></Td>
                                                        <Td></Td>
                                                        <Td
                                                            textAlign="center"
                                                            fontSize="sm"
                                                            fontWeight="medium"
                                                        >
                                                            Me
                                                        </Td>
                                                        <Td
                                                            textAlign="center"
                                                            fontSize="sm"
                                                            fontWeight="medium"
                                                        >
                                                            Comparison
                                                        </Td>
                                                        <Td
                                                            textAlign="center"
                                                            fontSize="sm"
                                                            fontWeight="medium"
                                                        >
                                                            Me
                                                        </Td>
                                                        <Td
                                                            textAlign="center"
                                                            fontSize="sm"
                                                            fontWeight="medium"
                                                        >
                                                            Peer group
                                                        </Td>
                                                    </Tr>
                                                    {metricListProps.items.map((item) => {
                                                        return (
                                                            <Tr key={item.definition.id}>
                                                                <Td>
                                                                    <MetricCell
                                                                        {...metricListProps.getItemProps(
                                                                            item
                                                                        )}
                                                                    />
                                                                </Td>
                                                                <Td textAlign="center">
                                                                    <PeerCell
                                                                        {...metricListProps.getPeerGroupProps(
                                                                            item
                                                                        )}
                                                                    />
                                                                </Td>
                                                                <Td textAlign="center">
                                                                    <ScalarCell
                                                                        {...metricListProps.getScalarProps(
                                                                            item
                                                                        )}
                                                                    />
                                                                </Td>
                                                                <Td textAlign="center">
                                                                    <RangeCell
                                                                        {...metricListProps.getRangeProps(
                                                                            item
                                                                        )}
                                                                    />
                                                                </Td>
                                                                <Td textAlign="center">
                                                                    <MovementCell
                                                                        {...metricListProps.getMovementProps(
                                                                            item,
                                                                            item.summary
                                                                                .current
                                                                        )}
                                                                    />
                                                                </Td>
                                                                <Td textAlign="center">
                                                                    <MovementCell
                                                                        {...metricListProps.getMovementProps(
                                                                            item,
                                                                            item.summary
                                                                                .mid
                                                                        )}
                                                                    />
                                                                </Td>
                                                            </Tr>
                                                        );
                                                    })}
                                                </Tbody>
                                            </Table>
                                        </Box>
                                    )}
                                </VStack>
                            </VStack>
                        </VStack>
                    </GridItem>
                </Grid>
            </Suspense>
        );
    };
}

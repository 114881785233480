import { z } from 'zod';
import { AnyTypeSchema } from '../type';

export const ColumnSchema = z.object({
    key: z.string(),
    display_name: z.string().optional(),
    partition: z.boolean().optional(),
    time: z.boolean().optional(),
    type: AnyTypeSchema.optional(),
});

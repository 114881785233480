import React, { useMemo } from 'react';
import { Navigate as Redirect, useLocation, useNavigate } from 'react-router-dom';
import { useToast } from '../../../container';
import { useStore } from '../../../stores/setupContext';
import { observer } from 'mobx-react';
import { ErrorInfo } from '../../../models/Errors';
import queryString from 'querystring';
import { OnBoardingStatus } from '../../../models/Account';
import { Box, Flex, Heading, VStack, Text } from '@chakra-ui/layout';
import { Spinner } from '@chakra-ui/spinner';
import { Link as RRDLink } from 'react-router-dom';
import { Button } from '@chakra-ui/button';
import { IntegrationConf } from '../../../models/integration';
import { OAUTH_REDIRECT_SESSION_STORAGE_KEY } from '../../../hooks';

const REDIRECT_DELAY_MS = 1500;
const TOAST_DELAY_MS = 300;

interface LocationState {
    state: string;
    code?: string;
    search: string;
    error_reason?: string;
    error?: string;
    error_description?: string;
}

interface AuthReturnCompProps {
    locationState?: LocationState;
    integrationDefinition: IntegrationConf | null;
    isLoading: boolean;
    err: ErrorInfo | null;
    isConfirmed: boolean;
    isLoggedIn: boolean;
    onBoardingStatus: OnBoardingStatus | null;
}

function isStringArray(opt: string | string[]): opt is string[] {
    return Array.isArray(opt);
}

const AuthReturnComp: React.FunctionComponent<AuthReturnCompProps> = ({
    locationState,
    isLoading,
    err,
    isConfirmed,
    isLoggedIn,
    onBoardingStatus,
    integrationDefinition,
}) => {
    const toast = useToast();
    const isErr = locationState?.error || err;

    const oauthRedirectHref = useMemo(
        () => window.sessionStorage.getItem(OAUTH_REDIRECT_SESSION_STORAGE_KEY) ?? null,
        []
    );
    const [nextLink, setNextLink] = React.useState('/');

    React.useEffect(() => {
        if (oauthRedirectHref) {
            console.log('navigating to saved redirect', oauthRedirectHref);
            setNextLink(oauthRedirectHref);
            return;
        }
        if (!onBoardingStatus || onBoardingStatus.state == 'complete') {
            setNextLink('/');
        } else {
            setNextLink('/on-boarding');
        }
    }, [onBoardingStatus, oauthRedirectHref]);
    const [canRedirect, setCanRedirect] = React.useState(false);

    React.useEffect(() => {
        if (isConfirmed && isLoggedIn) {
            setTimeout(() => {
                setCanRedirect(true);
            }, REDIRECT_DELAY_MS);
            setTimeout(() => {
                toast({
                    kind: 'success',
                    description: 'Your integration was succesfully connected',
                });
            }, REDIRECT_DELAY_MS + TOAST_DELAY_MS);
        }
    }, [isConfirmed, isLoggedIn]);

    return (
        <Flex height="100%" width="100%" justifyContent="center" alignItems="center">
            <Box
                borderWidth="thin"
                borderRadius="md"
                borderColor="yellow.400"
                padding={4}
            >
                <VStack>
                    {isErr ? (
                        <>
                            <Heading textAlign="center" color="red.300" fontSize="md">
                                Error integrating&nbsp;
                                {integrationDefinition?.title
                                    ? integrationDefinition.title
                                    : 'Unknown Connection Provider'}
                            </Heading>
                            <Text textAlign="center" color="red.300">
                                {locationState?.error_description ||
                                    locationState?.error ||
                                    err?.message}
                            </Text>
                        </>
                    ) : (
                        <>
                            <Heading
                                textAlign="center"
                                fontSize="md"
                                color={
                                    integrationDefinition?.title ? 'green.400' : 'red.300'
                                }
                            >
                                {integrationDefinition?.title
                                    ? integrationDefinition?.title
                                    : 'Loading...'}
                            </Heading>
                            {isLoading && (
                                <Spinner>
                                    <Text>Confirming integration...</Text>
                                </Spinner>
                            )}
                        </>
                    )}
                    {isConfirmed && (
                        <>
                            <>
                                <Heading
                                    fontSize="md"
                                    textAlign="center"
                                    color="green.400"
                                >
                                    {integrationDefinition?.title || 'Integration'}{' '}
                                    confirmed!
                                </Heading>
                                <Text textAlign="center">
                                    We are currently syncing and processing your data.
                                </Text>
                            </>
                            {isLoggedIn && (
                                <>
                                    <Spinner />
                                    {canRedirect && <Redirect to={nextLink} />}
                                </>
                            )}
                            {/* <Text textAlign="center">We will reach out soon to provide access details.</Text> */}
                        </>
                    )}

                    {isLoggedIn && isErr && (
                        <Box>
                            <Button variant="outline" as={RRDLink} to={nextLink}>
                                Continue
                            </Button>
                        </Box>
                    )}
                </VStack>
            </Box>
        </Flex>
    );
};

export interface OAuthReturnProps {}
const Observed = observer(AuthReturnComp);

function OAuthReturn({}: OAuthReturnProps) {
    const rootStore = useStore();
    const { auth, linkConfirm } = rootStore;
    const loc = useLocation();
    const [locationState, setLocationState] = React.useState<LocationState>();
    React.useEffect(() => {
        if (loc.search) {
            const srch = loc.search.substring(1);
            const qs = queryString.parse(srch);
            if (qs['state']) {
                const stateQs = qs['state'];
                const stateCode = qs['code'];
                const state = isStringArray(stateQs) ? stateQs[0] : stateQs;
                const locState: LocationState = {
                    state,
                    code: isStringArray(stateCode) ? stateCode[0] : stateCode,
                    search: srch,
                };
                setLocationState(locState);
                if (!locState.error) {
                    linkConfirm.resolveState(locState.state);
                }
            }
        }
    }, [loc.state]);
    React.useEffect(() => {
        if (locationState && linkConfirm.currentIntegration) {
            linkConfirm.confirm(
                locationState.state,
                locationState.code || '',
                locationState.search
            );
        }
    }, [locationState, linkConfirm.currentIntegration]);
    const isConfirmed = !!(
        locationState && linkConfirm.confirmedStates.has(locationState.state)
    );
    React.useEffect(() => {
        if (isConfirmed) {
            auth.reportEvent(
                'ui_integration_enabled',
                {
                    integration: linkConfirm.currentIntegration?.integration_type,
                    integration_name: linkConfirm.currentIntegration?.integration_type,
                    integration_type: linkConfirm.currentIntegration?.integration_type,
                    integration_id: linkConfirm.confirmedIntegrationId,
                    on_boarding: !auth.loggedInOrganization?.finished_on_boarding,
                },
                (data) => {
                    return data['count_integrated'] === undefined
                        ? false
                        : data['count_integrated'] == 1;
                }
            );
        }
    }, [isConfirmed]);
    return (
        <Observed
            isLoggedIn={!!auth.authToken}
            locationState={locationState}
            err={linkConfirm.err || (isConfirmed ? auth.err : null)}
            isLoading={linkConfirm.isLoading}
            onBoardingStatus={auth.requiredState}
            isConfirmed={isConfirmed}
            integrationDefinition={linkConfirm.currentIntegration}
        />
    );
}

export default observer(OAuthReturn);

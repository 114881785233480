import React, { useMemo } from 'react';
import { BeatLoader, SyncLoader } from 'react-spinners';
import {
    Box,
    BoxProps,
    Button,
    Flex,
    Grid,
    GridItem,
    HStack,
    Icon,
    IconButton,
    Image,
    Input,
    InputGroup,
    InputRightElement,
    SimpleGrid,
    Stack,
    Text,
    Textarea,
    useToken,
    VStack,
} from '@chakra-ui/react';
import { Icons } from '../../../../../config';
import { ConversationViewBaseConfig } from '../../base';
import { ConversationDetailViewProps } from './conversationDetailProps';
import { ChevronRightIcon } from '@chakra-ui/icons';

export function createConversationDetailView(
    config: ConversationViewBaseConfig
): React.FC<ConversationDetailViewProps> {
    const {
        UI: {
            Application: { Link },
        },
        strategy: { Agent },
    } = config;

    return (props) => {
        return (
            <VStack align="start" w="full" spacing={8}>
                <VStack align="start" w="full" spacing={3}>
                    <VStack align="start" spacing={3} w="full">
                        <HStack
                            fontWeight="semibold"
                            spacing={2}
                            divider={
                                <Icon
                                    boxSize="1.20rem"
                                    color="whiteAlpha.600"
                                    as={ChevronRightIcon}
                                    border="none"
                                />
                            }
                        >
                            <Link to="..">
                                <Text color="blue.300" _hover={{ color: 'blue.400' }}>
                                    Conversations
                                </Text>
                            </Link>
                        </HStack>
                        <HStack w="full" justify="space-between">
                            <HStack spacing={4} fontSize="lg">
                                <Box p={3} bg="whiteAlpha.300" borderRadius="sm">
                                    <Icon as={Icons.Accounts.User} />
                                </Box>
                                <VStack align="start" w="full">
                                    <Text fontWeight="semibold" color="whiteAlpha.800">
                                        {props.agent.label}
                                    </Text>
                                </VStack>
                            </HStack>
                        </HStack>
                    </VStack>
                    <Text color="whiteAlpha.600">{props.agent.description}</Text>
                </VStack>
                <VStack align="start" w="full" spacing={4}>
                    <HStack w="full">
                        <Text color="whiteAlpha.800" fontWeight="semibold">
                            Context
                        </Text>
                    </HStack>
                    <VStack align="start" w="full">
                        <Link style={{ width: '100%' }} to="">
                            <HStack
                                w="full"
                                justify="space-between"
                                p={{
                                    base: 4,
                                    '2xl': 6,
                                }}
                                borderWidth={2}
                                borderColor="whiteAlpha.300"
                                borderRadius="lg"
                                cursor="pointer"
                                _hover={{ bg: 'whiteAlpha.50' }}
                            >
                                <HStack spacing={4}>
                                    <Box p={3} bg="whiteAlpha.300" borderRadius="sm">
                                        <Icon as={Icons.Reports.Study} />
                                    </Box>
                                    <VStack align="start" w="full" spacing={0}>
                                        <Text fontWeight="medium" color="whiteAlpha.800">
                                            Creative Strategy
                                        </Text>
                                        <Text color="whiteAlpha.600">Survey</Text>
                                    </VStack>
                                </HStack>
                            </HStack>
                        </Link>
                    </VStack>
                </VStack>
            </VStack>
        );
    };
}

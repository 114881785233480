import { Box, StackProps, VStack } from '@chakra-ui/layout';
import React, { IframeHTMLAttributes, useState } from 'react';

export interface RemoteIframeProps
    extends IframeHTMLAttributes<HTMLIFrameElement>,
        Pick<StackProps, 'bg'> {
    defaultLoading?: boolean;
    spinner: React.ReactElement;
}

export const RemoteIframe: React.FC<RemoteIframeProps> = ({
    defaultLoading = true,
    spinner,
    bg,
    ...props
}) => {
    const [loading, setLoading] = useState(defaultLoading);

    const handleLoad: React.ReactEventHandler<HTMLIFrameElement> = () => {
        setLoading(false);
    };

    return (
        <VStack
            width={props.width}
            height={props.height}
            alignItems="center"
            justifyContent="center"
            bg={bg}
        >
            {loading ? <Box>{spinner}</Box> : null}
            <iframe
                src={props.src}
                frameBorder={props.frameBorder}
                onLoad={handleLoad}
                width={props.width}
                height={loading ? 0 : props.height}
                {...props}
            />
        </VStack>
    );
};

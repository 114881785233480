import React from 'react';
import { Chart, BarChart, useChart, BarChartV2 } from '../../../../ui';
import { BarVisualizationProps } from '../barProps';

// const COLORS = ['#7756ff', '#7fe1da', '#fe476a', '#2bd19a'];

export const BarVisualization: React.FC<BarVisualizationProps> = ({ ...props }) => {
    const chart = useChart({
        width: props.width ?? 0,
        height: props.height ?? 0,
        datasets: props.data.datasets,
    });
    return (
        <Chart.Wrapper {...chart.getWrapperProps()}>
            <Chart.Legend {...chart.getLegendProps()} />
            <Chart.Content {...chart.getContentProps()}>
                <BarChart
                    {...props}
                    stacked={false}
                    data={{
                        ...props.data,
                        datasets: props.data.datasets.map((dataset) => ({
                            ...dataset,
                            hidden: !chart.isVisible({ id: dataset.label }),
                        })),
                    }}
                />
            </Chart.Content>
        </Chart.Wrapper>
    );
};

export const BarVisualizationV2: React.FC<BarVisualizationProps> = ({ ...props }) => {
    const chart = useChart({
        width: props.width ?? 0,
        height: props.height ?? 0,
        datasets: props.data.datasets,
    });
    return (
        <BarChartV2
            {...props}
            stacked={false}
            data={{
                ...props.data,
                datasets: props.data.datasets.map((dataset) => ({
                    ...dataset,
                    hidden: !chart.isVisible({ id: dataset.label }),
                })),
            }}
        />
    );
    // return (
    //     <Chart.Wrapper {...chart.getWrapperProps()}>
    //         <Chart.Legend {...chart.getLegendProps()} />
    //         <Chart.Content {...chart.getContentProps()}>
    //             <BarChartV2
    //                 {...props}
    //                 width={props.width}
    //                 height={props.height}
    //                 stacked={false}
    //                 data={{
    //                     ...props.data,
    //                     datasets: props.data.datasets.map((dataset) => ({
    //                         ...dataset,
    //                         hidden: !chart.isVisible({ id: dataset.label }),
    //                     })),
    //                 }}
    //             />
    //         </Chart.Content>
    //     </Chart.Wrapper>
    // );
};

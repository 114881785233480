import { z } from 'zod';

const ConstraintSchema = z.object({
    name: z.nullable(z.string()),
    expression: z.union([
        z.object({
            operator: z.literal('between'),
            value: z.tuple([z.number(), z.number()]),
        }),
        z.object({
            operator: z.literal('between'),
            value: z.tuple([z.number(), z.number()]),
        }),
    ]),
});

const IntegerTypeSchema = z.literal('integer');
const BooleanTypeSchema = z.literal('boolean');
const StringTypeSchema = z.literal('string');
const UrlTypeSchema = z.literal('url');
const FloatTypeSchema = z.literal('float');
const DateTypeSchema = z.literal('date');
const PercentTypeSchema = z.literal('percent');

const EnumTypeSchema = z.object({
    kind: z.literal('enum'),
    members: z.array(
        z.object({
            value: z.string(),
            name: z.string(),
        })
    ),
});

const ComplexPercentTypeSchema = z.object({
    kind: z.literal('percent'),
    fraction: z.boolean(),
});

const CurrencyTypeSchema = z.object({
    kind: z.literal('currency'),
    currency: z.string(),
    constraints: z.array(ConstraintSchema).default([]),
});

const ReferenceTypeSchema = z.object({
    kind: z.literal('reference'),
    id: z.string(),
});

const ComplexTypeSchema = z.discriminatedUnion('kind', [
    ComplexPercentTypeSchema,
    CurrencyTypeSchema,
    ReferenceTypeSchema,
    EnumTypeSchema,
]);

const KnownTypeSchema = z.union([
    ComplexTypeSchema,
    IntegerTypeSchema,
    FloatTypeSchema,
    StringTypeSchema,
    PercentTypeSchema,
    DateTypeSchema,
    BooleanTypeSchema,
    UrlTypeSchema,
]);

export const TypeSchema = z
    .union([KnownTypeSchema, z.unknown().default(null)])
    .transform((value, context) => {
        const parsed = KnownTypeSchema.safeParse(value);
        if (parsed.success) {
            return parsed.data;
        }
        console.warn('unhandled type in schema', value);
        return 'unknown' as const;
    });

import React from 'react';
import { Select } from 'chakra-react-select';

import {
    Flex,
    Text,
    Box,
    VStack,
    InputGroup,
    FormControl,
    FormLabel,
    FormErrorMessage,
    HStack,
    Grid,
    GridItem,
    Heading,
    Center,
    Spinner,
} from '@chakra-ui/react';
import { GroupCardViewProps, GroupsFormViewProps } from './groupsFormProps';
import { Controller } from 'react-hook-form';
import { ApplicationUI } from '../../../../ui';
import { CardSpinner } from '../../../../../domain/card/component/CardSpinner';

interface GroupCardViewItemProps extends GroupCardViewProps {
    isSelected: boolean;
    onClick: () => void;
}

const GroupCardView = ({
    isSelected,
    title,
    description,
    onClick,
}: GroupCardViewItemProps) => {
    return (
        <Box
            height="full"
            p={3}
            _hover={{
                cursor: 'pointer',
                borderColor: '#95EF93',
            }}
            bg="gray.900"
            borderColor={isSelected ? '#95EF93' : 'gray.500'}
            borderWidth={1}
            borderRadius="md"
            onClick={onClick}
            overflow="auto"
        >
            <Heading mb={2} size="sm">
                {title}
            </Heading>
            <Text color="gray.500">{description}</Text>
        </Box>
    );
};

interface GroupCardSelectProps {
    options: Array<GroupCardViewProps>;
    value: string;
    onChange: (value: string) => void;
}

const GridCardSelect = ({ options, onChange, value }: GroupCardSelectProps) => {
    return (
        <Grid w="full" templateColumns="repeat(6, 1fr)" templateRows="200px" gap={4}>
            {options.map((opt) => (
                <GridItem key={opt.value} colSpan={3}>
                    <GroupCardView
                        isSelected={value === opt.value}
                        onClick={() => onChange(opt.value)}
                        {...opt}
                    />
                </GridItem>
            ))}
        </Grid>
    );
};

export const createGroupsFormView = ({ UI }: { UI: ApplicationUI }) => {
    return (props: GroupsFormViewProps) => {
        const currentState = props.watch('state');

        return (
            <React.Suspense
                fallback={
                    <Center py={16} h="100%" position="relative" userSelect="none">
                        <VStack
                            // position="relative" bottom={16}
                            spacing={4}
                        >
                            <CardSpinner />
                            <Text
                                fontWeight="medium"
                                fontSize="lg"
                                color="whiteAlpha.700"
                                letterSpacing="wide"
                            >
                                Loading
                            </Text>
                        </VStack>
                    </Center>
                }
            >
                <form onSubmit={props.handleSubmit(props.onSave)}>
                    <VStack w="full" spacing={4} alignItems="flex-start">
                        <Controller
                            control={props.control}
                            name="state"
                            rules={{
                                required: true,
                            }}
                            render={({ field: { onChange, value } }) => {
                                if (props.isLoading) {
                                    <Spinner />;
                                }
                                return props.groupOptions.length > 0 ? (
                                    <GridCardSelect
                                        options={props.groupOptions}
                                        value={value}
                                        onChange={onChange}
                                    />
                                ) : (
                                    <></>
                                );
                            }}
                        />

                        {currentState == 'opt_in' && (
                            <FormControl
                                width={1 / 3}
                                isInvalid={!!props.formState.errors.optinAssets}
                            >
                                <InputGroup orientation="vertical" flexDirection="column">
                                    <FormLabel>Companies sharing to Co-Op</FormLabel>
                                    <Controller
                                        control={props.control}
                                        name="optinAssets"
                                        rules={{
                                            // required: true,
                                            validate: (v) => {
                                                if (v) {
                                                    if (v.length == 0) {
                                                        return 'Required';
                                                    }
                                                } else {
                                                    return 'Required';
                                                }
                                            },
                                        }}
                                        render={({
                                            field: { ref },
                                            field: { onChange, value },
                                        }) => {
                                            return (
                                                <Select
                                                    ref={ref}
                                                    isMulti={true}
                                                    isClearable={false}
                                                    value={value?.map((x) => ({
                                                        label: x.name,
                                                        value: x.id,
                                                    }))}
                                                    onChange={(
                                                        vals: {
                                                            label: string;
                                                            value: any;
                                                        }[]
                                                    ) =>
                                                        onChange(
                                                            vals.map((x) => ({
                                                                id: x.value,
                                                                name: x.label,
                                                            }))
                                                        )
                                                    }
                                                    options={
                                                        props.assetOptions.data?.map(
                                                            (x) => ({
                                                                label: x.name,
                                                                value: x.id,
                                                            })
                                                        ) ?? []
                                                    }
                                                />
                                            );
                                        }}
                                    />
                                </InputGroup>
                                {props.formState.errors.optinAssets ? (
                                    <FormErrorMessage>
                                        {
                                            // @ts-ignore
                                            props.formState.errors.optinAssets?.message
                                        }
                                    </FormErrorMessage>
                                ) : null}
                            </FormControl>
                        )}
                        {/* <Flex w='full' justify='space-between'> */}
                        <HStack alignSelf="flex-end">
                            {!props.isDisabled && currentState !== 'pending' && (
                                <UI.Button
                                    isLoading={props.isClearing}
                                    colorScheme="red"
                                    variant="outline"
                                    onClick={props.onClear}
                                    type="button"
                                >
                                    Clear Configuration
                                </UI.Button>
                            )}
                            <UI.Button
                                isLoading={props.isSaving}
                                disabled={
                                    !!props.formState.errors.state ||
                                    !!props.formState.errors.optinAssets ||
                                    props.isClearing ||
                                    props.isDisabled
                                }
                                colorScheme="green"
                                variant="solid"
                                type="submit"
                            >
                                Save Configuration
                            </UI.Button>
                        </HStack>
                        {props.error && <Text color="red">{props.error}</Text>}
                        {/* </Flex> */}
                    </VStack>
                </form>
            </React.Suspense>
        );
    };
};

import React, { FormEventHandler } from 'react';
import { useForm, DefaultValues } from 'react-hook-form';
import {
    Box,
    TooltipProps,
    Tag as BaseTag,
    TagProps as BaseTagProps,
    TagCloseButton,
    TagCloseButtonProps,
} from '@chakra-ui/react';
import { Tooltip } from '../tooltip/Tooltip';

export interface TagProps extends BaseTagProps {
    onClear?: React.MouseEventHandler;
    showClear?: boolean;
    disabled?: boolean;
    allowClickWhenDisabled?: boolean;
    tooltipProps?: Pick<TooltipProps, 'label' | 'placement'>;
    rightIcon?: React.ReactElement;
}

export const Tag: React.FC<TagProps> = React.forwardRef((props, ref) => {
    const control = useControl(props);
    return (
        <Tooltip isDisabled={!props.tooltipProps} {...props.tooltipProps}>
            <BaseTag ref={ref as any} {...control.getContainerProps()}>
                {props.children}
                {control.showClear && (
                    <TagCloseButton {...control.getCloseProps()} h="inherit" />
                )}
                {props.rightIcon ? <Box ml={2}>{props.rightIcon}</Box> : null}
            </BaseTag>
        </Tooltip>
    );
});

const useControl = ({
    tooltipProps,
    allowClickWhenDisabled,
    showClear,
    onClear,
    disabled,
    rightIcon,
    ...props
}: TagProps) => {
    return {
        showClear: typeof showClear === 'boolean' ? showClear : !!onClear,
        getCloseProps(): TagCloseButtonProps {
            return {
                onClick: onClear,
            };
        },
        getContainerProps(): BaseTagProps {
            const style = disabled ? (props._disabled as any) : {};
            return {
                ...props,
                ...style,
                onClick(event) {
                    if (disabled && !allowClickWhenDisabled) {
                        return;
                    }
                    return props.onClick?.(event);
                },
            };
        },
    };
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RunCreatePayloadSchema = exports.RunObjectSchema = void 0;
const zod_1 = require("zod");
const message_1 = require("../message");
exports.RunObjectSchema = zod_1.z.object({
    id: zod_1.z.string(),
    object: zod_1.z.literal('run'),
    messages: zod_1.z.array(message_1.MessageObjectSchema),
    state: zod_1.z.record(zod_1.z.string(), zod_1.z.unknown()).nullable(),
    created_at: zod_1.z.date({ coerce: true }),
});
exports.RunCreatePayloadSchema = zod_1.z.object({
    thread: zod_1.z.string(),
    agent: zod_1.z.string().optional(),
    messages: zod_1.z.array(message_1.MessageCreatePayloadSchema.omit({
        thread: true,
    })),
});

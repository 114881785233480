import {
    Link as ChakraLink,
    Box,
    Grid,
    GridItem,
    HStack,
    Icon,
    Image,
    Input,
    InputGroup,
    InputLeftElement,
    Popover,
    PopoverBody,
    PopoverContent,
    PopoverTrigger,
    Select,
    StackDivider,
    Tag,
    Text,
    useDisclosure,
    VStack,
    Button,
    Center,
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    WrapItem,
    Wrap,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { SettingAccountItemProviderConfig } from '../../../../../../route';
import { SettingDatasetConnectionConfig } from '../../connectionConfig';
import { SettingDatasetConnectionViewProps } from '../../connectionProps';
import { DatasetConnectionItemProps } from '../../../../../../view/datasets';

export function createSettingAssetConnectionListView(
    init: SettingDatasetConnectionConfig,
    config: SettingAccountItemProviderConfig
): React.FC<SettingDatasetConnectionViewProps> {
    const { controller } = init;
    const {
        UI: {
            Application: { Link, Heading, Tooltip },
        },
        Layout: {
            Container,
            Content,
            Panel,
            List,
            Header: {
                Container: Header,
                Breadcrumbs: { List: BreadcrumbsList, Item: BreadcrumbsItem },
                Action: { List: ActionList },
            },
        },
    } = config;

    const ListItem: React.FC<DatasetConnectionItemProps> = (props) => {
        const [isOpen, setIsOpen] = useState(false);
        const metadataListProps = props.getMetadataListProps();
        return (
            <Grid
                w="full"
                templateColumns="minmax(0, 1fr) minmax(150px, 150px) minmax(150px, 150px)"
                p={6}
            >
                <GridItem>
                    <VStack spacing={1} w="full" align="start">
                        <HStack h="full" spacing={4}>
                            <Box
                                p={2.5}
                                bg="whiteAlpha.300"
                                borderRadius="md"
                                flexShrink={0}
                            >
                                <Image w={5} h={5} src={props.iconUrl} />
                            </Box>
                            <VStack align="start" spacing={0}>
                                <Text>{props.name}</Text>
                                <Wrap spacing={3} color="whiteAlpha.500">
                                    {metadataListProps.items.map((item, index) => (
                                        <WrapItem key={index} cursor="pointer">
                                            <Text userSelect="none">{item.label}</Text>
                                        </WrapItem>
                                    ))}
                                </Wrap>
                            </VStack>
                        </HStack>
                    </VStack>
                </GridItem>
                <GridItem>
                    <HStack h="full" w="full" justify="center">
                        {props.updated ? (
                            <Tooltip
                                placement="top"
                                hasArrow={true}
                                p={4}
                                bg="#1D1C21"
                                label={
                                    <Text color="whiteAlpha.700">
                                        {props.updated.absolute.description}
                                    </Text>
                                }
                            >
                                <Text color="whiteAlpha.700" userSelect="none">
                                    {props.updated.relative.label}
                                </Text>
                            </Tooltip>
                        ) : (
                            <></>
                        )}
                    </HStack>
                </GridItem>
                <GridItem>
                    <HStack h="full" w="full" justify="end">
                        <Tooltip
                            placement="top-end"
                            hasArrow={true}
                            p={4}
                            bg="#1D1C21"
                            label={
                                <VStack
                                    spacing={2}
                                    align="start"
                                    minW={32}
                                    divider={
                                        <StackDivider
                                            borderWidth={1}
                                            borderColor="whiteAlpha.300"
                                        />
                                    }
                                >
                                    <VStack spacing={1}>
                                        <HStack w="full">
                                            <Box
                                                w={2}
                                                h={2}
                                                borderRadius="full"
                                                bg={`${props.status.colorScheme}.400`}
                                            />
                                            <Text fontWeight="semibold">
                                                {props.status.label}
                                            </Text>
                                        </HStack>
                                        <Text color="whiteAlpha.700">
                                            {props.status.description}
                                        </Text>
                                    </VStack>
                                </VStack>
                            }
                        >
                            <Tag
                                userSelect="none"
                                size="lg"
                                {...props.status.getTagProps()}
                            />
                        </Tooltip>
                    </HStack>
                </GridItem>
            </Grid>
        );
    };

    return (props) => {
        const pageProps = props.getPageProps();
        const breadcrumbsProps = pageProps.getBreadcrumbProps();
        const viewProps = props.getViewProps();

        return (
            <Container>
                <Header>
                    <BreadcrumbsList>
                        {breadcrumbsProps.items.map((item, index) => (
                            <BreadcrumbsItem
                                key={index}
                                to={item.to ?? undefined}
                                description={item.description}
                            >
                                {item.label}
                            </BreadcrumbsItem>
                        ))}
                    </BreadcrumbsList>
                </Header>
                <Content>
                    <VStack spacing={0} align="start" w="full" fontWeight="medium">
                        <Grid
                            bg="whiteAlpha.300"
                            w="full"
                            templateColumns="1fr minmax(150px, 150px) minmax(150px, 150px)"
                            px={6}
                            // py={3}
                            h={10}
                        >
                            <GridItem>
                                <HStack h="full" w="full">
                                    <Heading
                                        textTransform="uppercase"
                                        fontSize="xs"
                                        fontWeight="bold"
                                        color="whiteAlpha.700"
                                        letterSpacing="wider"
                                    >
                                        Dataset
                                    </Heading>
                                </HStack>
                            </GridItem>
                            <GridItem>
                                <HStack h="full" w="full" justify="center">
                                    <Text
                                        textTransform="uppercase"
                                        fontSize="xs"
                                        fontWeight="bold"
                                        color="whiteAlpha.700"
                                        letterSpacing="wider"
                                    >
                                        Updated At
                                    </Text>
                                </HStack>
                            </GridItem>
                            <GridItem>
                                <HStack h="full" w="full" justify="end">
                                    <Text
                                        textTransform="uppercase"
                                        fontSize="xs"
                                        fontWeight="bold"
                                        color="whiteAlpha.700"
                                        letterSpacing="wider"
                                    >
                                        Status
                                    </Text>
                                </HStack>
                            </GridItem>
                        </Grid>
                        <VStack
                            spacing={0}
                            align="start"
                            w="full"
                            divider={
                                <StackDivider
                                    borderWidth={1}
                                    borderColor="whiteAlpha.300"
                                />
                            }
                        >
                            {viewProps.items.map((item) => {
                                const itemProps = viewProps.getItemProps(item);
                                return <ListItem key={itemProps.id} {...itemProps} />;
                            })}
                        </VStack>
                    </VStack>
                </Content>
            </Container>
        );
    };
}

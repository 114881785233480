import { AxiosInstance } from 'axios';
import React, { useMemo } from 'react';
import { useParams } from 'react-router';
import { useAuthenticatedContext } from '../../../../container';
import { Workspace } from '../../../domain';
import { useWorkspaceApi } from '../workspace';
import { DashboardConfigurationAdapter } from '../configuration';
import { ChartAdapter } from '../chart';
import { DashboardContext, DashboardContextValue } from './dashboardContext';
import { DashboardAdapter } from './dashboardAdapter';
import { PreferencesAdapter } from '../preferences';
import { Navigate } from 'react-router-dom';

export interface DashboardProviderConfig {
    param: string;
    getDeps(config: { workspace: Workspace; api: AxiosInstance }): {
        dashboard: DashboardAdapter;
        configuration: DashboardConfigurationAdapter;
        chart: ChartAdapter;
        preferences: PreferencesAdapter;
    };
}
const BW_DASHBOARD_SLUGS: Record<string, string> = {
    "googleads-v2": 'google-a-d-s-v2'
};

export interface DashboardProviderProps {}

export function createDashboardProvider(
    config: DashboardProviderConfig
): React.FC<DashboardProviderProps> {
    return (props) => {
        const { api } = useAuthenticatedContext();
        const workspace = useWorkspaceApi().getWorkspace();
        const dashboards = useWorkspaceApi().getDashboards();

        const adapter = useMemo(
            () => config.getDeps({ api, workspace }),
            [api, workspace]
        );

        const { [config.param]: dashboardId, ...rest } = useParams();
        if (!dashboardId) {
            const candidates = Object.keys(rest);
            throw new Error(
                `url parameter '${config.param}' not found. found ${candidates.join(
                    ', '
                )}`
            );
        }

        const dashboard = dashboards.items.find((item) => item.id === dashboardId);

        if (!dashboard) {
            if(BW_DASHBOARD_SLUGS[dashboardId]) {
                return <Navigate to={`../${BW_DASHBOARD_SLUGS[dashboardId]}`} />
            }
            throw new Error(`dashboard '${dashboardId}' not found`);
        }

        const context: DashboardContextValue = {
            workspace,
            dashboard,
            adapter: {
                chart: adapter.chart,
                preferences: adapter.preferences,
                dashboard: adapter.dashboard,
                configuration: adapter.configuration,
            },
        };

        return (
            <DashboardContext.Provider value={context}>
                {props.children}
            </DashboardContext.Provider>
        );
    };
}

import React, { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
    decodeVisualization,
    fromVisualizationDto,
} from '../../../service/visualizations';
import { WorkspaceContextV2 } from '../../../app';
import { AnalysisRouteDeps } from './analysisRouteConfig';
import { AnalysisRouteController, AnalysisRouteLoader } from './analysisRouteInterface';
import { AnalysisRouteViewProps } from './analysisRouteProps';

export function createAnalysisRouteContainer(
    config: AnalysisRouteDeps,
    loader: AnalysisRouteLoader,
    controller: AnalysisRouteController,
    Component: React.FC<AnalysisRouteViewProps>
): React.FC {
    const { hook } = config;
    return (props) => {
        const [searchParams, setQueryParams] = useSearchParams();

        const visualizationProps = useMemo(
            () => fromVisualizationDto(decodeVisualization(searchParams)),
            [searchParams]
        );

        const organization = hook.useOrganization();
        const workspace = hook.useWorkspace();
        const auth = hook.useAuth();

        const context: WorkspaceContextV2 = {
            organization,
            workspace,
            auth,
        };

        const data = loader.useData(context, { visualization: visualizationProps });

        const viewProps = controller.useProps(context, data, {
            visualization: visualizationProps,
        });

        return <Component {...viewProps} />;
    };
}

import React from 'react';
import {
    Box,
    Flex,
    Heading,
    HStack,
    Spacer,
    VStack,
    Text,
    Image,
    SkeletonCircle,
    Skeleton,
    Stack,
    BoxProps,
    Tooltip,
    Icon,
    Link,
} from '@chakra-ui/react';
import { MdOutlineHelpOutline } from 'react-icons/md';
import { IntegrationTheme } from '../../integrationTheme';
import { AnyIntegrationStatus, UserIntegrationDefinition } from '../../integrationType';

export interface IntegrationCardProps {
    containerProps?: BoxProps;
    definition: UserIntegrationDefinition;
    description?: string | null;
    highlight?: boolean;
    // onClick?(): void;
    theme: IntegrationTheme;
    status?: AnyIntegrationStatus;
    statusText?: React.ReactNode;
    footer?: React.ReactElement;
    action?: React.ReactElement;
}

export const IntegrationCard: React.FunctionComponent<IntegrationCardProps> = ({
    highlight = true,
    definition,
    statusText,
    status,
    ...props
}) => {
    function getStatusStyleProps() {
        if (status?.kind === 'error' || status?.kind === 'warning') {
            return {
                borderColor: 'red.500',
                _hover: {
                    borderColor: 'red.500',
                },
            };
        }
        return {};
    }
    function getErrorTextProps() {
        if (status?.kind === 'error' || status?.kind === 'warning') {
            return {
                color: 'red.500',
            };
        }
        return { color: 'gray.500' };
    }
    return (
        <Box
            w="100%"
            h="100%"
            _hover={{
                borderColor: props.theme.card._hover.borderColor,
                boxShadow: 'md',
                ...props.containerProps?._hover,
            }}
            borderWidth="thin"
            borderRadius="md"
            padding={2}
            borderColor={props.theme.cardFrame}
            bg={props.theme.cardBgColor}
            shadow="sm"
            align="stretch"
            p={5}
            // onClick={props.onClick}
            {...props.containerProps}
            {...getStatusStyleProps()}
        >
            <VStack h="100%" align="stretch">
                <HStack w="100%" spacing={4} width="full">
                    <Box p={2} bg={props.theme.cardHighlightBgColor} borderRadius={8}>
                        {definition.icon_url ? (
                            <Image boxSize="30px" src={definition.icon_url} />
                        ) : (
                            <SkeletonCircle size="10" />
                        )}
                    </Box>
                    <Flex flex={1} justifyContent="flex-end">
                        {statusText}
                    </Flex>
                    {!statusText && highlight && definition.is_recommended ? (
                        <Box bg="labels.lightblue" borderRadius="md" px={3} py={0.5}>
                            <Text color="labels.blue" fontSize="sm" fontWeight="semibold">
                                Most Popular
                            </Text>
                        </Box>
                    ) : (
                        <></>
                    )}
                    {/* {definition.reconnectedItems && (
                        <HStack spacing={2} pr={1}>
                            <Image src={reconnectSVG} />
                            <Text color="#F8BC3B">{definition.reconnectedItems}</Text>
                        </HStack>
                    )} */}
                    {props.action}
                </HStack>
                <Box overflow="auto">
                    {definition.title ? (
                        <>
                            <Heading fontSize="md" colorScheme="gray">
                                {definition.title}
                            </Heading>
                            {props.description !== null && definition.description && (
                                <Text mt={1} overflow="auto" fontSize="sm" maxHeight="90px" colorScheme="gray">
                                    {definition.description}
                                </Text>
                            )}
                            {definition.implementation.install_guide && (
                                <Box pt={2}>
                                    <Link target='_blank' href={definition.implementation.install_guide.link}>Documentation</Link>
                                </Box>
                            )}
                        </>
                    ) : (
                        <Stack>
                            <Skeleton height="15px" />
                            <Skeleton height="15px" />
                        </Stack>
                    )}
                </Box>
                {props.children}
                <Box>{props.footer}</Box>
                {status?.text && (
                    <Box>
                        <Text color={getErrorTextProps().color}>
                            {status.text}
                            {status?.hint && (
                                <Tooltip
                                    openDelay={0}
                                    label={status?.hint}
                                    isDisabled={!status?.hint}
                                    hasArrow={true}
                                    py={2}
                                    px={4}
                                >
                                    <span>
                                        <Icon ml={1} as={MdOutlineHelpOutline} />
                                    </span>
                                </Tooltip>
                            )}
                        </Text>
                    </Box>
                )}
            </VStack>
        </Box>
    );
};

import React from 'react';
import { SettingAccountContextValue, SettingAccountController } from './accountInterface';
import { SettingAccountViewProps } from './accountProps';
import { SettingAccountConfig } from './accountConfig';
import { SettingAccountContext } from './accountContext';

export function createSettingAccountContainer(
    config: SettingAccountConfig['deps'],
    controller: SettingAccountController,
    Component: React.FC<SettingAccountViewProps>
): React.FC {
    return (props) => {
        const auth = config.hooks.useAuth();
        const organization = config.hooks.useOrganization();
        const context: SettingAccountContextValue = {
            auth,
            organization,
        };
        const viewProps = controller.useProps();
        return (
            <SettingAccountContext.Provider value={context}>
                <Component {...viewProps}>{props.children}</Component>
            </SettingAccountContext.Provider>
        );
    };
}

import {
    Box,
    Button,
    Grid,
    GridItem,
    Heading,
    HStack,
    Image,
    Input,
    List,
    ListItem,
    StackDivider,
    Switch,
    Tag,
    Text,
    Tooltip,
    VStack,
    FormControl,
    FormLabel,
    InputGroup,
    InputLeftAddon,
    Icon,
    SimpleGrid,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverBody,
    useDisclosure,
    PopoverHeader,
    Center,
    Wrap,
    WrapItem,
    Spinner,
    Alert,
    AlertTitle,
    AlertIcon,
    AlertDescription,
    InputLeftElement,
} from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import React, { useMemo, useRef, useState } from 'react';
import {
    AiOutlineArrowRight,
    AiOutlineCaretDown,
    AiOutlineClose,
    AiOutlineEdit,
    AiOutlineInfoCircle,
    AiOutlineLink,
    AiOutlineQuestionCircle,
    AiOutlineSearch,
} from 'react-icons/ai';
import { FiChevronDown } from 'react-icons/fi';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { RemoteIframe } from '../../../../ui';
import { CardSpinner } from '../../../../domain';
import { isLoadingLookup } from '../../../base';
import { Integration, IntegrationDefinition } from '../../../domain/assets';
import {
    OrganizationMappableListProps,
    OrganizationMappingOptionProps,
    OrganizationMappingViewProps,
} from '../../../view/assets';
import { AnyOnboardingStepId } from '../../../domain/onboarding';
import {
    EntityControlControllerProps,
    EntityControlDeps,
    EntityControlViewProps,
} from '../../../view';
import { AssetMappingContainerConfigV2 } from './onboardingMappingConfig';
import { createOnboardingEntityListView } from './entity/list';
import {
    createOnboardingEntityControlView,
    createOnboardingEntitySelectContainer,
} from './entity';

export function createOnboardingMappingViewV2(
    config: AssetMappingContainerConfigV2
): React.FC<OrganizationMappingViewProps> {
    const MappableSelectContainerV2 = createOnboardingEntitySelectContainer(config);
    return (props) => {
        const navigate = useNavigate();
        const [searchParams] = useSearchParams();
        const emptyStateProps = props.getEmptyStateProps();
        const integrationListProps = props.getDefinitionListProps();
        const conflicts = props.getConflictListProps();
        if (emptyStateProps?.kind === 'loading') {
            return (
                <Center p={6}>
                    <CardSpinner />
                </Center>
            );
        }
        if (emptyStateProps?.kind === 'empty') {
            return (
                <Alert status="warning" size="lg">
                    <AlertIcon />
                    <AlertTitle>No integrations found</AlertTitle>
                </Alert>
            );
        }
        return (
            <VStack
                w="full"
                spacing={12}
                py={6}
                divider={<StackDivider borderColor="gray.300" borderWidth={0.5} />}
            >
                <VStack spacing={6} w="full">
                    <VStack w="full" spacing={6}>
                        {integrationListProps.status && (
                            <Alert status="warning">
                                <AlertIcon />
                                <AlertTitle w="full">
                                    <HStack w="full" justify="space-between">
                                        <Text fontWeight="semibold">
                                            {integrationListProps.status.text}
                                        </Text>
                                        {/* {integrationListProps.status.isLoading && (
                                            <Spinner speed="3.5s" boxSize={5} />
                                        )} */}
                                    </HStack>
                                </AlertTitle>
                            </Alert>
                        )}
                        {conflicts.length > 0 && (
                            <VStack align="start" w="full">
                                {conflicts.map((item) => (
                                    <Alert key={item.entity.id} status="warning">
                                        <AlertIcon />
                                        <AlertTitle w="full">
                                            <HStack w="full" justify="space-between">
                                                <Text fontWeight="semibold">
                                                    {item.text}
                                                </Text>
                                            </HStack>
                                        </AlertTitle>
                                    </Alert>
                                ))}
                            </VStack>
                        )}
                        <VStack
                            w="full"
                            _selected={{
                                borderColor: 'blue.400',
                            }}
                            divider={<StackDivider borderColor="gray.300" />}
                        >
                            <VStack spacing={4} w="full">
                                <VStack spacing={4} w="full">
                                    {integrationListProps.items.map((integration) => {
                                        const itemProps =
                                            props.getDefinitionItemProps(integration);
                                        const selectProps =
                                            props.getMappableListProps(integration);
                                        return (
                                            <SimpleGrid
                                                key={integration.id}
                                                // maxW="66%"
                                                mx="auto"
                                                // align="start"
                                                columns={3}
                                                gap={4}
                                                w="full"
                                                borderRadius="lg"
                                                bg="white"
                                                shadow="sm"
                                                border="1px solid"
                                                borderColor="gray.300"
                                                templateColumns="1fr min-content"
                                                p={6}
                                            >
                                                <HStack
                                                    key={integration.id}
                                                    spacing={4}
                                                    flexShrink={0}
                                                >
                                                    <Box
                                                        p={3}
                                                        bg="gray.100"
                                                        borderRadius="md"
                                                        flexShrink={0}
                                                    >
                                                        <Image
                                                            w={6}
                                                            h={6}
                                                            src={integration.iconUrl}
                                                        />
                                                    </Box>
                                                    <Text
                                                        whiteSpace="nowrap"
                                                        fontSize="lg"
                                                        fontWeight="semibold"
                                                    >
                                                        {integration.name}
                                                    </Text>
                                                </HStack>
                                                <HStack
                                                    justify="end"
                                                    flexShrink={0}
                                                    w="full"
                                                    spacing={4}
                                                >
                                                    {itemProps.kind === 'ready' ? (
                                                        <>
                                                            <HStack
                                                                align="center"
                                                                justify="center"
                                                            >
                                                                <Icon
                                                                    color="gray.300"
                                                                    fontSize="xl"
                                                                    as={
                                                                        AiOutlineArrowRight
                                                                    }
                                                                />
                                                            </HStack>
                                                            <MappableSelectContainerV2
                                                                {...props.getEntityControlProps(
                                                                    integration
                                                                )}
                                                            />
                                                        </>
                                                    ) : null}
                                                    {itemProps.kind === 'analyzing' ||
                                                    itemProps.kind === 'waiting' ? (
                                                        <Tooltip
                                                            placement="top"
                                                            hasArrow={true}
                                                            label={
                                                                <Box p={2}>
                                                                    <Text>
                                                                        {
                                                                            itemProps.description
                                                                        }
                                                                    </Text>
                                                                    <Text>
                                                                        (estimate{' '}
                                                                        {
                                                                            itemProps.estimate
                                                                        }
                                                                        )
                                                                    </Text>
                                                                </Box>
                                                            }
                                                        >
                                                            <Tag
                                                                size="lg"
                                                                flexShrink={0}
                                                                colorScheme="orange"
                                                                whiteSpace="nowrap"
                                                            >
                                                                <HStack spacing={3}>
                                                                    <Text>
                                                                        {itemProps.label}
                                                                    </Text>
                                                                </HStack>
                                                            </Tag>
                                                        </Tooltip>
                                                    ) : null}

                                                    {itemProps.kind === 'empty' ? (
                                                        <Tooltip
                                                            placement="top"
                                                            hasArrow={true}
                                                            label={
                                                                <Box p={2}>
                                                                    <Text>
                                                                        {
                                                                            itemProps.description
                                                                        }
                                                                    </Text>
                                                                </Box>
                                                            }
                                                        >
                                                            <Tag
                                                                size="lg"
                                                                flexShrink={0}
                                                                colorScheme="orange"
                                                                whiteSpace="nowrap"
                                                            >
                                                                {itemProps.label}
                                                            </Tag>
                                                        </Tooltip>
                                                    ) : null}
                                                </HStack>
                                            </SimpleGrid>
                                        );
                                    })}
                                </VStack>
                                <HStack w="full" justify="space-between">
                                    {/* <HStack flex={1} w="full" color="gray.500">
                                        <Icon as={AiOutlineInfoCircle}></Icon>
                                        <Text>
                                            Please{' '}
                                            <Box
                                                color="blue.500"
                                                textDecoration="underline"
                                                cursor="pointer"
                                                as="span"
                                                onClick={integrationListProps.onHelpClick}
                                            >
                                                reach out
                                            </Box>{' '}
                                            to us if you would like multiple accounts
                                            associated with a single company.
                                        </Text>
                                    </HStack> */}
                                    <Button
                                        bg="white"
                                        borderColor="gray.300"
                                        borderStyle="solid"
                                        borderWidth={1}
                                        onClick={() => {
                                            const step: AnyOnboardingStepId =
                                                'integration';

                                            const current = `${location.pathname}${location.search}`;
                                            searchParams.set('step', step);
                                            searchParams.set('success_path', current);
                                            const queryString = searchParams.toString();
                                            navigate(`/u/onboarding?${queryString}`);
                                        }}
                                    >
                                        Manage integrations
                                    </Button>
                                </HStack>
                            </VStack>
                        </VStack>
                    </VStack>
                    <HStack flex={1} w="full" color="gray.500">
                        <Icon as={AiOutlineInfoCircle}></Icon>
                        <Text>
                            Please{' '}
                            <Box
                                color="blue.500"
                                textDecoration="underline"
                                cursor="pointer"
                                as="span"
                                onClick={integrationListProps.onHelpClick}
                            >
                                reach out
                            </Box>{' '}
                            to us if you would like multiple accounts associated with a
                            single company.
                        </Text>
                    </HStack>
                </VStack>
                <Center>
                    <Box borderRadius="md" overflow="hidden" shadow="md">
                        <RemoteIframe
                            bg="blackAlpha.900"
                            spinner={<Spinner color="whiteAlpha.900" />}
                            width="500"
                            height="300"
                            src="https://www.loom.com/embed/3ff3e930d77144b78d70d638ae396948?sid=7d13759d-ef15-4dca-9357-4234e7e8261e"
                            frameBorder="0"
                            allowFullScreen={true}
                        />
                    </Box>
                </Center>
            </VStack>
        );
    };
}

import { WorkspaceContextV2 } from '../../../app/platform';
import { CompetitveSetStatusAdapter } from './competitiveStatusAdapter';
import { CompetitveSetStatusQuery } from './competitiveStatusQuery';

export function createCompetitveSetStatusRepository(adapter: CompetitveSetStatusAdapter) {
    return {
        find(context: WorkspaceContextV2, query: CompetitveSetStatusQuery) {
            return {
                queryKey: ['competitive', 'status', query],
                async queryFn() {
                    return adapter.findOne(context, query);
                },
            };
        },
    };
}

import React from 'react';
import { WorkspaceContextV2 } from '../../../../app';
import { OnboardingPeerGroupContainerConfig } from '../onboardingPeerGroupConfig';
import { PluginItemController, PluginItemLoader } from './pluginInterface';
import { PluginItemControllerProps, PluginItemViewProps } from './pluginProps';

export function createPluginItemContainer(
    config: OnboardingPeerGroupContainerConfig,
    loader: PluginItemLoader,
    controller: PluginItemController,
    Component: React.FC<PluginItemViewProps>
): React.FC<PluginItemControllerProps> {
    const { hooks } = config;
    return (props) => {
        const context: WorkspaceContextV2 = {
            organization: hooks.useOrganization(),
            auth: hooks.useAuth(),
            workspace: hooks.useWorkspace(),
        };
        const data = loader.useData(context, props);
        const viewProps = controller.useProps(context, data, props);
        return <Component {...viewProps} />;
    };
}

import { StudyConstant } from '../../../config';
import { DatasetEntity } from '../../datasets';
import { CountEntity } from '../../platform';
import { FormSubmissionStatus } from './formSubmissionModel';

export function getSubmissionStatus(item: {
    dataset: DatasetEntity;
    respondents: CountEntity | null;
}): FormSubmissionStatus {
    if (
        item.respondents &&
        item.respondents.value > StudyConstant.MIN_NUMBER_OF_RESPONSES
    ) {
        return 'ready';
    }
    return 'collecting';
}

import React, { createContext, useContext, useEffect, useMemo } from 'react';
import { z } from 'zod';
import { useLocalStorage } from 'react-use';
import { useMousePosition } from '../../vendor/use-mouse';
import { Session, refreshSession, isSessionStale, buildSession } from '../../domain';
import { ApplicationEntryEnhancer } from '../../entrypoint';
import { SessionEnhancerConfig } from './sessionConfig';
import { SessionSchema } from './sessionSchema';
import { SessionStartedEvent } from './sessionEvent';

export interface SessionContextValue {
    session: Session | null;
}

/**
 * Tracks user sessions using the browser's session storage
 */
export function createSessionEnhancer(init: SessionEnhancerConfig) {
    const SessionContext = createContext<null | SessionContextValue>(null);

    const searchParams = new URLSearchParams(window.location.search);
    const searchParamsObject = [...searchParams.entries()].reduce(
        (acc, [key, value]) => ({ ...acc, [key]: value }),
        {}
    );

    const SessionUrlParametersSchema = z.object({
        [init.sourceNameParameterName]: z.optional(z.string()),
        [init.sourceMediumParameterName]: z.optional(z.string()),
        [init.sourceTriggerParameterName]: z.optional(z.string()),
    });

    const searchParamsParseResult =
        SessionUrlParametersSchema.safeParse(searchParamsObject);

    const searchParamsParsed = searchParamsParseResult.success
        ? searchParamsParseResult.data
        : null;

    const sourceNameValue = searchParamsParsed?.[init.sourceNameParameterName];
    const sourceMediumValue = searchParamsParsed?.[init.sourceMediumParameterName];
    const sourceTriggerValue = searchParamsParsed?.[init.sourceTriggerParameterName];

    if (sourceNameValue) {
        console.info(
            `session '${init.sourceNameParameterName}' as '${sourceNameValue}' detected`
        );
    }

    if (sourceMediumValue) {
        console.info(
            `session '${init.sourceMediumParameterName}' as '${sourceMediumValue}' detected`
        );
    }

    if (sourceTriggerValue) {
        console.info(
            `session trigger '${init.sourceTriggerParameterName}' as '${sourceTriggerValue}' detected`
        );
    }

    const enhancer: ApplicationEntryEnhancer = (create) => (config) => {
        const useTracker = config.infra.createUseTracker();

        const Provider: React.FC = (props) => {
            const tracker = useTracker<SessionStartedEvent>();
            const [sessionRaw, setSession] = useLocalStorage<Session | null>(
                init.storageKey
            );
            const parseResult = useMemo(
                () => (sessionRaw ? SessionSchema.safeParse(sessionRaw) : null),
                [sessionRaw?.id, sessionRaw?.expiresAt]
            );

            let sessionOrError: Session | null | Error = null;
            if (parseResult?.success) {
                sessionOrError = parseResult.data;
            } else if (parseResult?.error) {
                sessionOrError = parseResult.error;
            }

            const mouse = useMousePosition({
                debounceMs: init.mouseDebounceMs,
            });

            useEffect(() => {
                const now = new Date();
                const next: Session = buildSession({
                    id: init.createId(),
                    sourceName: sourceNameValue,
                    sourceMedium: sourceMediumValue,
                    sourceTrigger: sourceTriggerValue,
                    startedAt: now,
                    ttlMs: init.ttlMs,
                });
                if (sessionOrError instanceof Error) {
                    console.error(
                        'invalid session, recreating...',
                        next.id,
                        sessionOrError
                    );
                    return setSession(next);
                }
                if (sessionOrError == null) {
                    console.log('no session, creating...', next.id);
                    tracker.track('session_started', {
                        session_id: next.id,
                        session_source_name: next.sourceName,
                        session_source_medium: next.sourceMedium,
                        session_source_trigger: next.sourceTrigger,
                    });
                    return setSession(next);
                }
                if (isSessionStale(sessionOrError)) {
                    console.log('stale session, recreating...', next.id);
                    tracker.track('session_started', {
                        session_id: next.id,
                        session_source_name: next.sourceName,
                        session_source_medium: next.sourceMedium,
                        session_source_trigger: next.sourceTrigger,
                    });
                    return setSession(next);
                }
                const refreshed = refreshSession(sessionOrError, init.ttlMs);
                return setSession(refreshed);
            }, [mouse?.x, mouse?.y]);

            return React.createElement(SessionContext.Provider, {
                value: {
                    session: sessionOrError instanceof Error ? null : sessionOrError,
                },
                children: props.children,
            });
        };

        return create({
            ...config,
            infra: {
                ...config.infra,
                createUseTracker() {
                    return () => {
                        const original = useTracker();
                        const context = useContext(SessionContext);
                        return {
                            ...original,
                            track(event, payload) {
                                return original.track(event, {
                                    ...payload,
                                    session_id: context?.session?.id ?? null,
                                    session_source_name: context?.session?.sourceName,
                                    session_source_medium: context?.session?.sourceMedium,
                                    session_source_trigger:
                                        context?.session?.sourceTrigger,
                                });
                            },
                        };
                    };
                },
            },
            provider: {
                ...config.provider,
                custom: [...config.provider.custom, Provider],
            },
        });
    };

    return enhancer;
}

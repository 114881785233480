import {
    Alert,
    AlertIcon,
    AlertTitle,
    Box,
    Button,
    Center,
    Grid,
    GridItem,
    Heading,
    HStack,
    Icon,
    Spinner,
    Text,
    useToken,
    VStack,
} from '@chakra-ui/react';
import React, { Suspense } from 'react';
import { AiOutlineArrowRight, AiOutlineSearch } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { CardSpinner } from '../../../domain';
import { DomainLayoutConfig } from './domainLayoutConfig';
import { DomainLayoutViewProps } from './domainLayoutProps';

export function createDomainLayoutView(
    config: Pick<DomainLayoutConfig, 'UI'>
): React.FC<DomainLayoutViewProps> {
    const {
        UI: { createErrorBoundary, Link: ApplicationLink },
    } = config;
    const ErrorBoundary = createErrorBoundary({
        errorClass: Error,
    });
    return (props) => {
        const { navigationListProps } = props;
        const scrollbarBg = useToken('colors', 'whiteAlpha.400');
        const scrollbarHoverBg = useToken('colors', 'whiteAlpha.500');

        const iconProps = props.emptyStateProps?.icon?.props ?? { as: AiOutlineSearch };

        return (
            <Box
                ref={props.scrollRef}
                overflowY="auto"
                css={{
                    scrollbarGutter: 'stable both-edges',
                    '&::-webkit-scrollbar': {
                        '-webkit-appearance': 'none',
                        width: '0.5rem',
                    },
                    '&::-webkit-scrollbar-track': {},
                    '&::-webkit-scrollbar-corner': {
                        '-webkit-appearance': 'none',
                    },
                    '&::-webkit-scrollbar-thumb': {
                        borderRadius: '1rem',
                        background: scrollbarBg,
                    },
                    '&::-webkit-scrollbar-thumb:hover': {
                        // background: scrollbarBg,
                        background: scrollbarHoverBg,
                    },
                }}
            >
                <Grid
                    w="full"
                    h="full"
                    templateColumns={navigationListProps ? '1fr 3fr' : '1fr'}
                    templateRows="1fr"
                    maxW="96rem"
                    mx="auto"
                >
                    {navigationListProps && (
                        <GridItem
                            borderRightWidth={3}
                            borderStyle="solid"
                            borderColor="whiteAlpha.300"
                            my={12}
                            position="relative"
                        >
                            <VStack
                                p={12}
                                w="full"
                                align="end"
                                fontWeight="medium"
                                position="sticky"
                                top={12}
                            >
                                <VStack w="fit-content" align="end" spacing={0}>
                                    {navigationListProps.items.map((item) => {
                                        const itemProps =
                                            navigationListProps.getItemProps(item);
                                        return (
                                            <Link
                                                key={itemProps.id}
                                                {...itemProps.linkProps}
                                            >
                                                <Button
                                                    aria-selected={itemProps.isActive}
                                                    minW="12rem"
                                                    w="full"
                                                    bg="none"
                                                    py={0}
                                                    px={6}
                                                    borderLeftWidth={3}
                                                    borderStyle="solid"
                                                    borderColor="transparent"
                                                    color="whiteAlpha.800"
                                                    _focus={{ outline: 'none' }}
                                                    _hover={{ bg: 'whiteAlpha.200' }}
                                                    _selected={{
                                                        bg: 'whiteAlpha.300',
                                                        borderColor: 'blue.300',
                                                    }}
                                                    borderRadius="none"
                                                    justifyContent="start"
                                                    // textAlign="right"
                                                    // justifyContent="end"
                                                >
                                                    {itemProps.label}
                                                </Button>
                                            </Link>
                                        );
                                    })}
                                </VStack>
                            </VStack>
                        </GridItem>
                    )}
                    <GridItem p={12}>
                        <Suspense
                            fallback={
                                <Center w="full" h="full">
                                    <CardSpinner />
                                </Center>
                            }
                        >
                            <ErrorBoundary
                                key={props.errorBoundaryProps.key}
                                fallback={(error) => (
                                    <Alert status="error">
                                        <AlertIcon />
                                        <AlertTitle>{error.error.message}</AlertTitle>
                                    </Alert>
                                )}
                            >
                                {!props.emptyStateProps && props.navigationListProps && (
                                    <VStack
                                        w="full"
                                        align="start"
                                        maxW="54rem"
                                        pb="12rem"
                                    >
                                        {props.children}
                                    </VStack>
                                )}
                                {!props.emptyStateProps && !props.navigationListProps && (
                                    <VStack
                                        w="full"
                                        align="start"
                                        maxW="72rem"
                                        pb="12rem"
                                        mx="auto"
                                    >
                                        {props.children}
                                    </VStack>
                                )}
                                {props.emptyStateProps && (
                                    <Center w="full" h="full" fontWeight="medium">
                                        <HStack w="fit-content">
                                            <VStack
                                                w="full"
                                                align="start"
                                                maxW="54rem"
                                                pb="12rem"
                                                spacing={4}
                                            >
                                                {iconProps && (
                                                    <Box
                                                        p={3}
                                                        borderRadius="md"
                                                        bg="whiteAlpha.300"
                                                    >
                                                        <Icon
                                                            h={6}
                                                            w={6}
                                                            as={iconProps.as}
                                                        />
                                                    </Box>
                                                )}
                                                <VStack
                                                    w="full"
                                                    align="start"
                                                    spacing={1}
                                                >
                                                    <Heading fontSize="lg">
                                                        {props.emptyStateProps.title}
                                                    </Heading>
                                                    {props.emptyStateProps
                                                        .description && (
                                                        <Text color="whiteAlpha.700">
                                                            {
                                                                props.emptyStateProps
                                                                    .description
                                                            }
                                                        </Text>
                                                    )}
                                                </VStack>
                                                {props.emptyStateProps.linkProps && (
                                                    <HStack
                                                        w="full"
                                                        color="blue.400"
                                                        _hover={{
                                                            textDecoration: 'underline',
                                                        }}
                                                    >
                                                        <ApplicationLink
                                                            {...props.emptyStateProps
                                                                .linkProps}
                                                        >
                                                            {
                                                                props.emptyStateProps
                                                                    .buttonProps?.children
                                                            }
                                                        </ApplicationLink>
                                                        <Icon as={AiOutlineArrowRight} />
                                                    </HStack>
                                                )}
                                            </VStack>
                                        </HStack>
                                    </Center>
                                )}
                            </ErrorBoundary>
                        </Suspense>
                    </GridItem>
                </Grid>
            </Box>
        );
    };
}

import React, { IframeHTMLAttributes, useEffect, useMemo, useState } from 'react';
import {
    Text,
    Heading,
    VStack,
    Box,
    Center,
    Link,
    Spacer,
    Container,
    Button,
    Image,
    useColorModeValue,
} from '@chakra-ui/react';
import { CopyIcon } from '@chakra-ui/icons';
import { useToast, Tooltip, Spinner } from '@chakra-ui/react';
import { useStore } from '../../../stores/setupContext';
import varosLogoSVG from '../../../svg/varos-logo-large.svg';
import varosLogoWhiteSVG from '../../../svg/varos-logo-large-white.svg';
import { RemoteIframe } from '../../../ui';
import {
    createIntegrationDefinitionRepository,
    createIntegrationRepository,
} from '../../../v2/app/assets';
import {
    createIntegrationDefinitionImpl,
    createIntegrationImpl,
} from '../../../v2/impl/assets';
import { createAssetApi } from '../../../v2/api';
import {
    getIntegrationDefinitionOverrides,
    getSupportedOnboardingIntegrations,
} from '../../../v2/config';
import axios from 'axios';
import { OrganizationScope } from '../../../v2';
import { IntegrationDefinition } from 'src/v2/domain/assets';
import { useNavigate } from 'react-router';

const repository = {
    integration: createIntegrationRepository(
        createIntegrationImpl({
            api: {
                asset: createAssetApi({
                    client: axios,
                }),
            },
        })
    ),
    definition: createIntegrationDefinitionRepository(
        createIntegrationDefinitionImpl({
            config: {
                overridesByIntegrationType: getIntegrationDefinitionOverrides(),
            },
            api: {
                asset: createAssetApi({
                    client: axios,
                }),
            },
        })
    ),
};

export interface NoPeerGroupsProps {
    mainTitle?: string;
    subTitle?: string;
    supportMsg?: string;
    supportMail?: string;
    connectButtonText?: string;
    redirect?: () => void;
}

const NoPeerGroups: React.FC<NoPeerGroupsProps> = ({
    mainTitle = 'Thank you for joining Varos!',
    subTitle = 'Your dashboard will be ready in up to 4 days',
    supportMsg = 'If you have any questions, please email',
    supportMail = 'support@varos.io',
    connectButtonText = 'Connect more data sources',
    redirect,
}) => {
    const toast = useToast();
    const { auth } = useStore();
    const bgColor = useColorModeValue('#E0E6EB', '#1D1C21');
    const linkColor = useColorModeValue('black', 'white');
    const titleColor = useColorModeValue('onboarding.title', 'white');
    const navigate = useNavigate();
    const logo = useColorModeValue(varosLogoSVG, varosLogoWhiteSVG);

    const store = useStore();

    // const context: OrganizationContextV2 = {
    //     organization: {
    //         id: store.auth.currentUser?.organizations?.[0].organization?.id!,
    //     },
    //     auth: {
    //         scheme: {
    //             kind: 'legacy',
    //             store: store.auth,
    //         },
    //     },
    // };

    // const query = {
    //     integration: repository.integration.useFind(context, {}),
    //     definition: repository.definition.useFind(context, {}),
    // };

    // const integrationTypes = getSupportedOnboardingIntegrations();

    // const definitionById = useMemo(
    //     () =>
    //         query.definition.data?.items.reduce(
    //             (acc, item) => ({
    //                 ...acc,
    //                 [item.id]: item,
    //             }),
    //             {} as Record<string, IntegrationDefinition | undefined>
    //         ) ?? {},
    //     [query.definition.data]
    // );

    // const supportedIntegrations = useMemo(
    //     () =>
    //         query.integration.data?.items.flatMap((item) => {
    //             const definition = definitionById[item.definitionId];
    //             if (!definition) {
    //                 return [];
    //             }
    //             if (!integrationTypes.includes(definition.kind)) {
    //                 return [];
    //             }
    //             return [{ integration: item, definition }];
    //         }) ?? [],
    //     [query.integration.data, definitionById]
    // );

    // useEffect(() => {
    //     if (supportedIntegrations.length > 0) {
    //         console.info('supported integrations connecfted, moving to v2 onboarding...');
    //         navigate('/u/onboarding');
    //         return;
    //     }
    // }, [supportedIntegrations]);

    return (
        <Container centerContent maxW="container.xl">
            <VStack
                w="full"
                h="full"
                p={10}
                spacing={8}
                border="1px"
                borderColor="frame"
                borderRadius={10}
                bg={bgColor}
                align="stretch"
            >
                <Center>
                    {/* Varos Logo SVG */}
                    <Box>
                        <Image src={logo} maxW="5rem" />
                    </Box>
                </Center>
                <VStack spacing={2} w="full">
                    <Heading textAlign="center" size="lg" color={titleColor}>
                        {mainTitle}
                    </Heading>
                    <Center>
                        <Text
                            textAlign="center"
                            size="lg"
                            fontSize="xl"
                            color="onboarding.subtitle"
                            wordBreak="break-word"
                            // maxW="50%"
                        >
                            {subTitle}
                        </Text>
                    </Center>
                </VStack>
                <Center>
                    {/* <Box bg="blackAlpha.900">
                        <a href="https://www.loom.com/share/df8d9bdbb8b0443ba0837b163a8b4228">
                            <img
                                style={{ maxWidth: '500px' }}
                                src="https://cdn.loom.com/sessions/thumbnails/df8d9bdbb8b0443ba0837b163a8b4228-1679400685056-with-play.gif"
                            />
                        </a>
                    </Box> */}
                    <RemoteIframe
                        bg="blackAlpha.900"
                        spinner={<Spinner color="whiteAlpha.900" />}
                        width="500"
                        height="300"
                        src="https://www.loom.com/embed/3ff3e930d77144b78d70d638ae396948?sid=7d13759d-ef15-4dca-9357-4234e7e8261e"
                        frameBorder="0"
                        allowFullScreen={true}
                    />
                </Center>
                <Center>
                    <Button
                        textColor="button.white"
                        boxShadow="lg"
                        bg="button.green"
                        _hover={{ bg: 'button.hoverGreen' }}
                        onClick={redirect}
                    >
                        {connectButtonText}
                    </Button>
                </Center>

                <Text textAlign="center" size="lg" fontSize="md" color="gray.500">
                    {supportMsg}
                    <Tooltip label="copy to clipboard">
                        <Link
                            fontWeight="medium"
                            color={linkColor}
                            onClick={() => {
                                navigator.clipboard.writeText(supportMail);
                                toast({
                                    status: 'success',
                                    title: `"${supportMail}" coppied to clipboard.`,
                                    duration: 2000,
                                    isClosable: true,
                                });
                            }}
                        >
                            {` ${supportMail} `}
                            {<Image as={CopyIcon} mx="2px" />}
                        </Link>
                    </Tooltip>
                </Text>
                <Spacer />
            </VStack>
        </Container>
    );
};
export default NoPeerGroups;

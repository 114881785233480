import React from 'react';
import { Box, Heading, HStack } from '@chakra-ui/react';
import { MenuLabelProps } from './MenuLabelProps';
import { applyLabelChildDefaults } from './MenuLabelHelper';

export const MenuListLabel: React.FC<MenuLabelProps> = ({ ...props }) => {
    return (
        <HStack
            minH={10}
            px={4}
            py={1.5}
            w="full"
            borderBottom="1px"
            borderColor="whiteAlpha.300"
            {...props.stackProps}
        >
            <Heading flex={1} size="xs" fontWeight="medium">
                {props.children}
            </Heading>
            {props.rightSection && (
                <Box>{applyLabelChildDefaults(props.rightSection)}</Box>
            )}
        </HStack>
    );
};

import React from 'react';
import { AnyInputProps } from '../formModel';
import { StringInput } from '../string';
import { NumberInput } from '../number';
import { SelectInput } from '../select';
import { RangeInput } from '../range';
import { TreeInput } from '../tree';

export const Input: React.FC<AnyInputProps> = (props) => {
    if (props.type === 'string') {
        return <StringInput {...props} />;
    }
    if (props.type === 'number') {
        return <NumberInput {...props} />;
    }
    if (props.type === 'range') {
        return <RangeInput {...props} />;
    }
    if (props.type === 'select') {
        return <SelectInput {...props} />;
    }
    if (props.type === 'tree') {
        return <TreeInput {...props} />;
    }
    throw new Error(`unexpected input type '${JSON.stringify(props)}'`);
};

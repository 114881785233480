"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AnyElementSchema = exports.ElementButtonSchema = void 0;
const zod_1 = require("zod");
exports.ElementButtonSchema = zod_1.z.object({
    kind: zod_1.z.literal('button'),
    variant: zod_1.z.union([zod_1.z.literal('primary'), zod_1.z.literal('secondary')]).optional(),
    label: zod_1.z.string(),
    action: zod_1.z.string(),
});
exports.AnyElementSchema = zod_1.z.discriminatedUnion('kind', [exports.ElementButtonSchema]);

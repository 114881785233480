import { action, computed, IReactionDisposer, makeObservable, observable, runInAction } from 'mobx';

import StoreBase from './StoreBase';
import { setPasswordRequest, setPasswordIdentify, setPasswordResponse, requestPasswordLessLogin } from './../api/auth';
import { ChangePasswordIdentifiedUser } from '../models/Account';

class ResetPasswordStore extends StoreBase {
    public isLoading: boolean = false;
    public token: string | null = null;
    public identifiedUser: ChangePasswordIdentifiedUser | null = null;
    public requested: boolean = false;
    public completed: boolean = false;

    constructor() {
        super();
        makeObservable(this, {
            // Observables
            isLoading: observable,
            token: observable,
            identifiedUser: observable,
            requested: observable,
            setToken: action,
            requestSetPassword: action,
            identifyPasswordChangeRequest: action,
            setNewPassword: action,
        });
    }

    start() {
        this.setToken(null);
        this.setIdentifiedUser(null);
    }

    setLoading(flag: boolean) {
        if (flag && this.err) {
            this.err = null;
        }
        this.isLoading = flag;
    }

    setRequested(req: boolean) {
        this.requested = req;
        if (req) {
            this.completed = false;
        }
    }

    setToken(token: string | null) {
        this.token = token;
    }
    setIdentifiedUser(usr: ChangePasswordIdentifiedUser | null) {
        this.identifiedUser = usr;
    }

    
    requestPasswordLessLogin = async (email: string, recaptchaToken: string) => {
        this.setLoading(true);
        this.setRequested(false);
        try {
            const { data } = await requestPasswordLessLogin(email, recaptchaToken);
            this.setRequested(true);
        } catch (e) {
            const isDone = await this.rootStore.auth.doCommonUnauthErrorHandling(e);
            if (isDone) {
                await this.requestSetPassword(email, recaptchaToken);
            } else {
                this.handleAxiosError(e)
            }
        } finally {
            this.setLoading(false);
        }
    };

    requestSetPassword = async (email: string, recaptchaToken: string) => {
        this.setLoading(true);
        this.setRequested(false);
        try {
            const { data } = await setPasswordRequest(email, recaptchaToken);
            this.setRequested(true);
        } catch (e) {
            const isDone = await this.rootStore.auth.doCommonUnauthErrorHandling(e);
            if (isDone) {
                await this.requestSetPassword(email, recaptchaToken);
            } else {
                this.handleAxiosError(e)
            }
        } finally {
            this.setLoading(false);
        }
    };

    identifyPasswordChangeRequest = async (token: string) => {
        this.setLoading(true);
        try {
            const { data } = await setPasswordIdentify(token);
            this.setToken(token);
            this.setIdentifiedUser(data);
        } catch (e) {
            const isDone = await this.rootStore.auth.doCommonUnauthErrorHandling(e);
            if (isDone) {
                await this.identifyPasswordChangeRequest(token);
            } else {
                if (e) this.handleAxiosError(e);
                else {
                    this.setErrType('serverErr');
                }
            }
        } finally {
            this.setLoading(false);
        }
    };

    setNewPassword = async (newPassword: string) => {
        if (!this.token) {
            return;
        }
        this.setLoading(true);
        try {
            await setPasswordResponse(this.token, newPassword);
            runInAction(() => {
                this.completed = true;
            });
        } catch (e) {
            const isDone = await this.rootStore.auth.doCommonUnauthErrorHandling(e);
            if (isDone) {
                await this.setNewPassword(newPassword);
            } else {
                if (e) this.handleAxiosError(e);
                else {
                    this.setErrType('serverErr');
                }
            }
        } finally {
            this.setLoading(false);
        }
    };
}

export default ResetPasswordStore;

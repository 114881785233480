import React from 'react';
import { CheckCircleIcon, PlusSquareIcon, MinusIcon, LockIcon } from '@chakra-ui/icons';
import {
    IconButton,
    Button,
    VStack,
    HStack,
    Icon,
    Text,
    Grid,
    GridItem,
    StackDivider,
    Tooltip,
    Box,
    Tabs,
    TabList,
    Tab,
    TabPanels,
    TabPanel,
} from '@chakra-ui/react';
import { FiPlus, FiMinus } from 'react-icons/fi';
import { InputGroup, Input } from '../../../ui/application/impl/forms';
import { SegmentItemControllerProps, SegmentListViewProps } from './segmentProps';
import { useSegment } from './segmentController';
import { SegmentItemController } from './segmentInterface';
import { AiOutlineInfoCircle } from 'react-icons/ai';

export function createSegmentListView(): React.FC<SegmentListViewProps> {
    return (props) => {
        return (
            <VStack
                {...props.style?.wrapper}
                divider={<StackDivider borderWidth="1px" />}
            >
                {props.filters.map((filter, index) => (
                    <React.Fragment key={filter.property.key}>
                        {props.renderItem(filter, index)}
                    </React.Fragment>
                ))}
            </VStack>
        );
    };
}

export function createSegmentItemContainer(
    controller: SegmentItemController
): React.FC<SegmentItemControllerProps> {
    return (props) => {
        const control = controller.useProps(props);
        const inputProps = control.getInputProps(props.filter);
        // console.log('inputProps', props.filter.property.key, inputProps);
        return (
            <InputGroup
                label={props.filter.property.name}
                rightContent={
                    <IconButton
                        borderRadius="sm"
                        minWidth={4}
                        width={4}
                        height={4}
                        aria-label="collapse"
                        size="xs"
                        variant="ghost"
                        icon={props.disclosure.isOpen ? <FiMinus /> : <FiPlus />}
                        onClick={props.disclosure.onToggle}
                        _focus={{ outline: 'none' }}
                    >
                        Open
                    </IconButton>
                }
            >
                {props.disclosure.isOpen && (
                    <Input {...props.style?.input} {...inputProps} />
                )}
            </InputGroup>
        );
    };
}

export const SegmentListView: React.FC<SegmentListViewProps> = (props) => {
    return (
        <VStack {...props.style?.wrapper} divider={<StackDivider borderWidth="1px" />}>
            {props.filters.map((filter, index) => (
                <React.Fragment key={filter.property.key}>
                    {props.renderItem(filter, index)}
                </React.Fragment>
            ))}
        </VStack>
    );
};

export const SegmentItemView: React.FC<SegmentItemControllerProps> = (props) => {
    const control = useSegment(props);
    const inputProps = control.getInputProps(props.filter);

    const label = (
        <HStack w="full" spacing={2}>
            <Text>{props.filter.property.name}</Text>
            {props.description && (
                <Tooltip
                    placement="top"
                    hasArrow={true}
                    p={3}
                    // bg="#1D1C21"
                    label={props.description}
                >
                    <Box>
                        <Icon fontSize="sm" as={AiOutlineInfoCircle} />
                    </Box>
                </Tooltip>
            )}
        </HStack>
    );

    if (props.isDisabled) {
        return (
            <InputGroup
                label={label}
                rightContent={
                    <Tooltip
                        placement="top"
                        hasArrow={true}
                        isDisabled={!props.disabledReason}
                        label={<Box p={2}>{props.disabledReason}</Box>}
                    >
                        <span>
                            <Icon
                                cursor="pointer"
                                opacity={0.5}
                                onClick={props.onDisabledClick}
                                color="whiteAlpha.900"
                                as={LockIcon}
                            />
                        </span>
                    </Tooltip>
                }
            >
                {props.disclosure.isOpen && (
                    <Box
                        onClick={props.onDisabledClick}
                        cursor={props.onDisabledClick ? 'pointer' : undefined}
                    >
                        <Input {...props.style?.input} {...inputProps} />
                    </Box>
                )}
            </InputGroup>
        );
    }
    return (
        <InputGroup
            label={label}
            rightContent={
                <IconButton
                    borderRadius="sm"
                    minWidth={4}
                    width={4}
                    height={4}
                    aria-label="collapse"
                    size="xs"
                    variant="ghost"
                    icon={props.disclosure.isOpen ? <FiMinus /> : <FiPlus />}
                    onClick={props.disclosure.onToggle}
                    _focus={{ outline: 'none' }}
                >
                    Open
                </IconButton>
            }
        >
            {props.disclosure.isOpen && <Input {...props.style?.input} {...inputProps} />}
        </InputGroup>
    );
};

import React from 'react';
import { useIntersection } from 'react-use';
// import { useIntersection } from './hooks';

export interface LazyLoadOptions extends IntersectionObserverInit {}

/**
 * Returns true if element has been visible in viewport at least once
 */
export function useLazyLoad(
    ref: React.RefObject<HTMLElement>,
    options: LazyLoadOptions = {}
) {
    // const rootMargin = threshold ? `-${threshold}px 0px 0px 0px` : undefined;
    const visible = useIntersection(ref, options);
    const [wasVisibleOnce, setWasVisibleOnce] = React.useState(false);
    React.useEffect(() => {
        if (visible?.isIntersecting) {
            setWasVisibleOnce(true);
        }
    }, [visible?.isIntersecting]);
    return { visible: visible?.isIntersecting || wasVisibleOnce };
    // return { visible: visible?.isIntersecting ?? false };
}

import React from 'react';
import { Authentication } from '../../../../api';
import { UseInviteUserFormHook } from './userInviteInterface';
import {
    UserInviteFormViewProps,
    InviteUserFormContainerProps,
} from './userInviteFormProps';

export function createUserInviteFormContainer(config: {
    hooks: {
        useAuth(): Authentication;
    };
    View: React.FC<UserInviteFormViewProps>;
    controller: UseInviteUserFormHook;
}): React.FC<InviteUserFormContainerProps> {
    const {
        hooks: { useAuth },
        View,
        controller,
    } = config;
    return (props) => {
        const auth = useAuth();
        
        const viewProps = controller.useProps({ auth }, props);
        return <View {...viewProps} />;
    };
}

import { CalendarIcon, CheckIcon, ChevronDownIcon } from '@chakra-ui/icons';
import {
    Button,
    ButtonGroup,
    Icon,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
} from '@chakra-ui/react';
import React, { useMemo } from 'react';
import ReactDatePicker from 'react-datepicker';
import { DatePickerViewImplProps } from './chakraDatePickerProps';

export function createDatePickerView(): React.FC<DatePickerViewImplProps> {
    return (props) => {
        return (
            <ButtonGroup isAttached={props.layout.container.isAttached ?? false}>
                {props.layout.relative && (
                    <Menu>
                        <MenuButton
                            flexShrink={0}
                            borderRight="1px solid"
                            borderColor="blackAlpha.500"
                            borderRightRadius="none"
                            as={Button}
                            _selected={{
                                bg: 'green.500',
                                white: 'white',
                            }}
                            // minW={32}
                            size="sm"
                            bg="#292929"
                            _focus={{ outline: 'none' }}
                            rightIcon={
                                <Icon fontSize="lg" w="1rem" as={ChevronDownIcon} />
                            }
                        >
                            {props.getPeriodLabel()}
                        </MenuButton>
                        <MenuList bg="#292929">
                            {props.getOptions().map((item) => {
                                const itemProps = props.getPeriodOptionProps(item);
                                return (
                                    <MenuItem
                                        aria-selected={itemProps.isSelected}
                                        _selected={{
                                            color: 'green.500',
                                        }}
                                        icon={
                                            <Icon
                                                as={CheckIcon}
                                                visibility={
                                                    itemProps.isSelected
                                                        ? 'inherit'
                                                        : 'hidden'
                                                }
                                            />
                                        }
                                        key={itemProps.label}
                                        onClick={itemProps.onClick}
                                        fontWeight="medium"
                                    >
                                        {itemProps.label}
                                    </MenuItem>
                                );
                            })}
                        </MenuList>
                    </Menu>
                )}
                {props.layout.absolute && (
                    <ReactDatePicker
                        calendarClassName="dark"
                        customInput={
                            <Button
                                borderLeftRadius="none"
                                w="full"
                                leftIcon={<Icon as={CalendarIcon} />}
                                size="sm"
                                bg="#292929"
                                _focus={{ outline: 'none' }}
                            >
                                {props.getAbsoluteLabel(props.value, true)}
                            </Button>
                        }
                        {...props.getRangeProps()}
                        selectsRange={true}
                    />
                )}
            </ButtonGroup>
        );
    };
}

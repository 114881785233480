import React from 'react';
import { Box, HStack, StackProps } from '@chakra-ui/react';

export interface WorkspaceLayoutNavigationProps extends Pick<StackProps, 'bg' | 'px'> {}

export const WorkspaceLayoutNavigation: React.FC<WorkspaceLayoutNavigationProps> = (
    props
) => (
    <Box bg={props.bg} px={props.px}>
        {props.children}
    </Box>
);

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ThreadCreatePayloadSchema = exports.ThreadListPayloadSchema = exports.ThreadListSchema = exports.ThreadObjectSchema = exports.ThreadTypeSchema = void 0;
const zod_1 = require("zod");
const entity_1 = require("../entity");
const list_1 = require("../list");
exports.ThreadTypeSchema = zod_1.z.union([zod_1.z.literal('survey'), zod_1.z.literal('analysis')]);
exports.ThreadObjectSchema = zod_1.z.object({
    id: zod_1.z.string(),
    object: zod_1.z.literal('thread'),
    type: exports.ThreadTypeSchema,
    created_at: zod_1.z.date({ coerce: true }),
});
exports.ThreadListSchema = (0, list_1.ListSchema)(exports.ThreadObjectSchema);
exports.ThreadListPayloadSchema = zod_1.z.object({});
exports.ThreadCreatePayloadSchema = zod_1.z.object({
    type: exports.ThreadTypeSchema,
    entities: zod_1.z.array(entity_1.EntityReferenceObjectSchema),
});

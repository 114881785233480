import { z } from 'zod';

export const OperatorSchema = z.enum([
    'gt',
    'gte',
    'lt',
    'lte',
    'in',
    'nin',
    'eq',
    'neq',
]);

export const AggregateTypeSchema = z.enum([
    'sum',
    'average',
    'count',
    'distribution',
    'percentile',
]);

export const ConditionSchema = z.object({
    key: z.string(),
    operator: OperatorSchema,
    value: z.any(),
    aggregate: AggregateTypeSchema.optional(),
});

export const ConditionGroupSchema = z.object({
    mode: z.enum(['and', 'or']),
    conditions: z.array(z.lazy(() => ConditionSchema)),
});

export const AnyConditionExprSchema = z.union([ConditionSchema, ConditionGroupSchema]);

import { ResourceBaseConfig, createEndpoint } from './support';
import { SdkConfig, SdkInit } from './sdkConfig';
import { ConversationSdk, ConversationSdkEnhancer } from './sdkInterface';
import { createThreadResource } from './thread';
import { createAgentResource } from './agent';
import { createRunResource } from './run';
import { createMessageResource } from './message';

export function createConversationSdk(init: SdkInit, enhancer?: ConversationSdkEnhancer) {
    return create(init, enhancer);
}

function create(config: SdkConfig, enhancer?: ConversationSdkEnhancer): ConversationSdk {
    if (enhancer) {
        return enhancer(create)(config);
    }
    const http = config.http({ baseURL: config.baseUrl });
    const endpoint = createEndpoint({ http });
    const resourceConfig: ResourceBaseConfig = { http, endpoint };

    return {
        thread: createThreadResource(resourceConfig),
        agent: createAgentResource(resourceConfig),
        run: createRunResource(resourceConfig),
        message: createMessageResource(resourceConfig),
    };
}

import { assert } from '../../../util/assert';
import { InsightEntity } from '../../../domain/insights';
import { VisualizableStrategy } from '../../../app/visualizations';
import { AnyVisualizationCreateProps } from '../../../domain/visualization';
import { VisualizableInsightConfig } from './visualizableInsightConfig';

export function createInsightVisualizable(
    config: VisualizableInsightConfig = {}
): VisualizableStrategy<InsightEntity> {
    return {
        toVisualization(item): AnyVisualizationCreateProps | null {
            // if (item.definition.visualization?.kind === 'comparison') {
            //     return null;
            // }
            if (!item.definition.visualization) {
                return null;
            }
            // if (item.definition.view) {
            //     console.log('DEBUG item.definition.visualization', item.definition);
            // }
            if (item.definition.visualization.kind === 'comparison') {
                return {
                    // @ts-expect-error
                    kind: item.definition.visualization.kind,
                    view: item.definition.view, // @ts-expect-error
                    metrics: item.definition.metrics.map((metric) => ({
                        id: [item.definition.plugin, metric.key].join('.'),
                    })),
                    granularity: item.definition.visualization.granularity,
                    period: {
                        start: item.period_start_at,
                        end: item.period_end_at,
                    },
                    filters: null,
                    ranking: {
                        kind: 'fixed',
                        comparison: item.definition.visualization.ranking.key,
                        cohorts: [
                            {
                                name: 'Low performers',
                                visible: false,

                                lower: 0,
                                upper: 33,
                            },
                            {
                                name: 'Median',
                                visible: false,

                                lower: 33,
                                upper: 66,
                            },
                            {
                                name: 'High performers',
                                visible: true,
                                lower: 66,
                                upper: 100,
                            },
                        ],
                    },
                    breakdown: item.definition.visualization.breakdown
                        ? {
                              properties:
                                  item.definition.visualization.breakdown.keys.map(
                                      (key) => ({ key })
                                  ),
                          }
                        : null,
                };
            }

            const [metric, ...rest] = item.definition.metrics;
            assert(metric, 'no metric found');
            assert(rest.length === 0, 'multiple metrics not supported');
            return {
                // @ts-expect-error
                kind: item.definition.visualization.kind,
                view: item.definition.view,
                metrics: [
                    {
                        id: [item.definition.plugin, metric.key].join('.'),
                    },
                ],
                granularity: item.definition.visualization.granularity,
                period: {
                    start: item.period_start_at,
                    end: item.period_end_at,
                },
                filters: null,
                ranking: {
                    kind: 'fixed',
                    comparison: item.definition.visualization.ranking.key,
                    cohorts: [
                        {
                            name: 'Low performers',
                            visible: false,
                            // visible:
                            //     item.definition.visualization.kind === 'comparison'
                            //         ? true
                            //         : false,
                            lower: 0,
                            upper: 33,
                        },
                        {
                            name: 'Median',
                            visible: false,
                            // visible:
                            //     item.definition.visualization.kind === 'comparison'
                            //         ? true
                            //         : false,
                            lower: 33,
                            upper: 66,
                        },
                        {
                            name: 'High performers',
                            visible: true,
                            // visible:
                            //     item.definition.visualization.kind === 'comparison'
                            //         ? true
                            //         : true,
                            lower: 66,
                            upper: 100,
                        },
                    ],
                },
                breakdown: item.definition.visualization.breakdown
                    ? {
                          properties: item.definition.visualization.breakdown.keys.map(
                              (key) => ({ key })
                          ),
                      }
                    : null,
            };
        },
    };
}

import React from 'react';
import { useLocation, useNavigate } from 'react-router';
import { ReportSelectRouteConfig } from './reportSelectConfig';
import { createReportSelectRouteView } from './reportSelectView';

export function createReportSelectRoute(config: ReportSelectRouteConfig): React.FC {
    const { hook, controller, loader } = config;
    const Component = createReportSelectRouteView(config);
    return (props) => {
        const navigate = useNavigate();
        const location = useLocation();
        const auth = hook.useAuth();
        const organization = hook.useOrganization();
        const context = {
            auth,
            organization,
        };
        const viewData = loader.report.useLoad(context, {});
        const viewForm = config.form.report.useForm(context, viewData);
        const viewProps = controller.report.useProps(
            { location, navigate },
            context,
            viewData,
            viewForm,
            {}
        );
        return <Component {...viewProps}>{props.children}</Component>;
    };
}

import { assert } from '../../../../util/assert';
import { ConversationStrategyLoaderConfig } from '../../base';
import { AgentInterviewLoader } from './agentInterviewInterface';

export function createInterviewAgentLoader(
    config: ConversationStrategyLoaderConfig
): AgentInterviewLoader {
    const {
        repository: { dataset: datasetRepository },
    } = config;
    return {
        useLoad(context, props) {
            console.log('loader props', props);
            const datasetQuery = datasetRepository.useLookup(context, props.dataset, {
                suspense: true,
            });
            assert(datasetQuery.status === 'success', 'expected suspense');
            return { dataset: datasetQuery.data };
        },
    };
}

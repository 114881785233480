import { z } from 'zod';

export const SeriesValueSchema = z.union([z.number(), z.null()]);

export const SeriesSchema = z.object({
    key: z.string(),
    label: z.string(),
    values: z.array(SeriesValueSchema),
    axis: z.string().nullable(),
    stack: z.string().nullable(),
    mode: z.string().nullable(),
});

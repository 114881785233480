import { z } from 'zod';
import { ConversationLayoutControllerProps } from './layout';

export const ConversationUrlParamSchema = z
    .object({
        return_path: z.string().optional(),
        type: z.union([z.literal('survey'), z.literal('assistant')]),
    })
    .transform(
        (item): Partial<ConversationLayoutControllerProps> => ({
            returnPath: item.return_path ?? null,
            agent: {
                id: item.type,
            },
        })
    )
    .pipe(
        z.object({
            returnPath: z.string().nullable(),
            agent: z.object({
                id: z.string(),
            }),
        })
    );

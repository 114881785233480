import {
    ListSchema,
    MessageCreatePayloadSchema,
    MessageListPayloadSchema,
    MessageObjectSchema,
} from '@varos/schema';
import { ResourceBaseConfig } from '../support/base';
import { MessageResource } from './messageInterface';

export function createMessageResource(config: ResourceBaseConfig): MessageResource {
    return {
        list(threadId, options) {
            return config.endpoint.list({
                url: `/messages`,
                query: {
                    thread: threadId,
                },
                schema: {
                    query: MessageListPayloadSchema,
                    response: ListSchema(MessageObjectSchema),
                },
                options,
            });
        },
        get(threadId, options) {
            return config.endpoint.retrieve({
                url: `/messages/${threadId}`,
                schema: {
                    response: MessageObjectSchema,
                },
                options,
            });
        },
        create(payload, options) {
            return config.endpoint.create({
                url: '/messages',
                payload: payload,
                schema: {
                    body: MessageCreatePayloadSchema,
                    response: MessageObjectSchema,
                },
                options,
            });
        },
    };
}

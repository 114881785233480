import { z } from 'zod';
import { AnyConditionExprSchema } from '../condition';

export const SegmentReducerAggregationSchema = z.literal('average');

export const SegmentReducerPercentileSchema = z.object({
    type: z.literal('percentile'),
    value: z.number(),
});

export const AnySegmentReducerSchema = z.union([
    SegmentReducerAggregationSchema,
    SegmentReducerPercentileSchema,
]);

export const SegmentBucketSchema = z.object({
    key: z.string(),
    reducer: AnySegmentReducerSchema,
    filter: AnyConditionExprSchema.optional(),
});

export const DimensionBreakdownSchema = z.object({
    kind: z.literal('dimension'),
    key: z.string(),
});

export const SegmentBreakdownSchema = z.object({
    kind: z.literal('segment'),
    buckets: z.array(SegmentBucketSchema),
});

export const AnyBreakdownSchema = z.discriminatedUnion('kind', [
    DimensionBreakdownSchema,
    SegmentBreakdownSchema,
]);

import React from 'react';
import { AvatarBadgeProps, Box } from '@chakra-ui/react';

export interface ButtonIndicatorProps extends Pick<AvatarBadgeProps, 'borderColor' | 'bgColor'> {}

export const ButtonIndicator: React.FC<ButtonIndicatorProps> = ({ bgColor = 'gray.500', ...props }) => {
    return (
        <Box
            as={'span'}
            color={'white'}
            position={'absolute'}
            top={'-6px'}
            right={'-6px'}
            backgroundClip="padding-box"
            fontSize={'0.8rem'}
            bgColor={bgColor}
            borderRadius={'50%'}
            p={'6px'}
            borderWidth={3}
            borderStyle="solid"
            {...props}
        />
    );
};

import { useDisclosure } from '@chakra-ui/react';
import React from 'react';
import { NetworkContextProvider } from '../base';
import {
    NetworkOverviewController,
    NetworkOverviewDeps,
    NetworkOverviewLoader,
} from './networkOverviewInterface';
import { NetworkOverviewViewProps } from './networkOverviewProps';

export function createNetworkOverviewRoute(
    context: NetworkContextProvider,
    controller: NetworkOverviewController,
    loader: NetworkOverviewLoader,
    View: React.FC<NetworkOverviewViewProps>
): React.FC {
    return (props) => {
        const viewContext = context.useContext();
        const viewData = loader.useLoad(viewContext);
        const viewDeps: NetworkOverviewDeps = {
            modal: {
                disclosure: useDisclosure(),
            },
        };
        const viewProps = controller.useProps(viewContext, viewDeps, viewData);
        return <View {...viewProps} />;
    };
}

import React, { useEffect, useMemo, useState } from 'react';
import {
    StackProps,
    TextProps,
    Text,
    VStack,
    RangeSliderProps,
    RangeSlider,
    RangeSliderTrack,
    RangeSliderFilledTrack,
    RangeSliderThumb,
    RangeSliderThumbProps,
} from '@chakra-ui/react';
import { BaseProps } from '../base';

export interface RangeInputProps
    extends BaseProps,
        Pick<
            RangeSliderProps,
            'defaultValue' | 'onChange' | 'onChangeEnd' | 'max' | 'min'
        > {
    style?: {
        wrapper?: Omit<StackProps, 'alignItems'>;
        label?: Omit<TextProps, 'children'>;
    };
    theme?: {
        label?: Omit<TextProps, 'children'>;
        thumb?: Omit<RangeSliderThumbProps, 'children' | 'index'>;
    };
    intervals: number[];
    type: 'range';
    variant: 'slider';
    value: [number, number] | null;
    defaultValue?: [number, number];
    steps?: number;
    isDisabled?: boolean;
    getLabel?(value: [number, number]): string;
    renderLabel?(label: string): React.ReactNode;
}

export const RangeInput: React.FC<RangeInputProps> = (props) => {
    const control = useInternal(props);
    const label =
        control.label && props.renderLabel ? (
            props.renderLabel(control.label)
        ) : (
            <Text fontSize="sm" {...props.style?.label}>
                {control.label}
            </Text>
        );
    return (
        <VStack {...props.style?.wrapper} alignItems="flex-start" spacing={2} pb={2}>
            {control.label && label}
            <RangeSlider {...control.getSliderProps()}>
                <RangeSliderTrack>
                    <RangeSliderFilledTrack />
                </RangeSliderTrack>
                <RangeSliderThumb
                    index={0}
                    _focus={{ outline: 'none' }}
                    // h={4}
                    // w={4}
                    // bg="white"
                    // borderColor="gray.300"
                    // shadow="none"
                    // _disabled={{ bg: 'white' }}
                    {...props.theme?.thumb}
                />
                <RangeSliderThumb
                    index={1}
                    _focus={{ outline: 'none' }}
                    // h={4}
                    // w={4}
                    // bg="white"
                    // borderColor="gray.300"
                    // shadow="none"
                    // _disabled={{ bg: 'white' }}
                    {...props.theme?.thumb}
                />
            </RangeSlider>
        </VStack>
    );
};

const useInternal = (props: RangeInputProps) => {
    const { intervals, steps: numberOfSteps = intervals.length } = props;
    var minp = 0;
    var maxp = numberOfSteps - 1;

    const indexMin = minp;
    const indexMax = intervals.length - 1;

    const minActual = intervals[0];
    const [maxActual] = intervals.slice().reverse();

    if (intervals.length < numberOfSteps) {
        throw new Error(`number of intervals most be greater than steps`);
    }

    const { intervalsRounded, intervalsMapping } = useMemo(() => {
        // const range = createLogInterval(maxp, props.min!, props.max!);
        // const intervals = range.map(roundToNearest);
        return {
            // range,
            intervalsRounded: intervals,
            intervalsMapping: intervals.reduce(
                (acc, value) => ({ ...acc, [value]: value }),
                {} as Record<string, number | undefined>
            ),
        };
        // }, [maxp, props.min, props.max]);
    }, [intervals, indexMin, indexMax]);

    // const valueStart = props.value?.[0] ?? props.min!;
    // const valueEnd = props.value?.[1] ?? props.max!;

    const valueStart = props.value?.[0] ?? minActual;
    const valueEnd = props.value?.[1] ?? maxActual;

    // const roundedStart = roundToNearest(valueStart);
    // const roundedEnd = roundToNearest(valueEnd);

    const roundedStart = valueStart;
    const roundedEnd = valueEnd;

    const matchedValueStart = intervalsMapping[valueStart];
    const matchedValueEnd = intervalsMapping[valueEnd];

    const initialValue = useMemo(() => {
        return [toIndex(roundedStart) ?? indexMin, toIndex(roundedEnd) ?? indexMax] as [
            number,
            number,
        ];
    }, [intervalsRounded, indexMin, indexMax]);

    // console.log('initial', initialValue, { indexMin, indexMax });

    const [valueLocal, setLocalValue] = useState<[number, number]>(() => initialValue);

    const [indexFrom, indexTo] = valueLocal;

    const valueMapped = {
        from: toValue(indexFrom) ?? minActual,
        to: toValue(indexTo) ?? maxActual,
    };

    // console.log('intervals', intervals);
    // console.log('values', ...valueLocal);
    // console.log('indices', indexFrom, indexTo);
    // console.log('mapped', valueMapped.from, valueMapped.to);

    useEffect(() => {
        setLocalValue([toIndex(valueStart) ?? indexMin, toIndex(valueEnd) ?? indexMax]);
    }, [valueStart, valueEnd, indexMin, indexMax]);

    const onChange: RangeSliderProps['onChange'] = ([from, to]) => {
        setLocalValue([from, to]);
        props.onChange?.([from, to]);
    };

    function toIndex(value: number) {
        const found = intervalsRounded.findIndex(
            // (candidate) => candidate === roundToNearest(value)
            (candidate) => candidate === value
        );
        if (found === -1) {
            // default to selcting the range start
            return null;
        }
        return found;
    }

    function toValue(index: number) {
        return intervalsRounded[index] ?? null;
    }

    if (valueMapped.from === null || valueMapped.to === null) {
        throw new Error(
            `values not mapped for indices ${indexFrom} and ${indexTo}. intervals: ${intervalsRounded.join(
                ', '
            )}`
        );
    }

    // console.log('matched', {
    //     intervalsMapping,
    //     matchedValueStart,
    //     matchedValueEnd,
    //     valueMapped,
    //     valueStart,
    //     valueEnd,
    // });

    return {
        label: props.getLabel?.([
            // we show the original, un-mapped value when no matching tick on the scale
            matchedValueStart ? valueMapped.from : valueStart,
            matchedValueEnd ? valueMapped.to : valueEnd,
        ]),
        getSliderProps(): Partial<RangeSliderProps> {
            return {
                // colorScheme: props.isDisabled ? undefined : props.colorScheme,
                colorScheme: props.colorScheme,
                isDisabled: props.isDisabled,
                minStepsBetweenThumbs: 1,
                min: minp,
                max: maxp,
                // value: valueLocal,
                value: props.value === null ? [indexMin, indexMax] : valueLocal,
                onChange,
                onChangeEnd([from, to]) {
                    const [mappedFrom, mappedTo] = [
                        toValue(from) ?? minActual,
                        toValue(to) ?? maxActual,
                    ];
                    props.onChangeEnd?.([mappedFrom, mappedTo]);
                },
            };
        },
    };
};

import { Icon } from '@chakra-ui/react';
import React from 'react';
import { IoIosWarning } from 'react-icons/io';
import { ApplicationUI } from '../../../../ui';
import { HomeMetricConfig } from '../homeMetricConfig';

export function createMetricFallbackContainer(
    config: HomeMetricConfig & { UI: ApplicationUI }
): React.FC {
    const {
        UI: { EmptyState },
    } = config;
    return (props) => {
        return (
            <EmptyState
                icon={<Icon as={IoIosWarning} />}
                title="No default metric collection found"
            />
        );
    };
}

import React, { useMemo } from 'react';
import {
    createRatingController,
    OpportunityCollectionController,
    OpportunityItemController,
} from '../../../view';
import { ApplicationEntryEnhancer } from '../../../entrypoint';
import { AnyInsightTrackingEvent } from '../../../strategy/tracking/insight/insightTrackingEvent';
import { OrganizationMappingController } from '../../../view/assets';
import { FeedbackEnhancerConfig } from '../feedbackConfig';
import { createInsightFeedbackView } from './insightFeedbackView';
import { createInsightFeedbackController } from './insightFeedbackController';
import { InsightFeedbackControllerProps } from './insightFeedbackProps';

export function createInsightFeedbackEnhancer(
    init: FeedbackEnhancerConfig
): ApplicationEntryEnhancer {
    const { getIntercom } = init;
    return (create) => (config) => {
        function useTracker() {
            const tracker = instance.infra.useTracker<AnyInsightTrackingEvent>();
            return tracker;
        }

        const ratingController = createInsightFeedbackController(
            useTracker,
            // HACK this should be properly configured through DI
            createRatingController()
        );
        const View = createInsightFeedbackView();

        function enhanceMappingController(
            controller: OrganizationMappingController
        ): OrganizationMappingController {
            return {
                ...controller,
                useProps(...args) {
                    const viewProps = controller.useProps(...args);
                    const Intercom = useMemo(() => getIntercom(), []);
                    return {
                        ...viewProps,
                        getDefinitionListProps() {
                            const listProps = viewProps.getDefinitionListProps();
                            return {
                                ...listProps,
                                onHelpClick(...args) {
                                    if (!Intercom) {
                                        console.info(
                                            `Intercom is not available, skipping...`
                                        );
                                        return listProps.onHelpClick(...args);
                                    }
                                    console.info('opening Intercom widget...');
                                    Intercom('show');
                                    return listProps.onHelpClick(...args);
                                },
                            };
                        },
                    };
                },
            };
        }

        function enhanceOpportunityCollectionController(
            controller: OpportunityCollectionController
        ): OpportunityCollectionController {
            const Container: React.FC<InsightFeedbackControllerProps> = (props) => {
                const viewProps = ratingController.useProps(props);
                return <View {...viewProps} />;
            };

            return {
                ...controller,
                useProps(...args) {
                    const viewProps = controller.useProps(...args);
                    const Intercom = useMemo(() => getIntercom(), []);
                    return {
                        ...viewProps,
                        getRouteProps(item) {
                            const routeProps = viewProps.getRouteProps(item);
                            if (routeProps.path) {
                                return routeProps;
                            }
                            return {
                                ...routeProps,
                                onClick() {
                                    if (!Intercom) {
                                        console.info(
                                            `Intercom is not available, skipping survey...`
                                        );
                                        return routeProps.onClick?.();
                                    }
                                    Intercom('startSurvey', 36391904);
                                    return routeProps.onClick?.();
                                },
                            };
                        },
                        getActionProps(item) {
                            const actionProps = viewProps.getActionProps(item);
                            return [
                                ...actionProps,
                                {
                                    variant: 'subtle',
                                    key: 'feedback',
                                    label: <Container item={item} />,
                                },
                            ];
                        },
                    };
                },
            };
        }

        function enhanceOpportunityItemController(
            controller: OpportunityItemController
        ): OpportunityItemController {
            const Container: React.FC<InsightFeedbackControllerProps> = (props) => {
                const viewProps = ratingController.useProps(props);
                return <View {...viewProps} />;
            };

            return {
                ...controller,
                useProps(...args) {
                    const [_context, props] = args;
                    const viewProps = controller.useProps(...args);
                    return {
                        ...viewProps,
                        actions: [
                            {
                                variant: 'subtle',
                                key: 'feedback',
                                label: <Container item={props.item} />,
                            },
                        ],
                    };
                },
            };
        }

        const instance = create({
            ...config,
            controller: {
                ...config.controller,
                assets: {
                    ...config.controller.assets,
                    createMapping(...args) {
                        const controller = config.controller.assets.createMapping(
                            ...args
                        );
                        return enhanceMappingController(controller);
                    },
                },
                insights: {
                    ...config.controller.insights,
                    opportunity: {
                        ...config.controller.insights.opportunity,
                        createCollection(...args) {
                            const controller =
                                config.controller.insights.opportunity.createCollection(
                                    ...args
                                );
                            return enhanceOpportunityCollectionController(controller);
                        },
                        createItem(...args) {
                            const controller =
                                config.controller.insights.opportunity.createItem(
                                    ...args
                                );
                            return enhanceOpportunityItemController(controller);
                        },
                    },
                },
            },
            module: {
                ...config.module,
                createInsightModule(moduleConfig) {
                    return config.module.createInsightModule({
                        ...moduleConfig,
                        provider: {
                            ...moduleConfig.provider,
                            createController(controllerConfig) {
                                const controller =
                                    moduleConfig.provider.createController(
                                        controllerConfig
                                    );
                                return {
                                    useProps(...args) {
                                        const viewProps = controller.useProps(...args);
                                        const Intercom = useMemo(() => getIntercom(), []);
                                        return {
                                            ...viewProps,
                                            getChildProps(item, index) {
                                                const childProps =
                                                    viewProps.getChildProps(item, index);
                                                return {
                                                    ...childProps,
                                                    onDetailClick() {
                                                        if (childProps.detailPath) {
                                                            // only trigger when no path has been specified
                                                            return childProps.onDetailClick();
                                                        }
                                                        if (!Intercom) {
                                                            console.info(
                                                                `Intercom is not available, skipping survey...`
                                                            );
                                                            return childProps.onDetailClick();
                                                        }
                                                        Intercom('startSurvey', 36391904);
                                                        return childProps.onDetailClick();
                                                    },
                                                };
                                            },
                                        };
                                    },
                                };
                            },
                        },
                    });
                },
            },
        });
        return instance;
    };
}

import React, { useEffect } from 'react';
import { Box, Button, LightMode, VStack } from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { useLocation, useNavigate } from 'react-router';

export interface SettingsLayoutProps {
    onBack(): void;
}

export const SettingsLayout: React.FC<SettingsLayoutProps> = ({ ...props }) => {
    return (
        <LightMode>
            <Box h="100%" bg="white" overflow="scroll">
                <VStack alignItems="flex-start">
                    <Box p={4}>
                        <Button
                            leftIcon={<ArrowBackIcon />}
                            colorScheme="gray"
                            variant="ghost"
                            onClick={props.onBack}
                        >
                            Back to app
                        </Button>
                    </Box>
                    <Box p={4} w="full" pb={16}>
                        {props.children}
                    </Box>
                </VStack>
            </Box>
        </LightMode>
    );
};

import { z } from 'zod';
import { AnyBreakdownSchema } from '../breakdown';
import { ComparisonSchema } from '../comparison';
import { AnyConditionExprSchema } from '../condition';
import { TimeGranularitySchema } from '../granularity';
import { AnyPeriodSchema } from '../period';

export const CreateVisualizationQuerySchema = z.object({
    granularity: TimeGranularitySchema.nullable().optional(),
    breakdown: z.array(AnyBreakdownSchema).nullable().optional(),
    period: AnyPeriodSchema.nullable().optional(),
    filter: AnyConditionExprSchema.nullable().optional(),
    comparison: ComparisonSchema.nullable().optional(),
});

import React from 'react';
import {
    Flex,
    SimpleGrid,
    HStack,
    Icon,
    Text,
    Image,
    ButtonProps,
    GridProps,
    UseDisclosureReturn,
    ModalContentProps,
    Button,
    Center,
    ListProps,
    HeadingProps,
    Grid,
    DividerProps,
    Divider,
    GridItem,
    List,
    ListItem,
    TextProps,
    Heading,
    Modal,
    ModalCloseButton,
    ModalOverlay,
    ModalContent,
    ModalBody,
} from '@chakra-ui/react';
import VarosIcon from '../../../../../svg/varos-icon.svg';
import Graphic from '../../../../../images/demo_cta_graphic.png';

export interface DemoFeatureModalViewStyle {
    emphasize?: Omit<TextProps, 'children'>;
    wrapper?: Omit<GridProps, 'children'>;
    heading?: Omit<HeadingProps, 'children'>;
    divider?: Omit<DividerProps, 'children'>;
    list?: Omit<ListProps, 'children'>;
    button?: Omit<ButtonProps, 'children'>;
    modal?: {
        content?: Omit<ModalContentProps, 'children'>;
    };
}

export interface DemoFeatureModalViewProps {
    style?: DemoFeatureModalViewStyle;
    disclosure: UseDisclosureReturn;
    redirectUrl: string;
    highlights: Array<{
        text: string;
    }>;
}

export const DemoFeatureModalView: React.FC<DemoFeatureModalViewProps> = (props) => {
    return (
        <Modal {...props.disclosure} isCentered={false} blockScrollOnMount={false}>
            <ModalOverlay />
            <ModalContent {...props.style?.modal?.content}>
                <ModalBody bg="white" p={0} m={0} overflow="hidden">
                    <ModalCloseButton onClick={props.disclosure.onClose} />
                    <SimpleGrid minChildWidth="450px">
                        <GridItem>
                            <Grid
                                gridTemplateRows="min-content min-content 1fr min-content"
                                {...props.style?.wrapper}
                            >
                                <GridItem>
                                    <Heading {...props.style?.heading}>
                                        <Text
                                            display="inline"
                                            {...props.style?.emphasize}
                                        >
                                            Sign up
                                        </Text>{' '}
                                        to access all the marketing and business
                                        benchmarks
                                    </Heading>
                                </GridItem>
                                <GridItem>
                                    <Divider {...props.style?.divider} />
                                </GridItem>
                                <GridItem>
                                    <List {...props.style?.list}>
                                        {props.highlights.map((highlight, index) => (
                                            <ListItem key={index}>
                                                <HStack
                                                    spacing={props.style?.wrapper?.gap}
                                                    alignItems="center"
                                                >
                                                    <Image
                                                        transform="rotate(45deg)"
                                                        position="relative"
                                                        top="1px"
                                                        w={4}
                                                        h={4}
                                                        src={VarosIcon}
                                                    />
                                                    <Text>{highlight.text}</Text>
                                                </HStack>
                                            </ListItem>
                                        ))}
                                    </List>
                                </GridItem>
                                <GridItem>
                                    <a href={props.redirectUrl} target="_blank">
                                        <Button {...props.style?.button}>
                                            Get Started Free
                                        </Button>
                                    </a>
                                </GridItem>
                            </Grid>
                        </GridItem>
                        <GridItem>
                            <Flex h="full">
                                <Image mt={{ lg: 16 }} src={Graphic} />
                            </Flex>
                        </GridItem>
                    </SimpleGrid>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

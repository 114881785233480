import { ConversationLayoutController } from './conversationLayoutInterface';

export function createConversationLayoutController(): ConversationLayoutController {
    return {
        useProps(context, item, props) {
            return {
                detail: {
                    container: {
                        isVisible: props.detail.value,
                    },
                    button: {
                        onClick: props.detail.onChange.bind(null, !props.detail.value),
                    },
                },
                link: {
                    back: { to: props.returnPath ?? '/u' },
                    home: { to: '/u' },
                },
            };
        },
    };
}

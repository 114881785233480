import React, { useState } from 'react';
import { ConversationContainerBaseConfig } from '../../base';
import {
    ConversationThreadController,
    ConversationThreadLoader,
} from './conversationThreadInterface';
import { ConversationThreadUrlParamSchema } from './conversationThreadSchema';
import { ConversationThreadStateValues } from './conversationThreadModel';
import {
    ConversationThreadContainerProps,
    ConversationThreadViewProps,
} from './conversationThreadProps';
import { ElementContext } from '../element';

export function createConversationThreadContainer(
    config: ConversationContainerBaseConfig,
    loader: ConversationThreadLoader,
    controller: ConversationThreadController,
    View: React.FC<ConversationThreadViewProps>
): React.FC<ConversationThreadContainerProps> {
    const {
        infra: {
            optionParser: { useOptions },
        },
        repository: { run: runRepository },
        context: { useContext },
    } = config;
    return ({ children, ...containerProps }) => {
        const [state, setState] = useState<ConversationThreadStateValues>({
            chat: { text: '' },
        });
        const context = useContext();
        const options = useOptions(ConversationThreadUrlParamSchema);

        const mutation = {
            create: runRepository.useCreate(context),
        };

        const isSubmitting = mutation.create.status === 'loading';
        const data = loader.useLoad(context, options);
        const props = controller.useProps(context, data, {
            status: isSubmitting ? 'processing' : 'settled',
            chat: {
                // NOTE only allow synchronized message
                isDisabled: isSubmitting,
                onSubmit(message) {
                    const response = mutation.create.mutate({
                        thread: data.thread,
                        message: {
                            blocks: [
                                {
                                    kind: 'text',
                                    content: message.text,
                                    modifiers: new Set(),
                                    attachments: [],
                                },
                            ],
                        },
                    });
                    return;
                },
                state: {
                    text: {
                        value: state.chat.text,
                        onChange(value) {
                            setState({ ...state, chat: { ...state.chat, text: value } });
                        },
                    },
                },
            },
        });
        return (
            <ElementContext.Provider
                value={{
                    onAction(element) {
                        const response = mutation.create.mutate({
                            thread: data.thread,
                            message: {
                                blocks: [
                                    {
                                        kind: 'event',
                                        data: {
                                            kind: 'action',
                                            id: element.action,
                                        },
                                    },
                                ],
                            },
                        });
                    },
                }}
            >
                <View {...props}>{children}</View>
            </ElementContext.Provider>
        );
    };
}

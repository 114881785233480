import React, { useState } from 'react';
import { Select } from 'chakra-react-select';
import { capitalize } from 'lodash';
import {
    InputGroup,
    FormErrorMessage,
    FormLabel,
    VStack,
    ModalHeader,
    ModalCloseButton,
    Modal,
    ModalContent,
    ModalBody,
    ModalFooter,
    ButtonGroup,
    ModalOverlay,
} from '@chakra-ui/react';
import { ApplicationUI } from '../../../../ui';
import { EditScheduleFormViewProps } from './scheduleEditProps';

export function createScheduleEditView(config: {
    UI: ApplicationUI;
}): React.FC<EditScheduleFormViewProps> {
    const {
        UI: { Button },
    } = config;
    return (props) => {
        return (
            <>
                <Modal
                    isOpen={props.isOpen}
                    onClose={props.onClose}
                    closeOnEsc={true}
                    closeOnOverlayClick={true}
                >
                    <ModalOverlay />
                    <ModalContent>
                        <form onSubmit={props.onSubmit}>
                            <ModalHeader>Edit report</ModalHeader>
                            <ModalCloseButton />
                            <ModalBody>
                                <InputGroup orientation="vertical" flexDirection="column">
                                    <FormLabel>Recipients</FormLabel>
                                    <Select
                                        isMulti={true}
                                        value={props.recipients.value}
                                        onChange={props.recipients.onChange}
                                        options={props.recipients.options}
                                    />
                                </InputGroup>
                                {props.error ? (
                                    <FormErrorMessage>
                                        {props.error.message}
                                    </FormErrorMessage>
                                ) : null}
                            </ModalBody>
                            <ModalFooter>
                                <ButtonGroup>
                                    <Button
                                        variant="ghost"
                                        size="sm"
                                        onClick={props.onClose}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        isLoading={props.formState.isSubmitting}
                                        type="submit"
                                        colorScheme="blue"
                                        variant="solid"
                                        size="sm"
                                    >
                                        Save report
                                    </Button>
                                </ButtonGroup>
                            </ModalFooter>
                        </form>
                    </ModalContent>
                </Modal>
            </>
        );
    };
}

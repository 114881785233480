import React from 'react';
import { Box, HStack } from '@chakra-ui/react';

export interface OrganizationLayoutContextProps {}

export const OrganizationLayoutContext: React.FC<OrganizationLayoutContextProps> = (
    props
) => (
    <HStack h="100%">
        {/* {props.brand && <Box>{props.brand}</Box>} */}
        <Box>{props.children}</Box>
    </HStack>
);

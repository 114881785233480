import { observer } from 'mobx-react';
import * as React from 'react';
import { useStore } from '../../../../stores/setupContext';
import { LoginForm, LoginProps } from '../../../../components/Login/Login';
import { Box, Flex, Center, Image } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';
import queryString from 'querystring';
import varosLogoSVG from '../../../../svg/varos-logo-large.svg';
// export interface LoginProps {
// //   checkoutStatus: CheckoutStore['checkoutStatus'];
// }

export const LoginWrapper: React.FunctionComponent<LoginProps> = ({ doLogin, isLoading, err }) => {
    return (
        <Flex justifyContent="center" alignItems="center" height="100%" flexDir="column">
            <Center mb={5}>
                <Box>
                    <Image src={varosLogoSVG} />
                </Box>
            </Center>

            {/* New Login Page */}
            <LoginForm {...{ doLogin, isLoading, err }} />
        </Flex>
    );
};

const Observed = observer(LoginWrapper);

function isStringArray(opt: string | string[]): opt is string[] {
    return Array.isArray(opt);
}

const WithStoreConnection = () => {
    const { auth } = useStore();
    const {
        //isStarted, authToken,
        loading: isLoading,
        err,
        login: doLogin,
        handloginResponse,
    } = auth;
    const { search } = useLocation();
    React.useEffect(() => {
        if (search && search.length > 0) {
            const token = queryString.parse(search.substring(1));
            if (token) {
                const { access_token, refresh_token, email, forward_to } = token;
                if (
                    email &&
                    access_token &&
                    refresh_token &&
                    !isStringArray(email) &&
                    !isStringArray(access_token) &&
                    !isStringArray(refresh_token)
                ) {
                    handloginResponse(
                        email,
                        {
                            access_token,
                            refresh_token,
                        },
                        isStringArray(forward_to) ? undefined : forward_to
                    );
                }
            }
        }
    }, [search]);

    // const history = useHistory();
    // React.useEffect(() => {
    //   if(isStarted && authToken) {
    //     history.push('/');
    //   }
    // }, [isStarted, authToken])
    return <Observed {...{ doLogin, isLoading, err }} />;
};

export default observer(WithStoreConnection);

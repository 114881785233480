import React from 'react';
import { ButtonProps, Image } from '@chakra-ui/react';
import { Button } from '../../../../ui';
import connectedSVG from '../../../../svg/connected.svg';

export interface IntegrationCardConnectedButtonProps extends Pick<ButtonProps, 'onClick' | 'disabled'> {}

export const IntegrationCardConnectedButton: React.FunctionComponent<IntegrationCardConnectedButtonProps> = ({
    onClick,
    ...props
}) => {
    return (
        <Button
            onClick={onClick}
            leftIcon={<Image src={connectedSVG} />}
            size="sm"
            variant="solid"
            colorScheme="varosGreen"
            {...props}
        >
            {props.children}
        </Button>
    );
};

import { assert } from '../../../util/assert';
import { ConversationThreadAdapter } from '../../../app/conversations';
import { ThreadImplConfig } from './threadImplConfig';
import { toThreadEntity } from './threadImplSerdes';

export function createThreadImpl(config: ThreadImplConfig): ConversationThreadAdapter {
    const {
        api: { conversation: api },
    } = config;

    return {
        async find(context, query) {
            throw new Error('not impl');
        },
        async lookup(context, query) {
            assert(context.auth.scheme.kind === 'legacy', 'expected legacy auth');
            assert(context.auth.scheme.store.authToken, 'missing token');
            const response = await api.thread.get(query.id, {
                token: context.auth.scheme.store.authToken,
            });
            return toThreadEntity(response);
        },
        async create(context, props) {
            assert(context.auth.scheme.kind === 'legacy', 'expected legacy auth');
            assert(context.auth.scheme.store.authToken, 'missing token');
            const response = await api.thread.create(
                {
                    type: 'survey',
                    entities: [
                        {
                            kind: 'dataset',
                            id: 'studies_creative_strategy',
                        },
                    ],
                },
                {
                    token: context.auth.scheme.store.authToken,
                }
            );
            return toThreadEntity(response);
        },
    };
}

import React from 'react';
import { useForm } from 'react-hook-form';
import { Navigate } from 'react-router-dom';
import { OrganizationMappingFormValues } from '../../../../../view/assets';
import { SettingAssetItemProviderConfig } from '../../../../../route';
import {
    SettingImportController,
    SettingImportDeps,
    SettingImportLoader,
} from './importListInterface';
import { SettingImportContainerProps } from './importListProps';
import { SettingImportConfig } from '../importConfig';

export function createSettingImportListContainer(
    init: Pick<SettingImportConfig, 'infra'>,
    config: Pick<SettingAssetItemProviderConfig, 'api'>,
    loader: SettingImportLoader,
    controller: SettingImportController
): React.FC<SettingImportContainerProps> {
    const {
        infra: { toaster },
    } = init;
    const {
        api: { useContext },
    } = config;

    return ({ as: Component, children, ...props }) => {
        const viewContext = useContext();
        const viewData = loader.useLoad(viewContext);
        const deps: SettingImportDeps = {};
        const viewProps = controller.useProps(viewContext, deps, viewData);
        const underlyingProps = viewProps.getViewProps();
        if (underlyingProps.items.length === 0) {
            return <Navigate to="new" replace={true} />;
        }
        return <Component.List {...viewProps}>{children}</Component.List>;
    };
}

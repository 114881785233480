import { useMemo } from 'react';
import { createTableController, TableControllerProps } from '../../../../view/common';
import { assert } from '../../../../util/assert';
import { DatasetEntryViewConfig } from '../entryConfig';
import { buildDatasetEntryItemProps, DatasetEntryItemViewProps } from '../item';
import { DatasetEntryListController } from './entryListInterface';
import { keyBy } from 'lodash';
import { buildTableControllerProps } from './entryListFactory';

export function createDatasetEntryListController(
    config: Pick<DatasetEntryViewConfig, 'infra'>
): DatasetEntryListController {
    const {
        infra: { formatter },
    } = config;
    const tableController = createTableController();
    return {
        useProps(context, props) {
            // const itemPropsById = useMemo(() => {
            //     return props.item.items.reduce(
            //         (acc, item) => ({
            //             ...acc,
            //             [item.entry.id]: buildDatasetEntryItemProps(formatter, item),
            //         }),
            //         {} as Record<string, DatasetEntryItemViewProps | undefined>
            //     );
            // }, [props.item.items]);

            const tableControllerProps = useMemo(() => {
                return buildTableControllerProps(formatter, props.item);
            }, [props.item]);

            const tableViewProps = tableController.useProps(tableControllerProps);

            return {
                // items: props.item.items,
                // getItemProps(item) {
                //     const itemProps = itemPropsById[item.entry.id];
                //     assert(itemProps, 'missing item props');
                //     return itemProps;
                // },
                getTableProps() {
                    return tableViewProps;
                },
            };
        },
    };
}

import {
    Box,
    Grid,
    Link as ChakraLink,
    GridItem,
    HStack,
    Icon,
    Input,
    InputGroup,
    InputLeftElement,
    StackDivider,
    Text,
    Tooltip,
    VStack,
    InputRightElement,
} from '@chakra-ui/react';
import React from 'react';
import { AiOutlinePlus, AiOutlineSearch } from 'react-icons/ai';
import { HiOutlineCube } from 'react-icons/hi';
import { SettingAccountItemProviderConfig } from '../../../../../route';
import { SettingAssetListViewProps } from './listProps';

export function createSettingAssetListView(
    config: SettingAccountItemProviderConfig
): React.FC<SettingAssetListViewProps> {
    const {
        UI: {
            Application: { Link, Heading, Button },
        },
        Layout: {
            Container,
            Content,
            Header: {
                Container: Header,
                Breadcrumbs: { List: BreadcrumbsList, Item: BreadcrumbsItem },
                Action: { List: ActionList },
            },
        },
    } = config;

    const AssetIcon = HiOutlineCube;

    return (props) => {
        const listProps = props.getListProps();
        const collectionProps = listProps.getCollectionProps();
        return (
            <Container>
                <Header>
                    <BreadcrumbsList>
                        <BreadcrumbsItem>Companies</BreadcrumbsItem>
                    </BreadcrumbsList>
                    <ActionList>
                        <Link id="settings_asset_new_link" to="new">
                            <Button
                                size="sm"
                                colorScheme="blue"
                                _focus={{ outline: 'none' }}
                                leftIcon={<Icon as={AiOutlinePlus} />}
                            >
                                New company
                            </Button>
                        </Link>
                    </ActionList>
                </Header>
                <Content>
                    <Box w="full">
                        <InputGroup w="full">
                            <InputLeftElement>
                                <Icon
                                    fontSize="xl"
                                    color="whiteAlpha.400"
                                    as={AiOutlineSearch}
                                />
                            </InputLeftElement>
                            <Input
                                {...listProps.getSearchProps()}
                                placeholder="Search companies"
                                py={1.5}
                                borderWidth={2}
                                _placeholder={{ fontWeight: 'medium' }}
                            />
                            {collectionProps.status !== 'empty' &&
                                collectionProps.label && (
                                    <InputRightElement minW="16rem" userSelect="none">
                                        <HStack w="full" justify="end" px={4}>
                                            <Text
                                                color="whiteAlpha.500"
                                                whiteSpace="nowrap"
                                                fontWeight="semibold"
                                                fontSize="sm"
                                            >
                                                {collectionProps.label}
                                            </Text>
                                        </HStack>
                                    </InputRightElement>
                                )}
                        </InputGroup>
                    </Box>
                    <VStack align="start" w="full" spacing={0}>
                        <HStack
                            bg="whiteAlpha.300"
                            w="full"
                            px={6}
                            // py={3}
                            h={10}
                            justify="space-between"
                        >
                            <Heading
                                textTransform="uppercase"
                                fontSize="xs"
                                fontWeight="bold"
                                color="whiteAlpha.700"
                                letterSpacing="wider"
                            >
                                Companies
                            </Heading>
                        </HStack>
                        <VStack
                            align="start"
                            w="full"
                            spacing={0}
                            fontWeight="medium"
                            divider={
                                <StackDivider
                                    borderWidth={1}
                                    borderColor="whiteAlpha.200"
                                />
                            }
                        >
                            <Grid templateColumns="1fr" gap={0} w="full">
                                {collectionProps.status === 'empty' ? (
                                    <GridItem>
                                        <HStack
                                            p={6}
                                            w="full"
                                            justify="center"
                                            spacing={3}
                                        >
                                            <Text
                                                fontWeight="medium"
                                                color="whiteAlpha.500"
                                            >
                                                {collectionProps.label}
                                            </Text>
                                        </HStack>
                                    </GridItem>
                                ) : null}
                                {props.items.map((item) => {
                                    const itemProps = props.getItemProps(item);
                                    const sourceProps = itemProps.getDataSourceProps();
                                    return (
                                        <GridItem key={itemProps.id}>
                                            <HStack
                                                borderBottomWidth={2}
                                                borderColor="whiteAlpha.300"
                                                borderStyle="solid"
                                                p={4}
                                                // px={4}
                                                w="full"
                                                justify="space-between"
                                            >
                                                <HStack w="full" spacing={4}>
                                                    <Box
                                                        bg="whiteAlpha.300"
                                                        p={2}
                                                        borderRadius="md"
                                                    >
                                                        <Icon
                                                            w={6}
                                                            h={6}
                                                            as={AssetIcon}
                                                        />
                                                    </Box>
                                                    <VStack
                                                        align="start"
                                                        fontWeight="medium"
                                                        spacing={0}
                                                        w="full"
                                                    >
                                                        <ChakraLink
                                                            as={Link}
                                                            {...itemProps.getLinkProps()}
                                                            _focus={{ outline: 'none' }}
                                                        >
                                                            <Text>{itemProps.title}</Text>
                                                        </ChakraLink>
                                                        <HStack
                                                            fontSize="sm"
                                                            color="whiteAlpha.700"
                                                            w="full"
                                                            spacing={1}
                                                            divider={
                                                                <Box
                                                                    color="whiteAlpha.700"
                                                                    border="none"
                                                                >
                                                                    &#x2022;
                                                                </Box>
                                                            }
                                                        >
                                                            <Text>
                                                                {itemProps.secondary}
                                                            </Text>
                                                            {sourceProps?.to && (
                                                                <ChakraLink
                                                                    as={Link}
                                                                    to={sourceProps.to}
                                                                    _focus={{
                                                                        outline: 'none',
                                                                    }}
                                                                >
                                                                    <Text whiteSpace="nowrap">
                                                                        {
                                                                            sourceProps.label
                                                                        }
                                                                    </Text>
                                                                </ChakraLink>
                                                            )}
                                                            {sourceProps &&
                                                                !sourceProps.to && (
                                                                    <Text
                                                                        cursor="default"
                                                                        whiteSpace="nowrap"
                                                                    >
                                                                        {
                                                                            sourceProps.label
                                                                        }
                                                                    </Text>
                                                                )}
                                                        </HStack>
                                                    </VStack>
                                                </HStack>
                                                <Tooltip
                                                    {...itemProps.getButtonTooltipProps()}
                                                    placement="top"
                                                    hasArrow={true}
                                                    p={3}
                                                    bg="#1D1C21"
                                                >
                                                    <Box as="span">
                                                        <Link
                                                            id="settings_asset_manage_link"
                                                            to={itemProps.path}
                                                        >
                                                            <Button
                                                                {...itemProps.getButtonProps()}
                                                                size="sm"
                                                                bg="whiteAlpha.300"
                                                                _hover={{
                                                                    bg: 'whiteAlpha.400',
                                                                }}
                                                                _focus={{
                                                                    outline: 'none',
                                                                }}
                                                            />
                                                        </Link>
                                                    </Box>
                                                </Tooltip>
                                            </HStack>
                                        </GridItem>
                                    );
                                })}
                            </Grid>
                        </VStack>
                    </VStack>
                </Content>
            </Container>
        );
    };
}

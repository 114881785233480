import React from 'react';
import { Navigate, Outlet, Route } from 'react-router';
import {
    createStudiesSubmissionListController,
    createStudiesSubmissionListLoader,
    createStudiesSubmissionListRoute,
    createStudiesSubmissionListView,
} from './submission/list';
import { ReportRouterConfig } from './reportConfig';
import { createStudiesExploreRoute } from './report/list';
import { createStudiesExploreController } from './report/list/studiesReportListController';
import { createStudiesExploreLoader } from './report/list/studiesReportListLoader';
import { createStudiesExploreView } from './report/list/studiesReportListView';
import {
    createStudiesSubmissionDetailController,
    createStudiesSubmissionDetailLoader,
    createStudiesSubmissionDetailRoute,
    createStudiesSubmissionDetailView,
    createStudiesSubmissionNewController,
    createStudiesSubmissionNewDepsProvider,
    createStudiesSubmissionNewLoader,
    createStudiesSubmissionNewRoute,
    createStudiesSubmissionNewView,
    createStudiesSubmissionService,
} from './submission';
import {
    createStudiesContextProvider,
    StudiesBaseContainerConfig,
    StudiesControllerBaseConfig,
    StudiesLoaderBaseConfig,
    StudiesViewBaseConfig,
} from './base';
import {
    createStudiesDeteilContextProvider,
    createStudiesResponseDetailLoader,
    createStudiesResponseDetailRoute,
    createStudiesResponseDetailContainer,
    createStudiesResponseDetailRouteController,
    createStudiesResponseDetailStateProvider,
    createStudiesResponseDetailView,
    createStudiesResponseItemContainer,
    createStudiesResponseItemController,
    createStudiesResponseItemLoader,
} from './response';
import {
    createStudiesLayoutController,
    createStudiesLayoutRoute,
    createStudiesLayoutView,
} from './layout';
import {
    createStudiesChartItemLoader,
    createStudiesChartRoute,
    createStudiesChartRouteController,
} from './chart';
import {
    createStudiesDetailController,
    createStudiesDetailLoader,
    createStudiesDetailRoute,
    createStudiesDetailView,
} from './report';
import {
    createSurveyDetailContainer,
    createSurveyDetailController,
    createSurveyDetailLoader,
    createSurveyDetailView,
} from './survey';

export function createReportRouter(config: ReportRouterConfig) {
    const {
        controller: {
            form: { field: fieldController, submission: submissionController },
        },
        provider: { createLayoutController },
    } = config;

    const controller = {
        layout: createLayoutController({
            items: [
                // {
                //     id: 'overview',
                //     title: 'Overview',
                //     path: `${config.mount}/overview`,
                // },
                // {
                //     id: 'studies',
                //     title: 'Explore',
                //     path: `${config.mount}/explore`,
                // },
                {
                    id: 'reports',
                    title: 'Reports',
                    path: `${config.mount}/reports`,
                },
                {
                    id: 'surveys',
                    title: 'Surveys',
                    path: `${config.mount}/submissions`,
                },
            ],
        }),
    };

    const contexts = {
        root: createStudiesContextProvider(config),
        detail: createStudiesDeteilContextProvider(),
    };

    const containerConfig: StudiesBaseContainerConfig = {
        infra: config.infra,
        context: contexts.root,
    };

    const loaderConfig: StudiesLoaderBaseConfig = {
        repository: config.repository,
    };

    const controllerConfig: StudiesControllerBaseConfig = {
        infra: config.infra,
        macro: config.macro,
        controller: config.controller,
        provider: config.provider,
    };

    const viewConfig: StudiesViewBaseConfig = {
        UI: config.UI,
    };

    const Layout = createStudiesLayoutRoute(
        contexts.root,
        createStudiesLayoutController({
            layout: controller.layout,
        }),
        createStudiesLayoutView(config)
    );

    const Submission = {
        List: createStudiesSubmissionListRoute(
            contexts.root,
            createStudiesSubmissionListController({
                controller: {
                    page: config.controller.layout.page,
                    study: {
                        submission: config.controller.submissions.list,
                    },
                },
            }),
            createStudiesSubmissionListLoader({
                repository: config.repository,
            }),
            createStudiesSubmissionListView({ ...config, UI: config.UI.Application })
        ),
        Detail: createStudiesSubmissionDetailRoute(
            config.infra.optionParser,
            contexts.root,
            createStudiesSubmissionDetailController({
                controller: {
                    page: config.controller.layout.page,
                    study: {
                        submission: config.controller.submissions.item,
                    },
                },
            }),
            createStudiesSubmissionDetailLoader({
                repository: config.repository,
            }),
            createStudiesSubmissionDetailView({ ...config, UI: config.UI.Application })
        ),
        New: createStudiesSubmissionNewRoute(
            config.infra.optionParser,
            contexts.root,
            createStudiesSubmissionNewDepsProvider(config),
            createStudiesSubmissionNewController({
                service: { route: createStudiesSubmissionService() },
                controller: {
                    submission: submissionController,
                    field: fieldController,
                    page: config.controller.layout.page,
                    form: config.controller.common.form,
                    invite: config.controller.invite.form,
                    group: {
                        option: config.provider.createOptionController({
                            id: 'studies_submission_group_option',
                        }),
                    },
                    type: {
                        option: config.provider.createOptionController({
                            id: 'studies_submission_type_option',
                        }),
                    },
                },
            }),
            createStudiesSubmissionNewLoader({
                repository: config.repository,
            }),
            createStudiesSubmissionNewView({ ...config, UI: config.UI })
        ),
    };

    const ResponseDetailContainer = createStudiesResponseDetailContainer(
        {
            root: contexts.root,
            child: contexts.detail,
        },
        createStudiesResponseDetailStateProvider(),
        createStudiesResponseDetailRouteController({
            ...config,
        }),
        createStudiesResponseDetailLoader({
            repository: config.repository,
        }),
        createStudiesResponseDetailView(config, {
            Item: createStudiesResponseItemContainer(
                contexts.root,
                createStudiesResponseItemLoader(config),
                createStudiesResponseItemController(config)
            ),
            Chart: createStudiesChartRoute(
                contexts.detail,
                createStudiesChartItemLoader(config),
                createStudiesChartRouteController(config)
            ),
        })
    );

    const Response = {
        Detail: {
            Container: ResponseDetailContainer,
            Route: createStudiesResponseDetailRoute(
                config.infra.optionParser,
                ResponseDetailContainer
            ),
        },
    };

    const Report = {
        List: createStudiesExploreRoute(
            contexts.root,
            createStudiesExploreController({
                ...config,
                controller: {
                    ...config.controller,
                    page: config.controller.layout.page,
                    list: config.controller.studies.list,
                    filter: config.controller.studies.filter,
                },
            }),
            createStudiesExploreLoader({
                repository: config.repository,
            }),
            createStudiesExploreView({ ...config })
        ),
        Detail: createStudiesDetailRoute(
            config.infra.optionParser,
            contexts.root,
            createStudiesDetailController({
                ...config,
                controller: {
                    ...config.controller,
                    page: config.controller.layout.page,
                },
            }),
            createStudiesDetailLoader({
                repository: config.repository,
            }),
            createStudiesDetailView({
                ...config,
                // Container: { Response: ResponseDetailContainer },
            })
        ),
    };

    const Survey = {
        Detail: createSurveyDetailContainer(
            containerConfig,
            createSurveyDetailLoader(loaderConfig),
            createSurveyDetailController(controllerConfig),
            createSurveyDetailView(viewConfig)
        ),
    };

    return (
        <Route
            path={config.mount}
            element={
                <Layout>
                    <Outlet />
                </Layout>
            }
        >
            <Route index={true} element={<Navigate to="reports" replace={true} />} />
            <Route path="reports">
                <Route index={true} element={<Report.List />} />
                <Route path=":reportId" element={<Report.Detail />} />
                <Route path=":reportId/data" element={<Response.Detail.Route />} />
            </Route>
            <Route path="surveys">
                <Route index={true} element={<Navigate to=".." replace={true} />} />
                <Route path=":surveyId" element={<Survey.Detail />} />
            </Route>
            <Route path="submissions">
                <Route index={true} element={<Submission.List />} />
                <Route path="new" element={<Submission.New />} />
                <Route path=":submissionId" element={<Submission.Detail />} />
            </Route>
        </Route>
    );
}

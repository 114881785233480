import React from 'react';
import { useLocation } from 'react-router';
import { ReportShellRouteConfig } from './reportShellConfig';
import { createReportShellController } from './reportShellController';

export function createReportShellRoute(
    config: ReportShellRouteConfig
): React.FC<React.PropsWithChildren<{}>> {
    const { Layout } = config;
    const controller = createReportShellController();
    return (props) => {
        const location = useLocation();
        const viewProps = controller.useProps({ location }, {});
        return (
            <Layout.Container>
                <Layout.Navigation.List>
                    {viewProps.links.map((item) => (
                        <Layout.Navigation.Link key={item.to} to={item.to}>
                            {item.label}
                        </Layout.Navigation.Link>
                    ))}
                </Layout.Navigation.List>
                <Layout.Content>{props.children}</Layout.Content>
            </Layout.Container>
        );
    };
}

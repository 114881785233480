import {
    Box,
    Popover,
    PopoverBody,
    PopoverContent,
    PopoverTrigger,
} from '@chakra-ui/react';
import React from 'react';
import { createOnboardingEntityControlView } from '../control';
import { createOnboardingEntityListView } from '../list';
import { OnboardingEntitySelectViewProps } from './entitySelectProps';

export function createOnboardingEntitySelectView(): React.FC<OnboardingEntitySelectViewProps> {
    const EntityListView = createOnboardingEntityListView();
    const EntityControlView = createOnboardingEntityControlView();
    return (props) => {
        return (
            <Popover
                {...props.getPopoverProps()}
                placement="bottom-end"
                size="lg"
                isLazy={true}
            >
                <PopoverTrigger>
                    <Box>
                        <EntityControlView {...props.control} />
                    </Box>
                </PopoverTrigger>
                <PopoverContent
                    minW="32rem"
                    border="1px solid"
                    borderColor="gray.300"
                    _focus={{ outline: 'none' }}
                >
                    <PopoverBody p={0} maxH="50vh" overflow="auto">
                        <EntityListView {...props.control} />
                    </PopoverBody>
                </PopoverContent>
            </Popover>
        );
    };
}

import React from 'react';
import {
    Alert,
    AlertDescription,
    AlertIcon,
    AlertTitle,
    Box,
    Button,
    Center,
    FormControl,
    FormLabel,
    Grid,
    GridItem,
    Heading,
    HStack,
    Icon,
    Image,
    Spinner,
    Stack,
    StackDivider,
    Tag,
    Text,
    Textarea,
    Tooltip,
    VStack,
} from '@chakra-ui/react';
import { PeerFilterListViewProps } from '../../../../../../view/peer';
import { NetworkRequestNewViewProps } from '../../networkRequestNewProps';

export function createRequestNewCandidateStatusView(): React.FC<NetworkRequestNewViewProps> {
    return (props) => {
        return (
            <VStack align="start" w="full">
                <VStack
                    w="full"
                    align="start"
                    p={6}
                    borderStyle="solid"
                    borderColor="whiteAlpha.300"
                    borderWidth={2}
                    borderRadius="lg"
                    spacing={2}
                >
                    <HStack w="full" justify="space-between" minH={10}>
                        {props.preview.isLoading && (
                            <Center w="full">
                                <Spinner size="md" speed="1s" />
                            </Center>
                        )}
                        {!props.preview.isLoading && (
                            <HStack w="full" spacing={4}>
                                <HStack w="full" spacing={4}>
                                    {/* {props.metric.input.current?.icon?.kind ===
                                        'image' && (
                                        <Box
                                            flexShrink={0}
                                            p={2.5}
                                            bg="whiteAlpha.300"
                                            borderRadius="md"
                                        >
                                            <Image
                                                h={5}
                                                w={5}
                                                src={props.metric.input.current.icon.src}
                                            />
                                        </Box>
                                    )} */}
                                    <VStack w="full" align="start" spacing={1}>
                                        <Text fontWeight="semibold" whiteSpace="nowrap">
                                            Availability
                                        </Text>
                                        {/* <Text color="whiteAlpha.700" maxW="16rem">
                                        Varos will match you with the most relevant
                                        companies within the specified cohort
                                    </Text> */}
                                    </VStack>
                                </HStack>
                                <Tooltip
                                    placement="top"
                                    hasArrow={true}
                                    p={4}
                                    bg="#1D1C21"
                                    isDisabled={props.preview.description === null}
                                    label={
                                        <VStack align="start" w="full" spacing={1}>
                                            <HStack w="full" spacing={3}>
                                                <Box
                                                    w={2}
                                                    h={2}
                                                    borderRadius="full"
                                                    bg={`${props.preview.colorScheme}.400`}
                                                />
                                                <Text fontWeight="semibold">
                                                    {props.preview.statusName}
                                                </Text>
                                            </HStack>
                                            <Text color="whiteAlpha.700">
                                                {props.preview.description}
                                            </Text>
                                        </VStack>
                                    }
                                >
                                    <Tag
                                        borderRadius="sm"
                                        fontSize="md"
                                        px={3}
                                        py={1}
                                        cursor="default"
                                        size="lg"
                                        flexShrink={0}
                                        fontWeight="semibold"
                                        colorScheme={props.preview.colorScheme}
                                    >
                                        <HStack w="full" spacing={3}>
                                            {props.preview.Icon && (
                                                <Icon as={props.preview.Icon} />
                                            )}
                                            <Text>{props.preview.statusName}</Text>
                                        </HStack>
                                    </Tag>
                                </Tooltip>
                            </HStack>
                        )}
                    </HStack>
                </VStack>
                <Text
                    fontSize="sm"
                    // fontStyle="italic"
                    color="whiteAlpha.600"
                    // fontWeight="medium"
                >
                    Varos will match you with the most relevant companies within the
                    specified cohort
                </Text>
            </VStack>
        );
    };
}

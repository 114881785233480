import React, { FormEventHandler, useMemo } from 'react';
import { Box, HStack } from '@chakra-ui/react';
import { useAuthenticatedContext } from '../../../../container';
import { getVisualizationProps } from '../../../../domain';
import {
    isBarVisualization,
    isTableVisualization,
    tableStrategy,
    isLineVisualization,
    isStatVisualization,
    statStrategy,
} from '../../../../strategy/visualization';
import {} from '../../../impl';
import { ChartViewProps } from './chartProps';
import { ChartViewConfig } from './chartConfig';

export function createChartView(config: ChartViewConfig): React.FC<ChartViewProps> {
    const { lineStrategy, barStrategy } = config;
    return (props) => {
        // console.log('rendeinrg chart', props.card.title);
        const context = useAuthenticatedContext();
        const visualization = props.visualization;

        const visualizationProps = useMemo(
            () => getVisualizationProps(props.response),
            [props.response]
        );
        const viewSchema = props.dashboard.viewSchemas.find(
            (x) => x.view == props.query.source.view
        );
        if (!viewSchema) {
            throw new Error(`cannot find schema for view ${props.query.source.view}`);
        }
        const { schema } = viewSchema;
        if (isTableVisualization(visualization)) {
            const tableProps = useMemo(() => {
                return tableStrategy(
                    context,
                    visualizationProps,
                    props.query,
                    props.response,
                    visualization,
                    { dashboard: props.dashboard, schema, card: props.card }
                );
            }, [props.card, props.response]);
            return props.renderTable(tableProps);
        }
        if (isLineVisualization(visualization)) {
            const lineProps = useMemo(() => {
                return lineStrategy(
                    context,
                    visualizationProps,
                    props.query,
                    props.response,
                    visualization,
                    { dashboard: props.dashboard, schema, card: props.card }
                );
            }, [props.card, props.response]);
            return props.renderLine(lineProps);
        }
        if (isStatVisualization(visualization)) {
            const lineProps = useMemo(() => {
                return statStrategy(
                    context,
                    visualizationProps,
                    props.query,
                    props.response,
                    visualization,
                    { dashboard: props.dashboard, schema, card: props.card }
                );
            }, [props.card, props.response]);
            return props.renderStat(lineProps);
        }
        if (isBarVisualization(visualization)) {
            const barProps = useMemo(() => {
                return barStrategy(
                    context,
                    visualizationProps,
                    props.query,
                    props.response,
                    visualization,
                    { dashboard: props.dashboard, schema, card: props.card }
                );
            }, [props.card, props.response]);
            return props.renderBar(barProps);
        }
        return <Box {...props.style?.wrapper}>not supported</Box>;
    };
}

import { RunObject } from '@varos/schema';
import { assert } from '../../../util/assert';
import {
    ConversationRunAdapter,
    ConversationRunEntity,
} from '../../../app/conversations';
import { ConversationRunImplConfig } from './runImplConfig';
import { toMessageEntity } from '../message';

export function createConversationRunImpl(
    config: ConversationRunImplConfig
): ConversationRunAdapter {
    const {
        api: { conversation: api },
    } = config;

    function toEntity(dto: RunObject): ConversationRunEntity {
        return {
            id: dto.id,
            messages: dto.messages.map(toMessageEntity),
        };
    }

    return {
        async create(context, props) {
            assert(context.auth.scheme.kind === 'legacy', 'expected legacy auth');
            assert(context.auth.scheme.store.authToken, 'missing token');
            const response = await api.run.create(
                {
                    thread: props.thread.id,
                    messages: [
                        {
                            role: 'user',
                            content: props.message.blocks.map((block) => {
                                if (block.kind === 'event') {
                                    return {
                                        kind: 'event',
                                        data: block.data,
                                    };
                                }
                                if (block.kind === 'text') {
                                    return {
                                        kind: 'text',
                                        text: block.content,
                                    };
                                }
                                throw new Error('not impl');
                            }),
                        },
                    ],
                },
                {
                    token: context.auth.scheme.store.authToken,
                }
            );
            return toEntity(response);
        },
    };
}

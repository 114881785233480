import { Box, Checkbox, CheckboxProps, useDisclosure } from '@chakra-ui/react';
import React from 'react';
import { IntegrationView } from '../../../components/Integrations/IntegrationsList';
import { Integration, IntegrationConf } from '../../../models/integration';
import {
    UserIntegrationDefinition,
    IntegrationCard,
    IntegrationCardProps,
    IntegrationCardAction,
    IntegrationCardActionProps,
    IntegrationCardButton,
    IntegrationModal,
    IntegrationCardStatus,
    AnyIntegrationStatus,
} from '../../../domain';
import { Asset } from '../../../api';
import { useIntegrationTheme } from '../../../hooks/integrations/hooks';

export interface IntegrationItemOrganizationViewProps
    extends Pick<IntegrationCardProps, 'containerProps'>,
        Omit<IntegrationCardActionProps, 'onConnect' | 'onReconnect' | 'renderButton' | 'theme'> {
    definition: UserIntegrationDefinition;
    reconnectInteg?: Integration;
    onConnect?: (integ: IntegrationConf) => void;
    onReconnect?: (def: IntegrationConf, integration: Integration) => void;
    onSelect?(): void;
    forceShowIgnore?: boolean;
    forceDisconnected?: boolean;
    status?: AnyIntegrationStatus;
}

export const IntegrationItemOrganizationView: React.FC<IntegrationItemOrganizationViewProps> = ({
    definition,
    forceDisconnected,
    ...props
}) => {
    const theme = useIntegrationTheme();
    const { isOpen, onOpen, onClose } = useDisclosure();

    const onConnectClick: IntegrationCardActionProps['onConnect'] = (event) => {
        event.stopPropagation();
        return props.onConnect?.(definition);
    };
    const onReconnectClick: IntegrationCardActionProps['onReconnect'] = () => {
        const [integration] = definition.integrations;
        if (!integration) {
            console.warn('reconnect clicked but no reconnectInteg passed to component');
            return;
        }
        return props.onReconnect?.(definition, integration);
    };

    return (
        <IntegrationCard
            containerProps={props.containerProps}
            definition={definition}
            theme={theme}
            status={props.status}
            statusText={props.status && <IntegrationCardStatus status={props.status} onClick={onOpen} />}
            action={
                <IntegrationCardAction
                    {...props}
                    theme={theme}
                    definition={definition}
                    forceDisconnected={forceDisconnected}
                    onConnect={onConnectClick}
                    onReconnect={onReconnectClick}
                    onConnectedClick={onOpen}
                    renderButton={(buttonProps) => (
                        <IntegrationCardButton.Connect {...buttonProps} theme={theme}>
                            {buttonProps.children}
                        </IntegrationCardButton.Connect>
                    )}
                />
            }
        >
            {props.onConnect && (
                <IntegrationModal isOpen={isOpen} onClose={onClose} definition={definition} onConnect={props.onConnect}>
                    {definition.integrations.map((integration) => (
                        <Box w="full" borderBottom="1px solid #46454A" key={integration.id}>
                            <IntegrationView
                                integration={integration}
                                integrationDefinition={definition}
                                connect={props.onConnect!}
                                reconnect={props.onReconnect!}
                            />
                        </Box>
                    ))}
                </IntegrationModal>
            )}
        </IntegrationCard>
    );
};

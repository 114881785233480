import React, { useContext } from 'react';
import { assert } from '../../../../util/assert';
import { InsightRouteConfigV2 } from '../insightRouteConfig';
import { InsightRouteContextV2 } from '../insightRouteContext';
import { createOpportunityRouteVisualizationController } from './visualizationController';
import { createOpportunityRouteVisualizationLoader } from './visualizationLoader';
import { OpportunityVisualizationContainerProps } from './visualizationProps';

export function createOpportunityVisualizationContainer(
    config: InsightRouteConfigV2
): React.FC<OpportunityVisualizationContainerProps> {
    const controller = createOpportunityRouteVisualizationController(config.deps);
    const loader = createOpportunityRouteVisualizationLoader(config.deps);
    return ({ as: Component, ...props }) => {
        const viewContext = useContext(InsightRouteContextV2);
        assert(viewContext, 'no context');
        const viewData = loader.useLoad(viewContext, props);
        const viewProps = controller.useProps(viewContext, viewData, props);
        return <Component {...viewProps} />;
    };
}

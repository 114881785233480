import React, { useEffect, useRef } from 'react';
import {
    Box,
    Grid,
    GridItem,
    HStack,
    StackDivider,
    Text,
    VStack,
    Button as ChakraButton,
    Icon,
    Tag,
    Image,
    Tooltip,
} from '@chakra-ui/react';
import { PluginItemViewProps } from './pluginProps';
import { OnboardingPeerGroupContainerConfig } from '../onboardingPeerGroupConfig';
import { TraitFilterViewProps, TraitPreviewViewProps } from '../../../../view/peer';

export function createPluginItemView(
    config: OnboardingPeerGroupContainerConfig,
    Component: {
        Filter: React.FC<TraitFilterViewProps>;
        Preview: React.FC<TraitPreviewViewProps>;
    }
): React.FC<PluginItemViewProps> {
    const { Filter: FilterView, Preview: PreviewView } = Component;
    return (props) => {
        const ref = useRef<HTMLDivElement>(null);
        useEffect(() => {
            if (props.isOpen) {
                ref.current?.scrollIntoView(true);
            }
        }, [props.isOpen]);
        return (
            <VStack w="full" position="relative">
                <Box ref={ref} pointerEvents="none" position="relative" top={-12} />
                <VStack
                    w="full"
                    bg="white"
                    borderRadius="lg"
                    border="1px solid"
                    borderColor="gray.300"
                    shadow="sm"
                    aria-selected={props.isOpen}
                    _selected={{ borderColor: 'blue.300' }}
                    spacing={0}
                    // divider={
                    //     props.isOpen ? (
                    //         <StackDivider borderColor="gray.300"></StackDivider>
                    //     ) : undefined
                    // }
                >
                    <HStack
                        position="sticky"
                        top={0}
                        bg="white"
                        zIndex={5}
                        // onClick={props.onSelect}
                        w="full"
                        borderTopRadius="lg"
                        borderBottomRadius={props.isOpen ? undefined : 'lg'}
                        justify="space-between"
                        // cursor="pointer"
                        // _hover={{ bg: 'gray.50' }}
                        p={6}
                        borderBottom="1px solid"
                        borderColor="gray.300"
                    >
                        <HStack
                            userSelect="none"
                            // cursor="pointer"
                            spacing={6}
                            align="center"
                        >
                            <Box p={3} bg="gray.100" borderRadius="md">
                                <Image w={6} h={6} src={props.plugin.iconUrl!} />
                            </Box>
                            <VStack align="start" spacing={0}>
                                <Text fontSize="lg" fontWeight="bold">
                                    {props.plugin.name}
                                </Text>
                                {!props.isOpen && (
                                    <Text fontWeight="medium" color="gray.500">
                                        {props.previewProps.matchedText}
                                    </Text>
                                )}
                            </VStack>
                        </HStack>
                        <HStack spacing={3}>
                            {props.isOpen && (
                                <ChakraButton
                                    {...props.getCancelButtonProps()}
                                    bg="white"
                                    size="md"
                                    borderColor="gray.300"
                                    borderWidth="1px"
                                    borderStyle="solid"
                                >
                                    Close
                                </ChakraButton>
                            )}
                            {props.isOpen ? (
                                <>
                                    <ChakraButton
                                        {...props.getSubmitButtonProps()}
                                        colorScheme="blue"
                                        size="md"
                                    >
                                        Save changes
                                    </ChakraButton>
                                </>
                            ) : null}
                            {!props.isOpen &&
                                // !props.peergroup.default &&
                                props.showStatus && (
                                    <Tooltip
                                        hasArrow={true}
                                        placement="top"
                                        isDisabled={!props.previewProps.description}
                                        label={
                                            <Box p={2}>
                                                {props.previewProps.description}
                                            </Box>
                                        }
                                    >
                                        <Tag
                                            // size="md"
                                            whiteSpace="nowrap"
                                            px={4}
                                            h={8}
                                            colorScheme={props.previewProps.colorScheme}
                                            fontWeight="semibold"
                                            minW={32}
                                        >
                                            <HStack
                                                w="full"
                                                justify="center"
                                                align="center"
                                                spacing={2}
                                            >
                                                {props.previewProps.Icon && (
                                                    <Icon
                                                        fontSize="lg"
                                                        as={props.previewProps.Icon}
                                                    />
                                                )}
                                                <Text>
                                                    {props.previewProps.statusName}
                                                </Text>
                                            </HStack>
                                        </Tag>
                                    </Tooltip>
                                )}

                            {!props.isOpen ? (
                                <ChakraButton
                                    bg="white"
                                    size="md"
                                    borderColor="gray.300"
                                    borderWidth="1px"
                                    borderStyle="solid"
                                    onClick={props.onOpenClick}
                                >
                                    {props.getOpenButtonText()}
                                </ChakraButton>
                            ) : null}
                        </HStack>
                    </HStack>
                    <Grid
                        w="full"
                        templateColumns="minmax(0, 1fr) minmax(0, 2fr)"
                        gap={{ base: 12, '2xl': 16 }}
                        p={6}
                        display={props.isOpen ? undefined : 'none'}
                        position="relative"
                    >
                        <GridItem>
                            <FilterView {...props.getFilterProps()} />
                        </GridItem>
                        <GridItem>
                            <VStack
                                align="start"
                                spacing={6}
                                w="full"
                                position="sticky"
                                top={32}
                            >
                                <VStack
                                    w="full"
                                    align="start"
                                    // bg="white"
                                    p={6}
                                    borderRadius="md"
                                    border="1px solid"
                                    // borderColor="blue.400"
                                    borderColor="gray.300"
                                    // boxShadow="md"
                                >
                                    <PreviewView {...props.getPreviewProps()} />
                                </VStack>
                            </VStack>
                        </GridItem>
                    </Grid>
                </VStack>
            </VStack>
        );
    };
}

import { Checkbox, Box, Flex, HStack, StackProps, Text } from '@chakra-ui/react';
import { useTheme, useToken } from '@chakra-ui/system';
import React from 'react';
import { Tooltip } from '../../tooltip';

export interface LegendSeries {
    id: string;
    label: string;
    description?: string | null;
    colorScheme: string;
}

export interface LegendProps {
    height: number;
    width: number;
    containerProps?: StackProps;
    series: LegendSeries[];
    visible?: string[];
    onVisibleChange?(visible: string[]): void;
}

export const ChartLegend: React.FC<LegendProps> = ({ ...props }) => {
    const theme = useTheme();
    const colorsLegend = theme.colors.legend;

    const handleLegendClick = (legend: LegendSeries) => {
        const isVisible = props.visible?.includes(legend.id);
        const applied = isVisible
            ? props.visible?.filter((label) => label !== legend.id) ?? []
            : [...(props.visible ?? []), legend.id];
        props.onVisibleChange?.(applied);
    };

    const isVisible = (dataset: LegendSeries) => {
        return props.visible?.includes(dataset.label);
    };

    const isHidden = (dataset: LegendSeries) => {
        return !isVisible(dataset);
    };

    const containerProps = {
        ...props.containerProps,
        height: props.height * 0.15,
        width: props.width,
        mt: 4,
        mb: -2,
        spacing: 4,
    };

    return (
        <Flex justifyContent="center" {...containerProps}>
            <HStack spacing={props.containerProps?.spacing}>
                {props.series.map((dataset) => {
                    const showTooltip = !!dataset.description;
                    return (
                        <Tooltip.Tooltip
                            key={dataset.label}
                            label={
                                <Tooltip.Container>
                                    <HStack spacing={2}>
                                        <Box
                                            flexShrink={0}
                                            bg={colorsLegend[dataset.colorScheme]?.[200]}
                                            height={2.5}
                                            width={2.5}
                                            borderRadius="full"
                                        />
                                        <Tooltip.Title>{dataset.label}</Tooltip.Title>
                                    </HStack>
                                    {dataset.description && (
                                        <Tooltip.Text>{dataset.description}</Tooltip.Text>
                                    )}
                                </Tooltip.Container>
                            }
                            isDisabled={!showTooltip}
                        >
                            <HStack
                                key={dataset.label}
                                spacing={2}
                                opacity={isHidden(dataset) ? 0.5 : 1}
                                onClick={handleLegendClick.bind(null, dataset)}
                                cursor="pointer"
                            >
                                <Checkbox
                                    colorScheme={`legend.${dataset.colorScheme}`}
                                    pointerEvents="none"
                                    isChecked={isVisible(dataset)}
                                />
                                <Text userSelect="none" fontSize="sm">
                                    {dataset.label}
                                </Text>
                            </HStack>
                        </Tooltip.Tooltip>
                    );
                })}
            </HStack>
        </Flex>
    );
};

import React from 'react';
import { z } from 'zod';
import { createConversationAgent } from '../../../../route';
import { AgentInterviewConfig } from './agentInterviewConfig';
import { createAgentInterviewContainer } from './agentInterviewContainer';
import { createAgentInterviewController } from './agentInterviewController';
import { createInterviewAgentLoader } from './agentInterviewLoader';
import { AgentInterviewContainerProps } from './agentInterviewProps';
import { createAgentInverviewView } from './agentInterviewView';

export function createConversationInterviewAgent(init: AgentInterviewConfig) {
    return createConversationAgent({
        type: 'survey',
        schema: z
            .object({
                survey: z.string(),
            })
            .transform(
                (item): AgentInterviewContainerProps => ({
                    dataset: {
                        id: item.survey,
                    },
                })
            )
            .pipe(
                z.object({
                    dataset: z.object({
                        id: z.string(),
                    }),
                })
            ),
        create(config) {
            const Component = createAgentInterviewContainer(
                config,
                createInterviewAgentLoader({
                    repository: init.repository,
                }),
                createAgentInterviewController(),
                createAgentInverviewView(config)
            );
            return {
                Component(props) {
                    return <Component {...props.data} />;
                },
            };
        },
    });
}

import { observer } from 'mobx-react';
import React from 'react';
import { DateController, DateStateProvider } from './dateInterface';
import { AnalysisDateControllerProps, AnalysisDateViewProps } from './dateProps';

export function createDateContainer(
    states: DateStateProvider,
    controller: DateController,
    Component: React.FC<AnalysisDateViewProps>
): React.FC<AnalysisDateControllerProps> {
    return observer((props) => {
        // const state = states.useState(props.context);
        const viewProps = controller.useProps(props.data, props);
        return <Component {...viewProps} />;
    });
}

import React from 'react';
import { Box, StackProps, VStack, Flex, Container } from '@chakra-ui/react';

export interface MinimalLayoutContentProps extends Pick<StackProps, 'spacing'> {}

export const MinimalLayoutContent: React.FC<MinimalLayoutContentProps> = ({ ...props }) => {
    return (
        <Box w="full" h="full">
            <VStack spacing={props.spacing}>{props.children}</VStack>
        </Box>
    );
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AgentListPayloadSchema = exports.AgentObjectSchema = void 0;
const zod_1 = require("zod");
exports.AgentObjectSchema = zod_1.z.object({
    id: zod_1.z.string(),
    object: zod_1.z.literal('agent'),
    name: zod_1.z.string(),
    created_at: zod_1.z.date({ coerce: true }),
});
exports.AgentListPayloadSchema = zod_1.z.object({});

import React from 'react';
import { LineVisualizationV2 } from '../../../../../strategy';
import { LineChartControllerProps } from '../../../../ui/visualization/line';
import { createLineChartController } from './lineController';

export function createLineChart(): React.FC<LineChartControllerProps> {
    const controller = createLineChartController();
    return (props) => {
        const viewProps = controller.useProps(props);
        return <LineVisualizationV2 {...viewProps} />;
    };
}

export interface ErrorInfo {
    name: string | null;
    description: string;
    hint: string | null;
}

export class ApplicationError extends Error {
    constructor(public kind: string, message?: string) {
        super(message);
        Object.setPrototypeOf(this, ApplicationError.prototype);
        this.name = 'ApplicationError';
    }
}

export class ApiError extends Error {
    public readonly statusCode?: number;

    constructor(message?: string, statusCode?: number) {
        super(message);
        Object.setPrototypeOf(this, ApiError.prototype);
        this.statusCode = statusCode
    }
}

export class RateLimitError extends ApiError {
    constructor(message?: string) {
        super(message);
        Object.setPrototypeOf(this, RateLimitError.prototype);
    }
}

import React from 'react';
import { StudiesContextProvider } from '../base';
import { StudiesLayoutController } from './studiesLayoutInterface';
import {
    StudiesLayoutRouteContainerProps,
    StudiesLayoutRouteViewProps,
} from './studiesLayoutProps';

export function createStudiesLayoutRoute(
    contexts: StudiesContextProvider,
    controller: StudiesLayoutController,
    View: React.FC<StudiesLayoutRouteViewProps>
): React.FC<StudiesLayoutRouteContainerProps> {
    return (props) => {
        const viewContext = contexts.useContext();
        const viewProps = controller.useProps(viewContext, { layout: props });
        return <View {...viewProps}>{props.children}</View>;
    };
}

import {
    Alert,
    Link as ChakraLink,
    AlertDescription,
    AlertIcon,
    Box,
    FormControl,
    FormControlProps,
    FormErrorMessage,
    FormLabel,
    Grid,
    GridItem,
    HStack,
    Icon,
    Input,
    InputGroup,
    Radio,
    Slider,
    SliderFilledTrack,
    SliderThumb,
    SliderTrack,
    StackDivider,
    Switch,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Tag,
    Text,
    Tooltip,
    VStack,
    AlertTitle,
    Modal,
    ModalContent,
    ModalBody,
    ModalHeader,
    ModalFooter,
    ModalOverlay,
} from '@chakra-ui/react';
import React from 'react';
import { AiOutlineInfoCircle, AiOutlinePlus } from 'react-icons/ai';
import { HiOutlineCube } from 'react-icons/hi';
import {
    SubscriptionEditAddonItemProps,
    SubscriptionEditUsageItemProps,
    PlanSelectItemProps,
} from '../../../../../../view/billing';
import { SettingAccountItemProviderConfig } from '../../../../../../route';
import { SettingSubscriptionEditViewProps } from './subscriptionEditProps';
import { BsArrowRight } from 'react-icons/bs';
import { FaArrowRight } from 'react-icons/fa';

export function createSettingSubscriptionEditView(
    config: SettingAccountItemProviderConfig
): React.FC<SettingSubscriptionEditViewProps> {
    const {
        UI: {
            Application: { Anchor, Link, Heading, Button },
        },
        Layout: {
            Container,
            Content,
            Panel,
            Form,
            Header: {
                Action: { List: ActionList },
                Container: Header,
                Breadcrumbs: { List: BreadcrumbsList, Item: BreadcrumbsItem },
            },
        },
    } = config;

    const FormControl: React.FC<
        FormControlProps & { helperText?: React.ReactNode; secondary?: React.ReactNode }
    > = (props) => {
        return (
            <VStack align="start" w="full" spacing={4}>
                <VStack align="start" spacing={1}>
                    <HStack w="full" justify="space-between">
                        <Box color="whiteAlpha.800" fontWeight="semibold">
                            {props.label}
                        </Box>
                        {props.secondary && <Box>{props.secondary}</Box>}
                    </HStack>
                    {props.helperText && (
                        <Box fontWeight="medium" color="whiteAlpha.600">
                            {props.helperText}
                        </Box>
                    )}
                </VStack>
                {props.children}
            </VStack>
        );
    };

    const PlanItem: React.FC<PlanSelectItemProps> = (props) => {
        return (
            <HStack
                justify="space-between"
                cursor="pointer"
                aria-selected={props.isSelected}
                onClick={props.onSelectClick}
                p={4}
                spacing={4}
                borderRadius="lg"
                borderWidth={2}
                borderStyle="solid"
                borderColor="whiteAlpha.300"
                _hover={{
                    borderColor: 'whiteAlpha.400',
                }}
                _selected={{
                    borderColor: 'blue.400',
                }}
            >
                <HStack spacing={3}>
                    <Box p={2} bg="whiteAlpha.300" borderRadius="md">
                        <Icon h={5} w={5} as={HiOutlineCube}></Icon>
                    </Box>
                    <VStack align="start" spacing={0} userSelect="none">
                        <Text>{props.name}</Text>
                        <Text noOfLines={1} color="whiteAlpha.700">
                            {props.description}
                        </Text>
                    </VStack>
                </HStack>
                <Box>
                    {!props.isCurrent && <Radio isChecked={props.isSelected} />}
                    {props.isCurrent && (
                        <Tag colorScheme="blue" size="md">
                            Current
                        </Tag>
                    )}
                </Box>
            </HStack>
        );
    };

    const UsageItem: React.FC<SubscriptionEditUsageItemProps> = (props) => {
        return (
            <Box
                aria-selected={props.isSelected}
                cursor="pointer"
                onClick={props.onSelectClick}
                borderWidth={2}
                borderColor="whiteAlpha.300"
                borderStyle="solid"
                borderRadius="md"
                _hover={{
                    borderColor: 'whiteAlpha.400',
                }}
                _selected={{
                    borderColor: 'blue.400',
                }}
            >
                <HStack
                    h="full"
                    w="full"
                    justify="space-between"
                    align="center"
                    p={3}
                    fontWeight="medium"
                >
                    <Text whiteSpace="nowrap" userSelect="none">
                        {props.label}
                    </Text>
                    <Box>
                        {!props.isCurrent && <Radio isChecked={props.isSelected} />}
                        {props.isCurrent && (
                            <Tag colorScheme="blue" size="md">
                                Current
                            </Tag>
                        )}
                    </Box>
                </HStack>
            </Box>
        );
    };

    const AddonItem: React.FC<SubscriptionEditAddonItemProps> = (props) => {
        const isSetteled =
            props.action.display === 'switch'
                ? props.action.isSelected
                : props.action.isEnabled;
        return (
            <HStack
                w="full"
                justify="space-between"
                aria-selected={isSetteled}
                py={4}
                _selected={{
                    borderColor: 'blue.400',
                }}
            >
                <HStack spacing={3}>
                    <Box p={2} bg="whiteAlpha.300" borderRadius="md">
                        <Icon h={5} w={5} as={HiOutlineCube}></Icon>
                    </Box>
                    <HStack>
                        <Text>
                            {props.name} - {props.price.label}
                        </Text>
                        <Tooltip
                            p={4}
                            placement="top"
                            hasArrow={true}
                            bg="#1D1C21"
                            label={props.description}
                            isDisabled={props.description === null}
                        >
                            <Box>
                                <Icon as={AiOutlineInfoCircle} />
                            </Box>
                        </Tooltip>
                    </HStack>
                </HStack>
                <Box>
                    {props.action.display === 'switch' ? (
                        <Switch
                            size="md"
                            colorScheme="blue"
                            {...props.action.getSwitchProps()}
                        />
                    ) : (
                        <Button
                            variant={isSetteled ? 'outline' : 'solid'}
                            disabled={isSetteled}
                            isLoading={props.action.isLoading}
                            colorScheme="green"
                            onClick={props.action.beginPaymentSession}
                        >
                            {isSetteled
                                ? props.action.isPaid
                                    ? 'Paid'
                                    : 'Enabled'
                                : 'Add'}
                        </Button>
                    )}
                </Box>
            </HStack>
        );
    };

    return (props) => {
        const editProps = props.getEditProps();
        const alertListProps = props.getAlertProps();
        const formStateProps = editProps.getFormStateProps();
        const previewProps = editProps.getPreviewProps();
        const planSelectProps = editProps.getPlanSelectProps();
        const usageSelectProps = planSelectProps.getUsageProps();
        const addonListProps = editProps.getAddonListProps();

        const statusProps = props.page.getStatusProps();
        if (statusProps?.isDisabled) {
            return (
                <Container>
                    <Header
                        description={
                            <Text>Manage subscription plan and paid addons</Text>
                        }
                    >
                        <BreadcrumbsList>
                            <BreadcrumbsItem>Subscription</BreadcrumbsItem>
                        </BreadcrumbsList>
                    </Header>
                    <Content>
                        <Alert status="warning">
                            <AlertIcon />
                            <AlertTitle>{statusProps.description}</AlertTitle>
                        </Alert>
                    </Content>
                </Container>
            );
        }

        return (
            <form {...props.getFormProps()}>
                <Container>
                    <Header
                        description={
                            <Text>Manage subscription plan and paid addons</Text>
                        }
                    >
                        <BreadcrumbsList>
                            <BreadcrumbsItem>Subscription</BreadcrumbsItem>
                        </BreadcrumbsList>
                        <ActionList>
                            <Anchor
                                id="settings_billing_features_learn_more_link"
                                href="https://www.varos.com/pricing"
                                target="_blank"
                                color="blue.400"
                                _focus={{ outline: 'none' }}
                            >
                                <HStack w="full">
                                    <Text fontWeight="medium">
                                        Learn more about features
                                    </Text>
                                    <Icon fontSize="sm" as={FaArrowRight} />
                                </HStack>
                            </Anchor>
                        </ActionList>
                    </Header>
                    <Content>
                        {alertListProps.items.length > 0 && (
                            <VStack align="start" w="full">
                                {alertListProps.items.map((item) => (
                                    <Alert key={item.label} status={item.status}>
                                        <AlertIcon />
                                        <AlertTitle w="full">
                                            <HStack w="full" justify="space-between">
                                                <Text fontWeight="semibold">
                                                    {item.label}
                                                </Text>
                                                {item.action?.kind === 'click' && (
                                                    <ChakraLink
                                                        onClick={item.action.onClick}
                                                    >
                                                        {item.action.label}
                                                    </ChakraLink>
                                                )}
                                                {item.action?.kind === 'link' && (
                                                    <ChakraLink
                                                        as={Link}
                                                        id={item.action.id}
                                                        to={item.action.to}
                                                    >
                                                        {item.action.label}
                                                    </ChakraLink>
                                                )}
                                            </HStack>
                                        </AlertTitle>
                                    </Alert>
                                ))}
                            </VStack>
                        )}
                        <Grid
                            templateColumns="minmax(450px, 3fr) 2fr"
                            w="full"
                            gap={12}
                            position="relative"
                        >
                            <GridItem>
                                <VStack
                                    align="strat"
                                    spacing={8}
                                    w="full"
                                    divider={
                                        <StackDivider
                                            borderColor="whiteAlpha.300"
                                            borderWidth={1}
                                        />
                                    }
                                >
                                    <FormControl
                                        label="Plan"
                                        helperText={
                                            <Text>Select your subscription plan</Text>
                                        }
                                    >
                                        <Grid
                                            templateColumns="1fr"
                                            fontWeight="medium"
                                            w="full"
                                            gap={3}
                                        >
                                            {planSelectProps.items.map((item, index) => {
                                                const itemProps =
                                                    planSelectProps.getItemProps(
                                                        item,
                                                        index
                                                    );
                                                return (
                                                    <GridItem key={item.name}>
                                                        <PlanItem {...itemProps} />
                                                    </GridItem>
                                                );
                                            })}
                                        </Grid>
                                    </FormControl>
                                    {usageSelectProps && (
                                        <FormControl
                                            label={usageSelectProps.title}
                                            helperText={
                                                <Text>
                                                    {usageSelectProps.description}
                                                </Text>
                                            }
                                        >
                                            <Grid
                                                w="full"
                                                gap={3}
                                                templateColumns={{
                                                    base: '1fr',
                                                    xl: 'minmax(0, 1fr) minmax(0, 1fr)',
                                                }}
                                            >
                                                {usageSelectProps.items.map((item) => {
                                                    return (
                                                        <GridItem key={item.name}>
                                                            <UsageItem
                                                                {...usageSelectProps.getItemProps(
                                                                    item
                                                                )}
                                                            />
                                                        </GridItem>
                                                    );
                                                })}
                                            </Grid>
                                        </FormControl>
                                    )}
                                    {addonListProps.items.length > 0 && (
                                        <FormControl
                                            label="Addons"
                                            helperText={
                                                <Text>
                                                    Customize your subscription plan with
                                                    addons to unlock advanced
                                                    functionality
                                                </Text>
                                            }
                                        >
                                            <VStack
                                                divider={
                                                    <StackDivider
                                                        borderWidth={1}
                                                        borderColor="whiteAlpha.300"
                                                    />
                                                }
                                                fontWeight="medium"
                                                w="full"
                                                spacing={3}
                                                pb={32}
                                            >
                                                {addonListProps.items.map((item) => (
                                                    <AddonItem
                                                        key={item.name}
                                                        {...addonListProps.getItemProps(
                                                            item
                                                        )}
                                                    />
                                                ))}
                                            </VStack>
                                        </FormControl>
                                    )}
                                </VStack>
                            </GridItem>
                            <GridItem>
                                <VStack
                                    align="start"
                                    w="full"
                                    position="sticky"
                                    top={0}
                                    pl={12}
                                    borderLeftWidth={3}
                                    borderColor="whiteAlpha.300"
                                    borderStyle="solid"
                                    fontWeight="medium"
                                    spacing={6}
                                    // divider={
                                    //     <StackDivider
                                    //         borderWidth={1}
                                    //         borderColor="whiteAlpha.200"
                                    //     />
                                    // }
                                >
                                    <VStack w="full" spacing={6} align="start">
                                        <VStack align="start" spacing={0}>
                                            <Text fontSize="lg" color="whiteAlpha.700">
                                                {previewProps.title}
                                            </Text>
                                            <Text fontSize="xl">
                                                {previewProps.description}
                                            </Text>
                                        </VStack>
                                        <VStack align="start" w="full" spacing={4}>
                                            {previewProps.items.map((item) => (
                                                <VStack
                                                    key={item.label}
                                                    align="start"
                                                    spacing={0.5}
                                                    w="full"
                                                >
                                                    <Text
                                                        textTransform="uppercase"
                                                        fontSize="xs"
                                                        fontWeight="bold"
                                                        letterSpacing="wide"
                                                        color="whiteAlpha.500"
                                                    >
                                                        {item.label}
                                                    </Text>
                                                    {item.empty && (
                                                        <Text
                                                            // fontSize="sm"
                                                            color="whiteAlpha.500"
                                                            fontStyle="italic"
                                                        >
                                                            {item.empty.label}
                                                        </Text>
                                                    )}
                                                    {item.children.map((child) => {
                                                        return (
                                                            <HStack
                                                                key={child.label}
                                                                // fontSize="sm"
                                                                // color="whiteAlpha.800"
                                                                justify="space-between"
                                                                w="full"
                                                            >
                                                                <Box
                                                                    flex={1}
                                                                    color="whiteAlpha.700"
                                                                >
                                                                    <Text whiteSpace="nowrap">
                                                                        {child.label}
                                                                    </Text>
                                                                </Box>
                                                                <Text>
                                                                    {child.secondary}
                                                                </Text>
                                                            </HStack>
                                                        );
                                                    })}
                                                </VStack>
                                            ))}
                                        </VStack>
                                    </VStack>
                                    <VStack w="full" align="start" spacing={3}>
                                        {props.button.submit && (
                                            <Tooltip
                                                p={3}
                                                placement="top"
                                                hasArrow={true}
                                                bg="#1D1C21"
                                                {...editProps.getSubmitTooltipProps()}
                                            >
                                                <Box w="full">
                                                    <Button
                                                        {...props.button.submit}
                                                        colorScheme="green"
                                                        w="full"
                                                        _focus={{ outline: 'none' }}
                                                    >
                                                        Update subscription
                                                    </Button>
                                                </Box>
                                            </Tooltip>
                                        )}
                                        {props.button.paymentmethod && (
                                            <Box w="full">
                                                <Button
                                                    {...props.button.paymentmethod}
                                                    colorScheme="green"
                                                    w="full"
                                                    _focus={{ outline: 'none' }}
                                                >
                                                    Update subscription
                                                </Button>
                                            </Box>
                                        )}
                                        <HStack w="full" justify="center">
                                            <Button
                                                {...props.button.manage}
                                                variant="link"
                                                fontWeight="medium"
                                                color="whiteAlpha.600"
                                                _focus={{ outline: 'none' }}
                                            >
                                                Manage billing
                                            </Button>
                                        </HStack>
                                    </VStack>
                                </VStack>
                            </GridItem>
                        </Grid>
                    </Content>
                </Container>
                <Modal {...props.modal.containerProps} isCentered={true}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader p={4}>
                            <Heading fontSize="md">Payment method required</Heading>
                        </ModalHeader>
                        <ModalBody p={4}>
                            <Text>
                                An active payment method is required to update your
                                subscription
                            </Text>
                        </ModalBody>
                        <ModalFooter p={4}>
                            <HStack w="full" justify="end">
                                <Button
                                    {...props.modal.closeProps}
                                    size="sm"
                                    _focus={{ outline: 'none' }}
                                >
                                    Cancel
                                </Button>
                                <Link {...props.link.addPaymentMethod}>
                                    <Button
                                        size="sm"
                                        colorScheme="blue"
                                        _focus={{ outline: 'none' }}
                                    >
                                        Add payment method
                                    </Button>
                                </Link>
                            </HStack>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            </form>
        );
    };
}

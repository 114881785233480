import { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import { AxiosCacheInstance } from 'axios-cache-interceptor';
import { convertAxiosToError, isAxiosErr } from '../../../../utils/axiosUtils';
import {
    LegacyAuthenticationScheme,
    ListOffsetResponse,
    ListResponse,
} from '../../common';
import { UserResource } from './userInterface';
import { LoadedUserDto, UserDto } from './userModel';

type UserServerDto = Omit<UserDto, 'role'> & { organizations: { role: 'admin' | 'standard', organization_id: number }[] };

export function createUserResource(client: AxiosInstance): UserResource {
    return {
        async list(context, query) {
            const scheme = context.auth.scheme as LegacyAuthenticationScheme;
            await scheme.store.waitAuthenticated();

            const params: Record<string, unknown> = {};
            if (query.expand) {
                params['expand'] = query.expand;
            }
            if (query.page) {
                params['page'] = query.page;
            }
            if (query.page_size) {
                params['page_size'] = query.page_size;
            }
            if (query.search) {
                params['search'] = query.search;
            }

            const response = await (client as AxiosCacheInstance).get<
                ListOffsetResponse<UserServerDto>
            >('/api/v1/account/users', {
                cache: false,
                params,
                headers: {
                    Authorization: `bearer ${scheme.store.authToken}`,
                },
            });
            const serverResponse = response.data as ListOffsetResponse<UserServerDto>;
            const serverUsers = serverResponse.data;
            return {
                ...serverResponse,
                data: serverUsers.map(x => (
                    {
                        ...x,
                        role: x.organizations[0].role    
                    }
                ))
            };
        },
        async create(context, dto) {
            const scheme = context.auth.scheme as LegacyAuthenticationScheme;
            await scheme.store.waitAuthenticated();
            try {
                const response = await client.post<unknown, AxiosResponse<LoadedUserDto>>(
                    '/api/v1/account/users',
                    { ...dto, asset_access_role: 'manager' },
                    {
                        headers: {
                            Authorization: `bearer ${scheme.store.authToken}`,
                        },
                    }
                );
                return response.data;
            } catch (e) {
                if (isAxiosErr(e)) {
                    throw convertAxiosToError(e);
                } else {
                    throw e;
                }
            }
        },
        async remove(context, userId) {
            const scheme = context.auth.scheme as LegacyAuthenticationScheme;
            await scheme.store.waitAuthenticated();
            try {
                const response = await client.delete<unknown, AxiosResponse<void>>(
                    `/api/v1/account/users/${userId}`,
                    {
                        headers: {
                            Authorization: `bearer ${scheme.store.authToken}`,
                        },
                    }
                );
            } catch (e) {
                if (isAxiosErr(e)) {
                    throw convertAxiosToError(e);
                } else {
                    throw e;
                }
            }
        }
    };
}

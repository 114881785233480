import { FiChevronDown, FiPlus } from 'react-icons/fi';
import { FiCheck } from 'react-icons/fi';
import { SearchIcon, ChevronDownIcon } from '@chakra-ui/icons';
import {
    Portal,
    Box,
    Icon,
    UsePopoverProps,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverBody,
    InputGroup,
    InputLeftElement,
    Text,
    Input,
} from '@chakra-ui/react';
import React from 'react';
import { Button, ButtonProps, Menu, MenuItemProps } from '../../../../../ui';
import { WorkspaceSelectionViewProps } from './workspaceSelectionProps';
import { Link } from 'react-router-dom';

export function createWorkspaceSelectionView(): React.FC<WorkspaceSelectionViewProps> {
    return (props) => {
        return (
            <Popover
                closeOnBlur={true}
                closeOnEsc={true}
                {...props.getPopoverProps?.()}
                placement="bottom-end"
                isLazy={false}
            >
                <PopoverTrigger>
                    <Button
                        {...props.style?.button}
                        rightIcon={<Icon as={FiChevronDown} />}
                        _focus={{ outline: 'none' }}
                    >
                        {props.workspaces[props.selectedIndex]?.name}
                    </Button>
                </PopoverTrigger>
                <PopoverContent
                    {...props.style?.wrapper}
                    _focus={{ outline: 'none' }}
                    maxW="80%"
                    minW={350}
                >
                    <PopoverBody p={0}>
                        <Menu.List
                            maxH={500}
                            // {...props.listProps}
                        >
                            <Menu.Label
                                sticky={true}
                                rightSection={
                                    <Link to="/u/settings/companies/new">
                                        <Button
                                            size="sm"
                                            colorScheme="varosGreen"
                                            leftIcon={<FiPlus />}
                                            {...props.getAddButtonProps?.()}
                                        >
                                            Add
                                        </Button>
                                    </Link>
                                }
                            >
                                Companies
                            </Menu.Label>
                            <Box py={1} px={4} w="full">
                                <InputGroup>
                                    <InputLeftElement
                                        pointerEvents="none"
                                        children={<SearchIcon color="gray.300" />}
                                    />
                                    <Input
                                        type="text"
                                        placeholder="Search companies"
                                        autoFocus={true}
                                        value={props.searchTerm}
                                        onChange={(event) => {
                                            props.setSearchTerm(event.target.value);
                                        }}
                                    />
                                </InputGroup>
                            </Box>
                            {props.workspaces.map((workspace, index) => {
                                const itemProps = props.getItemProps?.(workspace, index);
                                return (
                                    <WorkspaceSelectionItem
                                        key={workspace.id}
                                        selected={index === props.selectedIndex}
                                        {...itemProps}
                                    >
                                        {workspace.name}
                                    </WorkspaceSelectionItem>
                                );
                            })}
                        </Menu.List>
                    </PopoverBody>
                </PopoverContent>
            </Popover>
        );
    };
}

export interface WorkspaceSelectionItemProps extends Omit<MenuItemProps, 'style'> {
    style?: Omit<MenuItemProps, 'w' | 'children' | 'cursor' | 'onClick'>;
    leftIcon?: React.FC;
    selected: boolean;
}

export const WorkspaceSelectionItem: React.FC<WorkspaceSelectionItemProps> = ({
    leftIcon,
    selected,
    style,
    ...props
}) => {
    return (
        <Menu.Item {...props} {...style} icon={selected ? <FiCheck /> : undefined}>
            {props.children}
        </Menu.Item>
    );
};

import { HStack, StackDivider, Text, useToken, VStack } from '@chakra-ui/react';
import React from 'react';
import { PeerCompanyListViewProps } from './peerCompanyListProps';

export function createPeerCompanyListView(): React.FC<PeerCompanyListViewProps> {
    return (props) => {
        const scrollbarBg = useToken('colors', 'whiteAlpha.400');
        const scrollbarHoverBg = useToken('colors', 'whiteAlpha.500');
        return (
            <VStack
                align="start"
                spacing={0}
                w="full"
                h="full"
                borderWidth={2}
                borderColor="whiteAlpha.300"
                borderStyle="solid"
                borderRadius="lg"
                overflowY="scroll"
                position="relative"
                css={{
                    '&::-webkit-scrollbar': {
                        '-webkit-appearance': 'none',
                        width: '0.5rem',
                    },
                    '&::-webkit-scrollbar-track': {},
                    '&::-webkit-scrollbar-corner': {
                        '-webkit-appearance': 'none',
                    },
                    '&::-webkit-scrollbar-thumb': {
                        borderRadius: '1rem',
                        background: scrollbarBg,
                    },
                    '&::-webkit-scrollbar-thumb:hover': {
                        background: scrollbarHoverBg,
                    },
                }}
            >
                <HStack
                    px={6}
                    py={4}
                    w="full"
                    position="sticky"
                    top={0}
                    bg="black"
                    borderBottomWidth={2}
                    borderStyle="solid"
                    borderColor="whiteAlpha.300"
                    zIndex={5}
                >
                    <HStack w="full" justify="space-between">
                        <Text fontWeight="medium" whiteSpace="nowrap">
                            {props.label}
                        </Text>
                        {props.rightContent}
                    </HStack>
                </HStack>
                <VStack
                    h="full"
                    w="full"
                    spacing={0}
                    divider={
                        <StackDivider borderWidth={1} borderColor="whiteAlpha.200" />
                    }
                >
                    {props.children}
                </VStack>
            </VStack>
        );
    };
}

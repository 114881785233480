import React from 'react';
import { AnyPlan, BillingUnit, PlanCategory, PricingPlan } from '../planType';
import { FormatterImpl } from '../../../../hooks';
import {
    Wrap,
    WrapItem,
    VStack,
    Box,
    Heading,
    Button,
    Link as Href,
    ButtonProps,
    Text,
    Center,
    Flex,
} from '@chakra-ui/react';
import { chain } from 'lodash';
import { isPricingPlan } from '../planHelper';
import { PlanId } from '../../../../api';

export interface ThresholdSelectorProps {
    category: PlanCategory;
    billingUnit: BillingUnit;
    currentPlanId?: PlanId;
    onSelect(plan: AnyPlan): void;
    getCta(plan: AnyPlan): string | undefined;
    onNotAdvertising(): void;
    buttonProps?: Pick<ButtonProps, 'children'>;
}
const formatter = new FormatterImpl();

function formatThreshold(plan: AnyPlan) {
    if (plan.threshold_start != undefined) {
        if (plan.unit_kind === 'currency') {
            return plan.threshold_end
                ? [
                      formatter.currency(plan.threshold_start, plan.currency, {
                          notation: 'short',
                      }),
                      formatter.currency(plan.threshold_end, plan.currency, {
                          notation: 'short',
                      }),
                  ].join('-')
                : [
                      formatter.currency(plan.threshold_start, plan.currency, {
                          notation: 'short',
                      }),
                      '+',
                  ].join('');
        } else {
            return `${
                plan.threshold_end
                    ? [plan.threshold_start, plan.threshold_end].join('-')
                    : [plan.threshold_start, '+'].join('')
            } ${plan.unit_name}`;
        }
    }
    console.warn('MISSING TRESHOLD START. that should happen', plan);
    return 'N/A';
}
interface ThresholdSelectorState {
    priceIndex: number;
}

const COLORS = {
    PURPLE: '#7164ff',
    GREEN: '#95EF93',
    DARK_GREEN: '#3abb68',
};

export const ThresholdSelector: React.FC<ThresholdSelectorProps> = ({
    category,
    billingUnit,
    currentPlanId,
    onSelect,
    getCta,
    onNotAdvertising,
}) => {
    const prices = chain(category.plans)
        .map((item) => ({ plan: item, value: item.id, label: formatThreshold(item) }))
        .value();
    const [state, setState] = React.useState<ThresholdSelectorState>({
        priceIndex: 1,
    });
    const selectedPrice = prices?.[state.priceIndex] ?? null;
    return (
        <Flex alignItems="center" flexDirection="column">
            <VStack spacing={4}>
                <VStack textAlign="center" spacing={2}>
                    <Heading fontSize="xl" fontWeight="bold">
                        Choose your {billingUnit.name}
                    </Heading>
                    <Text fontWeight="light" fontSize="md">
                        Don't currently advertise?{' '}
                        <Button
                            fontSize="md"
                            color="blue.500"
                            variant="link"
                            onClick={onNotAdvertising}
                        >
                            Click Here
                        </Button>
                    </Text>
                </VStack>
                <Wrap justify="center">
                    {prices.map((price, index) => (
                        <WrapItem
                            key={price.value}
                            p="2px"
                            // bg="red"
                            borderRadius="6px"
                            bg={
                                index === state.priceIndex
                                    ? 'linear-gradient(90deg, #29D89B 0%, #3CD990 15.57%, #63DC79 52.47%, #7BDE6A 81.91%, #84DF65 100%)'
                                    : 'gray.100'
                            }
                        >
                            <Button
                                _focus={{ outline: 'none' }}
                                borderRadius="4px"
                                // borderStyle="solid"
                                // borderWidth="2px"
                                // borderColor={
                                //     index === state.priceIndex ? COLORS.GREEN : 'transparent'
                                // }
                                fontWeight="bold"
                                color="blackAlpha.800"
                                variant="solid"
                                minW={36}
                                onClick={() => setState({ ...state, priceIndex: index })}
                            >
                                {price.label}
                            </Button>
                        </WrapItem>
                    ))}
                </Wrap>
            </VStack>
            <Box my={10}>
                {selectedPrice.plan.amount && (
                    <>
                        <Text display="inline" fontSize="4xl" fontWeight="bold">
                            {formatter.currency(
                                selectedPrice.plan.amount / 100,
                                selectedPrice.plan.currency
                            )}
                        </Text>
                        <Text
                            position="relative"
                            ml={1}
                            display="inline"
                            fontSize="sm"
                            fontWeight="semibold"
                            color="blackAlpha.500"
                        >
                            /MTH
                        </Text>
                    </>
                )}
            </Box>
            <Box>
                {currentPlanId == selectedPrice.plan.id ? (
                    <Button disabled={true}>Current plan</Button>
                ) : (
                    <Button
                        onClick={() => onSelect(selectedPrice?.plan)}
                        w="full"
                        {...(category.key == 'enterprise'
                            ? {
                                  borderColor: COLORS.DARK_GREEN,
                                  borderWidth: 1,
                                  borderStyle: 'solid',
                                  bg: 'transparent',
                                  color: COLORS.DARK_GREEN,
                                  _hover: { bg: 'transparent' },
                                  _focus: {
                                      outline: 0,
                                      _hover: { bg: 'transparent' },
                                  },
                              }
                            : {
                                  color: 'whiteAlpha.900',
                                  bg: COLORS.DARK_GREEN,
                                  _hover: {
                                      color: 'whiteAlpha.900',
                                      bg: COLORS.DARK_GREEN,
                                  },
                                  _focus: {
                                      outline: 0,
                                      color: 'whiteAlpha.900',
                                      bg: COLORS.DARK_GREEN,
                                  },
                              })}
                    >
                        {getCta(selectedPrice.plan) ?? 'Get started'}
                    </Button>
                )}
            </Box>
        </Flex>
    );
};

import React from 'react';
import { ApplicationLinkProps } from '../../../ui';
import { Location } from 'react-router';
import { ApplicationEntryEnhancer } from '../../../entrypoint';
import { AnyNavigationEvent } from './navigationTrackingEvent';
import { useLocation } from 'react-router';
import { useDebounce } from 'react-use';
import { buildPayload } from './navigationTrackingFactory';
import { NavigationState } from './navigationTrackingModel';
import { toRaw } from './navigationTrackingSerializer';

/**
 * Wraps link component to automatically track navigation with the link ID as the source
 * @returns
 */
export function createNavigationTrackingStrategy(): ApplicationEntryEnhancer {
    return (create) => (init) => {
        function useTracker() {
            const tracker = instance.infra.useTracker<AnyNavigationEvent>();
            return tracker;
        }

        const Provider: React.FC = (props) => {
            const tracker = useTracker();
            const location = useLocation();

            function handleRouteChange(location: Location) {
                const payload = buildPayload(location);
                if (payload) {
                    tracker.track('application_route_changed', payload);
                }
                return;
            }

            // avoid sending multiple events for redirects
            const [] = useDebounce(
                () => {
                    handleRouteChange(location);
                },
                100,
                [location]
            );

            return <>{props.children}</>;
        };

        function enhanceLink(
            Component: React.FC<ApplicationLinkProps>
        ): React.FC<ApplicationLinkProps> {
            return (props) => {
                const location = useLocation();

                if (!props.id) {
                    return <Component {...props} />;
                }

                const state: NavigationState = {
                    source_link_id: props.id,
                    source_path: location.pathname,
                    source_page_title: document.title,
                };

                return (
                    <Component
                        {...props}
                        state={{ ...props.state, ...toRaw(state) }}
                        data-link-id={state.source_link_id}
                    />
                );
            };
        }

        const instance = create({
            ...init,
            provider: {
                ...init.provider,
                custom: [...init.provider.custom, Provider],
            },
            ui: {
                ...init.ui,
                createSettingsUI(...args) {
                    const UI = init.ui.createSettingsUI(...args);
                    return {
                        ...UI,
                        Link: enhanceLink(UI.Link),
                    };
                },
                createWorkspaceUI(...args) {
                    const UI = init.ui.createWorkspaceUI(...args);
                    return {
                        ...UI,
                        Link: enhanceLink(UI.Link),
                    };
                },
            },
        });
        return instance;
    };
}

import { AssertError } from './assertError';

type ErrorCallback = () => string;

export function assert(
    value: unknown,
    messageOrCallback: string | ErrorCallback
): asserts value {
    if (!value) {
        const message =
            typeof messageOrCallback === 'function'
                ? messageOrCallback()
                : messageOrCallback;
        throw new AssertError(value, message);
    }
}

import React from 'react';
import {
    VStack,
    Heading,
    Link as ChakraLink,
    Text,
    Box,
    Link as CLink,
    Alert,
    AlertIcon,
    AlertTitle,
    HStack,
} from '@chakra-ui/react';
import { GroupsFormViewProps } from '../../../view/groups/group';
import { ApplicationUI } from '../../../ui';
import { SettingGroupViewProps } from './groupsSettingsProps';

export function createGroupsRouteView(
    FormView: React.FC<GroupsFormViewProps>,
    UI: ApplicationUI
): React.FC<SettingGroupViewProps> {
    return (props) => {
        const pageProps = props.getPageProps();
        const pageAlertProps = pageProps.getAlertProps();
        return (
            <VStack alignItems="flex-start" spacing={4}>
                <VStack alignItems="flex-start" spacing={6}>
                    <Heading fontSize="2xl">Privacy</Heading>
                    {pageAlertProps.items.length > 0 && (
                        <VStack align="start" w="full">
                            {pageAlertProps.items.map((item) => (
                                <Alert key={item.label} status={item.status}>
                                    <AlertIcon />
                                    <AlertTitle w="full">
                                        <HStack w="full" justify="space-between">
                                            <Text fontWeight="semibold">
                                                {item.label}
                                            </Text>
                                        </HStack>
                                    </AlertTitle>
                                </Alert>
                            ))}
                        </VStack>
                    )}
                    <VStack align="start">
                        <Heading fontSize="lg">Top Creatives</Heading>
                        <Text color="whiteAlpha.700">
                            The top-creatives module allows you to find best performing
                            creatives to replicate. You can opt-in to our co-op model to
                            get access to creatives from other companies that participate
                            in the co-op or configure it as internal to better identify
                            top creatives across companies you manage.
                        </Text>
                    </VStack>
                </VStack>
                <FormView {...props.getFormProps()} />
                <Box alignSelf="flex-end">
                    <CLink as={UI.Link} to="/u/private-groups">
                        Go to top creatives
                    </CLink>
                </Box>
            </VStack>
        );
    };
}

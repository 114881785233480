import { useQuery } from '@tanstack/react-query';
import { ConversationAgentAdapter } from './conversationAgentAdapter';
import { AgentRepository } from './conversationAgentInterface';

export function createAgentRepository(
    adapter: ConversationAgentAdapter
): AgentRepository {
    const PREFIX = ['conversations', 'agents'];
    return {
        useFind(context, query, options) {
            const result = useQuery({
                retry: false,
                staleTime: Infinity,
                ...options,
                queryKey: [...PREFIX, context.auth.user, query],
                queryFn: async () => {
                    const response = await adapter.find(context, query);
                    return response;
                },
            });
            return result;
        },
        useLookup(context, query, options) {
            const result = useQuery({
                retry: false,
                staleTime: Infinity,
                ...options,
                queryKey: [...PREFIX, context.auth.user, query],
                queryFn: async () => {
                    const response = await adapter.lookup(context, query);
                    return response;
                },
            });
            return result;
        },
    };
}

import React from 'react';
import { ReportTableContainerConfig } from './reportTableConfig';
import { ReportTableContainerProps } from './reportTableProps';

export const createReportTableContainer = (
    config: ReportTableContainerConfig
): React.FC<ReportTableContainerProps> => {
    const { View, controller } = config;
    return (props) => {
        const query = controller.query.useProps(props);
        const tableProps = controller.table.useProps(query);
        return <View {...tableProps} />;
    };
};

import { observer } from 'mobx-react';
import React from 'react';
import { ActionProps } from '../../../view/dashboard';
import { ActionViewController } from './actionController';

export interface ActionContainerProps {
    as: React.FC<ActionProps>;
    useController: ActionViewController;
}

export const ActionContainer: React.FC<ActionContainerProps> = observer(
    ({ as: Component, useController, ...restProps }) => {
        const viewProps = useController();
        return <Component {...viewProps} {...restProps} />;
    }
);

import React from 'react';
import * as Chakra from '@chakra-ui/react';
import { getChildrenProps } from '../../../../../util';

export interface ImageProps extends Chakra.ImageProps {
    children?: React.ReactElement | null | Array<React.ReactElement | null>;
}

export const Image: React.FC<ImageProps> = (props) => {
    const { children, ...rest } = props;

    const { badgeProps } = getChildrenProps(children, {
        badgeProps: ImageBadge,
    });

    return (
        <Chakra.Box position="relative">
            {badgeProps ? (
                <Chakra.Badge
                    fontSize="xs"
                    borderRadius="full"
                    position="absolute"
                    top={-2}
                    right={-2}
                    w={4}
                    h={4}
                    textAlign="center"
                    {...badgeProps}
                />
            ) : null}
            <Chakra.Image {...rest} />
        </Chakra.Box>
    );
};

export const ImageBadge: React.FC<Chakra.BadgeProps> = (props) => {
    return <>{props.children}</>;
};

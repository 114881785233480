import React, { useContext, useMemo } from 'react';
import { BeatLoader, SyncLoader } from 'react-spinners';
import {
    Box,
    BoxProps,
    Button,
    ButtonGroup,
    Flex,
    Grid,
    GridItem,
    HStack,
    Icon,
    IconButton,
    Image,
    Input,
    InputGroup,
    InputRightElement,
    Select,
    SimpleGrid,
    Stack,
    Text,
    Textarea,
    useToken,
    VStack,
    Wrap,
} from '@chakra-ui/react';
import { BsArrowLeft } from 'react-icons/bs';
import { AiOutlineLink, AiOutlineQuestionCircle, AiOutlineSend } from 'react-icons/ai';
import { Graphics } from '../../../../config/svg';
import { Icons } from '../../../../../config';
import { assertNever } from '../../../../util';
import {
    ActionBlock,
    AnyBlock,
    AnyElement,
    AnyInputBlock,
    ButtonElement,
    EventBlock,
    JsonBlock,
    SectionBlock,
    SelectBlock,
    TextBlock,
} from '../../../../app/conversations';
import { assert } from '../../../../util/assert';
import { ConversationViewBaseConfig } from '../../base';
import { createConversationDetailView } from '../details';
import { MessageItemViewProps } from '../message';
import { ConversationThreadViewProps } from './conversationThreadProps';
import { ElementContext } from '../element';
import { capitalize } from 'lodash';

export function createConversationThreadView(
    config: ConversationViewBaseConfig
): React.FC<ConversationThreadViewProps> {
    const {
        UI: {
            Application: { Link },
        },
        strategy: { Block: BlockContainer },
        Layout,
    } = config;

    const Detail = createConversationDetailView(config);

    const containerStyle = {
        maxW: '42rem',
    } satisfies BoxProps;

    const messageStyle = {
        maxW: '32rem',
    } satisfies BoxProps;

    const ButtonElement: React.FC<ButtonElement> = (props) => {
        const context = useContext(ElementContext);
        assert(context, 'missing element context');
        return (
            <Button
                colorScheme={props.variant === 'primary' ? 'green' : undefined}
                onClick={context.onAction.bind(null, props)}
                _focus={{ outline: 'none' }}
            >
                {props.label}
            </Button>
        );
    };

    const Element: React.FC<{ data: AnyElement }> = (props) => {
        if (props.data.kind === 'button') {
            return <ButtonElement {...props.data} />;
        }
        assertNever(props.data.kind);
    };

    const TextBlock: React.FC<TextBlock> = (props) => {
        return (
            <VStack
                align="start"
                w="full"
                spacing={4}
                maxW={containerStyle.maxW}
                fontWeight={props.modifiers.has('bold') ? 'semibold' : 'normal'}
                fontStyle={props.modifiers.has('italic') ? 'italic' : undefined}
            >
                <Text color={props.modifiers.has('bold') ? undefined : 'whiteAlpha.800'}>
                    {props.content}
                </Text>
                {props.attachments.length > 0 && (
                    <Wrap w="full" shouldWrapChildren={true} spacing={3}>
                        {props.attachments.map((attachment, index) => (
                            <HStack
                                key={index}
                                cursor="pointer"
                                userSelect="none"
                                color="whiteAlpha.600"
                                borderColor="whiteAlpha.300"
                                borderWidth={2}
                                borderRadius="lg"
                                px={4}
                                py={3}
                                bg="none"
                                spacing={3}
                                fontWeight="medium"
                                _hover={{ bg: 'whiteAlpha.50' }}
                            >
                                <Icon as={AiOutlineLink} />
                                <Text>{attachment.id.slice(0, 16)}</Text>
                            </HStack>
                        ))}
                    </Wrap>
                )}
            </VStack>
        );
    };

    const JsonBlock: React.FC<JsonBlock> = (props) => {
        return (
            <VStack align="start" w="full" spacing={1} maxW={containerStyle.maxW}>
                <Text color="whiteAlpha.800">{JSON.stringify(props.data, null, 2)}</Text>
            </VStack>
        );
    };

    const SelectBlock: React.FC<SelectBlock> = (props) => {
        return (
            <HStack>
                <Select placeholder={props.placeholder ?? 'Select'}>
                    {props.options.map((option) => (
                        <option key={option.value} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                </Select>
            </HStack>
        );
    };

    const ActionBlock: React.FC<ActionBlock> = (props) => {
        return (
            <HStack>
                {props.elements.map((element, index) => (
                    <Element key={index} data={element} />
                ))}
            </HStack>
        );
    };

    const SectionBlock: React.FC<SectionBlock> = (props) => {
        return (
            <VStack align="start" w="full" spacing={3} maxW={containerStyle.maxW}>
                {props.text && <Text color="whiteAlpha.800">{props.text}</Text>}
                <VStack
                    align="start"
                    w="full"
                    px={6}
                    py={6}
                    borderWidth={2}
                    borderColor="whiteAlpha.300"
                    borderRadius="lg"
                    spacing={3}
                >
                    {props.fields.map((field, index) => (
                        <VStack key={index} align="start" w="full" spacing={0}>
                            <Text fontWeight="medium" color="whiteAlpha.600">
                                {field.label}
                            </Text>
                            <Text fontWeight="semibold" color="white">
                                {field.value}
                            </Text>
                        </VStack>
                    ))}
                </VStack>
            </VStack>
        );
    };

    const InputBlock: React.FC<{ data: AnyInputBlock }> = (props) => {
        if (props.data.type === 'select') {
            return <SelectBlock {...props.data} />;
        }
        assertNever(props.data.type);
    };

    const EventBlock: React.FC<EventBlock> = (props) => {
        return (
            <HStack
                color="whiteAlpha.600"
                fontWeight="medium"
                userSelect="none"
                fontStyle="italic"
                // bg="whiteAlpha.50"
                // borderColor="whiteAlpha.300"
                // borderWidth={2}
                px={6}
                py={4}
                borderRadius="lg"
            >
                <Text>{capitalize(props.data.kind)} submitted</Text>
            </HStack>
        );
    };

    const Block: React.FC<{ data: AnyBlock }> = (props) => {
        if (props.data.kind === 'text') {
            return <TextBlock {...props.data} />;
        }
        if (props.data.kind === 'json') {
            return <JsonBlock {...props.data} />;
        }
        if (props.data.kind === 'input') {
            return <InputBlock data={props.data} />;
        }
        if (props.data.kind === 'section') {
            return <SectionBlock {...props.data} />;
        }
        if (props.data.kind === 'action') {
            return <ActionBlock {...props.data} />;
        }
        if (props.data.kind === 'custom') {
            return <Text>custom block</Text>;
        }
        if (props.data.kind === 'event') {
            return <EventBlock {...props.data} />;
        }
        if (props.data.kind === 'unknown') {
            return (
                <HStack
                    px={6}
                    py={4}
                    color="red.400"
                    borderWidth={2}
                    borderColor="red.400"
                    borderRadius="md"
                >
                    <Text>unknown block</Text>
                </HStack>
            );
        }
        assertNever(props.data);
    };

    const SystemMessageGroup: React.FC<MessageItemViewProps> = (props) => {
        return (
            <Stack align="start" w="full" spacing={6} direction="column-reverse">
                <HStack w="full" spacing={6} align="start" maxW={messageStyle.maxW}>
                    <HStack
                        align="center"
                        justify="center"
                        position="relative"
                        // left={-14}
                        top={-1}
                        flexShrink={0}
                        // bg="whiteAlpha.300"
                        borderWidth={2}
                        borderColor="whiteAlpha.300"
                        borderRadius="full"
                        h={8}
                        w={8}
                    >
                        <Image
                            position="relative"
                            h={3}
                            w={3}
                            top="1px"
                            right="1px"
                            src={Graphics.Brand.Icon}
                            alt="system"
                            // filter="grayscale(100%)"
                        />
                    </HStack>
                    <VStack w="full" align="start" spacing={3}>
                        {props.content
                            .filter(
                                // NOTE we render select blocks as suggestions
                                (block) =>
                                    !(block.kind === 'input' && block.type === 'select')
                            )
                            .flatMap((block, index) => (
                                <Block data={block} key={index} />
                            ))}
                    </VStack>
                </HStack>
            </Stack>
        );
    };

    const UserMessageGroup: React.FC<MessageItemViewProps> = (props) => {
        return (
            <VStack w="full" align="end" spacing={3}>
                {props.content.map((block, index) => {
                    if (block.kind === 'event') {
                        return <EventBlock key={index} {...block} />;
                    }
                    if (block.kind !== 'text') {
                        return (
                            <React.Fragment key={index}>
                                user message type '{block.kind}' not implemented
                            </React.Fragment>
                        );
                    }
                    return (
                        <HStack key={index} w="full" justify="end">
                            <HStack
                                w="fit-content"
                                maxW={messageStyle.maxW}
                                bg="whiteAlpha.200"
                                borderRadius="lg"
                                px={6}
                                py={4}
                            >
                                <Text color="whiteAlpha.800">{block.content}</Text>
                            </HStack>
                        </HStack>
                    );
                })}
            </VStack>
        );
    };

    const MessageGroup: React.FC<MessageItemViewProps> = (props) => {
        if (props.author.kind === 'system') {
            return <SystemMessageGroup {...props} />;
        }
        if (props.author.kind === 'user') {
            return <UserMessageGroup {...props} />;
        }
        assertNever(props.author.kind);
    };

    return (props) => {
        const colorSpinner = useToken('colors', 'whiteAlpha.400');
        const scrollbarBg = useToken('colors', 'whiteAlpha.400');
        const scrollbarHoverBg = useToken('colors', 'whiteAlpha.500');
        return (
            <Layout title="Join survey" sidebar={<Detail {...props.detail} />}>
                <Grid
                    w="full"
                    h="full"
                    templateColumns="1fr"
                    templateRows="1fr min-content"
                    templateAreas={`
                        "conversation"
                        "composer"
                    `}
                >
                    {/* Messages */}
                    <GridItem gridArea="conversation" position="relative">
                        <Stack
                            position="absolute"
                            top={0}
                            bottom={0}
                            left={0}
                            right={0}
                            align="center"
                            w="full"
                            h="fit-content"
                            maxH="full"
                            spacing={12}
                            // pl={8}
                            pt={8}
                            pb={64}
                            overflowY="scroll"
                            direction="column-reverse"
                            onScroll={props.chat.onScroll}
                            ref={props.chat.containerRef}
                            css={{
                                scrollbarGutter: 'stable both-edges',
                                '&::-webkit-scrollbar': {
                                    '-webkit-appearance': 'none',
                                    width: '0.5rem',
                                },
                                '&::-webkit-scrollbar-track': {},
                                '&::-webkit-scrollbar-corner': {
                                    '-webkit-appearance': 'none',
                                },
                                '&::-webkit-scrollbar-thumb': {
                                    borderRadius: '1rem',
                                    background: scrollbarBg,
                                },
                                '&::-webkit-scrollbar-thumb:hover': {
                                    // background: scrollbarBg,
                                    background: scrollbarHoverBg,
                                },
                            }}
                        >
                            {/* Loading indicator */}
                            {props.isLoading && (
                                <HStack maxW={containerStyle.maxW} w="full">
                                    <HStack w="full" spacing={6} maxW={messageStyle.maxW}>
                                        <HStack
                                            align="center"
                                            justify="center"
                                            position="relative"
                                            top={-1}
                                            flexShrink={0}
                                            borderWidth={2}
                                            borderColor="whiteAlpha.300"
                                            borderRadius="full"
                                            h={8}
                                            w={8}
                                        >
                                            <Image
                                                position="relative"
                                                h={3}
                                                w={3}
                                                top="1px"
                                                right="1px"
                                                src={Graphics.Brand.Icon}
                                                alt="system"
                                            />
                                        </HStack>
                                        <HStack
                                            w="full"
                                            maxW={containerStyle.maxW}
                                            color="whiteAlpha.800"
                                            position="relative"
                                            top={-1}
                                        >
                                            <BeatLoader
                                                color={colorSpinner}
                                                speedMultiplier={0.7}
                                                size="1rem"
                                            />
                                        </HStack>
                                    </HStack>
                                </HStack>
                            )}
                            {props.items.map((item, index) => (
                                <HStack key={item.id} maxW={containerStyle.maxW} w="full">
                                    <MessageGroup {...item} />
                                </HStack>
                            ))}
                            {props.items.length > 0 && (
                                <HStack
                                    w="full"
                                    justify="center"
                                    fontWeight="medium"
                                    fontSize="sm"
                                    color="whiteAlpha.400"
                                >
                                    <Text>Start of conversation</Text>
                                </HStack>
                            )}
                        </Stack>
                    </GridItem>
                    {/* Composer */}
                    <GridItem gridArea="composer">
                        <HStack w="full" justify="center">
                            <VStack
                                align="start"
                                w="full"
                                h="full"
                                spacing={3}
                                pb={8}
                                maxW={containerStyle.maxW}
                            >
                                <Wrap w="full" shouldWrapChildren={true} spacing={2}>
                                    {props.suggestion.items.map((item) => (
                                        <Button
                                            key={item.id}
                                            {...item.button}
                                            borderRadius="full"
                                            _focus={{ outline: 'none' }}
                                        >
                                            {item.label}
                                        </Button>
                                    ))}
                                </Wrap>
                                <InputGroup>
                                    <Textarea
                                        {...props.chat.input}
                                        resize="none"
                                        ref={props.chat.composerRef}
                                        placeholder="Send a message"
                                        _focus={{
                                            outline: 'none',
                                            borderColor: 'whiteAlpha.400',
                                        }}
                                    />
                                    <InputRightElement m={5}>
                                        <IconButton
                                            {...props.chat.submit}
                                            aria-label="submit"
                                            borderRadius="full"
                                            icon={
                                                <Icon fontSize="lg" as={AiOutlineSend} />
                                            }
                                            bg="white"
                                            color="black"
                                            _disabled={{
                                                opacity: 0.33,
                                            }}
                                            _hover={{ bg: 'white' }}
                                            _focus={{ outline: 'none' }}
                                        />
                                    </InputRightElement>
                                </InputGroup>
                            </VStack>
                        </HStack>
                    </GridItem>
                </Grid>
            </Layout>
        );
    };
}

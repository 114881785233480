import React from 'react';
import { useStore } from '../../../stores/setupContext';
import queryString from 'querystring';
import { Navigate, useLocation } from 'react-router';
import { Spinner } from '@chakra-ui/react';

function isStringArray(opt: string | string[]): opt is string[] {
    return Array.isArray(opt);
}

export const SocialCallback: React.FC = () => {
    const { auth } = useStore();
    const {
        //isStarted, authToken,
        // loading: isLoading,
        err,
        handloginResponse,
    } = auth;
    const { search } = useLocation();
    const [status, setStatus] = React.useState<
        'success-signup' | 'success-login' | 'failed' | 'pending'
    >('pending');
    React.useEffect(() => {
        if (search && search.length > 0) {
            const token = queryString.parse(search.substring(1));
            if (token) {
                const { access_token, refresh_token, email, signed_up } = token;
                if (
                    email &&
                    access_token &&
                    refresh_token &&
                    !isStringArray(email) &&
                    !isStringArray(access_token) &&
                    !isStringArray(refresh_token)
                ) {
                    handloginResponse(
                        email,
                        {
                            access_token,
                            refresh_token,
                        },
                        undefined,
                        (data) => {
                            if (signed_up == '1') {
                                auth.reportEvent('registration', {
                                    email: email,
                                    first_name: data.first_name,
                                    last_name: data.last_name,
                                    is_social_registration: true,
                                    USER_ID: data.id,
                                });
                            }
                        }
                    )
                        .then((x) =>
                            setStatus(
                                signed_up == '1' ? 'success-signup' : 'success-login'
                            )
                        )
                        .catch(() => setStatus('failed'));
                }
            }
        }
    }, [search]);
    if (search) {
        if (err || status == 'failed') {
            return <Navigate to="/" replace={true} />;
        }
        if (status == 'pending') {
            return <Spinner />;
        }
        if (status == 'success-login') {
            return <Navigate to="/u" replace={true} />;
        }
        if (status == 'success-signup') {
            return <Navigate to="/u/onboarding" replace={true} />;
        }
    }
    return <Navigate to="/" replace={true} />;
};

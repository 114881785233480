import React from 'react';
import {
    Box,
    Button,
    HStack,
    Icon,
    IconButton,
    Image,
    Menu,
    MenuButton,
    MenuGroup,
    MenuItem,
    MenuList,
    Tag,
    Text,
} from '@chakra-ui/react';
// import { Link } from 'react-router-dom';
import { AiOutlinePlus } from 'react-icons/ai';
import varosLogoWhiteSVG from '../../../svg/varos-logo-large-white.svg';
import { UserContainer } from '../../controller';
import { Icons } from '../../../config';
import { GRAY } from '../../config';
import { OrganizationRouteConfig } from './organizationRouteConfig';
import { OrganizationRouteProps } from './organizationRouteProps';

export function createOrganizationRouteView(
    config: OrganizationRouteConfig
): React.FC<OrganizationRouteProps> {
    const {
        deps: {
            Layout,
            UI: { Link },
        },
        controller: Controller,
        views: { User: UserView, Context: ContextView },
    } = config;

    return (props) => {
        const { workspaceUrl, reportsUrl, isVisible, datasetAssetCount } = props;
        return (
            <Layout.Wrapper style={config.layout}>
                <Layout.Brand>
                    {workspaceUrl ? (
                        <Link id={props.getLinkId('brand')} to={workspaceUrl}>
                            <Image src={varosLogoWhiteSVG} width={20} />
                        </Link>
                    ) : (
                        <></>
                    )}
                </Layout.Brand>
                {/* @ts-expect-error */}
                <Layout.Navigation>
                    {props.homeUrl ? (
                        <Layout.Link id={props.getLinkId('home')} to={props.homeUrl}>
                            Home
                        </Layout.Link>
                    ) : (
                        <></>
                    )}
                    {props.creativeCoOpUrl ? (
                        <Layout.Link
                            id={props.getLinkId('creative-co-op')}
                            to={props.creativeCoOpUrl}
                        >
                            <HStack spacing={2}>
                                <Text>Top Creatives</Text>
                                <Tag
                                    size="sm"
                                    fontWeight="bold"
                                    color="blackAlpha.900"
                                    bg="#c6e74f"
                                    letterSpacing="wide"
                                >
                                    New
                                </Tag>
                            </HStack>
                        </Layout.Link>
                    ) : (
                        <></>
                    )}
                    {props.researchUrl ? (
                        <Layout.Link
                            id={props.getLinkId('research')}
                            to={props.researchUrl}
                        >
                            Research
                        </Layout.Link>
                    ) : (
                        <></>
                    )}
                    {props.dashboardUrl ? (
                        <Layout.Link
                            id={props.getLinkId('dashboards')}
                            to={props.dashboardUrl}
                        >
                            Dashboards
                        </Layout.Link>
                    ) : (
                        <></>
                    )}
                    {props.insightsUrl && (
                        <Layout.Link
                            id={props.getLinkId('opportunities')}
                            to={props.insightsUrl}
                        >
                            <HStack spacing={2}>
                                <Text>Opportunities</Text>
                            </HStack>
                        </Layout.Link>
                    )}
                    {props.reportsUrl && (
                        <Layout.Link
                            id={props.getLinkId('reports')}
                            to={props.reportsUrl}
                        >
                            Reports
                        </Layout.Link>
                    )}
                    {props.peersUrl && (
                        <Layout.Link id={props.getLinkId('peers')} to={props.peersUrl}>
                            <HStack spacing={2}>
                                <Text>Peers</Text>
                                <Tag
                                    size="sm"
                                    fontWeight="bold"
                                    color="blackAlpha.900"
                                    bg="#c6e74f"
                                    letterSpacing="wide"
                                >
                                    Beta
                                </Tag>
                            </HStack>
                        </Layout.Link>
                    )}
                    {/* {props.expertCallUrl ? (
                        <Layout.Link
                            id={props.getLinkId('tactical-calls')}
                            to={props.expertCallUrl}
                        >
                            <HStack spacing={2}>
                                <Text>High Performer Calls</Text>
                                <Tag
                                    size="sm"
                                    fontWeight="bold"
                                    color="blackAlpha.900"
                                    bg="#c6e74f"
                                    letterSpacing="wide"
                                >
                                    New
                                </Tag>
                            </HStack>
                        </Layout.Link>
                    ) : (
                        <></>
                    )} */}
                    {/* {props.reportUrl && (
                        <Layout.Link id={props.getLinkId('reports')} to={props.reportUrl}>
                            Audit
                        </Layout.Link>
                    )} */}
                    {props.integrationsUrl && (
                    <Layout.Link
                        id={props.getLinkId('integrations')}
                        to={props.integrationsUrl}
                    >
                        Integrations
                    </Layout.Link>
                    )}

                    {config.layoutItems
                        .filter((item) => item.kind === 'dropdown')
                        .map(({ Component, ...item }, index) => (
                            <Layout.Dropdown key={index} badge={{ children: 2 }}>
                                <Component />
                            </Layout.Dropdown>
                        ))}
                    {/* <Layout.Link id="asd" to="/">
                        <Button
                            colorScheme="green"
                            size="sm"
                            leftIcon={<Icon as={AiOutlinePlus} />}
                            _focus={{ outline: 'none' }}
                        >
                            Add brand
                        </Button>
                    </Layout.Link> */}
                </Layout.Navigation>
                {/* <Layout.DatasetText>
                    <span>
                        Total Companies in the Varos Data Set: {datasetAssetCount}
                    </span>
                </Layout.DatasetText> */}
                <Layout.Menu>
                    <HStack spacing={4}>
                        <Box h="full" py={3}>
                            <Box w="2px" h="full" bg="whiteAlpha.200"></Box>
                        </Box>
                        <HStack spacing={4}>
                            <Menu closeOnSelect={true}>
                                <MenuButton
                                    as={IconButton}
                                    size="sm"
                                    variant="outline"
                                    colorScheme="blue"
                                    borderRadius="full"
                                    icon={<Icon as={AiOutlinePlus} />}
                                    _focus={{ outline: 'none' }}
                                />
                                <MenuList bg={GRAY[600]} zIndex={10}>
                                    <MenuGroup title="Account">
                                        {props.links.newCompany && (
                                            <MenuItem
                                                {...props.links.newCompany}
                                                color="blue.300"
                                                fontWeight="medium"
                                                icon={<Icon as={Icons.Accounts.Asset} />}
                                                _hover={{ bg: 'whiteAlpha.100' }}
                                            >
                                                Company
                                            </MenuItem>
                                        )}
                                        {props.links.newIntegration && (
                                            <MenuItem
                                                {...props.links.newIntegration}
                                                color="blue.300"
                                                fontWeight="medium"
                                                icon={
                                                    <Icon
                                                        as={Icons.Accounts.Integration}
                                                    />
                                                }
                                                _hover={{ bg: 'whiteAlpha.100' }}
                                            >
                                                Integration
                                            </MenuItem>
                                        )}
                                    </MenuGroup>
                                    <MenuGroup title="Peers">
                                        {props.links.newGroup && (
                                            <MenuItem
                                                {...props.links.newGroup}
                                                color="blue.300"
                                                fontWeight="medium"
                                                icon={
                                                    <Icon
                                                        as={Icons.Peers.CompetitiveSets}
                                                    />
                                                }
                                                _hover={{ bg: 'whiteAlpha.100' }}
                                            >
                                                Competitive set
                                            </MenuItem>
                                        )}
                                    </MenuGroup>
                                </MenuList>
                            </Menu>
                            {/* {props.addBrandUrl && (
                                <Link
                                    to={props.addBrandUrl}
                                    id={props.getLinkId('add_company')}
                                >
                                    <Button
                                        h={7}
                                        variant="outline"
                                        colorScheme="green"
                                        size="sm"
                                        leftIcon={<Icon as={AiOutlinePlus} />}
                                        _focus={{ outline: 'none' }}
                                    >
                                        Add company
                                    </Button>
                                </Link>
                            )} */}
                            <UserContainer
                                as={UserView}
                                useController={Controller.useUserNavigation}
                            />
                        </HStack>
                    </HStack>
                </Layout.Menu>
                {ContextView ? (
                    <Layout.Context>
                        <HStack spacing={3}>
                            <ContextView />
                            {/* <Button
                                colorScheme="green"
                                size="sm"
                                rightIcon={<Icon as={AiOutlinePlus} />}
                                _focus={{ outline: 'none' }}
                            >
                                Add brand
                            </Button> */}
                        </HStack>
                    </Layout.Context>
                ) : null}
                <Layout.Body>{props.children}</Layout.Body>
            </Layout.Wrapper>
        );
    };
}

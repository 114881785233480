import React from 'react';
import { GroupsRedirectProps } from './groupsRedirectProps';
import { Navigate } from 'react-router';
import { Spinner } from '@chakra-ui/react';

export const createGroupRedirect = (): React.FC<GroupsRedirectProps> => {
    return (props) => {
        if (props.isLoading) {
            return <Spinner />;
        }
        if (props.to) {
            return <Navigate to={props.to} />;
        }
        return null;
    };
};

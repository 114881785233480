import { z } from 'zod';

export const LabelTimeSchema = z.object({
    kind: z.literal('time'),
    start: z.string(),
    end: z.string(),
});

export const LabelCategorySchema = z.object({
    kind: z.literal('category'),
    key: z.string(),
    display_name: z.string().optional(),
});

export const AnyLabelSchema = z.discriminatedUnion('kind', [
    LabelTimeSchema,
    LabelCategorySchema,
]);

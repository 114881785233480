import React from 'react';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import {
    Box,
    // Button,
    Heading,
    HStack,
    List,
    ListItem,
    Tag,
    Text,
    VStack,
    Wrap,
    WrapItem,
} from '@chakra-ui/react';
import { AssetMappingModuleConfig } from './mappingConfig';
import { AssetMappingViewProps } from './mappingProps';
import { Link } from 'react-router-dom';

export function createAssetMappingView(
    config: AssetMappingModuleConfig['deps']
): React.FC<AssetMappingViewProps> {
    const {
        UI: { Button, AsyncButton },
    } = config;
    return (props) => (
        <Box w="full">
            <VStack alignItems="flex-start" spacing={8} w="full">
                {/* <HStack spacing={8} w="full">
                    <VStack flex={2} alignItems="flex-start" spacing={2}>
                        <Heading>Select your default asset</Heading>
                        <Text color="blackAlpha.700" fontSize="lg">
                            <Box as="span">
                                Our free plans grant access to one brand's data. To unlock
                                multiple brands, upgrade to our{' '}
                            </Box>
                            <Box
                                as="span"
                                display="inline-block"
                                color="blue.500"
                                textDecoration="underline"
                            >
                                <Link to="/settings/plan?type=agency">agency plan</Link>
                            </Box>
                        </Text>
                    </VStack>
                    <Box display="flex" flex={1} justifyContent="flex-end">
                        <AsyncButton
                            onClick={props.onSubmit}
                            minW={16}
                            colorScheme="green"
                            rightIcon={<ArrowForwardIcon />}
                        >
                            Continue
                        </AsyncButton>
                    </Box>
                </HStack> */}
                <List w="full" spacing={4} fontWeight="medium">
                    {props.items.map((item, index) => {
                        const childProps = props.getChildProps(item, index);
                        return (
                            <ListItem
                                onClick={childProps.onSelect}
                                boxShadow={childProps.selected ? 'md' : 'sm'}
                                cursor="pointer"
                                borderRadius="md"
                                key={item.id}
                                px={8}
                                py={6}
                                bg="white"
                                borderColor={childProps.selected ? 'blue.400' : '#CBD5E0'}
                                _hover={{
                                    borderColor: 'blue.300',
                                    boxShadow: 'md',
                                }}
                                borderStyle="solid"
                                borderWidth={1}
                            >
                                <HStack justifyContent="space-between">
                                    <VStack alignItems="flex-start">
                                        <Box>
                                            <Text>{item.name}</Text>
                                        </Box>
                                        <Wrap>
                                            {item.url && (
                                                <WrapItem>
                                                    <Tag
                                                        colorScheme="gray"
                                                        variant="subtle"
                                                    >
                                                        {item.url}
                                                    </Tag>
                                                </WrapItem>
                                            )}
                                        </Wrap>
                                    </VStack>
                                    <Box>
                                        <Tag
                                            variant="subtle"
                                            colorScheme="green"
                                            size="lg"
                                            visibility={
                                                childProps.selected ? 'visible' : 'hidden'
                                            }
                                        >
                                            Default
                                        </Tag>
                                    </Box>
                                </HStack>
                            </ListItem>
                        );
                    })}
                </List>
            </VStack>
        </Box>
    );
}

import { AgentObject } from '@varos/schema';
import { AgentEntity } from '../../../app/conversations';

export function toAgentEntity(dto: AgentObject): AgentEntity {
    return {
        id: dto.id,
        name: dto.name,
        description: `Commodo nisi enim ea magna do reprehenderit in labore`,
        prelude: [],
        // prelude: dto.prelude.map((item) => ({
        //     role: item.role,
        //     text: item.content,
        //     kind: item.type,
        // })),
    };
}

import React, { useMemo } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router';
import { AxiosInstance } from 'axios';
import { useAuthenticatedContext } from '../../../../container';
import { useUserApi } from '../../platform/user';
import { Workspace } from '../../../domain';
import {
    WorkspaceContextDeps,
    WorkspaceContext,
    WorkspaceContextValue,
} from './workspaceContext';
import { useSearchParam } from 'react-use';
import { useSearchParams } from 'react-router-dom';

export interface WorkspaceProviderProps {}

export interface WorkspaceProviderConfig {
    paramKey: string;
    queryKey: string;
    getDeps(config: { workspace: Workspace; api: AxiosInstance }): WorkspaceContextDeps;
    workspaceNotFoundPathName: string;
}

export function createWorkspaceProvider(
    config: WorkspaceProviderConfig
): React.FC<WorkspaceProviderProps> {
    return (props) => {
        const { api } = useAuthenticatedContext();
        const user = useUserApi();
        const navigate = useNavigate();

        const { [config.paramKey]: workspaceParamId, ...rest } = useParams();
        const [searchParams] = useSearchParams();
        const workspaceId = workspaceParamId ?? searchParams.get(config.queryKey);

        if (!workspaceId) {
            const candidates = Object.keys(rest);
            throw new Error(
                `url parameter '${config.paramKey}' not found. found ${candidates.join(
                    ', '
                )}`
            );
        }

        const workspaces = user.getWorkspaces();

        const workspace = workspaces.find(
            (workspace) => workspace.id.toString() === workspaceId
        );

        if (!workspace) {
            const candidates = workspaces.map((workspace) => workspace.id);
            console.warn(
                `workspace ${workspaceId} not found. found: ${candidates.join(', ')}`
            );
            return <Navigate to={config.workspaceNotFoundPathName} replace={true} />;
        }
        const deps = useMemo(() => {
            return config.getDeps({ api, workspace });
        }, [workspace.id]);

        const context: WorkspaceContextValue = {
            adapter: deps.adapter,
            workspace,
        };

        return (
            <WorkspaceContext.Provider value={context}>
                {props.children}
            </WorkspaceContext.Provider>
        );
    };
}

import { observer } from 'mobx-react';
import * as React from 'react';
import { useStore } from '../../../../stores/setupContext';
import { RequestPasswordForm, RequestForgotPasswordProps } from '../../../../components/ForgotPassword/Request';
import { Container, Heading } from '@chakra-ui/layout';
import { Flex } from '@chakra-ui/react';

export const ReqWrapper: React.FunctionComponent<Omit<RequestForgotPasswordProps, 'title' | 'actionName'>> = ({
    doReqForgotPwd,
    isLoading,
    err,
    requested,
}) => {
    return (
        <Flex justifyContent="center" alignItems="center" height="100%" flexDir="column">
            <RequestPasswordForm {...{ doReqForgotPwd, isLoading, err, requested, title: 'Password-Less Login', actionName: 'login' }} />
        </Flex>
    );
};

const Observed = observer(ReqWrapper);

const WithStoreConnection = () => {
    const { setPassword } = useStore();
    const { isLoading, requestPasswordLessLogin: doReqForgotPwd, err, requested } = setPassword;
    return <Observed {...{ doReqForgotPwd, isLoading, err, requested }} />;
};

export default observer(WithStoreConnection);

import React from 'react';
import { ConversationContainerBaseConfig } from '../base';
import { ConversationUrlParamSchema } from '../conversationSchema';
import {
    ConversationLayoutController,
    ConversationLayoutLoader,
    ConversationLayoutStateProvider,
} from './conversationLayoutInterface';
import {
    ConversationLayoutContainerProps,
    ConversationLayoutViewProps,
} from './conversationLayoutProps';

export function createConversationLayoutContainer(
    config: ConversationContainerBaseConfig,
    states: ConversationLayoutStateProvider,
    loader: ConversationLayoutLoader,
    controller: ConversationLayoutController,
    View: React.FC<ConversationLayoutViewProps>
): React.FC<ConversationLayoutContainerProps> {
    const {
        infra: {
            optionParser: { useOptions },
        },
        context: { useContext },
    } = config;
    return ({ children, ...containerProps }) => {
        const options = useOptions(ConversationUrlParamSchema);
        const state = states.useState();
        const context = useContext();
        const data = loader.useLoad(context, containerProps);
        const props = controller.useProps(context, data, {
            ...options,
            detail: state.detail,
        });
        return (
            <View {...containerProps} {...props}>
                {children}
            </View>
        );
    };
}

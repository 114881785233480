import React from 'react';
import { HStack, Text, Image } from '@chakra-ui/react';
import { isNamedEnum } from '../../../domain/attributes';
import { EnumAtomProps } from './enumAtomProps';
import { EnumAtomConfig } from './enumAtomConfig';

export function createEnumAtom(config: EnumAtomConfig): React.FC<EnumAtomProps> {
    return (props) => {
        const member = props.type.members.find((member) => member.value === props.value);
        if (!member) {
            console.warn(`member '${props.value}' not found in enum ${props.type.name}`);
        }
        if (!isNamedEnum(props.type)) {
            return <>{member?.label ?? props.value}</>;
        }
        const iconSource = config.icons[props.type.namespace];
        if (!iconSource) {
            console.warn(
                `namespace ${props.type.namespace} does not have a configured icon`
            );
        }
        return (
            <HStack spacing={4} alignItems="center">
                {iconSource && <Image width={5} src={iconSource} />}
                <Text fontWeight="semibold">{member?.label ?? props.value}</Text>
            </HStack>
        );
    };
}

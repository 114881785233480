import React from 'react';
import { Text, HStack, Link, Box, VStack, Heading } from '@chakra-ui/layout';
import { RequiredKeyInfo } from '../../models/integration';
import { useForm } from 'react-hook-form';
import Input from '../../components/FormControls/Input';
import { Button, Image } from '@chakra-ui/react';
import ErrorView from '../ErrorView/ErrorView';
import { ErrorInfo } from '../../models/Errors';
import { Menu } from '../../ui';

export interface FormByKeysProps {
    requiredKeys: Array<RequiredKeyInfo>;
    logo: string;
    title: string;
    onSubmit: (vals: Record<string, string>) => void;
    onCancel: () => void;
    err: ErrorInfo | null;
    keySuggestions: Record<string, string[]>;
    isLoading?: boolean;
    docsLink?: string;
}

export default function ({
    requiredKeys,
    logo,
    title,
    onSubmit,
    onCancel,
    err,
    isLoading,
    docsLink,
    keySuggestions
}: FormByKeysProps): React.ReactElement {
    const {
        handleSubmit,
        register,
        formState: { errors, isDirty, isValid },
        setValue
    } = useForm<Record<string, string>>({ mode: 'onBlur', reValidateMode: 'onBlur' });
    const submitHandler = React.useCallback(
        (formData: Record<string, string>) => {
            const payload = requiredKeys.reduce<Record<string, string>>((agg, curr) => {
                if (curr.validation_rgx) {
                    const rgx = new RegExp(curr.validation_rgx, 'i');
                    const matchingResult = rgx.exec(formData[curr.key]);
                    if (matchingResult) {
                        return {
                            ...agg,
                            [curr.key]: matchingResult[1],
                        };
                    }
                }
                return agg;
            }, {});
            onSubmit(payload);
        },
        [requiredKeys, onSubmit]
    );
    React.useEffect(() => {
        Object.entries(keySuggestions).forEach(([k, v]) => {
            if(v && v.length > 0) {
                setValue(k, v[0]);
            }
        });
    }, [keySuggestions])

    return (
        <Box
            p={6}
            width={{ base: 'full', md: 'md' }}
            borderWidth={{ base: 'none', md: 'thin' }}
            // borderColor="yellow.400"
            // borderRadius="md"
        >
            <form onSubmit={handleSubmit(submitHandler)}>
                <VStack spacing={4}>
                    <HStack spacing={3}>
                        <Image alt={title} width="70px" src={logo} />
                        <Heading fontSize="sm">To Integrate {title}, please fill the following information:</Heading>
                    </HStack>

                    {requiredKeys.map((rk) => (
                        <React.Fragment key={rk.key}>
                            <Input
                                isDisabled={isLoading}
                                placeholder={rk.label}
                                {...register(rk.key, {
                                    required: rk.validation_msg || 'Required',
                                    ...(rk.validation_rgx && {
                                        pattern: {
                                            value: new RegExp(rk.validation_rgx, 'i'),
                                            message:
                                                rk.validation_msg ||
                                                `Invalid input. valid value must match ${rk.validation_rgx}`,
                                        },
                                    }),
                                })}
                                err={errors[rk.key] && errors[rk.key].message}
                                label={rk.label}
                                type="text"
                                helperText={rk.info}
                            />
                            { keySuggestions[rk.key] && keySuggestions[rk.key].length > 0 && <Menu.List>
                                <Menu.Label sticky={true}>
                                    Suggestions
                                </Menu.Label>
                                {
                                    keySuggestions[rk.key]?.map(x => (
                                        <Menu.Item onClick={() => setValue(rk.key, x)} key={x}>{x}</Menu.Item>
                                    ))
                                }
                            </Menu.List> }
                        </React.Fragment>
                    ))}
                    {docsLink && <Text fontSize='xs' py={2}>More information about how to configure {title} can be found <Link href={docsLink} fontWeight='bold'>here</Link></Text>}
                    {err && <ErrorView {...err} />}
                    <HStack p={3} spacing={2}>
                        <Button variant="outline" colorScheme="blue" type="button" onClick={onCancel}>
                            Cancel
                        </Button>
                        <Button
                            isLoading={isLoading}
                            isDisabled={isDirty && !isValid}
                            variant="solid"
                            colorScheme="blue"
                            type="submit"
                        >
                            Connect
                        </Button>
                    </HStack>
                </VStack>
            </form>
        </Box>
    );
}

import {
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverContent,
    PopoverTrigger,
    Portal,
    Text,
} from '@chakra-ui/react';
import React from 'react';
import { PeerCompanyPopoverProps } from './peerCompanyPopoverProps';

export function createPeerCompanyPopoverView(): React.FC<PeerCompanyPopoverProps> {
    return (props) => {
        return (
            <Popover
                trigger="hover"
                placement={props.placement}
                isLazy={true}
                openDelay={500}
                lazyBehavior="unmount"
            >
                <PopoverTrigger>{props.trigger}</PopoverTrigger>
                <Portal>
                    <PopoverContent
                        p={6}
                        minW="28rem"
                        bg="#1D1C21"
                        border="none"
                        _focus={{ outline: 'none' }}
                        fontSize="md"
                        fontWeight="medium"
                    >
                        <PopoverArrow bg="#1D1C21" />
                        <PopoverBody p={0}>{props.children}</PopoverBody>
                    </PopoverContent>
                </Portal>
            </Popover>
        );
    };
}

import { ThreadCreatePayloadSchema, ThreadObjectSchema } from '@varos/schema';
import { ResourceBaseConfig } from '../support/base';
import { ThreadResource } from './threadInterface';

export function createThreadResource(config: ResourceBaseConfig): ThreadResource {
    return {
        get(threadId, options) {
            return config.endpoint.retrieve({
                url: `/threads/${threadId}`,
                schema: {
                    response: ThreadObjectSchema,
                },
                options,
            });
        },
        create(payload, options) {
            return config.endpoint.create({
                url: '/threads',
                payload: payload,
                schema: {
                    body: ThreadCreatePayloadSchema,
                    response: ThreadObjectSchema,
                },
                options,
            });
        },
    };
}

import { observer } from 'mobx-react';
import React from 'react';
import { Navigate, useLocation } from 'react-router';
import { useUserApi, useWorkspaceApi } from '../../app';
import { HomeRouteConfig } from './homeRouteConfig';

export function createHomeRoute(config: HomeRouteConfig): {
    Route: React.FC;
    WorkspaceRedirect: React.FC;
} {
    const {
        deps: { hook, ...deps },
        providers: {
            root: { createLoader, createController, createView },
        },
    } = config;
    const controller = createController(config);
    const loader = createLoader(config);
    const View = createView(config);

    const DefaultRedirect = () => {
        const location = useLocation();
        const user = useUserApi();
        const homeUrl = user.getCurrentWorkspaceHomeUrl();
        if (!homeUrl) {
            return <></>;
        }
        return <Navigate to={homeUrl} replace={true} state={location.state} />;
    };

    return {
        WorkspaceRedirect: DefaultRedirect,
        Route: observer(() => {
            const {
                deps: {
                    adapter: { useControl },
                },
            } = config;
            const organization = hook.useOrganization();
            const workspace = hook.useWorkspace();
            const auth = hook.useAuth();
            const context = { auth, organization, workspace };
            const valueFormatter = hook.useValueFormatter();
            const legacyFormatter = hook.useFormatter();
            const control = useControl(context.workspace);

            const queries = loader.useData(context, control.value);

            const viewProps = controller.useProps(
                context,
                queries,
                useWorkspaceApi().getWorkspace(),
                { valueFormatter, legacyFormatter }
            );

            return <View {...viewProps} />;
        }),
    };
}

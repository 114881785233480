import React from 'react';
import { SettingAccountItemProviderConfig } from '../../../../../route';
import { SettingAssetPluginConfig } from '../pluginConfig';
import { SettingPluginItemContainerProps } from './pluginItemProps';

export function createSettingPluginItemContainer(
    init: SettingAssetPluginConfig,
    config: Pick<SettingAccountItemProviderConfig, 'api'>
): React.FC<SettingPluginItemContainerProps> {
    const {
        controller: {
            plugin: { item: controller },
        },
    } = init;
    const {
        api: { useContext },
    } = config;
    return ({ as: Component, children, ...props }) => {
        const viewContext = useContext();
        const viewProps = controller.useProps(viewContext, props);
        return <Component {...viewProps}>{children}</Component>;
    };
}

import React from 'react';
import { Select } from 'chakra-react-select';
import { ChakraSelectProps } from 'chakra-react-select/dist/types/types';

export interface Option {
    label: string;
    value: string;
}

export interface MultiSelectInputProps extends Omit<ChakraSelectProps, 'isMulti'> {
    type: 'select';
    isMulti: true;
    onChange(options: Option[]): void;
}

export interface SingleSelectInputProps extends Omit<ChakraSelectProps, 'onChange'> {
    type: 'select';
    isMulti: false;
    onChange(options: Option): void;
}

export type SelectInputProps = MultiSelectInputProps | SingleSelectInputProps;

export const SelectInput: React.FC<SelectInputProps> = (props) => {
    return <Select {...props} />;
};

import React, { useEffect } from 'react';
import { ConversationPluginConfig } from '../../../../route';
import { ConversationEntityController } from './blockEntityInterface';
import {
    ConversationEntityContainerProps,
    ConversationEntityViewProps,
} from './blockEntityProps';

export function createConversationEntityContainer(
    config: ConversationPluginConfig,
    controller: ConversationEntityController,
    View: React.FC<ConversationEntityViewProps>
): React.FC<ConversationEntityContainerProps> {
    const {
        context: { useContext },
    } = config;
    return (containerProps) => {
        const context = useContext();
        const props = controller.useProps(containerProps.item, {});
        return <View {...props} />;
    };
}

import { AxiosInstance } from 'axios';
import { LegacyAuthenticationScheme } from '../../common';
import { DatasetResource } from './datasetInterface';
import { DatasetListDtoSchema } from './datasetSchema';

export function createDatasetResource(client: AxiosInstance): DatasetResource {
    return {
        async list(context, query) {
            const scheme = context.auth.scheme as LegacyAuthenticationScheme;
            await scheme.store.waitAuthenticated();
            let params: Record<string, any> = {}
            if (query.types) {
                params['types'] = query.types;
            }
            if(query.slugs) {
                params['slugs'] = query.slugs;
            }
            
            const response = await client.get('/api/v1/datasets', {
                params,
                headers: {
                    Authorization: `bearer ${scheme.store.authToken}`,
                },
            });
            const parsed = DatasetListDtoSchema.parse(response.data);
            return parsed;
        },
        async lookup(context, query) {
            throw new Error('not impl');
        },
    };
}

import { assert } from '../../../util/assert';
import { ConversationMessageAdapter } from '../../../app/conversations';
import { MessageImplConfig } from './messageImplConfig';
import { toMessageEntity } from './messageImplSerdes';

export function createMessageImpl(config: MessageImplConfig): ConversationMessageAdapter {
    const {
        api: { conversation: api },
    } = config;

    return {
        async find(context, props) {
            assert(context.auth.scheme.kind === 'legacy', 'expected legacy auth');
            assert(context.auth.scheme.store.authToken, 'missing token');
            const response = await api.message.list(props.thread.id, {
                token: context.auth.scheme.store.authToken,
            });
            return response.data.flatMap(toMessageEntity);
        },
    };
}

import React from 'react';
import {
    Box,
    Button,
    Grid,
    GridItem,
    Heading,
    HStack,
    Icon,
    Image,
    Text,
    VStack,
} from '@chakra-ui/react';
import { ApplicationError } from '../../../../base';
import { renderCardError } from '../../../../view';
import { AnalysisContentViewProps } from './contentProps';
import { AnalysisRouteDeps } from '../analysisRouteConfig';
import { VisualizationControllerProps } from '../visualization';
import { AiOutlineBarChart, AiOutlineCaretDown, AiOutlineDown } from 'react-icons/ai';
import { FiChevronDown } from 'react-icons/fi';

export function createContentView(
    config: AnalysisRouteDeps,
    Visualization: React.FC<VisualizationControllerProps>
): React.FC<AnalysisContentViewProps> {
    const {
        UI: { createErrorBoundary, EmptyState },
    } = config;
    const ErrorBoundary = createErrorBoundary({
        errorClass: Error,
    });
    return (props) => {
        const visualizationProps = props.getVisualizationProps();
        const showTitle = props.getShowTitle();
        return (
            <Grid gap={16}>
                <GridItem>
                    <VStack
                        overflow="hidden"
                        align="start"
                        borderStyle="solid"
                        borderColor="whiteAlpha.300"
                        borderTopWidth={showTitle ? 2 : 0}
                    >
                        <Box w="full">
                            <Grid templateRows={showTitle ? 'min-content 1fr' : '1fr'}>
                                {showTitle && (
                                    <GridItem>
                                        <HStack
                                            w="full"
                                            // px={6}
                                            pt={6}
                                            justify="space-between"
                                        >
                                            <HStack spacing={4} flexShrink={0}>
                                                <Box
                                                    p={2}
                                                    borderRadius="md"
                                                    bg="whiteAlpha.300"
                                                    flexShrink={0}
                                                >
                                                    {props.plugin.iconUrl && (
                                                        <Image
                                                            h={5}
                                                            w={5}
                                                            src={props.plugin.iconUrl}
                                                        />
                                                    )}
                                                </Box>
                                                <Heading
                                                    whiteSpace="nowrap"
                                                    fontWeight="semibold"
                                                    letterSpacing="wide"
                                                    fontSize="lg"
                                                    color="whiteAlpha.900"
                                                >
                                                    {props.getQueryRequestLabel()}
                                                </Heading>
                                            </HStack>
                                        </HStack>
                                    </GridItem>
                                )}
                                <GridItem>
                                    <Box py={3}>
                                        <ErrorBoundary
                                            // @ts-expect-error
                                            fallback={renderCardError}
                                            key={visualizationProps.statehash}
                                        >
                                            <Visualization {...visualizationProps} />
                                        </ErrorBoundary>
                                    </Box>
                                </GridItem>
                            </Grid>
                        </Box>
                    </VStack>
                </GridItem>
            </Grid>
        );
    };
}

import React from 'react';
import { z } from 'zod';
import { createConversationPlugin } from '../../../../route';
import { ConversationSurveyConfig } from './blockSurveyConfig';
import { createConversationSurveyContainer } from './blockSurveyContainer';
import { createConversationSurveyController } from './blockSurveyController';
import { createConversationSurveyView } from './blockSurveyView';

export function createConversationSurveyPlugin(init: ConversationSurveyConfig) {
    return createConversationPlugin({
        type: 'survey',
        schema: z
            .object({
                survey: z.string(),
            })
            .transform((item) => ({
                survey: { id: item.survey },
            }))
            .pipe(
                z.object({
                    survey: z.object({
                        id: z.string(),
                    }),
                })
            ),
        create(config) {
            const Component = createConversationSurveyContainer(
                config,
                createConversationSurveyController(config),
                createConversationSurveyView(config)
            );
            return {
                Component(props) {
                    return <Component survey={props.data.survey} />;
                },
            };
        },
    });
}

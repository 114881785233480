import React from 'react';
import { UserNavigationViewProps } from '../../../view/platform';
import { UserNavigationController } from './userSelectionInterface';

export interface UserContainerProps {
    as: React.FC<UserNavigationViewProps>;
    useController: UserNavigationController;
}

export const UserContainer: React.FC<UserContainerProps> = ({
    as: Component,
    useController,
    ...props
}) => {
    const viewProps = useController();
    return <Component {...viewProps}>{props.children}</Component>;
};

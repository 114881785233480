import {
    Box,
    Button,
    HStack,
    StackDivider,
    Tag,
    TagProps,
    Text,
    VStack,
} from '@chakra-ui/react';
import React from 'react';
import { SubmissionItemViewProps } from '../../../../../view/studies';
import { StudiesOverviewRouteConfig } from '../../../overview/studiesOverviewConfig';

export function createSubmissionStatusView(
    config: Pick<StudiesOverviewRouteConfig, 'UI'>
): React.FC<SubmissionItemViewProps['status'] & Pick<TagProps, 'size' | 'cursor'>> {
    const {
        UI: { Tooltip },
    } = config;
    return (props) => {
        const { cursor = 'default' } = props;
        return (
            <Tooltip
                placement="top"
                hasArrow={true}
                p={4}
                bg="#1D1C21"
                label={
                    <VStack
                        align="start"
                        w="full"
                        spacing={2}
                        divider={
                            <StackDivider borderColor="whiteAlpha.200" borderWidth={1} />
                        }
                    >
                        <HStack spacing={2}>
                            <Box
                                h={2}
                                w={2}
                                borderRadius="full"
                                bg={`${props.colorScheme}.400`}
                            />
                            <Text fontWeight="semibold">{props.label}</Text>
                        </HStack>
                        <Text color="whiteAlpha.700">{props.description}</Text>
                    </VStack>
                }
                isDisabled={props.description === null}
            >
                <Tag
                    flexShrink={0}
                    cursor={cursor}
                    size={props.size}
                    colorScheme={props.colorScheme}
                >
                    {props.label}
                </Tag>
            </Tooltip>
        );
    };
}

import { observer } from 'mobx-react';
import * as React from 'react';
import { useStore } from '../../../stores/setupContext';
import {
    EmailPass,
    EmailPassProps,
} from '../../../components/Registration/EmailPass/EmailPass';
import { Box, Flex, Heading } from '@chakra-ui/layout';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { z } from 'zod';
import { useSessionStorage } from 'react-use';
import { fromPairs } from 'lodash';
import { ApplicationEntrypointStatic } from '../ApplicationDepsStatic';

const {
    repository: {
        registration: { option: options },
    },
    hook: {
        onboarding: { useLocalStorage },
    },
} = ApplicationEntrypointStatic;

export const RegisterSearchParamsSchema = z
    .object({
        source: z
            .union([z.literal('report'), z.unknown()])
            .transform((value) => (value === 'report' ? 'report' : undefined)),
    })
    .partial();

export const RegisterWrapper: React.FunctionComponent<EmailPassProps> = ({
    doRegister,
    isLoading,
    err,
}) => {
    // const [, setAccountType] = useSessionStorage<string | null>(KEY, null);

    return (
        <Flex justifyContent="center" alignItems="center" height="100%" flexDir="column">
            {/* <Heading fontSize="lg" marginBottom={4}>
                Create a Varos account
            </Heading> */}
            <EmailPass {...{ doRegister, isLoading, err }} />
        </Flex>
    );
};

const Observed = observer(RegisterWrapper);

const WithStoreConnection = () => {
    const { registration, auth } = useStore();
    const [searchParams] = useSearchParams();

    const { authToken, loading } = auth;
    const { isLoading, err, register: doRegister } = registration;
    // const history = useNavigate();

    const mutation = {
        option: options.useCreate({
            auth: {
                user: null,
                scheme: { kind: 'legacy', store: auth },
            },
        }),
    };

    const parsed = React.useMemo(
        () => RegisterSearchParamsSchema.parse(fromPairs([...searchParams.entries()])),
        [searchParams]
    );

    React.useEffect(() => {
        if (parsed.source) {
            console.log(`saving '${parsed.source}' account source in session storage`);
            mutation.option.mutateAsync({
                source: parsed.source ?? null,
            });
        } else {
            console.log(`no account source specified, resetting`);
            mutation.option.mutateAsync(null);
        }
    }, [parsed.source]);

    return <Observed {...{ doRegister, isLoading, err }} />;
};

export default observer(WithStoreConnection);

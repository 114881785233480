import React from 'react';
import {
    VStack,
    FormControl,
    FormLabel,
    FormErrorMessage,
    Button as ChakraButton,
    Alert,
    AlertIcon,
    AlertTitle,
} from '@chakra-ui/react';
import { FaArrowRight, FaExclamationTriangle } from 'react-icons/fa';
import { PaymentElement } from '@stripe/react-stripe-js';
import { SettingsBillingViewConfig } from '../../base';
import { PaymentMethodEditViewProps } from './paymentMethodEditProps';

export function createPaymentMethodEditView(
    config: SettingsBillingViewConfig
): React.FC<PaymentMethodEditViewProps> {
    const {
        settings: {
            Layout: {
                Container,
                Content,
                Header: {
                    Action: { List: ActionList },
                    Container: Header,
                    Breadcrumbs: { List: BreadcrumbsList, Item: BreadcrumbsItem },
                },
            },
        },
    } = config;

    return (props) => {
        const statusProps = props.page.getStatusProps();
        if (statusProps?.isDisabled) {
            return (
                <Container>
                    <Header>
                        <BreadcrumbsList>
                            <BreadcrumbsItem to="..">Payment</BreadcrumbsItem>
                            <BreadcrumbsItem>Add payment method</BreadcrumbsItem>
                        </BreadcrumbsList>
                    </Header>
                    <Content>
                        <Alert status="warning">
                            <AlertIcon />
                            <AlertTitle>{statusProps.description}</AlertTitle>
                        </Alert>
                    </Content>
                </Container>
            );
        }
        return (
            <form
                style={{ width: '100%', height: '100%' }}
                {...props.form.getFormElementProps()}
            >
                <Container>
                    <Header>
                        <BreadcrumbsList>
                            <BreadcrumbsItem to="..">Payment</BreadcrumbsItem>
                            <BreadcrumbsItem>Add payment method</BreadcrumbsItem>
                        </BreadcrumbsList>
                    </Header>
                    <Content>
                        <VStack
                            align="start"
                            w="full"
                            maxW="32rem"
                            p={6}
                            borderWidth={2}
                            borderColor="whiteAlpha.300"
                            borderRadius="lg"
                            boxShadow="md"
                        >
                            <VStack spacing={6} align="stretch" w="full">
                                <FormControl>
                                    <FormLabel>Payment Details</FormLabel>
                                    <PaymentElement {...props.payment} />
                                </FormControl>

                                {props.form.error && (
                                    <FormErrorMessage>
                                        {props.form.error?.label}
                                    </FormErrorMessage>
                                )}

                                <ChakraButton
                                    {...props.form.getSubmitButtonProps()}
                                    colorScheme="blue"
                                    w="full"
                                    _focus={{ outline: 'none' }}
                                >
                                    Add payment method
                                </ChakraButton>
                            </VStack>
                        </VStack>
                    </Content>
                </Container>
            </form>
        );
    };
}

import {
    Link as ChakraLink,
    Icon,
    Text,
    Button,
    Center,
    VStack,
    Alert,
    AlertTitle,
    AlertIcon,
    HStack,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { SettingAccountItemProviderConfig } from '../../../../../../route';
import { SettingDataSourceConfig } from '../../sourceConfig';
import { SettingDataSourceViewProps } from '../../sourceProps';
import { AiOutlinePlus } from 'react-icons/ai';
import { Link } from 'react-router-dom';

export function createSettingDataSourceEmptyView(
    init: SettingDataSourceConfig,
    config: SettingAccountItemProviderConfig
): React.FC<SettingDataSourceViewProps> {
    const { controller } = init;
    const {
        Layout: {
            Container,
            Content,
            Panel,
            List,
            Header: {
                Container: Header,
                Breadcrumbs: { List: BreadcrumbsList, Item: BreadcrumbsItem },
                Action: { List: ActionList },
            },
        },
    } = config;

    return (props) => {
        const pageProps = props.getPageProps();
        const breadcrumbsProps = pageProps.getBreadcrumbProps();
        const viewProps = props.getViewProps();
        return (
            <Container>
                <Header>
                    <BreadcrumbsList>
                        {breadcrumbsProps.items.map((item, index) => (
                            <BreadcrumbsItem
                                key={index}
                                to={item.to ?? undefined}
                                description={item.description}
                            >
                                {item.label}
                            </BreadcrumbsItem>
                        ))}
                    </BreadcrumbsList>
                </Header>
                <Content>
                    <Alert status="warning">
                        <AlertIcon></AlertIcon>
                        <AlertTitle w="full">
                            <HStack w="full" justify="space-between">
                                <ChakraLink as={Link} {...props.getNewLinkProps()}>
                                    Data sources are not configured
                                </ChakraLink>
                                <ChakraLink as={Link} {...props.getNewLinkProps()}>
                                    Add data source
                                </ChakraLink>
                            </HStack>
                        </AlertTitle>
                    </Alert>
                </Content>
            </Container>
        );
    };
}

import * as React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { RegistrationRequest, SocialLogin } from '../../../models/Account';
import isEmail from 'validator/es/lib/isEmail';
import { ErrorInfo } from '../../../models/Errors';
import GoogleLogin from 'react-google-login';
import Input from '../../FormControls/Input';
import {
    Box,
    HStack,
    VStack,
    Container,
    Heading,
    SimpleGrid,
    GridItem,
} from '@chakra-ui/layout';
import {
    Link,
    Button,
    Text,
    FormControl,
    Image,
    Center,
    Spacer,
    useMediaQuery,
} from '@chakra-ui/react';
import { Link as RRDLink } from 'react-router-dom';
import ErrorView from '../../ErrorView/ErrorView';
import varosLogoSVG from '../../../svg/varos-logo-small.svg';
import Connor from '../../../onboarding-images/Connor.png';
import continueSVG from '../../../svg/play.svg';
import googleSVG from '../../../svg/google-logo.svg';

export interface EmailPassProps {
    isLoading: boolean;
    err: ErrorInfo | null;
    doRegister: (user: RegistrationRequest, socialPayload?: SocialLogin) => void;
    title?: string;
    subTitle?: string;
}

const RecommendedCustomers: React.FC<{}> = ({}) => {
    return (
        <VStack w="full" h="full" alignItems="flex-start" p={10} pb={0}>
            <Box>
                <Image src={varosLogoSVG} />
            </Box>
            {/* <Text py={1} color="onboarding.subtitle">
                Manage Your Data Easily
            </Text> */}
            <Text color="onboarding.subtitle">
                "I can’t believe I didn’t have access to this data before Varos. It’s a
                total game changer.”
            </Text>
            <Box pt={5} pb={5}>
                <Text fontWeight="bold" fontSize="sm">
                    Connor MacDonald
                </Text>
                <Text fontSize="sm">CMO of The Ridge</Text>
            </Box>
            <Spacer />
            <Box pl={10}>
                <Image src={Connor} />
            </Box>
        </VStack>
    );
};

export const EmailPass: React.FunctionComponent<EmailPassProps> = ({
    isLoading,
    err,
    doRegister,
    title = 'Sign Up For Free',
    subTitle = 'Takes 2 minutes to complete onboarding',
}) => {
    const { handleSubmit, register, control, getValues, formState, setError } = useForm({
        mode: 'onBlur',
        reValidateMode: 'onBlur',
    });

    const [isLargerThan960] = useMediaQuery('(min-width: 960px)');
    const [googleError, setGoogleError] = React.useState<string>();

    React.useEffect(() => {
        if (err && err.fieldErrors) {
            err.fieldErrors.forEach(({ loc, msg }) => {
                if (loc.length == 2) {
                    setError(loc[1], {
                        message: msg,
                        type: 'invalid',
                    });
                } else if (loc.length == 3 && loc[1] == 'organization') {
                    let field = null;
                    switch (loc[2]) {
                        case 'name':
                            field = 'organization_name';
                            break;
                        case 'domain':
                            field = 'organization_domain';
                            break;
                        default:
                            break;
                    }
                    if (field) {
                        setError(field, {
                            message: msg,
                            type: 'invalid',
                        });
                    }
                }
            });
        }
    }, [err]);
    const onSubmit = async (data: any) => {
        const {
            first_name,
            last_name,
            email,
            title,
            organization_name,
            organization_url,
            password,
        } = data;
        doRegister({
            //   first_name,
            //   last_name,
            email,
            //   title,
            password,
            with_customer: true,
            //   organization: {
            //     name: organization_name,
            //     domain: organization_url,
            //   },
        });
    };
    const responseGoogle = React.useCallback((googleUser: any) => {
        const googleProf = googleUser.getBasicProfile();
        // getHostedDomain
        doRegister(
            {
                first_name: googleProf.getGivenName(),
                last_name: googleProf.getFamilyName(),
                email: googleProf.getEmail(),
                with_customer: true,
                // organization: {
                //     domain: googleProf.getHostedDomain(),
                // }
            },
            {
                social_type: 'google',
                token: googleUser.tokenId,
                access_token: googleUser.accessToken,
                email: googleProf.getEmail(),
            }
        );
    }, []);
    const responseFailedGoogle = React.useCallback(
        ({ error, details }) => {
            setGoogleError(details);
        },
        [setGoogleError]
    );
    //   useGoogleLogin({
    //     clientId: "240057280138-2honutvt52p3h127oh714vci3t6rlo6s.apps.googleusercontent.com",
    //     onSuccess: responseGoogle,
    //     onFailure: responseFailedGoogle,
    //     cookiePolicy: 'single_host_origin'
    //   })
    return (
        <Container centerContent maxW="container.xl">
            <HStack w="full" h="full" spacing={1} align="stretch">
                {isLargerThan960 ? (
                    <VStack w="full" h="full" p={10} spacing={2}></VStack>
                ) : (
                    <></>
                )}
                <VStack
                    w="full"
                    h="full"
                    p={10}
                    align="stretch"
                    spacing={8}
                    border="1px"
                    borderColor="frame"
                    borderRadius={10}
                    bg="box"
                >
                    <VStack>
                        <Heading size="lg" textAlign="center" color="onboarding.title">
                            {title}
                        </Heading>
                        <Text fontSize="md" color="onboarding.subtitle">
                            {subTitle}
                        </Text>
                    </VStack>
                    <form onSubmit={handleSubmit(onSubmit)} id="reg_frm">
                        <SimpleGrid columns={1} rowGap={6} w="full" h="full">
                            <GridItem colSpan={1}>
                                <FormControl>
                                    <Input
                                        bg="white"
                                        placeholder="Email"
                                        {...register('email', {
                                            required: 'This field is required!',
                                            validate: (v) => {
                                                if (!isEmail(v)) {
                                                    return 'Invalid email address!';
                                                }
                                            },
                                        })}
                                        label="Email Address"
                                        err={
                                            formState.errors.email &&
                                            formState.errors.email.message
                                        }
                                        type="text"
                                    />
                                </FormControl>
                            </GridItem>
                            <GridItem colSpan={1}>
                                <FormControl>
                                    <Input
                                        bg="white"
                                        placeholder="Password"
                                        {...register('password', {
                                            required: 'This field is required!',
                                        })}
                                        label="Password"
                                        err={
                                            formState.errors.password &&
                                            formState.errors.password.message
                                        }
                                        type="password"
                                    />
                                </FormControl>
                            </GridItem>

                            <GridItem colSpan={1}>
                                <Button
                                    isLoading={isLoading}
                                    w="full"
                                    textColor="button.white"
                                    rightIcon={<Image pt={0.5} src={continueSVG} />}
                                    boxShadow="lg"
                                    bg="button.green"
                                    type="submit"
                                    id="register_btn"
                                    _hover={{ bg: 'button.hoverGreen' }}
                                    isDisabled={isLoading}
                                >
                                    Continue
                                </Button>
                            </GridItem>
                            <GridItem>
                                <Center>
                                    <Text>
                                        Already have an account?
                                        <Link
                                            fontSize="lg"
                                            pl={2}
                                            color="link.green"
                                            as={RRDLink}
                                            to={'/login'}
                                        >
                                            Log In
                                        </Link>
                                    </Text>
                                </Center>
                            </GridItem>
                            {err && <ErrorView {...err} />}

                            <GoogleLogin
                                clientId="240057280138-2honutvt52p3h127oh714vci3t6rlo6s.apps.googleusercontent.com"
                                className="google_reg_btn"
                                onSuccess={responseGoogle}
                                onFailure={responseFailedGoogle}
                                cookiePolicy={'single_host_origin'}
                                render={(renderProps) => (
                                    <Button
                                        w="full"
                                        color="button.googleText"
                                        py={7}
                                        onClick={renderProps.onClick}
                                        bg="button.white"
                                        borderRadius="xl"
                                        boxShadow="xl"
                                        leftIcon={<Image src={googleSVG} />}
                                    >
                                        Sign Up with Google
                                    </Button>
                                )}
                            />
                            {googleError && googleError !== 'R' && (
                                <Text color="error">{googleError}</Text>
                            )}
                        </SimpleGrid>
                    </form>
                </VStack>
                {isLargerThan960 ? <RecommendedCustomers /> : <></>}
            </HStack>
        </Container>
    );
};

import {
    Alert,
    AlertDescription,
    AlertIcon,
    AlertTitle,
    Box,
    Button,
    Center,
    FormControl,
    FormHelperText,
    FormLabel,
    Grid,
    GridItem,
    Heading,
    HStack,
    Icon,
    Image,
    Input,
    InputGroup,
    InputRightElement,
    NumberInput,
    NumberInputField,
    Spinner,
    Stack,
    StackDivider,
    Tag,
    Text,
    Textarea,
    VStack,
} from '@chakra-ui/react';
import React from 'react';
import { Icons } from '../../../../../config';
import { createInputContainer } from '../../../../ui';
import { OptionItem, OptionList } from '../../../common';
import { NetworkBaseRouteConfig } from '../../base';
import { NetworkRequestNewViewProps } from './networkRequestNewProps';
import { createCandidateFilterListView, NetworkInput } from './candidate';
import { createMetricSelect } from './metric';
import { createRequestNewCandidateStatusView } from './candidate/status';
import { AiOutlineInfoCircle, AiOutlineMoneyCollect } from 'react-icons/ai';

export function createNetworkRequestNewRouteView(
    config: NetworkBaseRouteConfig
): React.FC<NetworkRequestNewViewProps> {
    const {
        UI: {
            Application: { Link, Tooltip },
        },
        Layout: {
            Page: { Container, Header, SectionList, SectionItem },
        },
    } = config;

    const InputContainer = createInputContainer();
    const MetricSelect = createMetricSelect();
    const CandidateFilterList = createCandidateFilterListView();
    const CandidateStatus = createRequestNewCandidateStatusView();

    return (props) => {
        return (
            <form {...props.form.getFormElementProps()} style={{ width: '100%' }}>
                <Container>
                    <Header {...props.page.getHeaderProps()} />
                    <SectionList>
                        <SectionItem
                            id="metric"
                            Icon={Icons.Networks.Target}
                            title="Select performance criteria"
                            description={
                                <HStack align="start" color="whiteAlpha.700">
                                    <Text>
                                        Specify the criteria that High Performers will be
                                        ranked by. For example, if you set the performance
                                        metric to Cost Per Purchase and the threshold to
                                        $20, we will only look for matches with users that
                                        have a CPP below $20
                                    </Text>
                                </HStack>
                            }
                        >
                            <HStack
                                w="full"
                                borderStyle="solid"
                                borderColor="whiteAlpha.300"
                                borderWidth={2}
                                p={6}
                                borderRadius="md"
                            >
                                <VStack
                                    w="full"
                                    align="start"
                                    spacing={6}
                                    divider={
                                        <StackDivider
                                            borderColor="whiteAlpha.300"
                                            borderWidth={1}
                                        />
                                    }
                                >
                                    <HStack w="full" justify="space-between">
                                        <VStack
                                            w="full"
                                            align="start"
                                            spacing={1}
                                            flex={1}
                                        >
                                            <Text fontWeight="semibold">
                                                Performance metric
                                            </Text>
                                            <Text color="whiteAlpha.700" maxW="16rem">
                                                Select metric to evaluate performance
                                            </Text>
                                        </VStack>
                                        <HStack minW="min-content">
                                            <MetricSelect {...props.metric.input} />
                                        </HStack>
                                    </HStack>
                                    <HStack w="full" justify="space-between">
                                        <VStack
                                            w="full"
                                            align="start"
                                            spacing={1}
                                            flex={1}
                                        >
                                            <Text fontWeight="semibold">Threshold</Text>
                                            <Text color="whiteAlpha.700" maxW="16rem">
                                                Set performance threshold
                                            </Text>
                                        </VStack>
                                        <HStack minW="min-content" spacing={4}>
                                            <HStack
                                                color="whiteAlpha.700"
                                                fontWeight="semibold"
                                                textTransform="uppercase"
                                            >
                                                <Text
                                                    fontSize="xs"
                                                    whiteSpace="nowrap"
                                                    cursor="default"
                                                >
                                                    {props.metric.item?.isInverse === true
                                                        ? 'Less than'
                                                        : null}
                                                    {props.metric.item?.isInverse ===
                                                    false
                                                        ? 'Greater than'
                                                        : null}
                                                </Text>
                                                {/* <Tooltip
                                                    id="expert_call_form_threshold_tooltip"
                                                    placement="top"
                                                    hasArrow={true}
                                                    p={4}
                                                    bg="#1D1C21"
                                                    label={
                                                        <Text>
                                                            For example, if you set the
                                                            performance metric to Cost Per
                                                            Purchase and the threshold to
                                                            $20, we will only look for
                                                            matches with users that have a
                                                            CPP below $20
                                                        </Text>
                                                    }
                                                >
                                                    <HStack>
                                                        <Icon
                                                            // position="relative"
                                                            // top={1}
                                                            as={AiOutlineInfoCircle}
                                                        />
                                                    </HStack>
                                                </Tooltip> */}
                                            </HStack>
                                            <InputGroup>
                                                <NumberInput
                                                    key={
                                                        // reset input value when metric changes
                                                        // this is a hack because setting value
                                                        // to undefined makes the input behave
                                                        // as an uncontrolled input
                                                        props.metric.input.current?.value
                                                    }
                                                    {...props.threshold.input}
                                                >
                                                    <NumberInputField placeholder="Enter threshold" />
                                                </NumberInput>
                                                {props.metric.unit && (
                                                    <InputRightElement color="whiteAlpha.500">
                                                        <Icon
                                                            as={props.metric.unit.Icon}
                                                        />
                                                    </InputRightElement>
                                                )}
                                            </InputGroup>
                                        </HStack>
                                    </HStack>
                                </VStack>
                            </HStack>
                        </SectionItem>
                        {/* <SectionItem
                            id="experttype"
                            title="Choose an expert type"
                            description={null}
                        >
                            <OptionList
                                {...props.expertType.input}
                                as={<Stack w="full" spacing={3} direction="column" />}
                            />
                        </SectionItem> */}
                        <SectionItem
                            id="filters"
                            Icon={Icons.Networks.Filter}
                            title="Filter candidates"
                            description="Define the criteria for companies you want to match with"
                        >
                            <Grid w="full" templateColumns="1fr" gap={6}>
                                {!props.metric.input.current && (
                                    <Alert status="warning">
                                        <AlertIcon />
                                        <AlertTitle>
                                            No performance criteria selected
                                        </AlertTitle>
                                    </Alert>
                                )}
                                {props.metric.input.current && (
                                    <>
                                        <GridItem>
                                            <CandidateFilterList
                                                {...props.peer.filter.filter}
                                            />
                                        </GridItem>
                                        <GridItem>
                                            <CandidateStatus {...props} />
                                        </GridItem>
                                    </>
                                )}
                            </Grid>
                        </SectionItem>
                        {/* <SectionItem
                            id="budget"
                            Icon={Icons.Networks.Budget}
                            title="Set a budget"
                            description="Set your budget for a one-hour consultation and we'll match you with experts within that range"
                        >
                            <HStack
                                w="full"
                                borderStyle="solid"
                                borderColor="whiteAlpha.300"
                                borderWidth={2}
                                p={6}
                                borderRadius="md"
                                justify="space-between"
                            >
                                <HStack w="full" spacing={4}>
                                    <Text fontWeight="semibold">Hourly budget</Text>
                                </HStack>
                                <HStack w="full">
                                    <InputContainer
                                        as={NetworkInput}
                                        {...props.budget.input}
                                    />
                                </HStack>
                            </HStack>
                        </SectionItem> */}
                        <SectionItem
                            id="review"
                            Icon={Icons.Networks.AdditionalDetail}
                            title="Add additional details"
                            description="Include any additional details that might help us identify the most relevant match"
                        >
                            <FormControl>
                                <FormLabel>Notes (optional)</FormLabel>
                                <Textarea
                                    placeholder="Add note"
                                    {...props.form.form.register('note')}
                                />
                                <FormHelperText>
                                    Eg. "looking for experts who have scaled businesses
                                    from 6 to 7 figures in one year"
                                </FormHelperText>
                            </FormControl>
                        </SectionItem>
                        <SectionItem
                            id="submit"
                            Icon={Icons.Networks.Submit}
                            title="Submit call request"
                            description="Submit request to be matched with a high performer. You'll receive an email with details and can then decide if you want to proceed"
                        >
                            {!props.form.form.formState.isSubmitSuccessful && (
                                <>
                                    <VStack w="full" align="start">
                                        <Button
                                            type="submit"
                                            colorScheme="green"
                                            {...props.form.getSubmitButtonProps()}
                                        >
                                            Submit request
                                        </Button>
                                        <HStack color="whiteAlpha.600">
                                            <Icon
                                                fontSize="sm"
                                                as={AiOutlineInfoCircle}
                                            />
                                            <Text fontSize="sm">
                                                Submitting this request does not incur any
                                                charges or commitments
                                            </Text>
                                        </HStack>
                                    </VStack>
                                </>
                            )}
                            {props.form.form.formState.isSubmitSuccessful && (
                                <>
                                    <Alert
                                        status="success"
                                        w="full"
                                        flexDir="column"
                                        alignItems="start"
                                        p={6}
                                    >
                                        <AlertTitle flexShrink={0} whiteSpace="nowrap">
                                            <HStack spacing={0}>
                                                <AlertIcon boxSize="1rem" />
                                                <Text>Call requested</Text>
                                            </HStack>
                                        </AlertTitle>
                                        <AlertDescription
                                            mt={1}
                                            fontWeight="medium"
                                            color="whiteAlpha.700"
                                        >
                                            You will receive an email with more details
                                            once you have been matched with a top
                                            performer
                                        </AlertDescription>
                                    </Alert>
                                </>
                            )}
                        </SectionItem>
                    </SectionList>
                </Container>
            </form>
        );
    };
}

import React from 'react';
import { NetworkContextProvider } from '../../base';
import {
    NetworkRequestNewController,
    NetworkRequestNewDepsProvider,
    NetworkRequestNewLoader,
} from './networkRequestNewInterface';
import { NetworkRequestNewViewProps } from './networkRequestNewProps';

export function createNetworkRequestNewRoute(
    context: NetworkContextProvider,
    deps: NetworkRequestNewDepsProvider,
    controller: NetworkRequestNewController,
    loader: NetworkRequestNewLoader,
    View: React.FC<NetworkRequestNewViewProps>
): React.FC {
    return (props) => {
        const viewContext = context.useContext();
        const viewDeps = deps.useDeps(viewContext);
        const viewData = loader.useLoad(viewContext, viewDeps);
        const viewProps = controller.useProps(viewContext, viewDeps, viewData);
        return <View {...viewProps} />;
    };
}

import React, { useEffect, useMemo } from 'react';
import {
    Tooltip,
    Box,
    Flex,
    Text,
    Button,
    ButtonGroup,
    Checkbox,
    HStack,
    VStack,
    UseCheckboxProps,
} from '@chakra-ui/react';
import { DefaultValues, SubmitHandler, useForm, UseFormProps } from 'react-hook-form';
import {
    ContextFreePeriod,
    DateRange,
    daterangeToDuration,
    isAbsolutePeriod,
    isRelativePeriod,
    Period,
} from '../../../domain';
import { TimeGranularityType } from '../../../models/Common';
import { DateRangeOption, DateRangePicker } from './DateRange';

export interface DateFilterFormValues {
    period: ContextFreePeriod;
    comparison: Period | null;
    granularity: TimeGranularityType;
}

export interface DateFilterProps
    extends Pick<UseFormProps<DateFilterFormValues>, 'defaultValues'> {
    granularity: TimeGranularityType;
    comparison: Period | null;
    getPresets(
        current: Period,
        minDate?: Date
    ): {
        period: DateRangeOption[];
        comparison: DateRangeOption[];
    };
    onChange(value: DateFilterFormValues): unknown;
    minDate?: Date;
}

export const DateFilter: React.FC<DateFilterProps> = (props) => {
    const initialValues: DefaultValues<DateFilterFormValues> = {
        ...props.defaultValues,
        granularity: props.granularity,
        comparison: props.comparison,
    };
    const form = useForm<DateFilterFormValues>({
        defaultValues: initialValues,
    });

    const formValue = form.watch();
    const presets = useMemo(
        () => props.getPresets(formValue.period, props.minDate),
        [formValue.period, props.minDate]
    );

    const showComparison = !!formValue.comparison;
    const formDisabled = false; // !form.formState.isDirty;

    const handlePeriodChange = (period: ContextFreePeriod) => {
        form.setValue('period', period);
    };

    const handleComparisonChange = (period: Period) => {
        form.setValue('comparison', period);
    };

    const handleSubmit: SubmitHandler<DateFilterFormValues> = (values, event) => {
        event?.preventDefault();
        event?.stopPropagation();
        props.onChange(values);
    };

    const handleGranularityChange = (value: TimeGranularityType) => {
        form.setValue('granularity', value);
    };

    const handleCompareChange: UseCheckboxProps['onChange'] = (event) => {
        if (event.target.checked && isRelativePeriod(formValue.period)) {
            form.setValue('comparison', formValue.period);
        } else if (event.target.checked && isAbsolutePeriod(formValue.period)) {
            const relative = daterangeToDuration(formValue.period);
            form.setValue('comparison', relative);
        } else {
            form.setValue('comparison', null);
        }
    };

    // useEffect(() => {
    //     form.reset(initialValues);
    // }, [
    //     // TODO: Optimize this check
    //     JSON.stringify(initialValues),
    // ]);

    return (
        <form
            style={{ margin: 0 }}
            onSubmit={form.handleSubmit(handleSubmit, console.error)}
        >
            <VStack spacing={4} alignItems="flex-start">
                <VStack spacing={4} alignItems="flex-start">
                    <VStack spacing={1} alignItems="flex-start">
                        <DateRangePicker
                            preview={true}
                            spacing={4}
                            options={presets.period}
                            granularity={props.granularity}
                            value={formValue.period}
                            onChange={handlePeriodChange}
                            minDate={props.minDate}
                        />
                    </VStack>
                    <Checkbox
                        colorScheme="green"
                        onChange={handleCompareChange}
                        defaultChecked={true}
                        disabled={true}
                        isDisabled={true}
                    >
                        Compared to
                    </Checkbox>
                    {formValue.comparison && (
                        <VStack spacing={1} alignItems="flex-start">
                            <DateRangePicker
                                preview={true}
                                spacing={4}
                                options={presets.comparison}
                                granularity={props.granularity}
                                value={formValue.comparison}
                                onChange={handleComparisonChange}
                                reference={formValue.period}
                            />
                        </VStack>
                    )}
                </VStack>
                {formValue.granularity && (
                    <ButtonGroup isAttached={true} size="sm" w="full" variant="solid">
                        <Button
                            _focus={{ outline: 0 }}
                            onClick={handleGranularityChange.bind(null, 'day')}
                            colorScheme={
                                formValue.granularity === 'day' ? 'varosGreen' : undefined
                            }
                            flex={1}
                        >
                            Day
                        </Button>
                        <Button
                            _focus={{ outline: 0 }}
                            onClick={handleGranularityChange.bind(null, 'week')}
                            colorScheme={
                                formValue.granularity === 'week'
                                    ? 'varosGreen'
                                    : undefined
                            }
                            flex={1}
                        >
                            Week
                        </Button>
                        <Button
                            _focus={{ outline: 0 }}
                            onClick={handleGranularityChange.bind(null, 'month')}
                            colorScheme={
                                formValue.granularity === 'month'
                                    ? 'varosGreen'
                                    : undefined
                            }
                            flex={1}
                        >
                            Month
                        </Button>
                    </ButtonGroup>
                )}
                <HStack w="100%">
                    <Button
                        ml="auto"
                        isFullWidth
                        type="submit"
                        size="sm"
                        w={1 / 5}
                        colorScheme="varosGreen"
                        // backgroundColor="#05A87D"
                        // _hover={{ backgroundColor: '#05A87D' }}
                        disabled={formDisabled}
                    >
                        Apply
                    </Button>
                </HStack>
            </VStack>
        </form>
    );
};

import React from 'react';
import { DemoRouteInit } from './demoRouteConfig';
import { createDemoRouteComponent } from './DemoRouteComponent';
import { TabProps } from '@chakra-ui/tabs';

export function createDemoRoute(config: DemoRouteInit): React.FC {
    const {
        container: { createChartContainer },
        view: { createRouteProvider },
    } = config;

    const adapter = {
        chart: config.adapter.createChartAdapter(),
    };

    const service = {
        chart: config.service.createChartService(adapter.chart),
    };

    const controller = {
        useRoute: config.controller.createRouteControllerHook(),
        useChart: config.controller.createChartControllerHook(service.chart),
    };

    const Route = {
        Demo: createDemoRouteComponent({
            ...config,
            container: {
                ChartContainer: createChartContainer(controller.useChart),
            },
        }),
    };

    const Provider = {
        Route: createRouteProvider(),
    };

    return () => {
        const routeController = controller.useRoute();
        return (
            <Provider.Route {...routeController}>
                <Route.Demo {...routeController} />
            </Provider.Route>
        );
    };
}

export interface CustomTabProps extends TabProps {
    tooltip?: string;
}

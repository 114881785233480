import { ValueFormatter } from '../../../../app/attributes';
import { WorkspaceContextV2 } from '../../../../app';
import { getSubmissionStatus } from '../../../../app/forms';
import { SubmissionItemAggregate } from './submissionItemModel';
import { SubmissionItemViewProps } from './submissionItemProps';

export function buildSubmissionItemViewProps(
    formatter: ValueFormatter,
    context: WorkspaceContextV2,
    item: SubmissionItemAggregate
): SubmissionItemViewProps {
    const status = getSubmissionStatus(item);
    const relative = formatter.format('datetime', item.submission.createdAt, {
        relative: true,
    });
    return {
        id: item.submission.id,
        study: {
            label: item.dataset.name,
            link: {
                to: `/u/assets/${context.workspace.id}/reporting/surveys/${item.submission.dataset.id}`,
            },
        },
        date: {
            relative: {
                label: relative,
            },
        },
        user: {
            label: item.user
                ? [item.user.first_name, item.user.last_name].filter(Boolean).join(' ')
                : 'N/A',
        },
        status: buildStatus(item),
        // group: item.group
        //     ? {
        //           label: item.group.name,
        //           links: {
        //               detail: {
        //                   to: `/u/assets/${context.workspace.id}/reports/groups/${item.group.id}`,
        //               },
        //           },
        //       }
        //     : null,
        answers: item.dataset.configuration.schema.map((property) => {
            const value = item.data?.[property.key] ?? null;
            return {
                id: property.key,
                title: property.name,
                value: {
                    label:
                        value === null ? 'N/A' : formatter.format(property.type, value),
                },
            };
        }),
        links: {
            detail: {
                to: `/u/assets/${context.workspace.id}/reporting/submissions/${item.submission.id}`,
            },
            response: null,
            // response:
            //     status === 'ready'
            //         ? {
            //               to: `/u/assets/${context.workspace.id}/reporting/reports/${item.report.id}/data`,
            //           }
            //         : null,
        },
    };
}

function buildStatus(item: SubmissionItemAggregate): SubmissionItemViewProps['status'] {
    const status = getSubmissionStatus(item);
    if (status === 'collecting') {
        return {
            label: 'Collecting responses',
            description: `Study responses are being collected and result will be available shortly`,
            colorScheme: 'orange',
        };
    }
    return {
        label: 'Ready',
        description: null,
        colorScheme: 'green',
    };
}

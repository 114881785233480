"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MessageCreatePayloadSchema = exports.MessageListPayloadSchema = exports.MessageListSchema = exports.MessageObjectSchema = void 0;
const zod_1 = require("zod");
const block_1 = require("../block");
const list_1 = require("../list");
exports.MessageObjectSchema = zod_1.z.object({
    id: zod_1.z.string(),
    object: zod_1.z.literal('message'),
    thread: zod_1.z.string(),
    role: zod_1.z.union([zod_1.z.literal('user'), zod_1.z.literal('system')]),
    content: zod_1.z.array(block_1.AnyBlockSchema),
    created_at: zod_1.z.date({ coerce: true }),
});
exports.MessageListSchema = (0, list_1.ListSchema)(exports.MessageObjectSchema);
exports.MessageListPayloadSchema = zod_1.z.object({
    thread: zod_1.z.string(),
});
exports.MessageCreatePayloadSchema = zod_1.z.object({
    thread: zod_1.z.string(),
    role: zod_1.z.union([zod_1.z.literal('user'), zod_1.z.literal('system')]),
    content: zod_1.z.array(block_1.AnyBlockSchema),
});

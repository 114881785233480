import { UseDisclosureProps, UseDisclosureReturn } from '@chakra-ui/react';
import React from 'react';
import {
    DashboardLayoutMain,
    DashboardLayoutWrapper,
    DashboardLayoutWrapperProps,
    DashboardLayoutActions,
    DashboardLayoutContent,
    DashboardLayoutFilters,
    DashboardLayoutSegment,
    DashboardLayoutCompetitiveSet,
} from './component';
import {
    DashboardControlItem,
    DashboardControlList,
} from './component/DashboardLayoutControl';
import { DashboardLayoutDate } from './component/DashboardLayoutDate';
import { DashboardLayoutGroup } from './component/DashboardLayoutGroup';
// import {
//     DashboardSettingsItem,
//     DashboardSettingsList,
// } from './component/DashboardLayoutSettings';
import { DashboardLayoutStatus } from './component/DashboardLayoutStatus';
import { DashboardLayoutComponents } from './dashboardLayoutInterface';

export function createDashboardLayout(): DashboardLayoutComponents {
    const instance: DashboardLayoutComponents = {
        UI: {
            // Control: {
            //     Group: React.FC;
            //     Label: React.FC;
            //     Input: React.FC;
            // };
            // Popover: {
            //     Container: React.FC<PopoverProps>;
            //     Content: React.FC<PopoverContentProps>;
            //     Trigger: React.FC<{ children: React.ReactChild }>;
            // };
        },
        Wrapper: DashboardLayoutWrapper,
        Cards: DashboardLayoutMain,
        Actions: DashboardLayoutActions,
        Filters: DashboardLayoutFilters,
        Date: DashboardLayoutDate,
        Content: DashboardLayoutContent,
        Segment: DashboardLayoutSegment,
        CompetitiveSet: DashboardLayoutCompetitiveSet,
        Status: DashboardLayoutStatus,
        Group: DashboardLayoutGroup,
        // Settings: {
        //     List: DashboardSettingsList,
        //     Item: DashboardSettingsItem,
        // },
        Control: {
            List: DashboardControlList,
            Item: DashboardControlItem,
        },
    };
    return instance;
}

import { Tooltip as ChakraTooltip, TooltipProps as ChakraTooltipProps } from '@chakra-ui/react';
import React from 'react';

export interface TooltipProps extends Pick<ChakraTooltipProps, 'label' | 'isDisabled' | 'placement' | 'hasArrow'> {}

export const Tooltip: React.FC<TooltipProps> = ({ hasArrow = true, children, ...props }) => {
    return (
        <ChakraTooltip bg="#1d1c21" borderRadius={4} label={props.label} openDelay={0} hasArrow={hasArrow} {...props}>
            {children}
        </ChakraTooltip>
    );
};

import React from 'react';
import {
    VStack,
    Link as ChakraLink,
    Spinner,
    Box,
    StackDivider,
    Grid,
    GridItem,
    Text,
    HStack,
    Heading,
    Tabs,
    TabList,
    Tab,
    TabPanels,
    TabPanel,
    Button,
    Alert,
    AlertTitle,
    AlertIcon,
    Icon,
    Wrap,
    WrapItem,
    Tag,
    Tooltip,
} from '@chakra-ui/react';
import { GroupsIntroProps } from '../../../../view/groups/group/introduction/groupsIntroProps';
import { RemoteIframe } from '../../../../../ui';
import { ApplicationUI } from '../../../../ui';
import { BsArrowRight } from 'react-icons/bs';

export const createGroupsIntroView = (config: {
    UI: ApplicationUI;
}): React.FC<GroupsIntroProps> => {
    const {
        UI: { Link },
    } = config;
    return (props) => {
        return (
            <VStack
                align="start"
                w="full"
                h="full"
                pt={12}
                spacing={12}
                maxW={{
                    sm: '100vw',
                    md: '90vw',
                    lg: '90vw',
                    xl: '85vw',
                    '2xl': '80rem',
                }}
                marginX="auto"
            >
                <Alert status="warning" flexShrink={0}>
                    <AlertIcon />
                    <AlertTitle w="full">
                        <HStack w="full" justify="space-between">
                            <ChakraLink
                                as={Link}
                                id="top_creatives_setup_join_link"
                                to={props.settingsUrl}
                                _focus={{ outline: 'none' }}
                            >
                                Data-sharing must be configured to access this feature
                            </ChakraLink>
                            <ChakraLink
                                as={Link}
                                id="top_creatives_setup_join_link"
                                to={props.settingsUrl}
                                _focus={{ outline: 'none' }}
                            >
                                <HStack>
                                    <Text>Manage data-sharing</Text>
                                </HStack>
                            </ChakraLink>
                        </HStack>
                    </AlertTitle>
                </Alert>
                <Grid
                    w="full"
                    templateColumns={{ base: '1fr', lg: '1fr 2fr' }}
                    gap={24}
                    fontWeight="medium"
                    pb={24}
                >
                    <GridItem>
                        <VStack align="start" w="full" spacing={3}>
                            <Heading fontSize="2xl" fontWeight="semibold">
                                Top Creatives
                            </Heading>
                            <Text fontSize="lg" color="whiteAlpha.800">
                                Replicate top performing creatives with high confidence
                                that these will perform well
                            </Text>
                            {/* <Wrap spacing={3}>
                                <WrapItem>
                                    <Tag colorScheme="blue" size="lg">
                                        Highlight 1
                                    </Tag>
                                </WrapItem>
                                <WrapItem>
                                    <Tooltip
                                        placement="top"
                                        hasArrow={true}
                                        bg="#1D1C21"
                                        p={3}
                                        label="Sit sunt ad in est dolore labore incididunt. In velit pariatur qui elit. Mollit proident ex commodo sint enim velit sint nostrud consequat eu fugiat ex fugiat labore."
                                    >
                                        <Tag
                                            colorScheme="blue"
                                            size="lg"
                                            userSelect="none"
                                        >
                                            Highlight 2
                                        </Tag>
                                    </Tooltip>
                                </WrapItem>
                                <WrapItem>
                                    <Tag colorScheme="blue" size="lg">
                                        Highlight 3
                                    </Tag>
                                </WrapItem>
                            </Wrap> */}
                            <Tabs isFitted={true} w="full">
                                <TabList>
                                    <Tab
                                        id="coop"
                                        fontWeight="medium"
                                        _focus={{ outline: 'none' }}
                                    >
                                        Co-op
                                    </Tab>
                                    <Tab
                                        id="internal"
                                        fontWeight="medium"
                                        _focus={{ outline: 'none' }}
                                    >
                                        Internal
                                    </Tab>
                                </TabList>
                                <TabPanels>
                                    <TabPanel id="coop" px={0} py={6}>
                                        <VStack align="start" w="full" spacing={6}>
                                            <VStack align="start">
                                                <Text color="whiteAlpha.700">
                                                    Facebook Ad Library has many creatives
                                                    but you can’t tell which ones are
                                                    actually performing well.
                                                </Text>
                                                <Text color="whiteAlpha.700">
                                                    With this feature you can see top
                                                    performing creative by actual
                                                    performance metrics (ROAS, CTR, Spend
                                                    as % of total ad account) from
                                                    companies with similar end customers.
                                                </Text>
                                            </VStack>
                                            <Box w="full">
                                                <Link
                                                    id="top_creatives_setup_join_link"
                                                    to={props.settingsUrl}
                                                >
                                                    <Button w="full" colorScheme="blue">
                                                        Configure Privacy
                                                    </Button>
                                                </Link>
                                            </Box>
                                        </VStack>
                                    </TabPanel>
                                    {props.showInternal &&
                                    <TabPanel id="internal" px={0} py={6}>
                                        <VStack align="start" w="full" spacing={6}>
                                            <VStack align="start">
                                                <Text color="whiteAlpha.700">
                                                    Ads from your companies are spread out
                                                    across accounts, preventing you from
                                                    easily identifying what works for
                                                    certain types of customers and
                                                    replicating that to other accounts.
                                                </Text>
                                                <Text color="whiteAlpha.700">
                                                    With this feature, you can see top
                                                    performing creative across all your
                                                    integrated accounts for a given
                                                    demographic group.
                                                </Text>
                                            </VStack>
                                            <Box w="full">
                                                <Link
                                                    id="top_creatives_setup_join_link"
                                                    to={props.settingsUrl}
                                                >
                                                    <Button w="full" colorScheme="blue">
                                                        Configure Privacy
                                                    </Button>
                                                </Link>
                                            </Box>
                                        </VStack>
                                    </TabPanel>
                                    }
                                </TabPanels>
                            </Tabs>
                        </VStack>
                    </GridItem>
                    <GridItem>
                        <HStack
                            w="full"
                            p={12}
                            borderWidth={2}
                            borderColor="whiteAlpha.300"
                            borderStyle="solid"
                            borderRadius="lg"
                            // bg="whiteAlpha.100"
                        >
                            <RemoteIframe
                                spinner={<Spinner color="whiteAlpha.900" />}
                                // width="700"
                                width="100%"
                                height="420"
                                src={props.videoUrl}
                                frameBorder="0"
                                allowFullScreen={true}
                            />
                        </HStack>
                    </GridItem>
                </Grid>
            </VStack>
        );
    };
};

import React from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { ConversationContainerBaseConfig } from '../base';
import { ConversationUrlParamSchema } from '../conversationSchema';
import {
    ConversationNewController,
    ConversationNewLoader,
} from './conversationNewInterface';
import { ConversationNewViewProps } from './conversationNewProps';

export function createConversationNewContainer(
    config: ConversationContainerBaseConfig,
    loader: ConversationNewLoader,
    controller: ConversationNewController,
    View: React.FC<ConversationNewViewProps>
): React.FC {
    const {
        infra: {
            optionParser: { useOptions },
            toaster: { useToast },
        },
        repository: {
            thread: threadRepository,
            run: runRepository,
            message: messageRepository,
        },
        context: { useContext },
    } = config;
    return (containerProps) => {
        const navigate = useNavigate();
        const toast = useToast();
        const [searchParams] = useSearchParams();
        const options = useOptions(ConversationUrlParamSchema);
        const context = useContext();

        const prefetch = {
            message: messageRepository.usePrefetch(context),
        };
        const mutation = {
            thread: threadRepository.useCreate(context),
            run: runRepository.useCreate(context),
        };
        const data = loader.useLoad(context, options);
        const props = controller.useProps(context, data, {
            form: {
                id: 'conversation_thread_create_form',
                form: useForm({
                    defaultValues: {},
                }),
                async onSubmit() {
                    const thread = await mutation.thread.mutateAsync({
                        messages: [],
                    });
                    await prefetch.message.load(
                        {
                            thread,
                        },
                        {}
                    );
                    const path = `../${thread.id}?${searchParams.toString()}`;
                    navigate(path, { replace: true });
                },
                onSubmitError(error) {
                    toast({
                        kind: 'error',
                        description: error.message,
                    });
                },
            },
        });
        return <View {...props} />;
    };
}

import React, { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
    SettingAccountItemProviderConfig,
    SettingAssetConfig,
} from '../../../../../route';
import {
    SettingAssetListController,
    SettingAssetListDeps,
    SettingAssetListLoader,
} from './listInterface';
import { SettingAssetListContainerProps } from './listProps';
import { SettingAccountIntegrationListRouteParams } from '../../integration/list/integrationListSchema';

export function createSettingAssetListContainer(
    init: Pick<SettingAssetConfig['deps'], 'state'>,
    config: Pick<SettingAccountItemProviderConfig, 'api'>,
    loader: SettingAssetListLoader,
    controller: SettingAssetListController
): React.FC<SettingAssetListContainerProps> {
    const {
        state: {
            asset: { list: state },
        },
    } = init;
    const {
        api: { useContext },
    } = config;

    return ({ as: Component, children, ...props }) => {
        const [searchParams] = useSearchParams();
        const options = useMemo(() => {
            const parsed = SettingAccountIntegrationListRouteParams.safeParse(
                Object.fromEntries(searchParams.entries())
            );
            return parsed.success ? parsed.data : null;
        }, [searchParams]);

        const viewContext = useContext();
        const viewState = state.useState(viewContext, { search: options?.name ?? '' });
        const viewData = loader.useLoad(viewContext);

        const viewDeps: SettingAssetListDeps = {
            list: {
                state: viewState,
            },
        };
        const viewProps = controller.useProps(viewContext, viewDeps, { item: viewData });
        return <Component {...viewProps}>{children}</Component>;
    };
}

import React, { useMemo } from 'react';
import { AxiosInstance } from 'axios';
import { ApplicationContext } from '../../../../hooks';
import { useAuthenticatedContext } from '../../../../container';
import { useAuthenticatedApi } from '../../../../infra';
import { OrganizationScopeContext, OrganizationScopeValue } from './organizationContext';
import { OrganizationDependencies } from './organizationInterface';

export interface OrganizationConfig {
    getDeps(config: {
        organization: ApplicationContext['organization'];
        api: AxiosInstance;
    }): OrganizationDependencies;
}

export interface OrganizationSetProviderProps {}

export function createOrganizationProvider(
    config: OrganizationConfig
): React.FC<OrganizationSetProviderProps> {
    return (props) => {
        const appcontext = useAuthenticatedContext();
        const api = useAuthenticatedApi();
        const adapter = useMemo<OrganizationDependencies>(
            () =>
                config.getDeps({
                    api,
                    organization: appcontext.organization,
                }),
            [api, appcontext.organization]
        );

        if (!appcontext.organization?.id) {
            throw new Error(`no organization context found in context`);
        }
        const context: OrganizationScopeValue = {
            adapter,
            organization: {
                id: appcontext.organization.id,
            },
        };
        return (
            <OrganizationScopeContext.Provider value={context}>
                {props.children}
            </OrganizationScopeContext.Provider>
        );
    };
}

import { ReportDetailRouteConfig } from '../reportDetailConfig';
import { ReportDetailRouteOpportunityController } from './opportunityInterface';
import { ReportDetailOpportunityViewProps } from './opportunityProps';

export function createReportDetailRouteOpportunityController(
    config: Pick<ReportDetailRouteConfig, 'controller'>
): ReportDetailRouteOpportunityController {
    const { controller } = config;
    return {
        useProps(context, data, props): ReportDetailOpportunityViewProps {
            const itemProps = controller.insights.item.useProps(context, {
                item: props.item,
            });
            return {
                itemProps: itemProps,
                // if we have a visualization, the date range will be
                // rendered as part of that instead
                showDate: props.visualization === null,
            };
        },
    };
}

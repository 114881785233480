import { useMutation, useQuery, useQueryClient, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { ErrorInfo } from '../../../..//models/Errors';
import { refreshDashboardListQueries } from '../../../../hooks';
import { useStore } from '../../../../stores/setupContext';
import { DashboardContext } from '../../../../hooks/dashboards/dashboardContext';
import { listDashboardHandler } from './dashboardDepsQuery';
import { DashboardDepsCheckResultDto } from '../../../../api/v2/dashboard/deps';

export const QUERY_PREFIX = ['dashboards', 'deps'];

function getQueryKey(context: DashboardContext) {
    return [...QUERY_PREFIX, context.slug, context.assetId];
}

export const useDashboardDeps = (
    context: DashboardContext,
    {
        suspense = false,
        refetchOnWindowFocus = false,
        ...options
    }: Partial<Omit<UseQueryOptions<DashboardDepsCheckResultDto, ErrorInfo>, 'queryFn' | 'queryKey'>> = {}
): UseQueryResult<DashboardDepsCheckResultDto, ErrorInfo> => {
    const query = useQuery<DashboardDepsCheckResultDto, ErrorInfo>({
        queryKey: getQueryKey(context),
        queryFn: listDashboardHandler(context),
        suspense,
        refetchOnWindowFocus,
        ...options,
    });
    return query;
};

// export const useDashboardActions = () => {
//     const store = useStore();
//     // const handler = mutations.createActivateHandler();
//     const client = useQueryClient();

// const activate = useMutation<void, ErrorInfo, DashboardContext>(handler, {
//     async onSuccess(updated, context) {
//         await client.resetQueries({ queryKey: getQueryKey(context) });
//         await refreshDashboardListQueries({ client });
//         await store.peerGrpFilters.refreshDefaultFilters();
//     },
// });

//     return { actions: {  } };
// };

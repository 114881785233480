import { OnboardingProfileController } from '../../../../strategy/onboarding';
import { ApplicationEntryEnhancer } from '../../../../entrypoint';
import { AnyOnboardingTrackingEvent } from '../trackingOnboardingEvent';

export function createTrackingOnboardingProfileStrategy(): ApplicationEntryEnhancer {
    return (create) => (config) => {
        function useTracker() {
            const tracker = instance.infra.useTracker<AnyOnboardingTrackingEvent>();
            return tracker;
        }

        function enhanceController(
            controller: OnboardingProfileController
        ): OnboardingProfileController {
            return {
                ...controller,
                useProps(...args) {
                    const tracker = useTracker();
                    const viewProps = controller.useProps(...args);
                    return {
                        ...viewProps,
                        getFormProps() {
                            const base = viewProps.getFormProps();
                            return {
                                ...base,
                                async onSubmit(...args) {
                                    const [values] = args;
                                    const submitted = await base.onSubmit(...args);
                                    tracker.track('onboarding_profile_submitted', {
                                        profile_first_name: values.firstname,
                                        profile_last_name: values.lastname,
                                        profile_company_name: values.companyName,
                                        profile_website_url: values.companyWebsite,
                                        profile_is_agency: values.isAgency,
                                    });
                                    return submitted;
                                },
                            };
                        },
                    };
                },
            };
        }

        const instance = create({
            ...config,
            controller: {
                ...config.controller,
                onboarding: {
                    ...config.controller.onboarding,
                    createProfile(...args) {
                        const controller = config.controller.onboarding.createProfile(
                            ...args
                        );
                        return enhanceController(controller);
                    },
                },
            },
        });

        return instance;
    };
}

import { usePrevious, Box, useToken } from '@chakra-ui/react';
import { useWindowSize } from 'react-use';
import React from 'react';
import { Bar, Line } from 'react-chartjs-2';
import { LineChartProps } from '../lineChartProps';

export const LINE_CHART_RIGHT_PADDING = 72;
export const LINE_CHART_RIGHT_OFFSET = 4;

export const LineChart: React.FC<LineChartProps> = ({
    getCaption = (context, original) => original,
    formatUnit = (value) => value.toString(),
    formatLabel = (value) => value.toString(),
    height = 0,
    width = 0,
    padding = {},
    ...props
}) => {
    const chartJsInstance = React.useRef<Bar | null>(null);
    const legendColor = useToken('colors', 'whiteAlpha.900');
    const labelColor = useToken('colors', 'whiteAlpha.700');

    // console.log(props);

    // const windowSize = useWindowSize();

    const prevWidth = usePrevious(width);
    const prevHeight = usePrevious(height);
    // const prevWindowSize = usePrevious(windowSize);

    React.useEffect(() => {
        chartJsInstance.current && chartJsInstance.current.chartInstance.update();
        if (
            prevWidth != width ||
            prevHeight != height
            // prevWindowSize.width !== windowSize.width ||
            // prevWindowSize.height !== windowSize.height
        ) {
            chartJsInstance.current && chartJsInstance.current.chartInstance.resize();
        }
    }, [
        width,
        height,
        // windowSize.height, windowSize.width
    ]);

    // console.log('DEBUG height', height);
    // console.log('DEBUG width', width);

    return (
        <Box>
            <Box
                height={height * 0.8}
                // width={width}
                width="full"
                overflow="hidden"
                position="relative"
            >
                <Line
                    ref={chartJsInstance}
                    data={props.data}
                    options={{
                        hover: {
                            animationDuration: 0,
                        },
                        animation: false,
                        layout: {
                            padding: {
                                left: 24,
                                right: LINE_CHART_RIGHT_PADDING,
                                // right: 0,
                                top: 24,
                                bottom: 32,
                                ...padding,
                            },
                        },
                        legend: {
                            usePointStyle: true,
                            // This version of chartjs does not support customizing the space
                            // between the chart and the legend and because we have a custom label design we
                            // end up with clipping. instead we use a custom legend component
                            display: false,
                            // align: 'start',
                            padding: 64,
                            labels: {
                                // padding: 32,
                                boxWidth: 6,
                                usePointStyle: true,
                                fontColor: legendColor,
                                fontSize: 14,
                            },
                        },
                        maintainAspectRatio: false,
                        // responsive = true breaks layout scrolling for some reason
                        responsive: true,
                        tooltips: {
                            // enabled: tooltipEnabled,
                            enabled: true,
                            mode: 'index',
                            // intersect: false,
                            axis: 'y',
                            position: 'nearest',
                            callbacks: {
                                title: function (tooltipItems: any[]) {
                                    const [tooltipItem] = tooltipItems;
                                    return formatLabel(tooltipItem.label, {
                                        format: 'long',
                                    });
                                },
                                label: props.getLabel,
                            },
                        },
                        scales: {
                            xAxes: [
                                {
                                    ticks: {
                                        fontColor: labelColor,
                                        fontSize: 14,
                                        // @ts-expect-error
                                        callback: function (value, index, values) {
                                            // console.log('callback', { value, index, values });
                                            return formatLabel(value, {
                                                format: 'short',
                                            });
                                        },
                                    },
                                },
                            ],
                            yAxes: [
                                {
                                    ticks: {
                                        fontSize: 14,
                                        // @ts-expect-error
                                        callback: function (value, index, values) {
                                            // console.log('callback', { value, index, values });
                                            return formatUnit(value);
                                        },
                                    },
                                    gridLines: { color: 'rgb(104, 104, 104, 0.3)' },
                                },
                            ],
                        },
                    }}
                />
            </Box>
        </Box>
    );
};

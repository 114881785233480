import { DashboardSlugs } from '../../../../../config/dashboard';
import {
    QueryExpressionSegment,
    QueryRangeSegment,
    QuerySegments,
} from '../../../../../api';
import {
    CohortState,
    DynamicCohortStateItem,
    FixedCohortStateItem,
} from '../../../../domain';
import { assert } from '../../../../util/assert';

const HIGH_PERFORMER_NAME = 'High performers';

const DEFAULTS_FIXED: FixedCohortStateItem[] = [
    {
        name: 'Low performers',
        lower: 0,
        upper: 33,
        visible: true,
    },
    {
        name: 'Median',
        lower: 33,
        upper: 66,
        visible: true,
    },
    {
        name: HIGH_PERFORMER_NAME,
        lower: 66,
        upper: 100,
        visible: true,
    },
];

// const DEFAULT_FIXED_BY_DASHBOARD: Record<string, FixedCohortStateItem[] | undefined> = {
//     // [DashboardSlugs.FACEBOOK_BREAKDOWN]: DEFAULTS_FIXED.map((item) =>
//     //     item.name === HIGH_PERFORMER_NAME ? item : { ...item, visible: false }
//     // ),
// };

const DEFAULTS_DYNAMIC: DynamicCohortStateItem[] = [
    {
        name: 'Low performers',
        value: 25,
        visible: true,
    },
    {
        name: 'Median',
        value: 50,
        visible: true,
    },
    {
        name: 'High performers',
        value: 75,
        visible: true,
    },
];

export function segmentToRanking(
    dashboardId: string,
    segments: QuerySegments
): CohortState {
    const comparisonMetrics = new Set(segments.fixed.map((item) => item.range.column));
    const unique = [...comparisonMetrics.values()];
    const [comparisonMetric, ...rest] = unique;
    if (!comparisonMetric || rest.length > 0) {
        throw new Error(`expected fixed cohorts to be the same metric`);
    }

    const fixedCohorts = DEFAULTS_FIXED;

    // @ts-expect-error
    return {
        mode: segments.mode,
        config: {
            fixed: {
                kind: 'fixed',
                comparison: comparisonMetric,
                cohorts: fixedCohorts.map((item) => {
                    const found = segments.fixed.find(
                        (candidate) => candidate.name === item.name
                    );
                    if (!found) {
                        return { ...item, visible: false };
                    }
                    return {
                        name: found.name,
                        lower: found.range.start.value,
                        upper: found.range.end.value,
                        visible: true,
                    };
                }),
                // cohorts: segments.fixed.map<FixedCohortStateItem>((item) => {
                //     return {
                //         name: item.name,
                //         lower: item.range.start.value,
                //         upper: item.range.end.value,
                //     };
                // }),
            },
            dynamic: {
                kind: 'dynamic',
                cohorts: DEFAULTS_DYNAMIC.map((item) => {
                    const found = segments.dynamic.find(
                        (candidate) => candidate.name === item.name
                    );
                    if (!found) {
                        return { ...item, visible: false };
                    }
                    return {
                        name: item.name,
                        value:
                            found.reducer?.kind === 'percentile'
                                ? found.reducer?.value
                                : null ?? 50,
                        visible: true,
                    };
                }),
                // cohorts: segments.dynamic.map<DynamicCohortStateItem>((item) => ({
                //     name: item.name,
                //     value:
                //         item.reducer?.kind === 'percentile'
                //             ? item.reducer?.value
                //             : null ?? 50,
                //     visible: true,
                // })),
            },
        },
    };
}

export function rankingToSegments(cohort: CohortState): QuerySegments {
    return {
        mode: cohort.mode,
        fixed: cohort.config.fixed.cohorts
            .filter((item) => item.visible)
            .map((item): QueryRangeSegment => {
                assert(
                    cohort.config.fixed.comparison,
                    'missing comparison metric in cohort definition'
                );
                return {
                    kind: 'range',
                    name: item.name,
                    range: {
                        column: cohort.config.fixed.comparison,
                        start: {
                            kind: 'percentile',
                            value: item.lower,
                        },
                        end: {
                            kind: 'percentile',
                            value: item.upper,
                        },
                    },
                    reducer: {
                        kind: 'percentile',
                        value: 50,
                    },
                };
            }),
        dynamic: cohort.config.dynamic.cohorts
            .filter((item) => item.visible)
            .map(
                (item): QueryExpressionSegment => ({
                    kind: 'expression',
                    name: item.name,
                    expr: {
                        kind: 'boolean',
                        value: true,
                    },
                    reducer: {
                        kind: 'percentile',
                        value: item.value,
                    },
                })
            ),
    };
}

import React from 'react';
import { Grid, SimpleGrid, SimpleGridProps } from '@chakra-ui/react';

export interface IntegrationCardListProps extends SimpleGridProps {}

export const IntegrationCardList: React.FC<IntegrationCardListProps> = ({
    children,
    ...props
}) => {
    return (
        <Grid
            w="100%"
            gridTemplateColumns="repeat(auto-fit, minmax(450px, 1fr) )"
            gap={4}
            {...props}
        >
            {children}
        </Grid>
    );
    // return (
    //     <SimpleGrid
    //         w="100%"
    //         columns={["minmax(250px, 1fr)"]}
    //         // columns={[1, 1, 1, 2, 2, 3]}
    //         {...props}
    //     >
    //         {children}
    //     </SimpleGrid>
    // );
};

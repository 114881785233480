import React from 'react';
import {
    Select,
    Box,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Grid,
    GridItem,
    HStack,
    Icon,
    Input,
    InputGroup,
    Text,
    VStack,
    InputLeftAddon,
    InputRightAddon,
} from '@chakra-ui/react';
import { FormTheme } from '../../../ui';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { OnboardingFormThemeConfig } from './onboardingFormThemeConfig';

export function createOnboardingFormTheme(config: OnboardingFormThemeConfig): FormTheme {
    const {
        UI: {
            Application: { Tooltip },
        },
    } = config;
    const Theme: FormTheme = {
        Container(props) {
            return <form>{props.children}</form>;
        },
        Field: {
            List(props) {
                const children = React.Children.map(props.children, (child) => {
                    const element = React.cloneElement(child, child.props);
                    return (
                        <GridItem
                            key={child.key}
                            colSpan={child.props.isInline ? 1 : props.columns}
                        >
                            {element}
                        </GridItem>
                    );
                });
                return (
                    <Grid
                        templateColumns={`repeat(${props.columns}, 1fr)`}
                        gap={props.spacing}
                        width="full"
                    >
                        {children}
                    </Grid>
                );
            },
            Item(props) {
                return (
                    <FormControl isInvalid={!!props.error} isReadOnly={props.isReadonly}>
                        <FormLabel>
                            <HStack>
                                <Text>{props.label}</Text>
                                {props.description && (
                                    <Tooltip
                                        id={`${props.key}_tooltip`}
                                        placement="top"
                                        label={props.description}
                                    >
                                        <Box as="span">
                                            <Icon as={AiOutlineInfoCircle} />
                                        </Box>
                                    </Tooltip>
                                )}
                            </HStack>
                        </FormLabel>
                        {props.children}
                        {props.error && (
                            <FormErrorMessage>{props.error.message}</FormErrorMessage>
                        )}
                    </FormControl>
                );
            },
        },
        Input(props) {
            return <></>;
        },
        InputGroup(props) {
            // NOTE we need to use the actual components are not wrappers here because the
            // styling is based on the identity off the child components.
            // if we wrap them in the theme they will not be properly recognized
            const children = React.Children.map(
                props.children as React.ReactElement[],
                (child) => {
                    if (child.type === Theme.InputLeftAddon) {
                        return (
                            <InputLeftAddon
                                bg="gray.100"
                                borderColor="gray.300"
                                color="gray.400"
                                {...child.props}
                            />
                        );
                    }
                    if (child.type === Theme.InputRightAddon) {
                        return (
                            <InputRightAddon
                                bg="gray.100"
                                color="gray.400"
                                borderColor="gray.300"
                                {...child.props}
                            />
                        );
                    }
                    if (child.type === Theme.Input) {
                        return (
                            <Input
                                ref={
                                    // @ts-expect-error
                                    child.ref
                                }
                                borderColor="gray.300"
                                _focus={{ outline: 'none', borderColor: 'gray.500' }}
                                _placeholder={{ color: 'gray.400' }}
                                _hover={{ borderColor: 'gray.400' }}
                                _readOnly={{
                                    color: 'gray.700',
                                    bg: 'gray.100',
                                    borderColor: 'gray.300',
                                    cursor: 'default',
                                    _focus: { outline: 'none', borderColor: 'gray.300' },
                                }}
                                {...child.props}
                            />
                        );
                    }
                    return child;
                }
            );
            return <InputGroup {...props}>{children}</InputGroup>;
        },
        InputLeftAddon(props) {
            return <></>;
        },
        InputRightAddon(props) {
            return <></>;
        },
        Select: React.forwardRef<HTMLSelectElement>((props, ref) => {
            return (
                <Select
                    ref={ref}
                    borderColor="gray.300"
                    _focus={{ outline: 'none', borderColor: 'gray.500' }}
                    _active={{ outline: 'none', borderColor: 'gray.500' }}
                    _placeholder={{ color: 'gray.400' }}
                    _hover={{ borderColor: 'gray.400' }}
                    {...props}
                />
            );
        }),
    };
    return Theme;
}

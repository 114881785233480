import { ZodTypeAny } from 'zod';
import { ConversationAgentConfig } from './conversationAgentConfig';
import {
    ConversationAgent,
    ConversationAgentInstance,
} from './conversationAgentInterface';

export function createConversationAgent<TSchema extends ZodTypeAny>(props: {
    type: string;
    schema: TSchema;
    create: (config: ConversationAgentConfig) => ConversationAgentInstance<TSchema>;
}): ConversationAgent<TSchema> {
    return {
        type: props.type,
        schema: props.schema,
        create(config) {
            const { Component } = props.create(config);
            return {
                Component,
            };
        },
    };
}

import React from 'react';
import type { ReportTableColumn, ReportTableRow } from './reportTableModel';
import { ReportTableViewConfig } from './reportTableConfig';
import { ReportTableViewProps } from './reportTableProps';

export function createReportTableView(
    config: ReportTableViewConfig
): React.FC<ReportTableViewProps> {
    const {
        components: { Text, Table, Thead, Tr, Th, Tbody, Td },
    } = config;
    return (props) => {
        function renderItem(
            row: ReportTableRow,
            column: ReportTableColumn,
            index: number
        ): React.ReactElement {
            const value = row[column.key] as any;
            if (column.renderValue) {
                return column.renderValue(value, row, index);
            }
            if (typeof value === 'object') {
                return <>{JSON.stringify(value)}</>;
            }
            return value;
        }
        // console.log('props', props);
        return (
            <Table className={props.className} width="full">
                <Thead bg="whiteAlpha.400">
                    <Tr>
                        {props.columns.map((column, index) => (
                            <Th
                                tooltip={column.description}
                                key={column.key}
                                color="whilteAlpha.100"
                                border="none"
                                letterSpacing="wider"
                                textAlign={index > 0 ? 'right' : undefined}
                            >
                                {column.title}
                            </Th>
                        ))}
                    </Tr>
                </Thead>
                <Tbody>
                    {props.data.map((row, indexRow) => (
                        <Tr key={indexRow}>
                            {props.columns.map((column, indexColumn) => (
                                <Td key={column.key} borderColor="whiteAlpha.400">
                                    {renderItem(row, column, indexRow)}
                                </Td>
                            ))}
                        </Tr>
                    ))}
                </Tbody>
            </Table>
        );
    };
}

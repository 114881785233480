import { ZodTypeAny } from 'zod';
import { ConversationPluginConfig } from './conversationBlockConfig';
import {
    ConversationPlugin,
    ConversationPluginInstance,
} from './conversationBlockInterface';

export function createConversationPlugin<TSchema extends ZodTypeAny>(props: {
    type: string;
    schema: TSchema;
    create: (config: ConversationPluginConfig) => ConversationPluginInstance<TSchema>;
}): ConversationPlugin<TSchema> {
    return {
        type: props.type,
        schema: props.schema,
        create(config) {
            const { Component } = props.create(config);
            return {
                Component,
            };
        },
    };
}

import React, { useMemo } from 'react';
import { isEqual } from 'lodash';
import {
    Box,
    Select,
    Flex,
    Text,
    VStack,
    HStack,
    StackProps,
    SelectProps,
    Input,
} from '@chakra-ui/react';
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { UseFormProps } from 'react-hook-form';
import {
    isAbsolutePeriod,
    periodToRange,
    Period,
    AbsolutePeriod,
    ContextFreePeriod,
    isRelativePeriod,
} from '../../../domain';
import { TimeGranularityType } from '../../../models/Common';

const FORMAT = 'MM/dd/yyyy';

export interface DateRangeOption {
    key: string;
    text: string;
    value: Period | null;
}

export interface DateRangePickerProps
    extends Pick<UseFormProps<Period>, 'defaultValues'>,
        Pick<SelectProps, 'width'>,
        Pick<StackProps, 'spacing'> {
    options: DateRangeOption[];
    value: Period;
    onChange(value: Period): unknown;
    preview?: boolean;
    /**
     * Reference period for when calculating date intervals for relative periods with "period" as unit
     */
    reference?: ContextFreePeriod;
    granularity: TimeGranularityType | null;
    minDate?: Date;
}

export const DateRangePicker: React.FC<DateRangePickerProps> = ({
    preview = false,
    value,
    onChange,
    options,
    spacing,
    minDate,
    ...restProps
}) => {
    const now = new Date();
    const selectedOption = useMemo(
        () =>
            options.find((candidate) => {
                if (
                    candidate.value &&
                    isAbsolutePeriod(value) &&
                    isAbsolutePeriod(candidate.value)
                ) {
                    // this is a custom date range
                    return true;
                }
                return isEqual(candidate.value, value);
            }) ?? null,
        [value, options]
    );

    const handleDateRangeChange: React.ChangeEventHandler<HTMLSelectElement> = (
        event
    ) => {
        const value =
            options.find((candidate) => candidate.key === event.target.value)?.value ??
            null;
        if (!value) {
            return;
        }
        onChange(value);
    };

    const handleAbsoluteChange = (
        range: keyof AbsolutePeriod,
        date: Date,
        event: React.SyntheticEvent<any, Event> | undefined
    ) => {
        event?.stopPropagation();
        event?.preventDefault();
        onChange({ ...value, [range]: date });
    };

    // We need to explitcly cancel blur event propagation to avoid
    // closing container when inside a popover
    const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        event.preventDefault();
        event.stopPropagation();
    };

    let range = periodToRange(restProps.reference ?? null, value);

    const rangeIsDisabled = isRelativePeriod(value);
    const showPreview =
        (isRelativePeriod(value) && !restProps.reference) || isAbsolutePeriod(value);

    return (
        <VStack spacing={spacing} minWidth="300px" maxWidth="300px">
            <Select
                {...restProps}
                _focus={{ outline: 0 }}
                defaultValue={selectedOption?.key}
                defaultChecked={true}
                backgroundColor={'#292929'}
                width="100%"
                onChange={handleDateRangeChange}
            >
                {options.map((option) => (
                    <option key={option.key} value={option.key}>
                        {option.text}
                    </option>
                ))}
            </Select>
            {/* {isAbsolute(value) && ( */}
            {showPreview && (
                <HStack minWidth="300px" maxWidth="400px" spacing={spacing}>
                    <ReactDatePicker
                        calendarClassName="dark"
                        disabled={rangeIsDisabled}
                        customInput={
                            <Input
                                cursor="pointer"
                                _focus={{ outline: 0 }}
                                backgroundColor={'#292929'}
                            />
                        }
                        selected={range.start}
                        maxDate={now}
                        minDate={minDate}
                        onChange={handleAbsoluteChange.bind(null, 'start')}
                        onBlur={handleBlur}
                        dateFormat={FORMAT}
                    />
                    <Text color={rangeIsDisabled ? 'dimgray' : undefined}>-</Text>
                    <ReactDatePicker
                        disabled={rangeIsDisabled}
                        calendarClassName="dark"
                        customInput={
                            <Input
                                cursor="pointer"
                                _focus={{ outline: 0 }}
                                backgroundColor={'#292929'}
                            />
                        }
                        selected={range.end}
                        maxDate={now}
                        onChange={handleAbsoluteChange.bind(null, 'end')}
                        onBlur={handleBlur}
                        dateFormat={FORMAT}
                    />
                </HStack>
            )}
            {/* )} */}
        </VStack>
    );
};

import { Box, BoxProps, useToken, VStack } from '@chakra-ui/react';
import React, { useRef, useState } from 'react';
import { Bar, LinearComponentProps } from 'react-chartjs-2';
import { useTheme } from '@chakra-ui/react';
import { usePrevious } from '../../utils/hooks';
import { useWindowSize } from 'react-use';

function convertRemToPixels(rem: number) {
    return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
}

export interface BarChartDatasetV2 {
    label: string;
    /**
     * If set will be displayed when hovering over the legend
     */
    description?: string | null;
    data: any[];
    colorScheme: string;
    hidden?: boolean;
}

export interface BarChartDataV2 {
    labels: string[];
    flatten?: boolean;
    datasets: BarChartDatasetV2[];
}

export interface BarChartPropsV2
    extends Omit<LinearComponentProps, 'data' | 'width' | 'height'> {
    // width: BoxProps['width'];
    // height: BoxProps['height'];
    // no propert typing supplied by library
    data: BarChartDataV2;
    stacked?: boolean;
    tooltipProps?: Partial<{ enabled: boolean }>;
    getValue?(
        context: { key: string; columnIndex: number; rowIndex: number },
        original: unknown
    ): unknown;
    getCaption?(
        context: { key: string; columnIndex: number; rowIndex: number },
        original: unknown
    ): unknown;
    formatUnit?(value: number): React.ReactNode;
    formatLabel?(value: string): string;
}

export const BarChartV2: React.FC<BarChartPropsV2> = ({
    getValue = (context, original) => original,
    getCaption = (context, original) => original,
    formatUnit = (value) => value.toString(),
    formatLabel = (value) => value.toString(),
    tooltipProps: { enabled: tooltipEnabled = true } = {},
    // height = 0,
    // width = 0,
    ...props
}) => {
    const chartJsInstance = React.useRef<Bar | null>(null);

    const legendColor = useToken('colors', 'whiteAlpha.900');
    const labelColor = useToken('colors', 'whiteAlpha.700');

    const theme = useTheme();
    const colorsLegend = theme.colors.legend;

    const datasetProps = {
        type: 'bar',
        fill: false,
        cubicInterpolationMode: 'monotone',
        lineTension: 0,
        barPercentage: 0.75,
        categoryPercentage: 0.75,
        // maxBarThickness: convertRemToPixels(2),
        // barPercentage: [0.75, 0.75, 0.75][props.data.labels.length] ?? 0.75,
        // categoryPercentage: [0.25, 0.25, 0.75][props.data.labels.length] ?? 0.75,
    };

    // console.log('datasetProps', props.data.labels, datasetProps);

    const windowSize = useWindowSize();

    const prevWidth = usePrevious(windowSize.width);
    const prevHeight = usePrevious(windowSize.height);

    React.useEffect(() => {
        // This thing is hack that seems to fix some chart flicking when navigating between dashboards
        chartJsInstance.current && chartJsInstance.current.chartInstance.update();

        if (prevWidth != windowSize.width || prevHeight != windowSize.height) {
            chartJsInstance.current && chartJsInstance.current.chartInstance.resize();
        }
    }, [windowSize, prevWidth, prevHeight]);

    return (
        // Detecting when the canvas size changes can not be done directly from the canvas element.
        // Chart.js uses its parent container to update the canvas render and display sizes. However,
        // this method requires the container to be relatively positioned and dedicated to the chart
        // canvas only. Responsiveness can then be achieved by setting relative values for the container size
        <Bar
            ref={chartJsInstance}
            {...props}
            data={{
                ...props.data,
                labels: props.data.flatten
                    ? props.data.labels.filter(
                          (_, idx) => !props.data.datasets[idx].hidden
                      )
                    : props.data.labels,
                datasets: props.data.flatten
                    ? [
                          {
                              ...datasetProps,
                              data: props.data.datasets
                                  .filter((x) => !x.hidden)
                                  .flatMap((x) => x.data),
                              backgroundColor: props.data.datasets
                                  .filter((x) => !x.hidden)
                                  .map((x) => colorsLegend?.[x.colorScheme]?.[200]),
                              borderColor: props.data.datasets
                                  .filter((x) => !x.hidden)
                                  .map((x) => colorsLegend?.[x.colorScheme]?.[200]),
                          },
                      ]
                    : props.data.datasets.map(({ colorScheme, ...dataset }) => ({
                          ...dataset,
                          ...datasetProps,
                          backgroundColor: colorsLegend?.[colorScheme]?.[200],
                          borderColor: colorsLegend?.[colorScheme]?.[200],
                      })),
            }}
            options={{
                hover: {
                    animationDuration: 0,
                },
                animation: {
                    duration: 1,
                    onComplete: function () {
                        // @ts-expect-error
                        var chartInstance = this.chart,
                            ctx = chartInstance.ctx;
                        // ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontSize, Chart.defaults.global.defaultFontStyle, Chart.defaults.global.defaultFontFamily);
                        ctx.textAlign = 'center';
                        ctx.textBaseline = 'bottom';

                        // @ts-expect-error
                        this.data.datasets.forEach(function (dataset, rowIndex) {
                            // console.log(dataset);
                            if (dataset.hidden) {
                                // do not show overlay for hidden series
                                return;
                            }
                            const meta =
                                chartInstance.controller.getDatasetMeta(rowIndex);
                            // @ts-expect-error
                            meta.data.forEach(function (bar, columnIndex) {
                                const data = dataset.data[columnIndex];
                                const rowKey = dataset.label;
                                // console.log({ rowKey, rowIndex, columnIndex });
                                const formatted = getValue(
                                    {
                                        key: rowKey,
                                        columnIndex,
                                        rowIndex,
                                    },
                                    data
                                );
                                ctx.font = ctx.font.replace(/\d+px/, '14px');
                                ctx.fillStyle = dataset.backgroundColor;

                                const OFFSET = 5;
                                if (props.stacked) {
                                    // ctx.fillText(formatted, bar._model.x - (32 + OFFSET), bar._model.y);
                                } else {
                                    ctx.fillText(
                                        formatted,
                                        bar._model.x,
                                        bar._model.y - OFFSET
                                    );
                                }
                            });
                        });
                    },
                },
                layout: {
                    padding: {
                        // left: 24,
                        // right: 72,
                        top: 24,
                        // right: 48,
                        // bottom: 32,
                    },
                },
                legend: {
                    usePointStyle: true,
                    // This version of chartjs does not support customizing the space
                    // between the chart and the legend and because we have a custom label design we
                    // end up with clipping. instead we use a custom legend component
                    display: false,
                    // align: 'start',
                    padding: 64,
                    labels: {
                        // padding: 32,
                        boxWidth: 6,
                        usePointStyle: true,
                        fontColor: legendColor,
                        fontSize: 14,
                    },
                },
                maintainAspectRatio: false,
                responsive: true,
                tooltips: {
                    enabled: tooltipEnabled,
                    mode: 'index',
                    axis: 'y',
                    position: 'nearest',
                    callbacks: {
                        title: function (tooltipItems: any[]) {
                            const [tooltipItem] = tooltipItems;
                            return formatLabel(tooltipItem.label);
                        },
                        label: function (tooltipItem: any) {
                            // console.log('tooltipItem', tooltipItem);
                            const dataset = props.data.datasets[tooltipItem.datasetIndex];
                            const value = getCaption(
                                {
                                    rowIndex: tooltipItem.datasetIndex,
                                    columnIndex: tooltipItem.index,
                                    key: dataset.label,
                                },
                                tooltipItem.value
                            );
                            if (!value) {
                                return;
                            }
                            // console.log('dataset', dataset);
                            return `${dataset.label}: ${value}`;
                        },
                    },
                },
                scales: {
                    xAxes: [
                        {
                            ticks: {
                                fontColor: labelColor,
                                fontSize: 14,
                                // @ts-expect-error
                                callback: function (value, index, values) {
                                    // console.log('callback', { value, index, values });
                                    return formatLabel(value);
                                },
                            },
                            stacked: props.stacked,
                        },
                    ],
                    yAxes: [
                        {
                            stacked: props.stacked,
                            ticks: {
                                fontSize: 14,
                                // beginAtZero: true,
                                // min: 0,
                                // maxTicksLimit: 4,
                                // stepSize: 10,
                                // @ts-expect-error
                                callback: function (value, index, values) {
                                    // console.log('callback', { value, index, values });
                                    return formatUnit(value);
                                },
                            },
                            gridLines: { color: 'rgb(104, 104, 104, 0.3)' },
                        },
                    ],
                },
            }}
        />
    );
};

import { observer } from 'mobx-react';
import React from 'react';
import {
    SegmentController,
    SegmentLoader,
    SegmentStateProvider,
} from './segmentInterface';
import { AnalysisSegmentControllerProps, AnalysisSegmentViewProps } from './segmentProps';

export function createSegmentContainer(
    states: SegmentStateProvider,
    loader: SegmentLoader,
    controller: SegmentController,
    Component: React.FC<AnalysisSegmentViewProps>
): React.FC<AnalysisSegmentControllerProps> {
    return observer((props) => {
        const state = states.useState(props.context);
        const data = loader.useData(props.context, props);
        const viewProps = controller.useProps(state, data, props);
        return <Component {...viewProps} />;
    });
}

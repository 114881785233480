import React from 'react';
import { Box, Heading, HStack, Popover } from '@chakra-ui/react';
import { MenuContainerProps } from './MenuContainerProps';

export const MenuContainer: React.FC<MenuContainerProps> = ({
    placement = 'bottom-end',
    // when isLazy defaults to false we encounter an issue where
    // the content that should be hidden is still clickable when
    // the popover is closed. therefore we default to true.
    // see https://github.com/chakra-ui/chakra-ui/issues/7647
    isLazy = true,
    ...props
}) => {
    return (
        <Popover
            placement={placement}
            closeOnBlur={props.closeOnBlur}
            onOpen={props.onOpen}
            isOpen={props.isOpen}
            onClose={props.onClose}
            isLazy={isLazy}
            lazyBehavior={props.lazyBehavior}
            closeOnEsc={props.closeOnEsc}
        >
            {props.children}
        </Popover>
    );
};

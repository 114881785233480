import { assert } from './../../../util/assert';
import { ConversationLoaderBaseConfig } from '../base';
import { ConversationNewLoader } from './conversationNewInterface';

export function createConversationNewLoader(
    config: ConversationLoaderBaseConfig
): ConversationNewLoader {
    const {
        repository: { agent: agentRepository },
    } = config;
    return {
        useLoad(context, props) {
            const agentQuery = agentRepository.useLookup(context, props.agent, {
                suspense: true,
            });
            assert(agentQuery.status === 'success', 'expected suspense');
            return {};
        },
    };
}

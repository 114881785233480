import React from 'react';
import { Box, BoxProps, VStack } from '@chakra-ui/react';
import { MovementAtomProps } from './movementProps';

export function createMovementAtom(): React.FC<MovementAtomProps> {
    return React.forwardRef((props, ref) => {
        let color: BoxProps['color'] = undefined;

        let direction: 'up' | 'down' | null = null;
        if (props.value.change && props.value.change > 0) {
            direction = 'up';
        }
        if (props.value.change && props.value.change < 0) {
            direction = 'down';
        }

        if (props.type.inverse && direction === 'up') {
            color = 'red.400';
        } else if (props.type.inverse && direction === 'down') {
            color = 'green.400';
        } else if (!props.type.inverse && direction === 'up') {
            color = 'green.400';
        } else if (!props.type.inverse && direction === 'down') {
            color = 'red.400';
        }

        if (!props.value.base) {
            return (
                <VStack
                    {...props.style}
                    // @ts-expect-error
                    ref={ref}
                    justifyContent="center"
                    minH={10}
                    fontWeight="semibold"
                    spacing={0}
                >
                    <Box fontWeight="semibold">N/A</Box>;
                </VStack>
            );
        }

        return (
            <VStack
                {...props.style}
                // @ts-expect-error
                ref={ref}
                minH={10}
                fontWeight="semibold"
                spacing={0}
            >
                <Box>{props.format(props.type.base, props.value.base)}</Box>
                <Box color={color}>
                    {props.value.change ? <>{direction === 'up' ? '+' : '-'}</> : null}
                    {props.value.change
                        ? props.format('percent', Math.abs(props.value.change))
                        : 'N/A'}
                </Box>
            </VStack>
        );
    });
}

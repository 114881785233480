import { useDisclosure } from '@chakra-ui/react';
import React from 'react';
import { PeerContextProvider } from '../base';
import {
    PeerOverviewRouteController,
    PeerOverviewRouteLoader,
} from './peerOverviewInterface';
import { PeerOverviewViewProps } from './peerOverviewProps';

export function createPeerOverviewRoute(
    context: PeerContextProvider,
    controller: PeerOverviewRouteController,
    loader: PeerOverviewRouteLoader,
    View: React.FC<PeerOverviewViewProps>
): React.FC {
    return (props) => {
        const viewContext = context.useContext();
        const viewData = loader.useLoad(viewContext);
        const viewProps = controller.useProps(viewContext, viewData, {
            modal: {
                disclosure: useDisclosure(),
            },
        });
        return <View {...viewProps} />;
    };
}

import React from 'react';
import {
    VStack,
    HStack,
    FormControl,
    FormLabel,
    FormControlProps,
    StackProps,
} from '@chakra-ui/react';
import { Box } from '@chakra-ui/react';

export interface InputGroupProps
    extends Pick<FormControlProps, 'w'>,
        Pick<StackProps, 'spacing'> {
    label: React.ReactNode | null;
    rightContent?: React.ReactElement;
}

export const InputGroup: React.FC<InputGroupProps> = (props) => {
    return (
        <FormControl>
            <VStack w="full" alignItems="flex-start" spacing={props.spacing}>
                <HStack w="full" alignItems="center" justifyContent="space-between">
                    {props.label && (
                        <FormLabel
                            m={0}
                            // textTransform="uppercase"
                            // fontSize="xs"
                            // fontWeight="bold"
                            // color="whiteAlpha.700"
                        >
                            {props.label}
                        </FormLabel>
                    )}
                    {props.rightContent && <Box>{props.rightContent}</Box>}
                </HStack>
                {props.children && <Box w="full">{props.children}</Box>}
            </VStack>
        </FormControl>
    );
};

import React from 'react';
import {
    Modal,
    ModalBody,
    ModalContent,
    ModalOverlay,
    ModalCloseButton,
} from '@chakra-ui/modal';
import { FeatureModalViewProps } from './featureModalProps';

export const FeatureModalView: React.FC<FeatureModalViewProps> = (props) => {
    return (
        <Modal isOpen={props.isOpen} onClose={props.onClose}>
            <ModalCloseButton outline="none" _focus={{ outline: 'none' }} />
            <ModalOverlay />
            <ModalContent
                maxW={{ sm: '100%', lg: '75%' }}
                _focus={{ outline: 'none' }}
                outline="none"
            >
                <ModalBody border="none" p={0} m={0} borderRadius="lg">
                    {props.children}
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

import { AgentInterviewController } from './agentInterviewInterface';
import { AgentInterviewViewProps } from './agentInterviewProps';

export function createAgentInterviewController(): AgentInterviewController {
    return {
        useProps(item, props): AgentInterviewViewProps {
            return {
                dataset: {
                    id: item.dataset.id,
                    label: item.dataset.name,
                    description: item.dataset.description ?? null,
                },
            };
        },
    };
}

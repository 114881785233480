import React from 'react';
import {
    Link as ChakraLink,
    Button,
    Grid,
    GridItem,
    HStack,
    Icon,
    Text,
    VStack,
    Spinner,
    Center,
    Box,
} from '@chakra-ui/react';
import { HomeGuideViewProps } from '../../../strategy';
import { RemoteIframe } from '../../../../ui';
import { HomeItemConfig } from '../homeRouteModel';
import { AiOutlineEyeInvisible } from 'react-icons/ai';

export function createHomeSecondaryView(
    init: HomeItemConfig
): React.FC<HomeGuideViewProps & { onHide: React.MouseEventHandler }> {
    const {
        UI: { Anchor },
    } = init;
    return (props) => {
        return (
            <HStack spacing={6}>
                <Anchor {...props.hrefProps}>
                    <Button color="blue.400" variant="link" _focus={{ outline: 'none' }}>
                        Learn more about best practices
                    </Button>
                </Anchor>
                <Button
                    variant="link"
                    size="sm"
                    rightIcon={<Icon as={AiOutlineEyeInvisible} />}
                    onClick={props.onHide}
                    _focus={{ outline: 'none' }}
                >
                    Hide
                </Button>
            </HStack>
        );
    };
}

export function createHomeGuideView(init: HomeItemConfig): React.FC<HomeGuideViewProps> {
    const {
        UI: {
            Tooltip,
            Icon: { IconGroup, Icon },
            EmptyState,
        },
    } = init;
    return (props) => {
        return (
            <VStack align="start" w="full" spacing={3}>
                <Grid
                    w="full"
                    gap={6}
                    templateColumns={{
                        base: '1fr',
                        sm: '1fr 1fr',
                        xl: '1fr 1fr 1fr',
                    }}
                >
                    {props.items.map((item, index) => {
                        const itemProps = props.getItemProps(item);
                        return (
                            <GridItem key={itemProps.id}>
                                <VStack
                                    w="full"
                                    p={6}
                                    borderColor="whiteAlpha.300"
                                    borderWidth={2}
                                    borderStyle="solid"
                                    borderRadius="lg"
                                    spacing={3}
                                >
                                    <HStack w="full">
                                        <Text fontSize="lg" fontWeight="semibold">
                                            {itemProps.title}
                                        </Text>
                                    </HStack>
                                    <Box
                                        bg="whiteAlpha.300"
                                        borderRadius="lg"
                                        overflow="hidden"
                                        w="full"
                                        style={{
                                            width: '100%',
                                            position: 'relative',
                                            paddingBottom: '64.86486486486486%',
                                            height: 0,
                                        }}
                                    >
                                        <iframe
                                            // spinner={
                                            //     <Center w="full" h="full" py={6}>
                                            //         <Spinner
                                            //             color="whiteAlpha.500"
                                            //             speed="1.5s"
                                            //         />
                                            //     </Center>
                                            // }
                                            src={itemProps.url}
                                            frameBorder="0"
                                            allowFullScreen
                                            style={{
                                                position: 'absolute',
                                                top: 0,
                                                left: 0,
                                                width: '100%',
                                                height: '100%',
                                            }}
                                        />
                                    </Box>
                                </VStack>
                            </GridItem>
                        );
                    })}
                </Grid>
            </VStack>
        );
    };
}

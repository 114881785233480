import { action, makeObservable, observable } from 'mobx';

import { sendInviteReferral, getInviteReferral } from './../api/referral';
import { ReferralRequest, ReferralRequestFull } from '../models/Referral';
import StoreBase from './StoreBase';

class ReferralStore extends StoreBase {
    isLoading: boolean = false;
    currentReferral: ReferralRequestFull | null = null;
    allReferrals: Array<ReferralRequestFull> | null = null;
    totalInvited: number = 0;

    constructor() {
        super();
        makeObservable(this, {
            // Observables
            allReferrals: observable,
            currentReferral: observable,
            isLoading: observable,
            totalInvited: observable,

            // Actions
            start: action,
            setReferrals: action,
            setLoading: action,
            inviteReferral: action,
            setCurrentReferral: action,
            fetchAllReferralInvite: action,
        });
    }

    // start = async () => {
    // await this.rootStore.auth.waitAuthenticated();
    // await this.fetchAllReferralInvite();
    // };

    setCurrentReferral(req: ReferralRequestFull) {
        if (this.currentReferral?.id !== req.id) {
            this.totalInvited += 1;
        }
        this.currentReferral = req;
    }

    setLoading(flag: boolean) {
        this.isLoading = flag;
    }

    setReferrals(refs: Array<ReferralRequestFull>) {
        this.allReferrals = refs;
    }

    inviteReferral = async (request: ReferralRequest) => {
        this.setLoading(true);
        try {
            const { data } = await sendInviteReferral(request);
            //   this.setAssetInEdit(data);
            //   await this.fetchDefinitions();
            this.setCurrentReferral(data);
        } catch (e) {
            console.log(e);
            const isDone = await this.rootStore.auth.doCommonUnauthErrorHandling(e);
            if (isDone) {
                await this.inviteReferral(request);
            } else {
                this.handleAxiosError(e);
            }
        } finally {
            this.setLoading(false);
        }
    };

    fetchAllReferralInvite = async () => {
        await this.rootStore.auth.waitAuthenticated();
        this.setLoading(true);
        try {
            const { data } = await getInviteReferral();
            this.setReferrals(data);
        } catch (e) {
            const isDone = await this.rootStore.auth.doCommonUnauthErrorHandling(e);
            if (isDone) {
                await this.fetchAllReferralInvite();
            } else {
                this.handleAxiosError(e);
            }
        } finally {
            this.setLoading(false);
        }
    };
}

export default ReferralStore;

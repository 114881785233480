import './index.css';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { sentry, highlight } from './container';
import * as serviceWorker from './serviceWorker';

declare global {
    interface Window {}
}

sentry.initialize();

// quick hack to avoid initializing highlight.io on the template
// pages so that the headless browser can properly wait
// for idle network activity
if (window.location.pathname.startsWith(`/template/`)) {
    console.log('skipping highlight.io');
} else {
    highlight.initialize();
}

ReactDOM.render(
    <React.StrictMode>
        <sentry.ErrorBoundary>
            <App />
        </sentry.ErrorBoundary>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import React from 'react';
import { useDisclosure } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormIds } from '../../../../../config';
import {
    SettingIntegrationDetailController,
    SettingIntegrationDetailLoader,
} from './integrationDetailnterface';
import {
    SettingIntegrationDetailContainerProps,
    SettingIntegrationDetailViewProps,
} from './integrationDetailProps';
import { SettingIntegrationDetailUrlParamsSchema } from './integrationDetailSchema';
import { SettingsIntegrationContainerConfig } from '../base';
import {
    createMappingNewService,
    SettingMappingNewFormValuesRawSchema,
    SettingMappingNewServiceProps,
    SettingsMappingNewFormValues,
} from '../mapping';

export function createSettingIntegrationDetailContainer(
    config: SettingsIntegrationContainerConfig,
    loader: SettingIntegrationDetailLoader,
    controller: SettingIntegrationDetailController,
    View: React.FC<SettingIntegrationDetailViewProps>
): React.FC<SettingIntegrationDetailContainerProps> {
    const {
        context: { useContext },
        infra: {
            options: { useOptions },
            toaster: { useToast },
        },
        repository: {
            mapping: { useCreate: useMappingCreate, useDelete: useMappingDelete },
        },
    } = config;

    const service = {
        mapping: createMappingNewService(),
    };

    return ({ children, ...containerProps }) => {
        const context = useContext();
        const toast = useToast();
        const options = useOptions(SettingIntegrationDetailUrlParamsSchema);
        const data = loader.useLoad(context, options);

        const mutation = {
            mapping: {
                create: useMappingCreate(context),
                delete: useMappingDelete(context),
            },
        };

        const form = {
            mapping: useForm<SettingsMappingNewFormValues>({
                defaultValues: {},
                reValidateMode: 'onChange',
                mode: 'onChange',
                resolver: zodResolver(SettingMappingNewFormValuesRawSchema),
            }),
        };

        const disclosure = {
            modal: useDisclosure({
                onClose() {
                    form.mapping.reset();
                },
            }),
            field: { asset: useDisclosure(), mappable: useDisclosure() },
        };

        const props = controller.useProps(context, data, {
            mapping: {
                disclosure: disclosure.field,
                form: {
                    id: FormIds.SETTING_INTEGRATION_MAPPING_NEW,
                    form: form.mapping,
                    async onSubmit(values) {
                        const parsed = SettingMappingNewFormValuesRawSchema.parse(values);
                        const result = await service.mapping.create(
                            context,
                            data.mapping,
                            {
                                asset: { id: parsed.asset },
                                mappable: { id: parsed.mappable },
                                mutation: mutation.mapping.create,
                            }
                        );
                        toast({
                            kind: 'success',
                            description: `Integration mapping added`,
                        });
                        disclosure.modal.onClose();
                    },
                    onSubmitError(error) {
                        console.error(error);
                        toast({
                            kind: 'error',
                            description: `Failed to add mapping`,
                        });
                    },
                },
            },
            modal: {
                disclosure: disclosure.modal,
                trigger: null,
            },
        });
        return <View {...props}>{children}</View>;
    };
}

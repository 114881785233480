import React from 'react';
import {
    StudiesSubmissionListController,
    StudiesSubmissionListLoader,
} from './studiesSubmissionInterface';
import { StudiesSubmissionListViewProps } from './studiesSubmissionProps';
import { StudiesContextProvider } from '../../base';

export function createStudiesSubmissionListRoute(
    context: StudiesContextProvider,
    controller: StudiesSubmissionListController,
    loader: StudiesSubmissionListLoader,
    View: React.FC<StudiesSubmissionListViewProps>
): React.FC {
    return (props) => {
        const viewContext = context.useContext();
        const viewData = loader.useLoad(viewContext);
        const viewProps = controller.useProps(viewContext, { item: viewData.data });
        return <View {...viewProps} />;
    };
}

import React from 'react';
import { Integration, IntegrationConf } from '../../models/integration';
import { IntegrationView } from '../../components/Integrations/IntegrationsList';
import {
    IntegrationCard,
    IntegrationCardProps,
    IntegrationCardAction,
    IntegrationCardActionProps,
    IntegrationCardButton,
    UserIntegrationDefinition,
    IntegrationModal,
    isLive,
} from '../../domain';
import { useIntegrationTheme } from '../integration';
import { Box, Text, useDisclosure } from '@chakra-ui/react';

export interface OnboardingIntegrationItemViewProps
    extends Omit<IntegrationCardProps, 'footer' | 'onClick' | 'displayMode' | 'theme'>,
        Omit<
            IntegrationCardActionProps,
            'onConnect' | 'onReconnect' | 'renderButton' | 'theme'
        > {
    definition: UserIntegrationDefinition;
    onConnect: (integ: IntegrationConf) => void;
    onReconnect: (integ: IntegrationConf, integration: Integration) => void;
    onSelect?(): void;
}

export const OnboardingIntegrationItemView: React.FC<
    OnboardingIntegrationItemViewProps
> = ({ definition, ...props }) => {
    const theme = useIntegrationTheme();

    const { isOpen, onOpen, onClose } = useDisclosure();
    
    const onConnectClick: IntegrationCardActionProps['onConnect'] = (event) => {
        event.stopPropagation();
        return props.onConnect(definition);
    };

    const onReconnectClick: IntegrationCardActionProps['onReconnect'] = (event) => {
        event.stopPropagation();
        const [integration] = definition.integrations;
        if (!integration) {
            throw new Error('no integration to reconnect');
        }
        return props.onReconnect(definition, integration);
    };

    const isConnected = definition.integrations.some(
        (integration) => integration.is_connected
    );

    return (
        <IntegrationCard
            {...props}
            highlight={isConnected ? false : props.highlight}
            definition={definition}
            containerProps={{ minHeight: '170px' }}
            theme={theme}
            action={
                <IntegrationCardAction
                    {...props}
                    theme={theme}
                    definition={definition}
                    onConnect={onConnectClick}
                    onReconnect={onReconnectClick}
                    onConnectedClick={onOpen}
                    renderButton={(buttonProps) =>
                        isConnected ? (
                            <IntegrationCardButton.Connected onClick={onOpen}>
                                Connected
                            </IntegrationCardButton.Connected>
                        ) : (
                            <IntegrationCardButton.Connect
                                {...buttonProps}
                                theme={theme}
                            />
                        )
                    }
                />
            }
        >
                {props.onConnect && (
                    <IntegrationModal isOpen={isOpen} onClose={onClose} definition={definition} onConnect={props.onConnect}>
                        {definition.integrations.map((integration) => (
                            <Box w="full" borderBottom="1px solid #46454A" key={integration.id}>
                                <IntegrationView
                                    integration={integration}
                                    integrationDefinition={definition}
                                    connect={props.onConnect!}
                                    reconnect={props.onReconnect!}
                                />
                            </Box>
                        ))}
                    </IntegrationModal>
                )}
        </IntegrationCard>
    );
};

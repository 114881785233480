import React from 'react';
import { MovementAtomProps, EnumAtomProps } from '../../../atom/attributes';
import { isNamedEnum } from '../../../domain/attributes';

export function createEmailAtoms(config: { icons: Record<string, string> }): {
    Movement: React.FC<MovementAtomProps>;
    Enum: React.FC<EnumAtomProps>;
} {
    const GREEN = '#39BB68';
    const RED = '#EE4848';
    return {
        Movement(props) {
            let color: string | undefined = undefined;

            let direction: 'up' | 'down' | null = null;
            if (props.value.change && props.value.change > 0) {
                direction = 'up';
            }
            if (props.value.change && props.value.change < 0) {
                direction = 'down';
            }

            if (props.type.inverse && direction === 'up') {
                color = RED;
            } else if (props.type.inverse && direction === 'down') {
                color = GREEN;
            } else if (!props.type.inverse && direction === 'up') {
                color = GREEN;
            } else if (!props.type.inverse && direction === 'down') {
                color = RED;
            }

            if (!props.value.base) {
                return <p>N/A</p>;
            }

            return (
                <div>
                    <p>{props.format(props.type.base, props.value.base)}</p>
                    <p style={{ color }}>
                        {props.value.change ? (
                            <>{direction === 'up' ? '+' : '-'}</>
                        ) : null}
                        {props.value.change
                            ? props.format('percent', Math.abs(props.value.change))
                            : 'N/A'}
                    </p>
                </div>
            );
        },
        Enum(props) {
            const member = props.type.members.find(
                (member) => member.value === props.value
            );
            if (!member) {
                console.warn(
                    `member '${props.value}' not found in enum ${props.type.name}`
                );
            }
            if (!isNamedEnum(props.type)) {
                return <>{member?.label ?? props.value}</>;
            }
            const iconSource = config.icons[props.type.namespace];
            if (!iconSource) {
                console.warn(
                    `namespace ${props.type.namespace} does not have a configured icon`
                );
            }
            return (
                <p
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    {iconSource && (
                        <img
                            style={{ marginRight: '12px' }}
                            width="24px"
                            src={iconSource}
                        />
                    )}
                    <span>{member?.label ?? props.value}</span>
                </p>
            );
        },
    };
}

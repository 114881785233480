import React from 'react';
import { EmailInsightViewProps } from '../../../view/email';
import { EmailInsightController } from '../../../view/email';
import { OrganizationReference, AuthenticationV2 } from '../../../domain/platform';
import { OpportunityService } from '../../../service/insight';
import { EmailInsightContainerProps } from './emailInsightProps';
import moment from 'moment';

export function createEmailInsightContainer(config: {
    hook: {
        useOrganization(): OrganizationReference;
        useAuth(): AuthenticationV2;
    };
    service: {
        opportunity: OpportunityService;
    };
    controller: EmailInsightController;
    View: React.FC<EmailInsightViewProps>;
}): React.FC<EmailInsightContainerProps> {
    const {
        View,
        controller,
        hook: { useOrganization, useAuth },
        service,
    } = config;

    const now = new Date();

    return (props) => {
        const organization = useOrganization();
        const auth = useAuth();

        const context = { auth, organization, workspace: props.workspace };

        const query = {
            latest: service.opportunity.useFind(
                context,
                {
                    since: moment(now).subtract(4, 'week').toDate(),
                    limit: 100,
                    orderBy: [{ key: 'period_end_at', direction: 'asc' }],
                    aggregate: {
                        keys: ['definition', 'kind'],
                        take: 1,
                    },
                },
                { suspense: true }
            ),
            current: service.opportunity.useLookup(
                context,
                {
                    id: props.insight.id,
                },
                { suspense: true }
            ),
        };

        const viewProps = controller.useProps(context, query, {
            workspace: props.workspace,
            insight: {
                id: props.insight.id,
            },
        });

        return <View {...viewProps} />;
    };
}

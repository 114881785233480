import {
    Box,
    Breadcrumb,
    Link as ChakraLink,
    BreadcrumbItem,
    BreadcrumbLink,
    Button,
    ButtonGroup,
    FormHelperText,
    FormControl,
    FormLabel,
    Grid,
    GridItem,
    Heading,
    HStack,
    Icon,
    Input,
    InputGroup,
    // Link,
    StackDivider,
    Tag,
    Text,
    VStack,
    Center,
    Tabs,
    TabList,
    Tab,
    TabPanels,
    TabPanel,
    ListIcon,
    ListItem,
    List,
    RadioGroup,
    Radio,
    InputLeftAddon,
    FormErrorMessage,
    Alert,
    AlertIcon,
    AlertDescription,
    Tooltip,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import React from 'react';
import { AiOutlinePlus } from 'react-icons/ai';
import { CardSpinner } from '../../../../domain';
import { ReportSelectAssetProps, ReportSelectViewProps } from '../../../view/reports';
import { ReportSelectRouteConfig } from './reportSelectConfig';
import { HiOutlineCube } from 'react-icons/hi';

export function createReportSelectRouteView(
    config: ReportSelectRouteConfig
): React.FC<ReportSelectViewProps> {
    const {
        UI: { Table, Thead, Tbody, Tr, Td, Th },
    } = config;

    const AssetIcon = HiOutlineCube;

    const AssetList: React.FC<ReportSelectAssetProps> = (props) => {
        return (
            <VStack w="full" spacing={3} align="start">
                {props.items.map((item) => {
                    const optionProps = props.getOptionProps(item);
                    return (
                        <Box
                            key={item.value}
                            userSelect="none"
                            w="full"
                            onClick={optionProps.onClick}
                            aria-selected={optionProps.isSelected}
                            py={3}
                            px={5}
                            cursor="pointer"
                            borderRadius="md"
                            borderWidth={2}
                            borderStyle="solid"
                            borderColor="whiteAlpha.300"
                            _selected={{
                                borderColor: 'blue.300',
                            }}
                        >
                            <HStack spacing={16}>
                                <HStack spacing={4} flex={1}>
                                    <Box p={2} bg="whiteAlpha.300" borderRadius="md">
                                        <Icon fontSize="xl" as={AssetIcon} />
                                    </Box>
                                    <VStack align="start" spacing={-0.5}>
                                        <HStack w="full">
                                            <Text fontWeight="medium">{item.label}</Text>
                                        </HStack>
                                        <Text
                                            color="whiteAlpha.700"
                                            whiteSpace="pre-wrap"
                                            noOfLines={1}
                                        >
                                            {item.secondary}
                                        </Text>
                                    </VStack>
                                </HStack>
                                <Box>
                                    <Radio
                                        value={item.value}
                                        isChecked={optionProps.isSelected}
                                    />
                                </Box>
                            </HStack>
                        </Box>
                    );
                })}
            </VStack>
        );
    };

    return (props) => {
        if (props.status === 'loading') {
            return (
                <Center py={16} h="100%" position="relative">
                    <Box position="relative" bottom={16}>
                        <CardSpinner />
                    </Box>
                </Center>
            );
        }
        const formProps = props.getFormProps();
        return (
            <Grid templateRows="1fr" h="full">
                <GridItem>
                    <VStack position="relative">
                        <VStack
                            w="full"
                            maxW={{
                                sm: '100vw',

                                md: '90vw',
                                lg: '90vw',
                                xl: '90vw',
                                '2xl': '80vw',
                                '3xl': '75vw',
                            }}
                            marginX="auto"
                            divider={
                                <StackDivider
                                    borderColor="whiteAlpha.200"
                                    borderWidth={1}
                                />
                            }
                            spacing={4}
                            pt={4}
                            pb={48}
                        >
                            <HStack justify="space-between" w="full">
                                <HStack flex={1} spacing={3} align="center">
                                    <Breadcrumb
                                        separator="/"
                                        color="whiteAlpha.500"
                                        fontWeight="semibold"
                                        fontSize="2xl"
                                    >
                                        <BreadcrumbItem>
                                            <BreadcrumbLink
                                                as={Link}
                                                to=".."
                                                _focus={{ outline: 'none' }}
                                            >
                                                Audits
                                            </BreadcrumbLink>
                                        </BreadcrumbItem>
                                        <BreadcrumbItem
                                            isCurrentPage={true}
                                            color="whiteAlpha.900"
                                        >
                                            <Text>New audit</Text>
                                        </BreadcrumbItem>
                                    </Breadcrumb>
                                </HStack>
                                <ButtonGroup>
                                    <Button
                                        w="full"
                                        colorScheme="green"
                                        onClick={formProps.form.handleSubmit(
                                            formProps.onSubmit
                                        )}
                                        isLoading={formProps.isSubmitting}
                                        isDisabled={!formProps.isSubmittable}
                                        _focus={{ outline: 'none' }}
                                    >
                                        {formProps.submitText}
                                    </Button>
                                </ButtonGroup>
                            </HStack>
                            <Grid
                                templateColumns={{
                                    base: '1fr',
                                    md: 'minmax(0, 1fr) minmax(0, 1fr)',
                                    xl: 'minmax(0, 1fr) minmax(0, 2fr)',
                                }}
                                w="full"
                                gap={8}
                                py={4}
                            >
                                <GridItem>
                                    <VStack
                                        align="start"
                                        w="full"
                                        spacing={8}
                                        divider={
                                            <StackDivider
                                                borderColor="whiteAlpha.300"
                                                borderWidth={1}
                                            />
                                        }
                                    >
                                        {/* <FormControl>
                                            <FormLabel>Dates</FormLabel>
                                            <ReactDatePicker
                                                customInput={
                                                    <HStack
                                                        cursor="pointer"
                                                        w="full"
                                                        px={5}
                                                        py={3}
                                                        borderRadius="md"
                                                        fontWeight="medium"
                                                        justify="space-between"
                                                        borderWidth={2}
                                                        borderStyle="solid"
                                                        borderColor="whiteAlpha.300"
                                                        _focus={{ outline: 'none' }}
                                                        _hover={{ bg: 'whiteAlpha.100' }}
                                                    >
                                                        <Text>
                                                            {dateProps.getAbsoluteLabel(
                                                                dateProps.value,
                                                                true
                                                            )}
                                                        </Text>
                                                        <Icon
                                                            color="whiteAlpha.500"
                                                            as={CalendarIcon}
                                                        />
                                                    </HStack>
                                                }
                                                {...dateProps.getRangeProps()}
                                                selectsRange={true}
                                            />
                                        </FormControl> */}
                                        <Tabs
                                            w="full"
                                            isFitted={true}
                                            variant="solid-rounded"
                                            index={
                                                formProps.values.mode === 'preset' ? 0 : 1
                                            }
                                            onChange={(index) =>
                                                formProps.form.setValue(
                                                    'mode',
                                                    index === 0 ? 'preset' : 'new'
                                                )
                                            }
                                        >
                                            <TabList>
                                                <Tooltip
                                                    closeOnClick={false}
                                                    placement="top"
                                                    hasArrow={true}
                                                    p={4}
                                                    bg="#1D1C21"
                                                    label={
                                                        <Text>
                                                            Create report using a company
                                                            preset
                                                        </Text>
                                                    }
                                                >
                                                    <Tab
                                                        id="preset"
                                                        fontWeight="medium"
                                                        _focus={{ outline: 'none' }}
                                                        _active={{ bg: 'inherit' }}
                                                    >
                                                        Saved companies
                                                    </Tab>
                                                </Tooltip>
                                                <Tooltip
                                                    closeOnClick={false}
                                                    placement="top"
                                                    hasArrow={true}
                                                    p={4}
                                                    bg="#1D1C21"
                                                    label={
                                                        <Text>
                                                            Create report for a new
                                                            company
                                                        </Text>
                                                    }
                                                >
                                                    <Tab
                                                        id="new"
                                                        // borderWidth={2}
                                                        fontWeight="medium"
                                                        _focus={{ outline: 'none' }}
                                                        _active={{ bg: 'inherit' }}
                                                    >
                                                        <HStack>
                                                            <Text>New company</Text>
                                                            <Icon as={AiOutlinePlus} />
                                                        </HStack>
                                                    </Tab>
                                                </Tooltip>
                                            </TabList>
                                            <TabPanels>
                                                <TabPanel id="preset" p={0} pt={4}>
                                                    <AssetList
                                                        {...props.getAssetProps()}
                                                    />
                                                </TabPanel>
                                                <TabPanel id="new" p={0} pt={4}>
                                                    <VStack w="full">
                                                        {formProps.error && (
                                                            <Alert status="error">
                                                                <AlertIcon />
                                                                <AlertDescription>
                                                                    {
                                                                        formProps.error
                                                                            .message
                                                                    }
                                                                </AlertDescription>
                                                            </Alert>
                                                        )}
                                                        <FormControl
                                                            isInvalid={
                                                                !!formProps.form.formState
                                                                    .errors.name
                                                            }
                                                        >
                                                            <FormLabel>
                                                                Company name
                                                            </FormLabel>
                                                            <InputGroup size="lg">
                                                                <Input
                                                                    {...formProps.form.register(
                                                                        'name'
                                                                    )}
                                                                    placeholder="Enter company name"
                                                                />
                                                            </InputGroup>
                                                            <FormErrorMessage>
                                                                {
                                                                    formProps.form
                                                                        .formState.errors
                                                                        .name?.message
                                                                }
                                                            </FormErrorMessage>
                                                        </FormControl>
                                                        <FormControl
                                                            isInvalid={
                                                                !!formProps.form.formState
                                                                    .errors.url
                                                            }
                                                        >
                                                            <FormLabel>
                                                                Company URL
                                                            </FormLabel>
                                                            <InputGroup size="lg">
                                                                <InputLeftAddon
                                                                    bg="whiteAlpha.300"
                                                                    color="whiteAlpha.500"
                                                                >
                                                                    https://
                                                                </InputLeftAddon>
                                                                <Input
                                                                    {...formProps.form.register(
                                                                        'url'
                                                                    )}
                                                                    placeholder="Enter company URL"
                                                                />
                                                            </InputGroup>
                                                            <FormErrorMessage>
                                                                {
                                                                    formProps.form
                                                                        .formState.errors
                                                                        .url?.message
                                                                }
                                                            </FormErrorMessage>
                                                        </FormControl>
                                                    </VStack>
                                                </TabPanel>
                                            </TabPanels>
                                        </Tabs>
                                    </VStack>
                                </GridItem>
                                <GridItem>
                                    {/* <Box
                                        h="full"
                                        w="full"
                                        bg="whiteAlpha.50"
                                        borderRadius="lg"
                                    ></Box> */}
                                </GridItem>
                            </Grid>
                        </VStack>
                    </VStack>
                </GridItem>
            </Grid>
        );
    };
}

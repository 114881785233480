import React from 'react';
import {
    Button,
    HStack,
    Icon,
    Popover,
    PopoverBody,
    PopoverCloseButton,
    PopoverContent,
    PopoverFooter,
    PopoverTrigger,
    Text,
    VStack,
} from '@chakra-ui/react';
import { HiOutlineThumbDown, HiOutlineThumbUp } from 'react-icons/hi';
import { InsightFeedbackViewProps } from './insightFeedbackProps';

const ThumbUp = HiOutlineThumbUp;
const ThumDown = HiOutlineThumbDown;

export function createInsightFeedbackView(): React.FC<InsightFeedbackViewProps> {
    return (props) => {
        const ratingProps = props.getRatingProps();
        const formProps = ratingProps.getFormProps(props.item);
        return (
            <Popover
                // need to reset local form state
                isLazy={true}
                placement="top"
                isOpen={props.disclosure.isOpen}
                onOpen={props.disclosure.onOpen}
                onClose={props.disclosure.onClose}
            >
                <PopoverTrigger>
                    <HStack position="relative" cursor="pointer">
                        <Icon position="relative" top={0.5} fontSize="lg" as={ThumDown} />
                        <Icon fontSize="lg" as={ThumbUp} />
                    </HStack>
                </PopoverTrigger>
                <PopoverContent bg="#292929" border="none" _focus={{ outline: 'none' }}>
                    <PopoverCloseButton onClick={props.disclosure.onClose} />
                    <PopoverBody px={4} pt={8} pb={4} m={0}>
                        <VStack w="full" spacing={4}>
                            <Text fontWeight="bold" color="whiteAlpha.900">
                                How would you rate this insight?
                            </Text>
                            <VStack w="full">
                                <VStack
                                    // w="full"
                                    mx="auto"
                                    w="fit-content"
                                    spacing={1}
                                >
                                    <HStack justify="space-between" spacing={4}>
                                        {ratingProps.options.map((item) => {
                                            const optionProps =
                                                ratingProps.getOptionProps(item);
                                            return (
                                                <HStack
                                                    key={item.value}
                                                    aria-selected={optionProps.isSelected}
                                                    onClick={optionProps.onClick}
                                                    h={8}
                                                    w={8}
                                                    align="center"
                                                    justify="center"
                                                    bg="whiteAlpha.200"
                                                    fontWeight="bold"
                                                    fontSize="sm"
                                                    cursor="pointer"
                                                    _selected={{
                                                        bg: 'green.400',
                                                        color: 'whiteAlpha.900',
                                                    }}
                                                >
                                                    <Text>{item.label}</Text>
                                                </HStack>
                                            );
                                        })}
                                    </HStack>
                                    <HStack
                                        w="full"
                                        justify="space-between"
                                        color="whiteAlpha.500"
                                        fontWeight="semibold"
                                        fontSize="sm"
                                    >
                                        <Text>Very poor</Text>
                                        <Text>Very good</Text>
                                    </HStack>
                                </VStack>
                            </VStack>
                        </VStack>
                    </PopoverBody>
                    <PopoverFooter>
                        <HStack w="full" justify="end">
                            {!formProps.isSubmitted && (
                                <Button
                                    size="sm"
                                    colorScheme="green"
                                    isLoading={formProps.isSubmitting}
                                    isDisabled={
                                        !formProps.isValid ||
                                        formProps.isSubmitting ||
                                        formProps.isSubmitted
                                    }
                                    onClick={formProps.onSubmit.bind(null, props.item)}
                                >
                                    Submit
                                </Button>
                            )}
                            {formProps.isSubmitted && (
                                <Button
                                    size="sm"
                                    variant="outline"
                                    border="none"
                                    bg="none"
                                    cursor="auto"
                                    color="whiteAlpha.700"
                                    _hover={{ bg: 'none' }}
                                >
                                    Submitted, thanks!
                                </Button>
                            )}
                        </HStack>
                    </PopoverFooter>
                </PopoverContent>
            </Popover>
        );
    };
}

import React from 'react';
import { WorkspaceNavigationContainerProps } from '../navigationProps';
import { WorkspaceNavigationContainerConfig } from '../navigationConfig';

export function createWorkspaceNavigationContainer(
    config: WorkspaceNavigationContainerConfig
): React.FC<WorkspaceNavigationContainerProps> {
    const { controller, Component } = config;
    return () => {
        const viewProps = controller.useProps();
        return <Component {...viewProps} />;
    };
}

import { useMemo } from 'react';
import { z, ZodError } from 'zod';
import {
    createDownloadLinkController,
    createFileController,
    createFormController,
    createSelectController,
} from '../../../common';
import { assert } from '../../../../util/assert';
import { FileParser, ParseError } from '../../../../app/file';
import { EntryAggregate } from '../../entry';
import { createPropertyListController } from '../../property';
import { DatasetAggregate } from '../../dataset';
import { ImportNewViewConfig } from './importNewConfig';
import { ImportNewController } from './importNewInterface';
import { ImportNewViewProps } from './importNewProps';
import { ImportNewFormValues, RawRow } from './importNewModel';
import { createTemplateContent } from './importNewFactory';

export function createImportNewController(
    config: ImportNewViewConfig
): ImportNewController {
    const {
        infra: { formatter, parser },
        controller: {
            entry: { list: entryController },
        },
    } = config;

    const selectController = createSelectController<DatasetAggregate>({
        getItemProps(item) {
            return {
                icon: {
                    kind: 'image',
                    src: item.plugin.iconUrl ?? '',
                },
                value: item.dataset.id,
                label: item.dataset.name,
                description: null,
            };
        },
    });

    const fileController = createFileController<RawRow[]>({
        mockDelayMs: 1500,
    });

    const formController = createFormController();
    const downloadController = createDownloadLinkController();
    const propertyListController = createPropertyListController();

    const now = new Date();

    return {
        useProps(context, deps, props): ImportNewViewProps {
            const selected = useMemo(
                () =>
                    props.item.datasets.find(
                        (candidate) => candidate.dataset.id === deps.values.dataset
                    ) ?? null,
                [props.item.datasets, deps.values.dataset]
            );

            const dataset = selected?.dataset ?? null;

            const parse = useMemo(() => {
                if (!dataset) {
                    return () => [];
                }
                return parser.create(dataset);
            }, [dataset]);

            const fileProps = fileController.useProps({
                parse,
                value: deps.values.rows ?? [],
                onChange(value) {
                    deps.form.setValue('rows', value);
                    if (value) {
                        props.onFileUpload(value);
                    }
                },
                onError(error) {
                    if (error instanceof ParseError) {
                        return props.onFileError(error);
                    }
                    throw error;
                },
            });

            const entryListProps = entryController.useProps(context, {
                item: {
                    configuration: dataset?.configuration ?? {
                        partitionKey: '',
                        schema: [],
                        timeKey: '',
                    },
                    items:
                        deps.values.rows?.map(
                            (row, index): EntryAggregate => ({
                                entry: {
                                    id: index.toString(),
                                    data: row,
                                    timestamp: now,
                                },
                            })
                        ) ?? [],
                },
            });

            const selectListProps = selectController.useProps(
                { disclosure: deps.disclosure.select },
                {
                    items: props.item.datasets,
                    value: deps.values.dataset,
                    onChange(value) {
                        deps.form.setValue(
                            'dataset',
                            // @ts-expect-error
                            value
                        );
                    },
                }
            );

            const downloadLinkProps = downloadController.useProps({
                filename: dataset
                    ? `${dataset.name.toLowerCase().replace(' ', '_')}_template.csv`
                    : `template.csv`,
                mimetype: 'text/csv',
                content() {
                    assert(dataset?.configuration, 'missing');
                    return createTemplateContent(dataset?.configuration);
                },
            });

            const formProps = formController.useProps({
                id: 'import',
                form: deps.form,
                async onSubmit(values) {
                    try {
                        const result = await deps.mutation.create.mutateAsync({
                            asset: context.workspace.id as number,
                            dataset: values.dataset!,
                            data: values.rows ?? [],
                        });
                        await props.onFormSubmit(result);
                        return result;
                    } catch (error) {
                        if (error instanceof Error) {
                            props.onFormError(error);
                        }
                        throw error;
                    }
                },
            });

            const propertyListProps = propertyListController.useProps(context, {
                item: {
                    items:
                        dataset?.configuration.schema.map((property) => ({
                            property,
                        })) ?? [],
                },
            });

            return {
                getEntryListProps() {
                    return entryListProps;
                },
                getDatasetSelectListProps() {
                    return selectListProps;
                },
                getFileProps() {
                    return fileProps;
                },
                getPropertyListProps() {
                    return propertyListProps;
                },
                getFormProps() {
                    return formProps;
                },
                getDownloadLinkProps() {
                    return downloadLinkProps.getButtonProps();
                },
            };
        },
    };
}

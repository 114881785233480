import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { DemoChartContainerProps } from '../../../controller/demo';
import {
    AOV_OPTIONS,
    MONTHLY_REVENUE_OPTIONS,
    MONTHLY_SPEND_OPTIONS,
    FACEBOOK_AOV_PROPERTY,
    FACEBOOK_MONTHLY_SPEND_PROPERTY,
    TIKTOK_MONTHLY_SPEND_PROPERTY,
    AOV_SHOPIFY_PROPERTY,
    SHOPIFY_MONTHLY_REVENUE_PROPERTY,
    GOOGLE_AOV_PROPERTY,
    GOOGLE_MONTHLY_SPEND_PROPERTY,
} from '../../../config/demo';
import { AnyCondition } from '../../../domain/attributes';
import {
    DemoFormValues,
    DemoRouteControllerHook,
    DemoRouteControllerReturn,
} from '../../../route/demo';
import { useQuery } from '@tanstack/react-query';
import { DemoRouteControllerConfig } from './demoRouteConfig';

const REVENUE_KEYS = [SHOPIFY_MONTHLY_REVENUE_PROPERTY.key];

const SPEND_KEYS = [
    FACEBOOK_MONTHLY_SPEND_PROPERTY.key,
    GOOGLE_MONTHLY_SPEND_PROPERTY.key,
    TIKTOK_MONTHLY_SPEND_PROPERTY.key,
];

const AOV_KEYS = [
    FACEBOOK_AOV_PROPERTY.key,
    GOOGLE_AOV_PROPERTY.key,
    AOV_SHOPIFY_PROPERTY.key,
];

export function createDemoRouteController(
    config: DemoRouteControllerConfig
): DemoRouteControllerHook {
    return () => {
        const [search, setSearch] = useSearchParams();

        const form = useForm<DemoFormValues>({
            defaultValues: {
                platform: null,
                filters: config.dashboards.reduce(
                    (dashboardAcc, dashboard) => ({
                        ...dashboardAcc,
                        [dashboard.id]:
                            dashboard.properties?.reduce(
                                (accFilter, property) => ({
                                    ...accFilter,
                                    [property.key]: null,
                                }),
                                {}
                            ) ?? {},
                    }),
                    {}
                ),
            },
        });

        const values = form.watch();

        const valuesWithDefault = {
            platform:
                values.platform ?? search.get('platform') ?? config.dashboards[0]?.id!,
        };

        const dashboard = useMemo(
            () =>
                config.dashboards.find((item) => item.id === valuesWithDefault.platform),
            [config.dashboards, valuesWithDefault.platform]
        );

        if (!dashboard) {
            throw new Error(`dashboard '${valuesWithDefault.platform}' not found`);
        }

        const { data: datasetStatus } = useQuery({
            queryKey: ['asset-count'],
            async queryFn() {
                return config.adapter.dataset.getAssetCount();
            },
            suspense: true,
            staleTime: Infinity,
        });

        let returnValue: DemoRouteControllerReturn = {
            dashboards: {
                items: config.dashboards,
                current: dashboard,
            },
            fields: {
                getPlatform() {
                    return {
                        onChange: (value) => form.setValue('platform', value),
                        value: valuesWithDefault.platform,
                    };
                },
                getFilter(dashboard, property) {
                    const urlKey = property.alias ?? property.key;
                    return {
                        key: `${dashboard.id}-${property.key}`,
                        value:
                            values.filters[dashboard.id]?.[property.key] ??
                            search.get(urlKey) ??
                            null,
                        onChange: (value) => {
                            form.setValue(
                                `filters.${valuesWithDefault.platform}.${property.key}`,
                                value
                            );
                        },
                    };
                },
            },
            getMetricProps(dashboard, metric, index) {
                return {};
            },
            getDateFilterProps(dashboard) {
                return {};
            },
            getPlatformListProps(props) {
                return props;
            },
            getPlatformItemProps(dashboard, props) {
                return props;
            },
            getErrorBoundaryKey() {
                return JSON.stringify({
                    ...values,
                });
            },
            getDatasetStatusProps() {
                return {
                    assetCount: datasetStatus?.count ?? 0,
                };
            },
            getCtaProps() {
                return {
                    onClick() {
                        window.open(config.url.registration, '__blank');
                    },
                };
            },
            getChartProps(): DemoChartContainerProps {
                return {
                    visualization: dashboard.visualization,
                    dashboard,
                    segment: dashboard.properties.flatMap<AnyCondition>(
                        (property): AnyCondition[] => {
                            if (property.kind !== 'trait') {
                                return [];
                            }
                            const { value } = returnValue.fields.getFilter(
                                dashboard,
                                property
                            );
                            if (!value) {
                                return [];
                            }
                            if (AOV_KEYS.includes(property.key)) {
                                return [
                                    {
                                        key: property.key,
                                        ...AOV_OPTIONS[value].condition,
                                    },
                                ];
                            }
                            if (SPEND_KEYS.includes(property.key)) {
                                return [
                                    {
                                        key: property.key,
                                        ...MONTHLY_SPEND_OPTIONS[value].condition,
                                    },
                                ];
                            }
                            if (REVENUE_KEYS.includes(property.key)) {
                                return [
                                    {
                                        key: property.key,
                                        ...MONTHLY_REVENUE_OPTIONS[value].condition,
                                    },
                                ];
                            }
                            return [
                                {
                                    key: property.key,
                                    operator: 'in',
                                    value: [value],
                                },
                            ];
                        }
                    ),
                    filters: dashboard.properties.flatMap<AnyCondition>((property) => {
                        if (property.kind !== 'data') {
                            return [];
                        }
                        const { value } = returnValue.fields.getFilter(
                            dashboard,
                            property
                        );
                        if (!value) {
                            return [];
                        }
                        return [
                            {
                                key: property.key,
                                operator: 'in',
                                value: [value],
                            },
                        ];
                    }),
                };
            },
        };
        return returnValue;
    };
}

import {
    Box,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    Button,
    ButtonGroup,
    Center,
    Grid,
    GridItem,
    Heading,
    HStack,
    Icon,
    Link as ChakraLink,
    StackDivider,
    Tag,
    Text,
    VStack,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import React from 'react';
import { AiOutlinePlus } from 'react-icons/ai';
import { ImDrawer2 } from 'react-icons/im';
import { HiOutlineDocumentReport } from 'react-icons/hi';
import { CardSpinner } from '../../../../domain';
import { ReportListItemProps, ReportListViewProps } from '../../../view/reports';
import { ReportListRouteConfig } from './reportListConfig';

export function createReportListRouteView(
    config: ReportListRouteConfig
): React.FC<ReportListViewProps> {
    const {
        UI: { Table, Thead, Tbody, Tr, Td, Th },
    } = config;

    const ReportIcon = HiOutlineDocumentReport;
    const EmptyIcon = ReportIcon;

    const ReportCell: React.FC<ReportListItemProps> = (props) => {
        return (
            <HStack spacing={4}>
                <Box flexShrink={0} p={2} bg="whiteAlpha.200" borderRadius="sm">
                    <Icon color="whiteAlpha.900" w={5} h={5} as={ReportIcon} />
                </Box>
                <VStack align="start" spacing={1}>
                    <HStack spacing={3}>
                        <ChakraLink
                            as={Link}
                            to={props.path}
                            _focus={{ outline: 'none' }}
                        >
                            <Text>{props.report.title}</Text>
                        </ChakraLink>
                    </HStack>
                    <Text color="whiteAlpha.700" fontSize="sm">
                        {props.label.period}
                    </Text>
                </VStack>
            </HStack>
        );
    };

    return (props) => {
        const statusProps = props.getStatusProps();
        if (statusProps.status === 'loading') {
            return (
                <Center py={16} h="100%" position="relative">
                    <Box position="relative" bottom={16}>
                        <CardSpinner />
                    </Box>
                </Center>
            );
        }
        if (statusProps.status === 'empty') {
            return (
                <Center py={16} h="100%" position="relative">
                    <HStack
                        position="relative"
                        bottom={16}
                        // p={6}
                        // border="2px solid"
                        // borderColor="whiteAlpha.200"
                        // borderRadius="md"
                        w="full"
                    >
                        <VStack p={4} w="full" spacing={4}>
                            <Box
                                bg="whiteAlpha.200"
                                p={3}
                                borderRadius="md"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Icon
                                    color="whiteAlpha.700"
                                    fontSize="3xl"
                                    as={EmptyIcon}
                                />
                            </Box>
                            <Heading
                                fontSize="lg"
                                fontWeight="medium"
                                color="whiteAlpha.700"
                            >
                                You don't have any audits yet
                            </Heading>
                            <Link to="new">
                                <Button
                                    // size="md"
                                    colorScheme="green"
                                    rightIcon={<Icon as={AiOutlinePlus} />}
                                    _focus={{ outline: 'none' }}
                                >
                                    New audit
                                </Button>
                            </Link>
                        </VStack>
                    </HStack>
                </Center>
            );
        }
        return (
            <Grid templateRows="1fr" h="full">
                <GridItem>
                    <VStack position="relative">
                        <VStack
                            w="full"
                            marginX="auto"
                            divider={
                                <StackDivider
                                    borderColor="whiteAlpha.200"
                                    borderWidth={1}
                                />
                            }
                            spacing={4}
                            pt={4}
                            pb={48}
                        >
                            <HStack justify="space-between" w="full">
                                <HStack flex={1} spacing={3} align="center">
                                    <Breadcrumb
                                        separator="/"
                                        color="whiteAlpha.900"
                                        fontWeight="semibold"
                                        fontSize="2xl"
                                    >
                                        <BreadcrumbItem isCurrentPage={true}>
                                            <BreadcrumbLink
                                                as={Link}
                                                to=""
                                                _focus={{ outline: 'none' }}
                                            >
                                                Audits
                                            </BreadcrumbLink>
                                        </BreadcrumbItem>
                                    </Breadcrumb>
                                    {/* <Tag
                                        fontWeight="semibold"
                                        color="blackAlpha.900"
                                        bg="#c6e74f"
                                        letterSpacing="wide"
                                    >
                                        Beta
                                    </Tag> */}
                                </HStack>
                                <Link to="new">
                                    <Button
                                        // size="sm"
                                        colorScheme="green"
                                        rightIcon={<Icon as={AiOutlinePlus} />}
                                        _focus={{ outline: 'none' }}
                                    >
                                        New audit
                                    </Button>
                                </Link>
                            </HStack>
                            <Table fontWeight="medium">
                                <Thead>
                                    <Tr>
                                        <Th>Name</Th>
                                        <Th>Creator</Th>
                                        <Th>Status</Th>
                                        <Th>Created at</Th>
                                        <Th></Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {props.items.map((item) => {
                                        const itemProps = props.getItemProps(item);
                                        return (
                                            <Tr key={item.report.id}>
                                                <Td>
                                                    <ReportCell {...itemProps} />
                                                </Td>
                                                <Td>
                                                    <Tag
                                                        size="lg"
                                                        colorScheme="green"
                                                        whiteSpace="nowrap"
                                                    >
                                                        {itemProps.user.label}
                                                    </Tag>
                                                </Td>
                                                <Td>
                                                    <Tag
                                                        size="lg"
                                                        colorScheme={
                                                            itemProps.status.colorScheme
                                                        }
                                                    >
                                                        {itemProps.status.label}
                                                    </Tag>
                                                </Td>
                                                <Td>
                                                    <Text whiteSpace="nowrap">
                                                        {itemProps.label.createdAt}
                                                    </Text>{' '}
                                                </Td>
                                                <Td textAlign="right">
                                                    <Link to={itemProps.path}>
                                                        <Button
                                                            variant="link"
                                                            _focus={{ outline: 'none' }}
                                                        >
                                                            View audit
                                                        </Button>
                                                    </Link>
                                                </Td>
                                            </Tr>
                                        );
                                    })}
                                </Tbody>
                            </Table>
                        </VStack>
                    </VStack>
                </GridItem>
            </Grid>
        );
    };
}

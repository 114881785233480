import { AnyBlockObject, AnyElementObject, AnyInputBlockObject } from '@varos/schema';
import { assertNever } from '../../../util';
import { AnyBlock, AnyElement, AnyInputBlock } from '../../../app/conversations';

function toInputBlock(block: AnyInputBlockObject): AnyInputBlock {
    if (block.type === 'select') {
        return {
            kind: 'input',
            type: 'select',
            placeholder: block.placeholder ?? null,
            options: block.options.map((option) => ({
                label: option.label,
                value: option.value,
            })),
        };
    }
    assertNever(block.type);
}

function toElement(block: AnyElementObject): AnyElement {
    if (block.kind === 'button') {
        return {
            kind: 'button',
            variant: block.variant ?? null,
            label: block.label,
            action: block.action,
        };
    }
    assertNever(block.kind);
}

export function toBlock(block: AnyBlockObject): AnyBlock {
    if (block.kind === 'text') {
        return {
            kind: 'text',
            content: block.text,
            attachments:
                block.attachments?.map((attachment) => ({
                    kind: attachment.kind,
                    type: attachment.type,
                    id: attachment.id,
                })) ?? [],
            modifiers: new Set(block.modifiers ?? []),
        };
    }
    if (block.kind === 'json') {
        return {
            kind: 'json',
            data: block.data,
        };
    }
    if (block.kind === 'action') {
        return {
            kind: 'action',
            elements: block.elements.map(toElement),
        };
    }
    if (block.kind === 'section') {
        return {
            kind: 'section',
            text: block.text ?? null,
            fields: block.fields.map((field) => ({
                label: field.label,
                value: field.value,
            })),
        };
    }
    if (block.kind === 'input') {
        return toInputBlock(block);
    }
    if (block.kind === 'event') {
        return {
            kind: 'event',
            data: block.data,
        };
    }
    if (block.kind === 'unknown') {
        return {
            kind: 'unknown',
            data: block,
        };
    }
    assertNever(block);
}

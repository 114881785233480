import { Query, useQuery, UseQueryResult } from '@tanstack/react-query';
import { useMemo } from 'react';
// import { AnyQuerySegment, Dashboard } from '../../../api';
import { DashboardSlugs } from '../../../config/dashboard';
import { ViewIds } from '../../../config/view';
import { listDashboards, DashboardListItem } from '../../../api/v2/dashboard';
import { useWorkspaceApi } from '../../app';
import { castFilterToConditionDomain } from '../../impl';
import { ReportSegment } from '../../domain/report';
import { ApplicationEntryEnhancer } from '../../entrypoint';
import { PreferenceEnhancerConfig } from './preferenceConfig';
import { QueryBuilderHook } from '../../../domain';
import {
    PreferencDataFiltersSectionMatchDto,
    PreferencTraitFiltersSectionMatchDto,
    PreferencQuerySegmentsSectionMatchDto,
    ViewPropertyDto,
    PreferenceMatchDto,
    AnyPreferenceMatchSection,
} from '../../api';
import {
    AnyRanking,
    CompetitiveSet,
    DynamicCohortStateItem,
    FixedCohortStateItem,
} from '../../domain';
import { chain } from 'lodash';
import { MetricConfiguration } from '../../domain/metrics';
import {
    applyTypeToCondition,
    flattenEnumMembers,
    Property,
} from '../../domain/attributes';
import {
    AnyCondition,
    EnumMember,
    EnumType,
    isEnumMemberWithChildren,
    TreeType,
} from '../../domain/attributes';
import { MemberEntity } from '../../domain/attributes';
import { useSystemContextV2, useWorkspaceContextV2 } from '../../context';

interface PreferenceOverrides {
    competitive: Pick<CompetitiveSet, 'conditions'> | null;
    ranking: AnyRanking | null;
    filters: AnyCondition[] | null;
}

export function createPreferenceEnhancer(
    init: PreferenceEnhancerConfig
): ApplicationEntryEnhancer {
    return (create) => (config) => {
        const {
            context,
            adapter: {
                metric: { createConfigurationProvider },
            },
        } = config;
        const instance = create({
            ...config,
            context: {
                ...config.context,
                createWorkspace(config) {
                    return context.createWorkspace({
                        ...config,
                        provider: {
                            ...config.provider,
                            createLoader(loaderConfig) {
                                return config.provider.createLoader({
                                    ...loaderConfig,
                                    queries: {
                                        ...loaderConfig.queries,
                                        preferences: (context) => ({
                                            queryKey: [
                                                'org',
                                                'preferences',
                                                context.organization.id,
                                                context.workspace?.id,
                                            ],
                                            async queryFn() {
                                                const {
                                                    api: {
                                                        platform: {
                                                            preferences: {
                                                                match: matchPreferences,
                                                            },
                                                        },
                                                    },
                                                } = instance;
                                                const preferences =
                                                    await matchPreferences(context, {
                                                        assetId: context.workspace
                                                            ?.id as number,
                                                        plugins: [
                                                            'facebookads',
                                                            'media-mix',
                                                            'executive-summary',
                                                            'googleads',
                                                            'tiktokads',
                                                            'googleanalytics',
                                                            'shopify',
                                                            'linkedinads',
                                                        ],
                                                        dashboards: [
                                                            'facebookads-v2',
                                                            'media-mix',
                                                            'executive-summary',
                                                            'google-a-d-s-v2',
                                                            'tiktokads-v2',
                                                            'shopify-v2',
                                                            'facebook-videos',
                                                            'ga4-traffic-and-users',
                                                            'ga4-revenue',
                                                            'ga4-ecommerce',
                                                            'facebook-spend-distribution',
                                                            'linkedinads-v2',
                                                            'shopify-ltv-v2',
                                                        ],
                                                        pageSize: 1000,
                                                        sections: [
                                                            // 'trait_filters',
                                                            'query_segments',
                                                            'data_filters',
                                                        ],
                                                    });
                                                return preferences.data;
                                            },
                                        }),
                                    },
                                });
                            },
                        },
                    });
                },
            },
            adapter: {
                ...config.adapter,
                metric: {
                    ...config.adapter.metric,
                    createConfigurationProvider(config) {
                        const {
                            repository,
                            hooks: { useQuery },
                        } = config;
                        const provider = createConfigurationProvider(config);
                        return {
                            ...provider,
                            useAdapter(...args) {
                                const [context] = args;
                                const adapter = provider.useAdapter(...args);

                                // console.log('DEBUG members', members);

                                const data = {
                                    system: useSystemContextV2().data,
                                    workspace: useWorkspaceContextV2(),
                                };

                                const preferences = data.workspace.preferences as
                                    | UseQueryResult<PreferenceMatchDto[]>
                                    | undefined;

                                if (!preferences) {
                                    console.info(data.workspace);
                                    throw new Error(
                                        `key 'preferences' not found in workspace context`
                                    );
                                }

                                const dashboards = data.workspace.dashboards;

                                const propertyKeyByTypeSlug = useMemo(() => {
                                    return (
                                        data.system.views.data?.reduce(
                                            (accView, itemView) =>
                                                itemView.columns.reduce(
                                                    (accColumn, itemColumn) => {
                                                        const id = `${itemView.plugin}.${itemColumn.key}`;
                                                        if (accColumn[id]) {
                                                            // we might have duplicate columns across views
                                                            // so we assume the first one has priority
                                                            return accColumn;
                                                        }
                                                        if (
                                                            typeof itemColumn.type ===
                                                                'object' &&
                                                            itemColumn.type.kind ===
                                                                'reference'
                                                        ) {
                                                            return {
                                                                ...accColumn,
                                                                [itemColumn.type.id]:
                                                                    itemColumn,
                                                            };
                                                        }
                                                        return accColumn;
                                                    },
                                                    accView
                                                ),
                                            {} as Record<string, Property | undefined>
                                        ) ?? {}
                                    );
                                }, [data.system.views.data]);

                                // console.log(
                                //     'propertyKeyByTypeSlug',
                                //     propertyKeyByTypeSlug
                                // );

                                const preferencesByDashboard = useMemo(() => {
                                    return chain(preferences.data ?? [])
                                        .reduce<
                                            Record<string, AnyPreferenceMatchSection[]>
                                        >((agg, curr) => {
                                            const dashContext = curr.context.find(
                                                ([subj, subjId]) => subj === 'dashboard'
                                            );
                                            if (dashContext) {
                                                const dashboard = dashContext[1];
                                                const sections = agg[dashboard] || [];
                                                sections.push(...curr.sections);
                                                agg[dashboard] = sections;
                                            }
                                            return agg;
                                        }, {})
                                        .value();
                                }, [preferences.data]);

                                const preferencesByPlugin = useMemo(() => {
                                    return chain(preferences.data ?? [])
                                        .reduce<
                                            Record<string, AnyPreferenceMatchSection[]>
                                        >((agg, curr) => {
                                            const plugContext = curr.context.find(
                                                ([subj, subjId]) => subj === 'plugin'
                                            );
                                            if (plugContext) {
                                                const plugin = plugContext[1];
                                                const sections = agg[plugin] || [];
                                                sections.push(...curr.sections);
                                                agg[plugin] = sections;
                                            }
                                            return agg;
                                        }, {})
                                        .value();
                                }, [preferences.data]);

                                const savedConfigurationsByDashboard = useMemo(() => {
                                    const reduced = dashboards.data?.reduce(
                                        (acc, item) => {
                                            item;

                                            const preferences = [
                                                ...(preferencesByDashboard[item.id] ||
                                                    []),
                                                ...(preferencesByPlugin[item.pluginId] ||
                                                    []),
                                            ];

                                            const datafilters = preferences.find(
                                                (item) =>
                                                    item.section_type === 'data_filters'
                                            ) as
                                                | PreferencDataFiltersSectionMatchDto
                                                | undefined;

                                            // const trait = preferences.find(
                                            //     (item) =>
                                            //         item.section_type === 'trait_filters'
                                            // ) as
                                            //     | PreferencTraitFiltersSectionMatchDto
                                            //     | undefined;

                                            const cohorts = preferences.find(
                                                (item) =>
                                                    item.section_type === 'query_segments'
                                            ) as
                                                | PreferencQuerySegmentsSectionMatchDto
                                                | undefined;

                                            // console.log(
                                            //     'datafilters?.section.value.filters',
                                            //     datafilters?.value
                                            // );

                                            let filters: AnyCondition[] | null = null;

                                            // let competitive: Pick<
                                            //     CompetitiveSet,
                                            //     'conditions'
                                            // > | null = null;

                                            let ranking: AnyRanking | null = null;

                                            if (datafilters?.value) {
                                                filters =
                                                    datafilters.value.filters.flatMap(
                                                        (filter) => {
                                                            const condition =
                                                                castFilterToConditionDomain(
                                                                    propertyKeyByTypeSlug,
                                                                    filter
                                                                );
                                                            if (!condition) {
                                                                console.warn(
                                                                    `failed to cast filter to condition`,
                                                                    filter
                                                                );
                                                            }
                                                            return condition
                                                                ? [condition]
                                                                : [];
                                                        }
                                                    );
                                            }

                                            // if (trait?.value) {
                                            //     competitive = {
                                            //         conditions:
                                            //             trait?.value.rules.sub_rules.flatMap(
                                            //                 (rule): AnyCondition[] => {
                                            //                     if (
                                            //                         rule.metadata_definition_key
                                            //                     )
                                            //                         if (
                                            //                             Array.isArray(
                                            //                                 rule.value
                                            //                             ) &&
                                            //                             rule.operator ===
                                            //                                 'between'
                                            //                         ) {
                                            //                             const [
                                            //                                 left,
                                            //                                 right,
                                            //                             ] = rule.value;
                                            //                             return [
                                            //                                 {
                                            //                                     key: rule.metadata_definition_key,
                                            //                                     operator:
                                            //                                         rule.operator as any,
                                            //                                     value: {
                                            //                                         from: left,
                                            //                                         to: right,
                                            //                                     },
                                            //                                 },
                                            //                             ];
                                            //                         }
                                            //                     return [
                                            //                         {
                                            //                             key: rule.metadata_definition_key,
                                            //                             operator:
                                            //                                 rule.operator as any,
                                            //                             value: rule.value as any,
                                            //                         },
                                            //                     ];
                                            //                 }
                                            //             ) ?? [],
                                            //     };
                                            // }

                                            if (cohorts?.value.mode === 'fixed') {
                                                ranking = {
                                                    kind: 'fixed',
                                                    // Here the assumption is that every range segment shares the same comparison metric.
                                                    comparison:
                                                        cohorts.value.fixed[0].range
                                                            .column,
                                                    cohorts: cohorts?.value.fixed.map(
                                                        (item): FixedCohortStateItem => ({
                                                            name: item.name,
                                                            lower: item.range.start.value,
                                                            upper: item.range.end.value,
                                                            visible: true,
                                                        })
                                                    ),
                                                };
                                            }

                                            if (cohorts?.value.mode === 'dynamic') {
                                                ranking = {
                                                    kind: 'dynamic',
                                                    cohorts: cohorts?.value.dynamic.map(
                                                        (item): DynamicCohortStateItem =>
                                                            item.reducer?.kind ===
                                                            // HACK
                                                            'percentile'
                                                                ? {
                                                                      name: item.name,
                                                                      value: item.reducer!
                                                                          .value,
                                                                      visible: true,
                                                                  }
                                                                : {
                                                                      name: item.name,
                                                                      value: 50,
                                                                      visible: true,
                                                                  }
                                                    ),
                                                };
                                            }

                                            const overrides: PreferenceOverrides = {
                                                filters,
                                                competitive: null,
                                                // competitive,
                                                ranking,
                                            };

                                            return {
                                                ...acc,
                                                [item.id]: overrides,
                                            };
                                        },
                                        {} as Record<
                                            string,
                                            PreferenceOverrides | undefined
                                        >
                                    );

                                    return reduced;
                                }, [data.system, data.workspace, preferences]);
                                // console.log(
                                // 'preference configurations',
                                // savedConfigurationsByDashboard
                                // );
                                // console.log(
                                //     'DEBUG data.system.views',
                                //     data.system.views.data
                                // );

                                const queries = {
                                    member: repository.member.useFind(
                                        context,
                                        {
                                            workspace: {
                                                id: context.workspace.id,
                                            },
                                            types: [
                                                ...new Set(
                                                    data.system.views.data?.flatMap(
                                                        (item) => item.trait_filters
                                                    )
                                                ).values(),
                                            ]
                                                .filter(
                                                    (item) =>
                                                        // TODO this is a hack
                                                        item === 'vertical-v2'
                                                )
                                                .map((item) => ({
                                                    id: item,
                                                    kind: 'reference',
                                                })),
                                        },
                                        { suspense: true, staleTime: Infinity }
                                    ),
                                };

                                const typeByKey = useMemo(() => {
                                    return (
                                        queries.member.data?.reduce(
                                            (acc, member) => ({
                                                ...acc,
                                                [member.key]: member,
                                            }),
                                            {} as Record<string, MemberEntity | undefined>
                                        ) ?? {}
                                    );
                                }, queries.member.data);

                                return {
                                    ...adapter,
                                    async find(context, query) {
                                        const configurations = await adapter.find(
                                            context,
                                            query
                                        );

                                        return configurations.map((item) => {
                                            const traitDimensionIds = new Set(
                                                item.dimensions.flatMap((item) =>
                                                    item.kind === 'trait'
                                                        ? [item.key]
                                                        : []
                                                )
                                            );
                                            const dataDimensionIds = new Set(
                                                item.dimensions.flatMap((item) =>
                                                    item.kind === 'data' ? [item.key] : []
                                                )
                                            );

                                            const dashboardOverrides =
                                                savedConfigurationsByDashboard?.[
                                                    item.dashboard.id
                                                ] ?? null;

                                            let applied: MetricConfiguration = {
                                                ...item,
                                            };
                                            // if (dashboardOverrides?.competitive) {
                                            //     console.log(
                                            //         `overriding metric '${item.definition.id}' competitive set with preferences...`,
                                            //         dashboardOverrides.competitive
                                            //     );
                                            //     applied = {
                                            //         ...applied,
                                            //         competitiveSet: {
                                            //             ...dashboardOverrides.competitive,
                                            //             conditions:
                                            //                 dashboardOverrides.competitive.conditions
                                            //                     .filter((condition) =>
                                            //                         traitDimensionIds.has(
                                            //                             condition.key
                                            //                         )
                                            //                     )
                                            //                     .flatMap((item) => {
                                            //                         const type =
                                            //                             typeByKey[
                                            //                                 item.key
                                            //                             ];
                                            //                         const applied = type
                                            //                             ? applyTypeToCondition(
                                            //                                   item,
                                            //                                   type
                                            //                               )
                                            //                             : item;
                                            //                         return applied
                                            //                             ? [applied]
                                            //                             : [];
                                            //                     }),
                                            //         },
                                            //     };
                                            // }
                                            if (dashboardOverrides?.ranking) {
                                                console.log(
                                                    `overriding metric '${item.definition.id}' ranking with preferences...`,
                                                    dashboardOverrides.ranking
                                                );
                                                applied = {
                                                    ...applied,
                                                    ranking: dashboardOverrides.ranking,
                                                };
                                            }
                                            if (dashboardOverrides?.filters) {
                                                console.log(
                                                    `overriding metric '${item.definition.id}' filters with preferences...`,
                                                    dashboardOverrides.filters
                                                );

                                                applied = {
                                                    ...applied,
                                                    filters:
                                                        dashboardOverrides.filters.filter(
                                                            (item) =>
                                                                dataDimensionIds.has(
                                                                    item.key
                                                                )
                                                        ),
                                                };
                                            }

                                            return applied;
                                        });
                                    },
                                };
                            },
                        };
                    },
                },
            },
        });
        return instance;
    };
}

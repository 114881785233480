import React, { useState } from 'react';
import { PiMagicWand } from 'react-icons/pi';
import { useLocation } from 'react-router';
import { Icons } from '../../../../../config';
import { StudiesBaseContainerConfig } from '../../base';
import { SurveyDetailController, SurveyDetailLoader } from './surveyDetailInterface';
import { SurveyDetailStateValue } from './surveyDetailModel';
import { SurveyDetailContainerProps, SurveyDetailViewProps } from './surveyDetailProps';
import { SurveyDetailUrlParamSchema } from './surveyDetailSchema';

export function createSurveyDetailContainer(
    config: StudiesBaseContainerConfig,
    loader: SurveyDetailLoader,
    controller: SurveyDetailController,
    View: React.FC<SurveyDetailViewProps>
): React.FC<SurveyDetailContainerProps> {
    const {
        infra: {
            optionParser: { useOptions },
        },
        context: { useContext },
    } = config;

    const AiIcon = PiMagicWand;

    return ({ children, ...containerProps }) => {
        const [state, setState] = useState<SurveyDetailStateValue>({
            mode: 'interview',
        });
        const location = useLocation();
        const options = useOptions(SurveyDetailUrlParamSchema);
        const context = useContext();
        const data = loader.useLoad(context, options);
        const props = controller.useProps(context, data, {
            location,
            mode: {
                items: [
                    {
                        value: 'form',
                        label: 'Form',
                        description: `Veniam cillum cillum ex ex cillum proident ut eiusmod`,
                        isDisabled: false,
                        Icon: Icons.Reports.Submission,
                    },
                    {
                        value: 'interview',
                        label: 'AI Interview',
                        description: `In consectetur cillum enim commodo commodo`,
                        isDisabled: false,
                        Icon: Icons.Reports.Study,
                    },
                ],
                value: state.mode,
                onChange(value) {
                    setState({ mode: value });
                },
            },
        });
        return <View {...props}>{children}</View>;
    };
}

import React from 'react';
import { ButtonProps, Image, Text } from '@chakra-ui/react';
import { IntegrationCardButton } from '../button';
import { UserIntegrationDefinition } from '../../integrationType';
import connectSVG from '../../../../svg/connect.svg';
import connectWhiteSVG from '../../../../svg/connect-white.svg';
import { IntegrationTheme } from '../../integrationTheme';
import { isConnected } from '../../integrationHelper';

export interface IntegrationCardActionButtonProps {
    leftIcon: ButtonProps['leftIcon'];
    onClick: ButtonProps['onClick'];
    isLoading: boolean;
    children: React.ReactChild;
}

export interface IntegrationCardActionProps {
    theme: IntegrationTheme;
    definition: UserIntegrationDefinition;
    onConnect: ButtonProps['onClick'];
    onReconnect?: ButtonProps['onClick'];
    onConnectedClick?: ButtonProps['onClick'];
    isLoadingId?: number;
    isLoadingConnection?: boolean;
    isLoadingReconnection?: boolean;
    forceDisconnected?: boolean;
    renderButton(props: IntegrationCardActionButtonProps): React.ReactElement;
}

export const IntegrationCardAction: React.FunctionComponent<IntegrationCardActionProps> = ({ ...props }) => {
    const leftIcon = <Image src={props.theme.colorMode === 'light' ? connectSVG : connectWhiteSVG} />;
    const isLoading =
        (props.isLoadingReconnection || props.isLoadingConnection) && props.isLoadingId == props.definition?.id;

    // if (!props.forceDisconnected && isConnected(props.definition)) {
    //     return <IntegrationCardButton.Connected onClick={props.onConnectedClick}>Connected</IntegrationCardButton.Connected>;
    // }

    if (!props.forceDisconnected && props.definition.is_available && props.definition.reconnectedItems > 0) {
        return props.renderButton({
            onClick: props.onReconnect,
            isLoading: isLoading ?? false,
            leftIcon,
            children: 'Reconnect',
        });
    }

    if (props.definition.is_available) {
        return props.renderButton({
            onClick: props.onConnect,
            isLoading: isLoading ?? false,
            leftIcon,
            children: 'Connect',
        });
    }

    return <Text color="gray.600">In Private Preview</Text>;
};

import { EventBlock, MessageEntity } from '../../../../app/conversations';
import { assert } from '../../../../util/assert';
import { ConversationLoaderBaseConfig } from '../../base';
import { ConversationThreadLoader } from './conversationThreadInterface';

export function createConversationThreadLoader(
    config: ConversationLoaderBaseConfig
): ConversationThreadLoader {
    const {
        repository: {
            thread: threadRepository,
            message: messageRepository,
            agent: agentRepository,
        },
    } = config;
    return {
        useLoad(context, props) {
            const agentQuery = agentRepository.useLookup(context, props.agent, {
                suspense: true,
            });
            assert(agentQuery.status === 'success', 'expected suspense');
            const threadQuery = threadRepository.useLookup(context, props.thread, {
                suspense: true,
            });
            assert(threadQuery.status === 'success', 'expected suspense');
            const messageQuery = messageRepository.useFind(
                context,
                { thread: props.thread },
                {
                    suspense: true,
                    // select(data) {
                    //     const messages = [
                    //         ...agentQuery.data.prelude.map(
                    //             (item, index): MessageEntity => ({
                    //                 id: `prelude_${index}`,
                    //                 kind: item.kind,
                    //                 content: item.text,
                    //                 role: item.role,
                    //             })
                    //         ),
                    //         ...data,
                    //     ];
                    //     return messages;
                    // },
                }
            );
            assert(messageQuery.status === 'success', 'expected suspense');
            return {
                agent: agentQuery.data,
                thread: threadQuery.data,
                entries: messageQuery.data.map((message, index, array) => ({
                    message,
                    result:
                        array[index - 1]?.blocks.find(
                            (item): item is EventBlock => item.kind === 'event'
                        ) ?? null,
                })),
                mostRecentMessage: messageQuery.data[0] ?? null,
                chat: {
                    messages: messageQuery.data.map((item) => ({
                        text: item.id,
                        // text:
                        //     item.kind === 'text'
                        //         ? item.content
                        //         : JSON.stringify(item.data),
                    })),
                },
            };
        },
    };
}

import React from 'react';
import {
    OnboardingStrategyOrganizationController,
    OnboardingStrategyAssetController,
    OnboardingStrategyUserController,
} from './strategyInterface';
import {
    OnboardingOrganizationStrategyControllerProps,
    OnboardingAssetStrategyControllerProps,
    OnboardingOrganizationStrategyViewProps,
    OnboardingAssetStrategyViewProps,
    OnboardingUserStrategyViewProps,
    OnboardingUserStrategyControllerProps,
} from './strategyProps';

export function createOnboardingUserStrategyContainer(
    // controller: OnboardingStrategyUserController,
    View: React.FC<OnboardingUserStrategyViewProps>
): React.FC<OnboardingUserStrategyControllerProps> {
    return (props) => {
        // const viewProps = controller.useProps(props);
        return <View />;
    };
}

export function createOnboardingOrganizationStrategyContainer(
    controller: OnboardingStrategyOrganizationController,
    View: React.FC<OnboardingOrganizationStrategyViewProps>
): React.FC<OnboardingOrganizationStrategyControllerProps> {
    return (props) => {
        const viewProps = controller.useProps(props);
        return <View {...viewProps} />;
    };
}

export function createOnboardingAssetStrategyContainer(
    controller: OnboardingStrategyAssetController,
    View: React.FC<OnboardingAssetStrategyViewProps>
): React.FC<OnboardingAssetStrategyControllerProps> {
    return (props) => {
        const viewProps = controller.useProps(props);
        return <View {...viewProps} />;
    };
}

import React from 'react';
import { GoogleReCaptchaProvider, useGoogleReCaptcha, GoogleReCaptcha } from 'react-google-recaptcha-v3';
import { ErrorInfo } from '../../models/Errors';
import { useForm } from 'react-hook-form';
import Input from '../FormControls/Input';
import {
    Button,
    ButtonProps,
    Container,
    Spinner,
    Text,
    VStack,
    Heading,
    SimpleGrid,
    GridItem,
    Link,
    Center,
} from '@chakra-ui/react';
import { Link as RRDLink } from 'react-router-dom';

interface RecaptchaButtonProps extends ButtonProps {
    onVerifyCaptcha: (token: string) => void;
}
export interface RequestForgotPasswordProps {
    title: string;
    actionName: string;
    isLoading: boolean;
    err: ErrorInfo | null;
    doReqForgotPwd: (email: string, captchaToken: string) => void;
    requested: boolean;
}

const CaptchaButton: React.FC<RecaptchaButtonProps> = ({ onVerifyCaptcha, children, ...props }) => {
    const { executeRecaptcha } = useGoogleReCaptcha();
    const clickHandler = async () => {
        if (!executeRecaptcha) {
            return;
        }

        const token = await executeRecaptcha('changePassword');

        onVerifyCaptcha(token);
    };

    return (
        // <GoogleReCaptcha
        <Button onClick={clickHandler} {...props}>
            {children}
        </Button>
    );
};

export const ReCaptcha: React.FC<RecaptchaButtonProps> = ({ onVerifyCaptcha, ...props }) => (
    <GoogleReCaptchaProvider
        reCaptchaKey="6LdOzRAcAAAAADf2t0FStSFkA2WcnZi6tvH2nYbp"
        scriptProps={{
            async: false, // optional, default to false,
            defer: false, // optional, default to false
            appendTo: 'head', // optional, default to "head", can be "head" or "body",
            nonce: undefined, // optional, default undefined
        }}
    >
        <CaptchaButton onVerifyCaptcha={onVerifyCaptcha} {...props} />
    </GoogleReCaptchaProvider>
);

const WrappedRequestPasswordForm: React.FunctionComponent<RequestForgotPasswordProps> = ({
    isLoading,
    err,
    doReqForgotPwd,
    requested,
    title,
    actionName
}) => {
    const { handleSubmit, register, setValue, formState } = useForm({ mode: 'onBlur', reValidateMode: 'onBlur' });
    // React.useEffect(() => {
    //     register({ name: 'captchaToken' }, { required: true });
    // }, []);
    const { executeRecaptcha } = useGoogleReCaptcha();

    // const onVerifyCaptcha = (token: string | null) => {
    //     setValue('captchaToken', token);
    // }
    const onSubmit = async (data: any) => {
        if (!executeRecaptcha) {
            return;
        }

        const token = await executeRecaptcha('changePassword');

        doReqForgotPwd(data.email, token);
    };

    return (
        <Container centerContent maxW="md">
            <VStack
                w="full"
                h="full"
                p={10}
                align="stretch"
                spacing={8}
                border="1px"
                borderColor="frame"
                borderRadius={10}
                bg="box"
            >
                <VStack>
                    <Heading size="lg" textAlign="center" color="onboarding.title">
                        {title}
                    </Heading>
                    <Text fontSize="lg" color="onboarding.subtitle">
                        Enter your email below
                    </Text>
                </VStack>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <SimpleGrid columns={1} rowGap={6} w="full" h="full">
                        <GridItem colSpan={1}>
                            <Input
                                bg="button.white"
                                placeholder="Email"
                                {...register('email', {
                                    required: 'This field is required!',
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                        message: 'Invalid email address',
                                    },
                                })}
                                isRequired
                                err={formState.errors.email && formState.errors.email.message}
                                label="Email Address"
                                type="text"
                            />
                        </GridItem>

                        {(err || formState.errors.captchaToken) && (
                            <GridItem colSpan={1}>
                                {formState.errors.captchaToken && (
                                    <Text color="error">{formState.errors.captchaToken.message}</Text>
                                )}
                                {err && (
                                    <Center>
                                        <Text textAlign="center" {...{ variant: 'caption', color: 'error' }}>
                                            {err.errType == 'unauthorized'
                                                ? 'Cannot trigger set password request'
                                                : err.message || err.errType}
                                        </Text>
                                    </Center>
                                )}
                            </GridItem>
                        )}
                        {isLoading && <Spinner />}
                        <Button
                            isLoading={isLoading}
                            w="full"
                            textColor="button.white"
                            boxShadow="lg"
                            bg="button.green"
                            type="submit"
                            _hover={{ bg: 'button.hoverGreen' }}
                            isDisabled={isLoading}
                        >
                            Send Email
                        </Button>
                        <GridItem colSpan={1}>
                            <Center>
                                <Link pl={2} as={RRDLink} to={'../login'}>
                                    Back to Log in
                                </Link>
                            </Center>
                        </GridItem>
                    </SimpleGrid>
                </form>
                {requested && (
                    <Center>
                        <Text textAlign="center" fontSize="lg">
                        {/* change password */}
                            Link to {actionName} has been sent to your email.
                        </Text>
                    </Center>
                )}
            </VStack>
        </Container>
    );
};

export const RequestPasswordForm: React.FC<RequestForgotPasswordProps> = (props) => {
    return (
        <GoogleReCaptchaProvider
            reCaptchaKey="6LdOzRAcAAAAADf2t0FStSFkA2WcnZi6tvH2nYbp"
            scriptProps={{
                async: false, // optional, default to false,
                defer: false, // optional, default to false
                appendTo: 'head', // optional, default to "head", can be "head" or "body",
                nonce: undefined, // optional, default undefined
            }}
        >
            <WrappedRequestPasswordForm {...props} />
        </GoogleReCaptchaProvider>
    );
};

import { TooltipProps } from '@chakra-ui/react';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useStopWatch } from '../../../vendor/use-stop-watch';
import { ApplicationEntryEnhancer } from '../../../entrypoint';
import { AnyTooltipEvent } from './tooltipTrackingEvent';
import { TooltipTrackingConfig } from './tooltipTrackingConfig';
import { createLooltipTrackingController } from './tooltipTrackingController';

/**
 * Tracks tooltip hovering with a configurable min open time
 * @returns
 */
export function createTooltipTrackingStrategy(
    init: TooltipTrackingConfig
): ApplicationEntryEnhancer {
    return (create) => (config) => {
        function useTracker() {
            const tracker = instance.infra.useTracker<AnyTooltipEvent>();
            return tracker;
        }

        const controller = createLooltipTrackingController({
            ...init,
            useTracker,
        });

        function enhanceTooltip(
            Component: React.FC<TooltipProps>
        ): React.FC<TooltipProps> {
            return (props) => {
                const overridenProps = controller.useProps(props);
                if (!props.id) {
                    return <Component {...props} />;
                }
                return <Component {...overridenProps} />;
            };
        }

        const instance = create({
            ...config,
            ui: {
                ...config.ui,
                createSettingsUI(...args) {
                    const UI = config.ui.createSettingsUI(...args);
                    return {
                        ...UI,
                        Tooltip: enhanceTooltip(UI.Tooltip),
                    };
                },
                createWorkspaceUI(...args) {
                    const UI = config.ui.createWorkspaceUI(...args);
                    return {
                        ...UI,
                        Tooltip: enhanceTooltip(UI.Tooltip),
                    };
                },
            },
        });
        return instance;
    };
}

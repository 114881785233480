import { ChevronRightIcon } from '@chakra-ui/icons';
import { Box, HStack, Icon, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import { Icons } from '../../../../../config';
import { ConversationAgentConfig } from '../../../../route';
import { AgentInterviewViewProps } from './agentInterviewProps';

export function createAgentInverviewView(
    config: ConversationAgentConfig
): React.FC<AgentInterviewViewProps> {
    const {
        Layout,
        UI: {
            Application: { Link },
        },
    } = config;
    return (props) => {
        return (
            <VStack align="start" w="full" spacing={4}>
                <VStack align="start" w="full" spacing={3}>
                    <VStack align="start" spacing={3} w="full">
                        <HStack
                            fontWeight="semibold"
                            spacing={2}
                            divider={
                                <Icon
                                    boxSize="1.20rem"
                                    color="whiteAlpha.600"
                                    as={ChevronRightIcon}
                                    border="none"
                                />
                            }
                        >
                            <Link to="..">
                                <Text color="blue.300" _hover={{ color: 'blue.400' }}>
                                    Reports
                                </Text>
                            </Link>
                            <Link to="..">
                                <Text color="blue.300" _hover={{ color: 'blue.400' }}>
                                    Surveys
                                </Text>
                            </Link>
                        </HStack>
                        <HStack w="full" justify="space-between">
                            <HStack spacing={4} fontSize="lg">
                                <Box p={3} bg="whiteAlpha.300" borderRadius="sm">
                                    <Icon as={Icons.Reports.Study} />
                                </Box>
                                <VStack align="start" w="full">
                                    <Text fontWeight="semibold" color="whiteAlpha.800">
                                        {props.dataset.label}
                                    </Text>
                                </VStack>
                            </HStack>
                        </HStack>
                    </VStack>
                    {props.dataset.description && (
                        <Text color="whiteAlpha.600">{props.dataset.description}</Text>
                    )}
                </VStack>
            </VStack>
        );
    };
}

import { PageIds } from '../../../../config';
import { RegistrationControllerConfig } from '../../base';
import { RegistrationAcceptOrganizationController } from './acceptOrganizationInterface';
import { AcceptOrganizationViewProps } from './acceptOrganizationProps';

export function createRegistrationAcceptOrganizationController(
    config: RegistrationControllerConfig
): RegistrationAcceptOrganizationController {
    const {
        provider: { createPageController, createFormController },
        controller: {
            step: { useProps: useLayoutProps },
        },
    } = config;

    const { useProps: usePageProps } = createPageController({
        id: PageIds.REGISTRATION_ACCEPT_ORGANIZATION,
        title: 'Organization',
        description: `Provide your organization's details to complete the setup process`,
        provider: config.provider,
    });

    const { useProps: useFormProps } = createFormController({
        submitOnEnter: true,
    });

    return {
        useProps(props): AcceptOrganizationViewProps {
            const page = usePageProps({}, {});
            const form = useFormProps(props.form);
            return {
                form,
                layout: useLayoutProps(
                    {
                        id: page.id,
                        name: page.header.title,
                        description: page.header.description,
                        submitText: 'Continue',
                    },
                    {
                        ...props.layout,
                        form,
                    }
                ),
            };
        },
    };
}

import React, { forwardRef, Suspense } from 'react';
import { CalendarIcon, ChevronDownIcon } from '@chakra-ui/icons';
import {
    Center,
    VStack,
    Box,
    Button,
    FormLabel,
    InputGroup,
    Image,
    ButtonGroup,
    Tooltip,
    TabProps,
    Wrap,
    WrapItem,
    Text,
    Stack,
} from '@chakra-ui/react';
import { CardSpinner } from '../../../domain';
import { ErrorBoundary } from '../../../infra';
import { DemoLayout as Layout } from '../../layout';
import { TypeInputView } from '../../view/demo';
import { Radio } from '../../ui/application/impl/demo';
import { DemoRouteControllerReturn } from './demoRouteInterface';
import { DemoRouteConfig } from './demoRouteConfig';

export interface DemoRouteProps extends DemoRouteControllerReturn {}

export function createDemoRouteComponent({
    container: { ChartContainer },
    ...config
}: Pick<
    DemoRouteConfig,
    'style' | 'intl' | 'container' | 'infra'
>): React.FC<DemoRouteControllerReturn> {
    return ({ children, ...controller }) => {
        const {
            fields,
            dashboards: { current: dashboard, items: dashboards },
        } = controller;
        return (
            <Layout.Wrapper style={config.style.layout}>
                {/* <Layout.Banner
                    text={`Companies Actively Sharing Data with Varos: ${
                        controller.getDatasetStatusProps().assetCount
                    }`}
                /> */}
                <Layout.Header
                    title={config.intl.header.title}
                    subtitle={config.intl.header.subtitle}
                />
                <Layout.Body>{children}</Layout.Body>
                <Layout.Platforms>
                    <InputGroup flexDirection="column">
                        <FormLabel {...config.style.ui?.label}>Platform</FormLabel>
                        <Radio.Group
                            {...controller.getPlatformListProps({
                                style: config.style.ui?.button?.list,
                                ...fields.getPlatform(),
                            })}
                        >
                            {dashboards.map((dashboard, index) => (
                                <Radio.Button
                                    key={dashboard.id}
                                    {...controller.getPlatformItemProps(dashboard, {
                                        style: config.style.ui?.button?.item,
                                        value: dashboard.id,
                                        leftIcon: (
                                            <Image w={5} h={5} src={dashboard.icon} />
                                        ),
                                    })}
                                >
                                    {dashboard.name}
                                </Radio.Button>
                            ))}
                        </Radio.Group>
                    </InputGroup>
                </Layout.Platforms>
                <Layout.Filters>
                    <Wrap spacing={config.style.layout?.control.spacing}>
                        {dashboard.properties.map((item) => {
                            const { key, ...inputProps } = fields.getFilter(
                                dashboard,
                                item
                            );
                            return (
                                <WrapItem key={key}>
                                    <TypeInputView
                                        style={{
                                            wrapper: {
                                                minW: 48,
                                            },
                                            label: config.style.ui?.label,
                                        }}
                                        item={item}
                                        {...inputProps}
                                    />
                                </WrapItem>
                            );
                        })}
                    </Wrap>
                </Layout.Filters>
                <Layout.Metrics>
                    <Stack
                        direction="row"
                        alignItems="center"
                        spacing={config.style.layout?.control.spacing}
                    >
                        <Text {...config.style.ui?.label}>Metric</Text>
                        <ButtonGroup isAttached={true}>
                            {dashboard.metrics.map((metric, index) => {
                                const { tooltip, ...buttonProps } =
                                    controller.getMetricProps(dashboard, metric, index);
                                let Wrapper: React.FC = ({ children }) => (
                                    <Tooltip
                                        placement="top"
                                        label={tooltip}
                                        isDisabled={!tooltip}
                                    >
                                        <span>{children}</span>
                                    </Tooltip>
                                );
                                return (
                                    <Wrapper key={metric.id}>
                                        <Button
                                            key={metric.id}
                                            variant="outline"
                                            border="none"
                                            borderRadius={0}
                                            borderBottomWidth={2}
                                            borderStyle="solid"
                                            aria-selected={index === 0}
                                            {...config.style.ui?.tab}
                                            {...buttonProps}
                                        >
                                            {metric.name}
                                        </Button>
                                    </Wrapper>
                                );
                            })}
                        </ButtonGroup>
                    </Stack>
                </Layout.Metrics>
                <Layout.Time>
                    <Tooltip {...controller.getDateFilterProps(dashboard).tooltipProps}>
                        <span>
                            <Button
                                {...controller.getDateFilterProps(dashboard)}
                                size="sm"
                                variant="ghost"
                                leftIcon={
                                    <CalendarIcon
                                        color={config.style.theme?.accentColor}
                                    />
                                }
                                rightIcon={<ChevronDownIcon />}
                            >
                                Last 4 weeks
                            </Button>
                        </span>
                    </Tooltip>
                </Layout.Time>
                <Layout.Visualization>
                    <ErrorBoundary
                        key={controller.getErrorBoundaryKey()}
                        fallback={(props) => {
                            const info = config.infra.errorFormatter.format(props.error);
                            return (
                                <Layout.ErrorState
                                    error={info}
                                    style={config.style.layout?.errorState}
                                />
                            );
                        }}
                    >
                        <Suspense fallback={<CardSpinner />}>
                            <ChartContainer {...controller.getChartProps()} />
                        </Suspense>
                    </ErrorBoundary>
                </Layout.Visualization>
                <Layout.Footer>
                    <Center>
                        <VStack spacing={config.style.layout?.control.spacing}>
                            <Text {...config.style.layout?.text?.secondary}>
                                {config.intl.cta.title}
                            </Text>
                            <Button
                                {...config.style.layout?.button?.cta}
                                {...controller.getCtaProps()}
                            >
                                {config.intl.cta.button}
                            </Button>
                        </VStack>
                    </Center>
                </Layout.Footer>
            </Layout.Wrapper>
        );
    };
}

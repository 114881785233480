import { CalendarIcon, CheckIcon, ChevronDownIcon } from '@chakra-ui/icons';
import { AiOutlineBulb } from 'react-icons/ai';
import {
    VStack,
    Box,
    HStack,
    Image,
    StackDivider,
    Text,
    Heading,
    Select,
    Button,
    ButtonGroup,
    Icon,
    Tooltip,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    Tag,
    Grid,
    GridItem,
} from '@chakra-ui/react';
import React from 'react';
import ReactDatePicker from 'react-datepicker';
import { CardSpinner } from '../../../../domain';
import {
    isEmptyCollection,
    isLoadedCollection,
    isLoadingCollection,
} from '../../../base';
import { InsightRouteConfig } from './insightRouteConfig';
import { InsightRouteViewProps } from './insightRouteProps';
import { Link } from 'react-router-dom';

export function createInsightRouteView(
    config: InsightRouteConfig
): React.FC<InsightRouteViewProps> {
    const {
        deps: {},
    } = config;

    return (props) => {
        const opportunityProps = props.getOpportunityProps();
        const dateProps = props.getDateProps();
        const statusProps = opportunityProps.getStatusProps();
        return (
            <Grid
                // templateRows="1fr min-content"
                templateRows="1fr"
                h="full"
            >
                <GridItem>
                    <VStack position="relative">
                        <VStack
                            px={4}
                            py={8}
                            w="full"
                            maxW={{ base: '66vw', xl: '50vw' }}
                            marginX="auto"
                            divider={
                                <StackDivider
                                    borderColor="whiteAlpha.300"
                                    borderWidth={1}
                                />
                            }
                            spacing={4}
                            pb={48}
                        >
                            <HStack justify="start" w="full">
                                <HStack flex={1} spacing={3} align="center">
                                    <Heading fontWeight="semibold" fontSize="2xl">
                                        Opportunities
                                    </Heading>
                                    <Tag
                                        fontWeight="semibold"
                                        color="blackAlpha.900"
                                        bg="#c6e74f"
                                        letterSpacing="wide"
                                    >
                                        Beta
                                    </Tag>
                                </HStack>
                                <HStack>
                                    <ButtonGroup isAttached={true}>
                                        <Menu>
                                            <MenuButton
                                                flexShrink={0}
                                                borderRight="1px solid"
                                                borderColor="blackAlpha.500"
                                                borderRightRadius="none"
                                                as={Button}
                                                _selected={{
                                                    bg: 'green.500',
                                                    white: 'white',
                                                }}
                                                // minW={32}
                                                size="sm"
                                                bg="#292929"
                                                _focus={{ outline: 'none' }}
                                                rightIcon={
                                                    <Icon
                                                        fontSize="lg"
                                                        w="1rem"
                                                        as={ChevronDownIcon}
                                                    />
                                                }
                                            >
                                                {dateProps.getPeriodLabel()}
                                            </MenuButton>
                                            <MenuList bg="#292929">
                                                {dateProps.getOptions().map((item) => {
                                                    const itemProps =
                                                        dateProps.getPeriodOptionProps(
                                                            item
                                                        );
                                                    return (
                                                        <MenuItem
                                                            aria-selected={
                                                                itemProps.isSelected
                                                            }
                                                            _selected={{
                                                                color: 'green.500',
                                                            }}
                                                            icon={
                                                                <Icon
                                                                    as={CheckIcon}
                                                                    visibility={
                                                                        itemProps.isSelected
                                                                            ? 'inherit'
                                                                            : 'hidden'
                                                                    }
                                                                />
                                                            }
                                                            key={itemProps.label}
                                                            onClick={itemProps.onClick}
                                                            fontWeight="medium"
                                                        >
                                                            {itemProps.label}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </MenuList>
                                        </Menu>
                                        <ReactDatePicker
                                            calendarClassName="dark"
                                            customInput={
                                                <Button
                                                    borderLeftRadius="none"
                                                    w="full"
                                                    leftIcon={<Icon as={CalendarIcon} />}
                                                    // minW={42}
                                                    size="sm"
                                                    bg="#292929"
                                                    _focus={{ outline: 'none' }}
                                                >
                                                    {dateProps.getAbsoluteLabel(
                                                        dateProps.value,
                                                        true
                                                    )}
                                                </Button>
                                            }
                                            {...dateProps.getRangeProps()}
                                            selectsRange={true}
                                        />
                                    </ButtonGroup>
                                </HStack>
                            </HStack>
                            <VStack
                                align="start"
                                w="full"
                                // spacing={8}
                                spacing={4}
                            >
                                <HStack
                                    w="full"
                                    // align="end"
                                    // borderBottom={2}
                                    // borderColor="whiteAlpha.300"
                                    // borderStyle="solid"
                                    // pb={4}
                                >
                                    <HStack flex={1}>
                                        <Text
                                            fontWeight="medium"
                                            color="whiteAlpha.700"
                                            letterSpacing="wide"
                                            // fontSize="sm"
                                            // textTransform="uppercase"
                                        >
                                            {opportunityProps.getCollectionLabel()}
                                        </Text>
                                    </HStack>
                                    <HStack>
                                        <Select size="md" {...props.getSortingProps()}>
                                            <option value="relevance">Relevance</option>
                                            <option value="recent">Most recent</option>
                                        </Select>
                                    </HStack>
                                </HStack>
                                <VStack w="full">
                                    {isEmptyCollection(opportunityProps.collection) && (
                                        <HStack
                                            p={4}
                                            border="2px solid"
                                            borderColor="whiteAlpha.300"
                                            borderRadius="md"
                                            w="full"
                                        >
                                            <VStack p={4} w="full" spacing={4}>
                                                <Box
                                                    bg="whiteAlpha.200"
                                                    p={4}
                                                    borderRadius="md"
                                                >
                                                    <Icon
                                                        color="whiteAlpha.700"
                                                        fontSize="3xl"
                                                        as={AiOutlineBulb}
                                                    ></Icon>
                                                </Box>
                                                <VStack spacing={2}>
                                                    <Heading
                                                        fontSize="lg"
                                                        fontWeight="medium"
                                                        color="whiteAlpha.700"
                                                    >
                                                        No opportunities found for period
                                                    </Heading>
                                                    <Text
                                                        fontSize="md"
                                                        fontWeight="medium"
                                                        color="whiteAlpha.500"
                                                    >
                                                        {dateProps.getAbsoluteLabel(
                                                            dateProps.getAbsoluteRange(
                                                                dateProps.value
                                                            )
                                                        )}
                                                    </Text>
                                                </VStack>
                                            </VStack>
                                        </HStack>
                                    )}
                                    {isLoadingCollection(opportunityProps.collection) ? (
                                        <CardSpinner />
                                    ) : (
                                        <VStack align="start" spacing={4} w="full">
                                            {opportunityProps.collection.data?.items?.map(
                                                (item) => {
                                                    const dateProps =
                                                        opportunityProps.getDateProps(
                                                            item
                                                        );
                                                    const routeProps =
                                                        opportunityProps.getRouteProps(
                                                            item
                                                        );
                                                    const actionProps =
                                                        opportunityProps.getActionProps(
                                                            item
                                                        );
                                                    return (
                                                        <VStack
                                                            key={item.insight.id}
                                                            // px={4}
                                                            p={6}
                                                            align="start"
                                                            border="2px solid"
                                                            borderColor="whiteAlpha.300"
                                                            borderRadius="md"
                                                            w="full"
                                                            spacing={2}
                                                            fontWeight="medium"
                                                        >
                                                            <HStack spacing={3} w="full">
                                                                <Box
                                                                    p={2.5}
                                                                    bg="whiteAlpha.300"
                                                                    borderRadius="md"
                                                                    flexShrink={0}
                                                                >
                                                                    <Image
                                                                        w={5}
                                                                        h={5}
                                                                        src={
                                                                            item.plugin
                                                                                .iconUrl ??
                                                                            ''
                                                                        }
                                                                    />
                                                                </Box>
                                                                <HStack
                                                                    justify="space-between"
                                                                    w="full"
                                                                    align="start"
                                                                >
                                                                    <Box flex={1}>
                                                                        <Text color="whiteAlpha.900">
                                                                            {
                                                                                item
                                                                                    .insight
                                                                                    .title
                                                                            }
                                                                        </Text>
                                                                    </Box>
                                                                </HStack>
                                                            </HStack>
                                                            <VStack
                                                                align="start"
                                                                w="full"
                                                                spacing={2}
                                                            >
                                                                <Box color="whiteAlpha.700">
                                                                    {item.insight.body}
                                                                </Box>
                                                                <HStack
                                                                    w="full"
                                                                    justify="space-between"
                                                                >
                                                                    <HStack
                                                                        spacing={2}
                                                                        color="whiteAlpha.500"
                                                                        divider={
                                                                            <Box border="none">
                                                                                &#x2022;
                                                                            </Box>
                                                                        }
                                                                    >
                                                                        <Tooltip
                                                                            placement="top"
                                                                            hasArrow={
                                                                                true
                                                                            }
                                                                            label={
                                                                                <Box
                                                                                    p={2}
                                                                                >
                                                                                    {
                                                                                        dateProps.description
                                                                                    }
                                                                                </Box>
                                                                            }
                                                                        >
                                                                            <Box>
                                                                                {
                                                                                    dateProps.label
                                                                                }
                                                                            </Box>
                                                                        </Tooltip>
                                                                        {actionProps.map(
                                                                            (action) => (
                                                                                <Box
                                                                                    key={
                                                                                        action.key
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        action.label
                                                                                    }
                                                                                </Box>
                                                                            )
                                                                        )}
                                                                    </HStack>

                                                                    <Link
                                                                        to={
                                                                            routeProps.path ??
                                                                            ''
                                                                        }
                                                                        onClick={
                                                                            routeProps.onClick
                                                                        }
                                                                    >
                                                                        <Text
                                                                            fontWeight="semibold"
                                                                            color="blue.400"
                                                                            _hover={{
                                                                                textDecoration:
                                                                                    'underline',
                                                                            }}
                                                                        >
                                                                            {
                                                                                routeProps.label
                                                                            }
                                                                        </Text>
                                                                    </Link>
                                                                </HStack>
                                                            </VStack>
                                                        </VStack>
                                                    );
                                                }
                                            )}
                                            {isLoadedCollection(
                                                opportunityProps.collection
                                            ) && statusProps ? (
                                                <VStack
                                                    p={6}
                                                    align="start"
                                                    border="2px solid"
                                                    borderColor="whiteAlpha.300"
                                                    borderRadius="md"
                                                    w="full"
                                                    spacing={2}
                                                    fontWeight="medium"
                                                >
                                                    <HStack w="full">
                                                        <HStack
                                                            w="full"
                                                            justify="space-between"
                                                        >
                                                            <HStack w="full" spacing={4}>
                                                                {statusProps.Icon && (
                                                                    <Box
                                                                        bg="whiteAlpha.300"
                                                                        p={2.5}
                                                                        borderRadius="md"
                                                                    >
                                                                        <Icon
                                                                            fontSize="lg"
                                                                            color="whiteAlpha.700"
                                                                            as={
                                                                                statusProps.Icon
                                                                            }
                                                                        />
                                                                    </Box>
                                                                )}
                                                                {statusProps.description && (
                                                                    <Text color="whiteAlpha.700">
                                                                        {
                                                                            statusProps.description
                                                                        }
                                                                    </Text>
                                                                )}
                                                                <Heading
                                                                    fontSize="lg"
                                                                    color="whiteAlpha.700"
                                                                    fontWeight="medium"
                                                                >
                                                                    {statusProps.title}
                                                                </Heading>
                                                            </HStack>
                                                            {statusProps.button && (
                                                                <Button
                                                                    colorScheme="green"
                                                                    variant="outline"
                                                                    {...statusProps.button}
                                                                />
                                                            )}
                                                        </HStack>
                                                    </HStack>
                                                </VStack>
                                            ) : null}
                                        </VStack>
                                    )}
                                </VStack>
                            </VStack>
                        </VStack>
                    </VStack>
                </GridItem>
                {/* <GridItem position="relative">
                    <HStack
                        justify="start"
                        w="full"
                        position="fixed"
                        bottom={0}
                        left={0}
                        p={4}
                    >
                        <Box
                            px={8}
                            py={4}
                            bg="blackAlpha.900"
                            zIndex={10}
                            borderRadius="full"
                        >
                            <Text color="whiteAlpha.700" fontWeight="medium">
                                Have a suggestion?{' '}
                                <Box
                                    as="span"
                                    textDecoration="underline"
                                    cursor="pointer"
                                    onClick={() => {
                                        // HACK this should be moved into feedback middleware
                                        // @ts-expect-error
                                        if (window.Intercom) {
                                            // @ts-expect-error
                                            window.Intercom('startSurvey', 38394946);
                                        } else {
                                            console.warn('intercom not loaded');
                                        }
                                    }}
                                >
                                    Let us know
                                </Box>
                            </Text>
                        </Box>
                    </HStack>
                </GridItem> */}
            </Grid>
        );
    };
}

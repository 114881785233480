import React from 'react';
import { ReportListRouteConfig } from './reportListConfig';
import { createReportListRouteView } from './reportListView';

export function createReportListRoute(config: ReportListRouteConfig): React.FC {
    const { hook, controller, loader } = config;
    const Component = createReportListRouteView(config);
    return () => {
        const auth = hook.useAuth();
        const organization = hook.useOrganization();
        const context = {
            auth,
            organization,
        };
        const viewData = loader.report.useLoad(context, {});
        const viewProps = controller.report.useProps(context, viewData, {});
        return <Component {...viewProps} />;
    };
}

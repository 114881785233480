import { Button, ButtonGroup, HStack, Icon, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import { AiOutlineEdit } from 'react-icons/ai';
import { HiOutlineCube } from 'react-icons/hi';
import { ConversationPluginConfig } from '../../../../route';
import { ConversationEntityViewProps } from './blockEntityProps';

export function createConversationEntityView(
    config: ConversationPluginConfig
): React.FC<ConversationEntityViewProps> {
    const {
        Layout: { Container, Content },
    } = config;

    const EntityIcon = HiOutlineCube;

    return (props) => {
        return (
            <HStack
                px={5}
                py={3}
                spacing={3}
                color="whiteAlpha.800"
                borderColor="whiteAlpha.300"
                borderWidth={2}
                cursor="pointer"
                borderRadius="md"
                align="center"
                _hover={{ textDecor: 'underline' }}
            >
                <Icon as={EntityIcon} />
                <Text>{props.label}</Text>
            </HStack>
        );
    };
}

import React, { Suspense, useState } from 'react';
import { VStack, Switch, Box, Heading } from '@chakra-ui/react';
import { SettingsIcon } from '@chakra-ui/icons';
import { WorkspaceListConfig } from './workspaceListConfig';
import { WorkspaceListViewProps } from './workspaceListProps';

export function createWorkspaceListView(
    config: WorkspaceListConfig
): React.FC<WorkspaceListViewProps> {
    const {
        UI: { Table, Tbody, Thead, Tr, Td, Th, Tag, Button, Text },
        Layout,
        Container: { EditForm },
    } = config;
    return (props) => {
        return (
            <VStack alignItems="flex-start" spacing={4} w="full">
                <VStack alignItems="flex-start" spacing={2}>
                    <Heading size="lg">Permissions</Heading>
                    <Text color="whiteAlpha.700">Manage access to users</Text>
                </VStack>
                <Table>
                    <Thead>
                        <Tr>
                            <Th>Brand</Th>
                            <Th>Permissioned Users</Th>
                            <Th>Settings</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {props.workspaces.data.map((workspace, index) => {
                            return (
                                <Tr key={index}>
                                    <Td whiteSpace="nowrap">{workspace.name}</Td>
                                    <Td
                                        onClick={() => props.onEditWorkspace(workspace)}
                                        cursor="pointer"
                                    >
                                        <Tag.List maxItems={5}>
                                            {workspace.users.displayUsers
                                                .map((user) => {
                                                    if (typeof user !== 'object') {
                                                        throw new Error(
                                                            'expected asset to have been expanded'
                                                        );
                                                    }
                                                    return user;
                                                })
                                                .map((user) => (
                                                    <Tag.Item
                                                        key={user.email}
                                                        colorScheme="green"
                                                        variant="subtle"
                                                    >
                                                        {user.first_name
                                                            ? [
                                                                  user.first_name,
                                                                  user.last_name,
                                                              ]
                                                                  .filter(Boolean)
                                                                  .join(' ')
                                                            : user.email}
                                                    </Tag.Item>
                                                ))}
                                            {workspace.users.countLeft > 0 && (
                                                <Tag.Item
                                                    colorScheme="green"
                                                    variant="subtle"
                                                >
                                                    {' '}
                                                    And {workspace.users.countLeft} others
                                                </Tag.Item>
                                            )}
                                        </Tag.List>
                                    </Td>
                                    <Td>
                                        <SettingsIcon
                                            cursor="pointer"
                                            onClick={() =>
                                                props.onEditWorkspace(workspace)
                                            }
                                        />
                                    </Td>
                                </Tr>
                            );
                        })}
                    </Tbody>
                </Table>
                <Suspense
                    fallback={
                        // temp hack that wraps in suspense to prevent bubbling loading states from inside modal content
                        <></>
                    }
                >
                    {props.editProps && (
                        <EditForm
                            key={props.editProps.workspace.id}
                            {...props.editProps}
                        />
                    )}
                </Suspense>
            </VStack>
        );
    };
}

import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import moment from 'moment';
import { ApiErrorResponseSchema } from '../../api';
import { ApiError } from '../../base';

export function createAxios(config: AxiosRequestConfig<any>) {
    const instance = axios.create({
        ...config,
    });

    const serializeDates = (obj: any): any => {
        if (obj instanceof Date) {
            // Check if the Date has time components
            if (
                obj.getHours() === 0 &&
                obj.getMinutes() === 0 &&
                obj.getSeconds() === 0 &&
                obj.getMilliseconds() === 0
            ) {
                // Date has no time component, format as 'YYYY-MM-DD'
                return moment(obj).format('YYYY-MM-DD');
            } else {
                // Date has time component, format including time
                return moment(obj).format('YYYY-MM-DDTHH:mm:ss.SSSZ');
            }
        } else if (Array.isArray(obj)) {
            return obj.map((item) => serializeDates(item));
        } else if (obj !== null && typeof obj === 'object') {
            const newObj: any = {};
            for (const key in obj) {
                if (obj.hasOwnProperty(key)) {
                    newObj[key] = serializeDates(obj[key]);
                }
            }
            return newObj;
        } else {
            return obj;
        }
    };

    instance.interceptors.response.use(
        (response) => {
            return response;
        },
        (error: AxiosError) => {
            const parsed = ApiErrorResponseSchema.safeParse(error.response?.data ?? {});
            if (parsed.success && error.response?.status === 403) {
                const error = new ApiError(
                    `You do not have permission to perform this action`
                );
                throw error;
            }
            if (parsed.success) {
                const error = new ApiError(parsed.data.detail);
                throw error;
            }
            throw new ApiError(error.message);
        }
    );

    instance.interceptors.request.use((config: AxiosRequestConfig) => {
        if (config.params) {
            config.params = serializeDates(config.params);
        }

        // Serialize dates in config.data (request body)
        if (config.data) {
            config.data = serializeDates(config.data);
        }

        return config;
    });

    return instance;
}

import React from 'react';
import {
    Slider,
    SliderTrack,
    SliderFilledTrack,
    SliderThumb,
    SliderProps,
} from '@chakra-ui/react';
import { Box } from '@chakra-ui/react';
import { BaseProps } from '../base';

export interface NumberInputProps
    extends BaseProps,
        Pick<SliderProps, 'defaultValue' | 'value' | 'onChange'> {
    type: 'number';
    variant: 'slider';
}

export const NumberInput: React.FC<NumberInputProps> = (props) => {
    return (
        <Slider
            aria-label="slider-ex-1"
            value={props.value}
            onChange={props.onChange}
            defaultValue={props.defaultValue}
        >
            <SliderTrack>
                <SliderFilledTrack />
            </SliderTrack>
            <SliderThumb />
        </Slider>
    );
};

import React from 'react';
import { Box, VStack } from '@chakra-ui/react';
import { MenuListProps } from './MenuListProps';
import { createMenuListChildren } from './MenuListHelper';

export const MenuList: React.FC<MenuListProps> = ({ ...props }) => {
    const { sticky, children } = createMenuListChildren(props.children);
    return (
        <VStack alignItems="flex-start" spacing={0} w="full" zIndex={props.zIndex}>
            {sticky && <Box w="full">{sticky}</Box>}
            <VStack
                w="full"
                alignItems="flex-start"
                pb={2}
                divider={props.divider}
                spacing={0}
                overflowY="auto"
                maxH={props.maxH}
            >
                {children}
            </VStack>
        </VStack>
    );
};

import { observer } from 'mobx-react';
import React from 'react';
import { FilterController, FilterLoader, FilterStateProvider } from './filterInterface';
import { AnalysisFilterControllerProps, AnalysisFilterViewProps } from './filterProps';

export function createFilterContainer(
    states: FilterStateProvider,
    loader: FilterLoader,
    controller: FilterController,
    Component: React.FC<AnalysisFilterViewProps>
): React.FC<AnalysisFilterControllerProps> {
    return observer((props) => {
        // const state = states.useState(props.context);
        // const data = loader.useData(props);
        const viewProps = controller.useProps(props);
        return <Component {...viewProps} />;
    });
}

import { useLocation, useNavigate } from 'react-router';
import { SettingsLayoutProps } from './SettingsLayout';

export const useSettingsLayout = (): SettingsLayoutProps => {
    const location = useLocation();
    const navigate = useNavigate();

    const handleBackClick = () => {
        // @ts-expect-error
        if (!location.state?.previousPathname) {
            return navigate('/', { replace: true });
        }
        // @ts-expect-error
        navigate(location.state.previousPathname, { replace: true });
    };
    return {
        onBack: handleBackClick,
    };
};

import React from 'react';
import { Box, BoxProps } from '@chakra-ui/react';

export interface DashboardLayoutMainProps {
    as?: React.ReactElement<BoxProps>;
}

export const DashboardLayoutMain: React.FC<DashboardLayoutMainProps> = (props) => (
    <>{props.children}</>
);

import { useMediaQuery } from '@chakra-ui/media-query';

export const useIsExtraLargeScreen = () => {
    const [value] = useMediaQuery('(min-width: 1920px)');
    return value;
};

export const useIsLargeScreen = () => {
    const [value] = useMediaQuery('(min-width: 1440px)');
    return value;
};

export const useIsMediumScreen = () => {
    const [value] = useMediaQuery('(min-width: 1024px)');
    return value;
};

export const useIsSmallScreen = () => {
    const [value] = useMediaQuery('(min-width: 1920px)');
    return value;
};

export const useIsTabletScreen = () => {
    const [value] = useMediaQuery('(min-width: 768)');
    return value;
};

export const useIsMobileScreen = () => {
    const [value] = useMediaQuery('(min-width: 425px)');
    return value;
};

import { Location } from 'react-router';
import { StudiesContext, StudiesControllerBaseConfig } from '../../base';
import { SurveyDetailController } from './surveyDetailInterface';
import { SurveyDetailAggregate, SurveyResponseMode } from './surveyDetailModel';
import { SurveyDetailControllerProps } from './surveyDetailProps';

export function createSurveyDetailController(
    config: StudiesControllerBaseConfig
): SurveyDetailController {
    const {
        controller: {
            submissions: { list: submissionController },
        },
        provider: { createOptionController },
    } = config;

    const optionController = createOptionController<SurveyResponseMode>({
        id: 'survey_start_mode_option',
    });

    function getConversationPath(
        context: StudiesContext,
        location: Location,
        item: SurveyDetailAggregate
    ) {
        const params = new URLSearchParams();
        params.set('asset', context.workspace.id.toString());
        params.set('type', 'survey');
        params.set('survey', item.survey.id);
        params.set('return_path', location.pathname);
        return `/u/conversations/new?${params.toString()}`;
    }

    return {
        useProps(context, item, props) {
            const submission = submissionController.useProps(context, item.submission);
            const mode = optionController.useProps(props.mode);
            return {
                submission,
                option: {
                    mode,
                },
                button: {
                    join: {
                        label:
                            props.mode.value === 'form'
                                ? 'Start survey'
                                : 'Start interview',
                    },
                },
                link: {
                    new: {
                        id: 'survey_detail_new_submission_link',
                        to:
                            props.mode.value === 'interview'
                                ? getConversationPath(context, props.location, item)
                                : `/u/assets/${context.workspace.id}/reporting/submissions/new?survey=${item.survey.id}`,
                    },
                    overview: {
                        to: `/u/assets/${context.workspace.id}/reporting/submissions`,
                    },
                },
            };
        },
    };
}

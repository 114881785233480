import { z } from 'zod';
import { TypeSchema } from '../../dashboards/type';

// Defining ColumnFlag and FlowType
const ColumnFlag = z.enum([
    'asset',
    'time_partition',
    'dimension',
    'required',
    'activity',
]);

export const FlowTypeSchema = z
    .enum(['integration', 'custom', 'direct', 'upload'])
    .transform((value) => {
        if (
            value !== 'integration' &&
            value !== 'custom' &&
            value !== 'direct' &&
            value !== 'upload'
        ) {
            return 'unknown';
        }
        return value;
    });

// DatasetTag
export const DatasetTagDto = z.object({
    name: z.string(),
    value: z.string(),
});

// SchemaColumnDto
export const SchemaColumnDto = z.object({
    type: TypeSchema,
    name: z.string(),
    description: z.string().nullable(),
    title: z.string(),
    flags: z.array(ColumnFlag),
});

// PhysicalDatasetDefinitionDto
const BasePhysicalDatasetDefinitionDto = z.object({
    kind: z.literal('physical'),
    slug: z.string(),
    name: z.string(),
    plugin: z.string(),
    columns: z.array(SchemaColumnDto),
    priority: z.number(),
    version: z.number(),
});

const PhysicalDatasetDefinitionDto = BasePhysicalDatasetDefinitionDto.extend({
    flows: z.array(z.any()), // Assuming IngestionFlow schema is defined elsewhere
    tags: z.array(DatasetTagDto),
    ingest_types: z.undefined(),
});

const UserPhysicalDatasetDefinitionDto = BasePhysicalDatasetDefinitionDto.extend({
    id: z.number(),
    ingest_types: z.array(FlowTypeSchema),
});

// V1DatasetDefinitionDto
export const V1DatasetDefinitionDto = z.object({
    kind: z.literal('legacy'),
    slug: z.string(),
    name: z.string(),
    plugin: z.string(),
    tags: z.array(DatasetTagDto),
});

// SyntheticDatasetDefinitionDto
export const SyntheticDatasetDefinitionDto = z.object({
    kind: z.literal('synthetic'),
    slug: z.string(),
    name: z.string(),
    plugin: z.string(),
    parent_dataset: z.string(),
    tags: z.array(DatasetTagDto),
});

// Union types for AnyDatasetDefinitionDto using discriminated union on 'kind'
// export const DatasetDtoSchema = z.union([
//     PhysicalDatasetDefinitionDto,
//     UserPhysicalDatasetDefinitionDto,
//     SyntheticDatasetDefinitionDto,
//     V1DatasetDefinitionDto,
// ]);

export const DatasetDtoSchema = UserPhysicalDatasetDefinitionDto;

export const DatasetItemSchema = z.object({
    dataset: DatasetDtoSchema,
});

export const DatasetListDtoSchema = z.object({
    data: z.array(DatasetItemSchema),
});

import {
    ReportDetailRouteOpportunityData,
    ReportDetailRouteOpportunityLoader,
} from './opportunityInterface';
import { ReportDetailRouteConfig } from '../reportDetailConfig';

export function createReportDetailRouteOpportunityLoader(
    config: Pick<ReportDetailRouteConfig, 'loader' | 'strategy' | 'repository'>
): ReportDetailRouteOpportunityLoader {
    const { repository, strategy } = config;
    return {
        useLoad(context, props): ReportDetailRouteOpportunityData {
            // const views = repository.view.useFind(context, {});
            // const plugins = repository.plugin.useFind(context, {}, {});
            // const peergroups = repository.peergroup.useFind(
            //     context,
            //     {
            //         workspace: props.asset,
            //         plugins: plugins.data?.map((item) => item.id) ?? [],
            //     },
            //     {}
            // );
            // const metrics = repository.metric.definition.useFind(context, {
            //     plugins: plugins.data ?? [],
            // });

            // const metricsById = useMemo(() => keyBy(metrics.data, (item) => item.id), []);
            return {
                item: {
                    status: 'loaded',
                    data: {
                        insight: props.item.insight,
                        plugin: props.item.plugin,
                    },
                },
            };
        },
    };
}

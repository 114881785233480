import {
    VStack,
    Box,
    Text,
    TableContainer,
    Table as ChakraTable,
    TableProps as ChakraTableProps,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableHeadProps,
    TableCellProps,
    TableBodyProps,
    TableRowProps,
    TableColumnHeaderProps,
} from '@chakra-ui/react';
import React from 'react';
import { useIsExtraLargeScreen } from '../../layout';
import { RowValue, Column, RowData, ColumnData, CellData } from './TableType';

export interface TableProps {
    overrides?: Partial<{
        table: ChakraTableProps;
        thead: TableHeadProps;
        tbody: TableBodyProps;
        th: TableColumnHeaderProps;
        tr: TableRowProps;
        td: TableCellProps;
    }>;
    columns: Column[];
    data: RowValue[];
    getHeaderProps?(context: { columnData: ColumnData }): TableHeadProps;
    getCellProps?(context: {
        rowData: RowData;
        columnData: ColumnData;
        cellData: CellData;
    }): TableCellProps;
    getCellValue?(
        context: { rowData: RowData; columnData: ColumnData },
        original: unknown
    ): unknown;
    renderCell?(
        context: { rowData: RowData; columnData: ColumnData; cellData: CellData },
        props: TableCellProps
    ): React.ReactElement;
    emptyState?: React.ReactElement;
}

export const Table: React.FC<TableProps> = ({
    overrides = {},
    emptyState = <Text color="yellow.500">No data found</Text>,
    getCellValue = (context, original) => original,
    getHeaderProps = () => ({}),
    getCellProps = () => ({}),
    renderCell = (context, value) => value.children as React.ReactElement,
    ...props
}) => {
    const isLarge = useIsExtraLargeScreen();

    const gutter = isLarge ? 4 : 2;
    const defaults: TableProps['overrides'] = {
        ...overrides,
        th: {
            fontSize: 'sm',
            position: 'sticky',
            top: 0,
            ...overrides.th,
        },
        td: {
            paddingInlineStart: 0,
            paddingInlineEnd: gutter,
            paddingTop: gutter,
            paddingBottom: gutter,
            ...overrides.td,
        },
    };

    const Wrapper: React.FC = ({ ...props }) => (
        <VStack alignItems="center" justifyContent="center" p={0}>
            {props.children}
        </VStack>
    );

    if (props.data.length === 0 && emptyState) {
        return <Wrapper>{emptyState}</Wrapper>;
    }

    return (
        // <Wrapper>
        <ChakraTable
            {...defaults.table}
            // props.columns.length > 5 ? 'auto' :
            style={{ ...defaults.table?.style, tableLayout:  'fixed' }}
        >
            <Thead {...defaults.thead}>
                <Tr {...defaults.tr}>
                    {props.columns.map((column, index) => (
                        <Th
                            key={index}
                            {...getHeaderProps({ columnData: { ...column, index } })}
                            {...defaults.th}
                            width={index === 0 && isLarge ? '17.5%' : 'auto'}
                        >
                            {column.title === null ? null : column.title ?? column.key}
                        </Th>
                    ))}
                </Tr>
            </Thead>
            <Tbody {...defaults.tbody}>
                {props.data.map((rowValue, index) => {
                    const rowData: RowData = { index, value: rowValue };
                    return (
                        <Tr key={index} {...defaults.tr}>
                            {props.columns.map((column, index) => {
                                const cellData: CellData = {
                                    value: rowValue[column.key],
                                };
                                const cellValue = getCellValue(
                                    { rowData, columnData: { ...column, index } },
                                    cellData.value
                                );
                                const cellProps = getCellProps({
                                    rowData,
                                    columnData: { ...column, index },
                                    cellData: { value: cellValue },
                                });
                                const cellContent = renderCell(
                                    {
                                        rowData,
                                        columnData: { ...column, index },
                                        cellData: { value: cellValue },
                                    },
                                    {
                                        ...cellProps,
                                        children: cellValue as React.ReactNode,
                                    }
                                );
                                return (
                                    <Td
                                        key={column.key}
                                        {...defaults.td}
                                        width={index === 0 ? '300px' : 'auto'}
                                        whiteSpace="nowrap"
                                        // minH={20}
                                    >
                                        {cellContent}
                                    </Td>
                                );
                            })}
                        </Tr>
                    );
                })}
            </Tbody>
        </ChakraTable>
        // </Wrapper>
    );
};

import * as React from 'react';
import { ErrorInfo } from '../../models/Errors';
import { useForm } from 'react-hook-form';
import GoogleLogin from 'react-google-login';
import { SocialLogin } from '../../models/Account';
import Input from '../../components/FormControls/Input';
import { Link as RRDLink } from 'react-router-dom';
import ErrorView from '../ErrorView/ErrorView';
import {
    Container,
    Center,
    GridItem,
    Heading,
    SimpleGrid,
    Button,
    Spinner,
    Text,
    Link,
    VStack,
    Image,
    useColorMode,
} from '@chakra-ui/react';
import googleSVG from '../../svg/google-logo.svg';

export interface LoginProps {
    isLoading: boolean;
    err: ErrorInfo | null;
    doLogin: (email: string, authPayload: string | SocialLogin) => void;
    title?: string;
    subTitle?: string;
}

export const LoginForm: React.FunctionComponent<LoginProps> = ({
    isLoading,
    err,
    doLogin,
    title = 'Log In',
    subTitle = '',
}) => {
    const {
        handleSubmit,
        register,
        formState: { errors },
    } = useForm({ mode: 'onBlur', reValidateMode: 'onBlur' });
    const [googleError, setGoogleError] = React.useState<string>();
    const onSubmit = async (data: any) => {
        doLogin(data.email, data.password);
    };
    const { colorMode, toggleColorMode } = useColorMode();

    React.useEffect(() => {
        if (colorMode === 'dark') {
            toggleColorMode();
        }

        // this is a hack to fix this issue
        // https://github.com/chakra-ui/chakra-ui/issues/5925
        if (localStorage.getItem('chakra-ui-color-mode') === 'dark') {
            localStorage.setItem('chakra-ui-color-mode', 'light');
        }
    }, []);

    const responseGoogle = React.useCallback((googleUser: any) => {
        const googleProf = googleUser.getBasicProfile();
        // getHostedDomain
        doLogin(googleProf.getEmail(), {
            social_type: 'google',
            token: googleUser.tokenId,
            access_token: googleUser.accessToken,
            email: googleProf.getEmail(),
        });
    }, []);
    const responseFailedGoogle = React.useCallback(
        ({ error, details }) => {
            setGoogleError(details);
        },
        [setGoogleError]
    );
    return (
        <Container centerContent maxW="md">
            <VStack
                w="100%"
                h="100%"
                p={10}
                align="stretch"
                spacing={8}
                border="1px"
                borderColor="frame"
                borderRadius={10}
                bg="box"
            >
                <VStack>
                    <Heading size="lg" textAlign="center" color="onboarding.title">
                        {title}
                    </Heading>
                    {/* <Text fontSize="lg" color="onboarding.subtitle">
                        {subTitle}
                    </Text> */}
                </VStack>
                <form onSubmit={handleSubmit(onSubmit)} id="login_frm">
                    <SimpleGrid columns={1} rowGap={6} w="100%" h="100%">
                        <GridItem colSpan={1}>
                            <Input
                                bg="button.white"
                                placeholder="Email"
                                {...register('email', {
                                    required: 'Required',
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                        message: 'Invalid email address',
                                    },
                                })}
                                err={errors.email && errors.email.message}
                                label="Email Address"
                                type="text"
                            />
                        </GridItem>
                        <GridItem colSpan={1}>
                            <Input
                                bg="button.white"
                                placeholder="Password"
                                label="Password"
                                type="password"
                                {...register('password', {
                                    required: 'Required password',
                                })}
                                err={errors.password && errors.password.message}
                            />
                        </GridItem>
                        <GridItem colSpan={1}>
                            <Button
                                isLoading={isLoading}
                                w="full"
                                textColor="button.white"
                                boxShadow="lg"
                                bg="button.green"
                                type="submit"
                                id="login_btn"
                                _hover={{ bg: 'button.hoverGreen' }}
                                isDisabled={isLoading}
                            >
                                Login
                            </Button>
                        </GridItem>
                        <GridItem colSpan={1}>
                            <Button
                                as={RRDLink}
                                isLoading={isLoading}
                                w="full"
                                to={'passwordless'}
                                variant='outline'
                                type="button"
                                id="login_btn"
                            >
                                Passwordless Login
                            </Button>
                        </GridItem>
                        {err && (
                            <Center>
                                {' '}
                                <ErrorView {...err} />
                            </Center>
                        )}
                        {googleError && googleError !== 'R' && (
                            <Center>
                                <Text color="error">{googleError}</Text>
                            </Center>
                        )}

                        <GridItem colSpan={1}>
                            <GoogleLogin
                                clientId="240057280138-2honutvt52p3h127oh714vci3t6rlo6s.apps.googleusercontent.com"
                                className="googlebtn"
                                onSuccess={responseGoogle}
                                onFailure={responseFailedGoogle}
                                cookiePolicy={'single_host_origin'}
                                render={(renderProps) => (
                                    <Button
                                        w="full"
                                        color="button.googleText"
                                        py={7}
                                        onClick={renderProps.onClick}
                                        bg="button.white"
                                        borderRadius="xl"
                                        boxShadow="xl"
                                        leftIcon={<Image src={googleSVG} />}
                                    >
                                        Login with Google
                                    </Button>
                                )}
                            />
                        </GridItem>

                        <GridItem>
                            <Center>
                                <Link
                                    as={RRDLink}
                                    to="/forgot-password/request"
                                    color="gray.700"
                                >
                                    Forgot Password?
                                </Link>
                            </Center>
                        </GridItem>

                        <GridItem colSpan={1}>
                            <Center>
                                <Text color="gray.700">
                                    Don't have an account yet?
                                    <Link
                                        fontSize="lg"
                                        pl={2}
                                        color="link.green"
                                        as={RRDLink}
                                        to={'/register'}
                                    >
                                        Register
                                    </Link>
                                </Text>
                            </Center>
                        </GridItem>
                    </SimpleGrid>
                </form>
            </VStack>
        </Container>
    );
};

import React from 'react';
import { ErrorInfo } from '../../models/Errors';
import { useForm } from 'react-hook-form';
import { ChangePasswordIdentifiedUser } from 'src/models/Account';
import { VStack, Text } from '@chakra-ui/layout';
import Input from '../FormControls/Input';
import { Spinner } from '@chakra-ui/spinner';
import { Button } from '@chakra-ui/button';

export interface ResponseForgotPasswordProps {
    isLoading: boolean;
    err: ErrorInfo | null;
    usr: ChangePasswordIdentifiedUser | null;
    doSetForgotPwd: (password: string) => void;
}

export const SetPasswordForm: React.FunctionComponent<ResponseForgotPasswordProps> = ({
    isLoading,
    err,
    doSetForgotPwd,
    usr,
}) => {
    const { handleSubmit, register, formState, getValues, setError } = useForm({
        mode: 'onBlur',
        reValidateMode: 'onBlur',
    });

    const onSubmit = async (data: any) => {
        doSetForgotPwd(data.password);
    };

    React.useEffect(() => {
        if (err && err.fieldErrors) {
            err.fieldErrors.forEach(({ loc, msg }) => {
                if (loc.length == 2) {
                    setError(loc[1], {
                        message: msg,
                        type: 'invalid',
                    });
                }
            });
        }
    }, [err]);
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <VStack spacing={4} width="md">
                {usr && <Text>{usr.first_name}, please fill your new password below:</Text>}
                <Input
                    placeholder="Password"
                    {...register('password', {
                        required: 'Required',
                    })}
                    label="Password"
                    err={formState.errors.password && formState.errors.password.message}
                    type="password"
                />
                <Input
                    placeholder="Confirm Password"
                    {...register('confirm_password', {
                        required: 'Required',
                        validate: (v) => {
                            const pass = getValues('password');
                            if (pass !== v) {
                                return 'Passwords does not match';
                            }
                        },
                    })}
                    label="Confirm Password"
                    err={formState.errors.confirm_password && formState.errors.confirm_password.message}
                    type="password"
                />
                {err && (
                    <Text color="red.200">
                        {err.errType == 'unauthorized' ? 'Cannot trigger set password' : err.message || err.errType}
                    </Text>
                )}
                {isLoading && <Spinner />}
                <Button isFullWidth colorScheme="yellow" type="submit">
                    Save New Password
                </Button>
            </VStack>
        </form>
    );
};

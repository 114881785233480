import { z } from 'zod';

export const TimeGranularitySchema = z.enum([
    'hour',
    'day',
    'week',
    'month',
    'quarter',
    'year',
]);

import React from 'react';
import { NetworkContextProvider } from '../base';
import { NetworkLayoutController } from './networkLayoutInterface';
import { NetworkLayoutRouteViewProps } from './networkLayoutProps';

export function createNetworkLayoutRoute(
    contexts: NetworkContextProvider,
    controller: NetworkLayoutController,
    View: React.FC<NetworkLayoutRouteViewProps>
): React.FC {
    return (props) => {
        const viewContext = contexts.useContext();
        const viewProps = controller.useProps(viewContext, {});
        return <View {...viewProps}>{props.children}</View>;
    };
}

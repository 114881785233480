import React, { Suspense, useEffect, useRef } from 'react';
import { AiOutlineBarChart } from 'react-icons/ai';
import { BiTrendingUp } from 'react-icons/bi';
import { IoIosWarning } from 'react-icons/io';
import {
    VStack,
    Grid,
    GridItem,
    Box,
    Center,
    SimpleGrid,
    HStack,
    Heading,
    Tag,
    Icon,
    DarkMode,
    TagProps,
    TextProps,
    Button,
    Image,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverBody,
    PopoverHeader,
} from '@chakra-ui/react';
import { CardSpinner } from '../../../domain';
import { AnalysisLayout } from '../../route';
import {
    fetchElements,
    getAllChildrenProps,
    getChildrenProps,
    getElements,
} from '../../util';
import type { HomeLayout, HomeLayoutViewItemProps } from '../../route/home';
import { AnalysisLayoutConfig } from './analysisLayoutConfig';
import { ChevronDownIcon, WarningIcon } from '@chakra-ui/icons';
import { BsArrowLeft, BsChevronDown } from 'react-icons/bs';
import { FiArrowLeft, FiChevronDown } from 'react-icons/fi';
import { props } from 'lodash/fp';
import { Link } from 'react-router-dom';

export function createAnalysisLayout(config: AnalysisLayoutConfig): AnalysisLayout {
    const {
        errorFormatter,
        UI: {
            createErrorBoundary,
            EmptyState,
            Td,
            Text,
            Icon: { IconGroup },
        },
        BaseLayout,
    } = config;

    const ErrorBoundary = createErrorBoundary({
        errorClass: Error,
    });

    const Instance: AnalysisLayout = {
        UI: config.UI,
        Wrapper(props) {
            const { Segment, Content, CompetitiveSet, Group } = fetchElements(
                props.children,
                {
                    CompetitiveSet: Instance.CompetitiveSet,
                    Segment: Instance.Segment,
                    Content: Instance.Content,
                    ControlList: Instance.Control.List,
                    Group: Instance.Group,
                }
            );

            const { Actions, Filters, Date } = getElements(props.children, {
                Filters: Instance.Filters,
                Date: Instance.Date,
                Actions: Instance.Actions,
            });

            const {
                selectionTrigger: selectionTriggerProps,
                selectionContent: selectionContentProps,
                backbutton: backProps,
                controlList: controlListProps,
            } = getChildrenProps(props.children, {
                selectionTrigger: Instance.Selection.Trigger,
                selectionContent: Instance.Selection.Content,
                backbutton: Instance.BackButton,
                controlList: Instance.Control.List,
            });

            const { controlItem: controlItemProps } = getAllChildrenProps(
                controlListProps?.children,
                {
                    controlItem: Instance.Control.Item,
                }
            );

            return (
                <Grid templateRows="min-content 1fr">
                    <GridItem bg="rgb(21, 21, 21)">
                        <HStack
                            p={2}
                            borderBottom="1px solid"
                            borderColor="whiteAlpha.300"
                        >
                            {backProps && (
                                <Box>
                                    <Link to={backProps.to}>
                                        <Button
                                            h={8}
                                            py={0}
                                            fontSize="sm"
                                            fontWeight="semibold"
                                            bg="none"
                                            variant="ghost"
                                            color="whiteAlpha.700"
                                            leftIcon={<Icon as={FiArrowLeft} />}
                                        >
                                            {backProps?.children}
                                        </Button>
                                    </Link>
                                </Box>
                            )}
                            {selectionTriggerProps && (
                                <Box>
                                    <Popover
                                        placement="bottom-start"
                                        lazyBehavior="unmount"
                                        // need to be lazy for proper auto-focus behaviour
                                        isLazy={true}
                                        isOpen={selectionContentProps?.isOpen}
                                        onOpen={selectionContentProps?.onOpen}
                                        onClose={selectionContentProps?.onClose}
                                    >
                                        <PopoverTrigger>
                                            <Button
                                                h={8}
                                                py={0}
                                                fontSize="sm"
                                                // fontWeight="medium"
                                                fontWeight="semibold"
                                                bg="none"
                                                variant="ghost"
                                                color="whiteAlpha.800"
                                                rightIcon={<Icon as={FiChevronDown} />}
                                                _focus={{ outline: 'none' }}
                                                {...selectionTriggerProps}
                                            ></Button>
                                        </PopoverTrigger>
                                        <PopoverContent
                                            bg="#292929"
                                            outline="none"
                                            _focus={{ outline: 'none' }}
                                        >
                                            <PopoverHeader px={4} py={3}>
                                                <Heading
                                                    fontSize="md"
                                                    fontWeight="medium"
                                                >
                                                    Metrics
                                                </Heading>
                                            </PopoverHeader>
                                            <PopoverBody
                                                p={0}
                                                maxH="33vh"
                                                overflowY="scroll"
                                            >
                                                <Box p={4}>
                                                    {selectionContentProps?.children}
                                                </Box>
                                            </PopoverBody>
                                        </PopoverContent>
                                    </Popover>
                                </Box>
                            )}
                        </HStack>
                    </GridItem>
                    <GridItem>
                        <BaseLayout.Wrapper
                            stickyControls={false}
                            showGroups={false}
                            isVisible={props.isVisible}
                            disclosure={props.disclosure}
                            style={config.style}
                            mode="dynamic"
                            onModeChange={console.log}
                        >
                            <BaseLayout.Segment>{Segment}</BaseLayout.Segment>
                            <BaseLayout.Group>{Group}</BaseLayout.Group>
                            {/* {Actions ? (
                                <BaseLayout.Actions>{Actions}</BaseLayout.Actions>
                            ) : null} */}
                            <BaseLayout.CompetitiveSet>
                                {CompetitiveSet}
                            </BaseLayout.CompetitiveSet>
                            <BaseLayout.Filters as={<Box />}>
                                {Filters}
                            </BaseLayout.Filters>
                            <BaseLayout.Date as={<Box />}>{Date}</BaseLayout.Date>
                            {controlListProps ? (
                                <BaseLayout.Control.List as={<Box pt={6} px={6} />}>
                                    {controlItemProps.map(({ ...item }) => (
                                        <BaseLayout.Control.Item
                                            key={item.id}
                                            {...item}
                                        />
                                    ))}
                                </BaseLayout.Control.List>
                            ) : null}
                            <BaseLayout.Cards as={<Box px={6} />}>
                                <Suspense
                                    fallback={
                                        <Center py={6}>
                                            <CardSpinner />
                                        </Center>
                                    }
                                >
                                    {Content}
                                </Suspense>
                            </BaseLayout.Cards>
                        </BaseLayout.Wrapper>
                    </GridItem>
                </Grid>
            );
        },
        Selection: {
            Trigger(props) {
                return <>{props.children}</>;
            },
            Content(props) {
                return <>{props.children}</>;
            },
        },
        BackButton(props) {
            return <>{props.children}</>;
        },
        Segment(props) {
            return <>{props.children}</>;
        },
        Date(props) {
            return <>{props.children}</>;
        },
        Group(props) {
            return <>{props.children}</>;
        },
        Status(props) {
            return <>{props.children}</>;
        },
        Control: {
            List(props) {
                return <>{props.children}</>;
            },
            Item(props) {
                return <>{props.children}</>;
            },
        },
        Actions(props) {
            return <>{props.children}</>;
        },
        Filters(props) {
            return <>{props.children}</>;
        },
        CompetitiveSet(props) {
            return <>{props.children}</>;
        },
        Content(props) {
            return <>{props.children}</>;
        },
    };
    return Instance;
}

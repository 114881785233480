import React, { useState } from 'react';
import { Select } from 'chakra-react-select';
import { capitalize } from 'lodash';
import {
    InputGroup,
    FormErrorMessage,
    FormLabel,
    VStack,
    ModalHeader,
    ModalCloseButton,
    Modal,
    ModalContent,
    ModalBody,
    ModalFooter,
    ButtonGroup,
    Input,
    FormControl,
    Checkbox,
    RadioGroup,
    Radio,
    Stack,
} from '@chakra-ui/react';
import { ApplicationUI } from '../../../../ui';
import { UserInviteFormViewProps } from './userInviteFormProps';
import { Controller, FieldError } from 'react-hook-form';

export function createUserInviteFormView(config: {
    UI: ApplicationUI;
}): React.FC<UserInviteFormViewProps> {
    const {
        UI: { Button, Text },
    } = config;
    return (props) => {
        const workspaceErrorMsg = (
            props.formState.errors.workspaces as unknown as FieldError
        )?.message; // @ts-ignore
        return (
            <>
                <Modal
                    isOpen={props.isOpen}
                    onClose={props.onClose}
                    closeOnEsc={true}
                    closeOnOverlayClick={false}
                >
                    <ModalContent
                        onKeyDown={(e) => {
                            e.stopPropagation();
                        }}
                    >
                        <form onSubmit={props.onSubmit} noValidate>
                            <ModalHeader>Invite Teammate</ModalHeader>
                            <ModalCloseButton />
                            <ModalBody>
                                <VStack spacing={2}>
                                    <FormControl
                                        isInvalid={!!props.formState.errors.email}
                                    >
                                        <FormLabel>Email address</FormLabel>
                                        <Input
                                            placeholder="Email"
                                            {...props.register('email', {
                                                required: 'Required',
                                            })}
                                            isRequired
                                            title="Email"
                                            type="text"
                                        />
                                        {props.formState.errors.email &&
                                            props.formState.errors.email.message && (
                                                <FormErrorMessage>
                                                    {props.formState.errors.email.message}
                                                </FormErrorMessage>
                                            )}
                                    </FormControl>
                                    <FormControl
                                        isInvalid={!!props.formState.errors.firstName}
                                    >
                                        <FormLabel>First Name</FormLabel>
                                        <Input
                                            placeholder="First Name"
                                            {...props.register('firstName', {})}
                                            isRequired
                                            title="First Name"
                                            type="text"
                                        />
                                        {props.formState.errors.firstName &&
                                            props.formState.errors.firstName.message && (
                                                <FormErrorMessage>
                                                    {
                                                        props.formState.errors.firstName
                                                            .message
                                                    }
                                                </FormErrorMessage>
                                            )}
                                    </FormControl>
                                    <FormControl
                                        isInvalid={!!props.formState.errors.lastName}
                                    >
                                        <FormLabel>Last Name</FormLabel>
                                        <Input
                                            placeholder="Last Name"
                                            {...props.register('lastName', {})}
                                            isRequired
                                            title="Last Name"
                                            type="text"
                                        />
                                        {props.formState.errors.lastName &&
                                            props.formState.errors.lastName.message && (
                                                <FormErrorMessage>
                                                    {
                                                        props.formState.errors.lastName
                                                            .message
                                                    }
                                                </FormErrorMessage>
                                            )}
                                    </FormControl>

                                    <FormControl
                                        isInvalid={!!props.formState.errors.title}
                                    >
                                        <FormLabel>Title</FormLabel>
                                        <Controller
                                            control={props.control}
                                            name="title"
                                            render={({
                                                field: {
                                                    onChange,
                                                    onBlur,
                                                    value,
                                                    name,
                                                    ref,
                                                },
                                                fieldState: {
                                                    invalid,
                                                    isTouched,
                                                    isDirty,
                                                    error,
                                                },
                                                formState,
                                            }) => (
                                                <Select
                                                    ref={ref}
                                                    value={value}
                                                    onChange={onChange}
                                                    isMulti={false}
                                                    options={props.titles.map((t) => ({
                                                        value: t,
                                                        label: t,
                                                    }))}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                    <FormControl
                                        as="fieldset"
                                        isInvalid={!!props.formState.errors.workspaces}
                                    >
                                        <FormLabel as="legend">Brand Access</FormLabel>
                                        {props.allowFullWorkspaceAccess && (
                                            <Controller
                                                control={props.control}
                                                name="role"
                                                rules={{
                                                    required: 'Required',
                                                }}
                                                render={({
                                                    field: {
                                                        onChange,
                                                        onBlur,
                                                        value,
                                                        name,
                                                        ref,
                                                    },
                                                    fieldState: {
                                                        invalid,
                                                        isTouched,
                                                        isDirty,
                                                        error,
                                                    },
                                                    formState,
                                                }) => (
                                                    <RadioGroup
                                                        onChange={onChange}
                                                        value={value}
                                                    >
                                                        <Stack>
                                                            <Radio value="admin">
                                                                Full Brands Access
                                                            </Radio>
                                                            <Radio value="standard">
                                                                Specific Brands Access
                                                            </Radio>
                                                        </Stack>
                                                    </RadioGroup>
                                                )}
                                            />
                                        )}
                                        <InputGroup
                                            orientation="vertical"
                                            flexDirection="column"
                                            mt={2}
                                        >
                                            <Controller
                                                control={props.control}
                                                name="workspaces"
                                                rules={{
                                                    validate: (selectedWorkspaces) => {
                                                        if (
                                                            props.allowFullWorkspaceAccess &&
                                                            props.getValues('role') ==
                                                                'admin'
                                                        ) {
                                                            return;
                                                        }
                                                        if (
                                                            (props.workspacesOptions
                                                                .length > 0 &&
                                                                !selectedWorkspaces) ||
                                                            selectedWorkspaces.length == 0
                                                        ) {
                                                            return 'Please grant access to least one brand';
                                                        }
                                                    },
                                                }}
                                                render={({
                                                    field: {
                                                        onChange,
                                                        onBlur,
                                                        value,
                                                        name,
                                                        ref,
                                                    },
                                                    fieldState: {
                                                        invalid,
                                                        isTouched,
                                                        isDirty,
                                                        error,
                                                    },
                                                    formState,
                                                }) => (
                                                    <Select
                                                        ref={ref}
                                                        isDisabled={
                                                            props.getValues('role') ==
                                                            'admin'
                                                        }
                                                        value={value}
                                                        onChange={onChange}
                                                        isMulti={true}
                                                        options={props.workspacesOptions}
                                                    />
                                                )}
                                            />
                                        </InputGroup>
                                        <FormErrorMessage>
                                            {workspaceErrorMsg}
                                        </FormErrorMessage>
                                        {props.error ? (
                                            <Text color="red.500">
                                                {props.error.message}
                                            </Text>
                                        ) : null}
                                    </FormControl>
                                </VStack>
                            </ModalBody>
                            <ModalFooter>
                                <ButtonGroup>
                                    <Button
                                        variant="ghost"
                                        size="sm"
                                        onClick={props.onClose}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        isLoading={props.formState.isSubmitting}
                                        // isDisabled={props.formState.isSubmitting}
                                        type="submit"
                                        colorScheme="blue"
                                        variant="solid"
                                        size="sm"
                                    >
                                        Invite
                                    </Button>
                                </ButtonGroup>
                            </ModalFooter>
                        </form>
                    </ModalContent>
                </Modal>
            </>
        );
    };
}

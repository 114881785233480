import { ButtonProps } from '@chakra-ui/button';
import React from 'react';

export interface OrganizationLayoutItemProps extends Pick<ButtonProps, 'leftIcon'> {
    children: React.ReactElement;
}

export const OrganizationLayoutItem: React.FC<OrganizationLayoutItemProps> = (props) => (
    <>{props.children}</>
);

import {
    Box,
    Button,
    Grid,
    GridItem,
    Heading,
    HStack,
    Image,
    Input,
    List,
    ListItem,
    StackDivider,
    Switch,
    Tag,
    Text,
    Tooltip,
    VStack,
    FormControl,
    FormLabel,
    InputGroup,
    InputLeftAddon,
    Icon,
    SimpleGrid,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverBody,
    useDisclosure,
    PopoverHeader,
    Center,
    Wrap,
    WrapItem,
    Alert,
    AlertTitle,
    AlertIcon,
    Spinner,
    AlertDescription,
    FormErrorMessage,
} from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import React from 'react';
import {
    AiOutlineArrowRight,
    AiOutlineCaretDown,
    AiOutlineCheckCircle,
    AiOutlineClose,
    AiOutlineEdit,
    AiOutlineInfoCircle,
    AiOutlineLink,
} from 'react-icons/ai';
import { FiChevronDown } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { RemoteIframe } from '../../../../ui';
import { CardSpinner } from '../../../../domain';
import { AssetPrepareViewProps } from '../../../view/assets';
import { AccountProfileViewProps } from '../../../view';

export function createOnboardingProfileView(): React.FC<AccountProfileViewProps> {
    return (props) => {
        const formProps = props.getFormProps();
        const statusProps = props.getStatusProps();
        // const onboardingProps = props.getOnboardingProps();
        if (statusProps.status === 'loading') {
            return (
                <Center p={6}>
                    <CardSpinner />
                </Center>
            );
        }
        return (
            <VStack
                w="full"
                spacing={6}
                py={6}
                divider={<StackDivider borderColor="gray.300" borderWidth={0.5} />}
            >
                <VStack
                    w="full"
                    spacing={6}
                    maxW={{ base: undefined, md: '75%', lg: '66%', xl: '50%' }}
                    // mx="auto"
                >
                    {formProps.error && (
                        <Alert status="error">
                            <AlertIcon />
                            <AlertDescription>{formProps.error.message}</AlertDescription>
                        </Alert>
                    )}
                    <VStack w="full" spacing={4}>
                        <HStack w="full" spacing={4} align="start">
                            <FormControl
                                isInvalid={props.getControlProps('firstname').isInvalid}
                            >
                                <FormLabel htmlFor="firstname" fontWeight="semibold">
                                    First name
                                </FormLabel>
                                <InputGroup borderColor="gray.300" size="lg">
                                    <Input
                                        bg="white"
                                        placeholder="First name"
                                        {...props.getInputProps('firstname')}
                                    />
                                </InputGroup>
                                <FormErrorMessage>
                                    {props.getControlProps('firstname').error?.message}
                                </FormErrorMessage>
                            </FormControl>
                            <FormControl
                                isInvalid={props.getControlProps('lastname').isInvalid}
                            >
                                <FormLabel htmlFor="lastname" fontWeight="semibold">
                                    Last name
                                </FormLabel>
                                <InputGroup borderColor="gray.300" size="lg">
                                    <Input
                                        bg="white"
                                        placeholder="last name"
                                        {...props.getInputProps('lastname')}
                                    />
                                </InputGroup>
                                <FormErrorMessage>
                                    {props.getControlProps('lastname').error?.message}
                                </FormErrorMessage>
                            </FormControl>
                        </HStack>
                        <FormControl
                            isInvalid={props.getControlProps('companyName').isInvalid}
                        >
                            <FormLabel htmlFor="companyName" fontWeight="semibold">
                                Company name
                            </FormLabel>
                            <InputGroup borderColor="gray.300" size="lg">
                                <Input
                                    bg="white"
                                    placeholder="Company name"
                                    {...props.getInputProps('companyName')}
                                />
                            </InputGroup>
                            <FormErrorMessage>
                                {props.getControlProps('companyName').error?.message}
                            </FormErrorMessage>
                        </FormControl>
                        <FormControl
                            isInvalid={props.getControlProps('companyWebsite').isInvalid}
                        >
                            <FormLabel htmlFor="companyWebsite" fontWeight="semibold">
                                Website URL
                            </FormLabel>
                            <InputGroup borderColor="gray.300" size="lg">
                                <InputLeftAddon bg="gray.200">
                                    <Text color="gray.500">https://</Text>
                                </InputLeftAddon>
                                <Input
                                    bg="white"
                                    placeholder="Website URL"
                                    {...props.getInputProps('companyWebsite')}
                                />
                            </InputGroup>
                            <FormErrorMessage>
                                {props.getControlProps('companyWebsite').error?.message}
                            </FormErrorMessage>
                        </FormControl>
                        <FormControl>
                            <HStack mb={2}>
                                <FormLabel fontWeight="semibold" m={0}>
                                    I want to integrate data from multiple companies (e.g.
                                    agencies)
                                </FormLabel>
                                {/* <Tooltip
                                    placement="top"
                                    hasArrow={true}
                                    label={
                                        <Box p={2}>
                                            Agencies can work with data from multiple
                                            brands
                                        </Box>
                                    }
                                >
                                    <span>
                                        <Icon color="gray.700" as={AiOutlineInfoCircle} />
                                    </span>
                                </Tooltip> */}
                            </HStack>
                            <InputGroup borderColor="gray.300" size="lg">
                                <Switch
                                    colorScheme="blue"
                                    size="lg"
                                    {...props.getBooleanInputProps('isAgency')}
                                />
                            </InputGroup>
                        </FormControl>
                    </VStack>
                </VStack>
            </VStack>
        );
    };
}

import { useQuery } from '@tanstack/react-query';
import { SubscriptionDto } from '../../../../api';
import { CollectionOld } from '../../../base';
import { Workspace, Dashboard } from '../../../domain';
import { useUserApi } from '../../';
import { useWorkspaceContext } from './workspaceContext';
import { useWorkspaceContextV2 } from '../../../context';

export interface WorkspaceApi {
    getKey(): string;
    getWorkspace(): Workspace;
    getDashboards(): CollectionOld<Dashboard>;
    getDefaultDashboard(): Dashboard | null;
    getCurrentDashboardUrl(): string | null;
    isHighlighted(dashboard: Dashboard): boolean;
    inExampleMode(): boolean;
    getSubscription(): SubscriptionDto;
}

export const useWorkspaceApi = (): WorkspaceApi => {
    const { workspace, adapter } = useWorkspaceContext();
    const api = { user: useUserApi() };

    const data = useWorkspaceContextV2();

    const subscription = useQuery({
        queryKey: ['workspaces', workspace.id, 'subscription'],
        queryFn() {
            return adapter.subscription.getSubscription(workspace);
        },
        suspense: true,
        staleTime: Infinity,
    });

    return {
        getKey() {
            return workspace.id.toString();
        },
        getWorkspace() {
            return workspace;
        },
        getDashboards() {
            return {
                items: data.dashboards.data ?? [],
                getPath(dashboard) {
                    return `/u/assets/${workspace.id}/dashboards/${dashboard.id}`;
                },
                isActive(dashboard) {
                    return false;
                },
            };
        },
        inExampleMode(): boolean {
            return data.exampleMode ?? false;
        },
        getDefaultDashboard() {
            const [first] = data.dashboards.data ?? [];
            return first ?? null;
        },
        getCurrentDashboardUrl() {
            const dashboard = this.getDefaultDashboard();
            if (dashboard) {
                return `/u/assets/${workspace.id}/dashboards/${dashboard.id}`;
            }
            return api.user.getDefaultWorkspaceUrl();
        },
        // getError(dashboard) {
        //     return depErrorByDashboard[dashboard.id] ?? null;
        // },
        isHighlighted(dashboard) {
            return dashboard.highlighted;
        },
        getSubscription() {
            if (!subscription.data) {
                throw new Error(`subscription not loaded`);
            }
            return subscription.data;
        },
    };
};

import { Text, Box, BoxProps, Spinner } from '@chakra-ui/react';
import React from 'react';
import { DashboardContext } from '../../../hooks';
import {
    IntegrationCardList,
    IntegrationCardListProps,
    UserIntegrationDefinition,
    getDashboardIntegrationStatus,
    DashboardIntegration,
} from '../../../domain';
import { IntegrationItemAssetView } from '../../integration';
import { DashboardIntegrationProps } from './props';
import { canIgnore, isFullfilledOrIgnored } from './props/dashboardIntegrationHelper';

export interface DashboardConnectionListViewProps {
    context: DashboardContext;
    dashboardIntegrations: DashboardIntegrationProps;
    listProps?: Partial<IntegrationCardListProps>;
    renderErrors?: boolean;
    itemProps?(definition: UserIntegrationDefinition): BoxProps;
}

export const DashboardConnectionListView: React.FC<DashboardConnectionListViewProps> = ({
    context,
    renderErrors,
    ...props
}) => {
    return (
        <IntegrationCardList {...props.listProps} bg="gray.100" border="gray.200">
            {props.dashboardIntegrations.integrations.map((dashboardIntegration) => {
                const { integration: definition, isFulfilled } = dashboardIntegration;
                return (
                    <IntegrationItemAssetView
                        containerProps={{ ...props.itemProps?.(definition) }}
                        asset={{ id: context.assetId }}
                        key={definition.id}
                        definition={{
                            ...definition,
                            isIgnored: dashboardIntegration.isIgnored,
                            canIgnore:
                                canIgnore(dashboardIntegration) ||
                                dashboardIntegration.isFulfilled,
                        }}
                        forceShowIgnore={canIgnore(dashboardIntegration)}
                        forceDisconnected={!isFulfilled}
                        status={getDashboardIntegrationStatus(
                            { id: context.assetId },
                            dashboardIntegration
                        )}
                        onConnect={props.dashboardIntegrations.context.onConnect}
                        onReconnect={props.dashboardIntegrations.context.onReconnect}
                    />
                );
            })}
        </IntegrationCardList>
    );
};
